/**
 * src/pages/Support.jsx
 *
 * created by pycui on 8/18/23
 */

import React from 'react';

const Support = () => {
  return (
    <div className='home'>
      <h1>Get Support for Zhigu</h1>

      <p>
        We&apos;re here to help! If you encounter any issues or have questions,
        here&apos;s how you can get support:
      </p>

      <ul>
        <li>
          <strong>Contact Support:</strong> If you need direct assistance, you
          can always reach out to us at{' '}
          <a href='service@chentu.co'>service@chentu.co</a>.
        </li>
      </ul>
    </div>
  );
};

export default Support;
