import React from 'react';
import styled, { keyframes } from 'styled-components';

// 定义旋转动画
const brd1 = keyframes`
  to {
    border-radius: var(--b2);
  }
`;

const rot = keyframes`
  to {
    transform: rotatez(360deg);
  }
`;



// 新增：定义文字旋转动画
const textRot = keyframes`
  to {
    transform: rotate(360deg);
  }
`;

// 定义组件样式
const Ring = styled.div`
  --s: 180px; /* 将尺寸调整到100px */
  --clr: #ffe681;
  --shd1: color-mix(in lab, var(--clr), #fff5);
  --b1: 140% 145% 141% 166%;
  --b2: 147% 200% 178% 150%;
  border-radius: var(--b1);
  border: 1px solid var(--clr);
  position: fixed;
  pointer-events: none;
  z-index: 999999;
  width: var(--s);
  height: var(--s);
  top: calc(50% - var(--s) / 2);
  left: calc(50% - var(--s) / 2);
  box-sizing: border-box;
  rotate: -55deg;
  animation: ${brd1} 1s infinite linear alternate, ${rot} 5s infinite linear forwards;
  filter: drop-shadow(0 0 1vmin var(--shd1)) drop-shadow(0.1em 0.1em 0.05em var(--shd1)) drop-shadow(-0.1em -0.1em 0.05em var(--shd1));
  display: flex;
  justify-content: center;
  align-items: center;

  &:before, &:after {
    content: "";
    box-sizing: border-box;
    position: absolute;
    inset: 0;
    border: inherit;
  }

  // 文字容器，单独应用文字动画
  .text {
    position: relative;
    animation: ${textRot} 5s infinite linear;
    font-family: system-ui, sans-serif;
    font-size: calc(var(--s) / 5);
    color: var(--clr);
    text-shadow: 1px 1px 0.5em #000;
  }
`;

// 修改组件，使其接受text prop
const LoadingData = ({ text = "loading..." }) => {
  return (
    <Ring>
      <span className="text">{text}</span>
    </Ring>
  );
};

export default LoadingData;
