import React, { useState, useRef, useEffect } from 'react';
import styled from 'styled-components';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { getHostName } from '../../utils/urlUtils';
import './AskAnything.css'
import UploadFile from '../../uploadfile';
import { useSnackbar } from '../../SnackbarProvider';
import ReactMarkdown from 'react-markdown';
import ChatWindow from '../ChatWindow/ChatWindow';
import LoadingData from '../dataloading';
import { Document, Page } from 'react-pdf';
import { pdfjs } from 'react-pdf';
import CommonCombobox from '../../components/CommonCombobox';
import { Button } from '@mui/material';
import { useMediaQuery, IconButton } from '@mui/material';
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Editor from '../../mquill4/Editor';
import PhotoComponent from '../LatexModalComponent/PhotoComponent';
import { useTranslation } from 'react-i18next';
import Cropper from 'cropperjs';
import 'cropperjs/dist/cropper.css';
import { requestUrl } from '../../requestUrl';
import SystemHelp from '../SystemHelp/SystemHelp';
import { v4 as uuidv4 } from 'uuid';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { MenuItem, FormControl, Select } from '@mui/material';
import ArticleIcon from '@mui/icons-material/Article';
import { TreeView } from '@mui/x-tree-view/TreeView';
import { TreeItem } from '@mui/x-tree-view/TreeItem';
import FolderIcon from '@mui/icons-material/Folder';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile'; // 默认图标
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import AudiotrackIcon from '@mui/icons-material/Audiotrack';
import MovieIcon from '@mui/icons-material/Movie';
import DescriptionIcon from '@mui/icons-material/Description'; // 用于文档文件
import ImageIcon from '@mui/icons-material/Image'; // 用于图片文件
import {  Modal } from '@mui/material';
import { Popper, Box, Fade, Paper,TextField,Card  } from '@mui/material';
import FolderAddIcon from '@mui/icons-material/CreateNewFolder';
import FolderDeleteIcon from '@mui/icons-material/DeleteSweep';
import FolderEditIcon from '@mui/icons-material/Edit';
import FileAddIcon from '@mui/icons-material/NoteAdd';
import FileDeleteIcon from '@mui/icons-material/DeleteOutline';
import OcrIcon from '@mui/icons-material/TextSnippet';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { List, ListItem } from '@mui/material';
import { PieChart, pieArcLabelClasses } from '@mui/x-charts/PieChart';
import remarkMath from 'remark-math';
import rehypeKatex from 'rehype-katex';
import rehypeRaw from 'rehype-raw'; 
import { Drawer,Tabs ,Slide,AppBar,Stack } from '@mui/material';
import { Menu as MenuIcon } from '@mui/icons-material';
import TimelineIcon from '@mui/icons-material/Timeline'; // 时间轴字幕图标
import ChatIcon from '@mui/icons-material/Chat'; // 聊天对话图标
import LightbulbIcon from '@mui/icons-material/Lightbulb'; // 提炼知识点图标
import ListAltIcon from '@mui/icons-material/ListAlt'; // 假定这个图标用作总结内容
import EditorNoClassName from '../../mquill4/EditorNoClassName';
import CustomButton from '../../components/CustomButton';


pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  'pdfjs-dist/build/pdf.worker.min.js',
  import.meta.url,
).toString();




const MarkdownWrapper = styled.div`
    &,
    & * {
        white-space: pre-wrap;
        word-wrap: break-word;
        word-break: break-all;
        overflow-wrap: break-word;
    }

    max-width: 100%;
    overflow: auto;
    white-space: pre-wrap;
    max-height: 85vh;
    background-color: #3b99ff;
    color: white;
    font-size: 14px; 
    padding-left: 10px; 
    padding-right: 10px; 
    padding-top: 20px; 
`;




const AskAnythingNB = () => {
  const { t } = useTranslation();
  const [topFlex, setTopFlex] = useState(0.5);
  const [content, setContent] = useState([]);
  const videoRef = useRef(null);

  const [messages, setMessages] = useState([]);
  const [inputText, setInputText] = useState(''); // 文本框的值
  const [isLoading, setIsLoading] = useState(false);
  const [activeTab, setActiveTab] = useState("1");
  const [audioUrl, setAudioUrl] = useState(null); // 用于存储服务器音频 URL
  const [displaySourceDiv, setDisplaySourceDiv] = useState('');//用于确定显示video，wave，还是文档编辑器video：显示视频，audio：显示音频，md
  const audioRef = useRef(null);
  const [videoOrAudio, setVideoOrAudio] = useState('');
  const [showDiv, setShowDiv] = useState(false)
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [markdownTextSummarize, setMarkdownTextSummarize] = useState('');
  const [selectedItem, setSelectedItem] = useState(null); // 用于跟踪选中的二级菜单
  const [selectedServerFileName, setSelectedServerFileName] = useState();
  const [treeData, setTreeData] = useState([]);
  const [firstLayerKeysAndTitles, setFirstLayerKeysAndTitles] = useState([]);
  const [docInfo, setDocInfo] = useState();//文档t
  const [loadingText, setLoadingText] = useState(false);
  const [flagLoadingDocConversation, setFlagLoadingDocConversation] = useState(true);//文档对话是否加载，true加载，每次点击文件设置为：true，第一次点击
  //文档对话tab，设置为false
  const file_ext_array_doc = ['txt', 'doc', 'docx', 'md', 'pdf', 'xlsx'];
  const [selectedFolderId, setSelectedFolderId] = useState();
  const [selectedFolderTitle, setSelectedFolderTitle] = useState("");
  const [usedSpace, setUsedSpace] = useState(0.1);
  const [usagePercentage, setUsagePercentage] = useState();
  const [isLoadSummarize, setIsLoadSummarize] = useState(false);
  const [isLoadDocConversation, setIsLoadDocConversation] = useState(false);
  const [selectedFileId, setSelectedFileId] = useState();//被选中的文件ID
  const [selectedFileExt, setSelectedFileExt] = useState();//被选中的文件的扩展名
  const [open, setOpen] = useState(false);
  const [showPhotoComponent, setShowPhotoComponent] = useState(false);
  const editorRef = useRef(null);
  const [displayHistory, setDisplayHistory] = useState(false);
  const [operators, setOperators] = useState([]);
  const [isFirstLoadDoc, setIsFirstLoadDoc] = useState(false);
  const [docLanguage, setDocLanguage] = useState('none');//设置文档语言
  const [audioLanguage, setAudioLanguage] = useState('none');//设置音视频转语言

  const [
    displayDeleteButtonOnHistory,
    setDisplayDeleteButtonOnHistory
  ] = useState(false);
  const options = { displayHistory, operators, displayDeleteButtonOnHistory };

  const [imageSrc, setImageSrc] = React.useState(null); // 用于存储上传的图片
  const imageElement = useRef(null);

  const [uploadFilename, setUploadFilename] = useState('');
  const showSnackbar = useSnackbar();
  const [isShowSystemHelp, setIsShowSystemHelp] = useState(false);
  const [value, setValue] = React.useState('summ');

  const languages = [
    { id: 'en', name: 'English' },
    { id: 'zh', name: '中文' },
    { id: 'de', name: 'German' },
    // Add more languages as needed
  ];
  const [translateWordList,setTranslateWordList] = useState([]);
  const [selectedLanguage, setSelectedLanguage] = useState('en'); 
  const [openLanguage, setOpenLanguage] = useState(false);
  const [selectedNode,setSelectedNode] = useState({});
  const CUSTOM_OPERATORS = [
    ["\\pm", "\\pm"],
    ["\\sqrt{x}", "\\sqrt"],
    ["\\sqrt[3]{x}", "\\sqrt[3]{}"],
    ["\\sqrt[n]{x}", "\\nthroot"],
    ["\\frac{x}{y}", "\\frac"],
    ["\\sum^{s}_{x}{d}", "\\sum"],
    ["\\prod^{s}_{x}{d}", "\\prod"],
    ["\\coprod^{s}_{x}{d}", "\\coprod"],
    ["\\int^{s}_{x}{d}", "\\int"],
    ["\\binom{n}{k}", "\\binom"]
  ];

  const onCloseHelp = () => {

    setIsShowSystemHelp(false);
  }


  let cropper;


  const handleChangeLanguage = async (event) => {
    setSelectedLanguage(event.target.value);
    setOpenLanguage(false);


    const dataToSend = {
      source_lang: selectedNode.doc_language,
      aim_lang: event.target.value,
      md5str: selectedNode.filemd5,
    };

    console.log("TIJIAO:" + JSON.stringify(dataToSend));
    try {
      setIsLoading(true);
      const data = await requestUrl(dataToSend, "translate_audio_video_text_to_language_web");
      
      if (data.status == "success") {
          setContent(data.data);
          
      }
      if (data.status == "error") {
        showSnackbar(t('data.data'),'error');
        
      }
      setIsLoading(false);
    }
    catch (error) {
      setIsLoading(false);
    }
    // You can perform any additional actions here when the language is changed
  };

  const handleOpenLanguage = () => {
    setOpenLanguage(true);
  };

  const handleCloseLanguage = () => {
    setOpenLanguage(false);
  };

  useEffect(() => {
    if (imageSrc && imageElement.current) {
      cropper = new Cropper(imageElement.current, {
        aspectRatio: NaN,  // 允许矩形框选
        // 其他选项
      });
    }
    return () => {
      if (cropper) {
        cropper.destroy();
      }
    };
  }, [imageSrc]);


  useEffect(() => {
    setOperators(CUSTOM_OPERATORS);

  }, []);


  useEffect(() => {
    const handlePaste = (e) => {
      processClipboardData(e.clipboardData || window.clipboardData);
    };

    window.addEventListener('paste', handlePaste);

    return () => {
      window.removeEventListener('paste', handlePaste);
    };
  }, []);


  const processClipboardData = (clipboardData) => {
    const items = clipboardData.items;
    if (!items) return;

    const imageItem = Array.from(items).find(item => item.type.startsWith('image/'));
    if (imageItem) {
      const imageFile = imageItem.getAsFile();
      const reader = new FileReader();

      reader.onload = (event) => {
        setImageSrc(event.target.result);
      };

      reader.readAsDataURL(imageFile);
    }
  };



  // 动态计算容器尺寸
  const getPopupSize = () => {
    const width = window.innerWidth;
    const height = window.innerHeight;
    if (width <= 480) {  // 手机
      return { width: '100%', height: '95%' };
    } else if (width <= 1024) {  // 平板
      return { width: '70%', height: '80%' };
    } else {  // 电脑
      return { width: '60%', height: '80%' };
    }
  };

  const handleCloseSetingLanguage = () => {
    setOpen(false);
  };


  const dropdownLanguages = [
    { "id": "chinese", "text": "中文" },
    { "id": "english", "text": "英语" },
    { "id": "russian", "text": "俄语" },
    { "id": "french", "text": "法语" },
    { "id": "german", "text": "德语" },
    { "id": "japanese", "text": "日语" },
    { "id": "korean", "text": "韩语" },
    { "id": "afrikaans", "text": "南非荷兰语" },
    { "id": "arabic", "text": "阿拉伯语" },
    { "id": "armenian", "text": "亚美尼亚语" },
    { "id": "azerbaijani", "text": "阿塞拜疆语" },
    { "id": "belarusian", "text": "白俄罗斯语" }
  ];





  const getTree = async () => {
    setLoadingText(t('load catalog'));
    setIsLoading(true);
    const token = localStorage.getItem("access_token");
    try {
      const url = getHostName() + '/getuserdocs_tree'; // 你的服务端URL
      const response = await fetch(url, {
        method: "POST", // 使用POST方法
        headers: {
          "Content-Type": "application/json",
          'Authorization': `Bearer ${token}`,
        },
      });
      if (response.ok) {
        const data = await response.json();

        // 获取第一层的 key 和 title，传递给上传组件uploadfile
        const newFirstLayerKeysAndTitles = data.data.map(item => ({
          id: item.key,
          text: item.title,
        }));

        setFirstLayerKeysAndTitles(newFirstLayerKeysAndTitles);
        setTreeData(data.data)
        console.log("Space:::" + data.useSpace);
        let useSpa = data.useSpace;
        if(data.useSpace === 0){
          useSpa = 0.01;
        }
        setUsedSpace(data.useSpace);
        let aaa = ((usedSpace / 10) * 100).toFixed(2);
        setUsagePercentage(aaa);
        //console.log("尺寸:" + String(data.useSpace));
        setIsLoading(false);
      }
      else {
        console.error("Error fetching reply:", response.statusText);
        setIsLoading(false);
      }
    }
    catch (error) {
      console.error("There was an error fetching the reply:", error);
      setIsLoading(false);
    }
  };

  //根据不同的文件扩展名显示不同的div，包括源，右侧tab
  const setDisplayDivBy_fileExt = (fileExt) => {

    if (fileExt === "pdf" || fileExt == "doc" || fileExt === "docx" || fileExt === "txt" || fileExt === "md") { //文档
      setActiveTab("1");
      setDisplaySourceDiv("document");
    }
    if (fileExt === "mp4") { //视频
      setActiveTab("2");
      setDisplaySourceDiv("video");
    }

    if (fileExt === "mp3" || fileExt === "wav") { //音频频
      setActiveTab("2");
      setDisplaySourceDiv("audio");
    }
    if (fileExt === "img" || fileExt === "png") { //音频频
      //setActiveTab("2");
      setDisplaySourceDiv("mathpage");
    }
  }


  function loadWordTime(data) {

    // 循环遍历JSON数据并格式化
    const elements = data.text.map((item) => (
      <div onClick={() => handleLineClick(item.start)}>
        [{item.start}-{item.end}] {item.text}
      </div>
    ));

    // 更新组件的状态，以便在页面上显示内容
    setContent(elements);

  }
  function timeStringToSeconds(timeString) {
    const [hours, minutes, seconds] = timeString.split(':').map(parseFloat);
    return hours * 3600 + minutes * 60 + seconds;
}
  function loadWordTime1(data) {
    const parsedData = JSON.parse(data);
    console.log("songlixun:" + JSON.stringify(parsedData));
    setTranslateWordList(translateWordList);
    setContent(parsedData);
    let a = Array.isArray(parsedData);
    console.log("content is array:" + a);
    // 循环遍历JSON数据并格式化
    const elements = data.text.map((item) => (
      <div onClick={() => handleLineClick(item.start)}>
        [{item.start}-{item.end}] {item.text}
      </div>
    ));

    // 更新组件的状态，以便在页面上显示内容
    setContent(elements);

  }
  function timeStringToSeconds(timeString) {
    const [hours, minutes, seconds] = timeString.split(':').map(parseFloat);
    return hours * 3600 + minutes * 60 + seconds;
}


  function handleLineClick(start) {
    console.log(start);
    console.log(videoRef.current);
    const currentTimeInSeconds = timeStringToSeconds(start);
    if (videoOrAudio === 'v') {

      videoRef.current.currentTime = currentTimeInSeconds; // 设置开始时间
      videoRef.current.play(); // 开始播放
    }
    if (videoOrAudio === 'a') {
      audioRef.current.currentTime = currentTimeInSeconds; // 设置开始时间
      audioRef.current.play(); // 开始播放
    }
  }

  const jsonData = [
    { start: 0.58, end: 3.26, text: 'Today, an indictment was unsealed.' }, { start: 3.68, end: 7.26, text: 'Toddsfasdfay, an indictment was unsasdfasdfasdealed.' }
    // 其他对象
  ];


  const handleUpdateWordTime = async () => {
    setLoadingText(t('updating'));
    setIsLoading(true);
    const payload = {
      id: docInfo.id,
      data: content
    };

    try {
      const data = await requestUrl(payload, "update_audio_video_subtitling");
      setLoadingText("");
      setIsLoading(false);
      
      showSnackbar(t('updates success'),'success');
      //console.log("TTTTTTTT:" + data.data.translate_words);

    }
    catch (error) {
      setLoadingText("");
      setIsLoading(false);
    }
  }


  // 当 audioUrl 更改时，自动播放音频
  useEffect(() => {
    if (audioUrl && audioRef.current) {
      audioRef.current.load(); // 重新加载 <audio> 元素以更新 src
      audioRef.current.play();
    }
  }, [audioUrl]);



  {/*
    docInfo 数据结构
  
    id = Column(Integer, primary_key=True, autoincrement=True)
    user_id = Column(Integer, nullable=False)
    user_folder_id = Column(Integer)
    username = Column(String(200))
    name = Column(String(255), nullable=False)
    server_file_name = Column(String(300))
    path = Column(String(400))
    file_extension = Column(String(50))
    size = Column(BigInteger)
    created_at = Column(DateTime)
    updated_at = Column(DateTime)
    filemd5 = Column(String(200))
    translate_words = Column(Text)
    translate_words_text = Column(Text)
    is_upload_alioss = Column(Boolean)
    ali_oss_url = Column(String(200))
    vector_name = Column(String(100)) */}

  //点击左侧文件事件
  const handleSubItemClick = async (item, subItem) => {
    console.log("song");
    setLoadingText(t('load document'));
    setFlagLoadingDocConversation(true);

    console.log(subItem.title);
    setSelectedItem(subItem.title);
    setMessages([]);
    const parts = subItem.key.split("-");
    //const fileid = parts[1]; // "part2"
    const fileid = subItem.id;
    console.log(fileid);
    const fileExt = subItem.file_extension;
    const server_file_name = subItem.server_file_name;
    setSelectedServerFileName(server_file_name);
    setSelectedFileId(fileid);
    setSelectedFileExt(fileExt);

    setDocLanguage(subItem.doc_language);
    if (subItem.doc_language === "none") {
      setIsFirstLoadDoc(true);
      setOpen(true);
      return;
    }

    load_doc_info(fileid, fileExt, isFirstLoadDoc, docLanguage, server_file_name);
  };

  const handleSetingLanguageOK = () => {
    setOpen(false);

    load_doc_info(selectedFileId, selectedFileExt, isFirstLoadDoc, docLanguage, selectedServerFileName);
    setIsFirstLoadDoc(false);
  };


  function processMathString(inputString) {
    let ops = [];
    let tempString = '';
    let inFormula = false;

    for (let i = 0; i < inputString.length; i++) {
      const c = inputString[i];
      if (c === '$') {
        if (inFormula) {
          ops.push({ "insert": { "formula": tempString } });
          tempString = '';
          inFormula = false;
        } else {
          if (tempString.length > 0) {
            ops.push({ "insert": tempString });
            tempString = '';
          }
          inFormula = true;
        }
      } else {
        tempString += c;
      }
    }

    if (tempString.length > 0) {
      ops.push({ "insert": tempString });
    }

    return {
      "ops": ops
    };
  }

  const load_doc_info = async (fileid, fileExt, isFirstLoadDoc, docLanguage, server_file_name) => {
    setIsLoading(true);
    let doc_url = "";

    let dinfo;
    setDisplayDivBy_fileExt(fileExt);

    //docLanguage  文档语言，如果时文档，使用docLanguage，如果音视频,使用audioLanguage，因为最终音视频转录也是文字，到了后台要判断
    const dataToSend = {
      id: fileid,
      fileExt: fileExt,
      isFirstLoad: isFirstLoadDoc,
      docLanguage: docLanguage,
    };

    let imgText = "";
    try {
      const data = await requestUrl(dataToSend, "get_docs_info");
      if (data.status == "success") {
        setDocInfo(data.data);
        dinfo = data.data;
        //console.log("docInfo,text:::" + dinfo.translate_words_text);
        imgText = dinfo.translate_words_text;
        doc_url = data.data.ali_oss_url;
        //setContent(data.data.translate_words);

        if(data && data.data && data.data.translate_words != null){
          setContent(data.words);
        }
        
      }
      if (data.status == "error") {
        showSnackbar(t('data.data'),'error');
        
      }

    }
    catch (error) {
      setIsLoading(false);
    }

    if (fileExt === "mp4" || fileExt === "av") { //音频
      videoRef.current.src = doc_url;
      //videoRef.current.play();
      setVideoOrAudio('v');
      console.log("song::L:::");
      //loadWordTime(data.data.translate_words);
    }

    if (fileExt === "mp3" || fileExt === "wav") { //音频
      const serverAudioUrl = "userdocs/" + server_file_name; // 这里应该是动态获取的 URL
      setAudioUrl(serverAudioUrl);

      setVideoOrAudio('a');
      //loadWordTime(data.data.translate_words);
    }

    if (fileExt === "pdf" || fileExt == "doc" || fileExt === "docx" || fileExt === "txt" || fileExt === "md") { //文档
      //setDocumentText(dinfo.translate_words_text);

      setDisplaySourceDiv("document");
      const fetchedContentElement = await fetchContent(doc_url, fileExt);
      setContentElement(fetchedContentElement); // 将获取的React元素设置为状态
    }

    if (fileExt === "png" || fileExt === "jpg") { //图片内容
      setDisplaySourceDiv("mathpage");
      const initialDelta = processMathString(imgText);

      if (editorRef.current) {
        editorRef.current.setFullContents(initialDelta);
      }

      //const fetchedContentElement = await fetchContent(doc_url, fileExt);
      //setContentElement(fetchedContentElement); // 将获取的React元素设置为状态
    }

    setIsLoading(false);
  }


  const load_doc_info1 = async (fileid, fileExt, isFirstLoadDoc, docLanguage, server_file_name) => {
    setIsLoading(true);
    let doc_url = "";

    let dinfo;
    setDisplayDivBy_fileExt(fileExt);

    //docLanguage  文档语言，如果时文档，使用docLanguage，如果音视频,使用audioLanguage，因为最终音视频转录也是文字，到了后台要判断
    const dataToSend = {
      id: fileid,
      fileExt: fileExt,
      isFirstLoad: isFirstLoadDoc,
      docLanguage: docLanguage,
    };

    let imgText = "";
    try {
      const data = await requestUrl(dataToSend, "get_docs_info");
      if (data.status == "success") {
        setDocInfo(data.data);
        dinfo = data.data;
        imgText = dinfo.translate_words_text;
        doc_url = data.data.ali_oss_url;
        
        if(data && data.data && data.data.translate_words != null){
          loadWordTime(data.data.translate_words);
        }
        
      }
      if (data.status == "error") {
        showSnackbar(t('data.data'),'error');
        
      }

    }
    catch (error) {
      setIsLoading(false);
    }

    if (fileExt === "mp4" || fileExt === "av") { //音频
      videoRef.current.src = doc_url;
      //videoRef.current.play();
      setVideoOrAudio('v');
    }

    if (fileExt === "mp3" || fileExt === "wav") { //音频
      const serverAudioUrl = "userdocs/" + server_file_name; // 这里应该是动态获取的 URL
      setAudioUrl(serverAudioUrl);

      setVideoOrAudio('a');
      //loadWordTime(data);
    }

    if (fileExt === "pdf" || fileExt == "doc" || fileExt === "docx" || fileExt === "txt" || fileExt === "md") { //文档
      //setDocumentText(dinfo.translate_words_text);

      setDisplaySourceDiv("document");
      const fetchedContentElement = await fetchContent(doc_url, fileExt);
      setContentElement(fetchedContentElement); // 将获取的React元素设置为状态
    }

    if (fileExt === "png" || fileExt === "jpg") { //图片内容
      setDisplaySourceDiv("mathpage");
      const initialDelta = processMathString(imgText);

      if (editorRef.current) {
        editorRef.current.setFullContents(initialDelta);
      }

      //const fetchedContentElement = await fetchContent(doc_url, fileExt);
      //setContentElement(fetchedContentElement); // 将获取的React元素设置为状态
    }

    setIsLoading(false);
  }


  useEffect(() => {
    getTree();
  }, []);

  const handleAddfiles = () => {
    setOpenMenu(false);
    setShowDiv(!showDiv)

  };
  const handleClose = () => {
    setShowDiv(false);

  };

  const [isModalVisibleDocLanguage, setIsModalVisibleDocLanguage] = useState(false);
  const [isModalVisibleEditFolder, setIsModalVisibleEditFolder] = useState(false);
  const [folderNameEdit, setFolderNameEdit] = useState('');

  const handleOkEditFolder = async () => {
    if (!/^[\u4e00-\u9fa5a-zA-Z0-9]+$/.test(folderNameEdit) && folderNameEdit !== "") {
      showSnackbar(t('invalid input, please enter chinese, English or numeric characters'),'waring');
      
      return;
    }

    setLoadingText(t('saving'));
    setIsLoading(true);

    // 你可以在这里处理添加文件夹的逻辑

    const dataToSend = {
      folderId: selectedFolderId,
      folderName: folderNameEdit,
    };
    let newKey;
    try {

      const res = await requestUrl(dataToSend, "edit_user_folder");

      const updatedData = treeData.map(item => {
        if (item.key === selectedFolderId) {
          return { ...item, title: folderNameEdit };
        }
        return item;
      });
      setTreeData(updatedData);

      setLoadingText("");
      setIsLoading(false);
    }
    catch (error) {
      setLoadingText("");
      setIsLoading(false);
    }
    setIsModalVisibleEditFolder(false);
    setFolderNameEdit('');
  };

  const handleCancelEditFolder = () => {
    setIsModalVisibleEditFolder(false);
    setFolderNameEdit('');
  };


  //编辑目录点击
  const handleEditFolder = () => {
    setOpenMenu(false);
    setFolderNameEdit(selectedFolderTitle);
    setIsModalVisibleEditFolder(true);

  };

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [folderName, setFolderName] = useState('');

  //添加文件夹
  const handleOk = async () => {
    console.log("foldername:" + folderName);
    
    if (!/^[\u4e00-\u9fa5a-zA-Z0-9]+$/.test(folderName) && folderName === "") {
      showSnackbar(t('invalid input, please enter chinese, English or numeric characters'),'warning');
      
      return;
    }

    setLoadingText(t('adding'));
    setIsLoading(true);

    // 你可以在这里处理添加文件夹的逻辑
   
    const dataToSend = {
      folderName: folderName,
    };
    let newKey;
    try {

      newKey = await requestUrl(dataToSend, "add_user_folder");

      setLoadingText("");
      setIsLoading(false);
    }
    catch (error) {
      setLoadingText("");
      setIsLoading(false);
    }

    const newNode = {
      key: newKey,
      title: folderName,
      children: null,
    };
    setTreeData([...treeData, newNode]);
    setIsModalVisible(false);
    setFolderName('');
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setFolderName('');
    
  };

  const handleAddFolder = () => {
    setOpenMenu(false);
    setIsModalVisible(true);
  };









  const [openDialogDeleteFolder, setOpenDialogDeleteFolder] = React.useState(false);

  const handleDeleteFolder = () => {
    setOpenMenu(false);
    let folderKey = selectedFolderId;
    treeData.map(folder => {
      if (folder.key === folderKey) {
        if (folder.children) {
          showSnackbar(t('exists file,cannot be deleted'), 'warning');
        } else {
          setOpenDialogDeleteFolder(true);
        }
      }
    });
  };


  const handleCloseDialogDeleteFolder = () => {
    setOpenDialogDeleteFolder(false);
  };


  const handleConfirmDeleteFolder = async () => {
    // 执行删除目录操作
    setIsLoading(true);
    const token = localStorage.getItem("access_token");
    const url = getHostName() + '/delete_user_folder'; // 你的服务端URL
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
      body: JSON.stringify({ folder_id: selectedFolderId })
    });

    if (!response.ok) {
      throw new Error(`An error occurred: ${response.statusText}`);
      setIsLoading(false);
    }

    let data = await response.json();
    const updatedTree = treeData.filter(node => node.key !== selectedFolderId);
    setTreeData(updatedTree);

    setOpenDialogDeleteFolder(false);
    setIsLoading(false);
  };





  const handleDeleteFolder2222 = () => {

    setOpenMenu(false);

    let folderKey = selectedFolderId;
    treeData.map(folder => {
      if (folder.key === folderKey) {
        if (folder.children) {
          
           showSnackbar(t('exists file,cannot be deleted'),'warning');
        }
        else {// 删除目录
          Modal.confirm({
            title: t('determine the directory to delete') + '<<' + selectedFolderTitle + '>>?',
            icon: <ExclamationCircleOutlined />,
            content: t('delete directory'),
            okText: t('delete'),
            okType: 'danger',
            cancelText: t('cancel'),
            async onOk() {
              // 执行删除操作
              const token = localStorage.getItem("access_token");
              const url = getHostName() + '/delete_user_folder'; // 你的服务端URL
              const response = await fetch(url, {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json',
                  'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify({ folder_id: selectedFolderId })
              });

              if (!response.ok) {
                throw new Error(`An error occurred: ${response.statusText}`);
              }

              let data = await response.json();
              const updatedTree = treeData.filter(node => node.key !== folderKey);
              setTreeData(updatedTree);

            },
            onCancel() {

            },
          });
        }
      }

    });
  };


  //文本拍照识别
  const handleOCRText = async () => {
    setOpenMenu(false);
    if (selectedFolderId) {
      //setShowPopup(true);
      setShowPhotoComponent(true);
    }
    else {
      showSnackbar(t('select a category','warning'));
    }

  }
  const onClosePhotoCom = (updateLatexText) => {
    setShowPhotoComponent(false);
  };


  const handleDeleteFile1 = async () => {
    setOpenMenu(false);
    Modal.confirm({
      title: t('determine the file to delete') + '<<' + docInfo.name + '>>?',
      icon: <ExclamationCircleOutlined />,
      content: t('once deleted, the file cannot be recovered'),
      okText: t('delete'),
      okType: 'danger',
      cancelText: t('cancel'),
      async onOk() {
        // 执行删除操作
        const token = localStorage.getItem("access_token");
        const url = getHostName() + '/delete_user_doc'; // 你的服务端URL
        const response = await fetch(url, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
          },
          body: JSON.stringify({ file_id: selectedFileId })
        });

        if (!response.ok) {
          throw new Error(`An error occurred: ${response.statusText}`);
        }

        let data = await response.json();
        let filekey = docInfo.user_folder_id + '-' + docInfo.id;
        deleteFileFromFolder(String(docInfo.user_folder_id), filekey);
        console.log(t('document deleted'));
      },
      onCancel() {
        console.log(t('cancel delete'));
      },
    });
  };



  const [openDialog, setOpenDialog] = React.useState(false);
  const handleDeleteFile = async () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };




  const deleteFileFromFolder = (folderKey, fileKey) => {
    let deleted = false;
    const newTreeData = treeData.map(folder => {
      if (folder.key === folderKey) {

        const filteredChildren = (folder.children || []).filter(file => {

          if (file.key === fileKey) {
            deleted = true;
            return false;
          }
          return true;
        });
        return { ...folder, children: filteredChildren.length > 0 ? filteredChildren : null };
      }
      return folder;
    });

    if (deleted) {
      console.log(`Deleted file with key: ${fileKey}`);
    } else {
      console.log(`File with key: ${fileKey} was not found in folder with key: ${folderKey}`);
    }

    // 更新state
    setTreeData(newTreeData);

  };

  const handleUploadedFiles = (files) => {
    setUploadedFiles(files);
  };

const handleConfirmDelete = async () => {
    // 执行删除操作
    const token = localStorage.getItem("access_token");
    const url = getHostName() + '/delete_user_doc'; // 你的服务端URL
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
      body: JSON.stringify({ file_id: selectedFileId })
    });

    if (!response.ok) {
      throw new Error(`An error occurred: ${response.statusText}`);
    }

    let data = await response.json();
    let filekey = docInfo.user_folder_id + '-' + docInfo.id;
    deleteFileFromFolder(String(docInfo.user_folder_id), filekey);
    console.log(t('document deleted'));

    setOpenDialog(false);
  };



  {/*
    这是uploadedFiles的数据结构，从uploadfile组件传递过来
    id:0, //这是数据库自增id，此时还没有值
    name: file.name,
    size: file.size,
    folderId: folderId,
    md5str: md5Results[index],
    needs_upload:false,
    server_file_name:"",
    file_extension:"",
    doc_language:""*/}

  {/* treedata 结构         
    "key": str(folder.id),
    "title": folder.folder_name,
    "children": []*/}

  {/*   treedata children 数据结构

      "key": f"{folder.id}-{doc.id}",
      "title": doc.name,
      "path": doc.path,
      "file_extension": doc.file_extension,
      "server_file_name": doc.server_file_name,
      "doc_language": doc.doc_language
  */}
  useEffect(() => {
    // 获取 uploadedFiles 中的 folderId（假设所有 uploadedFiles 有相同的 folderId）
    console.log("update tree:");
    console.log("uploadedFiles:::" + JSON.stringify(uploadedFiles));
    const folderId = uploadedFiles[0]?.folderId;
    // 创建一个 treeData 的副本以进行修改
    const treeDataCopy = [...treeData];
    // 遍历 treeData 副本
    const updatedTreeData = treeDataCopy.map((item) => {
      if (item.key === String(folderId)) {
        // 如果找到匹配的 key，将 uploadedFiles 的数据添加到该项
        const newChildren = uploadedFiles.map((file) => {
          return {
            id:file.dbid,
            key: folderId + "-" + String(file.dbid),  // treeData 的 key 对应 uploadedFiles 的 dbid
            title: file.name,
            file_extension: file.file_extension,
            server_file_name: file.server_file_name,
            doc_language: file.doc_language,
          };
        });

        // 如果 item.children 存在，将 newChildren 添加到现有的 children，否则直接设置为 newChildren
        const updatedItem = {
          ...item,
          children: item.children ? [...item.children, ...newChildren] : newChildren
        };

        return updatedItem;
      }
      return item;
    });

    // 使用 setTreeData 一次性更新状态
    setTreeData(updatedTreeData);

  }, [uploadedFiles]);

  const [contentElement, setContentElement] = useState(null); // 初始化为null

  const [numPages, setNumPages] = useState(null);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  //type 文件扩展名
  async function fetchContent(url, type) {
    try {
      const response = await fetch(url);
      const text = await response.text();
      switch (type) {
        case 'md':
          return <ReactMarkdown
                  children={text}
                  remarkPlugins={[remarkMath]}
                  rehypePlugins={[rehypeRaw, rehypeKatex]} // 注意插件的顺序，rehypeRaw 先于 rehypeKatex
                />;
        case 'txt':
          return <ReactMarkdown
                    children={text}
                    remarkPlugins={[remarkMath]}
                    rehypePlugins={[rehypeRaw, rehypeKatex]} // 注意插件的顺序，rehypeRaw 先于 rehypeKatex
                  />;
        case 'pdf':
          return (
            <MarkdownWrapper>
              <Document
                file={url}
                onLoadSuccess={onDocumentLoadSuccess}
                onError={(error) => console.error('Error while loading document:', error)}
              >
                {Array.from(new Array(numPages), (el, index) => (
                  <Page key={`page_${index + 1}`} pageNumber={index + 1} />
                ))}
              </Document>
            </MarkdownWrapper>
          );
        default:
          return <div>Unsupported file type.</div>;
      }
    } catch (error) {
      return <div>Error: {error.message}</div>;
    }
  }


  //文本总结
  const handleTextSummarize = async () => {

    if (!selectedFileId) {
      showSnackbar(t('please select a file'),'warning');
      
      return;
    }
    const token = localStorage.getItem("access_token");
    const url = getHostName() + '/text_summarize_by_file_id'; // 你的服务端URL
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
      body: JSON.stringify({ file_id: selectedFileId })
    });

    if (!response.ok) {
      throw new Error(`An error occurred: ${response.statusText}`);
    }

    let data = await response.json();

    if (data.status === "success") {
      let summarize = data.data;
      console.log(summarize);
      setMarkdownTextSummarize(summarize);
    }
    else {
      
      showSnackbar(t(data.data),'warning');
    }

  };

  const handleSendMessage = async (message) => {
    if (!selectedFileId) {
      showSnackbar( t('please select a file'),'warning');
      
      return;
    }

    setMessages(prevMessages => [...prevMessages, { sender: 'S', text: message, time: new Date().toLocaleTimeString() }]);
    setLoadingText(t('waiting for a reply'));
    setIsLoading(true);

    console.log("isloading:" + isLoading);
    const dataToSend = {
      server_file_name: selectedServerFileName,
      question: message,
      doc_language: docLanguage,
      doc_id: selectedFileId,
    };


    const token = localStorage.getItem("access_token");
    const url = getHostName() + '/ask_doc'; // 你的服务端URL
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
      body: JSON.stringify(dataToSend),
    });

    if (!response.ok) {
      setIsLoading(false);
      throw new Error(`An error occurred: ${response.statusText}`);
    }

    let data = await response.json();
    setMessages(prevMessages => [...prevMessages, { id: 1, sender: 'R', text: data.data, time: new Date().toLocaleTimeString() }]);
    setIsLoading(false);

  }

  const handleOCRImage = async (base64Image) => {
    setDisplayDivBy_fileExt("png");
    let filenameimg = uploadFilename;
    if (uploadFilename == "") {
      filenameimg = uuidv4() + ".png";
      setUploadFilename(filenameimg);
    }

    const base64data = base64Image;
    const dataToSend = {
      name: filenameimg,
      folderId: selectedFolderId,
      image: base64data,
    };
    setIsLoading(true);
    const data = await requestUrl(dataToSend, "getimagetext");
    const mathtext = data.message;
    const dbid = data.dbid;
    const uploadImage = [{
      dbid: dbid,
      name: filenameimg,
      size: "",
      folderId: selectedFolderId,
      md5str: "",
      needs_upload: false,
      server_file_name: "",
      file_extension: "png",
      doc_language: "",
    }]

    setUploadedFiles(uploadImage);

    const initialDelta = processMathString(mathtext);

    if (editorRef.current) {
      editorRef.current.setFullContents(initialDelta);
    }    
    setIsLoading(false);
  }



  const handleOCRImage1 = async (base64Image) => {
    console.log("folderID:" + selectedFolderId);
    setDisplayDivBy_fileExt("png");

    let filenameimg = uploadFilename;
    if (uploadFilename == "") {
      filenameimg = uuidv4() + ".png";
      setUploadFilename(filenameimg);
    }

    const base64data = base64Image;
    const dataToSend = {
      name: filenameimg,
      folderId: selectedFolderId,
      image: base64data,
    };

    const data = await requestUrl(dataToSend, "getimagetext");
    const mathtext = data.message;
    const dbid = data.dbid;
    console.log("dbid:" + dbid);
    const uploadImage = [{
      dbid: dbid,
      name: filenameimg,
      size: "",
      folderId: selectedFolderId,
      md5str: "",
      needs_upload: false,
      server_file_name: "",
      file_extension: "png",
      doc_language: "",
    }]
    console.log("uploadImage:::" + JSON.stringify(uploadImage));
    setUploadedFiles(uploadImage);
    console.log("mathtext:::" + mathtext);
    



    const initialDelta = {
      "ops": [
        { "insert": "手动阀十分下" },
        { "insert": { "formula": "x^2" } },
        { "insert": " \n" }
      ]
    };

    if (editorRef.current) {
      console.log("editorRefcurrent:::");
      editorRef.current.setFullContents(initialDelta);
    }



    
    setIsLoading(false);

  }
  const handleTestttt = async () => {
  
    const initialDelta = {"ops":[{"insert":"（2）设函数 "},{"insert":{"formula":"f(x)=5 \\sin \\left(-2 x+\\frac{\\pi}{2}+\\alpha\\right)+2 \\cos ^{2} x\\left(x \\in\\left[\\frac{\\pi}{24}, \\frac{\\pi}{2}\\right]\\right)"}},{"insert":", 求 "},{"insert":{"formula":"\\mathrm{x}"}},{"insert":" 为何值时, "},{"insert":{"formula":"f(x)"}},{"insert":" 取得最\n大值, 最大\n值是多少, 并求 "},{"insert":{"formula":"f(x)"}},{"insert":" 的单调增区间。"}]};

    if (editorRef.current) {
      editorRef.current.setFullContents(initialDelta);
    }
  }

function RenderTreeItem({ node, level = 0 }) {
  const isSecondLevel = level === 1; // 判断是否为第二层节点

  const itemSx = {
    color: 'black',
    '& > .MuiTreeItem-content': {
      marginBottom: '5px',
    },
  };

  // 选择图标
  const icon = level === 0 ? <FolderIcon sx={{color:'#d68438'}}/> : getFileIcon(node.file_extension);

  return (
      <TreeItem 
        key={node.key} 
        nodeId={node.key} 
        label={
          <div style={{ display: 'flex', alignItems: 'center', padding: '0px' }}>
            {icon}
            <div style={{ marginLeft: '8px' }}>{node.title}</div>
          </div>
        } // 在这里添加图标和标题
        sx={itemSx}
        onClick={isSecondLevel ? (event) => handleSubItemClick(node, node) : undefined} // 仅第二层节点添加点击事件
      >
          {Array.isArray(node.children) ? node.children.map((childNode) => (
              <RenderTreeItem key={childNode.key} node={childNode} level={level + 1} />
          )) : null}
      </TreeItem>
  );
}

// 根据文件扩展名选择图标
const getFileIcon = (fileExtension) => {
  switch (fileExtension) {
    case 'md':
      return <ArticleIcon sx={{color:'#2a3d66'}}/>;
    case 'docx':
      return <DescriptionIcon sx={{color:'#003366'}}/>;
    case 'xlsx':
      return <DescriptionIcon sx={{color:'#003366'}} />; // 假设用相同图标表示文档和表格
    case 'pdf':
      return <PictureAsPdfIcon sx={{color:'red'}} />;
    case 'mp3':
      return <AudiotrackIcon sx={{color:'#342056'}}/>;
    case 'mp4':
      return <MovieIcon sx={{color:'#5454c5'}}/>;
    case 'png':
    case 'jpg':
    case 'jpeg':
      return <ImageIcon sx={{color:'#555c78'}}/>;
    default:
      return <InsertDriveFileIcon />;
  }
};


const handleNodeSelect = (event, nodekey) => {
  if (nodekey.indexOf('-') !== -1) {
    const parts = nodekey.split("-");
    const folderid = parts[0]; // "part2"
    
    setSelectedFolderId(folderid);
  }
  else{
    setSelectedFolderId(nodekey);
  }
  
  console.log("Selected node ID (key):", nodekey);
  const nodeData = findNodeByKey(treeData, nodekey);
  setSelectedNode(nodeData);
  console.log("SSSSSSS:::" + JSON.stringify(nodeData));
  setSelectedFolderTitle(nodeData.title);
  // 在这里你可以根据nodeId进行进一步处理
};

// 辅助函数：递归查找节点
const findNodeByKey = (nodes, key) => {
  for (let node of nodes) {
      if (node.key === key) {
          return node; // 找到了，直接返回
      }
      if (node.children) {
          const found = findNodeByKey(node.children, key);
          if (found) {
              return found; // 在子节点中找到
          }
      }
  }
  return null; // 没找到
};

const [anchorEl, setAnchorEl] = React.useState(null);
const [openMenu, setOpenMenu] = React.useState(false);
const [placement, setPlacement] = React.useState();

const handleClickMenu = (newPlacement) => (event) => {
  setAnchorEl(event.currentTarget);
  setOpenMenu((prev) => placement !== newPlacement || !prev);
  setPlacement(newPlacement);
};


const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const handleTextChange = (index, newText) => {
  const updatedContent = content.map((item, i) =>
    i === index ? { ...item, text: newText } : item
  );
  setContent(updatedContent);
};

const [openRightMenu, setOpenRightMenu] = useState(false);

const handleTabChange = async (event, newValue) => {
  
  //setOpenRightMenu(!openRightMenu);
  if (!selectedFileId) {//没有选中左侧文件
    showSnackbar(t('please select a file'),'warning');
    return;
  }
  setOpenRightMenu(true);
  // 根据openRightMenu的新状态调整主内容区域的宽度
  setMainContentWidth('calc(100% - 400px)'); // 假设右侧Slide宽度为600px


  if (newValue === "doccon") { //文档对话
    console.log("文档对话");
    if (isLoadDocConversation === true) {
      setValue(newValue);
      return;
    }
    if (flagLoadingDocConversation === true) {

      setFlagLoadingDocConversation(false);//
      setLoadingText(t('prepare the document'));
      setIsLoading(true);
      setValue(newValue);



      const dataToSend = {
        file_id: selectedFileId,
      };

      const data = await requestUrl(dataToSend, "begin_doc_conversation");

      if (data.status === "success") {
        
        showSnackbar(t('ready'),'success');
        if (data.type === "record") {//有文档对话记录
          console.log(data.data);
          setMessages(data.data);

          setIsLoadDocConversation(true);//文档对话加载完成，切换后不需要要重新请求
        }
        setIsLoading(false);
      }
      setIsLoading(false);
    }
  }

  if (newValue === "time") {
    let rrr = file_ext_array_doc.includes(docInfo.file_extension);
    if (rrr) { showSnackbar('warning', t('please select audio or video file'));  return; }
  }

  if (newValue === "summ") {
    if (isLoadSummarize === true) {
      setValue(newValue);
      return;
    }
    handleTextSummarize();
  }

  setValue(newValue);
};

const [mainContentWidth, setMainContentWidth] = useState('100%'); // 控制主内容区域的宽度

const handleFixRight = () => {
  if(openRightMenu){
    setOpenRightMenu(false);
    setMainContentWidth('100%');
  }
  else{
    handleTabChange(null,"doccon")
  }
  
};


const data = [
  { label: 'used space', value: usedSpace, color: 'red' },
  { label: 'unused space', value: 1-usedSpace, color: '#00C49F' },
];

const TOTAL = data.map((item) => item.value).reduce((a, b) => a + b, 0);

const getArcLabel = (params) => {
  const percent = params.value / TOTAL;
  return `${(percent * 100).toFixed(0)}%`;
};

const sizing = {
  margin: { right: 5 ,top:10},
  width: 160,
  height: 160,
  legend: { hidden: true },
};


//////////////////////////////////////////////
const [showSubtitles, setShowSubtitles] = useState(false);
const [currentSubtitle, setCurrentSubtitle] = useState('');
// Function to handle video time update
// Function to handle video time update
function handleTimeUpdate() {
  console.log("handleTimeUpdate:");
  if (!showSubtitles) return;
 

  const currentTime = videoRef.current.currentTime;
  const foundSubtitle = content.find(subtitle =>
    currentTime >= timeStringToSeconds(subtitle.start) &&
    currentTime <= timeStringToSeconds(subtitle.end)
  );
  // Update displayed subtitle
  if (foundSubtitle) {
    setCurrentSubtitle(foundSubtitle.text);
  } else {
    setCurrentSubtitle('');
  }
}

// Effect to add event listener for time update when component mounts
/*
useEffect(() => {
  videoRef.current.addEventListener('timeupdate', handleTimeUpdate);
  return () => {

    videoRef.current.removeEventListener('timeupdate', handleTimeUpdate);
  };
}, [showSubtitles, content]);



*/

useEffect(() => {
  const videoElement = videoRef.current;
  if (videoElement) {
    videoElement.addEventListener('timeupdate', handleTimeUpdate);
    return () => {
      videoElement.removeEventListener('timeupdate', handleTimeUpdate);
    };
  }
}, [showSubtitles, content]);

// Function to toggle subtitle display
function toggleSubtitles() {
  setShowSubtitles(!showSubtitles);
}



  return (
    <Box position="relative" height="100%">
      <Box
        sx={{
          height: "90vh", // 默认高度设置为95vh，如果需要根据不同屏幕调整，可以使用theme.breakpoints.down/up
          backgroundColor: "#fff",
          display: 'flex',
          overflow: 'auto',
          width: mainContentWidth, // 使用状态控制宽度
          // 添加其他需要的样式
        }}
      >
        {/* showLeft && */}
        {(
          <Box
          sx={{
            width: "15vw", // 直接设置宽度为15vw
            height: "100%",
            backgroundColor: "white",
            position: "static", // 或者根据需要设置为"absolute"
            zIndex: 2000,
            // 添加其他需要的样式
          }}
        >
            <Box
              sx={{
                overflow: 'hidden',
                transition: 'all 0.5s ease-in-out',
                position: 'relative',
                height: '100%',
                borderRight: '1px solid #e8e8e8',
              }}
            >
              <TreeView
                sx={{
                  marginTop: '10px',
                  overflow: 'auto',
                  maxHeight: 600, // 指定一个最大高度
                  '& .MuiTreeView-root': { // 针对TreeView根元素的额外样式调整
                    overflowY: 'auto' // 确保Y轴（垂直方向）上的溢出内容是可滚动的
                  }
                }}
                defaultCollapseIcon={<ExpandMoreIcon />}
                defaultExpandIcon={<ChevronRightIcon />}
                onNodeSelect={handleNodeSelect} // 添加这行
              >
                {treeData.map((node) => (
                    <RenderTreeItem key={node.key} node={node} />
                ))}
              </TreeView>

              <Box
                  sx={{
                    position: 'absolute', // 绝对定位相对于父容器
                    bottom: 10, // 紧贴父容器底部，设置 10px 间隔
                    left: 0,
                    right: 0,
                    height: '300px',
                    backgroundColor: '#f9f7f7', // 背景颜色
                    borderRadius: '10px',
                    marginLeft: '5px',
                    marginRight: '5px',
                    display: 'flex',         // 使用flex布局
                    flexDirection: 'column', // 子元素竖直排列
                    justifyContent: 'center', // 子元素竖直居中
                    alignItems: 'center', 
                    gap: '10px',             // 设置竖直间隔为10px
                    paddingBottom: '20px', // 底部内边距设置为20px，为Button留出空间
                  }}
                >
                  <Box
                    sx={{
                      flexGrow: 1, // 让这个Box填充可用空间，以推动Button向底部
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center', // 确保内容在这个Box内部也是居中的
                      alignItems: 'center', 
                      gap: '10px', // 维持间隔
                    }}
                  >
                  <PieChart
                    series={[
                      {
                        outerRadius: 80,
                        data,
                        arcLabel: getArcLabel,
                      },
                    ]}
                    sx={{
                      [`& .${pieArcLabelClasses.root}`]: {
                        fill: 'white',
                        fontSize: 14,
                      },
                    }}
                    {...sizing}
                  />

                
                  </Box>
                  {/* 将Button放在Box外面，利用flexGrow推动它到底部 */}
                  <CustomButton 
                    sx={{
                      width: '100%', 
                      position: 'absolute', // 绝对定位
                      bottom: '0px', // 距底部20px
                    }} 
                    onClick={handleClickMenu('top')}
                  >
                    {t('operator')}
                  </CustomButton>
                  <Popper
                    // Note: The following zIndex style is specifically for documentation purposes and may not be necessary in your application.
                    sx={{ zIndex: 9999 }}
                    open={openMenu}
                    anchorEl={anchorEl}
                    placement={placement}
                    transition
                  >
                    {({ TransitionProps }) => (
                      <Fade {...TransitionProps} timeout={350}>
                        <Paper sx={{ display: 'flex', flexDirection: 'column', p: 1,width:'100%' }}>
                          {/* 在这里添加带有图标和文字的按钮 */}
                          <CustomButton onClick={handleAddFolder} startIcon={<FolderAddIcon />}>{t('add directory')}</CustomButton>
                          <CustomButton onClick={handleDeleteFolder} startIcon={<FolderDeleteIcon />}>{t('delete directory')}</CustomButton>
                          <CustomButton onClick={handleEditFolder} startIcon={<FolderEditIcon />}>{t('modify directory')}</CustomButton>
                          <CustomButton onClick={handleAddfiles} startIcon={<FileAddIcon />}>{t('add file')}</CustomButton>
                          <CustomButton onClick={handleDeleteFile} startIcon={<FileDeleteIcon />}>{t('delete file')}</CustomButton>
                          <CustomButton onClick={handleOCRText} startIcon={<OcrIcon />}>{t('text OCR')}</CustomButton>
                          <CustomButton onClick={handleTestttt} startIcon={<OcrIcon />}>{t('test')}</CustomButton>
                        </Paper>
                      </Fade>
                    )}
                  </Popper>
              </Box>

            </Box>
          </Box>
        )}
        <Box
          sx={{
            flexGrow: 1, // 使元素能够填充可用空间
            height: '100%', // 高度设置为100%
            // 可以根据需要添加更多样式
          }}
        >
          
          <Box
            sx={{
              flex: 1,
              overflow: 'hidden',
              flex: topFlex, // 动态样式
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              marginTop: '5px',
              marginRight: '10px',
            }}>
              {(() => {
                switch (displaySourceDiv) {
                  case "mathpage":
                    return (
                      <div 
                      style={{ width: '100%', height: '80vh', color: 'black', justifyContent: 'center', alignItems: 'center', overflow: 'auto', whiteSpace: 'pre-wrap' }}>
                        <EditorNoClassName ref={editorRef} options={options} key={JSON.stringify(options)} width="100%" height="80vh"/>
                    </div>
                    );
                  case "document":
                    return (
                      <div style={{ width: '100%', justifyContent: 'center', alignItems: 'center', overflow: 'auto', whiteSpace: 'pre-wrap' }}>
                        {contentElement}
                      </div>
                    );
                  default:
                    return (
                      <div style={{ width: '90%', justifyContent: 'center', alignItems: 'center', position: 'relative' }}>
        <video
          ref={videoRef}
          controls
          style={{
            width: '100%',
            maxWidth: '100%',
            maxHeight: '50vh',
            objectFit: 'contain'
          }}
        >
          <source src="your_video_source_here" type="video/mp4" />
          Your browser does not support the video tag.
        </video>
        {showSubtitles && (
          <div
            style={{
              zIndex: 999999,
              position: 'absolute',
              bottom: '0',
              left: '0',
              width: '100%',
              height:'100px',
              textAlign: 'center',
              backgroundColor: 'rgba(0, 0, 0, 0.5)',
              color: '#fff',
              padding: '5px'
            }}
          >
            {currentSubtitle}
          </div>
        )}
        <audio controls ref={audioRef} style={{ width: 'calc(100% - 20px)', margin: '0 10px', marginBottom: '10px' }}>
                          <source src={audioUrl} type="audio/mp3" />
                          Your browser does not support the audio element.
                        </audio>
                        <div style={{color:'black'}}>因服务器资源有限，音视频转录请使用客户端工具转录.
                        仅支持windows操作系统。内置转录模型，下载即用，无需配置环境。
                        <a href='https://transcribeapp.oss-cn-beijing.aliyuncs.com/zhigu.zip'>点击下载</a></div>
      </div>
                    );
                }
              })()}
          </Box>

        </Box>
        
      </Box>
      
      <Modal
      open={showDiv}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      {/* 使用Box代替直接在Paper上应用sx属性，以便更好地控制布局 */}
      
      <Paper 
          style={{
            display: 'flex',
            flexDirection: 'column', // 由于我们希望内容垂直堆叠，需要设置flex方向
            backgroundColor: 'white',
            position: 'fixed',
            left: '50%',
            top: '50%',
            transform: 'translate(-50%, -50%)',
            margin: '20px',
            padding: '20px',
          }} 
          elevation={3}
      >
        <UploadFile
            handleClose={handleClose}
            options={firstLayerKeysAndTitles}
            onUpload={handleUploadedFiles}
          />
        </Paper>
    
    </Modal>


      {isLoading && (
        <LoadingData text={t(loadingText)}/>
      )}

      
      <Modal
        open={isModalVisible}
        onClose={handleCancel}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={modalStyle}>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Input folder name"
            type="text"
            fullWidth
            
            variant="standard"
            value={folderName}
            onChange={(e) => setFolderName(e.target.value)}
            sx={{
              '& .MuiInputBase-input': { // 直接目标到输入框的基础样式
                color: 'black', // 将字体颜色设置为黑色
                height: '40px', // 设置输入区域的高度
                padding: '0 14px', // 根据需要调整水平和垂直填充
              },
            }}
          />
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
            <CustomButton onClick={handleCancel} sx={{ mr: 1 }}>{t('cancel')}</CustomButton>
            <CustomButton onClick={handleOk}>{t('confirm')}</CustomButton>
          </Box>
        </Box>
      </Modal>


      <Modal
        open={isModalVisibleEditFolder}
        onClose={handleCancelEditFolder}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={modalStyle}>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label={t('modify directory')}
            type="text"
            fullWidth
            
            variant="standard"
            value={folderNameEdit}
            onChange={(e) => setFolderNameEdit(e.target.value)}
            sx={{
              '& .MuiInputBase-input': { // 直接目标到输入框的基础样式
                color: 'black', // 将字体颜色设置为黑色
                height: '40px', // 设置输入区域的高度
                padding: '0 14px', // 根据需要调整水平和垂直填充
              },
            }}
          />
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
            <CustomButton onClick={handleCancelEditFolder} sx={{ mr: 1 }}>{t('cancel')}</CustomButton>
            <CustomButton onClick={handleOkEditFolder}>{t('confirm')}</CustomButton>
          </Box>
        </Box>
      </Modal>



      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{
          '& .MuiPaper-root': {
            width: {
              xs: '90%', // 手机屏幕上宽度设置为 90%
              sm: '500px', // 平板和电脑屏幕上最大宽度为 500px
            },
            maxWidth: '100%', // 确保不会超出屏幕
          },
        }}
      >
        <DialogTitle id="alert-dialog-title">{t('caption')}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {t('Please set the main language of the document, audio and video need to set the language of transcription.')}
          </DialogContentText>

          <CommonCombobox
            preferredValue={docLanguage}
            setPreferredValue={setDocLanguage}
            options={dropdownLanguages}

            placeholder={t('select language')}
            labelText={t('document language')}
          />
          <CommonCombobox
            preferredValue={audioLanguage}
            setPreferredValue={setAudioLanguage}
            options={dropdownLanguages}

            placeholder={t('select language')}
            labelText={t('audio and video transcription language')}
          />
        </DialogContent>
        <DialogActions>
          <CustomButton onClick={handleCloseSetingLanguage}>
            {t('close')}
          </CustomButton>
          <CustomButton onClick={handleSetingLanguageOK}>
            {t('confirm')}
          </CustomButton>
        </DialogActions>
      </Dialog>


      {showPhotoComponent && <PhotoComponent onClose={onClosePhotoCom} ocrType="subject" subject="mathematics" moduleName="doc" handleCustomRecognize={handleOCRImage} />}

      {isShowSystemHelp && (
        <SystemHelp modulename="knowledge" subject="common" onClose={onCloseHelp} />
      )}

    <Box>
      <IconButton
        sx={{ position: 'absolute', bottom: 16, right: 10, zIndex: 1300 }}
        onClick={handleFixRight}
      >
        <MenuIcon />
      </IconButton>
      
      {!openRightMenu && (
        <Stack direction="column" spacing={1} sx={{ position: 'absolute', top: '10%', right: 16, transform: 'translateY(-50%)', zIndex: 1300 }}>

          <IconButton onClick={(event) => handleTabChange(event,"summ")}>
              <ListAltIcon />
            </IconButton>

            <IconButton onClick={(event) => handleTabChange(event,"time")}>
            <TimelineIcon />
            </IconButton>

            <IconButton onClick={(event) => handleTabChange(event,"doccon")}>
            <ChatIcon />
            </IconButton>

            <IconButton onClick={(event) => handleTabChange(event,"qa")}>
            <LightbulbIcon />
            </IconButton>

         
        </Stack>
      )}

      <Slide direction="left" in={openRightMenu} mountOnEnter unmountOnExit>
        <Box
          sx={{
            position: 'absolute',
            right: 0,
            top: 0,
            bottom: 0,
            width: '400px', // 根据需要调整宽度
            boxShadow: 1,
            zIndex: 1200,
          }}
        >
          <AppBar position="static" style={{backgroundColor:'white'}}>
            

          <TabContext value={value}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider'}}>
              
              <TabList onChange={handleTabChange} aria-label="lab API tabs example">
                <Tab label={t('summary')} value="summ" />
                <Tab label={t('timeline subtitle')} value="time" />
                <Tab label={t('document conversation')} value="doccon" /> 
                <Tab label={t('a distillation of knowledge')} value="qa" />
              </TabList>
            </Box>
          
                <TabPanel value="summ" key="summ" sx={{width:'100%'}}>
                <Paper 
                  style={{
                    zIndex: 5555,
                    display: 'flex',
                    flexDirection: 'column', // 由于我们希望内容垂直堆叠，需要设置flex方向
                    margin: '20px',
                    padding: '20px',
                    backgroundColor: '#2a3d66'
                  }} 
                  elevation={3}>
                    <ReactMarkdown
                      children={markdownTextSummarize}
                      remarkPlugins={[remarkMath]}
                      rehypePlugins={[rehypeRaw, rehypeKatex]} // 注意插件的顺序，rehypeRaw 先于 rehypeKatex
                    />
                </Paper>
                </TabPanel >
                <TabPanel value="time" key="time" sx={{width:'100%'}} >
                  <FormControl>
                    <Select
                      value={selectedLanguage}
                      onChange={handleChangeLanguage}
                      onOpen={handleOpenLanguage}
                      onClose={handleCloseLanguage}
                      open={openLanguage}
                      MenuProps={{ onClose: handleCloseLanguage }}
                    >
                      {languages.map((language) => (
                        <MenuItem key={language.id} value={language.id}>
                          {language.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <Button onClick={toggleSubtitles}>
                    {showSubtitles ? 'Hide Subtitles' : 'Show Subtitles'}
                  </Button>
                  
                  <Card
                    style={{
                      borderRadius: '10px',
                      boxShadow: '0px 0px 10px rgba(0,0,0,0.1)',
                      height: 'calc(80vh - 70px)', // 70px考虑到按钮和间隙的空间
                      overflowY: 'auto',
                      marginBottom: '50px',
                      
                    }}
                    title={<div style={{ textAlign: 'center', fontSize: '15px', color: '#333' }}>{t('timeline subtitle')}</div>}
                  >
                    <List>
                      {content.map((item, index) => (
                        <ListItem key={index}>
                          <TextField
                            fullWidth
                            variant="outlined"
                            label={`Start: ${item.start}`}
                            value={item.text}
                            onClick={() => handleLineClick(item.start)}
                            onChange={(e) => handleTextChange(index, e.target.value)}
                            sx={{
                              '& .MuiInputBase-input': {
                                color: 'black',
                                height: '35px',
                                padding: '0 14px',
                              },
                            }}
                          />
                        </ListItem>
                      ))}
                    </List>
                  </Card>
                  <Button
                    type="primary"
                    onClick={handleUpdateWordTime}
                    style={{
                      position: 'absolute',
                      right: '10px',
                      bottom: '20px',
                      marginBottom: '30px',
                    }}
                  >
                    {t('updated subtitles')}
                  </Button>
                </TabPanel>

                <TabPanel value="doccon" key="doccon" sx={{width:'100%'}}>
                      <ChatWindow
                        width="100%"
                        height="50%"
                        messages={messages}
                        onSendMessage={handleSendMessage}
                        inputText={inputText}
                        setInputText={setInputText}
                        isLoading={isLoading}
                        subject='document'
                        fromModule='docconversation'
                      />
                </TabPanel >
                <TabPanel  value="qa" key="qa" sx={{width:'100%'}}>
                  
                </TabPanel >
      
          </TabContext>


          </AppBar>
         
        </Box>
      </Slide>
    </Box>


      <Dialog open={openDialog} onClose={handleCloseDialog}>
        <DialogTitle>{t('determine the file to delete ')}?</DialogTitle>
        <DialogContent>{t('once deleted, the file cannot be recovered')}</DialogContent>
        <DialogActions>
          <CustomButton onClick={handleCloseDialog}>{t('cancel')}</CustomButton>
          <CustomButton onClick={handleConfirmDelete} autoFocus>{t('delete')}</CustomButton>
        </DialogActions>
      </Dialog>

      <Dialog open={openDialogDeleteFolder} onClose={handleCloseDialogDeleteFolder}>
        <DialogTitle>{t('determine the directory to delete')} </DialogTitle>
        <DialogContent>{t('delete directory')}</DialogContent>
        <DialogActions>
          <CustomButton onClick={handleCloseDialogDeleteFolder}>{t('cancel')}</CustomButton>
          <CustomButton onClick={handleConfirmDeleteFolder} autoFocus>{t('delete')}</CustomButton>
        </DialogActions>
      </Dialog>
    </Box>
  );

};

export default AskAnythingNB;
