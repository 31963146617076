import React from 'react';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';


const VideoModalPlayer = ({ open, handleClose, videoSrc }) => {
  return (
    <Modal
      open={open}

      onClose={handleClose}
      aria-labelledby="video-modal-title"
      aria-describedby="video-modal-description"
    >
      <Box sx={{

        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: 4,
        borderRadius:'15px',
      }}>
        <Card sx={{ maxWidth: 1280, maxHeight: 960, overflow: 'hidden' }}>
          <CardMedia
            component="video"
            controls
            src={videoSrc}
            alt="video content"
          />
        </Card>
      </Box>
    </Modal>
  );
};

export default VideoModalPlayer;
