import React, { useState,useEffect } from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import AnalysisIcon from '@mui/icons-material/Assessment'; // 假设这是分析题目的图标
import 'katex/dist/katex.min.css';
import katex from 'katex';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import { blue, orange } from '@mui/material/colors';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from '../../SnackbarProvider';
import { requestUrl } from '../../requestUrl';
import LoadingData from '../dataloading';
import CustomButton from '../../components/CustomButton';
import { getColorDark,getColorLight ,getTheme} from '../../ColorManager';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Pagination,Modal,Checkbox ,TextField,Chip } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import ProblemDetail from './ProblemDetail';
import { Select, MenuItem, FormControl, InputLabel } from '@mui/material';
import CommonComboboxOne from '../../components/CommonComboboxOne.jsx';
import CommonCombobox from '../../components/CommonCombobox.jsx';
import AddEditProblem from './AddEditProblem.jsx';
import { Container, Button } from '@mui/material';
import ConfigHomework from './ConfigHomework.jsx';
import CloseIcon from '@mui/icons-material/Close';

// 生成随机颜色函数
const getRandomColor = () => {
    const letters = '0123456789ABCDEF';
    let color = '#';
    for (let i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
};


const TagList = ({onClose}) => {
  const showSnackbar = useSnackbar();
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [loadingText,setLoadingText] = useState('loading');
  const [userid,setUserid] = useState(0);
  const [colorManager, setColorManager] = useState(getColorDark()); // 初始化为空数组
  const [tableData, setTableData] = useState([]);
  const [tableHomeWorkData,setTableHomeWorkData] = useState([]);//保存指定老师的家庭作业列表
  const [tableGradeHomeworkData,setTableGradeHomeworkData] = useState([]);//保存某一个家庭作业的详细信息，学生，完成情况，界面右侧列表
  const [totalPagesSession, setTotalPagesSession] = useState(1);
  const [currentPage, setCurrentPage] = useState(0);


  const [selectedClassName, setSelectedClassName] = useState(null);
  const [selectedTagId, setSelectedTagId] = useState(null);
  const [selectedTagIds, setSelectedTagIds] = useState([]);
  const [selectedTags, setSelectedTags] = useState([]);
 

  const get_color_by_name = (name) => {

    const item = colorManager.find(item => item.container === name);
    return item.value;
  };


   // 示例数据
   const classNames1 = ['Class A', 'Class B', 'Class C'];
   const tags1 = {
       'Class A': [
           { id: 1, tag_name_zh: 'Tag 1A' },
           { id: 2, tag_name_zh: 'Tag 2A' }
       ],
       'Class B': [
           { id: 3, tag_name_zh: 'Tag 1B' },
           { id: 4, tag_name_zh: 'Tag 2B' }
       ],
       'Class C': [
           { id: 5, tag_name_zh: 'Tag 1C' },
           { id: 6, tag_name_zh: 'Tag 2C' }
       ]
   };

   const [classNames,setClassNames] = useState([]);
   const [tags,setTags] = useState({});


   // 为 classNames 和 tags 生成固定颜色
   const [classColors, setClassColors] = useState({});
   const [tagColors, setTagColors] = useState({});


   useEffect(() => {
    console.log("song");
    const fetchData = async () => {
        try {
            setIsLoading(true);
            setLoadingText("loading");
            const dataToSend = {
                id:1,
            };
            const data = await requestUrl(dataToSend, "get_all_tags",t,showSnackbar);
            if(data.status === "success"){
                setClassNames(data.class_names);
                setTags(data.tags);
              
            }
            
            
          } catch (error) {
              console.error("获取数据时出错:", error);
          } finally {
            setIsLoading(false);
          }
    };

    fetchData(); 
  }, []);



   useEffect(() => {
       const colors = {};
       classNames.forEach(className => {
           colors[className] = getRandomColor();
       });
       setClassColors(colors);

       const tagColorsObj = {};
       Object.keys(tags).forEach(className => {
           tagColorsObj[className] = {};
           tags[className].forEach(tag => {
               tagColorsObj[className][tag.id] = getRandomColor();
           });
       });
       setTagColors(tagColorsObj);
   }, []);

   const handleTagClick = (tag, tagId) => {
       if (selectedTagIds.length >= 3 && !selectedTagIds.includes(tagId)) {
           return; // 如果已选中3个标签，不能再选择更多
       }
       if (selectedTagIds.includes(tagId)) {
           // 如果标签已经被选中，移除它
           setSelectedTagIds(prevIds => prevIds.filter(id => id !== tagId));
           setSelectedTags(prevTags => prevTags.filter(t => t.id !== tagId));
       } else {
           // 添加新选中的标签
           setSelectedTagIds(prevIds => [...prevIds, tagId]);
           setSelectedTags(prevTags => [...prevTags, { id: tagId, tag_name_zh: tag }]);
       }
   };

   const handleRemoveTag = (tagId) => {
       // 删除选中的标签
       setSelectedTagIds(prevIds => prevIds.filter(id => id !== tagId));
       setSelectedTags(prevTags => prevTags.filter(t => t.id !== tagId));
   };




   

  const handleClose = () => {
    onClose(selectedTagIds,selectedTags);
  }



    return (
        <Container maxWidth="md" sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
            {/* 上部分 */}
            <Box sx={{ display: 'flex', flexWrap: 'wrap', p: 1, bgcolor: 'lightgray', overflowX: 'auto' }}>
                {classNames.map((className) => (
                    <Chip
                        key={className}
                        label={className}
                        onClick={() => setSelectedClassName(className)}
                        sx={{
                            m: 0.5,
                            bgcolor: classColors[className] || 'grey', // 使用固定颜色
                            color: 'white' // 文字颜色为白色以提高可读性
                        }}
                        color={selectedClassName === className ? 'primary' : 'default'}
                        clickable
                    />
                ))}
            </Box>

            {/* 中间部分 */}
            <Box
                sx={{ flexGrow: 1, overflowY: 'auto', p: 1, bgcolor: 'whitesmoke', display: 'flex', flexWrap: 'wrap' }}
            >
                {selectedClassName && tags[selectedClassName].map((tag) => (
                    <Chip
                        key={tag.id}
                        label={tag.tag_name_zh}
                        onClick={() => handleTagClick(tag.tag_name_zh, tag.id)}
                        sx={{
                            m: 0.5,
                            bgcolor: tagColors[selectedClassName]?.[tag.id] || 'grey', // 使用固定颜色
                            color: 'white' // 文字颜色为白色以提高可读性
                        }}
                        color={selectedTagIds.includes(tag.id) ? 'primary' : 'default'}
                        clickable
                    />
                ))}
            </Box>

            {/* 下部分 */}
            <Box sx={{ p: 2, bgcolor: 'lightgray', display: 'flex', flexWrap: 'wrap', gap: 1 }}>
                {selectedTags.map((tag) => (
                    <Chip
                        key={tag.id}
                        label={
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                {tag.tag_name_zh}
                                <IconButton
                                    onClick={() => handleRemoveTag(tag.id)}
                                    size="small"
                                    sx={{ ml: 1 }}
                                >
                                    <CloseIcon fontSize="small" />
                                </IconButton>
                            </Box>
                        }
                        sx={{ bgcolor: tagColors[selectedClassName]?.[tag.id] || 'grey', color: 'white' }}
                        color="primary"
                    />
                ))}
            </Box>

            <Box sx={{ p: 2, bgcolor: 'lightgray' }}>
                <CustomButton sx={{ marginRight: '10px', height: '100%' }} onClick={handleClose}>
                    {t('关闭')}
                </CustomButton> 
            </Box>

        {isLoading && (
        <LoadingData text={t(loadingText)}/>
      )}
        </Container>
    )
};

export default TagList;