import React, { useEffect, useState } from 'react';
import { Box, Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, TextField ,IconButton} from '@mui/material';

import { requestUrl } from '../../requestUrl';
import { useTranslation } from 'react-i18next';
import LoadingData from '../../education/dataloading';
import { getHostName } from '../../utils/urlUtils';
import DeleteIcon from '@mui/icons-material/Delete';

import { useSnackbar } from '../../SnackbarProvider';
const FamilyHeadDocument = () => {
    const showSnackbar = useSnackbar();
    const [isLoading, setIsLoading] = useState(false);
    const [loadingText,setLoadingText] = useState(false);

    const [documents, setDocuments] = useState([]);

    const [selectedFile, setSelectedFile] = useState(null);
    const [fileName, setFileName] = useState('');

    const [author,setAuthor] = useState('');
    const [description,setDescription] = useState('');

    const [chunkSize,setChunkSize] = useState(1000);
    const [overlap,setOverlap] = useState(100);

    const { t } = useTranslation();
    useEffect(() => {
        // Replace with your actual API call
        fetchDocuments();
    }, []);

    const fetchDocuments = async () => {
        try {

            setIsLoading(true);
            
            const dataToSend = {
                id:0,
            };
            const data = await requestUrl(dataToSend, "get_familyhead_vector_docs");
            console.log(JSON.stringify(data));
            if(data.status === "success"){
                setDocuments(data.data);
            }
            else{
              showSnackbar('warning', t('password wrong'));
            }
          } 
          catch (error) {
              console.error("获取数据时出错:", error);
          }
          finally {
            setIsLoading(false);
          }
    };



    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            setSelectedFile(file);
            setFileName(file.name);
        }
    };

    const handleUpload = async () => {
        if (selectedFile) {
            // 这里实现上传逻辑
            console.log('Uploading:', selectedFile);
            // 清除选中的文件
            setSelectedFile(null);
            setFileName('');

            uploadFileToServer(selectedFile);
        }
    };


    const uploadFileToServer = async (file) => {
        let fp = "";
        setIsLoading(true);
        const token = localStorage.getItem("access_token");
        const formData = new FormData();
        formData.append("file", file);
        formData.append("author", author);
        formData.append("des", description);
        formData.append("chunkSize", chunkSize);
        formData.append("overLap", overlap);
       
        try {
            const url = getHostName() + '/upload_familyhead_vector_doc'; // 你的服务端URL
            const response = await fetch(url, {method: 'POST',
                headers: {'Authorization': `Bearer ${token}`},
                body: formData,});
    
            if (response.ok) {
                let fp = response.filepath;
                showSnackbar('success', t('file upload success'));
                
            } else {
                console.error("文件上传失败");
                // 处理错误
                showSnackbar('warning', t('file upload failure'));
                
            }
        } catch (error) {
            console.error("上传过程中出错", error);
            showSnackbar('failure', t('file upload failure'));
            setIsLoading(false);
        }
        setIsLoading(false);

        return fp;
    };

    const handleTxtChangeDes = (event) => {
        setDescription(event.target.value);
    };

    const handleTxtChangeAuthor = (event) => {
        setAuthor(event.target.value);
    };
    
    const handleTxtChangeChunkSize = (event) => {
        setChunkSize(event.target.value);
    };

    const handleTxtChangeOverlap = (event) => {
        setOverlap(event.target.value);
    };


      
    const handleDeleteDoc = async (docid) => {
        try {

            setIsLoading(true);
            
            const dataToSend = {
                id:docid,
            };
            const data = await requestUrl(dataToSend, "delete_familyhead_vector_docs");
            console.log(JSON.stringify(data));
            if(data.status === "success"){
                const updatedDocuments = documents.filter(document => document.id !== docid);
                setDocuments(updatedDocuments);
            }
            else{
                showSnackbar('warning', t('password wrong'));
              
            }
          } 
          catch (error) {
              console.error("获取数据时出错:", error);
          }
          finally {
            setIsLoading(false);
          }
    };

   
    
    return (
        <>
        
       <Box sx={{width:'90%',height:'100px',backgroundColor:'white'}} 
       alignItems="center" display="flex" justifyContent="flex-start" m={1} style={{ margin: 'auto' }}>
                {/* 其他按钮 */}
                <Button variant="contained" component="label" style={{ marginRight: 20,height:'30px' }}>
                    选择文件
                    <input type="file" hidden onChange={handleFileChange} />
                </Button>
                <TextField 
                    disabled 
                    value={fileName} 
                    placeholder="未选择文件" 
                    style={{ marginRight: 20 }}
                />
               
                <TextField 
                    label="author"
                    value={author} 
                    placeholder="" 
                    style={{ marginRight: 20 }}
                    variant="outlined"
                    size="small"
                    multiline
                    rows={2}
                    onChange={handleTxtChangeAuthor}
                />

                <TextField
                    label="description"
                    variant="outlined"
                    size="small"
                    multiline
                    rows={2}
                    sx={{width:'300px'}}
                    name="title_local_lang"
                    value={description}
                    onChange={handleTxtChangeDes}
                />
               
                {/* ... 其他组件 */}
            </Box>


            <Box sx={{width:'90%',height:'100px',backgroundColor:'white'}} 
            alignItems="center" display="flex" justifyContent="flex-start" m={1} style={{ margin: 'auto' }}>
                {/* 其他按钮 */}
                <TextField 
                    label="chunkSize"
                    value={chunkSize} 
                    placeholder="" 
                    style={{ marginRight: 20 }}
                    variant="outlined"
                    size="small"
                    multiline
                    rows={2}
                    onChange={handleTxtChangeChunkSize}
                />

                <TextField
                    label="overlap"
                    variant="outlined"
                    size="small"
                    multiline
                    rows={2}
                    sx={{width:'300px'}}
                    name="title_local_lang"
                    value={overlap}
                    onChange={handleTxtChangeOverlap}
                />
                <Button 
                    variant="contained" 
                    onClick={handleUpload} 
                    disabled={!selectedFile}
                >
                    上传文件
                </Button>
                {/* ... 其他组件 */}
            </Box>


            <br></br>
        <TableContainer sx={{width:'90%'}} component={Paper} style={{ margin: 'auto' }}>
            <Table aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <TableCell>ID</TableCell>
                        <TableCell align="right">Document Name</TableCell>
                        <TableCell align="right">Description</TableCell>
                        <TableCell align="right">Author</TableCell>
                        <TableCell align="right">Language</TableCell>
                        <TableCell align="right">emebeddingStatus</TableCell>
                        <TableCell align="right">chunk size</TableCell>
                        <TableCell align="right">overlap</TableCell>
                        <TableCell align="right">Delete</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {documents.map((doc) => (
                        <TableRow key={doc.id}>
                            <TableCell component="th" scope="row">
                                {doc.id}
                            </TableCell>
                            <TableCell align="right">{doc.book_name}</TableCell>
                            <TableCell align="right">{doc.description}</TableCell>
                            <TableCell align="right">{doc.author}</TableCell>
                            <TableCell align="right">{doc.language}</TableCell>
                            <TableCell align="right">
                            {doc.status === 1 ? "嵌入成功" : (doc.status === 0 ? "未嵌入" : "")}
                            </TableCell>
                            <TableCell align="right">{doc.chunk_size}</TableCell>
                            <TableCell align="right">{doc.overlap}</TableCell>
                            <TableCell>
                      <IconButton aria-label="delete" color="primary" onClick={() => handleDeleteDoc(doc.id)}>
                        <DeleteIcon />
                      </IconButton>
                    </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
            {isLoading && (
                <LoadingData text={t(loadingText)}/>
            )}
        </TableContainer>
        </>
    );
};

export default FamilyHeadDocument;
