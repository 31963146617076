import React, { useState, useEffect } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button, TextField, Box, TablePagination } from '@mui/material';
import { getHostName } from '../../utils/urlUtils';
import { useSnackbar } from '../../SnackbarProvider';
import LoadingData from '../dataloading';
import { useTranslation } from 'react-i18next';
import { getColorDark,getColorLight } from '../../ColorManager';
import CustomButton from '../../components/CustomButton';
import { requestUrl } from '../../requestUrl';


const InteractiveCourseware = () => {
  const showSnackbar = useSnackbar(); // 这个是界面顶部弹出提示信息
  const { t } = useTranslation(); // 这是i18n全球化，支持多语言的界面
  const [isLoading, setIsLoading] = useState(false);
  const [loadingText, setLoadingText] = useState('加载...');
  const [rows, setRows] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const [searchContent,setSearchContent] = useState('');

  const [colorManager, setColorManager] = useState(getColorDark()); // 初始化为空数组

  const get_color_by_name = (name) => {

    const item = colorManager.find(item => item.container === name);
    return item.value;
  }


  const inputStyles = {
    color:get_color_by_name('mui-textfield-text-color'),
    fontFamily: 'siyuan',
  };
  
  //获取颜色设置，系统加载时设置
  useEffect(() => {

    const themeName = localStorage.getItem('theme');//此处存储的是数字1-12，而不是汉字，
    if (themeName) {
      
      if(themeName === "dark"){
        setColorManager(getColorDark());
      }

      
      if(themeName === "light"){
        setColorManager(getColorLight());
      }
    }
    else{
      let aa = getColorDark();
      setColorManager(aa);
    }

    
  }, []);




  useEffect(() => {
    // 模拟数据加载
    setIsLoading(true);
    setTimeout(() => {
      setRows([
        { id: 1, name: 'Course 1', description: 'Description 1' },
        { id: 2, name: 'Course 2', description: 'Description 2' },
        { id: 3, name: 'Course 3', description: 'Description 3' },
        // 其他数据...
      ]);
      setIsLoading(false);
    }, 2000);
  }, []);

  
  const handleSearch = async () => {
    try {
      setIsLoading(true);
      const dataToSend = {
        searchContent:searchContent,
      };
      const data = await requestUrl(dataToSend, "search_interactive_courseware",t,showSnackbar);
      setRows(data.data);

      setIsLoading(false);


    }
    catch (error) {
      setIsLoading(false);
      console.error("There was an error fetching the reply:", error);
    }
  }

  const handleKeyDown  = (e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      
      handleSearch();
    }
    
  };



  return (
    <Box sx={{ p: 2 }}>
      <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
      <TextField
              label={t('category name')}
              InputLabelProps={{
                style: {
                  color: get_color_by_name('mui-textfield-text-color'), // 使用主题颜色
                },
              }}
              InputProps={{
                sx: inputStyles,
              }}
              sx={{
                '& .MuiInputBase-root': {
                  backgroundColor: get_color_by_name('mui-textfield-bg'), // 自定义背景色
                },
                '& .MuiOutlinedInput-root': {
                  '&:hover fieldset': {
                    borderColor: get_color_by_name('mui-textfield-bg'),
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: 'primary.main',
                  },
                  '& fieldset': {
                    borderColor: get_color_by_name('mui-textfield-bg'),
                  },
                },
              }}
              fullWidth
              multiline
             
              value={searchContent}
              onKeyDown={handleKeyDown} 
              onChange={(event) => setSearchContent(event.target.value)}
              autoFocus
            />
            <CustomButton
            onClick={handleSearch}>
            {t('search')}
          </CustomButton>
        
      </Box>
      {isLoading ? (
        <LoadingData text={loadingText} />
      ) : (
        <Paper>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>{t('ID')}</TableCell>
                  <TableCell>{t('GID')}</TableCell>
                  <TableCell>{t('Name')}</TableCell>
                  <TableCell>{t('Description')}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row) => (
                  <TableRow key={row.id}>
                    <TableCell>{row.id}</TableCell>
                    <TableCell>{row.gid}</TableCell>
                    <TableCell>{row.gname}</TableCell>
                    <TableCell>{row.gtype}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          
        </Paper>
      )}
    </Box>
  );
};

export default InteractiveCourseware;
