
import { getHostName } from "./utils/urlUtils";
import { useNavigate } from 'react-router-dom';

import {useVisitorData} from '@fingerprintjs/fingerprintjs-pro-react';
import { useTranslation } from 'react-i18next';

  export const requestUrl_old = async (dataToSend, interfaceName,t) => {
    const token = localStorage.getItem("access_token");

    //const updatedDataToSend = { ...dataToSend, visitorId };
    try {
        const url = getHostName() + '/' + interfaceName; // 你的服务端URL
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify(dataToSend),
        });

        if (response.ok) {
            const data = await response.json();
            return data;
        } else {
            if (response.status === 401) {
                // 处理Token过期或无效的情况
                console.error("Log out and log back in");
                
                alert(t("Log out and log back in"));
                
            } else {
                console.error("Error fetching reply:", response.statusText);
            }
        }
    } catch (error) {
        console.error("There was an error fetching the reply:", error);
    }
}

export const requestUrl = async (dataToSend, interfaceName, t, showSnackbar) => {
    const token = localStorage.getItem("access_token");
    try {
      const url = getHostName() + '/' + interfaceName; // 你的服务端URL
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify(dataToSend),
      });
  
      if (response.ok) {
        const data = await response.json();
        return data;
      } else {
        if (response.status === 401) {
          
          showSnackbar(t("Log out and log back in"),'success');
        } else {
          console.error("Error fetching reply:", response.statusText);
        }
      }
    } catch (error) {
      console.error("There was an error fetching the reply:", error);
    }
  }

export const requestUrl_Navigate = async (dataToSend, interfaceName,navigate) => {
  const token = localStorage.getItem("access_token");

  try {
      const url = getHostName() + '/' + interfaceName; // 你的服务端URL
      const response = await fetch(url, {
          method: 'POST',
          headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${token}`
          },
          body: JSON.stringify(dataToSend),
      });

      if (response.ok) {
          const data = await response.json();
          return data;
      } else {
          if (response.status === 401) {
              // 处理Token过期或无效的情况
              console.error("please login in again.");
              alert('please login in again.');
              //showSnackbar('error', 'please login in again.');
              navigate('/Default'); 
              
          } else {
              console.error("Error fetching reply:", response.statusText);
          }
      }
  } catch (error) {
      console.error("There was an error fetching the reply:", error);
  }
}

  export const requestUrlWithOutParameter = async (interfaceName) => {
    const token = localStorage.getItem("access_token");
  
    try {
      const url = getHostName() + '/' + interfaceName; // 你的服务端URL
      const response = await fetch(url, {
        method: 'POST', // 使用POST方法
        headers: {
          'Authorization': `Bearer ${token}` // 只发送认证头
        }
      });
  
      if (response.ok) {
          const data = await response.json();
          console.log("data:::" + JSON.stringify(data));
          return data;
      } else {
          console.error("Error fetching reply:", response.statusText);
      }
    } catch (error) {
      console.error("There was an error fetching the reply:", error);
    }
  }
  
