import React from 'react';

const Algorithm = () => {
  return (
    <div style={{ width: '100%', height: '90vh' }}> {/* 使用100vw和100vh来使组件的尺寸最大 */}
      <iframe src="https://chentu.online:8088" style={{ width: '100%', height: '100%', border: 'none' }}></iframe>
    </div>
  );
};

export default Algorithm;
