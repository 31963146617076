import React, { Fragment, useState, useCallback, useRef,useEffect } from "react";
import { AgCharts } from "ag-charts-community";
import { AgChartsReact } from "ag-charts-react";
import { Box, Paper, Divider, Stack,Modal } from '@mui/material';
import { requestUrl } from '../../requestUrl';
import {  Typography, Grid,Button,Chip } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { useTranslation } from 'react-i18next';
import 'katex/dist/katex.min.css';
import katex from 'katex';
import LoadingData from '../dataloading';
import ReactMarkdown from 'react-markdown';
import './PkBuddleMapCss.css';
import { styled } from '@mui/system';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import remarkMath from 'remark-math';
import rehypeKatex from 'rehype-katex';
import CreateIcon from '@mui/icons-material/Create';
import { useSnackbar } from "../../SnackbarProvider";
import PointKnowledgeMapComponent from "../Subject/PointKnowledgeMapComponent";
import CustomButton from "../../components/CustomButton";
const PkBuddleMap = ({subject,onClose}) => {
    const { t } = useTranslation();
    const [isLoading, setIsLoading] = useState(false);
    const [loadingText,setLoadingText] = useState('loading');
    const chartRef = useRef(null);

    const [showPkMapComponent,setShowPkMapComponent] = useState(false);
    const [selectedPkContent,setSelectedPkContent] = useState('');

    const [buddlemapGenerateTime,setBuddlemapGenerateTime] = useState('');
    const [selectedSubject,setSelectedSubject] = useState('mathematics');
    const [temporarySelectedSubject, setTemporarySelectedSubject] = useState('');
    const [open, setOpen] = useState(false);
    const [options, setOptions] = useState({});

    const showSnackbar = useSnackbar();
    const loadBuddleMapBySubject = async (subject) => {
      try {
          setIsLoading(true);
          setLoadingText("loading");
          const dataToSend = {
              subject:subject,
          };
          const data = await requestUrl(dataToSend, "query_pk_buddle_map",t,showSnackbar);
          if(data.status === "success"){
            setMapDatas(data.data.buddle_data);
            setBuddlemapGenerateTime(data.data.recordtime);
          }
          if(data.status === "failure"){
            console.log("there is nothing data.");
          }
          
          
        } catch (error) {
            console.error("获取数据时出错:", error);
        } finally {
          setIsLoading(false);
        }
  };

    useEffect(() => {
      loadBuddleMapBySubject(subject);
      setSelectedSubject(subject);
    }, [subject]);


    const [mapDatas,setMapDatas] = useState({});

    const [mapDatas1,setMapDatas1] = useState({
        "一元二次函数": [
          {
            "title": "一元二次函数",
            "yearOfRelease": 1994,
            "duration": 142,
            "rating": 9.3,
            "genre": "Drama",
            "weight": 733000000,
            "ranking": 1
          },
          {
            "title": "一元二次函数图像及应用",
            "yearOfRelease": 1957,
            "duration": 96,
            "rating": 9.0,
            "genre": "Drama",
            "weight": 2000000,
            "ranking": 5
          }
        ],
        "贝叶斯曲线": [
          {
            "title": "贝叶斯曲线概念",
            "yearOfRelease": 1972,
            "duration": 175,
            "rating": 9.2,
            "genre": "Crime",
            "weight": 1200000000,
            "ranking": 2,
          },
          {
            "title": "贝叶斯曲线实际使用",
            "yearOfRelease": 1974,
            "duration": 202,
            "rating": 9.0,
            "genre": "Crime",
            "weight": 700000000,
            "ranking": 4,
          },
          {
            "title": "贝叶斯曲线 伟大",
            "yearOfRelease": 1994,
            "duration": 154,
            "rating": 8.9,
            "genre": "Crime",
            "weight": 213900000,
            "ranking": 8,
          },
        ],
        "欧拉公式": [
          {
            "title": "最美的公式:欧拉公式",
            "yearOfRelease": 2008,
            "duration": 152,
            "rating": 9.0,
            "genre": "Action",
            "weight": 1006000000,
            "ranking": 3,
          },
        ],
        "洛希极限": [
          {
            "title": "引力",
            "yearOfRelease": 1993,
            "duration": 195,
            "rating": 9.0,
            "genre": "Biography",
            "weight": 322200000,
            "ranking": 6,
          },
        ],
        "微积分": [
          {
            "title": "微分 导数",
            "yearOfRelease": 2003,
            "duration": 201,
            "rating": 9.0,
            "genre": "Adventure",
            "weight": 1148000000,
            "ranking": 7,
          },
          {
            "title": "积分 极限",
            "yearOfRelease": 2001,
            "duration": 178,
            "rating": 8.8,
            "genre": "Adventure",
            "weight": 898200000,
            "ranking": 9,
          }
        ],
        "离散数学": [
          {
            "title": "微分 斜率",
            "yearOfRelease": 1966,
            "duration": 178,
            "rating": 8.8,
            "genre": "Western",
            "weight": 38900000,
            "ranking": 10,
            "commonchats":[{"id":1,"question":"提问的内容1"},{"id":1,"question":"提问的内容2"}]
          }
        ]
    });


    const tooltip = {
      renderer: ({ datum, xName, yName, sizeName, xKey, yKey, sizeKey,data }) => {
        return {
          content: `<b>${xName}:</b> ${datum[xKey]}<br/><b>${yName}: </b>${
            datum[yKey]
          }<br/><b>${sizeName}: </b>${datum[sizeKey].toLocaleString()}
          <br/>

          `,
        };
      },
    };

    const updateFontSize = useCallback(
      (event) => {
        const clone = { ...options };
        var value = +event.target.value;
        clone.series.forEach(series => {
          series.label.fontSize = value;
        });
        document.getElementById("fontSizeSliderValue").innerHTML = String(value);
  
        setOptions(clone);
      },
      [options, String],
    ); 

    const handleNodeClickPkDetail = async (title) => {
        setSelectedPkContent(title);
        setShowPkMapComponent(true);
    }

    

    const handleGeneratePkMap = async () => {
      try {
        setIsLoading(true);
        setLoadingText("loading");
        const dataToSend = {
            subject:selectedSubject,
        };
        const data = await requestUrl(dataToSend, "generate_pk_map_buddle",t,showSnackbar);
        if(data.status === "success"){
          setMapDatas(data.data);
        }
        
        
      } catch (error) {
          console.error("获取数据时出错:", error);
      } finally {
        setIsLoading(false);
      }
    }
  

    // 使用 useEffect 来监听 mapDatas 的变化
  useEffect(() => {
    const newOptions = {
      container: document.getElementById("myChart"),
      title: {
        text: t('knowledge point statistics'),
      },
      subtitle: {
        text: t('the larger the circle, the more questions are involved and need to be taken seriously, click on the circle to see the details.'),
      },
      series: Object.entries(mapDatas).map(([genre, data]) => ({
        data,
        type: "bubble",
        xKey: "datax",
        xName: "Year",
        yKey: "datay",
        yName: `${genre[0].toUpperCase()}${genre.slice(1)}`,
        sizeKey: "weight",
        sizeName: "Box Office",
        marker: {
          size: 20,
          maxSize: 60,
          domain: [2000000, 1200000000],
        },
        label: {
          formatter: ({ datum: { ranking, title } }) => `${ranking}. ${title}`,
        },
        listeners: {
          nodeClick: (event) => {
              var datum = event.datum;
              let title = datum["title"];
              //datum[event.xKey];
              handleNodeClickPkDetail(title);
            },
        },
        tooltip,
      })),
      axes: [
        {
          position: "bottom",
          type: "number",
          title: {
            text: t('the bigger the bubble, the more questions you will run into on the relevant points, click to see the breakdown'),
          },
          gridLine: {
            enabled: false,
          },
          nice: false,
        },
        {
          position: "left",
          type: "number",
          title: {
            text: t('click on the bubbles to view'),
          },
          gridLine: {
            enabled: true,
          },
        },
      ],
    
      legend: {
        position: "top",
      },
    };

    // 更新 options 状态以重新渲染图表
    setOptions(newOptions);
  }, [mapDatas]); // 依赖于 mapDatas 的变化
      


  const handleClickOpen = () => {
    setOpen(true);
    setTemporarySelectedSubject(selectedSubject);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubjectChange = (event) => {
    setTemporarySelectedSubject(event.target.value);
  };

  const handleConfirm = () => {
    setSelectedSubject(temporarySelectedSubject);
    handleClose();
    loadBuddleMapBySubject(temporarySelectedSubject);
  };

 // 关闭Modal的方法
 const handleClosePKComponent = () => setShowPkMapComponent(false);

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
      <Box sx={{backgroundColor:'#2a3d66', color:'white',height: '40px', display: 'flex', alignItems: 'center', justifyContent: 'center',borderRadius:'10px' }}>
      <Button sx={{color:'white'}} endIcon={<CreateIcon />} onClick={() => {setOpen(true)}}>
      {t(`${selectedSubject}`)}
      </Button>
        &nbsp;
            {t('generate time')}:{buddlemapGenerateTime}
      </Box>


      <Box sx={{ height: '100%', overflow: 'auto', display: 'flex', flexDirection: 'column', gap: 2, padding: '20px' }}>
        <div className="wrapper" style={{borderRadius:'10px'}}>
          <div className="toolPanel" style={{borderRadius:'10px'}}>
            <div className="sliders">
              <label style={{color:'#2a3d66'}} >{t('label font size')}</label>
              <input
                type="range"
                id="sliderInput"
                min="8"
                max="30"
                defaultValue="12"
                step="1"
                onInput={(event) => updateFontSize(event)}
                onChange={(event) => updateFontSize(event)}
              />
              <span id="fontSizeSliderValue">12</span>
            </div>
          </div>
      
        <AgChartsReact ref={chartRef} options={options} />

        <Modal
          open={showPkMapComponent}
          onClose={handleClosePKComponent}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          sx={{zIndex:5555}}
        >
         
            <PointKnowledgeMapComponent pkContent={selectedPkContent} onClose={handleClosePKComponent} subject={subject}/>
         
      </Modal>

        </div>
      </Box>


      <Box sx={{ height: '40px', display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '20px' }}>
        <CustomButton onClick={handleGeneratePkMap}>{t('generate')}</CustomButton>
        <CustomButton onClick={onClose}>{t('close')}</CustomButton>
      </Box>


      {isLoading && (
       <LoadingData text={t(loadingText)}/>
      )}


      <Dialog open={open} onClose={handleClose} sx={{zIndex:9999}}>
        <DialogTitle>{t('select a subject')}</DialogTitle>
        <DialogContent>
          <RadioGroup
            row
            aria-label="subject"
            name="subject"
            value={temporarySelectedSubject}
            onChange={handleSubjectChange}
          >
            <Grid container spacing={2}>
              {['chinese', 'mathematics', 'english', 'physics', 'chemistry', 'geography', 'biology'].map((subjectKey) => (
                <Grid item xs={4} key={subjectKey}>
                  <FormControlLabel 
                    value={subjectKey} 
                    control={<Radio />} 
                    label={<Typography sx={{color: 'skyblue'}}>{t(`${subjectKey}`)}</Typography>} // 使用Typography组件改变文本颜色
                    
                    sx={{color: 'blue'}} // 设置文本颜色为蓝色
                  />
                </Grid>
              ))}
            </Grid>
          </RadioGroup>
        </DialogContent>
        <DialogActions>
          <CustomButton onClick={handleClose}>{t('cancel')}</CustomButton>
          <CustomButton onClick={handleConfirm}>{t('confirm')}</CustomButton>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default PkBuddleMap;
