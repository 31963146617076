import React from 'react';
import styled from 'styled-components';
const ComboboxContainer = styled.div`
  display: flex;
  height: 100%;
  margin-top: 5px;
  margin-bottom: 5px;
  width: 100%;  // 考虑使用100%以充满父容器
  // height: ${props => props.customHeight ? props.customHeight : '90px'};'
`;

const ComboboxContainer1 = styled.div`
  margin-top: 5px;
  margin-bottom: 5px;
  width: 95%;
  height: ${props => props.customHeight ? props.customHeight : '50px'};  // 使用传入的高度或默认高度
  display: flex;
`;

const ComboboxLabel = styled.label`
  color: black;
  width: 80px;
  &:after {
    content: ':';
  }
`;

const ComboboxSelect = styled.select`
  background-color: #1b2134;
  color: #ffffff;
  border-color: #1b2134;
   width: 100%;
  padding: 10px;
  border-radius: 5px;
  margin-top: 10px;
  margin-bottom: 10px;
  
`;

const CommonCombobox = ({ 
  className, 
  preferredValue, 
  setPreferredValue, 
  options, 
  onValueSelected, 
  placeholder, 
  labelText, 
  customWidth, 
  customHeight, 
  isShowLabel = true,
  optionTextColor = '#ffffff',
  backgroudColor = '#1b2134' }) => {
  const handleChange = (e) => {
    const selectedId = e.target.value;
    setPreferredValue(selectedId);

    const selectedOption = options.find(option => option.id === selectedId);
    if (selectedOption && onValueSelected) {
      onValueSelected(selectedOption);
    }
  };

  return (
    <ComboboxContainer className={className} customWidth={customWidth} customHeight={customHeight}>
      {isShowLabel && <ComboboxLabel>{labelText}</ComboboxLabel>}
      <select
        value={preferredValue}
        onChange={handleChange}
        style={{
          backgroundColor: backgroudColor,
          color: optionTextColor,
          borderColor: '#1b2134',
          width: '100%',
          padding: '10px',
          borderRadius: '5px',
          marginTop:'10px',
          marginBottom:'10px',

        }}
      >
        <option disabled value="">``
          {placeholder || 'Select Option'}
        </option>
        {options.map(({ id, text }) => (
          <option key={id} value={id}>
            {text}
          </option>
        ))}
      </select>
    </ComboboxContainer>
  );
};

export default CommonCombobox;
