/**
 * src/components/Languages/index.jsx
 * Select a language: currently support English or Spanish
 *
 * created by pycui on 7/28/23
 */

import React, { useState } from 'react';
import './style.css';
import IconButton from '@mui/material/IconButton';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import CustomTooltip from '../Common/CustomTooltip';

const AudioSpeed = ({ defaultAudioSpeed, setDefaultAudioSpeed }) => {

  const [value, setValue] = useState(1); // 设置初始值为50

// 处理滑动事件
  const handleChange = (event) => {
    setValue(event.target.value);
    setDefaultAudioSpeed(event.target.value);
  };
  return (
    <>
      <div className='languages-container'>
      <div
        className='title'
        style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}
      >
        <span>语速</span>
        <CustomTooltip
          title='正常语速为1 值越低语速越慢'
          placement='top'
        >
          <IconButton
            aria-label='info'
            sx={{ color: 'white', marginLeft: '5px', padding: '5px' }}
          >
            <InfoOutlinedIcon fontSize='small' />
          </IconButton>
        </CustomTooltip><span>当前值:{value}</span>
      </div>
        <div>
        <input className="custom-slider" // 使用自定义的CSS类名
          type="range"
          min="0"
          max="2"
          step="0.1"
          value={defaultAudioSpeed}
          onChange={handleChange}
        />
        
      </div>
      </div>

    </>
    
  );
};

export default AudioSpeed;
