

import React, { useState } from 'react';

const ModelChange = () => {

      // Inline styles
      const styles = {
        container: {
            display: 'flex',
            flexDirection: 'row',
            width: '100vw',  // 100% of viewport width
            height: '100vh', // 100% of viewport height
            overflow: 'hidden' // Prevents scrollbars if content overflows
        },
        menu: {
            width: '300px',
            height: '100%',   // Full height
            borderRight: '1px solid #ddd',
            padding: '10px',
            overflowY: 'auto' // Allows scrolling for long menus
        },
        content: {
            flex: 1,
            height: '100%',  // Full height
            padding: '10px',
            overflowY: 'auto' // Allows scrolling for long content
        }
    };

  

    return (
        <div style={styles.container}>
          
        </div>
    );
};

export default ModelChange;
