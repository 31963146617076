import React, { useEffect, useState ,useRef} from 'react';
import Uppy from '@uppy/core';
import { Dashboard } from '@uppy/react';
import Tus from '@uppy/tus';
import Button from '@mui/material/Button';
import '@uppy/core/dist/style.css'
import '@uppy/dashboard/dist/style.css'
import SparkMD5 from 'spark-md5';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { getHostName } from './utils/urlUtils';

import { useSnackbar } from './SnackbarProvider';
import LoadingData from './education/dataloading';
import CommonCombobox from './components/CommonCombobox';
import Box from '@mui/material/Box';
import CustomButton from './components/CustomButton';




const UploadFile = ({ options,handleClose,onUpload  }) => {
  const showSnackbar = useSnackbar();

  const [loadingText,setLoadingText] = useState('loading');
    const [uppy, setUppy] = useState(null);
    const { t } = useTranslation();
    const [fileInfo, setFileInfo] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    const needUploadFilesRef = useRef([]);
    const [folderId, setFolderId] = useState('');
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [docLanguage,setDocLanguage] = useState('chinese');//设置文档语言


    const dropdownLanguages = [
      { "id": 'en', "text": 'english' },
      { "id": 'zh', "text": '简体中文' },
      { "id": "zh-TW", "text": "中國台灣" },
      { "id": "zh-HK", "text": "中国香港" },
      { "id": 'ru', "text": 'russian' },
      { "id": 'fr', "text": 'french' },
      { "id": 'de', "text": 'german' },
      { "id": 'jp', "text": 'japanese' },
      { "id": 'ko', "text": 'korean' },
      { "id": "in", "text": "Indonesian" },
      { "id": "ms", "text": "Malay" },
      { "id": "ar", "text": "Arabic" },
      { "id": "vi", "text": "Vietnamese" },
      { "id": "th", "text": "Thai" },
      { "id": "fil", "text": "Filipino" },
    ];

  
    const computeFileMD5 = (file) => {
        return new Promise((resolve, reject) => {
            const blobSlice = File.prototype.slice || File.prototype.mozSlice || File.prototype.webkitSlice;
            const chunkSize = 2097152; // Read in chunks of 2MB
            const chunks = Math.ceil(file.size / chunkSize);
            let currentChunk = 0;
            const spark = new SparkMD5.ArrayBuffer();
            const fileReader = new FileReader();
        
            fileReader.onload = function(e) {
                spark.append(e.target.result);
                currentChunk++;
                if (currentChunk < chunks) {
                    loadNext();
                } else {
                    resolve(spark.end());
                }
            };
    
            fileReader.onerror = function(e) {
                reject(new Error("FileReader error: " + e.toString()));
            };
        
            function loadNext() {
                const start = currentChunk * chunkSize;
                const end = start + chunkSize >= file.size ? file.size : start + chunkSize;
                const blob = blobSlice.call(file.data, start, end);
                fileReader.readAsArrayBuffer(blob);
            }
            loadNext();
        });
    };

    useEffect(() => {
        const uppyInstance = new Uppy({
        autoProceed: false,
        restrictions: {
            maxFileSize: 600000000,
            maxNumberOfFiles: isLoading ? 0 : 1 // 当isLoading为true时，限制文件数量为0，这会禁用上传按钮
        },
    });

        uppyInstance.on('file-added', (file) => {
            console.log(`文件尺寸: ${file.size} bytes`);   
            console.log("File added with name:", file.name);
        });

        uppyInstance.use(Tus, {
            //endpoint: 'https://chentu.online/files/',
            endpoint: 'http://localhost:8080/files/',
            chunkSize: 500 * 1024,
            retryDelays: [0, 1000, 3000, 5000]
        });

        uppyInstance.on('complete', async (result) => {
            console.log('Upload complete!');
            console.log('Updated:', needUploadFilesRef.current);
            const token = localStorage.getItem("access_token");
            const url = getHostName() + '/add_use_docs';
            const response = await fetch(url, {method: 'POST',
            headers: {'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`},
                body: JSON.stringify( needUploadFilesRef.current),
            });
        
            if (!response.ok) {
                throw new Error(`An error occurred: ${response.statusText}`);
            }
        
            let data1 =  await response.json();
            {/*  data返回的格式
            "id": db_user_docs.id,
                        "server_file_name": db_user_docs.server_file_name,
                        "file_extension": db_user_docs.file_extension,
                        "path":db_user_docs.path,
                        "name":db_user_docs.name,
                        "doc_language":db_user_docs.doc_language*/}
            let data = data1.data;
            console.log("插入数据库的返回值:" + JSON.stringify(data)); 
            // 更新 needUploadFilesRef.current 中的 id
            for (let file of needUploadFilesRef.current) {
                const matchingData = data.find(d => d.name === file.name);
                if (matchingData) {
                file.dbid = matchingData.id;
                }
            }
            showSnackbar(t('the file has been uploaded, the background is speeding up the processing, please wait'),t('success'));
            
            if (onUpload) {
                onUpload(needUploadFilesRef.current);
              }
            //handleClose();
            //setIsModalVisible(true);
        });

        uppyInstance.on('upload-success', (file, response) => {
            
            //console.log('Upload success for:', file.name);
            const name = file.name;//原始文件名
            const fileExt = file.name.split('.').pop();//文件扩展名
            const server_file_name = response.uploadURL.split('/').pop(); // 获取保存在服务器上的文件名或路径
            //console.log("sucdess:::::::::::::" + needUploadFilesRef.current);//needs_upload：bool,一共5个name,size,foderId,md5str,

            // 找到需要更新的对象的索引
            const targetIndex = needUploadFilesRef.current.findIndex(file => file.name === name);

            // 如果找到了，直接更新这个对象
            if (targetIndex !== -1) {
            needUploadFilesRef.current[targetIndex].server_file_name = server_file_name;
            needUploadFilesRef.current[targetIndex].file_extension = fileExt;
            }
            
        });

        uppyInstance.on('file-removed', (file) => {
            setFileInfo(prev => prev.filter(f => f.name !== file.name));
        })

        setUppy(uppyInstance);
    }, []);


    useEffect(() => {
        if (uppy) {
            uppy.setOptions({
                restrictions: {
                    maxFileSize: 600000000,
                    maxNumberOfFiles: isLoading ? 0 : null
                }
            });
        }
    }, [isLoading, uppy]);

    //上传按钮
    const handleUpload = async () => {
        console.log("开始上传调用用");
        if(folderId === ''){
            showSnackbar(t('select upload category'),'warning');
            
            return;
        }
        const files = uppy.getFiles();
        if(files.length <= 0){
          showSnackbar(t('select upload file'),'warning');
           
            return;
        }
        setIsLoading(true);
        

        // 使用Promise.all计算每个文件的MD5
        const md5Promises = files.map(file => computeFileMD5(file));
        const md5Results = await Promise.all(md5Promises);


        const detailedFileInfo = files.map((file, index) => {
            return {
                dbid:0, //这是数据库自增id，此时还没有值
                name: file.name,
                size: file.size,
                folderId: folderId,
                md5str: md5Results[index],
                needs_upload:false,
                server_file_name:"",
                file_extension:"",
                doc_language:docLanguage
            };
        });
        
        // 更新fileInfo状态
        needUploadFilesRef.current = [...needUploadFilesRef.current, detailedFileInfo];
          //console.log("第一次更新：" + JSON.stringify(needUploadFilesRef.current));
        const totalSize = detailedFileInfo.reduce((acc, file) => acc + file.size, 0);

        console.log(`所有文件的总尺寸: ${totalSize} bytes`);

        if(totalSize > 600000000){
            showSnackbar(t('exceeding the maximum size of uploaded files'),'warning');
            return;
        }
        let checkResults;
        //发送文件到后端，确认那些文件需要上传，
        try {
            checkResults = await sendFileDetailsToServer(detailedFileInfo);//checkResults的值，在detailedFileInfo的基础上，增加了字段：needs_upload：bool,一共5个name,size,foderId,md5str,

            if(checkResults.status === "failure"){
              alert(checkResults.info);
              return;
            }
            console.log(checkResults); // Handle or display the response from the server
            needUploadFilesRef.current = checkResults;
        } catch (error) {
            console.error("Error while sending data to server:", error);
        } finally {
            setIsLoading(false);
        }

        console.log("checkResults:::" + checkResults);
        await uploadFiles(checkResults);//真正上传文件
        setIsLoading(false);
        //uppy && uppy.upload();
    }

    
    //真正上传文件
    const uploadFiles = async (checkResults) => {
        const allFiles = uppy.getFiles(); // 获取文件列表
    
        // 筛选出真正需要上传的文件
        const filesToUpload = allFiles.filter(file => 
            checkResults.some(uploadFile => uploadFile.name === file.name && uploadFile.needs_upload)
        );
        
        // 确定哪些文件不应被上传
        const filesToRemove = allFiles.filter(file => !filesToUpload.includes(file));
    
        // 从 uppy 实例中移除不应上传的文件
        filesToRemove.forEach(file => {
            uppy.removeFile(file.id);
        });
        
        uppy.upload(); // 上传剩余的文件
    }
    //已经上传完毕，更新服务器db
    const sendToServer = async (interfaceName,fileDetails) => {
       
        const token = localStorage.getItem("access_token");
        const scheme = window.location.protocol;
        const url = getHostName() + '/' + interfaceName; // 你的服务端URL
        const response = await fetch(url, {method: 'POST',
          headers: {'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`},
            body: JSON.stringify(fileDetails),
        });
    
        if (!response.ok) {
            throw new Error(`An error occurred: ${response.statusText}`);
        }
    
        return await response.json();
    };

    //发送要上传的文件到后端，验证那些文件需要上传，哪些不需要上传
    const sendFileDetailsToServer = async (fileDetails) => {
      
        const token = localStorage.getItem("access_token");
        const url = getHostName() + '/compare_file_md5_for_upload'; // 你的服务端URL

        const response = await fetch(url, {method: 'POST',
          headers: {'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`},
            body: JSON.stringify(fileDetails),
        });
    
        if (!response.ok) {
            throw new Error(`An error occurred: ${response.statusText}`); 
        }
    
        return await response.json();
    };

    const handlePauseAll = () => {
        uppy && uppy.pauseAll();
    }

    const handleResumeAll = () => {
        uppy && uppy.resumeAll();
    }
    

    // 中文本地化设置
    const chineseLocale = {
        strings: {
        // 主要的翻译部分
        dropHereOr: '拖文件到此或',
            dropHint: '请将您的文件拖到这里',
            browse: '选择文件',
            dropPaste: '请将文件拖到此处或 %{browse}',
        dropPasteImport: '将文件拖放到此处、粘贴或 %{browse}',
        browse: '选择',
        fileSource: '文件来源',
        done: '完成',
        folderAdded: {
            '0': '从 %{folder} 文件夹添加了 %{smart_count} 个文件',
            '1': '从 %{folder} 文件夹添加了 %{smart_count} 个文件',
        },
        import: '导入',
        importFrom: '从 %{name} 导入',
        loading: '加载中...',
        myDevice: '我的设备',
        noFilesFound: '您没有任何文件',
        noInternetConnection: '无法连接到互联网',
        openFolderNamed: '打开文件夹 %{name}',
        pause: '暂停',
        paused: '已暂停',
        poweredBy: '技术支持',
        processingXFiles: {
            '0': '正在处理 %{smart_count} 个文件',
            '1': '正在处理 %{smart_count} 个文件',
        },
        removeFile: '删除文件',
        resetFilter: '重置过滤器',
        resume: '继续',
        retry: '重试',
        retryUpload: '重试上传',
        saveChanges: '保存更改',
        selectAllFiles: '选择所有文件',
        selectXFiles: {
            '0': '选择了 %{smart_count} 个文件',
            '1': '选择了 %{smart_count} 个文件',
        },
        smile: '请微笑!',
        unselectAllFiles: '取消选择所有文件',
        upload: '上传',
        uploadComplete: '上传完成',
        uploadFailed: '上传失败',
        uploadPaused: '上传已暂停',
        uploadXFiles: {
            '0': '上传 %{smart_count} 个文件',
            '1': '上传 %{smart_count} 个文件',
        },
        uploadXNewFiles: {
            '0': '上传 +%{smart_count} 个新文件',
            '1': '上传 +%{smart_count} 个新文件',
        },
        xFilesSelected: {
            '0': '选择了 %{smart_count} 个文件',
            '1': '选择了 %{smart_count} 个文件',
        },
        xMoreFilesAdded: {
            '0': '又添加了 %{smart_count} 个文件',
            '1': '又添加了 %{smart_count} 个文件',
        },
        xTimeLeft: '还剩 %{time} 时间',
        youCanOnlyUploadFileTypes: '您只能上传： %{types}',
        }
    };
    

  const [uploadProgress, setUploadProgress] = useState(0);


  const uploadFilesToAliyunOss = async (files) => {
    for (const uppyFile of files) {
      const { name, type, data } = uppyFile; // data 是一个 Blob 对象
      const extension = name.split('.').pop();
  
      if (['mp4', 'wmv','mp3'].includes(extension)) { // 如果文件是视频
        const token = localStorage.getItem("access_token");
        const dataToSend = {
          filename: name
        };
        const url = getHostName() + '/generate_upload_params';
  
        const response = await fetch(url, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
          },
          body: JSON.stringify(dataToSend)
        });
  
        if (response.ok) {
          const data = await response.json();
  
          const formData = new FormData();
          formData.append("key", data.key);
          formData.append("OSSAccessKeyId", data.OSSAccessKeyId);
          formData.append("policy", data.policy);
          formData.append("Signature", data.Signature);
          formData.append("file", uppyFile.data); // 使用 Uppy 的 Blob 对象
  
          const xhr = new XMLHttpRequest();
          xhr.open("POST", `https://${data.bucket}.${data.endpoint}`);
          xhr.upload.onprogress = (e) => {
            if (e.lengthComputable) {
              const percentage = (e.loaded / e.total) * 100;
              setUploadProgress(percentage);
            }
          };
  
          xhr.onload = () => {
            if (xhr.status >= 200 && xhr.status < 300) {
              console.log("Upload successful!");
              setUploadProgress(0);
            } else {
              console.error("OSS Error Response:", xhr.status, xhr.statusText, xhr.responseText);
              throw new Error("Error uploading file to OSS");
            }
          };
  
          xhr.onerror = () => {
            throw new Error("Error uploading file to OSS");
          };
  
          xhr.send(formData);
        } else {
          const errorData = await response.text();
          console.error("Error fetching upload params:", errorData);
        }
      }
    }
  };
  const isMobile = window.innerWidth <= 768; // 假设宽度小于或等于768px的设备是手机

  const handleOk = () => {
    const files = uppy.getFiles();
    uploadFilesToAliyunOss(files);
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    console.log('Clicked Cancel');
    setIsModalVisible(false);
  };
    
    return (
      <Box width={500}>
        <Box
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        alignItems="center"
        marginBottom={1} // MUI 的 spacing 默认单位是 8px，这里使用 1 代表 8px
        marginTop={1} // 同上，根据需要调整这些值
        margin={2}
      >
      {options ? (  // 确保 folderdata 不是 undefined 或 null
        <CommonCombobox
        preferredValue={folderId}
        setPreferredValue={setFolderId}
        options={options}
        
        placeholder={t('upload catalog')}
        labelText={t('upload catalog')}
      />
    ) : (
      <p>Loading...</p>  // 当 folderdata 是 null 或 undefined 时显示
    )}
      <CommonCombobox
      preferredValue={docLanguage}
      setPreferredValue={setDocLanguage}
      options={dropdownLanguages}
      
      placeholder={t('select language')}
      labelText={t('document language')}
    />
        </Box>
        <div style={{fontSize:'14px', color:'black',marginLeft:'10px'}}>{t('upload files support breakpoint continuous transmission, support md, pdf, txt audio, video files m4a, mp3, webm, mp4, mpga, wav, mpeg')}</div>
        <div style={{fontSize:'14px', color:'black',marginLeft:'10px'}}>{t('if audio or video files are included, after uploading successfully, it will take about 1 minute to 15 minutes to transcribe the text according to the file size, please wait patiently.')}</div>
        <Box>
        {uppy ? <Dashboard 
          uppy={uppy} note="" 
          hideUploadButton={true} 
          inline={true} 
          proudlyDisplayPoweredByUppy={false} 
          locale={chineseLocale} 
          metaFields={[]} 
          height={300}/> : null}

          <Box
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              alignItems: 'center',
              gap: '1px', // 控件之间的间隔
              marginRight: '10px', // 右边距
            }}
          >
              <CustomButton onClick={handleUpload} style={{ margin: '10px' }} disabled={isLoading}>
                  {t('upload file')}
              </CustomButton>
              <CustomButton onClick={handlePauseAll} style={{ margin: '10px' }} disabled={isLoading}>
                  {t('pause upload')}
              </CustomButton>
              <CustomButton onClick={handleResumeAll} style={{ margin: '10px' }} disabled={isLoading}>
                  {t('recovery upload')}
              </CustomButton>
              <CustomButton onClick={handleClose} style={{ margin: '10px' }} disabled={isLoading}>
                  {t('close')}
              </CustomButton>
            </Box>
      </Box>
      {isLoading && (
        <LoadingData text={t(loadingText)}/>
      )}
      
    </Box>
    );
    

}

export default UploadFile;
