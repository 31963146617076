import React from 'react';
import { Paper, Card, CardActionArea, CardContent, CardMedia, Typography, Grid, useMediaQuery, useTheme } from '@mui/material';

// 模拟的数据数组
const data = [
  {
    id: 1,
    title: "秋日之森",
    content: "深秋的森林，树叶变成了金色，一片宁静而美丽的景象。",
    backgroundImage: "default/k1.png",
  },
  {
    id: 2,
    title: "冬夜星空",
    content: "寒冬夜晚，星星在无云的天空中闪烁，宁静而深邃。",
    backgroundImage: "default/k1.png",
  },
  {
    id: 3,
    title: "春日花海",
    content: "春天到来，大地回暖，万物复苏，花儿竞相开放，构成了一片绚烂的花海。",
    backgroundImage: "default/k1.png",
  },
  {
    id: 4,
    title: "夏日海滩",
    content: "夏日炎炎，一片清凉的海滩，海浪拍打着沙滩，带来阵阵凉爽。",
    backgroundImage: "default/k1.png",
  },
  {
    id: 5,
    title: "古城夕阳",
    content: "夕阳西下，金色的阳光洒在古老的城墙上，历史的沧桑与自然的美丽交织。",
    backgroundImage: "default/k1.png",
  },
  {
    id: 6,
    title: "繁星之下",
    content: "在遥远的乡村，远离城市的喧嚣，仰望夜空，繁星点点，如梦如幻。",
    backgroundImage: "default/k1.png",
  },
  // 添加更多项目...
];


const ResponsiveCards = () => {
  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.down('md'));

  const handleCardClick = (id) => {
    console.log("Card clicked:", id);
    // 这里可以添加点击事件的具体处理逻辑
  };

  return (
    <Paper style={{ padding: 20, background: '#f5f5f5' }}>
      <Grid container spacing={2}>
        {data.map(({ id, title, content, backgroundImage }) => (
          <Grid item xs={12} sm={6} md={4} key={id}>
            <Card>
              <CardActionArea onClick={() => handleCardClick(id)}>
                <CardMedia
                  component="img"
                  height="140"
                  image={backgroundImage}
                  alt={title}
                />
                <CardContent>
                  <Typography gutterBottom variant="h5" component="div">
                    {title}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    {content}
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Paper>
  );
};

export default ResponsiveCards;
