import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { Button, Tooltip, Spin } from 'antd';
import CustomButton from '../../components/CustomButton';
import { PlayCircleOutlined, TranslationOutlined, StarOutlined, CopyOutlined, LoadingOutlined } from '@ant-design/icons';
import { getHostName } from '../../utils/urlUtils';
const MessageContainer = styled.div`
position: relative;  // 添加这一行
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin-bottom: 20px;
`;

const EnglishText = styled.p`
  margin: 0;
  cursor: pointer;
  border-bottom: 1px solid white;
  padding-bottom: 5px;
`;

const ChineseText = styled.p`
  margin: 0;
  display: ${props => (props.show ? 'block' : 'none')};
  border-bottom: 1px solid white;
  padding: 5px 0;
`;

const AudioButton = styled(Button)`
  margin-top: 5px;
  color: white;
`;

const TranslationButton = styled(Button)`
  margin-left: 10px;

  color:white;
`;


const WordTooltip = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);  // 添加这一行
  background-color: #87CEFA;  
  color: white;
  border-radius: 5px;
  padding: 5px;
  width: auto;
  max-width: 50px;
  display: ${props => (props.show ? 'block' : 'none')};
  font-size: 13px;
  font-weight: bold;
  font-family: 'Arial', sans-serif;
  z-index: 9999;  // High z-index to overlap other content

`;


const TooltipIcons = styled.div`
  display: flex;
  justify-content: space-between;
`;


const AvatarContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 10px;
`;

const AvatarImage = styled.img`
  border-radius: 50%;
  width: 40px;
  height: 40px;
`;

const Timestamp = styled.span`
  margin-top: 5px;
  font-size: 10px;  // 设置字体大小
  color: #808080;  // 设置字体颜色
`;

const ContentAndIconContainer1 = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
`;

const ContentAndIconContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start; // Ensures elements are left-aligned
  width: 85%; // Takes up as much width as possible
  margin-top: 10px; 
`;


const Content = styled.div`
  flex: 1; // Takes up all available space
  margin: 0 10px;
  background-color: ${props => (props.messageType === 'R' ? '#006400' : '#483D8B')};
  border-radius: 10px;
  padding: 10px;
  color: white;
`;

const MessageEng = ({chatid, messageType, englishText, audioUrl, chineseText, time, avatarUrl }) => {
  const [showChinese, setShowChinese] = useState(false);
  const [showWordInfo, setShowWordInfo] = useState(false);
  const [loading, setLoading] = useState(false);
  const [translation, setTranslation] = useState('');
  const tooltipRef = useRef(null);
  const [starClicked, setStarClicked] = useState(false);
  const [tooltipPosition, setTooltipPosition] = useState({ top: 0, left: 0 });
  const englishTextRef = useRef(null);

  const handleStarClick = () => {
    setStarClicked(!starClicked);
    console.log("Star clicked");
  };


  const handleCopyClick = () => {
    console.log("Copy clicked");
  };

  const handleClickOutside = (event) => {
    if (tooltipRef.current && !tooltipRef.current.contains(event.target)) {
      setShowWordInfo(false);
    }
  };

  const handleAudioPlay = async () => {
    // TODO: Play the audio from audioUrl
    console.log("chatid:" + chatid);
    const token = localStorage.getItem('access_token');
    try {
      const url = getHostName() + '/get_chat_audio'; // 你的服务端URL
      const response = await fetch(url, {method: 'POST',
        headers: {'Content-Type': 'application/json',
                  'Authorization': `Bearer ${token}`},
                  body:JSON.stringify({ chatid:chatid})
                });

      
      if (response.ok) {
        const audioFiles = await response.json();  // 直接获取JSON响应体
        const parsedData = JSON.parse(audioFiles);
        console.log(parsedData);
        let aaa = Array.isArray(parsedData);
        console.log("is array:" + aaa);
        // 现在audioFiles是一个对象数组，您可以按照需求使用它
        
        const audioObjects = [];
        parsedData.forEach((item, index) => {

          console.log(item.audio_filename);
          const audioUrl = `userdocs/englishMP3/${item.audio_filename}`;  // 请根据实际情况更改 URL
          console.log(audioUrl);
          const audio = new Audio(audioUrl);
          audioObjects.push(audio);
      

          // 播放第一个音频
          if (audioObjects.length > 0) {
            console.log("Attempting to play the first audio:", audioObjects[0].src);
            audioObjects[0].play().then(() => {
                console.log("Audio started playing");
            }).catch((error) => {
                console.log("Error playing audio:", error);
            });
          }

          // 当一个音频播放完毕时，播放下一个
          audio.addEventListener('ended', () => {
              if (index < audioObjects.length - 1) {
                  audioObjects[index + 1].play();
              }
          });
      });
        

      }
      else {
          console.error("Error fetching reply:", response.statusText);
      }
  }
  catch (error) {
      console.error("There was an error fetching the reply:", error);
  }

  };

  const handleWordClick = async (word, index) => {
    if (englishTextRef.current) {
      const wordElement = englishTextRef.current.childNodes[index];
      const rect = wordElement.getBoundingClientRect();


      console.log("Element top position:", rect.top);
      console.log("Element right position:", rect.right);
      console.log("Element bottom position:", rect.bottom);
      console.log("Element left position:", rect.left);
      console.log("Element width:", rect.width);
      console.log("Element height:", rect.height);

      console.log("rect is:" + rect);
      setTooltipPosition({
        top: rect.bottom + window.scrollY + 10,
        left: rect.left + window.scrollX + rect.width / 2
      });
    }
    setShowWordInfo(true);
    setLoading(true);
    console.log(word);
    // Simulated API call




    const token = localStorage.getItem('access_token');
    try {
      const url = getHostName() + '/translate'; // 你的服务端URL
      const response = await fetch(url, {method: 'POST',
        headers: {'Content-Type': 'application/json',
                  'Authorization': `Bearer ${token}`},
                  body: JSON.stringify({ transtext: word }) 
                });
      if (response.ok) {
        const data = await response.json();
        setTranslation(data.translated_text);
        setLoading(false);
      }
      else {
          console.error("Error fetching reply:", response.statusText);
          if (loading) {
            setLoading(false);
            setTranslation('请求超时');
          }
      }
  }
  catch (error) {
      console.error("There was an error fetching the reply:", error);
  }
    
  };



  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <MessageContainer>
      <AvatarContainer>
        <AvatarImage src={avatarUrl} alt="avatar" />
        <Timestamp>{time}</Timestamp>
      </AvatarContainer>
      <ContentAndIconContainer>
        <Content messageType={messageType}>
        <EnglishText ref={englishTextRef}>
          {englishText.split(' ').map((word, i) => (
            <span key={i} onClick={() => handleWordClick(word, i)}>{word} </span>
          ))}
        </EnglishText>

          <WordTooltip show={showWordInfo} ref={tooltipRef} >
            {loading ? <Spin indicator={<LoadingOutlined />} /> : translation}
            <TooltipIcons>
              <Button 
                type="text" 
                icon={<StarOutlined />} 
                style={{ color: starClicked ? 'yellow' : 'inherit' }} 
                onClick={handleStarClick} 
              />
              <Button type="text" icon={<CopyOutlined />} onClick={handleCopyClick} />
            </TooltipIcons>
          </WordTooltip>
          <div style={{ 
              display: 'flex', 
              alignItems: 'flex-end', 
              justifyContent: 'center', // This will center the items horizontally
              width: '100%', 
          }}>
            <TranslationButton type="text" style={{ height: '40px', margin: '0' }} icon={<TranslationOutlined /> } onClick={() => setShowChinese(!showChinese)} />
            <AudioButton type="text" icon={<PlayCircleOutlined /> } onClick={handleAudioPlay} style={{ height: '40px', margin: '0' }}>
                
            </AudioButton>
          </div>

          <ChineseText show={showChinese}>{chineseText}</ChineseText>
        </Content>
        
      </ContentAndIconContainer>
    </MessageContainer>
  );
  
  
};

export default MessageEng;
