import React, { useState,useEffect } from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import AnalysisIcon from '@mui/icons-material/Assessment'; // 假设这是分析题目的图标
import 'katex/dist/katex.min.css';
import katex from 'katex';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import { blue, orange } from '@mui/material/colors';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from '../../SnackbarProvider';
import { requestUrl } from '../../requestUrl';
import LoadingData from '../dataloading';
import { Paper } from '@mui/material';
import CustomButton from '../../components/CustomButton';
// 外面的壳子
const ChatCommonSessionClassComponent = ({commonChatSessionClassName,commonChatSessionClassId,onClose,onConfirm,onHiddenClassDetail}) => {
  const showSnackbar = useSnackbar();
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [loadingText,setLoadingText] = useState('loading');
    let data1 = [
        {
          "id": 1,
          "status": true,
          "question": "解方程 $x^2 - 5x + 6 = 0$",
          "answer":"",
          "topics": ["代数", "方程"]
        },
        {
          "id": 2,
          "status": false,
          "question": "计算积分 $\\int_0^1 x^2 dx$",
          "answer":"",
          "topics": ["微积分", "积分"]
        },
        {
          "id": 3,
          "status": true,
          "question": "简化表达式 $\\frac{a}{b} + \\frac{b}{a}$",
          "answer":"",
          "topics": ["分数", "代数"]
        },
        {
          "id": 4,
          "status": false,
          "question": "求解不等式 $3x + 2 > x - 5$",
          "answer":"",
          "topics": ["代数", "不等式"]
        },
        {
          "id": 5,
          "status": true,
          "question": "求圆的面积，给定半径 $r = 5$",
          "answer":"",
          "topics": ["几何", "面积"]
        },
        {
          "id": 6,
          "status": false,
          "question": "计算斜边为 $c$ 的直角三角形的面积，已知两直角边为 $a$ 和 $b$",
          "answer":"",
          "topics": ["勾股定理", "几何"]
        }
      ]

  const [data,setData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
        try {
            setIsLoading(true);
            setLoadingText("loading");
            const dataToSend = {
                id:commonChatSessionClassId,
            };
            const data = await requestUrl(dataToSend, "get_common_chat_session_by_classId",t,showSnackbar);
            if(data.status === "success"){
              console.log(data.data);
              setData(data.data);
            }
            
            
          } catch (error) {
              console.error("获取数据时出错:", error);
          } finally {
            setIsLoading(false);
          }
    };

    fetchData();
  }, [commonChatSessionClassId]);



  const renderTextAndMath = (text) => {
    if (text === undefined) {
      text = "";
    }
    const regex = /\$(.+?)\$/g;
    let result = '';
    let lastIndex = 0;

    text.replace(regex, (match, formula, index) => {
      // 添加普通文本
      result += text.slice(lastIndex, index);
      // 将KaTeX公式转换为HTML
      try {
        result += katex.renderToString(formula, { throwOnError: false });
      } catch (e) {
        console.error(e);
        result += match; // 如果出错，就使用原始文本
      }
      lastIndex = index + match.length;
    });

    // 确保添加最后一段普通文本
    result += text.slice(lastIndex);
    return result;
  };


  const [selectedCardProblem,setSelectedCardProblem] = useState({});
  const handleClickCard = (item) => {
    console.log(item.session_name);
    //setShowExamPaperProblem(true);
    setSelectedCardProblem(item);
    onConfirm(item);
  }
  

  const handleHiddenClassDetail = () => {
    onHiddenClassDetail();
  }


    return (
      <Paper 
        style={{
          zIndex: 9999,
          display: 'flex',
          flexDirection: 'column', // 由于我们希望内容垂直堆叠，需要设置flex方向
          backgroundColor: '#FFCC33',
          position: 'fixed',
          left: '50%',
          top: '50%',
          height:'80%',
          width:'80%',
          transform: 'translate(-50%, -50%)',
          margin: '20px',
          padding: '20px'
        }} 
        elevation={3}
     >
        <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%',width:'100%',gap:'10px' }}>
        <div style={{ display: 'flex', justifyContent: 'center' }}>{commonChatSessionClassName}</div>
           <Grid container spacing={2} sx={{ maxHeight: '100%', overflowY: 'auto' }}>
              {data.map((item) => (
                <Grid item xs={12} sm={6} md={6} key={item.id}>
                  <Card
                  onClick={() => handleClickCard(item)}
                  sx={{ cursor:'pointer', minWidth: 275, backgroundColor: '#003366',borderRadius:'10px', borderColor: '#b3e5fc', borderWidth: 1, borderStyle: 'solid' }}>
                    <CardContent>
                      <Typography sx={{ fontSize: 14, color: 'white', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                        {item.status ?  <TaskAltIcon style={{ color: blue[500], fontSize: 30 }} /> : <HighlightOffIcon style={{ color: orange[500], fontSize: 30 }} />}
                        <IconButton aria-label="analyze" style={{ color: '#ff9700' }}>
                          <AnalysisIcon />
                        </IconButton>
                      </Typography> 
                      <Typography sx={{ mb: 1.5, color: 'white' }} dangerouslySetInnerHTML={{ __html: renderTextAndMath(item.session_name) }}>
                        {/* 空容器，内容通过dangerouslySetInnerHTML插入 */}
                      </Typography>
                      <Typography sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                        {item.recordtime} {t(item.subject)} {t(item.qtype_name)}
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
              ))}

              
    </Grid>
    <CustomButton onClick={handleHiddenClassDetail}>{t('close')}</CustomButton>
    {isLoading && (
        <LoadingData text={t(loadingText)}/>
      )}
        
    </Box>
    </Paper>
    )
}

export default ChatCommonSessionClassComponent