import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { SnackbarProvider } from './SnackbarProvider.jsx';
import './i18n';
import { HashRouter } from 'react-router-dom';
import {
  FpjsProvider
} from '@fingerprintjs/fingerprintjs-pro-react'
// 状态保留
import { Provider } from 'react-redux'
import store from './store/index.js'
import './fonts.css';

import ThemeProvider from './ThemeContext.js';
import { useContext } from 'react';
import { ThemeContext } from './ThemeContext';


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <Provider store={store}>
    <SnackbarProvider>
      <App />
      </SnackbarProvider>
    </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
