import React, { useState, useEffect, useRef } from 'react';
import ChatWindow from '../ChatWindow/ChatWindow';
import { message as sysMessage } from 'antd';
import { getHostName } from '../../utils/urlUtils';
import CommonCombobox from '../../components/CommonCombobox';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, MenuItem, IconButton } from '@mui/material';
import { Menu as MuiMenu } from '@mui/material';
import styled from 'styled-components';
import './Student.css';
import AudioRecorder from '../AudioRecorder/AudioRecorder';
import { useTranslation } from 'react-i18next';
import ReactMarkdown from 'react-markdown';
import { deviceType_m_t_d } from '../../utils/commonUtils';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, TablePagination } from '@mui/material';
import HistoryIcon from '@mui/icons-material/History';
import ClearIcon from '@mui/icons-material/Clear';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import SystemHelp from '../SystemHelp/SystemHelp';
import TextField from '@mui/material/TextField';
import { useNavigate } from 'react-router-dom';
import { requestUrl } from '../../requestUrl';
import DeleteIcon from '@mui/icons-material/Delete';
import { Refresh } from '@mui/icons-material';
import { get_gradelist_by_subject } from '../../utils/commonUtils';
import LoadingData from '../dataloading';

export const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 6666;
`;

export const ModalContainer = styled.div`
  background-color: white;
  padding: 10px;
  width: 100%;
  max-width: 800px;
  height: 100%;
  max-height: 600px;
  overflow-y: auto;
  border-radius: 8px;
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.1);
`;

const Container = styled.div`
  width: 100%;
  height: 85vh;
  @media (min-width: 768px) {  /* 针对平板和电脑屏幕 */
    width: 98%;
  }
`;

const Top = styled.div`
  height: 30px;
  background-color: #1565c0;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  & > * {
    margin-left: 10px;
    margin-right: 10px;
  }
`;

const Middle = styled.div`
  height: calc(90vh -40px);  /* 计算剩余高度 */
  background-color: #eee;
  
`;

const Bottom = styled.div`
  height: 35px;
  background-color: #1565c0;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

const Div1 = styled.div`
  margin-right: auto; 
  margin-left: 10px; // 紧贴最左侧，距离左边间隔10px
  display: flex; 
  justify-content: flex-end;
  align-items: center; // 增加这一行来实现垂直居中
  & > * {                   // 选择所有直接子元素
    margin-right: 10px;    // 设置子元素间的间隔为10px
  }

  & > :last-child {        // 选择最后一个子元素
    margin-right: 0;       // 取消最后一个子元素的右侧间距
  }
`;

const Div2 = styled.div`
  display: flex;           // 开启Flex布局
  justify-content: flex-end; // 子元素水平排列并紧贴最右侧
  margin-left: auto;        // 紧贴最右侧
  margin-right: 10px;       // 距离最右侧10px间隔
  align-items: center; // 增加这一行来实现垂直居中
  & > * {                   // 选择所有直接子元素
    margin-right: 10px;    // 设置子元素间的间隔为10px
  }

  & > :last-child {        // 选择最后一个子元素
    margin-right: 0;       // 取消最后一个子元素的右侧间距
  }
`;

const StyledButton = styled(Button)`
  margin-top: 200px; // 用于演示向上弹出

`;

const Student = () => {
  const { t } = useTranslation();
  const [selectedGradeOption, setSelectedGradeOption] = useState('7');
  const [selectedSubjectOptionText, setSelectedSubjectOptionText] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [messages, setMessages] = useState([]);
  const [inputText, setInputText] = useState(''); // 文本框的值
  const [isKeepHistory, setIsKeepHistory] = useState(false);
  const [uploadedImage, setUploadedImage] = useState(null); //从inputarea中传过来的上传图片
  const [cuspredefinedContent, setCuspredefinedContent] = useState([]);
  const [open, setOpen] = useState(false);
  const [menuAnchorElement, setMenuAnchorElement] = useState(null);
  const [selectedType, setSelectedType] = useState(t('Common Q'));
  const [selectedTypeId, setSelectedTypeId] = useState('1');
  const [menuAnchorElementPage, setMenuAnchorElementPage] = useState(null);
  const [selectedPageNumber, setSelectedPageNumber] = useState(1);
  const [pageList, setPageList] = useState([1]);
  const [isShowAudioRecorder, setIsShowAudioRecorder] = useState(false);
  const [translateText, setTranslateText] = useState('');
  const [anchorEl, setAnchorEl] = useState(null)
  const blankImageList = useRef([]);
  const blankMarkdownText = useRef(null);
  const [isOpenHistory, setIsOpenHistory] = useState(false);
  const [blankPageContentElement, setBlankPageContentElement] = useState();//填充刚加载页面的空白element
  const [isDisplayBlankContent, setIsDisplayBlankContent] = useState(true);
  const [sessionChatId, setSessionChatId] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [numberOfRowsPerPage, setNumberOfRowsPerPage] = useState(10);
  const [selectedRowId, setSelectedRowId] = useState(null);
  const [tableData, setTableData] = useState([]);
  const [selectedRowData, setSelectedRowData] = useState(null);
  const [isNeedCreateSeesionChat, setIsNeedCreateSeesionChat] = useState(true);
  const [isShowTextOCRDiv, setIsShowTextOCRDiv] = useState(false);//显示语文英语的图片识别，主要用于作文点评
  const inputAreaRef = useRef(null);

  const [dialogOpen, setDialogOpen] = useState(true);
  const [password, setPassword] = useState('');
  const [loadingText,setLoadingText] = useState('loading');
  const navigate = useNavigate();

  const [questionTypeList, setQuestionTypeList] = useState([
    { id: 1, value: '通用问题' },
    { id: 2, value: '古诗文' },
    { id: 3, value: '作文点评' },
    { id: 3, value: '作文点评' },
    { id: 3, value: '作文点评' },
    { id: 3, value: '作文点评' },
    { id: 3, value: '作文点评' },
  ]);


  const flagMessFromItem = useRef(false);
  const [gradeOptions, setGradeOptions] = useState([
    { id: '1', text: '1' },
    { id: '2', text: '2' },
    { id: '3', text: '3' },
    { id: '4', text: '4' },
    { id: '5', text: '5' },
    { id: '6', text: '6' },
    { id: '7', text: '7' },
    { id: '8', text: '8' },
    { id: '9', text: '9' },
    { id: '10', text: '10' },
    { id: '11', text: '11' },
    { id: '12', text: '12' },
    // ... Add more options
  ]);

  const subjectOptions = [
    { id: 'chinese', text: t('chinese') },
    { id: 'mathematics', text: t('mathematics') },
    { id: 'english', text: t('english') },
    { id: 'physics', text: t('physics') },
    { id: 'chemistry', text: t('chemistry') },
    { id: 'geography', text: t('geography') },
    { id: 'biology', text: t('biology') },
    // ... Add more options
  ];

  // 如果需要在组件加载时自动弹出对话框，可以使用useEffect
  useEffect(() => {
    setDialogOpen(true);
  }, []);

  const [isShowSystemHelp, setIsShowSystemHelp] = useState(false);
  const onCloseHelp = () => {

    setIsShowSystemHelp(false);
  }


  const handleSystemHelp = () => {
    setIsShowSystemHelp(true);
  };


  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const year = date.getFullYear().toString().slice(-2); // 获取年份的后两位
    const month = String(date.getMonth() + 1).padStart(2, '0'); // 月份从0开始，所以要+1
    const day = String(date.getDate()).padStart(2, '0');
    const hour = String(date.getHours()).padStart(2, '0');

    return `${year}-${month}-${day}:${hour}`;
  };

  const loadSubjectData = async (page) => {
    if (!selectedGradeOption) {
      sysMessage.warning("通过设置,请先选择年级!");
      return;
    }

    const token = localStorage.getItem("access_token");
    setAnchorEl(null);
    try {
      setIsLoading(true);
      const dataToSend = {
        subject: '',
        grade_value: selectedGradeOption,
        common_chat_session_id: sessionChatId,
        page_size: 10,
        page_number: page,
      };

      const url = getHostName() + '/get_student_history';
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify(dataToSend),
      });
      if (response.ok) {
        setIsDisplayBlankContent(false);//默认空白页的图片不显示
        const data = await response.json();

        setMessages(data.data);
        //setCuspredefinedContent(data.prompts);
        //setQuestionTypeList(data.qtypes);

        setIsKeepHistory(true);
        if (page === 1) {
          setPageList(data.total_page);
        }
        setIsNeedCreateSeesionChat(false);//无需创建sessionchat表数据
        setIsLoading(false);
      }
      else {
        console.error("Error fetching reply:", response.statusText);
      }
    }
    catch (error) {
      setIsLoading(false);
      console.error("There was an error fetching the reply:", error);
    }
  }
  const [detailPrompts, setDetailPrompts] = useState([]);
  //加载推荐问题列表和问题类型列表
  const load_prompts_questionTypes = async (subject, grade) => {
    const token = localStorage.getItem("access_token");
    try {
      setIsLoading(true);
      const dataToSend = {
        grade_value: grade,
      };

      const url = getHostName() + '/get_student_prompts'; // 你的服务端URL
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify(dataToSend),
      });
      if (response.ok) {

        const data = await response.json();
        setCuspredefinedContent(data.prompts);
        setQuestionTypeList(data.qtypes);
        let detailPrompts = data.question_types_details_prompts;
        setDetailPrompts(detailPrompts);

        blankImageList.current = data.images;
        blankMarkdownText.current = data.mdtext;

        let element = renderOverlay(data.images, data.mdtext);
        setBlankPageContentElement(element);
        setIsLoading(false);
        setTableData([]);

        setSessionChatId(0);
        setSelectedRowId(0);
      }
      else {
        console.error("Error fetching reply:", response.statusText);
      }
    }
    catch (error) {
      setIsLoading(false);
      console.error("There was an error fetching the reply:", error);
    }
  };

  const set_problem_type = async (problemTypeId) => {
    setSelectedTypeId(problemTypeId); //问题类型设置为默认
    const item = questionTypeList.find(item => item.id === problemTypeId);
    setSelectedType(item.value);//修改界面
  }


  const handleSendMessage = async (message) => {
    if (message === "") {
      sysMessage.warning(t('please input content'));

      return;
    }

    if (selectedGradeOption === null) {
      sysMessage.warning(t('you must select subject and grade'));
      setTimeout(() => {
        inputAreaRef.current.setInputValue(message);
      }, 1000);

      return;
    }

    setIsDisplayBlankContent(false);
    let dddd;
    if (uploadedImage) {
      const dddd = `<img src=${uploadedImage} style="height: 50vh; max-width: 100vw;" alt="upload Image"/>`;
      message = message + dddd;
      // 有图片
    } else {
      // 没有图片
    }

    // 处理发送消息逻辑
    setMessages(prevMessages => [...prevMessages, { sender: 'S', text: message, time: new Date().toLocaleTimeString() }]);
    try {

      const token = localStorage.getItem("access_token");
      setIsLoading(true);

      let dataimage = null;
      if (uploadedImage === null) {
        dataimage = 'none';
      }
      else {
        dataimage = uploadedImage;
      }
      //qtype
      let messsend = message;

      if (flagMessFromItem.current == true) {
        messsend = messsend + "<questionflag>chatinner</questionflag>";
        flagMessFromItem.current = false;

      }
      const dataToSend = {
        gradeValue: selectedGradeOption,
        inputValue: message,
        qtype: selectedTypeId,
        isKeepHistory: isKeepHistory,
        image: dataimage,
        common_chat_session_id: sessionChatId,
        isNeedCreateSeesionChat: isNeedCreateSeesionChat,
      };
      const url = getHostName() + '/question_student'; // 你的服务端URL
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify(dataToSend),
      });

      if (response.ok) {
        setUploadedImage(null);
        setIsKeepHistory(true);//保留记忆
        set_problem_type(1);
        const dat_server = await response.json();
        const { dbid, dbsessionid, response_text } = dat_server;
        if (sessionChatId === '0') { //刚加载为0 ，新建为0，对话后，这个返回值肯定大于0，只要开始对话，这个值就会大于0，因为数据库新增了记录或者从客户端直接把大于0 的值带了过去，然后同样回传
          setSessionChatId(dbsessionid);
        }
        setMessages(prevMessages => [...prevMessages, { id: dbid, sender: 'R', text: response_text, time: new Date().toLocaleTimeString() }]);
        //uploadedImage = null;
        setUploadedImage(null);
        setIsLoading(false);

        setIsNeedCreateSeesionChat(false);
      }
      else {
        console.error("Error fetching reply:", response.statusText);
        set_problem_type(1);
      }
    }
    catch (error) {
      setIsLoading(false);
      set_problem_type(1);
      console.error("There was an error fetching the reply:", error);
    }
  };

  //选择年级事件
  const handleMenuItemGradeClick = async (e) => {
    setSelectedGradeOption(e.text); // 2. 设置选中的选项

    localStorage.setItem('familyheagrade', e.text);
    load_prompts_questionTypes('', e.text);
  };

  useEffect(() => {

    const defaultGrade = localStorage.getItem('familyheagrade');//此处存储的是数字1-12，而不是汉字，
    if (defaultGrade) {
      setSelectedGradeOption(defaultGrade);
      load_prompts_questionTypes('', defaultGrade);
    }
    else {
      load_prompts_questionTypes('', "1");
    }


  }, []);

  const handleClearRecord = () => {
    setMessages([]);
    setIsKeepHistory(false);//不保留记忆，已经清除记录
    setAnchorEl(null);
    setIsDisplayBlankContent(true);//显示空白页的默认图
    setIsNeedCreateSeesionChat(true);
    setSelectedRowId(0);
    setSessionChatId(0);
  }


  const onReceiveStringFromMessItem = (str) => {
    // 处理发送消息逻辑
    flagMessFromItem.current = true;

    handleSendMessage(str);
  };

  const handleImageUpload = (imageData) => {
    setUploadedImage(imageData);
  }

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleClick11 = (event) => {
    setMenuAnchorElement(event.currentTarget);
  };

  //选择问题类型事件
  const handleClose11 = (id, type) => {
    console.log(id);
    setMenuAnchorElement(null);
    if (type !== null && id !== null) {
      setSelectedType(type);
      setSelectedTypeId(id);

      setCuspredefinedContent(detailPrompts[id]);
    }
  };


  const handleClickPageNumber = (event) => {
    setMenuAnchorElementPage(event.currentTarget);
  };

  const handleClosePage = (pagenumber) => {
    setMenuAnchorElementPage(null);
    if (pagenumber !== null) {
      setSelectedPageNumber(pagenumber);
    }
  };

  const handlePageChange = (pagenumber) => {
    setMenuAnchorElementPage(null);
    if (pagenumber !== null) {
      setSelectedPageNumber(pagenumber);
      loadSubjectData(pagenumber);
    }
  };

  const handleAudioRecord = () => {
    setIsShowAudioRecorder(true);
  }

  const onCloseOnly = () => {
    setIsShowAudioRecorder(false);
  }

  const onCloseAudioRecord = () => {
    setIsShowAudioRecorder(false);
    //setIsetInputValueEnable(true);
    inputAreaRef.current.setInputValue(translateText);
    //setIsetInputValueEnable(false);
  }

  const handleImageClick = (subject, imgName) => {
    const filenameWithExtension = imgName.split('/').pop(); // 提取最后一个路径组件，即 "math2.png"
    const filename = filenameWithExtension.split('.').slice(0, -1).join('.'); // 移除扩展名

    if (selectedSubjectOptionText === 'mathematics') {
      if (filename === 'math1') {
        alert('You clicked Algebra');
      }
      if (filename === 'math2') {
        alert('You clicked Geometry');
      }
      if (filename === 'math3') {
        alert('You clicked Geometry');
      }
      if (filename === 'math4') {
        alert('You clicked Geometry');
      }
      // ... other math images
    }
    if (selectedSubjectOptionText === 'physical') {
      if (filename === 'phys1') {
        alert('You clicked Physics');
      }
      if (filename === 'phys2') {
        alert('You clicked Chemistry');
      }
      if (filename === 'phys3') {
        alert('You clicked Chemistry');
      }
      if (filename === 'phys4') {
        alert('You clicked Chemistry');
      }
      // ... other physical images
    }
  };


  const isMobile = window.innerWidth <= 768;
  const renderOverlay = (imageList, mktext) => {
    const device = deviceType_m_t_d();
    const containerStyles = {

      display: 'flex',
      flexDirection: isMobile ? 'column' : 'row',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      zIndex: 1000,
      overflow: 'hidden',
      width: isMobile ? '60%' : '70vw',
      height: isMobile ? '60%' : '500px'
    };

    // Initialize image size and layout direction based on the device type
    let imageSize = {};
    let imageContainerFlexDirection = 'column'; // Default to vertical alignment
    switch (device) {
      case 'm':
        imageSize = { width: '100px', height: '100px' };
        imageContainerFlexDirection = 'row'; // Horizontal alignment for mobile
        break;
      case 't':
        imageSize = { width: '200px', height: '200px' };
        break;
      case 'd':
        imageSize = { width: '400px', height: '400px' };
        break;
      default:
        imageSize = { width: 'auto', height: 'auto' };
        break;
    }

    if (isDisplayBlankContent) {
      return (
        <div style={containerStyles}>
          <div style={{
            marginBottom: '20px',
            marginRight: '20px',
            flexDirection: imageContainerFlexDirection,
            display: 'flex'
          }}>
            {imageList.map((imgSrc, index) => (
              <div key={index} style={{ marginBottom: imageContainerFlexDirection === 'column' ? '20px' : '0', marginRight: imageContainerFlexDirection === 'row' ? '20px' : '0' }}>
                <img
                  src={imgSrc}
                  alt={`image-${index}`}
                  style={{
                    ...imageSize,
                    maxHeight: '80%',
                    borderRadius: '20px',
                    cursor: 'pointer'
                  }}
                  onClick={() => handleImageClick(selectedSubjectOptionText, imgSrc)}
                />
              </div>
            ))}
          </div>
          <div style={{ wordWrap: 'break-word', overflowY: 'auto', padding: '10px', maxWidth: '70vw' }} className="centered-markdown">
            <ReactMarkdown>
              {mktext}
            </ReactMarkdown>
          </div>
        </div>
      );
    }
    return null;
  };

  //关闭主题历史组件
  const handleClosePTHistory = () => {
    setIsOpenHistory(false);
  };

  const handleGetPTHistory = async () => {
    try {
      const token = localStorage.getItem("access_token");
      const uids = localStorage.getItem("uid");
      const uid = parseInt(uids, 10);

      setIsLoading(true);
      let dataimage = null;
      if (uploadedImage === null) {
        dataimage = 'none';
      }
      else {
        dataimage = uploadedImage;
      }
      //qtype
      const dataToSend = {
        subject: '',
        grade: selectedGradeOption,
        userid: uid,
        page_size: 10,
        page_number: 1,
      };
      setIsLoading(true);
      const url = getHostName() + '/get_familyhead_chat_session_list'; // 你的服务端URL
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify(dataToSend),
      });

      if (response.ok) {
        setUploadedImage(null);
        const dat_server = await response.json();
        setTableData(dat_server);
        //setSelectedPTHistoryData(dat_server);
        setIsLoading(false);
      }
      else {
        console.error("Error fetching reply:", response.statusText);
        setIsLoading(false);
      }
    }
    catch (error) {
      setIsLoading(false);
      console.error("There was an error fetching the reply:", error);
    }
  };

  const handleChangePage = (event, newPage) => {
    setCurrentPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setNumberOfRowsPerPage(parseInt(event.target.value, 10));
    setCurrentPage(0);
  };

  //加载指定主题的对话记录
  const handleLoadChatPT = async () => {
    if (!selectedRowId) {
      sysMessage.warning(t('Load Record, select a record to load'));
      return;
    }
    loadSubjectData(1);
    setIsOpenHistory(false);
  }

  const handleShowCommonChatList = () => {
    setIsOpenHistory(true);
  }

  const [passwordServer, setPasswordServer] = useState('');

  const handleDialogClose = () => {
    navigate('/Default');
  };



  const handleDialogPasswordOk = async () => {
    // 这里可以添加密码验证逻辑
    if (password === "") {
      sysMessage.warning("please input your password.");
      return;
    }
    try {

      setIsLoading(true);

      const dataToSend = {
        password: password,
      };
      const data = await requestUrl(dataToSend, "validate_student_password");
      if (data.status === "success") {
        setDialogOpen(false);
      }
      else {
        sysMessage.warning("password wrong");
      }
    }
    catch (error) {
      console.error("获取数据时出错:", error);
    }
    finally {
      setIsLoading(false);
    }

  };


  const handleSettingPasswordServer = async () => {
    if (passwordServer === "") {
      sysMessage.warning("please input your password.");
      return;
    }
    try {

      setIsLoading(true);

      const dataToSend = {
        password: passwordServer,
      };
      const data = await requestUrl(dataToSend, "set_student_password");
      if (data.status === "success") {
        sysMessage.success("update success");
        setDialogOpen(false);
      }
    }
    catch (error) {
      console.error("获取数据时出错:", error);
    }
    finally {
      setIsLoading(false);
    }
  }


  const removeItem = (idToRemove) => {
    // 使用filter方法过滤掉id等于1的元素
    const filteredItems = tableData.filter(item => item.id !== idToRemove);

    // 更新状态以反映删除后的数组
    setTableData(filteredItems);
  };

  // 调用函数来删除id为1的元素

  //删除chatsession
  const handleDeleteChatSession = async (sessionid) => {
    try {
      setIsLoading(true);
      const dataToSend = {
        sessionid: sessionid,
      };
      const data = await requestUrl(dataToSend, "delete_student_chat_session1");

      if (data.status === "success") {
        removeItem(sessionid);

      }
    }
    catch (error) {
      console.error("获取数据时出错:", error);
    }
    finally {
      setIsLoading(false);
    }
  };


  return (
    <Container>
      <Top>
        <div style={{ color: 'white', fontSize: '14px' }}>
          {selectedGradeOption}{t('grade')}
        </div>
        <HistoryIcon onClick={handleShowCommonChatList} sx={{ color: 'white', cursor: 'pointer' }} /> {/* 显示历史记录的图标 */}
        <ClearIcon onClick={handleClearRecord} sx={{ color: 'white', cursor: 'pointer' }} /> {/* 显示新建记录的图标 */}
        <Button
          onClick={handleClickOpen}
          size="medium"
          sx={{
            color: 'white',
            textTransform: 'none',
            height: '25px',  // 设置高度
            width: '60px',  // 设置宽度
            marginTop: '5px',  // 设置距离上方的间距
            marginBottom: '5px',  // 设置距离下方的间距
            marginLeft: '10px',  // 设置距离左边的间距
            marginRight: '10px'  // 设置距离右边的间距
          }}>
          {t('settings')}
        </Button>
        <IconButton aria-label="help" sx={{ color: 'white' }} onClick={handleSystemHelp}>
          <HelpOutlineIcon />
        </IconButton>
      </Top>
      <Middle>
        {isDisplayBlankContent && blankPageContentElement}
        <ChatWindow
          messages={messages}
          onSendMessage={handleSendMessage}
          predefinedContent={cuspredefinedContent}
          inputText={inputText}
          setInputText={setInputText}
          subject='familyhead'
          isLoading={isLoading}
          onReceiveStringFromMessItem={onReceiveStringFromMessItem}
          onImageUpload={handleImageUpload}
          showAudio={true}
          handleAudioRecord={handleAudioRecord}
          ref={inputAreaRef}
        />

      </Middle>
      <Bottom>
        <Div1>
          <span style={{ fontSize: '14px' }}>{t('Q type')}:</span>
          <div>
            <StyledButton onClick={handleClick11} sx={{ color: 'white' }}>
              {selectedType}
            </StyledButton>
            <MuiMenu sx={{ color: 'white' }}
              anchorEl={menuAnchorElement}
              open={Boolean(menuAnchorElement)}
              onClose={() => handleClose11(null)}
              anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
              transformOrigin={{ vertical: 'bottom', horizontal: 'left' }}
              getContentAnchorEl={null}
            >
              {questionTypeList.map(({ id, value }) => (
                <MenuItem
                  sx={{ fontSize: '14px' }}
                  key={id}
                  selected={id === selectedTypeId} // 确保选中状态基于 selectedTypeId
                  onClick={() => handleClose11(id, value)}
                >
                  {value}
                </MenuItem>
              ))}


            </MuiMenu>
          </div>
        </Div1>
        <Div2>

          <span style={{ fontSize: '14px' }}>{t('page')}:</span>
          <div>
            <StyledButton onClick={handleClickPageNumber} sx={{ color: 'white' }}>
              {selectedPageNumber}
            </StyledButton>
            <MuiMenu sx={{
              color: 'white',
              '& .MuiPaper-root': {
                maxHeight: '200px', // 设置最大高度
                overflowY: 'auto' // 设置垂直滚动条
              }
            }}
              anchorEl={menuAnchorElementPage}
              open={Boolean(menuAnchorElementPage)}
              onClose={() => handleClosePage(null)}
              anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
              transformOrigin={{ vertical: 'bottom', horizontal: 'left' }}
              getContentAnchorEl={null}
            >
              {pageList.map((p) => (
                <MenuItem key={p} onClick={() => handlePageChange(p)}>
                  {t('page')}:{p}
                </MenuItem>
              ))}
            </MuiMenu>
          </div>

        </Div2>
      </Bottom>

      {isLoading && (
        <LoadingData text={t(loadingText)}/>
      )}
      <Dialog
        open={open}
        onClose={handleClose}
        sx={{
          zIndex: 6666, // 设置一个高的 z-index 值
          '& .MuiPaper-root': {
            width: {
              xs: '90%',
              sm: '500px',
            },
            maxWidth: '100%',
          },
        }}>
        <DialogTitle sx={{ textAlign: 'center', color: 'black', fontSize: '24px' }}>
          {t('settings')}
        </DialogTitle>
        <DialogContent>
          {/* 
          <CommonCombobox
            preferredValue={selectedVersion}
            setPreferredValue={setSelectedVersion}
            options={dropdownMenuSVersion}
            onValueSelected={handleMenuItemSversionClick}
            placeholder="选择教材"
            labelText="教材"
          />
          */}
          <div className='form-item'>
            <div className='label'>密码</div>
            <TextField
              autoFocus
              margin="dense"
              id="password"
              label="密码"
              type="password"
              fullWidth
              variant="standard"
              value={passwordServer}
              onChange={(e) => setPasswordServer(e.target.value)}
            />
          </div>
          <CommonCombobox
            preferredValue={selectedGradeOption}
            setPreferredValue={setSelectedGradeOption}
            options={gradeOptions}
            onValueSelected={handleMenuItemGradeClick}
            placeholder={t('select grade')}
            labelText={t('grade')}
            className='form-item white-bg'
          />
          {/* 
          <CommonCombobox
            preferredValue={selectedSemester}
            setPreferredValue={setSelectedSemester}
            options={dropdownMenuSemester}
            onValueSelected={handleMenuItemSemesterClick}
            placeholder="选择"
            labelText="学期"
          />*/}

        </DialogContent>
        <DialogActions>
          <Button
            variant='contained'
            onClick={handleSettingPasswordServer}
            fullWidth
            size='large'
            sx={{
              textTransform: 'none',
            }}
          >
            {t('重置密码')}
          </Button>
          <Button
            variant='contained'
            onClick={handleClose}
            fullWidth
            size='large'
            sx={{
              textTransform: 'none',
            }}
          >
            {t('close')}
          </Button>

        </DialogActions>
      </Dialog>

      {isShowSystemHelp && (
        <SystemHelp modulename="parent" subject="common" onClose={onCloseHelp} />
      )}
      {isShowAudioRecorder && (
        <div style={{ zIndex: 9999, position: 'fixed' }}>
          <AudioRecorder onCloseAudioRecord={onCloseAudioRecord} translateText={translateText} setTranslateText={setTranslateText} onCloseOnly={onCloseOnly} />
        </div>
      )}

      <Dialog open={isOpenHistory} onClose={handleClosePTHistory}
        sx={{

          '& .MuiPaper-root': {
            width: {
              xs: '98%', // 手机屏幕上宽度设置为 90%
              sm: '800px', // 平板和电脑屏幕上最大宽度为 500px
            },
            maxWidth: '100%', // 确保不会超出屏幕
          },
        }}>
        <DialogTitle sx={{ textAlign: 'center', color: 'black', fontSize: '24px' }}>
          {t('topics history records')}
          {/* sx={{ width: '100%', marginBottom: '10px' }}  */}
          <Button variant="text" color="primary" onClick={handleGetPTHistory}>
            <Refresh></Refresh>
            {/* {t('load records')} */}
          </Button>
        </DialogTitle>
        <DialogContent>
          <div>

            <div>
              <TableContainer component={Paper}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>{t('topics')}</TableCell>
                      <TableCell>{t('time')}</TableCell>
                      <TableCell>{t('delete')}</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {tableData.slice(currentPage * numberOfRowsPerPage, currentPage * numberOfRowsPerPage + numberOfRowsPerPage).map((row, index) => (
                      <TableRow key={row.id} selected={selectedRowId === row.id}
                        onClick={() => {
                          setSelectedRowId(row.id);
                          setSessionChatId(row.id);
                          setSelectedRowData(row);
                        }}
                        hover
                        style={{ cursor: 'pointer', backgroundColor: selectedRowId === row.id ? '#ccc' : '' }}
                      >
                        <TableCell>{row.session_name}</TableCell>
                        <TableCell>{formatDate(row.recordtime)}</TableCell>
                        <TableCell>
                          <IconButton aria-label="delete" color="primary" onClick={() => handleDeleteChatSession(row.id)}>
                            <DeleteIcon />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
                <TablePagination sx={{ position: "relative", zIndex: 10000 }}
                  rowsPerPageOptions={[5, 10, 25]}
                  component="div"
                  count={tableData.length}
                  rowsPerPage={numberOfRowsPerPage}
                  page={currentPage}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </TableContainer>
            </div>
          </div>

        </DialogContent>
        <DialogActions>
          <Button variant='contained' onClick={handleLoadChatPT} fullWidth sx={{ textTransform: 'none' }}>{t('confirm')}</Button>
          <Button variant='contained' onClick={handleClosePTHistory} fullWidth sx={{ textTransform: 'none', }}>{t('close')}</Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={dialogOpen}
        style={{ textAlign: 'center' }}
      >
        <DialogTitle>请输入密码</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            id="password"
            label="密码"
            type="password"
            fullWidth
            variant="standard"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            onKeyPress={(e) => {
              if (e.key === 'Enter') {
                handleDialogPasswordOk();
              }
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose} color="primary">
            关闭
          </Button>
          <Button onClick={handleDialogPasswordOk} color="primary">
            确定
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default Student;
