import React, { useState, useEffect,useMemo,useRef  } from "react";
import ReactMarkdown from 'react-markdown';
import { useSnackbar } from "../../SnackbarProvider";
import { requestUrl } from "../../requestUrl";
import { Grid, Box, Button,IconButton } from '@mui/material';
import { TreeView } from '@mui/x-tree-view/TreeView';
import { TreeItem } from '@mui/x-tree-view/TreeItem';
import remarkMath from 'remark-math';
import rehypeKatex from 'rehype-katex';
import rehypeRaw from 'rehype-raw'; 
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import './studydoccss.css';
import 'katex/dist/katex.min.css';
import { Light as SyntaxHighlighter } from 'react-syntax-highlighter';
import cpp from 'react-syntax-highlighter/dist/esm/languages/hljs/cpp';
import { atomOneDark } from 'react-syntax-highlighter/dist/esm/styles/hljs'; // 选择一个样式

import Draggable from "react-draggable"; // 用于拖动窗口
import { ResizableBox } from "react-resizable"; // 用于调整尺寸
import "react-resizable/css/styles.css"; // 样式文件
import ChatWindow from "../ChatWindow/ChatWindow";

import FolderIcon from '@mui/icons-material/Folder';
import DescriptionIcon from '@mui/icons-material/Description';
import './studydoccss.css';
import remarkGfm from 'remark-gfm';
import { Tabs, Tab } from '@mui/material';

SyntaxHighlighter.registerLanguage('cpp', cpp);



// ImageTabs component for each group
const ImageTabs = ({ imageUrls }) => {
  const [selectedImage, setSelectedImage] = useState(0); // Tracks the selected image in the group

  const handleImageChange = (event, newValue) => {
    setSelectedImage(newValue); // Update the selected image
  };

  return (
    <Box mt={2}>
      {/* Image Tabs for the group */}
      <Tabs value={selectedImage} onChange={handleImageChange}>
        {imageUrls.map((url, index) => (
          <Tab label={`<${index + 1}>`} key={index} />
        ))}
      </Tabs>

      {/* Display the selected image */}
      <Box mt={2}>
        <img
          src={imageUrls[selectedImage]}
          alt={`Selected Image`}
          style={{ maxWidth: '80%', height: 'auto' }} 
        />
      </Box>
    </Box>
  );
};

// Function to parse image groups from markdown content
const parseImageGroups = (markdown) => {
  const groupRegex = /<!-- group:(.*?) -->([\s\S]*?)<!-- endgroup -->/g;
  let groups = [];
  let match;

  while ((match = groupRegex.exec(markdown)) !== null) {
    const groupName = match[1];
    const images = match[2].match(/!\[.*?\]\((.*?)\)/g) || [];
    const imageUrls = images.map((image) => image.match(/\((.*?)\)/)[1]); // Extract URLs from image syntax
    groups.push({ groupName, imageUrls });
  }

  return groups;
};






const StudyDocumentComponent = () => {
    const showSnackbar = useSnackbar();
    const [isLoading, setIsLoading] = useState(false);
    const [treeData, setTreeData] = useState([]);
    const [markdownContent, setMarkdownContent] = useState('');
    const [copied, setCopied] = useState(null);





    useEffect(() => {
        const fetchData = async () => {
            try {
                setIsLoading(true);
                const dataToSend = { id: 1 };
                const data = await requestUrl(dataToSend, "get_md_documents", showSnackbar);
                if (data.status === "success") {
                    setTreeData(data.data);
                }
            } catch (error) {
                console.error("获取数据时出错:", error);
            } finally {
                setIsLoading(false);
            }
        };
        fetchData();
    }, []);

    // 递归渲染TreeItem
    const renderTree1 = (nodes) => (
        <TreeItem key={nodes.path} nodeId={nodes.path} label={nodes.name}>
            {Array.isArray(nodes.children)
                ? nodes.children.map((node) => renderTree(node))
                : null}
        </TreeItem>
    );


    const renderTree = (nodes) => (
        <TreeItem
            key={nodes.path}
            nodeId={nodes.path}
            label={
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    {nodes.ftype === 'folder' ? <FolderIcon /> : <DescriptionIcon />}
                    <span style={{ marginLeft: 8 }}>{nodes.name}</span>
                </div>
            }
        >
            {Array.isArray(nodes.children)
                ? nodes.children.map((node) => renderTree(node))
                : null}
        </TreeItem>
    );

    

    const handleNodeSelect = async (event, nodeId) => {
        // 判断是否为目录（假设没有扩展名的是目录）
        if (!nodeId.includes('.')) {
            console.log('这是一个目录，不执行操作');
            return;
        }

        try {
            setIsLoading(true);
            const dataToSend = { path: nodeId };
            const data = await requestUrl(dataToSend, "get_doc_content", showSnackbar);
            if (data.status === "success") {
                setMarkdownContent(data.data);
                
                
            }
        } catch (error) {
            console.error("获取数据时出错:", error);
        } finally {
            setIsLoading(false);
        }
    };

    const handleCopy = (code) => {
        navigator.clipboard.writeText(code);
        setCopied(code); // 用来显示复制状态
        setTimeout(() => setCopied(null), 2000); // 2秒后恢复
    };



    const handlePrint = () => {
        const newWindow = window.open();
        newWindow.document.write(`<pre>${markdownContent}</pre>`);
        newWindow.print();
    };

    const handleExportWord = () => {
        const blob = new Blob([markdownContent], {
            type: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
        });
        const link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.download = "document.docx";
        link.click();
    };

    const handleExportMarkdown = () => {
        const blob = new Blob([markdownContent], { type: "text/markdown" });
        const link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.download = "document.md";
        link.click();
    };

    const [windowSize, setWindowSize] = useState({ width: 500, height: window.innerHeight * 0.95});
    const [isWindowVisible, setIsWindowVisible] = useState(false); // 控制窗口的显示
  
    // 处理调整窗口大小
    const handleResize = (event, { size }) => {
      setWindowSize(size);
    };
  
    // 显示窗口
    const handleOpenWindow = () => {
      setIsWindowVisible(true);
    };
  
    // 关闭窗口
    const handleCloseWindow = () => {
      setIsWindowVisible(false);
    };

    const [messages, setMessages] = useState([]);
    const [inputText, setInputText] = useState(''); // 文本框的值

    const handleSendMessage = async (message) => {
        
    }

    //const imageGroups = useMemo(() => parseImageGroups(markdownContent), [markdownContent]);
    const imageGroups = useMemo(() => parseImageGroups(markdownContent) || [], [markdownContent]);
    // Use a ref to track groupIndex across renders
    const groupIndexRef = useRef(0);

    const renderImageTabsAtFirstImage = (groupIndex) => {
        if (groupIndex < imageGroups.length) {
            return <ImageTabs imageUrls={imageGroups[groupIndex].imageUrls} />;
        }
        return null;
    };



    return (
        <Grid container spacing={2} style={{ height: '100vh' }}>
            {/* 左侧 25% 文件树 */}
            <Grid item xs={3} style={{ height: '100vh', position: 'sticky', top: 0, borderRight: '1px solid #ddd', overflowY: 'auto' }}>
                <TreeView
                    aria-label="file system navigator"
                    defaultCollapseIcon={<ExpandMoreIcon />}
                    defaultExpandIcon={<ChevronRightIcon />}
                    sx={{ height: '100%', flexGrow: 1, maxWidth: 800 }}
                    onNodeSelect={handleNodeSelect} // 绑定事件处理函数
                >
                    {treeData.map((node) => renderTree(node))}
                </TreeView>
            </Grid>

            {/* 右侧 75% 预留展示区域 */}
            <Grid item xs={9}>
                <Box
                    sx={{
                        position: 'sticky',
                        top: 0,
                        display: 'flex',
                        justifyContent: 'center', // 居中排列
                        alignItems: 'center',
                        gap: '20px', // 按钮水平间距
                        height: '50px', // 设置容器的高度与按钮一致
                        backgroundColor: '#fff', // 背景色，避免与内容区混淆
                        zIndex: 1000 // 保证按钮区域在上层
                    }}
                >
                   <Button variant="contained" color="primary" onClick={handlePrint}>
                        打印
                    </Button>
                    <Button variant="contained" color="secondary" onClick={handleExportWord}>
                        导出为 Word
                    </Button>
                    <Button variant="contained" color="success" onClick={handleExportMarkdown}>
                        导出为 Markdown
                    </Button>
                    <Button variant="contained" color="warning" onClick={handleOpenWindow}>
                        AI 提问
                    </Button>
                </Box>
                <Box sx={{ p: 2, height: 'calc(100vh - 50px)', overflowY: 'auto' }}>
                <div
                  style={{backgroundColor:'#2a3d66', wordWrap: 'break-word', overflowY: 'auto', padding: '10px', maxWidth: '100%',border: '1px solid white'}} 
                  className="markdown-doc">
                   <ReactMarkdown
            children={markdownContent}
            remarkPlugins={[remarkMath]}
            rehypePlugins={[rehypeRaw, rehypeKatex]}
            components={{
              img({ src, alt, ...props }) {
                const decodedSrc = decodeURIComponent(src);

                // Debugging: Print decoded src and current group index
                console.log("Rendering image src:", decodedSrc);
                console.log("Current group index:", groupIndexRef.current);

                if (groupIndexRef.current < imageGroups.length) {
                    const firstImageUrl = imageGroups[groupIndexRef.current].imageUrls[0];
                    const decodedFirstImageUrl = decodeURIComponent(firstImageUrl);
                    console.log("First image URL for current group:", decodedFirstImageUrl);

                    // Check if the src matches the first image URL of the current group
                    if (decodedSrc === decodedFirstImageUrl) {
                        // Render the tab container at the position of the first image in the group
                        console.log("Rendering tab container for group", groupIndexRef.current);
                        const element = renderImageTabsAtFirstImage(groupIndexRef.current);
                        groupIndexRef.current += 1; // Move to the next group after rendering the tabs
                        return element;
                    }
                }

                // If the image doesn't match the first image of any group, return null to prevent rendering
                return null;
            },
                code({ node, inline, className, children, ...props }) {
                    const match = /language-(\w+)/.exec(className || '');
                    const codeString = String(children).replace(/\n$/, '');

                    return !inline && match ? (
                        <div style={{ position: 'relative' }}>
                            <SyntaxHighlighter
                                style={atomOneDark}
                                language={match[1]}
                                PreTag="div"
                                {...props}
                            >
                                {codeString}
                            </SyntaxHighlighter>
                            <button
                                onClick={() => handleCopy(codeString)}
                                style={{
                                    position: 'absolute',
                                    right: '10px',
                                    top: '10px',
                                    padding: '5px',
                                    backgroundColor: '#4CAF50',
                                    color: 'white',
                                    border: 'none',
                                    cursor: 'pointer',
                                }}
                            >
                                {copied === codeString ? '已复制!' : '复制'}
                            </button>
                        </div>
                    ) : (
                        <code className={className} {...props}>
                            {children}
                        </code>
                    );
                }
            }}
        />

                </div>
                </Box>
            </Grid>



            {/* 如果窗口可见，则显示窗口 */}
            {isWindowVisible && (
        <div
          style={{
            position: "absolute", // 使用绝对定位
            top: "20px", // 距离顶部100px
            right: "40px", // 距离右侧40px
            zIndex: 1000, // 保证窗口在顶层
          }}
        >
          <Draggable handle=".draggable-header">
            <div>
              <ResizableBox
                width={windowSize.width}
                height={windowSize.height}
                minConstraints={[300, 300]}
                maxConstraints={[900, window.innerHeight * 1]}
                onResize={handleResize}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    width: "100%",
                    height: "100%",
                    border: "1px solid #ccc",
                    borderRadius: "4px",
                    overflow: "hidden",
                    backgroundColor: "white",
                  }}
                >
                  {/* 顶部区域，包含按钮和拖动 */}
                  <Box
                    className="draggable-header"
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      height: "40px",
                      backgroundColor: "#f5f5f5",
                      cursor: "move",
                      padding: "0 16px",
                      borderBottom: "1px solid #ddd",
                    }}
                  >
                    <Button variant="contained">顶部按钮</Button>
                  </Box>

                  {/* 中间区域，放置你的div */}
                  <Box
                    sx={{
                      flexGrow: 1,
                      padding: "16px",
                      overflowY: "auto",
                      backgroundColor: "#fafafa",
                    }}
                  >
                    {/* 这里放你的自定义内容 */}
                    <ChatWindow
                        width="100%"
                        height="50%"
                        messages={messages}
                        onSendMessage={handleSendMessage}
                        inputText={inputText}
                        setInputText={setInputText}
                        isLoading={isLoading}
                        subject='document'
                        fromModule='studydoc'
                      />
                  </Box>

                  {/* 底部区域，包含两个按钮 */}
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      padding: "8px 16px",
                      borderTop: "1px solid #ddd",
                      backgroundColor: "#f5f5f5",
                    }}
                  >
                    <Button variant="contained" onClick={handleCloseWindow}>
                      关闭窗口
                    </Button>
                    <Button variant="contained">底部按钮2</Button>
                  </Box>
                </Box>
              </ResizableBox>
            </div>
          </Draggable>
        </div>
      )}
 
        </Grid>
    );
};

export default StudyDocumentComponent;
