import React from 'react';

// 定义一个样式对象，用于五角星
const starStyle = {
  color: 'yellow',
  textShadow: '0 0 10px yellow', // 光晕效果
  animation: 'blink 1s infinite alternate' // 闪烁效果
};

// 五角星闪烁动画的内联样式
const blinkAnimation = `
  @keyframes blink {
    from { opacity: 0.5; }
    to { opacity: 1; }
  }
`;

const StarButton = () => {
  return (
    <div style={{ width: '200px', height: '30px', display: 'flex', alignItems: 'center', justifyContent: 'space-around' }}>
      <style>{blinkAnimation}</style> {/* 内联动画样式 */}
      <span style={starStyle}>★</span>
      <span style={starStyle}>★</span>
      <span style={starStyle}>★</span>
    </div>
  );
};

export default StarButton;
