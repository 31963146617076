import React, { useState,useEffect,useMemo } from 'react';

import { requestUrl,requestUrlWithOutParameter } from '../requestUrl';
import { List, ListItem, TextField, Button, Container,InputAdornment } from '@mui/material';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import LoadingData from '../education/dataloading';

import { useSnackbar } from '../SnackbarProvider';
import { useTranslation } from 'react-i18next';

import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import CommonComboboxOne from '../components/CommonComboboxOne';
import Grid from '@mui/material/Grid';
const PromptManage = () => {
    const showSnackbar = useSnackbar();
      // Inline styles
      const styles = {
        listContainer: {
            maxHeight: 'calc(100vh - 100px)', // Adjust the 100px based on your header/footer size
            overflowY: 'auto' // Enable scrolling
        },
        container: {
            display: 'flex',
            flexDirection: 'row',
            width: '100vw',  // 100% of viewport width
            height: '100vh', // 100% of viewport height
            overflow: 'hidden' // Prevents scrollbars if content overflows
        },
        menu: {
            width: '300px',
            height: '100%',   // Full height
            borderRight: '1px solid #ddd',
            padding: '10px',
            overflowY: 'auto' // Allows scrolling for long menus
        },
        content: {
            flex: 1,
            height: '100%',  // Full height
            padding: '10px',
            overflowY: 'auto' 
        }
    };

    const textFieldStyles = {
        input: { 
            color: 'black', // 设置输入文本的颜色
            overflowY: 'auto' // 自动滚动条
        },
        label: { color: 'black' }
    };
    const { t } = useTranslation();
    const [prompts, setPrompts] = useState([]);
    const [selectedSubject, setSelectedSubject] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [loadingText,setLoadingText] = useState(false);
    //const dropdownModels1 = ['azure-35','azure-35-16k','azure-40','azure-40-32k','gpt-3.5-turbo-1106','gpt-4-0125-preview'];
    const [dropdownModels,setDropdownModels] = useState([]);

    const [dropdownCrhd,setDropdownCrhd] = useState([0,1,2,3,4,5,6,7,8,9,10]);
    const [dropdownTemperature,setDropdownTemperature] = useState([0.1,0.2,0.3,0.4,0.5,0.6,0.7,0.8,0.9,1]);
    const [dropdownFormat,setDropdownFormat] = useState(['text','json']);

    const [currentPageFormat,setCurrentPageFormat] = useState('');



    useEffect(() => {
        const fetchData = async () => {
            try {
                setIsLoading(true);
                setLoadingText("loading");
                const dataToSend = {
                    id:1,
                };
                const data = await requestUrl(dataToSend, "get_llm");
                if(data.status === "success"){
                    setDropdownModels(data.data);
                    
                }
              } 
              catch (error) {
                  console.error("获取数据时出错:", error);
              }
              finally {
                setIsLoading(false);
              }
        };

        fetchData();
    }, []);

    


    const getQtypeName = (subject,qtype) => {
        let qtypename = "";
        if(subject === "chinese"){
            switch (qtype) {
                case 1:
                    qtypename = "common question";
                    break;
                case 2:
                    qtypename = "poems";
                    break;
                case 3:
                    qtypename = "Essay Review";
                    break;
                default:
                    // 可以添加默认处理，如果需要
                    break;
            }
        }
        if(subject === "english"){
            switch (qtype) {
                case 1:
                    qtypename = "通用问题";
                    break;
                case 2:
                    qtypename = "写作点评指导";
                    break;
                default:
                    // 可以添加默认处理，如果需要
                    break;
            }
        }
        if(subject === "mathematics"){
            switch (qtype) {
                case 1:
                    qtypename = "通用问题";
                    break;
                case 2:
                    qtypename = "解题";
                    break;
                default:
                    // 可以添加默认处理，如果需要
                    break;
            }
        }

        if(subject === "physics"){
            switch (qtype) {
                case 1:
                    qtypename = "通用问题";
                    break;
                case 2:
                    qtypename = "解题";
                    break;
                default:
                    // 可以添加默认处理，如果需要
                    break;
            }
        }

        if(subject === "physics"){
            switch (qtype) {
                case 1:
                    qtypename = "通用问题";
                    break;
                case 2:
                    qtypename = "解题";
                    break;
                default:
                    // 可以添加默认处理，如果需要
                    break;
            }
        }

        if(subject === "chemistry"){
            switch (qtype) {
                case 1:
                    qtypename = "通用问题";
                    break;
                case 2:
                    qtypename = "解题";
                    break;
                default:
                    // 可以添加默认处理，如果需要
                    break;
            }
        }

        if(subject === "geography"){
            switch (qtype) {
                case 1:
                    qtypename = "通用问题";
                    break;
                case 2:
                    qtypename = "解题";
                    break;
                default:
                    // 可以添加默认处理，如果需要
                    break;
            }
        }

        if(subject === "biology"){
            switch (qtype) {
                case 1:
                    qtypename = "通用问题";
                    break;
                case 2:
                    qtypename = "解题";
                    break;
                default:
                    // 可以添加默认处理，如果需要
                    break;
            }
        }

        if(subject === "familyhead"){
            switch (qtype) {
                case 1:
                    qtypename = "通用话题";
                    break;
                case 2:
                    qtypename = "教育话题";
                    break;
                case 3:
                    qtypename = "心理健康";
                    break;
                case 4:
                    qtypename = "社交技能";
                    break;
                case 5:
                    qtypename = "身体健康";
                    break;
                case 6:
                    qtypename = "家庭教育";
                    break;
                default:
                    // 可以添加默认处理，如果需要
                    break;
            }
        }

        return qtypename;
    }

    const handleMenuClick = async (menuItem,qtype) => {
        console.log(menuItem + "," + qtype);
        setSelectedSubject(menuItem);
        try {
            setIsLoading(true);
            setLoadingText("loading");
            const dataToSend = {
                pname:menuItem,
                qtype:qtype,
              };
            const data = await requestUrl(dataToSend,"get_prompts_by_p");
            setPrompts(data);
            
            setQtypeId(qtype);
            setQtype(getQtypeName(menuItem,qtype));
          } catch (error) {
            console.error("获取数据时出错:", error);
            setIsLoading(false);
          }
          setIsLoading(false);

    };

    const handlePromptChange = (id, field, value) => {
        setPrompts(prompts.map(
            prompt => prompt.id === id ? { ...prompt, [field]: value } : prompt
        ));
    };
 
    const handleSaveSubject = async (id) => {
        console.log(id + "sss");
        prompt = prompts.find(prompt => prompt.id === id);
        try {
            setIsLoading(true);
            setLoadingText("loading");

            const dataToSend = {
                id:id,
                subject:prompt.pname,
                grade:prompt.grade,
                qtype:1,
                model:prompt.model,
                sys_prompt_zh:prompt.sys_prompt_zh,
                sys_prompt_en:prompt.sys_prompt_en,
                user_prompt_zh:prompt.user_prompt_zh,
                user_prompt_en:prompt.user_prompt_en,
                carrying_recent_historical_data:prompt.carrying_recent_historical_data,
                temperature:prompt.temperature,
            };
            
            const data = await requestUrl(dataToSend,"update_prompt_by_id");
            if(data.status === "success"){
                
                showSnackbar('success', 'update success');
            }
          } catch (error) {
            console.error("获取数据时出错:", error);
            setIsLoading(false);
          }

          setIsLoading(false);
    }

    const handleCopyVariable = async (value) => {
        await navigator.clipboard.writeText(value);
        
        showSnackbar('success', 'Text copied to clipboard');
    }

    const [openMenus, setOpenMenus] = useState({
        chinese: false,
        english: false,
        mathematics: false,
        physics: false,
        chemistry: false,
        geography: false,
        biology: false,
        familyhead:false,
        // 其他菜单...
      });


    const allSubjects = ['mathematics','chinese','english','physics','chemistry','biology','geography'];

    const [qtype,setQtype] = useState();

    const [qtypeId,setQtypeId] = useState();

    const handleClick = (menu) => {
        setOpenMenus(prevOpenMenus => ({
          ...prevOpenMenus,
          [menu]: !prevOpenMenus[menu]
        }));
      };

      
      const [currentPageModel,setCurrentPageModel] = useState('');
      const [currentPageTemperature,setCurrentPageTemperature] = useState(0.7);

      const handleDropdownChange = (promptId, selectedValue) => {
        setPrompts(prompts.map(
            prompt => prompt.id === promptId ? { ...prompt, model: selectedValue } : prompt
        ));

        setCurrentPageModel(selectedValue);
    };

    const [currentPageCrhd,setCurrentPageCrhd] = useState(0);
    const handleDropdownChangeCrhd = (promptId, selectedValue) => {
        setPrompts(prompts.map(
            prompt => prompt.id === promptId ? { ...prompt, carrying_recent_historical_data: selectedValue } : prompt
        ));

        setCurrentPageCrhd(selectedValue);
    };


    const handleDropdownChangeTemperature = (promptId, selectedValue) => {
        setPrompts(prompts.map(
            prompt => prompt.id === promptId ? { ...prompt, temperature: selectedValue } : prompt
        ));

        setCurrentPageTemperature(selectedValue);
    };



    const handleUpdateAll = async (subject,qtype, ptypeV,promptvalue) => {
        
        const updatedPrompts = prompts.map(prompt => {
            if(ptypeV === "sys_prompt_en"){
                return { ...prompt, sys_prompt_en: promptvalue };
            }
            if(ptypeV === "sys_prompt_zh"){
                return { ...prompt, sys_prompt_zh: promptvalue };
            }
            if(ptypeV === "user_prompt_en"){
                return { ...prompt, user_prompt_en: promptvalue };
            }
            if(ptypeV === "user_prompt_zh"){
                return { ...prompt, user_prompt_zh: promptvalue };
            }
            
        });
        setPrompts(updatedPrompts);

        try {
            setIsLoading(true);
            setLoadingText("loading");
            const dataToSend = {
                subject:subject,
                qtype:qtype,
                ptypeV:ptypeV,
                promptV:promptvalue,
              };
            const data = await requestUrl(dataToSend,"update_promptList_by_subject_grade");
            
            if(data === "success"){
                
                showSnackbar('success', 'update suceess');
            }
           
          } catch (error) {
            console.error("获取数据时出错:", error);
            setIsLoading(false);
          }
          setIsLoading(false);
    }


    useEffect(() => {
        loadPromptsData();
    }, []);  

   const [menuList,setMenuList] = useState({});
   

    const loadPromptsData = async () => {
        try {
            setIsLoading(true);
            setLoadingText("loading");
            const dataToSend = {
                id:0,
            };
            const data = await requestUrl(dataToSend, "get_prompts_am");
            
            if(data.status === "success"){
                const promptsData = JSON.parse(data.prompts);
                setMenuList(promptsData);
               // console.log(JSON.stringify(data.prompts));
            }
          } catch (error) {
              console.error("获取数据时出错:", error);
          } finally {
            setIsLoading(false);
          }
    }


    const toggleMenu = (menu) => {
        setOpenMenus(prevOpenMenus => ({
          ...prevOpenMenus,
          [menu]: !prevOpenMenus[menu]
        }));
      };

      
      const handleModifyAllCrhd = async (subject,qtype) => {
        
        const updatedPrompts = prompts.map(prompt => {
            return { ...prompt, carrying_recent_historical_data: currentPageCrhd };
        });
        setPrompts(updatedPrompts);

        try {
            setIsLoading(true);
            setLoadingText("loading");
            const dataToSend = {
                subject:subject,
                qtype:qtype,
                crhd:currentPageCrhd,
              };
            const data = await requestUrl(dataToSend,"update_all_crhd_by_p_qtype");
            
            if(data === "success"){
                showSnackbar('success', 'update suceess');
            }
           
          } catch (error) {
            console.error("获取数据时出错:", error);
            setIsLoading(false);
          }
          setIsLoading(false);
      }



      

      const handleModifyAllFormat = async (subject,qtype) => {
        
       
        const updatedPrompts = prompts.map(prompt => {
            return { ...prompt, format_type: currentPageFormat };
        });
        setPrompts(updatedPrompts);

        try {
            setIsLoading(true);
            setLoadingText("loading");
            const dataToSend = {
                subject:subject,
                qtype:qtype,
                format_type:currentPageFormat,
              };

              console.log("SSS:" + JSON.stringify(dataToSend));
            const data = await requestUrl(dataToSend,"update_all_format_by_p_qtype");
            
            if(data === "success"){
                showSnackbar('success', 'update suceess');
            }
           
          } catch (error) {
            console.error("获取数据时出错:", error);
            setIsLoading(false);
          }
          setIsLoading(false);
      }




      const handleModifyAllTemperature = async (subject,qtype) => {
        
       
        const updatedPrompts = prompts.map(prompt => {
            return { ...prompt, temperature: currentPageTemperature };
        });
        setPrompts(updatedPrompts);

        try {
            setIsLoading(true);
            setLoadingText("loading");
            const dataToSend = {
                subject:subject,
                qtype:qtype,
                temperature:currentPageTemperature,
              };
            const data = await requestUrl(dataToSend,"update_all_temperature_by_p_qtype");
            
            if(data === "success"){
                showSnackbar('success', 'update suceess');
            }
           
          } catch (error) {
            console.error("获取数据时出错:", error);
            setIsLoading(false);
          }
          setIsLoading(false);
      }

      
  
      const handleDropdownChangeformat = (promptId, selectedValue) => {
        setPrompts(prompts.map(
            prompt => prompt.id === promptId ? { ...prompt, format_type: selectedValue } : prompt
        ));

        setCurrentPageFormat(selectedValue);
    };




      const handleModifyAllModel = async (subject,qtype) => {
        console.log("当前选中model：" + currentPageModel);
        const updatedPrompts = prompts.map(prompt => {
            return { ...prompt, model: currentPageModel };
        });
        setPrompts(updatedPrompts);

        try {
            setIsLoading(true);
            setLoadingText("loading");
            const dataToSend = {
                subject:subject,
                qtype:qtype,
                model:currentPageModel,
              };
            const data = await requestUrl(dataToSend,"update_all_model_by_p_qtype");
            
            if(data === "success"){
                showSnackbar('success', 'update suceess');
            }
           
          } catch (error) {
            console.error("获取数据时出错:", error);
            setIsLoading(false);
          }
          setIsLoading(false);


      }


      
    return (
        <div style={styles.container}>
            
            <div style={styles.menu}>
            <List>
      {Object.keys(menuList).map((key) => (
        <React.Fragment key={key}>
          <ListItemButton onClick={() => toggleMenu(key)}>
            <ListItemText primary={t(key.replace(/_/g, ' '))} />
            {openMenus[key] ? <ExpandLess sx={{ color: 'white' }} /> : 
              <ExpandMore sx={{ color: 'white' }} />}
          </ListItemButton>
          <Collapse in={openMenus[key] || false} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              {menuList[key].map((item, index) => (
                <ListItemButton key={`${key}-${index}`} sx={{ pl: 4 }} onClick={() => handleMenuClick(key, item.qtype)}>
                  <ListItemText primary={t(item.qtype_des)} />
                </ListItemButton>
              ))}
            </List>
          </Collapse>
        </React.Fragment>
      ))}
    </List>
           
            </div>
            
                <div style={{justifyContent:'flex-start',color:'black', alignItems:'flex-start' ,textAlign:'left',width:'100%',backgroundColor:'white'}}>
                    
                    <Container maxWidth="md"> {/* 使用 Container 限制最大宽度 */}
                    <List style={styles.listContainer}>
                    {prompts.map(prompt => (
                        <ListItem key={prompt.id} style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', width: '100%' }}>
                            <br></br>
                            
                            <div style={{
                                color: 'black', // Consider a more vibrant color if suitable
                                fontSize: '20px', // Slightly larger font size
                                fontWeight: 'bold', // Make the font bold
                                textShadow: '1px 1px 2px gray', // Subtle shadow
                                backgroundColor: '#f0f0f0', // Light background for the text
                                padding: '5px', // Add some padding around the text
                                borderRadius: '5px', // Optional: round the corners of the background
                                border: '1px solid #ddd' // Optional: add a border
                            }}> {t('subject')}:{t(selectedSubject)},{t('question type')}:{t(prompt.qtype_des)},{t('grade')}: {prompt.grade}</div>
                            
                            <Grid container spacing={2}>
    {/* 第一行 */}
    <Grid item xs={4}>
        <Button
            variant="contained"
            color="primary"
            onClick={() => handleModifyAllModel(prompt.pname, prompt.qtype)}
            fullWidth
        >
            {t('modify all model')}
        </Button>
    </Grid>
    <Grid item xs={6}>
        <CommonComboboxOne
            preferredValue={prompt.model}
            setPreferredValue={(value) => handleDropdownChange(prompt.id, value)}
            options={dropdownModels}
            placeholder={t('model-------------------------')}
            labelText={t('setting')}
        />
    </Grid>

    {/* 第二行 */}
    <Grid item xs={4}>
        <Button
            variant="contained"
            color="primary"
            onClick={() => handleModifyAllCrhd(prompt.pname, prompt.qtype)}
            fullWidth
        >
            {t('modify all c_r_h_d')}
        </Button>
    </Grid>
    <Grid item xs={6}>
        <CommonComboboxOne
            preferredValue={prompt.carrying_recent_historical_data}
            setPreferredValue={(value) => handleDropdownChangeCrhd(prompt.id, value)}
            options={dropdownCrhd}
            placeholder={t('carrying_recent_historical_data')}
            labelText={t('setting')}
        />
    </Grid>

    {/* 第三行 */}
    <Grid item xs={4}>
        <Button
            variant="contained"
            color="primary"
            onClick={() => handleModifyAllTemperature(prompt.pname, prompt.qtype)}
            fullWidth
        >
            {t('modify all temperature')}
        </Button>
    </Grid>
    <Grid item xs={6}>
        <CommonComboboxOne
            customWidth={'400px'}
            preferredValue={prompt.temperature}
            setPreferredValue={(value) => handleDropdownChangeTemperature(prompt.id, value)}
            options={dropdownTemperature}
            placeholder={t('temperature-------------------')}
            labelText={t('setting')}
        />
    </Grid>

    {/* 第四行 */}
    <Grid item xs={4}>
        <Button
            variant="contained"
            color="primary"
            onClick={() => handleModifyAllFormat(prompt.pname, prompt.qtype)}
            fullWidth
        >
            {t('modify all format')}
        </Button>
    </Grid>
    <Grid item xs={6}>
        <CommonComboboxOne
            preferredValue={prompt.format_type}
            setPreferredValue={(value) => handleDropdownChangeformat(prompt.id, value)}
            options={dropdownFormat}
            placeholder={t('format-------------------------')}
            labelText={t('setting')}
        />
    </Grid>
</Grid>


                            <br></br>
                            <div style={{
                                color: 'black', // Consider a more vibrant color if suitable
                                fontSize: '16px', // Slightly larger font size
                                fontWeight: 'bold', // Make the font bold
                                textShadow: '1px 1px 2px gray', // Subtle shadow
                                backgroundColor: '#f0f0f0', // Light background for the text
                                padding: '5px', // Add some padding around the text
                                borderRadius: '5px', // Optional: round the corners of the background
                                border: '1px solid #ddd' // Optional: add a border
                            }}> 
                            
                            {t('system prompt')} &nbsp;&nbsp; {t('variant')} &nbsp;&nbsp;: 
                            


                            {['chinese','english'].includes(selectedSubject) && prompt.qtype === 30 && (//语文或者英语的作文点评的第二次以后的提示词
                            <>
                             
    
                                <span onClick={() => handleCopyVariable('{subject}')}  
                                style={{backgroundColor:'white',color:'black',cursor:'pointer'}}>{'{subject}'}</span>
    
                                <span onClick={() => handleCopyVariable('{grade}')}  
                                style={{backgroundColor:'white',color:'black',cursor:'pointer'}}>{'{grade}'}</span>
    
                                <span onClick={() => handleCopyVariable('{teaching_strategy}')}  
                                style={{backgroundColor:'white',color:'black',cursor:'pointer'}}>{'{teaching_strategy}'}</span>
                                
                                <span onClick={() => handleCopyVariable('{personal_interest}')}  
                                style={{backgroundColor:'white',color:'black',cursor:'pointer'}}>{'{personal_interest}'}</span>


                                <span onClick={() => handleCopyVariable('{teacher_style}')}  
                                style={{backgroundColor:'white',color:'black',cursor:'pointer'}}>{'{teacher_style}'}</span>
                                </>
                            )}

                            {['chinese','english'].includes(selectedSubject) && prompt.qtype === 40 && (//语文或者英语的作文点评的第二次以后的提示词
                            <>
                             
    
                                <span onClick={() => handleCopyVariable('{subject}')}  
                                style={{backgroundColor:'white',color:'black',cursor:'pointer'}}>{'{subject}'}</span>
    
                                <span onClick={() => handleCopyVariable('{grade}')}  
                                style={{backgroundColor:'white',color:'black',cursor:'pointer'}}>{'{grade}'}</span>

                                <span onClick={() => handleCopyVariable('{teaching_strategy}')}  
                                style={{backgroundColor:'white',color:'black',cursor:'pointer'}}>{'{teaching_strategy}'}</span>
                                
                                <span onClick={() => handleCopyVariable('{personal_interest}')}  
                                style={{backgroundColor:'white',color:'black',cursor:'pointer'}}>{'{personal_interest}'}</span>

                                <span onClick={() => handleCopyVariable('{teacher_style}')}  
                                style={{backgroundColor:'white',color:'black',cursor:'pointer'}}>{'{teacher_style}'}</span>
                               
                                </>
                            )}

                            {['chinese','english'].includes(selectedSubject) && prompt.qtype === 50 && (//语文或者英语的阅读理解的第二次以后的提示词
                            <>
                    
                                <span onClick={() => handleCopyVariable('{subject}')}  
                                style={{backgroundColor:'white',color:'black',cursor:'pointer'}}>{'{subject}'}</span>
    
                                <span onClick={() => handleCopyVariable('{grade}')}  
                                style={{backgroundColor:'white',color:'black',cursor:'pointer'}}>{'{grade}'}</span>
    
                                <span onClick={() => handleCopyVariable('{teaching_strategy}')}  
                                style={{backgroundColor:'white',color:'black',cursor:'pointer'}}>{'{teaching_strategy}'}</span>
                                
                                <span onClick={() => handleCopyVariable('{personal_interest}')}  
                                style={{backgroundColor:'white',color:'black',cursor:'pointer'}}>{'{personal_interest}'}</span>

                                <span onClick={() => handleCopyVariable('{teacher_style}')}  
                                style={{backgroundColor:'white',color:'black',cursor:'pointer'}}>{'{teacher_style}'}</span>
                                </>
                            )}


                            {selectedSubject === "familyhead" && (//文档对话
                               <>
                               <span onClick={() => handleCopyVariable('{grade}')}  
                                style={{backgroundColor:'white',color:'black',cursor:'pointer'}}>{'{grade}'}</span>
                                <span onClick={() => handleCopyVariable('{qtype}')}  
                                style={{backgroundColor:'white',color:'black',cursor:'pointer'}}>{'{qtype}'}</span>
                                <br></br>
                                <br></br>
                                qtype变量：问题类型
                               </>
                            )}


                            {selectedSubject === "user_generate_character" && (//文档对话
                               <></>
                            )}

                            {selectedSubject === "docconversation" && (//文档对话
                               <></>
                            )}

                            {selectedSubject === "feynman_summarize" && (//文档对话
                                <>
                                <span onClick={() => handleCopyVariable('{subject}')}  
                                style={{backgroundColor:'white',color:'black',cursor:'pointer'}}>{'{subject}'}</span>
    
                                <span onClick={() => handleCopyVariable('{grade}')}  
                                style={{backgroundColor:'white',color:'black',cursor:'pointer'}}>{'{grade}'}</span>

                                <span onClick={() => handleCopyVariable('{teaching_strategy}')}  
                                style={{backgroundColor:'white',color:'black',cursor:'pointer'}}>{'{teaching_strategy}'}</span>
                                
                                <span onClick={() => handleCopyVariable('{personal_interest}')}  
                                style={{backgroundColor:'white',color:'black',cursor:'pointer'}}>{'{personal_interest}'}</span>

                                <span onClick={() => handleCopyVariable('{teacher_style}')}  
                                style={{backgroundColor:'white',color:'black',cursor:'pointer'}}>{'{teacher_style}'}</span>
                                </>
                            )}

                            {selectedSubject === "picturebook" && (//文档对话
                                <>
                                <span onClick={() => handleCopyVariable('{grade}')}  
                                style={{backgroundColor:'white',color:'black',cursor:'pointer'}}>{'{grade}'}</span>

                                <span onClick={() => handleCopyVariable('{teaching_strategy}')}  
                                style={{backgroundColor:'white',color:'black',cursor:'pointer'}}>{'{teaching_strategy}'}</span>
                                
                                <span onClick={() => handleCopyVariable('{personal_interest}')}  
                                style={{backgroundColor:'white',color:'black',cursor:'pointer'}}>{'{personal_interest}'}</span>

                                <span onClick={() => handleCopyVariable('{teacher_style}')}  
                                style={{backgroundColor:'white',color:'black',cursor:'pointer'}}>{'{teacher_style}'}</span>
                                </>
                            )}

                            {selectedSubject === "student" && (//
                               <span onClick={() => handleCopyVariable('{style}')}  
                                style={{backgroundColor:'white',color:'black',cursor:'pointer'}}>{'{style}'}</span>
                            )}

                            {selectedSubject === "wolfram" && (//
                               <></>
                            )}

                            {selectedSubject === "qa_record" && (//
                               <>
                               <span onClick={() => handleCopyVariable('{subject}')}  
                                style={{backgroundColor:'white',color:'black',cursor:'pointer'}}>{'{subject}'}</span>
    
                                <span onClick={() => handleCopyVariable('{grade}')}  
                                style={{backgroundColor:'white',color:'black',cursor:'pointer'}}>{'{grade}'}</span>
                               </>
                            )}

                            {allSubjects.includes(selectedSubject)  && prompt.qtype === 21 && (//科目的feynman  ask
                                <>
                                 <span onClick={() => handleCopyVariable('{subject}')}  
                                style={{backgroundColor:'white',color:'black',cursor:'pointer'}}>{'{subject}'}</span>
    
                                <span onClick={() => handleCopyVariable('{grade}')}  
                                style={{backgroundColor:'white',color:'black',cursor:'pointer'}}>{'{grade}'}</span>

                                <span onClick={() => handleCopyVariable('{point}')}  
                                style={{backgroundColor:'white',color:'black',cursor:'pointer'}}>{'{point}'}</span>

                                <span onClick={() => handleCopyVariable('{teaching_strategy}')}  
                                style={{backgroundColor:'white',color:'black',cursor:'pointer'}}>{'{teaching_strategy}'}</span>
                                
                                <span onClick={() => handleCopyVariable('{personal_interest}')}  
                                style={{backgroundColor:'white',color:'black',cursor:'pointer'}}>{'{personal_interest}'}</span>

                                <span onClick={() => handleCopyVariable('{teacher_style}')}  
                                style={{backgroundColor:'white',color:'black',cursor:'pointer'}}>{'{teacher_style}'}</span>
                                
                                <br></br>
                                <br></br>
                                point变量：feynman的主题知识点
                                </>
                            )}

                            {allSubjects.includes(selectedSubject)  && prompt.qtype === 22 && (//科目的feynman  score
                                <>
                                 <span onClick={() => handleCopyVariable('{subject}')}  
                                style={{backgroundColor:'white',color:'black',cursor:'pointer'}}>{'{subject}'}</span>
    
                                <span onClick={() => handleCopyVariable('{grade}')}  
                                style={{backgroundColor:'white',color:'black',cursor:'pointer'}}>{'{grade}'}</span>

                                <span onClick={() => handleCopyVariable('{point}')}  
                                style={{backgroundColor:'white',color:'black',cursor:'pointer'}}>{'{point}'}</span>
                                 <br></br>
                                 <br></br>
                                point变量：feynman的主题知识点
                                </>
                            )}


                            {allSubjects.includes(selectedSubject)  && prompt.qtype === 23 && (//科目的feynman  summarize
                                <>
                                 <span onClick={() => handleCopyVariable('{subject}')}  
                                style={{backgroundColor:'white',color:'black',cursor:'pointer'}}>{'{subject}'}</span>
    
                                <span onClick={() => handleCopyVariable('{grade}')}  
                                style={{backgroundColor:'white',color:'black',cursor:'pointer'}}>{'{grade}'}</span>

                                <span onClick={() => handleCopyVariable('{point}')}  
                                style={{backgroundColor:'white',color:'black',cursor:'pointer'}}>{'{point}'}</span>

                                <span onClick={() => handleCopyVariable('{teaching_strategy}')}  
                                style={{backgroundColor:'white',color:'black',cursor:'pointer'}}>{'{teaching_strategy}'}</span>
                                
                                <span onClick={() => handleCopyVariable('{personal_interest}')}  
                                style={{backgroundColor:'white',color:'black',cursor:'pointer'}}>{'{personal_interest}'}</span>

                                <span onClick={() => handleCopyVariable('{teacher_style}')}  
                                style={{backgroundColor:'white',color:'black',cursor:'pointer'}}>{'{teacher_style}'}</span>
                                 <br></br>
                                 <br></br>
                                point变量：feynman的主题知识点
                                </>
                            )}


                            {allSubjects.includes(selectedSubject)  && prompt.qtype === 500 && (//get point knowledge for commonchat
                                <>
                                 <span onClick={() => handleCopyVariable('{subject}')}  
                                style={{backgroundColor:'white',color:'black',cursor:'pointer'}}>{'{subject}'}</span>
    
                                <span onClick={() => handleCopyVariable('{grade}')}  
                                style={{backgroundColor:'white',color:'black',cursor:'pointer'}}>{'{grade}'}</span>
                                <br></br>
                                <br></br>
                                grade 100 小学，200 初中，300 高中，写不写grade变量都可以，如果不写，指定小学初中高中即可。
                                </>
                            )}

                            {allSubjects.includes(selectedSubject)  && prompt.qtype === 600 && (////get point knowledge for commonchat session
                                <>
                                 <span onClick={() => handleCopyVariable('{subject}')}  
                                style={{backgroundColor:'white',color:'black',cursor:'pointer'}}>{'{subject}'}</span>
    
                                <span onClick={() => handleCopyVariable('{grade}')}  
                                style={{backgroundColor:'white',color:'black',cursor:'pointer'}}>{'{grade}'}</span>

                               
                                <br></br>
                                <br></br>
                                grade 100 小学，200 初中，300 高中，写不写grade变量都可以，如果不写，指定小学初中高中即可。
                                </>
                            )}

                            {allSubjects.includes(selectedSubject)  && prompt.qtype < 10 && (//科目提示词
                            <>
                                
    
                                <span onClick={() => handleCopyVariable('{subject}')}  
                                style={{backgroundColor:'white',color:'black',cursor:'pointer'}}>{'{subject}'}</span>
    
                                <span onClick={() => handleCopyVariable('{grade}')}  
                                style={{backgroundColor:'white',color:'black',cursor:'pointer'}}>{'{grade}'}</span>

                                <span onClick={() => handleCopyVariable('{teaching_strategy}')}  
                                style={{backgroundColor:'white',color:'black',cursor:'pointer'}}>{'{teaching_strategy}'}</span>
                                
                                <span onClick={() => handleCopyVariable('{personal_interest}')}  
                                style={{backgroundColor:'white',color:'black',cursor:'pointer'}}>{'{personal_interest}'}</span>

                                <span onClick={() => handleCopyVariable('{teacher_style}')}  
                                style={{backgroundColor:'white',color:'black',cursor:'pointer'}}>{'{teacher_style}'}</span>
                                </>
                            )}
                            
                            
                            
                            </div>
                            <br></br>
                            
                            <TextField
                                label="Prompt (EN)"
                                // 假设 prompt.user_prompt_en 是您要绑定的值
                                value={prompt.sys_prompt_en}
                                onChange={(e) => handlePromptChange(prompt.id, 'sys_prompt_en', e.target.value)}
                                fullWidth
                                multiline
                                InputProps={{
                                    inputComponent: TextareaAutosize,
                                    inputProps: {
                                    style: { height: '150px', overflowY: 'auto', color: 'black' },
                                    minRows: 4
                                    },
                                    // 在这里添加 InputAdornment
                                    endAdornment: (
                                    <InputAdornment position="end">
                                        <Button variant="contained" color="primary" 
                                            onClick={() => handleUpdateAll(prompt.pname,prompt.qtype,'sys_prompt_en',prompt.sys_prompt_en)}>
                                        {t('update')}
                                        </Button>
                                    </InputAdornment>
                                    )
                                }}
                                InputLabelProps={{ style: textFieldStyles.label }}
                                /><br></br>
                            <TextField
                                label="Prompt (ZH)"
                                value={prompt.sys_prompt_zh}
                                onChange={(e) => handlePromptChange(prompt.id, 'sys_prompt_zh', e.target.value)}
                                fullWidth
                                multiline
                                InputProps={{
                                    inputComponent: TextareaAutosize, // 使用 TextareaAutosize 组件
                                    inputProps: {
                                        style: { height: '100px', overflowY: 'auto',color:'black' }, // 直接设置高度和滚动
                                        minRows: 4
                                    },
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <Button variant="contained" color="primary" 
                                            onClick={() => handleUpdateAll(prompt.pname,prompt.qtype,'sys_prompt_zh',prompt.sys_prompt_zh)}>
                                            {t('update')}
                                            </Button>
                                        </InputAdornment>
                                        )
                                }}
                                InputLabelProps={{ style: textFieldStyles.label }} 
                            />
                            <br></br>
                            <div style={{
                                color: 'black', // Consider a more vibrant color if suitable
                                fontSize: '16px', // Slightly larger font size
                                fontWeight: 'bold', // Make the font bold
                                textShadow: '1px 1px 2px gray', // Subtle shadow
                                backgroundColor: '#f0f0f0', // Light background for the text
                                padding: '5px', // Add some padding around the text
                                borderRadius: '5px', // Optional: round the corners of the background
                                border: '1px solid #ddd' // Optional: add a border
                            }}>
                                {t('user prompt')} &nbsp;&nbsp;{t('variant')}:&nbsp;&nbsp;



                            {allSubjects.includes(selectedSubject)  && prompt.qtype === 500 && (//get point knowledge for commonchat
                                <>
                                 <span onClick={() => handleCopyVariable('{subject}')}  
                                style={{backgroundColor:'white',color:'black',cursor:'pointer'}}>{'{subject}'}</span>
    
                                <span onClick={() => handleCopyVariable('{grade}')}  
                                style={{backgroundColor:'white',color:'black',cursor:'pointer'}}>{'{grade}'}</span>

                            <span onClick={() => handleCopyVariable('{input}')}  
                                style={{backgroundColor:'white',color:'black',cursor:'pointer'}}>{'{input}'}</span>
    
                                <span onClick={() => handleCopyVariable('{aiquestion}')}  
                                style={{backgroundColor:'white',color:'black',cursor:'pointer'}}>{'{aiquestion}'}</span>
                                <br></br>
                                <br></br>
                                grade 100 小学，200 初中，300 高中，写不写grade变量都可以，如果不写，指定小学初中高中即可111。
                                </>
                            )}

                            {allSubjects.includes(selectedSubject)  && prompt.qtype === 600 && (////get point knowledge for commonchat session
                                <>
                                 <span onClick={() => handleCopyVariable('{subject}')}  
                                style={{backgroundColor:'white',color:'black',cursor:'pointer'}}>{'{subject}'}</span>
    
                                <span onClick={() => handleCopyVariable('{grade}')}
                                style={{backgroundColor:'white',color:'black',cursor:'pointer'}}>{'{grade}'}</span>

                                <span onClick={() => handleCopyVariable('{pk_content}')}  
                                style={{backgroundColor:'white',color:'black',cursor:'pointer'}}>{'{pk_content}'}</span>
                                <br></br>
                                <br></br>
                                grade 100 小学，200 初中，300 高中，写不写grade变量都可以，如果不写，指定小学初中高中即可。
                                整个对话过程作为request请求的数组添加到上下文.
                                <br></br>
                                pk_content变量：本轮对话的每一次对话涉及的知识点作为上下文参考，为数组形式
                                </>
                            )}


                            {selectedSubject === "familyhead" && (//文档对话
                               <>
                               <span onClick={() => handleCopyVariable('{grade}')}  
                                style={{backgroundColor:'white',color:'black',cursor:'pointer'}}>{'{grade}'}</span>
                                <span onClick={() => handleCopyVariable('{qtype}')}  
                                style={{backgroundColor:'white',color:'black',cursor:'pointer'}}>{'{qtype}'}</span>

                                <span onClick={() => handleCopyVariable('{input}')}  
                                style={{backgroundColor:'white',color:'black',cursor:'pointer'}}>{'{input}'}</span>
                                <span onClick={() => handleCopyVariable('{context}')}  
                                style={{backgroundColor:'white',color:'black',cursor:'pointer'}}>{'{context}'}</span>

                                <span onClick={() => handleCopyVariable('{teaching_strategy}')}  
                                style={{backgroundColor:'white',color:'black',cursor:'pointer'}}>{'{teaching_strategy}'}</span>
                                
                                <span onClick={() => handleCopyVariable('{personal_interest}')}  
                                style={{backgroundColor:'white',color:'black',cursor:'pointer'}}>{'{personal_interest}'}</span>

                                <span onClick={() => handleCopyVariable('{teacher_style}')}  
                                style={{backgroundColor:'white',color:'black',cursor:'pointer'}}>{'{teacher_style}'}</span>
                                <br></br>
                                <br></br>
                                qtype变量：问题类型
                                context变量:向量搜索到的书籍内容作为上下文
                               </>
                            )}

                            {selectedSubject === "picturebook" && (//文档对话
                                <>
                                <span onClick={() => handleCopyVariable('{grade}')}  
                                style={{backgroundColor:'white',color:'black',cursor:'pointer'}}>{'{grade}'}</span>
                                <span onClick={() => handleCopyVariable('{summarize}')}  
                                style={{backgroundColor:'white',color:'black',cursor:'pointer'}}>{'{summarize}'}</span>
                                <span onClick={() => handleCopyVariable('{pagecontent}')}  
                                style={{backgroundColor:'white',color:'black',cursor:'pointer'}}>{'{pagecontent}'}</span>

                                <span onClick={() => handleCopyVariable('{teaching_strategy}')}  
                                style={{backgroundColor:'white',color:'black',cursor:'pointer'}}>{'{teaching_strategy}'}</span>
                                
                                <span onClick={() => handleCopyVariable('{personal_interest}')}  
                                style={{backgroundColor:'white',color:'black',cursor:'pointer'}}>{'{personal_interest}'}</span>

                                <span onClick={() => handleCopyVariable('{teacher_style}')}  
                                style={{backgroundColor:'white',color:'black',cursor:'pointer'}}>{'{teacher_style}'}</span>
                                <br></br>
                                <br></br>
                                summarize变量:绘本摘要
                                pagecontent变量：提问当前页的绘本文字内容
                                </>
                            )}

                            {selectedSubject === "user_generate_character" && (//
                                <>
                                <span onClick={() => handleCopyVariable('{qa_poll}')}  
                                style={{backgroundColor:'white',color:'black',cursor:'pointer'}}>{'{qa_poll}'}</span>
                                <br></br>
                                <br></br>
                                qa_poll变量：根据用户提交的问卷调查内容，作为上下文,格式是：问答对数组
                                </>
                            )}


                            {selectedSubject === "docconversation" && (//文档对话
                                <>
                                <span onClick={() => handleCopyVariable('{input}')}  
                                    style={{backgroundColor:'white',color:'black',cursor:'pointer'}}>{'{input}'}
                                </span>

                                <span onClick={() => handleCopyVariable('{context}')}  
                                style={{backgroundColor:'white',color:'black',cursor:'pointer'}}>{'{context}'}</span>

                                <span onClick={() => handleCopyVariable('{teaching_strategy}')}  
                                style={{backgroundColor:'white',color:'black',cursor:'pointer'}}>{'{teaching_strategy}'}</span>
                                
                                <span onClick={() => handleCopyVariable('{personal_interest}')}  
                                style={{backgroundColor:'white',color:'black',cursor:'pointer'}}>{'{personal_interest}'}</span>

                                <span onClick={() => handleCopyVariable('{teacher_style}')}  
                                style={{backgroundColor:'white',color:'black',cursor:'pointer'}}>{'{teacher_style}'}</span>
                                <br></br>
                                <br></br>
                                context变量：向量搜索获取到的相关文档的文本内容。
                                </>
                            )}

                            {selectedSubject === "feynman_summarize" && (//文档对话
                                <>
                                <span onClick={() => handleCopyVariable('{subject}')}  
                                style={{backgroundColor:'white',color:'black',cursor:'pointer'}}>{'{subject}'}</span>
    
                                <span onClick={() => handleCopyVariable('{grade}')}  
                                style={{backgroundColor:'white',color:'black',cursor:'pointer'}}>{'{grade}'}</span>

                                 <span onClick={() => handleCopyVariable('{point}')}  
                                     style={{backgroundColor:'white',color:'black',cursor:'pointer'}}>{'{point}'}
                                 </span>

                                 <span onClick={() => handleCopyVariable('{teaching_strategy}')}  
                                style={{backgroundColor:'white',color:'black',cursor:'pointer'}}>{'{teaching_strategy}'}</span>
                                
                                <span onClick={() => handleCopyVariable('{personal_interest}')}  
                                style={{backgroundColor:'white',color:'black',cursor:'pointer'}}>{'{personal_interest}'}</span>

                                <span onClick={() => handleCopyVariable('{teacher_style}')}  
                                style={{backgroundColor:'white',color:'black',cursor:'pointer'}}>{'{teacher_style}'}</span>
                                 <br></br>
                                 <br></br>
                                 point变量: feynman对话关联的主题，再feynman模拟界面，选择的主题，针对哪个主题知识点进行费曼模拟
                                 </>
                            )}

                            {selectedSubject === "student" && (//
                                <>
                                <span onClick={() => handleCopyVariable('{style}')}  
                                 style={{backgroundColor:'white',color:'black',cursor:'pointer'}}>{'{style}'}</span>
 
                                 <span onClick={() => handleCopyVariable('{input}')}  
                                     style={{backgroundColor:'white',color:'black',cursor:'pointer'}}>{'{input}'}
                                 </span>
                                 </>
                            )}

                            {selectedSubject === "wolfram" && (//
                                <>
                                 <span onClick={() => handleCopyVariable('{input}')}  
                                     style={{backgroundColor:'white',color:'black',cursor:'pointer'}}>{'{input}'}
                                 </span>
                                 </>
                            )}

                            {selectedSubject === "qa_record" && (//
                               <>
                               <span onClick={() => handleCopyVariable('{subject}')}  
                               style={{backgroundColor:'white',color:'black',cursor:'pointer'}}>{'{subject}'}</span>
   
                               <span onClick={() => handleCopyVariable('{grade}')}  
                               style={{backgroundColor:'white',color:'black',cursor:'pointer'}}>{'{grade}'}</span>

                                <span onClick={() => handleCopyVariable('{input}')}  
                                    style={{backgroundColor:'white',color:'black',cursor:'pointer'}}>{'{input}'}
                                </span>
                                <span onClick={() => handleCopyVariable('{aiquestion}')}  
                                    style={{backgroundColor:'white',color:'black',cursor:'pointer'}}>{'{aiquestion}'}
                                </span>
                                <br></br>
                                <br></br>
                                input为学生问题，
                                aiquestion变量是ai对input内容的回复，根据这个问答对，再次提交llm生成qa问答对
                                </>
                            )}



                                {allSubjects.includes(selectedSubject)  && prompt.qtype === 21 && (//科目的feynman  ask
                                    <>
                                    <span onClick={() => handleCopyVariable('{subject}')}  
                                    style={{backgroundColor:'white',color:'black',cursor:'pointer'}}>{'{subject}'}</span>
        
                                    <span onClick={() => handleCopyVariable('{grade}')}  
                                    style={{backgroundColor:'white',color:'black',cursor:'pointer'}}>{'{grade}'}</span>

                                    <span onClick={() => handleCopyVariable('{point}')}  
                                    style={{backgroundColor:'white',color:'black',cursor:'pointer'}}>{'{point}'}</span>

                                    <span onClick={() => handleCopyVariable('{input}')}  
                                    style={{backgroundColor:'white',color:'black',cursor:'pointer'}}>{'{input}'}</span>
        
                                    <span onClick={() => handleCopyVariable('{aiquestion}')}  
                                    style={{backgroundColor:'white',color:'black',cursor:'pointer'}}>{'{aiquestion}'}</span>

                                    <span onClick={() => handleCopyVariable('{score}')}  
                                    style={{backgroundColor:'white',color:'black',cursor:'pointer'}}>{'{score}'}</span>

                                    <span onClick={() => handleCopyVariable('{teaching_strategy}')}  
                                    style={{backgroundColor:'white',color:'black',cursor:'pointer'}}>{'{teaching_strategy}'}</span>
                                    
                                    <span onClick={() => handleCopyVariable('{personal_interest}')}  
                                    style={{backgroundColor:'white',color:'black',cursor:'pointer'}}>{'{personal_interest}'}</span>

                                    <span onClick={() => handleCopyVariable('{teacher_style}')}  
                                    style={{backgroundColor:'white',color:'black',cursor:'pointer'}}>{'{teacher_style}'}</span>

                                    <br></br>
                                    <br></br>
                                    point变量：feynman的主题知识点
                                    </>
                                )}

                            {allSubjects.includes(selectedSubject)  && prompt.qtype === 22 && (//科目的feynman  score
                                <>
                                <span onClick={() => handleCopyVariable('{subject}')}  
                                    style={{backgroundColor:'white',color:'black',cursor:'pointer'}}>{'{subject}'}</span>
        
                                    <span onClick={() => handleCopyVariable('{grade}')}  
                                    style={{backgroundColor:'white',color:'black',cursor:'pointer'}}>{'{grade}'}</span>

                                    <span onClick={() => handleCopyVariable('{point}')}  
                                    style={{backgroundColor:'white',color:'black',cursor:'pointer'}}>{'{point}'}</span>

                                    <span onClick={() => handleCopyVariable('{input}')}  
                                    style={{backgroundColor:'white',color:'black',cursor:'pointer'}}>{'{input}'}</span>
        
                                    <span onClick={() => handleCopyVariable('{aiquestion}')}  
                                    style={{backgroundColor:'white',color:'black',cursor:'pointer'}}>{'{aiquestion}'}</span>
                                 <br></br>
                                 <br></br>
                                point变量：feynman的主题知识点
                                </>
                            )}


                            {allSubjects.includes(selectedSubject)  && prompt.qtype === 23 && (//科目的feynman  summarize
                                <>
                                 <span onClick={() => handleCopyVariable('{subject}')}  
                                style={{backgroundColor:'white',color:'black',cursor:'pointer'}}>{'{subject}'}</span>
    
                                <span onClick={() => handleCopyVariable('{grade}')}  
                                style={{backgroundColor:'white',color:'black',cursor:'pointer'}}>{'{grade}'}</span>

                                <span onClick={() => handleCopyVariable('{point}')}  
                                style={{backgroundColor:'white',color:'black',cursor:'pointer'}}>{'{point}'}</span>

                                <span onClick={() => handleCopyVariable('{teaching_strategy}')}  
                                style={{backgroundColor:'white',color:'black',cursor:'pointer'}}>{'{teaching_strategy}'}</span>
                                
                                <span onClick={() => handleCopyVariable('{personal_interest}')}  
                                style={{backgroundColor:'white',color:'black',cursor:'pointer'}}>{'{personal_interest}'}</span>

                                <span onClick={() => handleCopyVariable('{teacher_style}')}  
                                style={{backgroundColor:'white',color:'black',cursor:'pointer'}}>{'{teacher_style}'}</span>
                                 <br></br>
                                 <br></br>
                                point变量：feynman的主题知识点
                                历史记录作为request messages数组传递，
                                </>
                            )}

                            {allSubjects.includes(selectedSubject) && prompt.qtype < 10 && (//科目提示词
                            <>
                              
    
                                <span onClick={() => handleCopyVariable('{subject}')}  
                                style={{backgroundColor:'white',color:'black',cursor:'pointer'}}>{'{subject}'}</span>
    
                                <span onClick={() => handleCopyVariable('{grade}')}  
                                style={{backgroundColor:'white',color:'black',cursor:'pointer'}}>{'{grade}'}</span>


                                <span onClick={() => handleCopyVariable('{teaching_strategy}')}  
                                style={{backgroundColor:'white',color:'black',cursor:'pointer'}}>{'{teaching_strategy}'}</span>
                                
                                <span onClick={() => handleCopyVariable('{personal_interest}')}  
                                style={{backgroundColor:'white',color:'black',cursor:'pointer'}}>{'{personal_interest}'}</span>

                                <span onClick={() => handleCopyVariable('{teacher_style}')}  
                                style={{backgroundColor:'white',color:'black',cursor:'pointer'}}>{'{teacher_style}'}</span>
                                
                                </>
                            )}



                            {['chinese','english'].includes(selectedSubject) && prompt.qtype === 30 && (//语文或者英语的作文点评的第二次以后的提示词
                            <>
                               <span onClick={() => handleCopyVariable('{input}')}
                                style={{backgroundColor:'white',color:'black',cursor:'pointer'}}>{'{input}'}</span>
    
                                <span onClick={() => handleCopyVariable('{subject}')}  
                                style={{backgroundColor:'white',color:'black',cursor:'pointer'}}>{'{subject}'}</span>
    
                                <span onClick={() => handleCopyVariable('{grade}')}  
                                style={{backgroundColor:'white',color:'black',cursor:'pointer'}}>{'{grade}'}</span>
    
                                <span onClick={() => handleCopyVariable('{teaching_strategy}')}
                                style={{backgroundColor:'white',color:'black',cursor:'pointer'}}>{'{teaching_strategy}'}</span>
                                
                                <span onClick={() => handleCopyVariable('{personal_interest}')}  
                                style={{backgroundColor:'white',color:'black',cursor:'pointer'}}>{'{personal_interest}'}</span>

                                <span onClick={() => handleCopyVariable('{teacher_style}')}  
                                style={{backgroundColor:'white',color:'black',cursor:'pointer'}}>{'{teacher_style}'}</span>
                                </>
                            )}

                            {['chinese','english'].includes(selectedSubject) && prompt.qtype === 40 && (//语文或者英语的作文点评的第二次以后的提示词
                            <>
                             
    
                             <span onClick={() => handleCopyVariable('{input}')}
                                style={{backgroundColor:'white',color:'black',cursor:'pointer'}}>{'{input}'}</span>
    
                                <span onClick={() => handleCopyVariable('{subject}')}  
                                style={{backgroundColor:'white',color:'black',cursor:'pointer'}}>{'{subject}'}</span>
    
                                <span onClick={() => handleCopyVariable('{grade}')}  
                                style={{backgroundColor:'white',color:'black',cursor:'pointer'}}>{'{grade}'}</span>

                                <span onClick={() => handleCopyVariable('{teaching_strategy}')}  
                                style={{backgroundColor:'white',color:'black',cursor:'pointer'}}>{'{teaching_strategy}'}</span>
                                
                                <span onClick={() => handleCopyVariable('{personal_interest}')}  
                                style={{backgroundColor:'white',color:'black',cursor:'pointer'}}>{'{personal_interest}'}</span>

                                <span onClick={() => handleCopyVariable('{teacher_style}')}  
                                style={{backgroundColor:'white',color:'black',cursor:'pointer'}}>{'{teacher_style}'}</span>
                                </>
                            )}



                            {['chinese','english'].includes(selectedSubject) && prompt.qtype === 50 && (//语文或者英语的阅读理解的第二次以后的提示词
                            <>
                                <span onClick={() => handleCopyVariable('{input}')}
                                style={{backgroundColor:'white',color:'black',cursor:'pointer'}}>{'{input}'}</span>
    
                                <span onClick={() => handleCopyVariable('{subject}')}  
                                style={{backgroundColor:'white',color:'black',cursor:'pointer'}}>{'{subject}'}</span>
    
                                <span onClick={() => handleCopyVariable('{grade}')}  
                                style={{backgroundColor:'white',color:'black',cursor:'pointer'}}>{'{grade}'}</span>
    
                                <span onClick={() => handleCopyVariable('{teaching_strategy}')}  
                                style={{backgroundColor:'white',color:'black',cursor:'pointer'}}>{'{teaching_strategy}'}</span>
                                
                                <span onClick={() => handleCopyVariable('{personal_interest}')}  
                                style={{backgroundColor:'white',color:'black',cursor:'pointer'}}>{'{personal_interest}'}</span>

                                <span onClick={() => handleCopyVariable('{teacher_style}')}  
                                style={{backgroundColor:'white',color:'black',cursor:'pointer'}}>{'{teacher_style}'}</span>
                                </>
                            )}


                                
                                </div>
                            <br></br>

                            <TextField
                                label="Prompt (EN)"
                                value={prompt.user_prompt_en}
                                onChange={(e) => handlePromptChange(prompt.id, 'user_prompt_en', e.target.value)}
                                fullWidth
                                multiline
                                InputProps={{
                                    inputComponent: TextareaAutosize,
                                    inputProps: {
                                        style: { height: '150px', overflowY: 'auto',color:'black'  },
                                        minRows: 4
                                    },
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <Button variant="contained" color="primary" 
                                                onClick={() => handleUpdateAll(prompt.pname,prompt.qtype,'user_prompt_en',prompt.user_prompt_en)}>
                                            {t('update')}
                                            </Button>
                                        </InputAdornment>
                                        )
                                }}
                                InputLabelProps={{ style: textFieldStyles.label }}
                            /><br></br>
                            <TextField
                                label="Prompt (ZH)"
                                value={prompt.user_prompt_zh}
                                onChange={(e) => handlePromptChange(prompt.id, 'user_prompt_zh', e.target.value)}
                                fullWidth
                                multiline
                                InputProps={{
                                    inputComponent: TextareaAutosize, // 使用 TextareaAutosize 组件
                                    inputProps: {
                                        style: { height: '100px', overflowY: 'auto',color:'black' }, // 直接设置高度和滚动
                                        minRows: 4
                                    },
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <Button variant="contained" color="primary" 
                                            onClick={() => handleUpdateAll(prompt.pname,prompt.qtype,'user_prompt_zh',prompt.user_prompt_zh)}>
                                            {t('update')}
                                            </Button>
                                        </InputAdornment>
                                    )
                                }}
                                InputLabelProps={{ style: textFieldStyles.label }}
                            />
                            
                            <Button variant="contained" style={{ marginTop: '10px', width: '100%' }} onClick={() => handleSaveSubject(prompt.id)}>{t('save')} {prompt.grade} grade</Button>
                        </ListItem>
                    ))}
                    </List>
                    </Container>
            </div>

            {isLoading && (
                <LoadingData text={t(loadingText)}/>
            )}
        </div>
    );
};

export default PromptManage;
