import React, { useState,useEffect,useRef } from 'react';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import AnalysisIcon from '@mui/icons-material/Assessment'; // 假设这是分析题目的图标
import 'katex/dist/katex.min.css';
import katex from 'katex';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import { blue, orange } from '@mui/material/colors';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from '../../SnackbarProvider';
import { requestUrl } from '../../requestUrl';
import LoadingData from '../dataloading';
import CustomButton from '../../components/CustomButton';
import { getColorDark,getColorLight ,getTheme} from '../../ColorManager';
import DeleteIcon from '@mui/icons-material/Delete';
import { DragHandle } from '@mui/icons-material';
import { Box, Divider, Toolbar, Typography, IconButton,TextField,Button,Grid,FormControl,Select,MenuItem,InputLabel, Checkbox,ListItemText,Modal,Paper,Chip} from '@mui/material';
import { useCodeMirror } from '@uiw/react-codemirror';
import { cpp } from '@codemirror/lang-cpp'; // 导入 C++ 语言扩展

import './ProblemDetail.css';
import { EditorView, basicSetup } from 'codemirror';
import { EditorState } from '@codemirror/state';
import { oneDark } from '@codemirror/theme-one-dark';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw'; // 引入 rehype-raw 插件
import remarkMath from 'remark-math';
import rehypeKatex from 'rehype-katex';
import '../ChatWindow/MessageItem.css';
import TagList from './TagList';



// 生成随机颜色函数
const getRandomColor = () => {
  const letters = '0123456789ABCDEF';
  let color = '#';
  for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
};



// 外面的壳子
const AddEditProblem = ({row,onClose,addEdit,pid}) => {
  const showSnackbar = useSnackbar();
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [loadingText,setLoadingText] = useState('loading');
  const [userid,setUserid] = useState(0);
  const [colorManager, setColorManager] = useState(getColorDark()); // 初始化为空数组

  const [leftWidth, setLeftWidth] = useState(50);
  const [code, setCode] = React.useState(`#include <iostream>\nint main() {\n  std::cout << "Hello, World!";\n  return 0;\n}`);
  const [inputValue, setInputValue] = useState('');

  const editorRef = useRef(null);
  const [selectedValues, setSelectedValues] = useState([]);
  const [editorView, setEditorView] = useState(null);
  const [problemData,setProblemData] = useState({});

  const [showTagList,setShowTagList] = useState(false);

  const [tags,setTags] = useState([]);

  const get_color_by_name = (name) => {

    const item = colorManager.find(item => item.container === name);
    return item.value;
  }


  const mockData = [
    { id: 1, label: '水题' },
    { id: 2, label: '入门' },
    { id: 3, label: 'CSP-J' },
    { id: 4, label: 'CSP-S' },
    { id: 5, label: 'NOIP' },
    { id: 6, label: 'NOI' },
  ];


  useEffect(() => {
    if (editorRef.current) {
      const startState = EditorState.create({
        doc: code,
        extensions: [
          basicSetup,
          cpp(),
          oneDark,
          EditorView.theme({
            "&": {
              height: "100%", // 使用父div的100%高度
            },
            ".cm-scroller": {
              overflow: "auto",
              maxHeight: "100%", // 限制scroller的最大高度
            },
          })
        ]
      });

      const view = new EditorView({
        state: startState,
        parent: editorRef.current
      });

      setEditorView(view);

      return () => {
        view.destroy();
      };
    }
  }, []);


  const updateField = (fieldName, newValue) => {
    setProblemData(prevState => ({
      ...prevState,
      [fieldName]: newValue // 使用计算属性名来更新对应的字段
    }));
  };

  useEffect(() => {
    console.log(addEdit);
    if(addEdit == "Edit"){

    
      const fetchData = async () => {
          try {
              setIsLoading(true);
              setLoadingText("loading");
              const dataToSend = {
                  id:pid,
              };
              const data = await requestUrl(dataToSend, "problem_detail",t,showSnackbar);
              if(data.status === "success"){
                console.log(data.data);
                const data1 = data.data;

                let aaa = data1.DifficultyLevel;
                console.log("nandu::::" + aaa);
                const newArray = aaa.split(',');
                setSelectedValues(newArray);
                setProblemData(data1);
                setTags(data.tags);
              }
              
              
            } catch (error) {
                console.error("获取数据时出错:", error);
            } finally {
              setIsLoading(false);
            }
      };

      fetchData();
    }
  }, [pid]);



 // 获取编辑器内容
 const getCodeContent = () => {
  if (editorView) {
    const content = editorView.state.doc.toString();
    console.log("Editor content:", content);
    return content;
  }
};




  const handleDrag = (e) => {
    const newWidth = (e.clientX / window.innerWidth) * 100;
    setLeftWidth(newWidth);
  };


  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const year = date.getFullYear().toString().slice(-2); // 获取年份的后两位
    const month = String(date.getMonth() + 1).padStart(2, '0'); // 月份从0开始，所以要+1
    const day = String(date.getDate()).padStart(2, '0');
    const hour = String(date.getHours()).padStart(2, '0');

    return `${year}-${month}-${day}:${hour}`;
  };



  const inputStyles = {
    color:get_color_by_name('mui-textfield-text-color'),
    fontFamily: 'siyuan',
  };



  const handleClose = () => {

    onClose();
  }


  const handleChangePTitle = (e) => {
    updateField("ProblemTitle",e.target.value);
    
  };
  const handleChangePDes = (e) => {
    updateField("ProblemDes",e.target.value);
    
  };

  const handleChangeInputFormat = (e) => {
    updateField("ProblemInputFormat",e.target.value);
    
  };

  const handleChangeOutputFormat = (e) => {
    updateField("ProblemOutputFormat",e.target.value);
    
  };
  


  const handleChangePMemo = (e) => {
    updateField("ProblemMemo",e.target.value);
    
  };
  

  const handleChangeTimeLimit = (e) => {
    updateField("ProblemTimeLimit",e.target.value);
    
  };
  const handleChangeMemory = (e) => {
    updateField("ProblemMemory",e.target.value);
    
  };

  const handleChangePiP1 = (e) => {
    console.log("ssss");
    updateField("ProblemInputP1",e.target.value);
    
  };
  
  const handleChangePoP1 = (e) => {
    updateField("ProblemOutputP1",e.target.value);
    
  };


  const handleChangePiP2 = (e) => {
    updateField("ProblemInputP2",e.target.value);
    
  };
  
  const handleChangePoP2 = (e) => {
    updateField("ProblemOutputP2",e.target.value);
    
  };


    // 插入文本到当前光标位置
    const insertTextAtCursor = (text) => {
      if (editorView) {
        const transaction = editorView.state.update({
          changes: {
            from: editorView.state.selection.main.from, // 当前光标位置的起点
            to: editorView.state.selection.main.to, // 当前光标位置的终点（支持选择插入）
            insert: text // 要插入的文本
          }
        });
        editorView.dispatch(transaction);
      }
    };


// 覆盖编辑器内容
const overwriteContent = (newContent) => {
  if (editorView) {
    const transaction = editorView.state.update({
      changes: {
        from: 0, // 起始位置
        to: editorView.state.doc.length, // 结束位置（覆盖整个内容）
        insert: newContent // 新的内容
      }
    });
    editorView.dispatch(transaction);
  }
};

    const handleChangePCode = (e) => {
      updateField("ProblemCode",e.target.value);
        
      };
      
    
    

    const handleSave = async () => {
      console.log(addEdit);

      const difficulty_level = selectedValues.join(',');

      const tagids = tags.map(tag => tag.id).join(',');
      updateField("DifficultyLevel",difficulty_level);

       
          const dataToSend = {
            Id:pid || 0,
            ProblemTitle: problemData.ProblemTitle,
            ProblemCode:problemData.ProblemCode,
            ProblemDes:problemData.ProblemDes || ' ',
            ProblemInputFormat:problemData.ProblemInputFormat || '',
            ProblemOutputFormat:problemData.ProblemOutputFormat || '',
            ProblemInputP1:problemData.ProblemInputP1 || '',
            ProblemOutputP1:problemData.ProblemOutputP1 || '',
            ProblemInputP2:problemData.ProblemInputP2 || '',
            ProblemOutputP2:problemData.ProblemOutputP2 || '',
            ProblemMemo:problemData.ProblemMemo || '',
            ProblemMemory:problemData.ProblemMemory || 0,
            ProblemTimeLimit:problemData.ProblemTimeLimit || '',
            DifficultyLevel:difficulty_level || '',
            TagIds:tagids,
          };
          console.log("===============");
          console.log(JSON.stringify(dataToSend));
        if(addEdit == "Add"){
            try {
                setIsLoading(true);
                const data = await requestUrl(dataToSend, "problem_add",t,showSnackbar);
                console.log("ID:::::" + data);
              }
              catch (error) {
                setIsLoading(false);
                console.error("There was an error fetching the reply:", error);
              }
              finally{
                setIsLoading(false);
              }
        }   
        if(addEdit == "Edit"){
          try {
            console.log("开始编辑");
            setIsLoading(true);
            const data = await requestUrl(dataToSend, "problem_edit",t,showSnackbar);
            console.log("ID:::::" + data);
          }
          catch (error) {
            setIsLoading(false);
            console.error("There was an error fetching the reply:", error);
          }
          finally{
            setIsLoading(false);
          }
        } 
    }



    const handleChange = (event) => {
      setSelectedValues(event.target.value);
    };



    const handleAddTags = () => {
      setShowTagList(true);
    };
    




    const handleCloseTagList = (tagids,tagnames) => {
      setTags(tagnames);

      console.log("tagnames:::");
      console.log(JSON.stringify(tagnames));
      console.log("tagids:::");
      console.log(tagids);
      setShowTagList(false);
    };
    




    return (
      <div style={{ height: '100%', width:'100%', display: 'flex', flexDirection: 'column' ,backgroundColor:get_color_by_name('pop-window-bg'),}}>
      
        <Box sx={{ height: '100%' }}>
        {/* 上部工具栏 */}
        <div style={{ 
            position: 'relative',
            height: '30px',
            }}>
            <div style={{ 
                position: 'absolute', 
                right: '20px',
                display: 'flex', 
                gap: '20px' 
            }}>
               <Button
                    onClick={handleSave}
                    variant="contained"
                    sx={{
                        width:'200px',
                        backgroundColor: '#1976d2', // 蓝色背景
                        color: 'white',
                        '&:hover': {
                            backgroundColor: '#1565c0', // 悬停时的颜色
                        },
                        mb: 2, // 添加下边距，使两个按钮之间有间距
                        }}
                    >
                    保存
                </Button>
                <Button
                    onClick={handleClose}
                    variant="contained"
                    sx={{
                        width:'200px',
                        backgroundColor: '#1976d2', // 蓝色背景
                        color: 'white',
                        '&:hover': {
                        backgroundColor: '#1565c0', // 悬停时的颜色
                        },
                        mb: 2, // 添加下边距，使两个按钮之间有间距
                    }}
                    >
                    关闭
                </Button>
            </div>
            </div>
          {/* 下部分 */}
          <Box sx={{ display: 'flex', height: 'calc(100% - 64px)' }}>
            {/* 左侧部分 */}
            <Box
                sx={{
                    overflowY: 'auto',
                    height:'800px',
                    width: `${leftWidth}%`,
                    borderRadius: 2,
                    p: 4,
                    display: 'flex',
                    flexDirection: 'column',
                    backgroundColor:get_color_by_name('pop-window-bg'),
                    border: `1px solid ${get_color_by_name('pop-window-border-color')}`,
                }}
                >
                    <Typography variant="h6" component="h2" id="modal-modal-title" sx={{ mb: 2 ,backgroundColor:get_color_by_name('div-bg'), color:get_color_by_name('headline-color')}}>
                        题目标题
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                        <TextField
                            label=""
                            multiline
                            rows={1}
                            value={problemData.ProblemTitle}
                            onChange={handleChangePTitle}
                            sx={{
                            width: '100%',
                            backgroundColor: get_color_by_name('mui-textfield-bg'),
                            // Targeting the input element for styling
                            '& .MuiInputBase-input': {
                                color: 'white', // Change text color
                            },
                            // Targeting the label for styling
                            '& .MuiInputLabel-root': {
                                color: 'white', // Change label color
                            },
                            // Targeting the outlined border for styling
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                borderColor: get_color_by_name('pop-window-border-color'),
                                },
                                '&:hover fieldset': {
                                borderColor: get_color_by_name('pop-window-border-color'), // Change border color on hover
                                },
                                '&.Mui-focused fieldset': {
                                borderColor: get_color_by_name('pop-window-border-color'), // Change border color when focused
                                },
                            },
                            }}
                        />
                    </Typography>
                    <Typography variant="h6" component="h2" id="modal-modal-title" sx={{ mb: 2 ,backgroundColor:get_color_by_name('div-bg'), color:get_color_by_name('headline-color')}}>
                        题目编号
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                        <TextField
                            label=""
                            multiline
                            rows={1}
                            value={problemData.ProblemCode}
                            onChange={handleChangePCode}
                            sx={{
                            width: '100%',
                            backgroundColor: get_color_by_name('mui-textfield-bg'),
                            // Targeting the input element for styling
                            '& .MuiInputBase-input': {
                                color: 'white', // Change text color
                            },
                            // Targeting the label for styling
                            '& .MuiInputLabel-root': {
                                color: 'white', // Change label color
                            },
                            // Targeting the outlined border for styling
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                borderColor: get_color_by_name('pop-window-border-color'),
                                },
                                '&:hover fieldset': {
                                borderColor: get_color_by_name('pop-window-border-color'), // Change border color on hover
                                },
                                '&.Mui-focused fieldset': {
                                borderColor: get_color_by_name('pop-window-border-color'), // Change border color when focused
                                },
                            },
                            }}
                        />
                    </Typography>

                    <Typography variant="h6" component="h2" id="modal-modal-title" sx={{ mb: 2 ,backgroundColor:get_color_by_name('div-bg'), color:get_color_by_name('headline-color')}}>
                        题目描述
                    </Typography>
                <Typography variant="body1" gutterBottom>
                <TextField
                    label=""
                    multiline
                    rows={4}
                    value={problemData.ProblemDes}
                    onChange={handleChangePDes}
                    sx={{
                      width: '100%',
                      backgroundColor: get_color_by_name('mui-textfield-bg'),
                      // Targeting the input element for styling
                      '& .MuiInputBase-input': {
                        color: 'white', // Change text color
                      },
                      // Targeting the label for styling
                      '& .MuiInputLabel-root': {
                        color: 'white', // Change label color
                      },
                      // Targeting the outlined border for styling
                      '& .MuiOutlinedInput-root': {
                        '& fieldset': {
                          borderColor: get_color_by_name('pop-window-border-color'),
                        },
                        '&:hover fieldset': {
                          borderColor: get_color_by_name('pop-window-border-color'), // Change border color on hover
                        },
                        '&.Mui-focused fieldset': {
                          borderColor: get_color_by_name('pop-window-border-color'), // Change border color when focused
                        },
                      },
                    }}
                  />
                </Typography>
                <Divider sx={{ width: '100%', marginBottom: '16px' }} />

                <Typography variant="h6" component="h2" id="modal-modal-title" sx={{ mb: 2 ,backgroundColor:get_color_by_name('div-bg'), color:get_color_by_name('headline-color')}}>
                    输入格式说明
                </Typography>
                <Typography variant="body1" gutterBottom>
                <TextField
                    label=""
                    multiline
                    rows={4}
                    value={problemData.ProblemInputFormat}
                    onChange={handleChangeInputFormat}
                    sx={{
                      width: '100%',
                      backgroundColor: get_color_by_name('mui-textfield-bg'),
                      // Targeting the input element for styling
                      '& .MuiInputBase-input': {
                        color: 'white', // Change text color
                      },
                      // Targeting the label for styling
                      '& .MuiInputLabel-root': {
                        color: 'white', // Change label color
                      },
                      // Targeting the outlined border for styling
                      '& .MuiOutlinedInput-root': {
                        '& fieldset': {
                          borderColor: get_color_by_name('pop-window-border-color'),
                        },
                        '&:hover fieldset': {
                          borderColor: get_color_by_name('pop-window-border-color'), // Change border color on hover
                        },
                        '&.Mui-focused fieldset': {
                          borderColor: get_color_by_name('pop-window-border-color'), // Change border color when focused
                        },
                      },
                    }}
                  />
                </Typography>

                <Typography variant="h6" component="h2" id="modal-modal-title" sx={{ mb: 2 ,backgroundColor:get_color_by_name('div-bg'), color:get_color_by_name('headline-color')}}>
                    输出格式说明
                </Typography>
                <Typography variant="body1" gutterBottom>
                <TextField
                    label=""
                    multiline
                    rows={4}
                    value={problemData.ProblemOutputFormat}
                    onChange={handleChangeOutputFormat}
                    sx={{
                      width: '100%',
                      backgroundColor: get_color_by_name('mui-textfield-bg'),
                      // Targeting the input element for styling
                      '& .MuiInputBase-input': {
                        color: 'white', // Change text color
                      },
                      // Targeting the label for styling
                      '& .MuiInputLabel-root': {
                        color: 'white', // Change label color
                      },
                      // Targeting the outlined border for styling
                      '& .MuiOutlinedInput-root': {
                        '& fieldset': {
                          borderColor: get_color_by_name('pop-window-border-color'),
                        },
                        '&:hover fieldset': {
                          borderColor: get_color_by_name('pop-window-border-color'), // Change border color on hover
                        },
                        '&.Mui-focused fieldset': {
                          borderColor: get_color_by_name('pop-window-border-color'), // Change border color when focused
                        },
                      },
                    }}
                  />
                </Typography>


                <Divider sx={{ width: '100%', marginBottom: '16px' }} />
                <Typography variant="h6" component="h2" id="modal-modal-title" sx={{ mb: 2 ,backgroundColor:get_color_by_name('div-bg'), color:get_color_by_name('headline-color')}}>
                    难度级别
                </Typography>
                <Typography variant="body1" gutterBottom>
                  <FormControl fullWidth>
                    <InputLabel id="multi-select-label">选择标签</InputLabel>
                    <Select
                        labelId="multi-select-label"
                        id="multi-select"
                        multiple
                        value={selectedValues}
                        onChange={handleChange}
                        renderValue={(selected) => selected.join(', ')}
                        label="选择标签"
                        sx={{ color: 'white' }} // 设置文本框内文字颜色为白色
                    >
                        {mockData.map((item) => (
                        <MenuItem key={item.id} value={item.label}>
                            <Checkbox checked={selectedValues.indexOf(item.label) > -1} />
                            <ListItemText primary={item.label} />
                        </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </Typography>

                <Typography variant="h6" component="h2" id="modal-modal-title" sx={{ mb: 2 ,backgroundColor:get_color_by_name('div-bg'), color:get_color_by_name('headline-color')}}>
                  <CustomButton sx={{ marginRight: '10px', height: '100%' }} onClick={handleAddTags}>
                    {t('添加标签')}
                  </CustomButton> 
                </Typography>
                <Typography variant="body1" gutterBottom>
                  <div>
                    {tags.map((tag) => (
                      <Chip
                        key={tag.id}
                        label={tag.tag_name_zh}
                        style={{
                          backgroundColor: getRandomColor(), // 随机背景颜色
                          color: 'white',                    // 字体颜色为白色
                          margin: '0 10px 10px 0'            // 标签间隔
                        }}
                      />
                    ))}
                  </div>
                </Typography>
                <Typography variant="h6" component="h2" id="modal-modal-title" sx={{ mb: 2 ,backgroundColor:get_color_by_name('div-bg'), color:get_color_by_name('headline-color')}}>
                    输入输出样例
                </Typography>

                <Grid container spacing={2}>


                <Grid item xs={6}>

                    <Typography variant="subtitle1" component="h2" id="modal-modal-title" sx={{ mb: 2 ,backgroundColor:get_color_by_name('div-bg'), color:get_color_by_name('headline-color')}}>
                        内存限制
                    </Typography>
                        <TextField
                        InputLabelProps={{
                        style: {
                            color: get_color_by_name('mui-textfield-text-color'), // 使用主题颜色
                        },
                        }}
                        
                        sx={{
                        '& .MuiInputBase-root': {
                            backgroundColor: get_color_by_name('mui-textfield-bg'), // 自定义背景色
                        },
                        '& .MuiOutlinedInput-root': {
                            '&:hover fieldset': {
                            borderColor: get_color_by_name('mui-textfield-bg'),
                            },
                            '&.Mui-focused fieldset': {
                            borderColor: 'primary.main',
                            },
                            '& fieldset': {
                            borderColor: get_color_by_name('mui-textfield-bg'),
                            },
                        },
                        }}
                        fullWidth
                        multiline
                        rows={1}
                        value={problemData.ProblemMemory}
                        onChange={handleChangeMemory}
                        autoFocus
                        InputProps={{
                            sx: inputStyles,
                            }}
                            />
                        </Grid>

                      <Grid item xs={6}>
                      <Typography variant="subtitle1" component="h2" id="modal-modal-title" sx={{ mb: 2 ,backgroundColor:get_color_by_name('div-bg'), color:get_color_by_name('headline-color')}}>
                          时间限制
                      </Typography>
                      <TextField
                        InputLabelProps={{
                        style: {
                            color: get_color_by_name('mui-textfield-text-color'), // 使用主题颜色
                        },
                        }}
                        
                        sx={{
                        '& .MuiInputBase-root': {
                            backgroundColor: get_color_by_name('mui-textfield-bg'), // 自定义背景色
                        },
                        '& .MuiOutlinedInput-root': {
                            '&:hover fieldset': {
                            borderColor: get_color_by_name('mui-textfield-bg'),
                            },
                            '&.Mui-focused fieldset': {
                            borderColor: 'primary.main',
                            },
                            '& fieldset': {
                            borderColor: get_color_by_name('mui-textfield-bg'),
                            },
                        },
                        }}
                        fullWidth
                        multiline

                        value={problemData.ProblemTimeLimit}
                        onChange={handleChangeTimeLimit}
                        autoFocus
                        InputProps={{
                        sx: inputStyles,
                        }}
                          />
                      </Grid>

                      <Grid item xs={6}>

                        <Typography variant="subtitle1" component="h2" id="modal-modal-title" sx={{ mb: 2 ,backgroundColor:get_color_by_name('div-bg'), color:get_color_by_name('headline-color')}}>
                          输入 #1
                        </Typography>
                        <TextField
                              
                          InputLabelProps={{
                          style: {
                              color: get_color_by_name('mui-textfield-text-color'), // 使用主题颜色
                          },
                          }}
                          
                          sx={{
                          '& .MuiInputBase-root': {
                              backgroundColor: get_color_by_name('mui-textfield-bg'), // 自定义背景色
                          },
                          '& .MuiOutlinedInput-root': {
                              '&:hover fieldset': {
                              borderColor: get_color_by_name('mui-textfield-bg'),
                              },
                              '&.Mui-focused fieldset': {
                              borderColor: 'primary.main',
                              },
                              '& fieldset': {
                              borderColor: get_color_by_name('mui-textfield-bg'),
                              },
                          },
                          }}
                          fullWidth
                          multiline
                          
                          value={problemData.ProblemInputP1}
                          onChange={handleChangePiP1}
                          autoFocus
                          InputProps={{
                          sx: inputStyles,
                          endAdornment: (
                              <Button
                              variant="outlined"
                              size="small"
                              onClick={() => navigator.clipboard.writeText('32')}
                              >
                              复制
                              </Button>
                          ),
                          }}
                              />
                      </Grid>

                      <Grid item xs={6}>
                      <Typography variant="subtitle1" component="h2" id="modal-modal-title" sx={{ mb: 2 ,backgroundColor:get_color_by_name('div-bg'), color:get_color_by_name('headline-color')}}>
                          输出 #1
                        </Typography>
                        <TextField
                          InputLabelProps={{
                          style: {
                              color: get_color_by_name('mui-textfield-text-color'), // 使用主题颜色
                          },
                          }}
                          
                          sx={{
                          '& .MuiInputBase-root': {
                              backgroundColor: get_color_by_name('mui-textfield-bg'), // 自定义背景色
                          },
                          '& .MuiOutlinedInput-root': {
                              '&:hover fieldset': {
                              borderColor: get_color_by_name('mui-textfield-bg'),
                              },
                              '&.Mui-focused fieldset': {
                              borderColor: 'primary.main',
                              },
                              '& fieldset': {
                              borderColor: get_color_by_name('mui-textfield-bg'),
                              },
                          },
                          }}
                          fullWidth
                          multiline
                      
                          value={problemData.ProblemOutputP1}
                          onChange={handleChangePoP1}
                          autoFocus
                          InputProps={{
                          sx: inputStyles,
                          endAdornment: (
                              <Button
                              variant="outlined"
                              size="small"
                              onClick={() => navigator.clipboard.writeText('32')}
                              >
                              复制
                              </Button>
                          ),
                          }}
                            />
                      </Grid>

        


        <Grid item xs={6}>

          <Typography variant="subtitle1" component="h2" id="modal-modal-title" sx={{ mb: 2 ,backgroundColor:get_color_by_name('div-bg'), color:get_color_by_name('headline-color')}}>
            输入 #2
          </Typography>
          <TextField
                
            InputLabelProps={{
            style: {
                color: get_color_by_name('mui-textfield-text-color'), // 使用主题颜色
            },
            }}
            
            sx={{
            '& .MuiInputBase-root': {
                backgroundColor: get_color_by_name('mui-textfield-bg'), // 自定义背景色
            },
            '& .MuiOutlinedInput-root': {
                '&:hover fieldset': {
                borderColor: get_color_by_name('mui-textfield-bg'),
                },
                '&.Mui-focused fieldset': {
                borderColor: 'primary.main',
                },
                '& fieldset': {
                borderColor: get_color_by_name('mui-textfield-bg'),
                },
            },
            }}
            fullWidth
            multiline
            
            value={problemData.ProblemInputP2}
            onChange={handleChangePiP2}
            autoFocus
            InputProps={{
            sx: inputStyles,
            endAdornment: (
                <Button
                variant="outlined"
                size="small"
                onClick={() => navigator.clipboard.writeText('32')}
                >
                复制
                </Button>
            ),
            }}
                />
        </Grid>

        <Grid item xs={6}>
        <Typography variant="subtitle1" component="h2" id="modal-modal-title" sx={{ mb: 2 ,backgroundColor:get_color_by_name('div-bg'), color:get_color_by_name('headline-color')}}>
            输出 #2
          </Typography>
          <TextField
            InputLabelProps={{
            style: {
                color: get_color_by_name('mui-textfield-text-color'), // 使用主题颜色
            },
            }}
            
            sx={{
            '& .MuiInputBase-root': {
                backgroundColor: get_color_by_name('mui-textfield-bg'), // 自定义背景色
            },
            '& .MuiOutlinedInput-root': {
                '&:hover fieldset': {
                borderColor: get_color_by_name('mui-textfield-bg'),
                },
                '&.Mui-focused fieldset': {
                borderColor: 'primary.main',
                },
                '& fieldset': {
                borderColor: get_color_by_name('mui-textfield-bg'),
                },
            },
            }}
            fullWidth
            multiline
        
            value={problemData.ProblemOutputP2}
            onChange={handleChangePoP2}
            autoFocus
            InputProps={{
            sx: inputStyles,
            endAdornment: (
                <Button
                variant="outlined"
                size="small"
                onClick={() => navigator.clipboard.writeText('32')}
                >
                复制
                </Button>
            ),
            }}
              />
        </Grid>


      </Grid> 
                <Typography variant="body1" gutterBottom>
                    <div className="markdown-s">
                        <ReactMarkdown
                        children={problemData.ProblemTitle}
                        remarkPlugins={[remarkMath]}
                        rehypePlugins={[rehypeRaw, rehypeKatex]}
                        />
                    </div>
                </Typography>
                <Divider sx={{ width: '100%', marginBottom: '16px' }} />

                <Typography variant="h6" component="h2" id="modal-modal-title" sx={{ mb: 2 ,backgroundColor:get_color_by_name('div-bg'), color:get_color_by_name('headline-color')}}>
                    说明提示
                </Typography>
                <Typography variant="body2" gutterBottom>
                <TextField
                    label=""
                    multiline
                    rows={4}
                    value={problemData.ProblemMemo}
                    onChange={handleChangePMemo}
                    sx={{
                      width: '100%',
                      backgroundColor: get_color_by_name('mui-textfield-bg'),
                      // Targeting the input element for styling
                      '& .MuiInputBase-input': {
                        color: 'white', // Change text color
                      },
                      // Targeting the label for styling
                      '& .MuiInputLabel-root': {
                        color: 'white', // Change label color
                      },
                      // Targeting the outlined border for styling
                      '& .MuiOutlinedInput-root': {
                        '& fieldset': {
                          borderColor: get_color_by_name('pop-window-border-color'),
                        },
                        '&:hover fieldset': {
                          borderColor: get_color_by_name('pop-window-border-color'), // Change border color on hover
                        },
                        '&.Mui-focused fieldset': {
                          borderColor: get_color_by_name('pop-window-border-color'), // Change border color when focused
                        },
                      },
                    }}
                  />
                </Typography>
               
            </Box>

            {/* 拖拽分隔线 */}
            <Box
              sx={{
                width: '10px', // 调整线条宽度
                backgroundColor: get_color_by_name('div-bg'),
                cursor: 'col-resize',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
              onMouseDown={(e) => {
                document.addEventListener('mousemove', handleDrag);
                document.addEventListener('mouseup', () => {
                  document.removeEventListener('mousemove', handleDrag);
                });
              }}
            >
              <DragHandle />
            </Box>
            {/* 右侧部分 */}
            <Box
              sx={{
                width: `${100 - leftWidth}%`,
                display: 'flex',
                flexDirection: 'column', // 让内容垂直排列
                justifyContent:'top',
                alignItems: 'flex-start',
                height:'800px',
                backgroundColor: get_color_by_name('message-item-bg'),
              }}
            >
                <Box sx={{ width: '100%',marginTop:'10px',marginLeft:'20px' }}>
                    <Typography variant="h6" component="h2" id="modal-modal-title" sx={{ mb: 2 ,backgroundColor:get_color_by_name('div-bg'), color:get_color_by_name('headline-color')}}>
                        题目标题
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                        <div className="markdown-r">
                            <ReactMarkdown
                            children={problemData.ProblemTitle}
                            remarkPlugins={[remarkMath]}
                            rehypePlugins={[rehypeRaw, rehypeKatex]}
                            />
                        </div>
                    </Typography>

                    <Typography variant="h6" component="h2" id="modal-modal-title" sx={{ mb: 2 ,backgroundColor:get_color_by_name('div-bg'), color:get_color_by_name('headline-color')}}>
                        题目描述
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                        <div className="markdown-r">
                            <ReactMarkdown
                            children={problemData.ProblemDes}
                            remarkPlugins={[remarkMath]}
                            rehypePlugins={[rehypeRaw, rehypeKatex]}
                            />
                        </div>
                    </Typography>
                    <Divider sx={{ width: '100%', marginBottom: '16px' }} />

                    <Typography variant="h6" component="h2" id="modal-modal-title" sx={{ mb: 2 ,backgroundColor:get_color_by_name('div-bg'), color:get_color_by_name('headline-color')}}>
                        输入格式
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                    <div className="markdown-r">
                            <ReactMarkdown
                            children={problemData.ProblemInputFormat}
                            remarkPlugins={[remarkMath]}
                            rehypePlugins={[rehypeRaw, rehypeKatex]}
                            />
                        </div>
                    </Typography>
                    <Divider sx={{ width: '100%', marginBottom: '16px' }} />
                    <Typography variant="h6" component="h2" id="modal-modal-title" sx={{ mb: 2 ,backgroundColor:get_color_by_name('div-bg'), color:get_color_by_name('headline-color')}}>
                        说明提示
                    </Typography>
                    <Typography variant="body2" gutterBottom>
                        <div className="markdown-r">
                            <ReactMarkdown
                            
                            children={problemData.ProblemMemo}
                            remarkPlugins={[remarkMath]}
                            rehypePlugins={[rehypeRaw, rehypeKatex]}
                            />
                        </div>
                    </Typography>
                </Box>
            </Box>
          </Box>
        </Box>




        <Modal
          open={showTagList}
          onClose={handleCloseTagList}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          
        >
        <div style={{
          height: '100vh',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}>
          <Paper
            style={{
             
              border: `1px solid ${get_color_by_name('pop-window-border-color')}`,
              backgroundColor:get_color_by_name('pop-window-bg'),
              padding: '1em', // Optional: add some padding to the Paper
              width: '95%',  // Optional: adjust the width as needed
              height:'850px'
            }}
          >
            {/* Your content goes here */}
            <TagList onClose={handleCloseTagList}/>
          </Paper>
        </div>
      </Modal>



        {isLoading && (
        <LoadingData text={t(loadingText)}/>
      )}
    </div>
    )
}

export default AddEditProblem