// LatexTemplateModule.jsx
import React, { useState,useEffect,useRef } from 'react';
import styled from 'styled-components';
import katex from 'katex';
import { getHostName } from '../../utils/urlUtils';
import { useTranslation } from 'react-i18next';
import { Paper, TextField, MenuItem, Button, AppBar, Toolbar, Typography, Box,Popover,IconButton,Modal } from '@mui/material';
import PhotoCamera from '@mui/icons-material/PhotoCamera';
import { getColorDark,getColorLight } from '../../ColorManager';
import CustomButton from '../../components/CustomButton';
import PhotoComponent from '../LatexModalComponent/PhotoComponent';
import { requestUrl } from '../../requestUrl';
import LoadingData from '../dataloading';
import { useSnackbar } from '../../SnackbarProvider';
import MicIcon from '@mui/icons-material/Mic';
import AudioRecorder from '../AudioRecorder/AudioRecorder';

export const WritingConfig = ({ onCloseWritingConfig,writingConfigData }) => {

    const { t } = useTranslation();

    const [colorManager, setColorManager] = useState(getColorDark()); // 初始化为空数组

    const showSnackbar = useSnackbar();
    const [data, setData] = useState({});
    const [selectedCategory, setSelectedCategory] = useState(null);
    const [items, setItems] = useState([]);
    const [loading, setLoading] = useState(false);
    const [loadingText,setLoadingText] = useState('loading');

    const [isShowTextOCRDiv, setIsShowTextOCRDiv] = useState(false);//显示语文英语的图片识别，主要用于作文点评
    const [anchorEl, setAnchorEl] = useState(null);
    const [writingType, setWritingType] = useState('');
    const [writingTypeItem, setWritingTypeItem] = useState('');

    const [writingTitle,setWritingTitle] = useState('');
    const [otherRequirement,setOtherRequirement] = useState('');
    const open = Boolean(anchorEl);

    const [anchorE2, setAnchorE2] = useState(null);

    const openItem = Boolean(anchorE2);

    const id = open ? 'article-type-popover' : undefined;
    const idItem = openItem ? 'article-type-popover' : undefined;

    const [popoverWidth, setPopoverWidth] = useState(0);
    const textFieldRef = useRef(null);
    const textFieldRefItem = useRef(null);

 
    useEffect(() => {
        // 假设 fetchData 是一个获取数据的异步函数
        const fetchData = async () => {
            try {
                setLoading(true);
                setLoadingText("loading");
                const dataToSend = {
                    id:1,
                };
                const data = await requestUrl(dataToSend, "get_wrtingconfig_article_type",t,showSnackbar);
                console.log(JSON.stringify(data));
                if(data.status === "success"){
                    setData(data.data);
                }
              } 
              catch (error) {
                  console.error("获取数据时出错:", error);
              }
              finally {
                setLoading(false);
              }
        };

        fetchData();
    }, []);




     //获取颜色设置，系统加载时设置
    useEffect(() => {

        const themeName = localStorage.getItem('theme');//此处存储的是数字1-12，而不是汉字，
        if (themeName) {
        
        if(themeName === "dark"){
            setColorManager(getColorDark());
        }

        
        if(themeName === "light"){
            setColorManager(getColorLight());
        }
        }
        else{
        let aa = getColorDark();
        setColorManager(aa);
        }

    }, []);

    const get_color_by_name = (name) => {

        const item = colorManager.find(item => item.container === name);
        return item.value;
    }

    useEffect(() => {
        if (writingConfigData && writingConfigData.writingTitle) {
          setWritingTitle(writingConfigData.writingTitle);
          setOtherRequirement(writingConfigData.otherRequirement);
          setWritingType(writingConfigData.writingType);
          setWritingTypeItem(writingConfigData.writingTypeItem);
        }
    }, [writingConfigData]);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClickItem = (event) => {
        setAnchorE2(event.currentTarget);
    };


    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleCloseComponent = () => {
        const writingData = {
            writingType: writingType,
            writingTypeItem: writingTypeItem,
            writingTitle: writingTitle,
            otherRequirement: otherRequirement
          };

        onCloseWritingConfig(writingData);
    };
  
    const handleMenuItemClick = (type) => {
      
        setItems(data[type] || []);
        setWritingType(type);
        handleClose();
    };


    const handleCloseItem = () => {
        setAnchorE2(null);
    };


    const handleMenuItemClickItem = (type) => {
        setWritingTypeItem(type); 
        handleCloseItem();
    };


    const handleChange = (event) => {
        setWritingTitle(event.target.value);
    };


    const handleChangeOtherRequirement = (event) => {
        setOtherRequirement(event.target.value);
    };


  const inputStyles = {
    color:get_color_by_name('mui-textfield-text-color'),
    fontFamily: 'siyuan',
  };

  useEffect(() => {
    if (textFieldRef.current) {
      setPopoverWidth(textFieldRef.current.offsetWidth);
    }

    if (textFieldRefItem.current) {
        setPopoverWidth(textFieldRefItem.current.offsetWidth);
      }
  }, []);

  const handleCameraClick = () => {
    setIsShowTextOCRDiv(true);
  }
  

  
  const onClosePhotoCom = (updateLatexText) => {
    setOtherRequirement(updateLatexText);
    setIsShowTextOCRDiv(false);
  };

  const inputAreaRef = useRef(null);

  const [isShowAudioRecorder, setIsShowAudioRecorder] = useState(false);
  const [translateText, setTranslateText] = useState('');

  const onCloseAudioRecord = () => {
    setIsShowAudioRecorder(false);
    //setIsetInputValueEnable(true);
    let textOld = otherRequirement + "\n" + translateText;


   setOtherRequirement(textOld);
  }

  const onCloseOnly = () => {
    setIsShowAudioRecorder(false);
  }
  const handleAudioRecord = () => {
    setIsShowAudioRecorder(true);
  }

  return (
    <div
        style={{
            position: 'fixed', // 使用 fixed 确保它相对屏幕固定
            top: '50%', // 距离顶部 50%
            left: '50%', // 距离左侧 50%
            transform: 'translate(-50%, -50%)', // 通过 transform 将它自身的中心点移动到屏幕中心
            zIndex: 6666, // 确保容器位于最顶层
            width: '800px',
            // 添加其他所需的样式
        }}
        className='siyuanFont'
        onClick={(e) => e.stopPropagation()} // 防止容器内的点击事件冒泡
    >
        <Paper
            style={{
                padding: '20px',
                margin: '10px',
                backgroundColor: get_color_by_name('pop-window-bg'),
                border: `1px solid ${get_color_by_name('pop-window-border-color')}`,
            }}
            elevation={3}
        >
           
            <Typography variant="h6" component="h2" id="modal-modal-title" sx={{ mb: 2 ,backgroundColor:get_color_by_name('div-bg'), color:get_color_by_name('headline-color')}}>
                    写作设置
            </Typography>
                
            <Box mt={2}>
                <Box mb={1.5}> {/* mb={2.5} 对应 20px (MUI 默认间距单位为 8px) */}
                    <TextField
                        label="写作题目"
                        InputLabelProps={{
                            style: {
                            color: get_color_by_name('mui-textfield-text-color'), // 使用主题颜色
                            },
                        }}
                        value={writingTitle}
                        placeholder={t('enter your article title')}
                        multiline
                        fullWidth
                        rows={3}
                        InputProps={{
                            sx: inputStyles,
                        }}
                        onChange={handleChange}
                        sx={{
                            '& .MuiInputBase-root': {
                            backgroundColor: get_color_by_name('mui-textfield-bg'), // 自定义背景色
                            },
                            '& .MuiOutlinedInput-root': {
                            '&:hover fieldset': {
                                borderColor: get_color_by_name('mui-textfield-bg'),
                            },
                            '&.Mui-focused fieldset': {
                                borderColor: 'primary.main',
                            },
                            '& fieldset': {
                                borderColor: get_color_by_name('mui-textfield-bg'),
                            },
                            },
                        }}
                    />
                </Box>

            <Box mb={1.5}>
                <TextField
                    label="文章体裁"
                    ref={textFieldRef}
                    InputLabelProps={{
                        style: {
                        color: get_color_by_name('mui-textfield-text-color'), // 使用主题颜色
                        },
                    }}
                    value={writingType}
                    placeholder={t('enter your article title')}
                    multiline
                    fullWidth
                    readOnly
                    
                    InputProps={{
                        sx: inputStyles,
                    }}
                    
                    onClick={handleClick}
                    sx={{
                        '& .MuiInputBase-root': {
                        backgroundColor: get_color_by_name('mui-textfield-bg'), // 自定义背景色
                        },
                        '& .MuiOutlinedInput-root': {
                        '&:hover fieldset': {
                            borderColor: get_color_by_name('mui-textfield-bg'),
                        },
                        '&.Mui-focused fieldset': {
                            borderColor: 'primary.main',
                        },
                        '& fieldset': {
                            borderColor: get_color_by_name('mui-textfield-bg'),
                        },
                        },
                    }}
                />
            </Box>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                sx={{zIndex:7777,
                    '& .MuiPaper-root': {
                    backgroundColor: get_color_by_name('pop-window-bg'), // 更改背景色
                    color: get_color_by_name('pop-window-text-color'), // 更改文字颜色
                    padding: 2, // 添加内边距以使内容不贴边
                    border: `1px solid ${get_color_by_name('pop-window-border-color')}`,
                    width: popoverWidth, // 动态设置宽度
                    },}}
            >
                {Object.keys(data).map((type) => (
                    <MenuItem key={type} onClick={() => handleMenuItemClick(type)}>
                        {type}
                    </MenuItem>
                ))}
            </Popover>




                <Box mb={1.5}>
            <TextField
                label="体裁细节"
                ref={textFieldRefItem}
                InputLabelProps={{
                    style: {
                      color: get_color_by_name('mui-textfield-text-color'), // 使用主题颜色
                    },
                  }}
                value={writingTypeItem}
                placeholder={t('enter your article title')}
                multiline
                fullWidth
                readOnly
                
                InputProps={{
                    sx: inputStyles,
                }}
                onChange={handleChange}
                onClick={handleClickItem}
                sx={{
                    '& .MuiInputBase-root': {
                    backgroundColor: get_color_by_name('mui-textfield-bg'), // 自定义背景色
                    },
                    '& .MuiOutlinedInput-root': {
                    '&:hover fieldset': {
                        borderColor: get_color_by_name('mui-textfield-bg'),
                    },
                    '&.Mui-focused fieldset': {
                        borderColor: 'primary.main',
                    },
                    '& fieldset': {
                        borderColor: get_color_by_name('mui-textfield-bg'),
                    },
                    },
                }}
            />
            </Box>
                <Popover
                    id={idItem}
                    open={openItem}
                    anchorEl={anchorE2}
                    onClose={handleCloseItem}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                    }}
                    sx={{zIndex:7777,
                        '& .MuiPaper-root': {
                        backgroundColor: get_color_by_name('pop-window-bg'), // 更改背景色
                        color: get_color_by_name('pop-window-text-color'), // 更改文字颜色
                        padding: 2, // 添加内边距以使内容不贴边
                        border: `1px solid ${get_color_by_name('pop-window-border-color')}`,
                        width: popoverWidth, // 动态设置宽度
                      },}}
                >
                    {items.map((type) => (
                        <MenuItem key={type} onClick={() => handleMenuItemClickItem(type)}>
                            {type}
                        </MenuItem>
                    ))}
                </Popover>



            <Box mb={1.5}>
                <TextField
                label="其他要求,比如:人物数量,人物描述,事物特点,大概情节等,你可以天马行空开始你的描述"
                inputRef={inputAreaRef}
                InputLabelProps={{
                    style: {
                      color: get_color_by_name('mui-textfield-text-color'), // 使用主题颜色
                    },
                  }}
                value={otherRequirement}
                placeholder={t('输入你的要求')}
                multiline
                fullWidth
                rows={4}
                InputProps={{
                    sx: inputStyles,
                }}
                onChange={handleChangeOtherRequirement}
                sx={{
                    '& .MuiInputBase-root': {
                    backgroundColor: get_color_by_name('mui-textfield-bg'), // 自定义背景色
                    },
                    '& .MuiOutlinedInput-root': {
                    '&:hover fieldset': {
                        borderColor: get_color_by_name('mui-textfield-bg'),
                    },
                    '&.Mui-focused fieldset': {
                        borderColor: 'primary.main',
                    },
                    '& fieldset': {
                        borderColor: get_color_by_name('mui-textfield-bg'),
                    },
                    },
                }}
            />
                </Box>
            </Box>

            <Box mt={2} mb={1.5} display="flex" justifyContent="flex-end">
                <IconButton
                    aria-label="camera"
                    onClick={handleCameraClick}
                    onTouchEnd={handleCameraClick} // 添加触摸事件处理
                    sx={{
                        '& .MuiSvgIcon-root': {
                        color: get_color_by_name('small-icon-color')
                        }
                    }}
                    >
                    <PhotoCamera/>
                </IconButton>

                <IconButton sx={{color:get_color_by_name('small-icon-color')}} aria-label="record" component="span" onClick={handleAudioRecord}>
            <MicIcon/>
          </IconButton>


                <CustomButton onClick={handleCloseComponent}>
                    确定
                </CustomButton>
            </Box>
        </Paper>

       

          <Modal
          open={isShowAudioRecorder}
          onClose={onCloseAudioRecord}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          sx={{zIndex:9999}}
        >

            <AudioRecorder onCloseAudioRecord={onCloseAudioRecord} translateText={translateText} setTranslateText={setTranslateText} onCloseOnly={onCloseOnly} />
        </Modal>

        {isShowTextOCRDiv && (
            <PhotoComponent onClose={onClosePhotoCom } ocrType="subject" subject="chinese" moduleName="common"/>
        )}

        {loading && (
        <LoadingData text={t(loadingText)}/>
        )}
</div>
  );
};
