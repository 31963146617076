import React, { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import { getColorDark, getColorLight } from '../ColorManager';
import '../fonts.css';

function CustomButton(props) {
  const [colorManager, setColorManager] = useState(getColorDark()); // 初始化为 dark 主题

  useEffect(() => {
    const themeName = localStorage.getItem('theme'); // 从 localStorage 获取主题
    if (themeName) {
      if (themeName === 'dark') {
        setColorManager(getColorDark());
      } else if (themeName === 'light') {
        setColorManager(getColorLight());
      }
    } else {
      setColorManager(getColorDark()); // 默认主题为 dark
    }
  }, []);

  const get_color_by_name = (name) => {
    const item = colorManager.find((item) => item.container === name);
    return item ? item.value : '#000'; // 防止找不到时返回 undefined
  };

  const buttonStyle = {
    background: get_color_by_name('mui-button-bg'), // 使用主题颜色
    fontFamily: 'siyuan',
    borderRadius: 3,
    border: `1px solid ${get_color_by_name('pop-window-border-color')}`,
    color: get_color_by_name('mui-button-text-color'),
    height: 30,
    padding: '0 30px',
    width: props.width || 'auto', // 设置按钮宽度，优先使用传入的宽度
    boxShadow: '0 3px 5px 2px #2a3d66',
    '&:hover': {
      boxShadow: '0 4px 8px 3px rgba(42, 61, 102, 0.5)',
      backgroundColor: '#f5f5f5',
      color: '#2a3d66',
    },
  };

  // 合并样式，确保外部传入的样式不会覆盖掉内部定义的样式
  const combinedStyle = {
    ...buttonStyle,
    ...props.style,
  };

  return (
    <Button style={combinedStyle} {...props}>
      {props.children}
    </Button>
  );
}

export default CustomButton;
