import React, { useState,useEffect } from 'react';
import { Modal, Form, Input, Button, Dropdown, Menu ,Spin } from 'antd';
import { UserOutlined, SettingOutlined, ShoppingOutlined } from '@ant-design/icons';
import { getHostName } from '../utils/urlUtils';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import LoadingData from '../education/dataloading';


const Login = ({ user, isLoggedIn,setIsLoggedInS,setToken,handleDisconnect,setActiveView }) => {
  const { t } = useTranslation();
  const [isModalVisible, setIsModalVisible] = useState(true);
  const [isRegister, setIsRegister] = useState(false);
  const [username, setUsername] = useState(''); // 这里定义 user 和 setUser
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [loadingText,setLoadingText] = useState(false);

  const [showRegistration, setShowRegistration] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState("");
  const [invitationCode, setInvitationCode] = useState("");
  const [errorMessage, setErrorMessage] = useState(null);
  const [passwordMismatch, setPasswordMismatch] = useState(false);


  const navigate = useNavigate();


  const handleConfirmPasswordChange = (e) => {
    const newConfirmPassword = e.target.value;
    setConfirmPassword(newConfirmPassword);
  
    if (newConfirmPassword !== password) {
      setPasswordMismatch(true);
    } else {
      setPasswordMismatch(false);
    }
  };
  
  const handleLogin = async (e) => {
    //e.preventDefault();
    // 登录逻辑
    if(username === ''){
      alert("用户名不能为空");
      return;
    }
    if(password === ''){
      alert("密码不能为空");
      return;
    }

    setLoading(true); // 开始加载
    let url = getHostName() + '/login';
    setLoading(true); // 开始加载
    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          username: username,
          password: password,
        }),
      });
      

      const data = await response.json();

  
      if (data.success) {

        // 将username、uid和access_token保存在浏览器本地
        user = data.user;
        
        localStorage.setItem('username', user.username);
        localStorage.setItem('uid', user.id);
        localStorage.setItem('grade', user.grade);
        localStorage.setItem('subject', user.last_subject);
        localStorage.setItem('sversion', user.sversion);
        localStorage.setItem('language', user.language);
        localStorage.setItem('userlanguage', user.language);
        localStorage.setItem('rolename', user.rolename);
        localStorage.setItem('access_token', data.access_token);
        
        // 可以在此处执行其他操作，例如关闭模态框或跳转到其他页面
        
        setIsModalVisible(false);
        setUsername(data.username); // 更新用户名
        /////////////////////////////////////setIsLoggedIn(true);
        setIsLoggedInS(true);
        isLoggedIn.current = true;
        setLoading(false); // 结束加载

        navigate('/Default'); 
      } else {
        // 处理注册失败的情况
        
        console.log('登录失败:', data.message);
        setLoading(false); // 结束加载
      }
    } catch (error) {
      // 处理请求过程中的错误
      console.error('登录过程中发生错误:', error);
      setLoading(false); // 结束加载
    }



    setIsModalVisible(false);
  };
  const handleRegister = async (values) => {
    if(username === ''){
      alert("用户名不能为空");
      return;
    }
    if(password === ''){
      alert("密码不能为空");
      return;
    }
    if(invitationCode === ''){
      alert("邀请码不能为空");
    }

    setLoading(true); // 开始加载

    // 获取完整的语言设置字符串，如 "en-US"
    const fullLang = navigator.language || navigator.userLanguage; 

    // 通过'-'分割字符串，取第一个部分作为简写
    const shortLang = fullLang.split('-')[0]; 

    try {
      const response = await fetch(getHostName() + '/register', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          username: username,
          phone_number: "135",
          password: password,
          invite_code:invitationCode,
          lang:shortLang,
        }),
      });
      
      
      const data = await response.json();
      
  
      if (data.success) {
        
        // 将username、uid和access_token保存在浏览器本地
        localStorage.setItem('username', data.username);
        localStorage.setItem('uid', data.uid);
        localStorage.setItem('access_token', data.access_token);
        localStorage.setItem('grade', 5);
        localStorage.setItem('subject', "mathematics");
        localStorage.setItem('sversion', "人教版");
        localStorage.setItem('language', shortLang);
        localStorage.setItem('userlanguage', shortLang);


        // 可以在此处执行其他操作，例如关闭模态框或跳转到其他页面
        setIsRegister(false);
        setIsModalVisible(false);
        setUsername(data.username); // 更新用户名
        setIsLoggedInS(true);
        isLoggedIn.current = true;
        setLoading(false); // 结束加载
        navigate('/Default'); 
      } else {
        // 处理注册失败的情况
        console.log('注册失败:', data.message);
        setLoading(false); // 结束加载
      }
    } catch (error) {
      // 处理请求过程中的错误
      console.error('注册过程中发生错误:', error);
      setLoading(false); // 结束加载
    }
  };
  

  const logout = () => {
    
    // 清除本地存储的用户信息
    localStorage.removeItem('username');
    localStorage.removeItem('uid');
    localStorage.removeItem('access_token');
  
    // 如果你使用了某种状态管理（如 Redux 或 Context API），你可能还需要更新状态
    // 例如：dispatch(logoutAction());
  
    // 重定向到登录页面或首页
    window.location.href = '/login';
  };

  

 // 当组件挂载时，检查localStorage中的令牌
 useEffect(() => {
  const token = localStorage.getItem('access_token');
  if (token) {
    // 如果令牌存在，设置为已登录状态
    console.log("app找到了token");
    setUsername(localStorage.getItem('username'));
    console.log("：" + username);
    ///////////////////////////////////(true);
    //isLoggedIn.current = true;
  }
}, []); // 空依赖数组确保只在组件挂载时运行

  useEffect(() => {
    setIsModalVisible(true);
  }, []);
  
  const [isComposing, setIsComposing] = useState(false);


  const handleKeyDown = (e) => {
    if (isComposing) return;  // 如果正在输入中文，则直接返回

    if (e.key === 'Enter') {
      // 处理回车键逻辑
      handleLogin();
    }
  };
  const handleComposition = (e) => {
    if (e.type === 'compositionstart') {
      setIsComposing(true);
    } else if (e.type === 'compositionend') {
      setIsComposing(false);
    }
  };

  return (
    <div className="login-box">
      <h2>{showRegistration ? t('Register') : t('Login')}</h2>
      <form>
        <div className="user-box">
          <input 
            type="text" 
            name="username" 
            required 
            value={username}
            onChange={e => setUsername(e.target.value)}
          />
          <label>{t('Username')}</label>
        </div>
        <div className="user-box">
          <input 
            type="password" 
            name="password" 
            required 
            value={password}
            onChange={e => setPassword(e.target.value)}
            onKeyDown={handleKeyDown}
            onCompositionStart={handleComposition}
            onCompositionEnd={handleComposition}
          />
          <label>{t('Password')}</label>
        </div>
  
        {showRegistration && (
          <>
            <div className="user-box">
              <input 
                type="password" 
                name="confirmPassword" 
                required 
                value={confirmPassword}
                onChange={handleConfirmPasswordChange}
              />
              <label>{t('Confirm Password')}</label>
            </div>
            {passwordMismatch && <div style={{color:'#03e9f4'}}>{t('Passwords do not match')}</div>}
            <div className="user-box">
              <input 
                type="text" 
                name="invitationCode" 
                required 
                value={invitationCode}
                onChange={e => setInvitationCode(e.target.value)}
              />
              <label>{t('Invitation Code')}</label>
            </div>
          </>
        )}

  
        <a href="#" onClick={showRegistration ? handleRegister : handleLogin}>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          {showRegistration ? t('Register') : t('Login')}
        </a>
  
        <a href="#" onClick={() => setShowRegistration(!showRegistration)}>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          {showRegistration ? t('Go to Login') : t('Register')}
        </a>
      </form>
  
      {loading && (
        <LoadingData text={t(loadingText)}/>
      )}
    </div>
  );
};

export default Login;
