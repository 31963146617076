import React, { useEffect, useState } from 'react';
import { Box, Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, TextField ,IconButton,Radio } from '@mui/material';
import { requestUrl } from '../requestUrl';

import { useTranslation } from 'react-i18next';

import LoadingData from '../education/dataloading';

import { useSnackbar } from '../SnackbarProvider';
import { getHostName } from '../utils/urlUtils';
import DeleteIcon from '@mui/icons-material/Delete';

const UseKey = () => {
    const showSnackbar = useSnackbar();
    const [isLoading, setIsLoading] = useState(false);
    const [loadingText,setLoadingText] = useState(false);

    const [documents, setDocuments] = useState([]);

    const [selectedFile, setSelectedFile] = useState(null);
    const [fileName, setFileName] = useState('');

    const [author,setAuthor] = useState('');
    const [description,setDescription] = useState('');

    const [chunkSize,setChunkSize] = useState(1000);
    const [overlap,setOverlap] = useState(100);
    const [usekey,setUsekey] = useState();
    const [useUrl,setUseUrl] = useState('');

    const { t } = useTranslation();
    useEffect(() => {
        // Replace with your actual API call
        fetchDocuments();
    }, []);

    const fetchDocuments = async () => {
        try {

            setIsLoading(true);
            
            const dataToSend = {
                id:0,
            };
            const data = await requestUrl(dataToSend, "get_usekeys");
            console.log(JSON.stringify(data));
            if(data.status === "success"){
                setDocuments(data.data);
                console.log(data.data);
            }
            else{
              showSnackbar('warning', 'password wrong');
            }
          } 
          catch (error) {
              console.error("获取数据时出错:", error);
          }
          finally {
            setIsLoading(false);
          }
    };



    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            setSelectedFile(file);
            setFileName(file.name);
        }
    };



    const handleTxtChangeUrl = (event) => {
        setUseUrl(event.target.value);
    };

    const handleTxtkeyChange = (event) => {
        setUsekey(event.target.value);
    };
    
    const handleTxtChangeChunkSize = (event) => {
        setChunkSize(event.target.value);
    };

    const handleTxtChangeOverlap = (event) => {
        setOverlap(event.target.value);
    };


      
    const handleDeleteDoc = async (docid) => {
        try {

            setIsLoading(true);
            
            const dataToSend = {
                id:docid,
            };
            const data = await requestUrl(dataToSend, "delete_usekey");
            console.log(JSON.stringify(data));
            if(data.status === "success"){
                const updatedDocuments = documents.filter(document => document.id !== docid);
                setDocuments(updatedDocuments);
            }
            else{
              showSnackbar('warning', 'password wrong');
            }
          } 
          catch (error) {
              console.error("获取数据时出错:", error);
          }
          finally {
            setIsLoading(false);
          }
    };


    const handleAddkey = async () => {
        console.log("sss");
        try {

            setIsLoading(true);
            
            
            const dataToSend = {
                id:0,
                key:usekey,
                url:useUrl,
            };
            console.log(JSON.stringify(dataToSend));
            const data = await requestUrl(dataToSend, "add_usekey");
            
            if(data.status === "success"){
                
                const newDocument = {
                    id: data.id,
                    key: usekey,
                    url: useUrl,
                    usetoken: 0,
                    set_default: 0
                  };

                // 返回更新后的数组
                setDocuments(prevDocuments => [...prevDocuments, newDocument]);
            }
            else{
              showSnackbar('warning', 'password wrong');
            }
          } 
          catch (error) {
              console.error("获取数据时出错:", error);
          }
          finally {
            setIsLoading(false);
          }
    }
    const handleRadioChange = (event) => {
        // 更新逻辑
      };
    
      const handleSetDefault = async (id) => {
        try {

            setIsLoading(true);
            const document = documents.find(doc => doc.id === id);
            let key;
            let url;
            let api_platform;
            // 如果找到文档，返回其key和url
            if (document) {
                key = document.key;
                url = document.url;
                api_platform = document.api_platform;
            }
            const dataToSend = {
                id:id,
                key:key,
                url:url,
                api_platform:api_platform,
            };
            const data = await requestUrl(dataToSend, "set_default_key");
            
            if(data.status === "success"){
                
                // 更新documents数组
                const updatedDocuments = documents.map(doc => {
                    // 将所有set_default设置为0
                    //let newDoc = { ...doc, set_default: 0 };
                    let newDoc = { ...doc };
                    // 如果找到特定id，将其set_default设置为1
                    if (doc.id === id) {
                    newDoc.set_default = 1;
                    }

                    return newDoc;
                });

                // 返回更新后的数组
                setDocuments(updatedDocuments);
            }
            else{
              showSnackbar('warning', 'password wrong');
            }
          } 
          catch (error) {
              console.error("获取数据时出错:", error);
          }
          finally {
            setIsLoading(false);
          }
      };
      
      
    return (
        <>
        
       <Box sx={{width:'90%',height:'100px',backgroundColor:'white'}} 
       alignItems="center" display="flex" justifyContent="flex-start" m={1} style={{ margin: 'auto' }}>
                {/* 其他按钮 */}
               
               
                <TextField 
                    label="key"
                    value={usekey} 
                    placeholder="" 
                    style={{ marginRight: 20 }}
                    variant="outlined"
                    size="small"
                    multiline
                    rows={2}
                    onChange={handleTxtkeyChange}
                />

                <TextField
                    label="url"
                    variant="outlined"
                    size="small"
                    multiline
                    rows={2}
                    sx={{width:'300px'}}
                    name="title_local_lang"
                    value={useUrl}
                    onChange={handleTxtChangeUrl}
                />
               
                {/* ... 其他组件 */}
            </Box>


            <Box sx={{width:'90%',height:'100px',backgroundColor:'white'}} 
            alignItems="center" display="flex" justifyContent="flex-start" m={1} style={{ margin: 'auto' }}>
                {/* 其他按钮 */}
               

               
                <Button 
                    variant="contained" 
                    onClick={handleAddkey}
                   
                >
                    添加
                </Button>
                {/* ... 其他组件 */}
            </Box>


            <br></br>
        <TableContainer sx={{width:'90%'}} component={Paper} style={{ margin: 'auto' }}>
            <Table aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <TableCell>ID</TableCell>
                        <TableCell align="right">api_platform</TableCell>
                        <TableCell align="right">api_type</TableCell>
                        <TableCell align="center">key</TableCell>
                        <TableCell align="right">url</TableCell>
                        <TableCell align="right">usetoekn</TableCell>
                        <TableCell align="right">api_version</TableCell>
                        <TableCell align="right">setuse</TableCell>
                        <TableCell align="right">delete</TableCell>
                        <TableCell align="right">setdefault</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {documents.map((doc) => (
                        <TableRow key={doc.id}>
                            <TableCell component="th" scope="row">
                                {doc.id}
                            </TableCell>
                            <TableCell align="right">{doc.api_platform}</TableCell>
                            <TableCell align="right">{doc.api_type}</TableCell>
                            <TableCell align="right">{doc.key}</TableCell>
                            <TableCell align="right">{doc.url}</TableCell>
                            <TableCell align="right">{doc.api_version}</TableCell>
                            <TableCell align="right">{doc.usetoken}</TableCell>
                            <TableCell align="right">
                            <Radio
                                checked={doc.set_default}
                                onChange={handleRadioChange}
                                value={doc.id}
                                name="radio-button-demo"
                            
                                />
                            </TableCell>
                            <TableCell align="center">
                            <IconButton aria-label="delete" color="primary" onClick={() => handleDeleteDoc(doc.id)} align="center">
                                <DeleteIcon />
                            </IconButton>
                            </TableCell>
                            <TableCell align="center">
                            <Button color="primary" onClick={() => handleSetDefault(doc.id)}>set default
                               </Button>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
            {isLoading && (
                <LoadingData text={t(loadingText)}/>
            )}
        </TableContainer>
        </>
    );
};

export default UseKey;
