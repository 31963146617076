import React, { useState } from 'react';
import PromptManage from './PromptManage';
import ModelChange from './ModelChange';
import PicturebookManage from './picturebook/PicturebookManage';
import UserManage from './User/UserManage';
import SystemHelpManage from './SystemHelpManage';
import SystemConfig from './SystemConfig';
import UserConsumeManage from './UserConsume/UserConsumeManage';
import FamilyHeadDocument from './FamilyHeadDoc/FamilyHeadDocument';
import PriceRuleManage from './PriceRuleManage';
import ChatQueryConfig from './ChatQueryConfig';
import UseKey from './UseKey';
import GeoManage from './GeoManage';
import BgHtmlManage from './BgHtmlManage';
import WenXinYYSettingComponent from './WenXinYYSetting';


const AdminManage = () => {

      // Inline styles
      const styles = {
        container: {
            display: 'flex',
            flexDirection: 'row',
            width: '100vw',  // 100% of viewport width
            height: '100vh', // 100% of viewport height
            overflow: 'hidden' // Prevents scrollbars if content overflows
        },
        menu: {
            width: '300px',
            height: '100%',   // Full height
            borderRight: '1px solid #ddd',
            padding: '10px',
            overflowY: 'auto' // Allows scrolling for long menus
        },
        content: {
            flex: 1,
            height: '100%',  // Full height
            padding: '10px',
            overflowY: 'auto' // Allows scrolling for long content
        }
    };

    const [isVisiblePromptManage,setIsVisiblePromptManage] = useState(true);
    const[isVisibleModelChange,setIsVisibleModelChange] = useState(false);
    
    const [isVisiblePicturebookManage,setIsVisiblePicturebookManage] = useState(true);
    const [isShowUserManage,setIsShowUserManage] = useState(false);
    const [isShowSystemHelp,setIsShowSystemHelp] = useState(false);
    // State to manage the active menu item
    const [activeMenu, setActiveMenu] = useState('');
    const [isShowSystemConfig,setIsShowSystemConfig] = useState(false);

    const [isShowUserConsumeManage,setIsShowUserConsumeManage] = useState(false);
    const [isShowFamilyHeadDocument,setIsShowFamilyHeadDocument] = useState(false);

    const [isShowPriceRuleManage,setIsShowPriceRuleManage] = useState(false);

    const [isShowChatQueryConfig,setIsShowChatQueryConfig] = useState(false);
    const [isShowUseKey,setIsShowUseKey] = useState(false);

    const [isShowGeoManage,setIsShowGeoManage] = useState(false);

    const [isShowBgHtmlM,setIsShowBgHtmlM] = useState(false);

    const [isShowWXYYSetting,setIsShowWXYYSetting] = useState(false);
    const handleMenuClick = (menuItem) => {
        
        setIsVisiblePromptManage(false);
        setIsVisiblePicturebookManage(false);
        setIsShowUserManage(true);
        setIsShowSystemHelp(false);
        setIsShowSystemConfig(false);
        setIsShowUserConsumeManage(false);
        setIsShowFamilyHeadDocument(false);
        setIsShowPriceRuleManage(false);
        setIsShowChatQueryConfig(false);
        setIsShowUseKey(false);
        setIsShowGeoManage(false);
        setIsShowBgHtmlM(false);
        setIsShowWXYYSetting(false);
    };



    // Function to handle menu item click
    const handlePromptM = (menuItem) => {
        setIsVisibleModelChange(false);
        setIsVisiblePromptManage(true);
        setIsVisiblePicturebookManage(false);
        setIsShowUserManage(false);
        setIsShowSystemHelp(false);
        setIsShowSystemConfig(false);
        setIsShowUserConsumeManage(false);
        setIsShowFamilyHeadDocument(false);
        setIsShowChatQueryConfig(false);
        setIsShowUseKey(false);
        setIsShowGeoManage(false);
        setIsShowBgHtmlM(false);
        setIsShowWXYYSetting(false);
    };

    const handlePicturebook = (menuItem) => {
        setIsVisiblePromptManage(false);
        setIsVisiblePicturebookManage(true);
        setIsShowUserManage(false);
        setIsShowSystemHelp(false);
        setIsShowSystemConfig(false);
        setIsShowUserConsumeManage(false);
        setIsShowFamilyHeadDocument(false);
        setIsShowPriceRuleManage(false);
        setIsShowChatQueryConfig(false);
        setIsShowUseKey(false);
        setIsShowGeoManage(false);
        setIsShowBgHtmlM(false);
        setIsShowWXYYSetting(false);
    };

    const handleSystemHelp = (menuItem) => {
        setIsVisiblePromptManage(false);
        setIsVisiblePicturebookManage(false);
        setIsShowUserManage(false);
        setIsShowSystemHelp(true);
        setIsShowSystemConfig(false);
        setIsShowUserConsumeManage(false);
        setIsShowFamilyHeadDocument(false);
        setIsShowPriceRuleManage(false);
        setIsShowChatQueryConfig(false);
        setIsShowUseKey(false);
        setIsShowGeoManage(false);
        setIsShowBgHtmlM(false);
        setIsShowWXYYSetting(false);
    };
    

    const handleSystemConfig = (menuItem) => {
        setIsVisiblePromptManage(false);
        setIsVisiblePicturebookManage(false);
        setIsShowUserManage(false);
        setIsShowSystemHelp(false);
        setIsShowSystemConfig(true);
        setIsShowUserConsumeManage(false);
        setIsShowFamilyHeadDocument(false);
        setIsShowPriceRuleManage(false);
        setIsShowChatQueryConfig(false);
        setIsShowUseKey(false);
        setIsShowGeoManage(false);
        setIsShowBgHtmlM(false);
        setIsShowWXYYSetting(false);
    };

    const handleUserConsume = (menuItem) => {
        setIsVisiblePromptManage(false);
        setIsVisiblePicturebookManage(false);
        setIsShowUserManage(false);
        setIsShowSystemHelp(false);
        setIsShowSystemConfig(false);
        setIsShowUserConsumeManage(true);
        setIsShowFamilyHeadDocument(false);
        setIsShowPriceRuleManage(false);
        setIsShowChatQueryConfig(false);
        setIsShowUseKey(false);
        setIsShowGeoManage(false);
        setIsShowBgHtmlM(false);
        setIsShowWXYYSetting(false);
    };

    const handleFamilyHeadDoc = (menuItem) => {
        setIsVisiblePromptManage(false);
        setIsVisiblePicturebookManage(false);
        setIsShowUserManage(false);
        setIsShowSystemHelp(false);
        setIsShowSystemConfig(false);
        setIsShowUserConsumeManage(false);
        setIsShowFamilyHeadDocument(true);
        setIsShowPriceRuleManage(false);
        setIsShowChatQueryConfig(false);
        setIsShowUseKey(false);
        setIsShowGeoManage(false);
        setIsShowBgHtmlM(false);
        setIsShowWXYYSetting(false);
    };

    const handlePriceRule = (menuItem) => {
        console.log("wwww");
        setIsShowPriceRuleManage(true);
        setIsVisiblePromptManage(false);
        setIsVisiblePicturebookManage(false);
        setIsShowUserManage(false);
        setIsShowSystemHelp(false);
        setIsShowSystemConfig(false);
        setIsShowUserConsumeManage(false);
        setIsShowFamilyHeadDocument(false);
        setIsShowChatQueryConfig(false);
        setIsShowUseKey(false);
        setIsShowGeoManage(false);
        setIsShowBgHtmlM(false);
        setIsShowWXYYSetting(false);
       
    };
    
    const handleChatQueryConfig = (menuItem) => {
        console.log("wwww");
        setIsShowPriceRuleManage(false);
        setIsVisiblePromptManage(false);
        setIsVisiblePicturebookManage(false);
        setIsShowUserManage(false);
        setIsShowSystemHelp(false);
        setIsShowSystemConfig(false);
        setIsShowUserConsumeManage(false);
        setIsShowFamilyHeadDocument(false);
        setIsShowChatQueryConfig(true);
        setIsShowUseKey(false);
        setIsShowGeoManage(false);
        setIsShowBgHtmlM(false);
        setIsShowWXYYSetting(false);
    };

    const handleUseKey = (menuItem) => {
        
        setIsShowPriceRuleManage(false);
        setIsVisiblePromptManage(false);
        setIsVisiblePicturebookManage(false);
        setIsShowUserManage(false);
        setIsShowSystemHelp(false);
        setIsShowSystemConfig(false);
        setIsShowUserConsumeManage(false);
        setIsShowFamilyHeadDocument(false);
        setIsShowChatQueryConfig(false);
        setIsShowUseKey(true);
        setIsShowGeoManage(false);
        setIsShowBgHtmlM(false);
        setIsShowWXYYSetting(false);
    };
    

    const handleGeoManage = (menuItem) => {
        
        setIsShowPriceRuleManage(false);
        setIsVisiblePromptManage(false);
        setIsVisiblePicturebookManage(false);
        setIsShowUserManage(false);
        setIsShowSystemHelp(false);
        setIsShowSystemConfig(false);
        setIsShowUserConsumeManage(false);
        setIsShowFamilyHeadDocument(false);
        setIsShowChatQueryConfig(false);
        setIsShowUseKey(false);
        setIsShowGeoManage(true);
        setIsShowBgHtmlM(false);
        setIsShowWXYYSetting(false);
    };
    
    const handleBgHtmlManage = (menuItem) => {
        setIsShowPriceRuleManage(false);
        setIsVisiblePromptManage(false);
        setIsVisiblePicturebookManage(false);
        setIsShowUserManage(false);
        setIsShowSystemHelp(false);
        setIsShowSystemConfig(false);
        setIsShowUserConsumeManage(false);
        setIsShowFamilyHeadDocument(false);
        setIsShowChatQueryConfig(false);
        setIsShowUseKey(false);
        setIsShowGeoManage(false);
        setIsShowBgHtmlM(true);
        setIsShowWXYYSetting(false);
    };
    

    const handleWWYYSettingManage = (menuItem) => {
        setIsShowPriceRuleManage(false);
        setIsVisiblePromptManage(false);
        setIsVisiblePicturebookManage(false);
        setIsShowUserManage(false);
        setIsShowSystemHelp(false);
        setIsShowSystemConfig(false);
        setIsShowUserConsumeManage(false);
        setIsShowFamilyHeadDocument(false);
        setIsShowChatQueryConfig(false);
        setIsShowUseKey(false);
        setIsShowGeoManage(false);
        setIsShowBgHtmlM(false);
        setIsShowWXYYSetting(true);
    };

    return (
        <div style={styles.container}>
            <div style={styles.menu}>
                <ul>
                    <li onClick={() => handlePromptM('提示词管理')}>提示词管理</li>
                    <br></br>
                    <li onClick={() => handleMenuClick('用户管理')}>用户管理</li>
                    <br></br>
                    <li onClick={() => handlePicturebook('')}>绘本管理</li>
                    <br></br>
                    <li onClick={() => handleSystemHelp('')}>帮助管理</li>
                    <br></br>
                    <li onClick={() => handleSystemConfig('')}>系统配置</li>
                    <br></br>
                    <li onClick={() => handleUserConsume('')}>用户消耗</li>
                    <br></br>
                    <li onClick={() => handleFamilyHeadDoc('')}>家长接口文档嵌入</li>
                    <br></br>
                    <li onClick={() => handlePriceRule('')}>价格规则设置</li>
                    <br></br>
                    <li onClick={() => handleChatQueryConfig('')}>对话上下文设置</li>
                    <br></br>
                    <li onClick={() => handleUseKey('')}>useKey</li>
                    <br></br>
                    <li onClick={() => handleGeoManage('')}>GeoManage</li>
                    <br></br>
                    <li onClick={() => handleBgHtmlManage('')}>bgHtml</li>

                    <br></br>
                    <li onClick={() => handleWWYYSettingManage('')}>文心一言设置</li>
                    
                    {/* 更多菜单项 */}
                </ul>
            </div>
            <div style={styles.content}>
            {isVisiblePromptManage && (
                    <PromptManage/>
                )}

            {isVisibleModelChange && (
                <ModelChange/>
            )}
            {isVisiblePicturebookManage && (
                <PicturebookManage/>
            )}
            {isShowUserManage && (
                <UserManage/>
            )}

            {isShowSystemHelp && (
                <SystemHelpManage/>
            )}
            {isShowSystemConfig && (
                <SystemConfig/>
            )}
            {isShowUserConsumeManage && (
                <UserConsumeManage/>
            )}
            {isShowFamilyHeadDocument && (
                <FamilyHeadDocument/>
            )}
            {isShowPriceRuleManage && (
                <PriceRuleManage/>
            )}
            {isShowChatQueryConfig && (
                <ChatQueryConfig/>
            )}
            {isShowUseKey && (
                <UseKey/>
            )}
             {isShowGeoManage && (
                <GeoManage/>
            )}
            {isShowBgHtmlM && (
                <BgHtmlManage/>
            )}
            {isShowWXYYSetting && (
                <WenXinYYSettingComponent/>
            )}



            </div>
        </div>
    );
};

export default AdminManage;
