import React, { useState,useEffect } from 'react';
import { Box, Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Paper } from '@mui/material';
import { MenuItem, FormControl, InputLabel, Select } from '@mui/material';

import { requestUrl } from '../requestUrl';
import LoadingData from '../education/dataloading';
import { useTranslation } from 'react-i18next';

import { List, ListItem, Container,InputAdornment,Typography } from '@mui/material';
import ExpandMore from '@mui/icons-material/ExpandMore';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';
import ExpandLess from '@mui/icons-material/ExpandLess';
import CommonComboboxOne from '../components/CommonComboboxOne';
import { json } from 'react-router-dom';
import CustomButton from '../components/CustomButton';
const ChatQueryConfig = () => {

    const styles = {
        container: {
            display: 'flex',
            flexDirection: 'row',
            width: '100vw',  // 100% of viewport width
            height: '100vh', // 100% of viewport height
            overflow: 'hidden' // Prevents scrollbars if content overflows
        },
        menu: {
            width: '300px',
            height: '100%',   // Full height
            borderRight: '1px solid #ddd',
            padding: '10px',
            overflowY: 'auto' // Allows scrolling for long menus
        },
        content: {
            flex: 1,
            height: '100%',  // Full height
            padding: '10px',
            overflowY: 'auto' // Allows scrolling for long content
        }
    };


    const [loadingText,setLoadingText] = useState('loading');
    const { t } = useTranslation();
    const [isLoading, setIsLoading] = useState(false);
    const [open, setOpen] = useState(false);


    const handleSubjectClick = async (subject) => {
        loadData("subject",subject);
        setModuleName('subject');
    };

    const handleModuleClick = async (item) => {
        loadData(item,"");
        setModuleName(item);
    };


    const loadData = async (module_name,subject) => {
        try {
            setIsLoading(true);
            setLoadingText("loading");
            const dataToSend = {
                module_name:module_name,
                subject:subject,
            };
            const data = await requestUrl(dataToSend, "get_chat_query_config");
            
            if(data.status === "success"){
                setChatQueryConfig(data.data);
                
                console.log("Query Type:", chatQueryConfig.query_type); // 应该显示 "all", "session", 或 "both"

            }
          } catch (error) {
              console.error("获取数据时出错:", error);
          } finally {
            setIsLoading(false);
          }
    }
    const handleClick = () => {
        setOpen(!open);
    };

    const [chatQueryConfig, setChatQueryConfig] = useState({});
    

    const menuItems = [];
        for (let i = 1; i <= 10; i++) {
            menuItems.push(
                <MenuItem key={i} value={i}>{i}</MenuItem>
            );
    }


    const handleDropdownChange = (newSubject, selectedValue) => {
        console.log("sssssss");
        setChatQueryConfig(prevConfig => ({
            ...prevConfig,
            subject: newSubject,
            query_type: selectedValue,
          })); 
    };

    const handleDropdownChangeAC = (newSubject, selectedValue) => {
        console.log("sssssss");
        setChatQueryConfig(prevConfig => ({
            ...prevConfig,
            subject: newSubject,
            all_doc_count: selectedValue,
          })); 
    };

    const handleDropdownChangeSC = (newSubject, selectedValue) => {
        console.log("sssssss");
        setChatQueryConfig(prevConfig => ({
            ...prevConfig,
            subject: newSubject,
            session_doc_count: selectedValue,
          })); 
    };

    const dropdownModels = ['all','session','both'];
    
    const dropdownNumbers = ['1','2','3','4','5'];
    const [moduleName,setModuleName] = useState('subject');

    const handleSave = async () => {
        try {
            setIsLoading(true);
            setLoadingText("loading");
            const dataToSend = {
                module_name:moduleName,
                subject:chatQueryConfig.subject,
                query_type:chatQueryConfig.query_type,
                all_doc_count:chatQueryConfig.all_doc_count,
                session_doc_count:chatQueryConfig.session_doc_count,
                all_doc_min_score:chatQueryConfig.all_doc_min_score,
                session_doc_min_score:chatQueryConfig.session_doc_min_score,
            };

            console.log("aaa:" + JSON.stringify(dataToSend));
            const data = await requestUrl(dataToSend, "update_chat_query_config");
            
            if(data.status === "success"){
               

            }
          } catch (error) {
              console.error("获取数据时出错:", error);
          } finally {
            setIsLoading(false);
          }
    }


    return (
        <div style={styles.container}>
            
            <div style={styles.menu}>
            <List>
            <ListItemButton onClick={handleClick}>
                <ListItemText primary="学科" />
                {open ? <ExpandLess sx={{ color: 'white' }} /> : <ExpandMore sx={{ color: 'white' }} />}
            </ListItemButton>
            <Collapse in={open} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                    {['chinese', 'mathematics', 'english', 'physics', 'chemistry', 'geography', 'biology','sciencecjiajia','science'].map(subject => (
                        <ListItemButton key={subject} sx={{ pl: 4 }} onClick={() => handleSubjectClick(subject)}>
                            <ListItemText primary={subject} />
                        </ListItemButton>
                    ))}
                </List>
            </Collapse>
            {['familyhead', 'doc_conversation'].map(item => (
                <ListItemButton key={item} onClick={() => handleModuleClick(item)}>
                    <ListItemText primary={item} />
                </ListItemButton>
            ))}
        </List>
            </div>
        

                <div style={{justifyContent:'flex-start',color:'black', alignItems:'flex-start' ,textAlign:'left',width:'100%',backgroundColor:'white'}}>
                current selected:{chatQueryConfig.module_name}:{chatQueryConfig.subject}
                <Box sx={{width:'70%', display: 'flex', justifyContent: 'flex-end', marginBottom: 2, marginTop: '20px' }}>
                    
                <Container sx={{ marginTop: 4 }}>
                    <Paper elevation={3} sx={{ padding: 2 }}>
                    <CommonComboboxOne
                        preferredValue={chatQueryConfig.query_type}
                        setPreferredValue={(value) => handleDropdownChange(chatQueryConfig.subject, value)}
                        options={dropdownModels}
                        placeholder={t('model')}
                        labelText={t('model')}
                    />
                    
                        
                    <CommonComboboxOne
                        preferredValue={chatQueryConfig.all_doc_count}
                        setPreferredValue={(value) => handleDropdownChangeAC(chatQueryConfig.subject, value)}
                        options={dropdownNumbers}
                        placeholder={t('all_doc_count')}
                        labelText={t('all_doc_count')}
                    />
                    <CommonComboboxOne
                        preferredValue={chatQueryConfig.session_doc_count}
                        setPreferredValue={(value) => handleDropdownChangeSC(chatQueryConfig.subject, value)}
                        options={dropdownNumbers}
                        placeholder={t('session_doc_count')}
                        labelText={t('session_doc_count')}
                    />
                
                        <TextField 
                        label="All Document Minimum Score" 
                        variant="outlined" 
                        type="number"
                        
                        value={chatQueryConfig.all_doc_min_score}
                        onChange={(e) => setChatQueryConfig({ ...chatQueryConfig, all_doc_min_score: parseFloat(e.target.value) })}
                        fullWidth 
                        margin="normal"
                        />
                        <TextField 
                        label="Session Document Minimum Score" 
                        variant="outlined" 
                        type="number"
                        value={chatQueryConfig.session_doc_min_score}
                        onChange={(e) => setChatQueryConfig({ ...chatQueryConfig, session_doc_min_score: parseFloat(e.target.value) })}
                        fullWidth 
                        margin="normal"
                        />
                    </Paper>
                    </Container>
                    </Box>
                    <Container sx={{height:'100%'}} maxWidth="md"> {/* 使用 Container 限制最大宽度 */}

                   <CustomButton onClick={handleSave}>sss</CustomButton>
                    </Container>
            </div>
            
            {isLoading && (
                <LoadingData text={t(loadingText)}/>
            )}
        </div>
    );
};

export default ChatQueryConfig;
