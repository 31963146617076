import React, { useState, useEffect, useRef } from 'react';
import ChatWindow from '../ChatWindow/ChatWindow';
import { useSnackbar } from '../../SnackbarProvider';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, MenuItem, IconButton,Popper,Menu } from '@mui/material';
import { Menu as MuiMenu } from '@mui/material';
import styled from 'styled-components';
import './MainStudy.css';
import AudioRecorder from '../AudioRecorder/AudioRecorder';
import { useTranslation } from 'react-i18next';
import ReactMarkdown from 'react-markdown';
import { deviceType_m_t_d } from '../../utils/commonUtils';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Pagination,Modal,Checkbox } from '@mui/material';
import PhotoComponent from '../LatexModalComponent/PhotoComponent';
import SystemHelp from '../SystemHelp/SystemHelp';
import 'react-quill/dist/quill.snow.css';  // 对于 snow 主题
import 'react-quill/dist/quill.bubble.css'; // 对于 bubble 主题
import DeleteIcon from '@mui/icons-material/Delete';
import { requestUrl } from '../../requestUrl';
import { useNavigate } from 'react-router-dom';
import { get_gradelist_by_subject } from '../../utils/commonUtils';
import DialogContentText from '@mui/material/DialogContentText';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Draggable from 'react-draggable';
import { Refresh } from '@mui/icons-material';
import PhotoCamera from '@mui/icons-material/PhotoCamera';
import Grid from '@mui/material/Grid';
import PrintIcon from '@mui/icons-material/Print';
import AddCardIcon from '@mui/icons-material/AddCard';
import Backdrop from '@mui/material/Backdrop';
import EditIcon from '@mui/icons-material/Edit'; // 引入编辑图标
import SaveIcon from '@mui/icons-material/Save'; // 引入保存图标
import WebAssetIcon from '@mui/icons-material/WebAsset';
import QuizIcon from '@mui/icons-material/Quiz';
import FileCopyIcon from '@mui/icons-material/FileCopyOutlined';
import 'katex/dist/katex.min.css';
import katex from 'katex';
import CategoryIcon from '@mui/icons-material/Category';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import ChatCommonSessionClassComponent from '../ExamReview/ChatCommonSessionClassComponent';
import PkBuddleMap from '../PkBuddleMap/PkBuddleMap';
import PointKnowledgeMapComponent from './PointKnowledgeMapComponent';
import SettingsIcon from '@mui/icons-material/Settings';
import AnalyticsIcon from '@mui/icons-material/Analytics';
import LoadingData from '../dataloading';
import CustomButton from '../../components/CustomButton';
import LatexModule from '../Subject/LatexModule';
import HandOcr from '../Subject/HandOcr';
import { getColorDark,getColorLight } from '../../ColorManager';

export const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 6666;
`;

export const ModalContainer = styled.div`
  padding: 10px;
  width: 100%;
  max-width: 1200px;
  height: 100%;
  max-height: 800px;
  overflow-y: auto;
  border-radius: 20px;
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.1);
`;

const Container = styled.div`
  width: 100%;
  height: 80vh;
  @media (min-width: 768px) {  /* 针对平板和电脑屏幕 */
    width: 98%;
  }
`;


const ScienceC = () => {
  const { t } = useTranslation();
  const [selectedGradeOption, setSelectedGradeOption] = useState('7');
  const [selectedSubjectOptionText, setSelectedSubjectOptionText] = useState(null);

  const [isLoading, setIsLoading] = useState(false);
  const [messages, setMessages] = useState([]);
  const [inputText, setInputText] = useState(''); // 文本框的值
  const [selectedVersion, setSelectedVersion] = useState('人教版');
  const [isKeepHistory, setIsKeepHistory] = useState(false);
  const [uploadedImage, setUploadedImage] = useState(null); //从inputarea中传过来的上传图片
  const [cuspredefinedContent, setCuspredefinedContent] = useState([]);
  const [open, setOpen] = useState(false);
  const [menuAnchorElement, setMenuAnchorElement] = useState(null);
  const [selectedType, setSelectedType] = useState(t('Common Q'));
  const [selectedTypeId, setSelectedTypeId] = useState('1');
  const showSnackbar = useSnackbar();
  const [pageList, setPageList] = useState([1]);
  const [isShowAudioRecorder, setIsShowAudioRecorder] = useState(false);
  const [translateText, setTranslateText] = useState('');
  const [anchorEl, setAnchorEl] = useState(null)
  const [isOpenHistory, setIsOpenHistory] = useState(false);
  const [isDisplayBlankContent, setIsDisplayBlankContent] = useState(true);
  const [sessionChatId, setSessionChatId] = useState(0);
  const [loadingText,setLoadingText] = useState('loading');
  const [currentPage, setCurrentPage] = useState(0);
  const [selectedRowId, setSelectedRowId] = useState(null);
  const [tableData, setTableData] = useState([]);
  const [selectedRowData, setSelectedRowData] = useState(null);
  const [isNeedCreateSeesionChat, setIsNeedCreateSeesionChat] = useState(true);
  const [isShowTextOCRDiv, setIsShowTextOCRDiv] = useState(false);//显示语文英语的图片识别，主要用于作文点评
  const [isShowSystemHelp, setIsShowSystemHelp] = useState(false);
  const [totalPagesSession, setTotalPagesSession] = useState(1);
  const [showBuddleMap,setshowBuddleMap] = useState(false);

  const [isLatexModalVisible, setLatexModalVisible] = useState(false);


  const [questionTypeList, setQuestionTypeList] = useState([
    { id: 1, value: '通用问题' },
    { id: 2, value: '古诗文' },
    { id: 3, value: '作文点评' },
    { id: 3, value: '作文点评' },
    { id: 3, value: '作文点评' },
    { id: 3, value: '作文点评' },
    { id: 3, value: '作文点评' },
  ]);


  const flagMessFromItem = useRef(false);

let subjectOptions = [
  { id: 'science', text: t('popularization of science') },
  { id: 'c++', text: t('c++_csp_noip_noi') },
  // ... Add more options
];


  const [openEnsureSend, setOpenEnsureSend] = useState(false);
  const [pointKnowledgeArray,setPointKnowledgeArray] = useState([]);
  const [pointKnowledgeContent,setPointKnowledgeContent] = useState('');
  const [showPointKnowledgeMap,setShowPointKnowledgeMap] = useState(false);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const year = date.getFullYear().toString().slice(-2); // 获取年份的后两位
    const month = String(date.getMonth() + 1).padStart(2, '0'); // 月份从0开始，所以要+1
    const day = String(date.getDate()).padStart(2, '0');
    const hour = String(date.getHours()).padStart(2, '0');

    return `${year}-${month}-${day}:${hour}`;
  };




  const [colorManager, setColorManager] = useState(getColorDark()); // 初始化为空数组

      //获取颜色设置，系统加载时设置
  useEffect(() => {

    const themeName = localStorage.getItem('theme');//此处存储的是数字1-12，而不是汉字，
    if (themeName) {
      
      if(themeName === "dark"){
        setColorManager(getColorDark());
      }

      
      if(themeName === "light"){
        setColorManager(getColorLight());
      }
    }
    else{
      let aa = getColorDark();
      setColorManager(aa);
    }

    
  }, []);



  const get_color_by_name = (name) => {

    const item = colorManager.find(item => item.container === name);
    return item.value;
  }



  useEffect(() => {
    const defaultSubject = localStorage.getItem('science');
    if (defaultSubject) {
      setSelectedSubjectOptionText(defaultSubject);

    }
    if (defaultSubject) {
      load_prompts_questionTypes(defaultSubject, "1");
    }
    else
    {
      localStorage.setItem('science', "science");
      setSelectedSubjectOptionText("science");
      load_prompts_questionTypes("science", "1");
    }

  }, []);



  const loadSubjectData = async (page) => {
    if (!selectedSubjectOptionText || !selectedGradeOption) {
      
      showSnackbar(t('please select your grade level first'),'warning');

      return;
    }
    setAnchorEl(null);
    try {
      setIsLoading(true);
      const dataToSend = {
        subject: selectedSubjectOptionText,
        grade_value: selectedGradeOption,
        common_chat_session_id: sessionChatId,
        page_size: 10,
        page_number: page,
      };
      set_problem_type(selectedRowData.qtype);
      const data = await requestUrl(dataToSend, "get_subject_history",t,showSnackbar);
      setIsDisplayBlankContent(false);//默认空白页的图片不显示
      setMessages(data.data);
      console.log("data::::::" + JSON.stringify(data.data));
      setCuspredefinedContent(data.prompts);
      setQuestionTypeList(data.qtypes);


      setIsKeepHistory(true);
      if (page === 1) {
        setPageList(data.total_page);
      }
      setIsNeedCreateSeesionChat(false);//无需创建sessionchat表数据
      setIsLoading(false);


    }
    catch (error) {
      setIsLoading(false);
      console.error("There was an error fetching the reply:", error);
    }
  }

  //加载推荐问题列表和问题类型列表
  const load_prompts_questionTypes = async (subject, grade) => {
    handleClearRecord();
    //await new Promise(resolve => setTimeout(resolve, 100));
    try {
      setIsLoading(true);
      const dataToSend = {
        subject: subject,
        grade_value: grade,
      };
      const data = await requestUrl(dataToSend, "get_subject_prompts", t,showSnackbar);

      setCuspredefinedContent(data.prompts);
      setQuestionTypeList(data.qtypes);
      
      setIsLoading(false);
      setTableData([]);

      setSessionChatId(0);
      setSelectedRowId(0);


    }
    catch (error) {
      setIsLoading(false);
      console.error("There was an error fetching the reply:", error);
    }
  };


  const set_problem_type = async (problemTypeId) => {
    setSelectedTypeId(problemTypeId); //问题类型设置为默认
  }

  const [messageLatest, setMessageLatest] = useState('');
  const [qytpeLatest, setQytpeLatest] = useState(0);

  // 用户决定的回调
  const handleUserDecision = (decision) => {
    setOpenEnsureSend(false); // 无论用户决定什么，都关闭对话框
    if (decision) {
      handleSendMessageActual(messageLatest, qytpeLatest); // 继续进行你的操作
    } else {
      // 用户取消了操作
      setTimeout(() => {
        inputAreaRef.current.setInputValue(messageLatest);
      }, 500);
      console.log('User cancelled the action.');
    }
  };


  const handleSendMessage = async (message, executeType) => {
    if (message === "") {      
      showSnackbar('warning', t('please input content'));
      return;
    }

    if (selectedSubjectOptionText === null || selectedGradeOption === null) {      
      showSnackbar(t('you must select subject and grade'),'warning');
      setTimeout(() => {
        inputAreaRef.current.setInputValue(message);
      }, 1000);

      return;
    }

    //判断本轮对话是否第一次，如果是第一次弹出确认对话框，确认科目，年级，问题，以及问题类型，
    //如果不是第一次，则继续，无需确认


    if (sessionChatId === 0) {
      setMessageLatest(message);
      setQytpeLatest(executeType);
      setOpenEnsureSend(true);
    }
    else {
      handleSendMessageActual(message, executeType);
    }
  };




  const handleSendMessageActual = async (message, executeType) => {
    if (message === "") {
      
      showSnackbar(t('please input content'),'warning');
      return;
    }

    if (selectedSubjectOptionText === null || selectedGradeOption === null) {
      
      showSnackbar(t('you must select subject and grade'),'warning');
      setTimeout(() => {
        inputAreaRef.current.setInputValue(message);
      }, 1000);

      return;
    }

    //判断本轮对话是否第一次，如果是第一次弹出确认对话框，确认科目，年级，问题，以及问题类型，
    //如果不是第一次，则继续，无需确认
    setIsDisplayBlankContent(false);
    let dddd;
    if (uploadedImage) {
      const dddd = `<img src=${uploadedImage} style="height: 50vh; max-width: 100vw;" alt="upload Image"/>`;
      message = message + dddd;
      // 有图片
    } else {
      // 没有图片
    }

    // 处理发送消息逻辑
    setMessages(prevMessages => [...prevMessages, { sender: 'S', text: message, time: new Date().toLocaleTimeString() }]);
    try {
      setIsLoading(true);

      let dataimage = null;
      if (uploadedImage === null) {
        dataimage = 'none';
      }
      else {
        dataimage = uploadedImage;
      }
      //qtype
      let messsend = message;

      if (flagMessFromItem.current == true) {
        if (executeType === "deep") {
          messsend = messsend + "<questionflag>chatinner</questionflag>";
        }
        if (executeType === "deepgen") {
          messsend = messsend + "<deepgen>chatinner</deepgen>";
        }
        flagMessFromItem.current = false;
      }

      let typeid = Number(selectedTypeId);
      const itemq = questionTypeList.find(item => item.id === typeid);
      const dataToSend = {
        versionValue: selectedVersion,
        subjectValue: selectedSubjectOptionText,
        gradeValue: "1",
        inputValue: messsend,
        qtype: 1,
        qtype_name: "",
        isKeepHistory: isKeepHistory,
        image: dataimage,
        common_chat_session_id: sessionChatId,
        isNeedCreateSeesionChat: isNeedCreateSeesionChat,
      };
      console.log("datasend:" + JSON.stringify(dataToSend));
      const dat_server = await requestUrl(dataToSend, "question_science", t,showSnackbar);
      setUploadedImage(null);
      setIsKeepHistory(true);//保留记忆

      const { dbid, dbsessionid,response_text,usewf,question,qARecord,phetRecord,geoRecord,videoRecord,pointKnowledgeArray,pointKnowledgeContent,analyse,imageArray} = dat_server;
      //if(sessionChatId === 0){ //刚加载为0 ，新建为0，对话后，这个返回值肯定大于0，只要开始对话，这个值就会大于0，因为数据库新增了记录或者从客户端直接把大于0 的值带了过去，然后同样回传
      setSessionChatId(dbsessionid);
      //}
      //      setMessages(prevMessages => [...prevMessages, {id:dbid, sender: 'R', text: response_text, time: new Date().toLocaleTimeString(),question:question }]);
      setMessages(prevMessages => [...prevMessages, 
        {
          id:dbid,
          sender: 'R', 
          text: response_text, 
          time: new Date().toLocaleTimeString(),
          question:question,
          qARecord:qARecord,
          phetRecord:phetRecord,
          geoRecord:geoRecord,
          videoRecord:videoRecord,
          pointKnowledgeArray:pointKnowledgeArray,
          pointKnowledgeContent:pointKnowledgeContent,
          analyse:analyse,
          imageArray:imageArray}]);
      //uploadedImage = null;
      setUploadedImage(null);
      setIsLoading(false);
      setUseWf(usewf);
      setIsNeedCreateSeesionChat(false);

    }
    catch (error) {
      setIsLoading(false);

      console.error("There was an error fetching the reply:", error);
    }
  };

  //解题弹窗确认回调事件
  const handleDocumentUpdate = (updatedContent) => {
    setLatexModalVisible(false);
    if (updatedContent != "") {
      inputAreaRef.current.setInputValue(updatedContent);
      //setSelectedTypeId('2');
      //const item = questionTypeList.find(item => item.id === 2);
      //setSelectedType(item.value);//修改界面
    }

  };


  const handleClearRecord = () => {
    setMessages([]);
    setIsKeepHistory(false);//不保留记忆，已经清除记录
    setAnchorEl(null);
    setIsDisplayBlankContent(true);//显示空白页的默认图
    setIsNeedCreateSeesionChat(true);
    setSelectedRowId(0);
    setSessionChatId(0);

    setVisible(0);
  }


  const onReceiveStringFromMessItem = (str, executeType) => {
    // 处理发送消息逻辑
    flagMessFromItem.current = true;

    handleSendMessage(str, executeType);
  };

  const handleImageUpload = (imageData) => {
    setUploadedImage(imageData);
  }

  const handleClick11 = (event) => {
    setMenuAnchorElement(event.currentTarget);
  };

  //选择问题类型事件
  const handleClose11 = (id, type) => {
    setMenuAnchorElement(null);
    if (type !== null && id !== null) {
      setSelectedType(type);
      setSelectedTypeId(id);

      if (["english", "chinese"].includes(selectedSubjectOptionText) && [3, 4, 5].includes(id)) {
        setIsShowTextOCRDiv(true);
      }

    }
  };

  const handleAudioRecord = () => {
    setIsShowAudioRecorder(true);
  }

  const onCloseOnly = () => {
    setIsShowAudioRecorder(false);
  }


  const inputAreaRef = useRef(null);


  const onCloseAudioRecord = () => {
    setIsShowAudioRecorder(false);
    //setIsetInputValueEnable(true);
    let textOld = inputAreaRef.current.getInputValue();

    inputAreaRef.current.setInputValue(textOld + translateText);
    //setIsetInputValueEnable(false);
  }

  const [latexString, setLatexString] = useState();


  const isMobile = window.innerWidth <= 768;

  //关闭主题历史组件
  const handleClosePTHistory = () => {
    setIsOpenHistory(false);
  };

  const handleGetPTHistory = async () => {
    setIsLoading(true);
    loadDataCommonChatSessionList(1);
  };


  const loadDataCommonChatSessionList = async (page) => {
    try {
      setIsLoading(true);
      const dataToSend = {
        subject:selectedSubjectOptionText,
        grade:7,
        page_size:5,
        page_number:page,
      };
      setIsLoading(true);
      const data = await requestUrl(dataToSend,"get_common_chat_session_list",t,showSnackbar);
      setUploadedImage(null);
      setTableData(data.data);
      setTotalPagesSession(data.total_pages);
      //setSelectedPTHistoryData(dat_server);
      setIsLoading(false);
 
    }
    catch (error) {
      setIsLoading(false);
      console.error("There was an error fetching the reply:", error);
    }
    setIsLoading(false);
  }

  const handleChangePage = async (event, newPage) => {
    setCurrentPage(newPage);
    loadDataCommonChatSessionList(newPage);
  };

  //加载指定主题的对话记录
  const handleLoadChatPT = async () => {
    if (!selectedRowId) {
      
      showSnackbar(t('Load Record, select a record to load'),'warning');
      return;
    }
    loadSubjectData(1);

    setIsOpenHistory(false);
  }

  const handleShowCommonChatList = () => {
    setIsSettingButtonOpen(false);
    setIsOpenHistory(true);
  }
  const onCloseHelp = () => {

    setIsShowSystemHelp(false);
  }



  const onClosePhotoCom = (updateLatexText) => {
    if (updateLatexText != "") {
      inputAreaRef.current.setInputValue(updateLatexText);
    }
    setIsShowTextOCRDiv(false);
  };



  const removeItem = (idToRemove) => {
    // 使用filter方法过滤掉id等于1的元素
    const filteredItems = tableData.filter(item => item.id !== idToRemove);

    // 更新状态以反映删除后的数组
    setTableData(filteredItems);
  };

  // 调用函数来删除id为1的元素

  //删除chatsession
  const handleDeleteChatSession = async (sessionid) => {
    try {
      setIsLoading(true);
      const dataToSend = {
        sessionid: sessionid,
      };
      const data = await requestUrl(dataToSend, "delete_common_chat_session", t,showSnackbar);

      if (data.status === "success") {
        removeItem(sessionid);
      }
    }
    catch (error) {
      console.error("获取数据时出错:", error);
    }
    finally {
      setIsLoading(false);
    }
  };

  const handleSystemHelp = () => {
    setIsSettingButtonOpen(false);
    setIsShowSystemHelp(true);
  };

  const [useWf, setUseWf] = useState(0);

  //添加到分类
  const [totalPages,setTotalPages] = useState(1);//分类总页数

  const [commonChatClassList, setCommonChatClassList] = useState([]);
  const [addOrLoadClass,setAddOrLoadClass] = useState('add');//表示是添加到class还是加载class，  add  load
  const [showCommonChatSessionClass,setShowCommonChatSessionClass] = useState(false);

  const [selectedCommonChatSessionClassID,setSelectedCommonChatSessionClassID] = useState(0);
  const [selectedCommonChatSessionClassName,setSelectedCommonChatSessionClassName] = useState('');
  const centerStyle = {
    zIndex:9999,
    display: 'flex',
    backgroundColor:'white',
    justifyContent: 'center',
    alignItems: 'center',
    height: '70vh', // 视口高度
    width: '70vw', // 视口宽度，确保宽度也是全屏的
    position: 'fixed', // 固定定位，确保不受滚动影响
    left: '50%', // 左边距设置为50%，配合transform使用，可实现居中
    top: '50%', // 顶部距离设置为50%，配合transform使用，可实现居中
    transform: 'translate(-50%, -50%)' // 平移自己的50%，确保完全居中
};
  const [showAddClass,setShowAddClass] = useState(false);

  const handleAddClass = async () => {
    setAddOrLoadClass('add');
    setIsSettingButtonOpen(false);
    if(commonChatClassList.length > 0){
      setShowAddClass(true);
    }
    else{
      loadCommonChatClass(currentPageCommonChatClass);
      
    }
  };


  //加载commonchat 分类数据
  const loadCommonChatClass = async (selectedPage) => {
    try {
      setIsLoading(true);
      const dataToSend = {
        page_size:15,
        page_number:selectedPage,
      }; 
      const data = await requestUrl(dataToSend,"get_common_chat_class",t,showSnackbar);

      setCommonChatClassList(data.data);
      console.log(data.data);
      setTotalPages(data.total_pages);
      setIsLoading(false);
      setShowAddClass(true);
    }
    catch (error) {
      setIsLoading(false);
      console.error("There was an error fetching the reply:", error);
    }
  }


  // 状态管理：当前选中的行索引
  const [selectedRowIndex, setSelectedRowIndex] = useState(-1);
  
  // 更新选中的行的函数
  const handleSelectRow = (index) => {
    setSelectedRowIndex(index);
    let cid = commonChatClassList[index].id;
    let classname = commonChatClassList[index].class_name;
    setSelectedCommonChatSessionClassName(classname);
    setSelectedCommonChatSessionClassID(cid);
  };

  // 获取选中数据的函数,添加当前对话到分类
  const getSelectedData = async () => {

    if (selectedRowIndex >= 0) {
      
      if(addOrLoadClass === "add"){
        addCommonChatToClass();
      }
      if(addOrLoadClass === "load"){
        loadCommonChatSessionClass();
        setShowAddClass(false);
      }
      
      // 处理选中的数据，例如显示或发送给其他组件
    }
  };

  const loadCommonChatSessionClass = async () => {

    
    
    setShowCommonChatSessionClass(true);
  }

  const addCommonChatToClass = async () => {
    if(sessionChatId === 0){
      showSnackbar(t('no records yet to add to the categorization'),'warning');
      return;
    }
    let cid = commonChatClassList[selectedRowIndex].id;

      try {
        setIsLoading(true);
        const dataToSend = {
          sessionId:sessionChatId,
          classId:cid,
        }; 
        const data = await requestUrl(dataToSend,"add_common_chat_to_class",t,showSnackbar);
  
        
        setIsLoading(false);
        setShowAddClass(false);
      }
      catch (error) {
        setIsLoading(false);
        console.error("There was an error fetching the reply:", error);
      }
  }
  const handleCloseCommonChatClass = () => {
    setShowAddClass(false);
  };
  
  
  const handleGetCommonChatSessionByClassId = (index) => {
    setAddOrLoadClass('load');
    setIsSettingButtonOpen(false);
    if(commonChatClassList.length > 0){
      setShowAddClass(true);
    }
    else{
      loadCommonChatClass(1);
      
    }
  };
  

  const onCloseCommonChatSessionClass = async (item) => {
    setShowCommonChatSessionClass(false);

  }

  //确认，加载指定chatsession
  const onConfirmCommonChatSessionClass = async (item) => {
    setShowCommonChatSessionClass(false);
    loadSubjectData1(item.subject,item.grade,item.id,1,item.qtype);
    setSessionChatId(item.id);
    setSelectedGradeOption(item.grade);
    setSelectedSubjectOptionText(item.subject);
    
  }

  const loadSubjectData1 = async (subject,grade,sessionid,pagenumber,qtype) => { 
    setAnchorEl(null);
    try {
      setIsLoading(true);
      const dataToSend = {
        subject:subject,
        grade_value:grade.toString(),
        common_chat_session_id:sessionid,
        page_size:100,
        page_number:pagenumber,
      };
      
      const data = await requestUrl(dataToSend,"get_subject_history",t,showSnackbar);
      setIsDisplayBlankContent(false);//默认空白页的图片不显示
      setMessages(data.data);
      setCuspredefinedContent(data.prompts);
      setQuestionTypeList(data.qtypes);

      
      
      setSelectedTypeId(qtype); //问题类型设置为默认
      //const item = data.qtypes.find(item => item.id === qtype);
      //setSelectedType(item.value);//修改界面的问题类型




      
      setIsKeepHistory(true);
      if(pagenumber === 1){
        setPageList(data.total_page);
      }
      setIsNeedCreateSeesionChat(false);//无需创建sessionchat表数据
      setIsLoading(false);    
      
      
    }
    catch (error) {
      setIsLoading(false);
      console.error("There was an error fetching the reply:", error);
    }
  }

  //打开了某个分类的详细session列表，关闭后，继续显示分类列表
  const handleOnHiddenClassDetail = async (item) => {

    setShowCommonChatSessionClass(false);
    setShowAddClass(true);
  }

  


 // 状态控制显示内容：0-只显示A1，1-只显示A2，2-同时显示A1和A2
 const [visible, setVisible] = useState(0);




 const actions = [
  { icon: <FileCopyIcon />, name: '显示问答',id:1 },
  { icon: <SaveIcon />, name: '显示当前所有对话的分析' ,id:2},
  { icon: <PrintIcon />, name: '同时显示' ,id:3},
];



  const [openFloatPKSession, SetOpenFloatPKSession] = React.useState(false);

  const handleOpenSessionPK = () => {

    console.log("open:" + messages.length);
    //SetOpenFloatPKSession(prev => !prev); // 切换状态
    SetOpenFloatPKSession(!openFloatPKSession);
  };


  const handleCloseSessionPK = async (actionId) => {
    
    if(sessionChatId === 0){
      setVisible(0);
      SetOpenFloatPKSession(false);
      return;
    }

    if(messages.length === 2){//只有一次对话，不对当前轮对话总结
      showSnackbar(t('at least 2 dialogues are required'),'warning');
      SetOpenFloatPKSession(false);
      return;
    }

    

    if(actionId === 1){
      setVisible(0);
    }
    if(actionId === 2){
      if(pointKnowledgeArray.length === 0){
        load_chat_session_point_knowledge();
      }
      
      setVisible(1);
    }
    if(actionId === 3){
      if(pointKnowledgeArray.length === 0){
        load_chat_session_point_knowledge();
      }
      setVisible(2);
    }
    SetOpenFloatPKSession(false);
  };



  const load_chat_session_point_knowledge = async() =>{
    setIsLoading(true);

    try {
      setIsLoading(true);
      const dataToSend = {
        id:sessionChatId,
      };
      
      const data = await requestUrl(dataToSend,"get_point_knowledge_for_chat_session",t,showSnackbar);
      setPointKnowledgeArray(data.pointKnowledgeArray);
      setPointKnowledgeContent(data.pointKnowledgeContent);
      
    }
    catch (error) {
      setIsLoading(false);
      console.error("There was an error fetching the reply:", error);
    }
    setIsLoading(false);

  }




  const renderTextAndMath = (text) => {
    if (text === undefined) {
      text = "";
    }
    const regex = /\$(.+?)\$/g;
    let result = '';
    let lastIndex = 0;

    text.replace(regex, (match, formula, index) => {
      // 添加普通文本
      result += text.slice(lastIndex, index);
      // 将KaTeX公式转换为HTML
      try {
        result += katex.renderToString(formula, { throwOnError: false });
      } catch (e) {
        console.error(e);
        result += match; // 如果出错，就使用原始文本
      }
      lastIndex = index + match.length;
    });

    // 确保添加最后一段普通文本
    result += text.slice(lastIndex);
    return result;
  };

    // 定义Item组件的样式
    const itemStyle = {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      backgroundColor: '#1B4F72',
      padding: '8px',
      textAlign: 'center',
      color: 'rgba(0, 0, 0, 0.87)',
    };
  
  
    const [pkItems, setPkItems] = useState([
      { id: 1, xs: 8, content: "$y=ax^2+bx+c$一元二次函数的图像和应用" },
      { id: 2, xs: 4, content: "xs=4"},
      { id: 3, xs: 4, content: "xs=4" },
      { id: 4, xs: 8, content: "xs=8" },
    ]);
    //delete
     
  const handleDeletePointKnowledge = (pkitem) => {
  
    setPointKnowledgeArray(currentItems => currentItems.filter(item => item.id !== pkitem.id));

  };
  const updatePointKnowledgeArray = (pkitem) => {

    //setPointKnowledgeArray(currentItems => currentItems.filter(item => item.id !== pkitem.id));
 
  };

  
  const [pkIsEditing, setPkIsEditing] = useState(false);


  const handleDoubleClick = () => {
    setPkIsEditing(true);
  };

  const handleChange = (event) => {
    setPointKnowledgeContent(event.target.value);
  };

  //更新知识点内容
  const handleBlur = () => {

    setPkIsEditing(false);

    if(pkIsEditing){
      updatePkAarray(pointKnowledgeContent,"update_common_chat_session_pk_content");
    }
  
    // 在这里，你可以添加保存编辑内容的逻辑，比如更新数据库或状态管理库
  };

  //知识点分析内容更新，点击编辑按钮
  const toggleEdit = async () => {

    setPkIsEditing(!pkIsEditing);

    
  };

  //知识点数组编辑删除//////////////////////////
  const [editingIdPkArray, setEditingIdPkArray] = useState(null);
  // 新增一个状态来存储编辑中的文本
  const [editText, setEditText] = useState("");

  const toggleEditPkArray = (pkitem, event) => {
    event.stopPropagation();
    setEditingIdPkArray(editingIdPkArray === pkitem.id ? null : pkitem.id);
    // 设置当前编辑的文本为pkitem的内容
    setEditText(pkitem.content);
  };



  //保存知识点数据的某个内容
  const handleSaveEdit = (id) => {
    // 创建一个新数组作为副本，以避免直接修改状态
    const newArray = [...pointKnowledgeArray];
    
    // 找到需要更新的元素的索引
    const indexToUpdate = newArray.findIndex(pkitem => pkitem.id === id);
    
    // 检查是否找到了元素
    if(indexToUpdate !== -1) {
      // 更新元素的内容
      newArray[indexToUpdate].content = editText;
      
      // 使用更新后的数组来更新状态
      let arrayStr = JSON.stringify(newArray);
      updatePkAarray(arrayStr,"update_pk_array_for_common_chat_session");//更新服务端

      setPointKnowledgeArray(newArray);
    }
    
    // 退出编辑状态
    setEditingIdPkArray(null);



  };


  
  const updatePkAarray = async(arrayStr,interfacename) =>{
    try {
      setIsLoading(true);
      const dataToSend = {
        id:sessionChatId,
        content:arrayStr,
      };
      
      const data = await requestUrl(dataToSend,interfacename,t,showSnackbar);
      setIsLoading(false);    
    }
    catch (error) {
      setIsLoading(false);
      console.error("There was an error fetching the reply:", error);
    }
    setIsLoading(false);
    
  }


  const [pkArray,setPkArray] = useState([]);

  const getPointKnowledgeMap = async(pkContent) =>{
    setPkArray(pkContent);
    setShowPointKnowledgeMap(true);

  }

  const handleShowBuddleMap = async() =>{
    setshowBuddleMap(true);
  }



  //////////////////////分类功能分页

  const [currentPageCommonChatClass,setCurrentPageCommonChatClass] = useState(1);

  const [numberOfRowsPerPageCommonChatClass, setNumberOfRowsPerPageCommonChatClass] = useState(10);


  const handleChangePageCommonChatClass = (event, newPage) => {
    setCurrentPageCommonChatClass(newPage);
    loadCommonChatClass(newPage);
  };




  const handleChangeRowsPerPageCommonChatClass = (event) => {
    setNumberOfRowsPerPageCommonChatClass(parseInt(event.target.value, 10));
    setCurrentPageCommonChatClass(0);
  };




  const removeItemClass = (idToRemove) => {
    // 使用filter方法过滤掉id等于1的元素
    const filteredItems = commonChatClassList.filter(item => item.id !== idToRemove);
  
    // 更新状态以反映删除后的数组
    setCommonChatClassList(filteredItems);
  };

  const handleDeleteChatClass = async (sessionid) => {
    try {
      setIsLoading(true);
      const dataToSend = {
          id:sessionid,
      };
      const data = await requestUrl(dataToSend, "delete_common_chat_Class",t,showSnackbar);
      
      if(data.status === "success"){
        removeItemClass(sessionid);
      }
    }
    catch (error) {
        console.error("获取数据时出错:", error);
    }
    finally {
      setIsLoading(false);
    }
  };



    const [addClassName,setAddClassName] = useState('');
    const [addClassDes,setAddClassDes] = useState('');

    const [showAddNewChatClass,setShowAddNewChatClass] = useState(false);

    const handleAddNewClass = async () => {
      try {
        setIsLoading(true);
        const dataToSend = {
            classname:addClassName,
            des:addClassDes,
        };
        console.log("addC:" + JSON.stringify(dataToSend));
        const data = await requestUrl(dataToSend, "add_common_chat_Class",t,showSnackbar);
        
        if(data.status === "success"){
          let newid = data.data;
          const currentTime = new Date().toISOString();
          let uid = parseInt(localStorage.getItem('uid'),10);
          setCommonChatClassList(prevCommonChatClassList => [
            ...prevCommonChatClassList,
            {
              id: newid,
              class_name: addClassName,
              chat_session_id: 0,
              create_time: currentTime,
              userid: uid,
              description: addClassDes
            }
          ]);

          setShowAddNewChatClass(false);
        }
      }
      catch (error) {
          console.error("获取数据时出错:", error);
      }
      finally {
        setIsLoading(false);
        setShowAddNewChatClass(false);
      }
    };
    
   ///////////////////////////////
   const [isSettingButtonOpen, setIsSettingButtonOpen] = useState(false);
   const [settingDragging, setSettingDragging] = useState(false);
 
   const handleDrag = (e, data) => {
     if (data.deltaX !== 0 || data.deltaY !== 0) {
       setSettingDragging(true);
     }
   };
 
   const handleStop = () => {
     setTimeout(() => {
       setSettingDragging(false);
     }, 100);
   };
 
   const handleSettingClick = (e) => {
    e.stopPropagation(); // 阻止点击事件冒泡到document
    // 如果不是拖动行为，则切换弹出状态
    if (!settingDragging) {
      setIsSettingButtonOpen(!isSettingButtonOpen);
    }
  };

// 添加全局点击事件监听器以关闭容器
useEffect(() => {
  const handleClickOutside = (event) => {
    // 通过ref检查点击是否在容器或按钮外
    if (isSettingButtonOpen && !document.querySelector('[aria-label="settings"]').contains(event.target)) {
      setIsSettingButtonOpen(false);
    }
  };

  // 只有当容器打开时才监听点击事件
  if (isSettingButtonOpen) {
    document.addEventListener('click', handleClickOutside);
  }
  return () => {
    document.removeEventListener('click', handleClickOutside);
  };
}, [isSettingButtonOpen]); // 依赖于isSettingButtonOpen

// 动画和相关样式定义
const blinkAnimation = `
@keyframes blink-animation {
  0% { opacity: 1; }
  50% { opacity: 0; }
  100% { opacity: 1; }
}
`;


// 以下是组件样式
const paperStyle = {
  padding: '20px',
  margin: '10px',
  backgroundColor: '#f5f5f5', // 浅乳白色背景
};

const buttonGridStyle = {
  marginBottom: '20px',
};

 // 容器A样式
 const containerAStyle = {
  backgroudColor:'#1565c0 !important',
  display: 'flex',
  height: '30px',
  alignItems: 'center',
  justifyContent: isMobile ? 'flex-start' : 'center',
  position: 'relative',
  border: '1px solid #ccc', // 辅助可视化
  padding: isMobile ? '0 10px' : '0', // 为手机视图添加左右内边距
  
};

// 容器B样式，当非移动设备时，无需特殊定位
const containerBStyle = {};

// Right容器样式
const containerRightStyle = {
  position: 'absolute',
  right: '10px',
  display: 'flex',
  gap: '10px', // 设置子控件之间的水平间隔为10px
  justifyContent: 'center', // 让子控件水平居中对齐
  alignItems: 'center', // 使子控件在容器内垂直居中
  
};

  const onListItemClick = async (selectedSubject) => {
    //handleClearRecord();
    setMessages([]);
    setSelectedSubjectOptionText(selectedSubject);
    console.log("SSSSSSSSS:" + selectedSubject);
    localStorage.setItem('science', selectedSubject);

    setSelectedGradeOption("1");
    
    load_prompts_questionTypes(selectedSubject, "1");
    
    set_problem_type(1);
  };

  const handleCameraClick = () => {
    setLatexModalVisible(true);
  }
  

  const buttonRef = useRef(null); // 创建一个 ref 来引用按钮

  return (
    <div>
      <div style={containerAStyle}>
      {!isMobile && <div style={containerBStyle}>
        <AnalyticsIcon onClick={handleShowBuddleMap} sx={{color:'#eca106',cursor:'pointer'}} />
      </div>}
      <div style={containerRightStyle}>

      <div style={{ color: 'white', fontSize: '14px' }}>
          {t(selectedSubjectOptionText)}
        </div>
        
        
       
        <div style={{ fontSize: '14px' }}>{t('Q type')}:</div>
        <div>
          <Button style={{color:'white'}} onClick={handleClick11}>
            {selectedType}
          </Button>
          <MuiMenu sx={{ color: 'white' }}
            anchorEl={menuAnchorElement}
            open={Boolean(menuAnchorElement)}
            onClose={() => handleClose11(null)}
            anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
            transformOrigin={{ vertical: 'bottom', horizontal: 'left' }}
            getContentAnchorEl={null}
          >
            {questionTypeList.map(({ id, value }) => (
              <MenuItem
                sx={{ fontSize: '14px' }}
                key={id}
                selected={id === selectedTypeId} // 确保选中状态基于 selectedTypeId
                onClick={() => handleClose11(id, value)}
              >
                {t(value)}
              </MenuItem>
            ))}

          </MuiMenu>
        </div>
      </div>
      {isMobile && <div style={containerBStyle}>
        <AnalyticsIcon onClick={handleShowBuddleMap} sx={{color:'white',cursor:'pointer'}} />
      </div>}
      </div>
      <div>
        <Grid container spacing={0} >
          {/* 0220 新增*/}
        {visible !== 1 && (
          <Grid item xs={visible === 2 ? 6 : 12}>
            <ChatWindow 
              messages={messages}  
              onSendMessage={handleSendMessage}
              predefinedContent={cuspredefinedContent} 
              inputText={inputText} 
              setInputText={setInputText}
              subject={selectedSubjectOptionText}
              isLoading={isLoading}
              onReceiveStringFromMessItem={onReceiveStringFromMessItem}
              onImageUpload={handleImageUpload}
              showAudio={true}
              handleAudioRecord={handleAudioRecord}
              ref={inputAreaRef}
              useWf={useWf}
              getPointKnowledgeMap={getPointKnowledgeMap}
              isShowBackgroundHtml={isDisplayBlankContent}
              grade={selectedGradeOption}
              fromModule='science'
              questionType={selectedTypeId}
              showAddImage={true}
              />
          </Grid>
        )}
         {/* 0220 新增*/}
        {visible !== 0 && (
          
          <Grid item xs={visible === 2 ? 6 : 12}>

             <Box
                sx={{

                  height:'70%',
                  marginTop:'20px',
                  width: '70%', // 主容器宽度为视窗宽度的70%
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center', // 确保下部容器始终位于底部
                  // 这里没有设置固定高度，允许容器根据内容自适应
                }}
              >
           {/* 使用条件渲染确保上部容器在不显示时不占用空间 */}

             <Box
               sx={{
                marginTop:'10px',
                 flex: 1,
                 display: 'flex', // 当显示时占满剩余空间
                 overflow: 'hidden', // 防止内容溢出
                 backgroundColor:'white',
                 zIndex:2000,
                 borderRadius: '16px', // 圆角大小
                // 可以使用 theme.spacing 来定义圆角大小，例如 theme.spacing(2)
                // borderRadius: theme => theme.spacing(2),
                boxShadow: 3, // 阴影效果
               }}
             >
               <Container maxWidth="sm">
                <Box sx={{ bgcolor: '#cfe8fc', p: 2, display: 'flex', flexDirection: 'column', gap: 2 }}>
                  {/* 上部分的数组分组显示 */}
                  <Box sx={{ bgcolor: 'white', p: 2 }}>
                  <Grid container spacing={2}>
                  {pointKnowledgeArray.map((pkitem) => (
                    <Grid item xs={pkitem.xs} key={pkitem.id} onClick={(event) => toggleEditPkArray(pkitem, event)}>
                      <div style={itemStyle}>
                        {editingIdPkArray === pkitem.id ? (
                          // 编辑状态：显示输入框，允许用户修改文本
                          <div id={`edit-${pkitem.id}`}>
                            <TextField
                            sx={{
                              backgroundColor: 'white',
                              // Targeting the input element for styling
                              '& .MuiInputBase-input': {
                                color: 'black', // Change text color
                              },
                            }}
                            multiline
                            maxRows={4}
                            onClick={(e) => e.stopPropagation()} 
                            className="editable-input" 
                            style={{color:'black'}} 
                            type="text" 
                            value={editText} 
                            onChange={(e) => setEditText(e.target.value)} 
                            onBlur={() => handleSaveEdit(pkitem.id)} />
                            
                          
                          </div>
                        ) : (
                          // 非编辑状态：显示文本
                          <span dangerouslySetInnerHTML={{ __html: renderTextAndMath(pkitem.content) }} />
                        )}
                        <IconButton onClick={(event) => handleDeletePointKnowledge(pkitem, event)} aria-label="delete">
                          <DeleteIcon sx={{ color: get_color_by_name('small-icon-color') }} />
                        </IconButton>
                        {editingIdPkArray === pkitem.id && (
                          // 编辑状态时显示保存图标
                          <IconButton onClick={() => handleSaveEdit(pkitem.id)} aria-label="save">
                            <SaveIcon sx={{ color: get_color_by_name('small-icon-color') }}  />
                          </IconButton>
                        )}
                      </div>
                    </Grid>
                  ))}
                  </Grid>
                  </Box>

                  {/* 中间的总结文字内容 */}
                  <Box sx={{ bgcolor: '#7D26CD', p: 2, flex: 1 }} onDoubleClick={handleDoubleClick}>
                    {!pkIsEditing ? (
                      <Typography sx={{ mb: 1.5, color: 'white' }} dangerouslySetInnerHTML={{ __html: renderTextAndMath(pointKnowledgeContent) }}>
                        {/* 内容通过 dangerouslySetInnerHTML 插入 */}
                        
                      </Typography>
                    ) : (
                      <TextField
                      sx={{backgroundColor:'white',color:'black'}}
                        fullWidth
                        multiline
                        variant="outlined"
                        value={pointKnowledgeContent}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        autoFocus
                      />
                    )}
                  </Box>
                  {/* 下容器 */}
                  <Box sx={{ bgcolor: 'primary.main', p: 2, display: 'flex', justifyContent: 'center' }}>

                  {!pkIsEditing ? (
                    <IconButton sx={{ backgroundColor:'white', color: 'orange' }} onClick={toggleEdit}>
                      <EditIcon /> {/* 非编辑状态显示编辑图标按钮 */}
                    </IconButton>
                  ) : (
                    <IconButton sx={{backgroundColor:'white', color: 'orange' }} onClick={toggleEdit}>
                      <SaveIcon /> {/* 编辑状态显示保存图标按钮 */}
                    </IconButton>
                  )}
                  </Box>
                </Box>
              </Container>
             </Box>
         </Box>
          </Grid>
        )}
      </Grid>
    
      </div>

      

      {isLoading && (
        <LoadingData text={t(loadingText)}/>
      )}
      
      <Dialog
        open={openEnsureSend}
        onClose={() => handleUserDecision(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        
        sx={{
          '& .MuiDialog-paper': { // 直接目标MuiDialog的内部paper元素
            width: '500px', // 设置宽度
            height: '550px', // 设置高度
            maxWidth: 'none', // 取消最大宽度限制，以允许设置自定义宽度
            maxHeight: 'none', // 取消最大高度限制，以允许设置自定义高度
            m: 'auto', // 自动外边距以居中对话框
            backgroundColor:get_color_by_name('pop-window-bg'),
            border: `1px solid ${get_color_by_name('pop-window-border-color')}`,
          }
        }}
      >
        <DialogTitle style={{color:get_color_by_name('pop-window-text-color')}} id="alert-dialog-title">{t('confirm your action')}</DialogTitle>
        <DialogContent>
          <DialogContentText style={{color:get_color_by_name('pop-window-text-color')}}  sx={{fontFamily:'siyuan'}} id="alert-dialog-description">
            {t('reconfirm the following,and click confirm.')}
            <div>
              {t('You can also change the following options here before clicking on the confirmation button')}
            </div>
          </DialogContentText>
          <Stack direction="column" spacing={2} sx={{ width: '100%' }}>
            

            <Grid item>
              <h2 style={{color:get_color_by_name('pop-window-text-color')}}>{t('type')}</h2>
            </Grid>
            <Grid item container justifyContent="center" spacing={1}>
            {subjectOptions.map((option) => (
                <Grid item key={option.id}>

                <Button
                  onClick={() => onListItemClick(option.id)}
                    onTouchEnd={(e) => {
                      e.preventDefault(); // 阻止默认行为和冒泡，根据需要调整
                      onListItemClick(option.id);
                    }}
                    sx={{
                      fontFamily:'siyuan',
                      border: `1px solid ${get_color_by_name('pop-window-border-color')}`,
                      backgroundColor: selectedSubjectOptionText === option.id ? get_color_by_name('mul-button-bg-selected') : get_color_by_name('mul-button-bg'),
                      color: selectedSubjectOptionText === option.id ? get_color_by_name('mul-button-color-selected') : get_color_by_name('mul-button-color'),
                      '&:hover': {
                        backgroundColor: selectedSubjectOptionText === option.id ? 'darkgreen' : 'lightgrey',
                      },
                    }}
                  >
                    {option.text}
                  </Button>
                </Grid>
              ))}
            </Grid>
        
              <div style={{color:get_color_by_name('pop-window-text-color')}}>
                {t('identify the type of question you are asking, which will influence the answer')}
                </div>
              
                <br />
                <Button
        ref={buttonRef} // 将按钮的 ref 分配给 buttonRef
        variant='outlined'
        onClick={handleClick11}
        sx={{ color: get_color_by_name('headline-color'), height: '42px', width: '100%' }}
      >
        {selectedType || 'Select Type'}
      </Button>

      <Popper open={Boolean(menuAnchorElement)} anchorEl={menuAnchorElement}>
        <Menu
          anchorEl={menuAnchorElement}
          open={Boolean(menuAnchorElement)}
          onClose={() => handleClose11(null)}
          anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
          transformOrigin={{ vertical: 'bottom', horizontal: 'left' }}
          getContentAnchorEl={null}
          PaperProps={{
            sx: {
              backgroundColor:get_color_by_name('mui-button-bg'),
              width: buttonRef.current ? buttonRef.current.clientWidth : 'auto', // 使用按钮的宽度
            },
          }}
        >
          {questionTypeList.map(({ id, value }) => (
            <MenuItem
            sx={{
              color: get_color_by_name('headline-color'),
              fontSize: '14px',
              
            }}
              key={id}
              selected={id === selectedTypeId}
              onClick={() => handleClose11(id, value)}
            >
              {value}
            </MenuItem>
          ))}
        </Menu>
      </Popper>

          
          </Stack>

        </DialogContent>
        <DialogActions
          sx={{
            justifyContent: 'flex-end', // 将按钮对齐到容器的末端
            paddingRight: '18px', // 右侧内边距设置为25px
          }}
        >
          <CustomButton
            onClick={() => handleUserDecision(false)}>
            {t('cancel')}
          </CustomButton>
          <CustomButton
            onClick={() => handleUserDecision(true)}>
            {t('confirm')}
          </CustomButton>
        </DialogActions>
      </Dialog>

      <Modal
          open={isShowAudioRecorder}
          onClose={onCloseAudioRecord}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          sx={{zIndex:5555}}
        >

          <AudioRecorder onCloseAudioRecord={onCloseAudioRecord} translateText={translateText} setTranslateText={setTranslateText} onCloseOnly={onCloseOnly} />
      </Modal>
     
      <Dialog open={isOpenHistory} onClose={handleClosePTHistory}
        sx={{

          '& .MuiPaper-root': {
            width: {
              xs: '98%', // 手机屏幕上宽度设置为 90%
              sm: '800px', // 平板和电脑屏幕上最大宽度为 500px
            },
            maxWidth: '100%', // 确保不会超出屏幕
          },
        }}>
          <div style={{border: `1px solid ${get_color_by_name('pop-window-border-color')}`,}}>
            <DialogTitle sx={{ textAlign: 'center', color: 'black', fontSize: '24px' }}
            style={{color:get_color_by_name('headline-color')}}>
              {t('topics history records')}
              <Button variant="text" sx={{color:get_color_by_name('small-icon-color')}} onClick={handleGetPTHistory}>
                <Refresh></Refresh>
                {/* {t('load records')} */}
              </Button>
            </DialogTitle>
            <DialogContent sx={{backgroundColor:get_color_by_name('pop-window-bg')}}>
              <div>
                {/* <Button variant="contained" color="primary" sx={{ width: '100%', marginBottom: '10px' }} onClick={handleGetPTHistory}>
                  {t('load records')}
                </Button> */}
                <div>
                  <TableContainer component={Paper}>
                    <Table>
                      <TableHead>
                        <TableRow sx={{backgroundColor:get_color_by_name('pop-window-bg')}}>
                          <TableCell sx={{fontFamily:'siyuan',color:get_color_by_name('headline-color')}}>{t('type')}</TableCell>
                          <TableCell sx={{fontFamily:'siyuan',color:get_color_by_name('headline-color')}}>{t('topics')}</TableCell>

                          <TableCell sx={{fontFamily:'siyuan',color:get_color_by_name('headline-color')}}>{t('time')}</TableCell>
                          <TableCell sx={{fontFamily:'siyuan',color:get_color_by_name('headline-color')}}>{t('delete')}</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                      {tableData.map((row, index) => (
                          <TableRow key={row.id} selected={selectedRowId === row.id}
                            onClick={() => {
                              setSelectedRowId(row.id);
                              setSessionChatId(row.id);
                              setSelectedRowData(row);
                            }}
                            hover
                            style={{ cursor: 'pointer', backgroundColor: selectedRowId === row.id ?  get_color_by_name('mui-button-bg') : get_color_by_name('pop-window-bg') }}
                          >
                            <TableCell sx={{fontFamily:'siyuan',color:get_color_by_name('headline-color')}}>{t(row.qtype_name)}</TableCell>
                            <TableCell sx={{fontFamily:'siyuan',color:get_color_by_name('headline-color')}} title={row.session_name} >{row.session_name.length > 20 ? row.session_name.substring(0, 20) + "..." : row.session_name}</TableCell>

                            <TableCell sx={{fontFamily:'siyuan',color:get_color_by_name('headline-color')}}>{formatDate(row.recordtime)}</TableCell>
                            <TableCell sx={{fontFamily:'siyuan',color:get_color_by_name('headline-color')}}>
                              <IconButton aria-label="delete" sx={{color:get_color_by_name('small-icon-color')}} onClick={() => handleDeleteChatSession(row.id)}>
                                <DeleteIcon />
                              </IconButton>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                    
                  </TableContainer>
                  <Pagination
                    sx={{ backgroundColor:get_color_by_name('mui-textfield-bg') , borderRadius: '10px' }}
                    count={totalPagesSession}
                    page={currentPage}
                    onChange={handleChangePage}
                    color="primary"
                  />
                </div>
              </div>

            </DialogContent>
            <DialogActions sx={{backgroundColor:get_color_by_name('pop-window-bg')}}>
              <CustomButton onClick={handleLoadChatPT} fullWidth sx={{ textTransform: 'none' }}>{t('confirm')}</CustomButton>
              <CustomButton onClick={handleClosePTHistory} fullWidth sx={{ textTransform: 'none', }}>{t('close')}</CustomButton>
            </DialogActions>
        </div>
      </Dialog>

      {isShowTextOCRDiv && (
        <div style={{ zIndex: 99999 }}>
          <PhotoComponent onClose={onClosePhotoCom } ocrType="subject" subject={selectedSubjectOptionText} moduleName="common"/>
        </div>

      )}

      {isShowSystemHelp && (
        <SystemHelp modulename="science" subject={selectedSubjectOptionText} onClose={onCloseHelp} />
      )}
      {/**悬浮按钮代码----开始 */}
      <Draggable
          onDrag={handleDrag}
          onStop={handleStop}
        >
      <div style={{
        position: 'absolute',
        zIndex: 99999,
        right: '80px', // 距离屏幕右侧100px
        top: '35%', // 父容器的顶部距离视口顶部的距离为视口高度的一半
        transform: 'translateY(-40%)' // 向上移动元素高度的一半，实现垂直居中
      }}>        
        <style>
          {blinkAnimation}
        </style>
      
        <Box
          sx={{
            display: 'flex', // 启用 flex 布局
            flexDirection: 'column', // 子项水平排列
            alignItems: 'center', // 垂直居中对齐子项
            gap: 2, // 子项之间的间隔
          }}
        >
        <IconButton
          aria-label="settings"
          onClick={handleSettingClick}
          onTouchEnd={handleSettingClick} // 添加触摸事件处理
          sx={{
            '& .MuiSvgIcon-root': {
              color: get_color_by_name('small-icon-color'),
              animation: 'blink-animation 1s infinite'
            }
          }}
        >
          <SettingsIcon fontSize="large" />
        </IconButton>

        <IconButton
          aria-label="camera"
          onClick={handleCameraClick}
          onTouchEnd={handleCameraClick} // 添加触摸事件处理
          sx={{
            '& .MuiSvgIcon-root': {
              color: get_color_by_name('small-icon-color'),
              animation: 'blink-animation 1s infinite'
            }
          }}
        >
          <PhotoCamera fontSize="large" />
        </IconButton>
        </Box>

        {/* 条件渲染自定义的容器或组件 */}
        {isSettingButtonOpen && (
          <div
            style={{
             
              position: 'absolute',
              top: '50px', // 根据需要调整
              right: '0',
              zIndex: 100000, // 确保容器位于按钮之上
              width:'400px',
              // 添加其他所需的样式
            }}
            onClick={(e) => e.stopPropagation()} // 防止容器内的点击事件冒泡
          >

        <Paper style={{
          padding: '20px',
          margin: '10px',
          backgroundColor:get_color_by_name('pop-window-bg'),
          border: `1px solid ${get_color_by_name('pop-window-border-color')}`,
        }} elevation={3}>
          <Grid container spacing={2}>
            <Grid item xs={6} style={buttonGridStyle}>
              <CustomButton
                onClick={handleGetCommonChatSessionByClassId}
                onTouchEnd={(e) => {
                  e.preventDefault(); // 阻止默认行为和冒泡，根据需要调整
                  handleGetCommonChatSessionByClassId(e);
                }}
                size="medium"
                startIcon={<CategoryIcon />}
                sx={{width:'170px'}}>
                {t('load category')}
              </CustomButton>
            </Grid>
            <Grid item xs={6} style={buttonGridStyle}>
              <CustomButton
                onClick={handleAddClass}
                onTouchEnd={(e) => {
                  e.preventDefault(); // 阻止默认行为和冒泡，根据需要调整
                  handleAddClass();
                }}
                size="medium"
                startIcon={<AddCardIcon />}
                sx={{width:'170px'}}>
                {t('add to category')}
              </CustomButton>
            </Grid>
            <Grid item xs={6}>
              <CustomButton
                onClick={handleShowCommonChatList}
                onTouchEnd={(e) => {
                  e.preventDefault(); // 阻止默认行为和冒泡，根据需要调整
                  handleShowCommonChatList();
                }}
                size="medium"
                startIcon={<AddCardIcon />}
                sx={{width:'170px'}}>
                {t('history')}
              </CustomButton>
            </Grid>
            <Grid item xs={6}>
              <CustomButton
                onClick={handleSystemHelp}
                onTouchEnd={(e) => {
                  e.preventDefault(); // 阻止默认行为和冒泡，根据需要调整
                  handleSystemHelp();
                }}
                size="medium"
                startIcon={<QuizIcon />}
                sx={{width:'170px'}}>
                {t('functionality help')}
              </CustomButton>
            </Grid>

            <Grid container spacing={2} direction="column" alignItems="center" sx={{marginTop:'10px'}}>
             
            <Grid item>
              <h2><span style={{color:'black'}}>{t('type')}</span></h2>
            </Grid>
            <Grid item container justifyContent="center" spacing={1}>
            {subjectOptions.map((option) => (
                <Grid item key={option.id}>

                  <Button
                   onClick={() => onListItemClick(option.id)}
                    onTouchEnd={(e) => {
                      e.preventDefault(); // 阻止默认行为和冒泡，根据需要调整
                      onListItemClick(option.id);
                    }}
                    sx={{
                      fontFamily:'siyuan',
                      border: `1px solid ${get_color_by_name('pop-window-border-color')}`,
                      backgroundColor: selectedSubjectOptionText === option.id ? get_color_by_name('mul-button-bg-selected') : get_color_by_name('mul-button-bg'),
                      color: selectedSubjectOptionText === option.id ? get_color_by_name('mul-button-color-selected') : get_color_by_name('mul-button-color'),
                      '&:hover': {
                        backgroundColor: selectedSubjectOptionText === option.id ? 'darkgreen' : 'lightgrey',
                      },
                    }}
                  >
                    {option.text}
                  </Button>
                </Grid>
              ))}
            </Grid>
        </Grid>

          
            <Grid item xs={12} display="flex" justifyContent="center">
            <CustomButton
              onClick={() => setIsSettingButtonOpen(false)}
              
                onTouchEnd={(e) => {
                  e.preventDefault(); // 阻止默认行为和冒泡，根据需要调整
                  setIsSettingButtonOpen(false);
                }}
                size="medium"
                startIcon={<AddCardIcon />}
                sx={{width:'170px'}}
              >
                {t('close')}
              </CustomButton>
            </Grid>
          </Grid>
        </Paper>
      </div>
    )}
      </div>
      </Draggable>
      {/**悬浮按钮代码----结束 */}

      <Modal
          open={showAddClass}
          onClose={handleCloseCommonChatClass}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          sx={{zIndex:5555}}
        >
      <Paper 
        style={{
          zIndex: 5555,
          display: 'flex',
          flexDirection: 'column', // 由于我们希望内容垂直堆叠，需要设置flex方向
          backgroundColor: 'white',
          position: 'fixed',
          left: '50%',
          top: '50%',
          height:'600px',
          width:'800px',
          transform: 'translate(-50%, -50%)',
          margin: '20px',
          padding: '20px',
          backgroundColor: '#f0f0f0'
        }} 
      elevation={3}
    >
        <div style={{marginTop:'20px', maxHeight: '400px', overflowY: 'auto' }}>
        <TableContainer component={Paper}>
        <Table stickyHeader aria-label="customized table">
          <TableHead>
            <TableRow>
              <TableCell width="100px">{t('select')}</TableCell> {/* 使用width属性设置宽度 */}
              <TableCell>{t('category')}</TableCell>
              <TableCell>{t('description')}</TableCell>
              <TableCell>{t('delete')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {commonChatClassList.map((row, index) => (
              <TableRow
                key={index}
                hover
                onClick={() => handleSelectRow(index)}
                selected={selectedRowIndex === index}
                style={{ cursor: 'pointer' }}
              >
                <TableCell padding="checkbox" style={{ width: '100px' }}> {/* 使用style属性设置宽度 */}
                  <Checkbox
                    checked={selectedRowIndex === index}
                    onClick={(e) => e.stopPropagation()}
                  />
                </TableCell>
                <TableCell>{row.class_name}</TableCell>
                <TableCell>{row.description}</TableCell>
                <TableCell>
                <IconButton aria-label="delete" color="primary" onClick={(event) => {event.stopPropagation(); handleDeleteChatClass(row.id);}}>
                  <DeleteIcon />
                </IconButton>
              </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        </TableContainer>
        </div>

        <Pagination
          sx={{ backgroundColor: 'black', borderRadius: '10px' }}
          count={totalPages}
          page={currentPageCommonChatClass}
          onChange={handleChangePageCommonChatClass}
          color="primary"
        />
        

        <Box style={{ display: 'flex', justifyContent: 'flex-end', padding: '10px' }}>
          <CustomButton onClick={() => setShowAddNewChatClass(true)} style={{ marginRight: '10px' }}>{t('add new category')}</CustomButton>
          <CustomButton onClick={handleCloseCommonChatClass} style={{ marginRight: '10px' }}>{t('close')}</CustomButton>
          <CustomButton onClick={getSelectedData}>{t('confirm')}</CustomButton>
        </Box>
    </Paper>
    
      </Modal>

      <Modal
        open={showCommonChatSessionClass}
        onClose={onCloseCommonChatSessionClass}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{zIndex:5555}}
      >
        <ChatCommonSessionClassComponent
          commonChatSessionClassName={selectedCommonChatSessionClassName}
          commonChatSessionClassId={selectedCommonChatSessionClassID} 
          onClose={onCloseCommonChatSessionClass} 
          onConfirm={onConfirmCommonChatSessionClass}
          onHiddenClassDetail={handleOnHiddenClassDetail}
        />
      </Modal>

      <Modal
        open={showAddNewChatClass}
        onClose={() => setShowAddNewChatClass(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          zIndex: 5555,
        }}
      >
        <Box
          sx={{
            width: 500,
            height: 400,
            bgcolor: 'background.paper',
            borderRadius: 2,
            p: 4,
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Typography variant="h6" component="h2" id="modal-modal-title" sx={{ mb: 2 ,color:'black'}}>
            {t('add new category')}
          </Typography>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: 2, // Adjusts space between items
            }}
          >
            <TextField
              label={t('category name')}
              sx={{backgroundColor:'white', color:'black'}}
              fullWidth
              multiline
              variant="outlined"
              value={addClassName}
              onChange={(event) => setAddClassName(event.target.value)}
              autoFocus
            />
            <TextField
              label={t('description')}
              sx={{backgroundColor:'white', color:'black'}}
              fullWidth
              multiline
              variant="outlined"
              value={addClassDes}
              onChange={(event) => setAddClassDes(event.target.value)}
            />
          </Box>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-end',
              paddingTop: '20px', // Adjust as needed
              mt: 'auto', // Pushes the buttons to the bottom
            }}
          >
            <CustomButton onClick={() => setShowAddNewChatClass(false)} sx={{ marginRight: '10px' }}>
              {t('close')}
            </CustomButton>
            <CustomButton onClick={handleAddNewClass}>
              {t('confirm')}
            </CustomButton>
          </Box>
        </Box>
      </Modal>


    {isLatexModalVisible &&
        <Overlay>
          <ModalContainer>
            <LatexModule
              onClose={() => setLatexModalVisible(false)}
              onConfirm={handleDocumentUpdate}
              subject='mathematics'
              ocrType='subject'
              latexString={latexString}
            />
          </ModalContainer>
        </Overlay>
      }
      

      <Box sx={{ position: 'fixed', bottom: 0, right: 0, zIndex: 8888 }}>

        <Button
          onClick={handleClearRecord}
            onTouchEnd={(e) => {
              e.preventDefault(); // 阻止默认行为和冒泡，根据需要调整
              handleClearRecord();
            }}
            sx={{
              position: 'absolute', 
              bottom: '33vh', 
              right: 60,
              fontFamily:'siyuan',
              border: `1px solid ${get_color_by_name('pop-window-border-color')}`,
              backgroundColor: get_color_by_name('mul-button-bg'),
              color: get_color_by_name('mul-button-color'),
              
            }}
          >
            开始新对话
          </Button>
      </Box>
    </div>
  );
};

export default ScienceC;