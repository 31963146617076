import React, { useState, useEffect, useRef } from 'react';
import { Paper, Container,Card, CardActionArea, CardContent, CardMedia, Typography, Grid, useMediaQuery, useTheme,Divider } from '@mui/material';
import { List, ListItem, ListItemIcon, ListItemText,Box } from '@mui/material';
import ScienceIcon from '@mui/icons-material/Science';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import CalculateIcon from '@mui/icons-material/Calculate';
import PublicIcon from '@mui/icons-material/Public';
import BiologyIcon from '@mui/icons-material/Grass'; // 生物
import ChemistryIcon from '@mui/icons-material/Opacity'; // 化学
import LanguageIcon from '@mui/icons-material/Translate'; // 语文
import EnglishIcon from '@mui/icons-material/Language'; // 英语
import { useTranslation } from 'react-i18next';
import IconButton from '@mui/material/IconButton';
import LoopIcon from '@mui/icons-material/Loop'; // 循环播放图标

import { getHostName } from '../utils/urlUtils';

import PauseIcon from '@mui/icons-material/Pause';
import Plotly from 'plotly.js-dist';
import * as $3Dmol from '3dmol';
import { requestUrl } from '../requestUrl';
import rehypeRaw from 'rehype-raw'; // 引入 rehype-raw 插件
import 'katex/dist/katex.min.css';
import katex from 'katex';
import remarkMath from 'remark-math';
import rehypeKatex from 'rehype-katex';
import ReactMarkdown from 'react-markdown';
import './default.css';
import VideoModalPlayer from '../components/VideoModalPlayer';
import 'katex/dist/katex.min.css';
import { getHostNameWeb } from '../utils/urlUtils';
 import { getColorDark,getColorLight } from '../ColorManager';
 import { css, keyframes } from '@emotion/react';

const Default = () => {
    const { t } = useTranslation();
  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.down('md'));
  const [isLoading, setIsLoading] = useState(false);
  const [defaultLanguage,setDefaultLanguage] = useState('en');



  const [colorManager, setColorManager] = useState(getColorDark()); // 初始化为空数组

  //获取颜色设置，系统加载时设置
useEffect(() => {

const themeName = localStorage.getItem('theme');//此处存储的是数字1-12，而不是汉字，
if (themeName) {
  
  if(themeName === "dark"){
    setColorManager(getColorDark());
  }

  
  if(themeName === "light"){
    setColorManager(getColorLight());
  }
}
else{
  let aa = getColorDark();
  setColorManager(aa);
}


}, []);



const get_color_by_name = (name) => {

const item = colorManager.find(item => item.container === name);
return item.value;
}



  const getLanguage = () => {
    let lang = localStorage.getItem("userlanguage");
    if (lang === null) {
      const browserLang = navigator.language || navigator.userLanguage;
      lang = browserLang.split('-')[0];
    } else {
      // 存在值，lang变量就是对应的值
      console.log("User language set in localStorage:", lang);
    }

    setDefaultLanguage(lang);
  }


  useEffect(() => {
    getLanguage();
  }, []);


  const handleCardClick = (subject,video) => {
    const srcv = getHostNameWeb() + "/default/" + subject+"-" + defaultLanguage + ".mp4";
    console.log(srcv);
    setVideoSrc(srcv);
    setOpen(true);
  };
  const handleClose = () => setOpen(false);
  const [open, setOpen] = useState(false);
  const [videoSrc,setVideoSrc] = useState('');





  const data = [
    {
      id: 1,
      subject:'mathematics',
      title: t('mathematics'),
      content: "数学：解锁数学的无限可能从几何图形的旋转缩放到函数的神秘图谱，我们的AI家教带你领略数学的真正魅力！动态生成的二维、三维图像，让抽象的概念形象化、直观化。数学题目让你头疼？拍张照，一切尽在掌握。我们不仅解题，更解锁你对数学的无限好奇！",
      backgroundImage: getHostNameWeb() + "/default/mathematics.png",
      video: getHostNameWeb() + '/default/mathematics.mp4',
    },
    {
      id: 2,
      subject:'physics',
      title: t('physics'),
      content: "寒冬夜晚，$y=x^2$星星在无云的天空中闪烁，宁静而深邃。",
      backgroundImage: getHostNameWeb() + "/default/physics.png",
      video: getHostNameWeb() + '/default/physics.mp4',
    },
    {
      id: 3,
      subject:'chemistry',
      title: t('chemistry'),
      content: "春天到来，大地回暖，万物复苏，花儿竞相开放，构成了一片绚烂的花海。",
      backgroundImage: getHostNameWeb() + "/default/chemistry.png",
      video: getHostNameWeb() + '/default/chemistry.mp4',
    },
    {
      id: 4,
      subject:'geography',
      title: t('geography'),
      content: "夏日炎炎，一片清凉的海滩，海浪拍打着沙滩，带来阵阵凉爽。",
      backgroundImage: getHostNameWeb() + "/default/geography.png",
      video: getHostNameWeb() + '/default/geography.mp4',
    },
    {
      id: 5,
      subject:'biology',
      title: t('biology'),
      content: "夕阳西下，金色的阳光洒在古老的城墙上，历史的沧桑与自然的美丽交织。",
      backgroundImage: getHostNameWeb() + "/default/biology.png",
      video: getHostNameWeb() + '/default/biology.mp4',
    },
    {
      id: 6,
      subject:'english',
      title: t('english'),
      content: "在遥远的乡村，远离城市的喧嚣，仰望夜空，繁星点点，如梦如幻。",
      backgroundImage: getHostNameWeb() + "/default/english.png",
      video: getHostNameWeb() + '/default/english.mp4',
    },
  ];


  const dataother = [
    {
      id: 1,
      type:'knowledge',
      title: t('repository of knowledge'),
      content: "深秋的森林，树叶变成了金色，一片宁静而美丽的景象。",
      backgroundImage: getHostNameWeb() + "/default/knowledge.png",
      video: getHostNameWeb() + '/default/knowledge.mp4',
    },
    {
      id: 2,
      type:'feynman',
      title: t('feynman simulation'),
      content: "寒冬夜晚，星星在无云的天空中闪烁，宁静而深邃。",
      backgroundImage: getHostNameWeb() + "/default/feynman.png",
      video: getHostNameWeb() + '/default/feynman.mp4',
    },
    {
      id: 3,
      type:'picturebook',
      title: t('interactive picture book'),
      content: "春天到来，大地回暖，万物复苏，花儿竞相开放，构成了一片绚烂的花海。",
      backgroundImage: getHostNameWeb() + "/default/picturebook.png",
      video: '/default/picturebook.mp4',
    }
  ];

  //const [contentPage,setContentPage] = useState([]);

  const contentPage = [
    {
      title: `🚀${t('Illuminating the Path to Academic Excellence - Changing Not Only Learning, but the Future')}!🚀`,
      content: `${t('mindvista AI Tutor - Leading the Learning Revolution, Achieving Every Child is Dream of Being a School Master')}`,
    
    },
    {
      title: `🌟${t('All-round scientific tutoring to master the essence of academics')}`,
      content: `${t('From <Tale of Two Cities> to Quantum Physics')}**,${t('covering Language, Math, English, Physics, Chemistry, Geography, Biology, only you can not think of it, no we can not do i')}`
    },
    {
      title: `🌟${t('Local Knowledge Base, Connecting Infinite Possibilities')}`,
      content: `${t('Movies, documents, courses, document conversations, summaries, all in one place')}`
    },
    {
      title: `🌟${t('All-round scientific tutoring to master the essence of academics')}`,
      content: `${t('Feynman simulation, reverse thinking to start the learning mode! The Strongest Learning Method on the Earth')}`
    }
  ];


  const contentmath = `
  ## ${t('Math: Unlocking the Infinite Possibilities of Math')}
  #### ${t('Dynamically generated 2D and 3D interactive images to visualize and visualize abstract concepts')}
  #### ${t('Math problems giving you a headache? Take a picture and it is all under control')}
  #### 💡${t('We do not just solve problems, we unlock your endless curiosity about math')}！💡`;

  const contentphy = `
  ## ${t('Physics: Exploring the Mysteries of Physics in Animation')}
  #### ${t('The forces at work, the wonders of motion, are all there for you to explore in our AI tutor is animation')}！
  #### ${t('Change the rules of the physical world with your own hands by modifying the movement parameters and feel the real power behind every theory')}
  #### ${t('Physics is no longer a puzzle, it is a fun adventure!')}！`;
  const contentche = `
  ## ${t('Chemistry: Architects of the Molecular World')}
  #### ${t('Organic, Inorganic, Crystal Structures ......')}
  #### 💡${t('Every corner of the chemical world is full of endless possibilities')}💡
  #### ${t('Our AI tutor makes you an architect of the molecular world')}`;
  const contentbio = `
  ## ${t('Biology: Explorers of the Mysteries of Life')}
  #### ${t('The microcosm of the cell, the complex network of ecosystems')}
  #### ${t('Our AI tutors will take you deeper into the mysteries of life')}！`;
  const contentgeo = `
  ## ${t('Geography: Discovering Every Secret of the Earth')}
  #### ${t('From mountains and rivers to cities and villages, our AI tutors take you around the world,...')}
  #### 💡${t('Explore every corner of the planet. Topography, Climate, Resources')}💡`;

  const contenteng = `
  ## ${t('English: Start Your English Speaking Journey')}
  #### ${t('Forget about boring memorization and ditch the hard conversations')}!
  #### ${t('Our AI tutors use the most authentic scenes and the most practical conversations to take you on a relaxing and enjoyable English speaking journey')}
  `;

  const huanmingjieContent = `
  ${t('A torus knot is a closed curve that winds along the surface of the torus in three dimensions, which can be defined by two parameters p and q, which represent the number of times the curve winds around the major and minor circles of the torus surface, respectively')}
  
  ${t('The mathematical description of a ring surface knot can be expressed by the following parametric equations')}:
  
  $$
  \\begin{align*}
  x(u, v) &= (R + r \\cos(qv)) \\cos(pv) \\\\
  y(u, v) &= (R + r \\cos(qv)) \\sin(pv) \\\\
  z(u, v) &= r \\sin(qv)
  \\end{align*}
  $$
  
  ${t('Vector normalization involves scaling the length of a vector to 1, keeping its direction constant. For any non-zero vector')}$\\vec{v} = (x, y, z)$,${t('its normalized vector')}$\\vec{v}_{norm}$${t('The formula for this is')}:
  
  $$
  \\vec{v}_{norm} = \\frac{\\vec{v}}{|\\vec{v}|} = \\left( \\frac{x}{\\sqrt{x^2 + y^2 + z^2}}, \\frac{y}{\\sqrt{x^2 + y^2 + z^2}}, \\frac{z}{\\sqrt{x^2 + y^2 + z^2}} \\right)
  $$
  
  ${t('In three-dimensional space, rotation about any axis can be realized by means of a rotation matrix. For example, a rotation of one angle around the x-axis')}$$\\theta$$${t('The rotation matrix is')}:

  $$
  R_x(\\theta) = 
  \\begin{pmatrix}
  1 & 0 & 0 \\\\
  0 & \\cos(\\theta) & -\\sin(\\theta) \\\\
  0 & \\sin(\\theta) & \\cos(\\theta)
  \\end{pmatrix}
  $$
  `;

  const knowledge = `
  ## ${t('Private Education Knowledge Base - Your Personalized Learning Space')}
  #### ${t('Embark on a new era of learning! Our Private Education Knowledge Base allows parents and students to upload documents, audio, and video materials, facilitating document dialogue and knowledge point extraction,')}
  #### ${t('making learning more personalized and efficient. Audio and video transcripts with timelines allow for on-demand playback based on the timeline, image OCR recognition,')}
  #### ${t('and support for text rendering of all formulas in mathematics, physics, chemistry—everything at your fingertips.')}
  #### ${t('We also support resuming uploads of large files (up to 600MB), making learning limitless and knowledge accessible anytime, anywhere.')}

  `;

  const feynman = `
  ## ${t('Feynman Simulation - Become Your Own Teacher')}
  #### ${t('Utilizing the Feynman Technique, our app transforms AI into learners with difficulties, allowing students to take on the "teacher" role.')}
  #### ${t('Through multiple rounds of interactive dialogue, students can delve deeply into explaining concepts, a process that not only solidifies their understanding,')}
  #### ${t('but also, through our intelligent analysis, provides a summary and recommendations on the level of knowledge mastery.')}
  #### ${t('This is a revolutionary learning method that makes mastering knowledge more profound and effective.')}  
  `;

  const picturebook = `
  ## ${t('Interactive Picture Books - A Journey of Knowledge within Stories')}
  #### ${t('A book that interacts with children, telling not just stories but also incorporating knowledge points within them.')}
  #### ${t('Our interactive picture books create such a magical world, complete with sound, images, videos, and even background music,')}
  #### ${t('allowing children to learn and explore in an environment of their native language or English. Each grade level has its own picture book, making learning vivid and fun through stories.')}
  #### ${t('Even more exciting, children can ask questions via voice and receive instant voice responses, turning learning into a wonderful adventure.')}  
  `

  
  const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const [contentData,setContentData] = useState([]);

useEffect(() => {
  // 假设 fetchData 是一个获取数据的异步函数
  const fetchData = async () => {
      try {
          //setLoading(true);
          // /setLoadingText("loading");
          const dataToSend = {
              id:1,
          };
          const data = await requestUrl(dataToSend, "get_default_1");
          if(data.status === "success"){
            setContentData(data.data);
            //setContentPage(data.contentPage);
          }
        } 
        catch (error) {
            console.error("获取数据时出错:", error);
        }
        finally {
          //setLoading(false);
        }
  };

  fetchData();
}, []);

  const imageSrc = 'https://via.placeholder.com/150'; 
  return (
    <Paper sx={{backgroundColor:get_color_by_name('navigator-bg')}} style={{ padding: 20, backgroundColor: get_color_by_name('navigator-bg') }}>

      <Box sx={{ padding: '16px' ,textAlign:'center',border: `1px solid ${get_color_by_name('pop-window-border-color')}`}}>
      <div style={{textAlign:'center',fontSize:'30px',fontWeight: 'bold',color:get_color_by_name('headline-color')}}>---智谷AI家教-全学科---</div>
      <Grid container spacing={2} sx={{marginTop:'20px'}}>
        <Grid item xs={8}>
          {contentPage.map((item, index) => (
            <Box key={index} 
              sx={{ marginBottom: '16px', 
              border: `1px solid ${get_color_by_name('pop-window-border-color')}`,
              backgroundColor:get_color_by_name('mui-textfield-bg'),
              borderRadius:'15px',
            }}
              >
              <Typography sx={{marginTop:'20px', color: get_color_by_name('headline-color') }} variant="h5" component="h1" gutterBottom>{item.title}</Typography>
              
              <Divider sx={{ 
                  marginY: 2, 
                  borderColor: get_color_by_name('paragraphs-line-color'),
                  opacity: 0,
                  animation: `${fadeIn} 2s forwards`,// 应用动画
                  width: '50%', // 设置宽度
                  marginX: 'auto' // 使分隔线居中
                }} />
              <Typography sx={{ color: get_color_by_name('headline-color') }} variant="h7" component="h2" gutterBottom>{item.content}</Typography>
            </Box>
          ))}
        </Grid>
        <Grid item xs={4}>
          <img src='default/feynman.png' alt="content" style={{ maxWidth: '100%' }} />
        </Grid>
      </Grid>
    </Box>

      <div 
        
        style={{
          backgroundColor:get_color_by_name('navigator-bg'), 
          maxWidth:'100vw',
          marginTop:'10px',
          
          padding:'10px',
          border: `1px solid ${get_color_by_name('pop-window-border-color')}`,}}>
            <div style={{textAlign:'center',fontSize:'30px',fontWeight: 'bold',color:get_color_by_name('headline-color')}}>---智谷信奥---</div>
            {contentData.map((item, index) => (
        <Card key={index} 
          sx={{ 
            mb: 4, backgroundColor: get_color_by_name('mui-textfield-bg'), borderRadius: '20px', marginTop: '20px' ,
            border: `1px solid ${get_color_by_name('pop-window-border-color')}`}}>
          <CardContent>
            <Grid container spacing={2} alignItems="center" justifyContent="center">
              <Grid item>
                <Box
                  component="img"
                  src={`default/${item.imagesrc}`}
                  alt="AI 教育"
                  sx={{ width: 120, height: 120, borderRadius: '50%' }}
                />
              </Grid>
              <Grid item xs={8} md={6}>
                <Box sx={{ textAlign: 'center' }}>
                  <Typography sx={{ color: get_color_by_name('headline-color') }} variant="h5" component="h1" gutterBottom>
                    {item.title}
                  </Typography>
                  
                  
                  <Divider sx={{ 
                  marginY: 2, 
                  borderColor: get_color_by_name('paragraphs-line-color'),
                  opacity: 0,
                  animation: `${fadeIn} 2s forwards`, // 应用动画
                  width: '100 %', // 设置宽度
                  
                }} />

                  <Typography sx={{ color: get_color_by_name('headline-color') }} variant="h6" component="h2" gutterBottom>
                    {item.title1}
                  </Typography>
                  <Typography sx={{ color: get_color_by_name('headline-color') }} variant="body1" paragraph>
                    {item.content}
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      ))}


     
    </div>



      <br />
      <Paper style={{ margin: '20px 0', padding: '20px', background: '#e0e0e0', display: 'flex', position: 'relative' }}>
      <iframe
        src={getHostNameWeb() + '/jsbg/trefoil-knot-inside-torus/dist/index.html'}
        style={{
          width: '50%', // 现在占据容器宽度的一半
          height: '550px',
          border: 'none', // 去除边框
        }}
      ></iframe>
      <div style={{
        background: '#222',
        width: '50%', // 也占据容器宽度的一半
        display: 'flex',
        alignItems: 'center', // 如果需要，可以调整对齐方式
        justifyContent: 'center', // 根据需要调整
      }}>
        <div style={{ wordWrap: 'break-word', overflowY: 'auto', padding: '10px', maxWidth: '100%', color: 'rgba(255, 255, 255, 0.7)' }} className="centered-markdown">
          <ReactMarkdown
            children={huanmingjieContent}
            remarkPlugins={[remarkMath]}
            rehypePlugins={[rehypeRaw, rehypeKatex]} // 注意插件的顺序，rehypeRaw 先于 rehypeKatex
          />
        </div>
      </div>
    </Paper>
               
      <Grid container spacing={2}>
        {data.map(({ id,subject, title, content, backgroundImage,video }) => (
          <Grid item xs={12} sm={6} md={4} key={id}>
            <Card style={{height:'450px'}}>
              <CardActionArea onClick={() => handleCardClick(subject,video)}>
                <CardMedia
                  component="img"
                  height="250"
                  image={backgroundImage}
                  alt={title}
                />
                <CardContent>
                  
                  <Typography variant="body2" color="text.secondary">
                  <div style={{ backgroundColor:'#2a3d66', wordWrap: 'break-word', overflowY: 'auto', padding: '10px', maxWidth: '70vw' }} className="centered-markdown">
                    {subject === 'mathematics'&&  (
                    <ReactMarkdown
                        children={contentmath}
                        remarkPlugins={[remarkMath]}
                        rehypePlugins={[rehypeRaw, rehypeKatex]} // 注意插件的顺序，rehypeRaw 先于 rehypeKatex
                    />
                    )}
                    {subject === 'english'&&  (
                    <ReactMarkdown
                        children={contenteng}
                        remarkPlugins={[remarkMath]}
                        rehypePlugins={[rehypeRaw, rehypeKatex]} // 注意插件的顺序，rehypeRaw 先于 rehypeKatex
                    />
                    )}
                    {subject === 'physics'&&  (
                    <ReactMarkdown
                        children={contentphy}
                        remarkPlugins={[remarkMath]}
                        rehypePlugins={[rehypeRaw, rehypeKatex]} // 注意插件的顺序，rehypeRaw 先于 rehypeKatex
                    />
                    )}
                    {subject === 'chemistry'&&  (
                    <ReactMarkdown
                        children={contentche}
                        remarkPlugins={[remarkMath]}
                        rehypePlugins={[rehypeRaw, rehypeKatex]} // 注意插件的顺序，rehypeRaw 先于 rehypeKatex
                    />
                    )}
                    {subject === 'biology' && (
                    <ReactMarkdown
                        children={contentbio}
                        remarkPlugins={[remarkMath]}
                        rehypePlugins={[rehypeRaw, rehypeKatex]} // 注意插件的顺序，rehypeRaw 先于 rehypeKatex
                    />
                    )}
                    {subject === 'geography'&&  (
                    <ReactMarkdown
                        children={contentgeo}
                        remarkPlugins={[remarkMath]}
                        rehypePlugins={[rehypeRaw, rehypeKatex]} // 注意插件的顺序，rehypeRaw 先于 rehypeKatex
                    />
                    )}
                  </div>
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>
        ))}
      </Grid>

      <br/>
      <Grid container spacing={2}>
        {dataother.map(({ id,type, title, content, backgroundImage,video }) => (
          <Grid item xs={12}>
            <Card>
              <CardActionArea onClick={() => handleCardClick(video)}>
                <CardMedia
                  component="img"
                  height="240"
                  image={backgroundImage}
                  alt={title}
                />
                <CardContent>
                  
                  <Typography variant="body2" color="text.secondary">
                 
                     {type === 'knowledge'&&  (
                      <div style={{backgroundColor:'#2a3d66', wordWrap: 'break-word', overflowY: 'auto', padding: '10px', maxWidth: '100vw' }} className="centered-markdown">
                        <ReactMarkdown
                          children={knowledge}
                          remarkPlugins={[remarkMath]}
                          rehypePlugins={[rehypeRaw, rehypeKatex]} // 注意插件的顺序，rehypeRaw 先于 rehypeKatex
                        />
                        </div>
                    )}
                     {type === 'feynman'&&  (
                      <div style={{backgroundColor:'#2a3d66', wordWrap: 'break-word', overflowY: 'auto', padding: '10px', maxWidth: '100vw' }} className="centered-markdown">
                        <ReactMarkdown
                          children={feynman}
                          remarkPlugins={[remarkMath]}
                          rehypePlugins={[rehypeRaw, rehypeKatex]} // 注意插件的顺序，rehypeRaw 先于 rehypeKatex
                        />
                      </div>
                    )}
                     {type === 'picturebook'&&  (
                    <div style={{backgroundColor:'#2a3d66', wordWrap: 'break-word', overflowY: 'auto', padding: '10px', maxWidth: '100vw' }} className="centered-markdown">
                      <ReactMarkdown
                        children={picturebook}
                        remarkPlugins={[remarkMath]}
                        rehypePlugins={[rehypeRaw, rehypeKatex]} // 注意插件的顺序，rehypeRaw 先于 rehypeKatex
                      />
                    </div>
                    )}

                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>
        ))}
      </Grid>

    <VideoModalPlayer open={open} handleClose={handleClose} videoSrc={videoSrc} />
    </Paper>
  );
};

export default Default;
