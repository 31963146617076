import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import zhTranslation from './locales/zh/translation.json';
import koTranslation from './locales/ko/translation.json';
import inTranslation from './locales/in/translation.json';
import jaTranslation from './locales/ja/translation.json';
import ruTranslation from './locales/ru/translation.json';
const userLang = navigator.language || navigator.userLanguage;
let defaultLang = userLang.startsWith('zh') ? 'zh' : 'en';

const browserLang = navigator.language || navigator.userLanguage;
defaultLang = browserLang.split('-')[0];


console.log("User language:", userLang);
console.log("Default language:", defaultLang);

i18n
  .use(initReactI18next)
  .init({
    resources: {
      zh: {
        translation: zhTranslation,
      },
      ko: {
        translation: koTranslation,
      },
      in: {
        translation: inTranslation,
      },
      ja: {
        translation: jaTranslation,
      },
      ru: {
        translation: jaTranslation,
      },
    },
    lng: defaultLang,
    fallbackLng: 'en',  // 如果找不到翻译，回退到英语
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
