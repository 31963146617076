import React, { useState,useRef ,useEffect} from 'react';
import GeoGebra from 'react-geogebra';
import './GeoGebraComponent.css';
import styled from 'styled-components';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import FullscreenExitIcon from '@mui/icons-material/FullscreenExit';
import SaveIcon from '@mui/icons-material/Save';
import { requestUrl } from '../../requestUrl';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from '../../SnackbarProvider';
const CenteredDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 1100px; // 默认宽度
  height: 700px; // 默认高度
  margin: auto;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  @media (max-width: 768px) {
    width: 650px;
    height: 500px;
  }

  @media (max-width: 480px) {
    width: 90vw;
    height: 70vh;
  }
`;



// 创建一个容器来包裹按钮，使它们水平排列并靠右对齐
const ButtonContainer = styled.div`
  background-color:white;
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin-top: 20px; // 根据需要调整按钮与GeoGebra控件的间距
`;


function GeoGebraGraph({ drawCommandString, appName, language ,xmlCode,onCloseMathGeo,dbid}) {
  const [appXml, setAppXml] = useState();
  const [isFullScreen, setIsFullScreen] = useState(false);
  const centeredDivRef = useRef(null);
  const [size, setSize] = useState({ width: 1100, height: 700 });
  const { t } = useTranslation();
  const showSnackbar = useSnackbar();
  const afterAppIsLoaded = () => {
    // 将命令字符串分割成单独的命令
    let aaa = "A = (0, 0);B = (0, 2);C = (3, 2);D = (3, 0);Polygon[A, B, C, D]";


    //const commands = drawCommandString.split(';');
    const commands = aaa.split(';');
    commands.forEach(command => {
      window.ggbApplet.evalCommand(command.trim());
    });
  };

  
  const afterAppIsLoaded1 = () => {
    // 将命令字符串分割成单独的命令
    let aaa = "A = Point[0, 0];C = Point[2, 0];B = Point[4, 0];D = Point[2, 2];E = Point[2, -2];F = Point[2, 0];Segment[A, B];Segment[B, C];Segment[C, D];Segment[D, A];Segment[D, E];Segment[E, C];Segment[C, F];";

    //const commands = drawCommandString.split(';');
    const commands = aaa.split(';');
    commands.forEach(command => {
      window.ggbApplet.evalCommand(command.trim());
    });
    
    

    if (window.ggbApplet && xmlCode) {
        window.ggbApplet.setXML(xmlCode);
      }
  };

  const updateStylesForFullScreen = () => {
    const element = centeredDivRef.current;
    if (element) {
      let current = element;
      // 遍历所有父元素，为它们设置全屏样式
      while (current) {
        current.style.width = '100vw';
        current.style.height = '100vh';
        current.style.position = 'fixed';
        current.style.top = '0';
        current.style.left = '0';
        current.style.margin = '0';
        current.style.zIndex = '9999';
        current.style.transform = 'none';
        current = current.parentElement;
      }
      // 单独设置当前元素的 zIndex，以确保它在最前面
      element.style.zIndex = '9999';
    }
  }
  
  const resetStyles = () => {
    const element = centeredDivRef.current;
    if (element) {
      let current = element;
      // 遍历所有父元素，恢复它们的原始样式
      while (current) {
        current.style.width = '';
        current.style.height = '';
        current.style.position = '';
        current.style.top = '';
        current.style.left = '';
        current.style.margin = '';
        current.style.zIndex = '';
        current.style.transform = '';
        current = current.parentElement;
      }
    }
  }
  
  
  
  const handleFullScreen1 = () => {
    setIsFullScreen(!isFullScreen); // 这将触发组件的重新渲染
  
    if (!isFullScreen) {
      // 这里使用 setTimeout 确保状态更新和组件重新渲染后执行
      setTimeout(() => {
        updateStylesForFullScreen();
      }, 0);
    } else {
      resetStyles();
    }
  }
  
  const handleGetCode = () => {
    if (window.ggbApplet) {
      const objectNames = window.ggbApplet.getAllObjectNames();
      const commands = objectNames.map(name => window.ggbApplet.getCommandString(name));
      const filteredCommands = commands.filter(command => command);


      let xmls = window.ggbApplet.getXML();
      setAppXml(xmls);
      console.log(xmls);
    }
  };

  const handleSetCode = () => {
    if (window.ggbApplet && appXml) {
      window.ggbApplet.setXML(appXml);
    }
  };

  //保存
  const handleSaveImage = async () => {
    if (window.ggbApplet) {
        const objectNames = window.ggbApplet.getAllObjectNames();
        const commands = objectNames.map(name => window.ggbApplet.getCommandString(name));
        const filteredCommands = commands.filter(command => command);
        console.log(filteredCommands);
  
        let xmls = window.ggbApplet.getXML();
        setAppXml(xmls);
        console.log(xmls);

        try {
            const dataToSend = {
              common_chat_id:dbid,
              geo_xml:xmls,
              appname:'geometry',
            };
            const response = await requestUrl(dataToSend, "save_geogebra_xml",t,showSnackbar);
            if (response) {
              let data = response;
              
            }
          
          } catch (error) {
            // 处理请求错误
            console.error("Error fetching data:", error);
          }
      }
  };
  
  useEffect(() => {
    const updateSize = () => {
      const screenWidth = window.innerWidth;

      if (screenWidth <= 767) {
        // 适用于手机的尺寸
        setSize({ width: screenWidth * 0.9, height: window.innerHeight * 0.7 });
      } else if (screenWidth <= 1024) {
        // 适用于平板的尺寸
        setSize({ width: 650, height: 500 });
      } else {
        // 默认尺寸，适用于电脑
        setSize({ width: 1000, height: 600 });
      }


    };

    window.addEventListener('resize', updateSize);
    updateSize();  // 初始化尺寸

    return () => window.removeEventListener('resize', updateSize);
  }, []);

  return (
    <CenteredDiv ref={centeredDivRef}>
        <ButtonContainer>
        <button onClick={handleGetCode}>获取图形代码</button>
        <button onClick={handleSetCode}>设置图形代码</button>
        <IconButton aria-label="save" onClick={handleSaveImage}>
            <SaveIcon />
        </IconButton>
        <IconButton onClick={onCloseMathGeo}>
              <CloseIcon />
            </IconButton>
      </ButtonContainer>

    <GeoGebra
        width={size.width}
        height={size.height}
        showMenuBar={false}
        language={language}
        appName={appName}
        appletOnLoad={afterAppIsLoaded}
    />
    </CenteredDiv>
  );
}

export default GeoGebraGraph;
