import React, { useState, useRef, useEffect } from 'react';
import styled from 'styled-components';
import 'katex/dist/katex.min.css';
import MathQuill from 'react-mathquill';
import { LatexTemplateModule } from './LatexTemplateModule';
import { useTranslation } from 'react-i18next';
import PhotoComponent from '../LatexModalComponent/PhotoComponent';
import 'react-quill/dist/quill.snow.css';
import { Button, IconButton,Box  } from '@mui/material';
import { addStyles } from 'react-mathquill'
import PhotoCamera from '@mui/icons-material/PhotoCamera';
import EditorNoClassName from '../../mquill4/EditorNoClassName';
import { Gesture } from '@mui/icons-material';
import '../../mquill4/index.css';
import HandOcr from './HandOcr';
import Modal from '@mui/material/Modal';
import GavelIcon from '@mui/icons-material/Gavel';
import LayersIcon from '@mui/icons-material/Layers';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import { useSnackbar } from '../../SnackbarProvider';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import InputAdornment from '@mui/material/InputAdornment';
import CustomButton from '../../components/CustomButton';

import { getColorDark,getColorLight } from '../../ColorManager';
addStyles()

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
  position: relative;
  background-color: white;
`;

const Row = styled.div`
  display: flex;
  flex-direction: column;
  border: ${props => (props.bordered ? '1px solid gray' : 'none')};
  padding: ${props => (props.bordered ? '4px' : '0')};
  gap: 10px;  /* Increased gap */
`;

const SubjectContentDiv = styled(Row)`
  label: SubjectContentDiv;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const DocumentContentDiv = styled.div`
  border: 1px solid skyblue;
  padding: 10px;
  flex: 1;
  height: 500px;
  overflow-x: auto;
  display: flex;           // 添加这一行
  justify-content: center; // 添加这一行
`;


const CUSTOM_OPERATORS = [
  ["\\pm", "\\pm"],
  ["\\sqrt{x}", "\\sqrt"],
  ["\\sqrt[3]{x}", "\\sqrt[3]{}"],
  ["\\sqrt[n]{x}", "\\nthroot"],
  ["\\frac{x}{y}", "\\frac"],
  ["\\sum^{s}_{x}{d}", "\\sum"],
  ["\\prod^{s}_{x}{d}", "\\prod"],
  ["\\coprod^{s}_{x}{d}", "\\coprod"],
  ["\\int^{s}_{x}{d}", "\\int"],
  ["\\binom{n}{k}", "\\binom"]
];

export const LatexModule = ({ onClose,onConfirm,ocrType,subject,latexString }) => {
  const { t } = useTranslation();

  const [showLatexTemplate, setShowLatexTemplate] = useState(false);
  const [documentContent, setDocumentContent] = useState(
    '(理) 设函数 $f(x)=\\frac{2 x+a}{x^2+1}$; 函数 $g(x)=\\frac{2}{3} x^3+a x^2-2 x$ 分别在 $x=m$ 和 $x=n$ 处取得极值; 且 $m<n$ 。\n(1) 求 $f(m) \\cdot f(n)$ 的值。\n(2) 求证: $f(x)$ 在区间 $[m, n]$ 上是单调递增;');

  const inputString = 
    '(理) 设函数 $f(x)=\frac{2 x+a}{x^2+1}$; 函数 $g(x)=\frac{2}{3} x^3+a x^2-2 x$ 分别在 $x=m$ 和 $x=n$ 处取得极值; 且 $m<n$ 。(1) 求 $f(m) \cdot f(n)$ 的值。(2) 求证: $f(x)$ 在区间 $[m, n]$ 上是单调递增;';
    
    const showSnackbar = useSnackbar();

    const [operators, setOperators] = useState([]);
    const [displayHistory, setDisplayHistory] = useState(false);
    const editorRef = useRef(null);
    const [displayData, setDisplayData] = useState();
    

    const [displayDeleteButtonOnHistory,setDisplayDeleteButtonOnHistory] = useState(false);

    const options = { displayHistory, operators, displayDeleteButtonOnHistory };

    const [colorManager, setColorManager] = useState(getColorDark()); // 初始化为空数组

    //获取颜色设置，系统加载时设置
useEffect(() => {

  const themeName = localStorage.getItem('theme');//此处存储的是数字1-12，而不是汉字，
  if (themeName) {
    
    if(themeName === "dark"){
      setColorManager(getColorDark());
    }

    
    if(themeName === "light"){
      setColorManager(getColorLight());
    }
  }
  else{
    let aa = getColorDark();
    setColorManager(aa);
  }

  
}, []);



const get_color_by_name = (name) => {

  const item = colorManager.find(item => item.container === name);
  return item.value;
}



    const handleMoreClick = () => {
      setShowLatexTemplate(true);
    };

    const handleCloseLatexTemplate = () => {
      setShowLatexTemplate(false);
    };

    const handleOnConfirm = () => {
      if (editorRef.current) {
        const contents = editorRef.current.getFullContents();
        const originalString = convertToOriginalString(contents);
        setDisplayData(originalString);
        onConfirm(originalString);
      }
    };

    const handleCameraClick = () => {
      setShowPhotoComponent(true);
    };


  function processMathString(inputString) {
    let ops = [];
    let tempString = '';
    let inFormula = false;

    for (let i = 0; i < inputString.length; i++) {
      const c = inputString[i];
      if (c === '$') {
        if (inFormula) {
          ops.push({ "insert": { "formula": tempString } });
          tempString = '';
          inFormula = false;
        } else {
          if (tempString.length > 0) {
            ops.push({ "insert": tempString });
            tempString = '';
          }
          inFormula = true;
        }
      } else {
        tempString += c;
      }
    }

    if (tempString.length > 0) {
      ops.push({ "insert": tempString });
    }

    return {
      "ops": ops
    };
  }

  function convertToOriginalString(opsObject) {
    let originalString = '';
    
    opsObject.ops.forEach(op => {
      if (typeof op.insert === 'string') {
        originalString += op.insert;
      } else if (op.insert.hasOwnProperty('formula')) {
        originalString += `$${op.insert.formula}$`;
      }
    });
    
    return originalString;
  }


  const handleAddBlankLatex = () => {
    const inputString1 = '(理) 设函数 $f(x)=\\frac{2 x+a}{x^2+1}$; 函数 $g(x)=\\frac{2}{3} x^3+a x^2-2 x$ 分别在 $x=m$ 和 $x=n$ 处取得极值; 且 $m<n$ 。(1) 求 $f(m) \\cdot f(n)$ 的值。(2) 求证: $f(x)$ 在区间 $[m, n]$ 上是单调递增;';
    const initialDelta = processMathString(inputString1);

    if (editorRef.current) {
      editorRef.current.setFullContents(initialDelta);
    }
  };

  const onClosePhotoCom = (updateLatexText) => {
    setShowPhotoComponent(false);
    //setDocumentContent(updateLatexText);
    const initialDelta = processMathString(updateLatexText);

    if (editorRef.current) {
      editorRef.current.setFullContents(initialDelta);
    }
  };

  useEffect(() => {
    setOperators( CUSTOM_OPERATORS);
  
  }, []); 


  useEffect(() => {
    if(latexString){
    const initialDelta = processMathString(latexString)
    if (editorRef.current) {
      const timer = setTimeout(() => {
        editorRef.current.setFullContents(initialDelta);
      }, 500); 
      return () => clearTimeout(timer);
      //editorRef.current.setFullContents(initialDelta);
    }
  }
  }, [latexString]); // 添加 latexString 作为依赖

  const [showPhotoComponent, setShowPhotoComponent] = useState(false);

  //0220///////////////////////////////
  const [showHandOcrLatex,setShowHandOcrLatex] = useState(false);

  const handleHandwritingClick = () => {
    setShowHandOcrLatex(true);
  }
  const handleCloseHandOcr = (text) => {
    setShowHandOcrLatex(false);

    // 获取当前编辑器的内容
    const currentContents = editorRef.current ? editorRef.current.getFullContents() : {ops: []};
      
    // 创建一个新的 Delta 对象，包含新的换行符和文本
    const newTextDelta = processMathString('\n' + text);

    // 将新文本的 Delta 附加到当前内容的 Delta 上
    const updatedContents = {
      ops: [...currentContents.ops, ...newTextDelta.ops]
    };

    // 设置更新后的内容回编辑器
    if (editorRef.current) {
      editorRef.current.setFullContents(updatedContents);
    }

    setAddText(text);
  }

  const handleInsertClick = () => {
    
    if(addText.length === 0){
      return;
    }
    if (editorRef.current) {
        editorRef.current.insertFormulaAtCursor(addText,showSnackbar);
    }
  }

  const [addText,setAddText] = useState('');


  const handleAddText = (event) => {

    setAddText(event.target.value);
  }



  return (
    <div style={{
      width:'100%',
      height:'100%',
      display:'flex',
      flexDirection:'column',
      gap:'10px',
      position:'relative',
      backgroundColor:get_color_by_name('pop-window-bg'),
      border: `1px solid ${get_color_by_name('pop-window-border-color')}`,
    }}>
        <SubjectContentDiv>
            <span style={{color:get_color_by_name('headline-color')}}>{t('photo handwriting')}</span>
              <div style={{ display: 'flex', alignItems: 'center', width: '100%'}}>
              <DocumentContentDiv>
                <EditorNoClassName ref={editorRef} options={options} key={JSON.stringify(options)} width="100%" height="90%"/>
              </DocumentContentDiv>
              <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginLeft: '10px' }}>
                <IconButton  onClick={handleCameraClick}>
                    <PhotoCamera sx={{color:get_color_by_name('small-icon-color')}} />
                </IconButton>
                <IconButton onClick={handleHandwritingClick} sx={{ marginTop: '10px' }}>
                    <Gesture sx={{color:get_color_by_name('small-icon-color')}} /> {/* 假设Create是你想要的手写输入图标 */}
                </IconButton>
                <IconButton onClick={handleAddBlankLatex} sx={{ marginTop: '10px' }}>
                    <GavelIcon sx={{color:get_color_by_name('small-icon-color')}} /> {/* 假设Create是你想要的手写输入图标 */}
                </IconButton>
                <Tooltip title={t('latex template')}>
                <IconButton onClick={handleMoreClick} sx={{ marginTop: '10px' }}>
                    <LayersIcon sx={{color:get_color_by_name('small-icon-color')}} /> {/* 假设Create是你想要的手写输入图标 */}
                </IconButton>
                </Tooltip>
            </div>
          </div> 
        </SubjectContentDiv> 
        <span style={{color:get_color_by_name('headline-color')}}>
          {t('Enter a latex expression, e.g., x^2 for the square of x, and click the insert button to insert it into the cursor position above.')}
          </span>
        
        <Box
          sx={{
            display: 'flex', // 启用 flex 布局
            flexDirection: 'row', // 子项水平排列
            alignItems: 'center', // 垂直居中对齐子项
            gap: 2, // 子项之间的间隔
          }}
        >
          
          <TextField
            multiline
            rows={3}
            variant="outlined"
            value={addText}
            onChange={handleAddText}
            sx={{
             
              flex: 1,
              minWidth: 0,
              mr: '45px', // 添加右边外边距30px
              '& .MuiInputBase-root': {
                backgroundColor: get_color_by_name('mui-textfield-bg'), // 自定义背景色
              },
              '& .MuiOutlinedInput-root': {
                '&:hover fieldset': {
                  borderColor: get_color_by_name('mui-textfield-bg'),
                },
                '&.Mui-focused fieldset': {
                  borderColor: 'primary.main',
                },
                '& fieldset': {
                  borderColor: get_color_by_name('mui-textfield-bg'),
                },
              },
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={handleInsertClick}>
                    <AddCircleOutlineIcon sx={{color:get_color_by_name('small-icon-color')}}/>
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
         
        </Box>
        
        <Box
          sx={{
            display: 'flex', // 启用 flex 布局
            flexDirection: 'row', // 子项水平排列
            alignItems: 'center', // 垂直居中对齐子项
            justifyContent:'right',
            gap: 2, // 子项之间的间隔
            mr: '45px', // 添加右边外边距30px
          }}
        >
          <CustomButton
            onClick={onClose}
            size="medium"
          >
            {t('close')}
          </CustomButton>

          <CustomButton
            onClick={handleOnConfirm}
            size="medium"
          >
            {t('confirm')}
          </CustomButton>
           
        </Box>

        {showLatexTemplate && <LatexTemplateModule onClose={handleCloseLatexTemplate} />}
        {showPhotoComponent && <PhotoComponent onClose={onClosePhotoCom } ocrType={ocrType} subject={subject} moduleName="common"/> }
        <Modal
          
          open={showHandOcrLatex}
          onClose={handleCloseHandOcr}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            zIndex: 8888, 
          }} // 应用内联样式
        >
          {/* 这里你可以根据需要添加额外的 div 来包装你的 HandOcr 组件，以便可以更灵活地控制样式 */}
          <div style={{

            outline: 'none', // 移除焦点时的轮廓线
            backgroundColor: '#fff', // 背景颜色
            border: '2px solid #000', // 边框样式
            boxShadow: '24px', // 阴影效果
            padding: '16px', // 内边距
            // 这里可以根据需要调整宽度、高度等其他样式
          }}>
            <HandOcr onClose={handleCloseHandOcr} />
          </div>
        </Modal>
    </div>
  );
};

export default LatexModule;
