import React, { useState,useEffect } from 'react';
import { Container, Typography, Checkbox, FormControlLabel, FormGroup, Button, Grid,Box } from '@mui/material';
import { requestUrl } from '../../requestUrl';
import { useTranslation } from 'react-i18next';
import LoadingData from '../dataloading';
import CustomButton from '../../components/CustomButton';

const TeacherStyle = ({onClose}) => {
  const [questionData,setQuestionData] = useState(null);
  const { t } = useTranslation();

  const [isLoading, setIsLoading] = useState(false);
  const [loadingText,setLoadingText] = useState(false);

  const [selectedOptions, setSelectedOptions] = useState([]);

  const [maxSelection,setMaxSelection] = useState(3);


  const [isLoadingComplete, setIsLoadingComplete] = useState(false);

  useEffect(() => {
    
      const fetchData = async () => {
        try {
          console.log("song");
          setIsLoading(true);
          setLoadingText("loading");
          const dataToSend = {
            id: 0,
          };
          const data = await requestUrl(dataToSend, "get_teacher_style_qa", t);
          if (data.status === "success") {
            console.log(data.data);
            setQuestionData(data.data);
            setIsLoadingComplete(true); // 数据加载完成
          }
        } catch (error) {
          console.error("获取数据时出错:", error);
        } finally {
          setIsLoading(false);
        }
      };
  
      fetchData();
     
  }, []);
  
  



  const handleCheckboxChange = (option) => {
    if (selectedOptions.includes(option)) {
      setSelectedOptions(selectedOptions.filter(item => item !== option));
    } else {
      if (selectedOptions.length < maxSelection) {
        setSelectedOptions([...selectedOptions, option]);
      }
    }
  };

  const handleButtonClick = async () => {
    console.log('选中的答案:', JSON.stringify(selectedOptions));
    const selectedString = selectedOptions.join(',');
    try {
        setIsLoading(true);
        setLoadingText("loading");
        const dataToSend = {
            styles:selectedString,
        };
        const data = await requestUrl(dataToSend, "update_teacher_style",t);
        if(data.status === "success"){
          onClose();
        }
        
        
      } catch (error) {
          console.error("获取数据时出错:", error);
      } finally {
        setIsLoading(false);
      }
  };

  return (
    <Container style={{ 
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      height: '100vh', // 100%视窗高度
    }}>
      {questionData && (
      <Container style={{ 
        width: '400px', // 固定宽度为400px
        overflow: 'auto', // 添加滚动轴
        backgroundColor: '#f4f4f4', // 设置背景颜色
        padding: '20px',
        borderRadius: '8px',
        boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
      }}>
        <Typography sx={{color:'black'}} variant="h5">{questionData.question}</Typography>
        <FormGroup>
          <Grid container spacing={2}>
          {questionData.options && questionData.options.map((option, index) => (
              <Grid item xs={6} key={index}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={selectedOptions.includes(option.option)}
                      onChange={() => handleCheckboxChange(option.option)}
                    />
                  }
                  label={
                    <Typography style={{ color: 'black' }}>{/* Set the font color to black */}
                      {option.option}
                    </Typography>
                  }
                />
              </Grid>
            ))}
          </Grid>
        </FormGroup>

        <Box
          sx={{
            display: 'flex', // 使用 Flexbox 布局
            justifyContent: 'flex-end', // 将内容对齐到容器的右侧
            gap: '10px', // 设置子元素之间的间隔为 10px
          }}
        >
          <CustomButton
            onClick={handleButtonClick}
            sx={{ mt: 2 }} // 使用 MUI 的系统属性来设置 marginTop，等同于 { marginTop: '16px' }
          >
            {t('confirm')}
          </CustomButton>

          <CustomButton
            onClick={onClose}
            sx={{ mt: 2 }} // 同上
          >
            {t('close')}
          </CustomButton>
        </Box>
      </Container>
    )}

      {isLoading && (
               <LoadingData text={t(loadingText)}/>
            )}
    </Container>
  );
};

export default TeacherStyle;
