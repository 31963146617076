import React, { useState ,useRef,useEffect ,useCallback  } from 'react';


import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import styled from '@emotion/styled';
import { t } from 'i18next';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, IconButton ,Container,TextField,Select,MenuItem} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import { requestUrl } from '../requestUrl';
import LoadingData from '../education/dataloading';
import Pagination from '@mui/material/Pagination';
import OutlinedInput from '@mui/material/OutlinedInput';
import DeleteIcon from '@mui/icons-material/Delete';
import SaveIcon from '@mui/icons-material/Save';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

import { useSnackbar } from '../SnackbarProvider';
import CancelIcon from '@mui/icons-material/Cancel';
import PlayArrowIcon from '@mui/icons-material/PlayArrow'; 

const ContainerMain = styled.div`
  display: flex;
  flex-direction: column;
  height: 50%;
  width:100%;
`;
const ContentArea = styled.div`
  flex-grow: 1;
  padding: 20px;
  background: #ffffff;
`;



const GeoManage = () => {
  const showSnackbar = useSnackbar();

  const overlayContainerStyle = {
    position: 'fixed', // 或者 'absolute'，取决于父容器
    top: 0,
    left: 0,
    width: '100vw', // 视口宽度
    height: '100vh', // 视口高度
    backgroundColor: 'rgba(0, 0, 0, 1)', // 半透明背景
    zIndex: 1000, // 确保覆盖其他元素
  };
      // Inline styles
      const styles = {
        container: {
            display: 'flex',
            flexDirection: 'row',
            width: '100vw',  // 100% of viewport width
            height: '100vh', // 100% of viewport height
            overflow: 'hidden' // Prevents scrollbars if content overflows
        },
        menu: {
            width: '300px',
            height: '100%',   // Full height
            borderRight: '1px solid #ddd',
            padding: '10px',
            overflowY: 'auto' // Allows scrolling for long menus
        },
        content: {
            flex: 1,
            height: '100%',  // Full height
            padding: '10px',
            overflowY: 'auto' // Allows scrolling for long content
        }
    };

    const [storyList,setStoryList] = useState([]);
    const [loading, setLoading] = useState(false);
    const [loadingText,setLoadingText] = useState('loading');
    const [isVisibleAddEdit,setIsVisibleAddEdit] = useState(false);

    const [pictureBookId,setPictureBookId] = useState(0);
    const [pbRecord,setPbRecord] = useState({});
    const [pictureBookDetail,setPictureBookDetail] =useState([]);


    const [pageCount, setPageCount] = useState(10); // 初始页数
    const [currentPage, setCurrentPage] = useState(1);

    const [geoDetail,setGeoDetail] =useState({});
    const handleEditClick = async (record) => {
        
        // 你可以在这里添加更多的逻辑，比如打开一个编辑对话框
       
        //const record = storyList.find(item => item.id === id);
        setGeoDetail(record);

        
        setIsVisibleAddEdit(true);
      };


      useEffect(() => {
        loadStoryList(0,1,20);
    }, []);  // 依赖数组


      const loadStoryList = async (grade,pagenum,pagesize) => {

        try {
          setLoading(true);
          setLoadingText("loading");
          const dataToSend = {
              status: 1,
              pagenum: pagenum,
              pagesize: pagesize,
          };
          const data = await requestUrl(dataToSend, "get_geo_origin");
          
          if(data.status === "success"){
            console.log(JSON.stringify(data.data));
            setStoryList(data.data);
          }
        } catch (error) {
            console.error("获取数据时出错:", error);
        } finally {
          setLoading(false);
        }
      }

  
      const editStoryById = (id, newData) => {
        setStoryList(prevStoryList => {
          return prevStoryList.map(story => {
            // 检查当前元素的id是否与我们要编辑的id匹配
            if (story.id === id) {
              // 如果匹配，使用新数据来更新这个元素
              return { ...story, ...newData };
            } else {
              // 如果不匹配，保持原来的元素不变
              return story;
            }
          });
        });
      };
      
      

      const handleQuery = () => {
        loadStoryList(0,0,10);
      }


      const addDataAndUpdateList = (data) => {
        // 使用函数式更新来确保我们获得最新的状态
        setStoryList(prevStoryList => {
          // 创建数组的浅拷贝，以避免直接修改原始状态
          const newStoryList = [...prevStoryList];
      
          // 在数组的开始位置插入新元素
          newStoryList.unshift(data);
      
          // 如果数组长度超过1（即至少有一个元素），则移除最后一个元素
          if (newStoryList.length > 1) {
            newStoryList.pop();
          }
      
          // 返回更新后的数组作为新状态
          return newStoryList;
        });
      }

      

  const handlePublish = async (index,id,status) => {
    
    let newstatus = 0;
    if(status ===0){
      newstatus = 1;
    }

    if(status ===1){
      newstatus = 0;
    }

    const newStorylist = [...storyList];
    newStorylist[index] = { ...newStorylist[index], status: newstatus };
    setStoryList(newStorylist);

    try {
      setLoading(true);
      setLoadingText("loading");
      const dataToSend = {
          id:id, 
          status:newstatus, 
      };
      
      const data = await requestUrl(dataToSend, "picturebook_update_status");
      
      if(data.status === "success"){
         
      }
    } 
    catch (error) {
        console.error("获取数据时出错:", error);
    }
    finally {
      setLoading(false);
    }
  }


  const handlePageChange = (event, value) => {
    // 处理分页逻辑
    loadStoryList(0,value,10)
    
  };
  const handleInputChange = (e, index) => {
    // 创建一个新的storyList副本
    const newStoryList = [...storyList];
  
    // 更新指定索引处的对象
    newStoryList[index] = {
      ...newStoryList[index],
      [e.target.name]: e.target.value
    };
  
    // 使用更新后的数组设置状态
    setStoryList(newStoryList);
  };
  
  const handleSubjectChange = (e, index) => {
    const newStoryList = [...storyList];
    newStoryList[index] = {
      ...newStoryList[index],
      subject: e.target.value
    };
    setStoryList(newStoryList);
  };
  const handleStageChange = (e, index) => {
    // 创建一个新的storyList副本
    const newStoryList = [...storyList];
  
    // 更新指定索引处的对象的 'subject' 属性
    newStoryList[index] = {
      ...newStoryList[index],
      stage: e.target.value
    };
  
    // 使用更新后的数组设置状态
    setStoryList(newStoryList);
  };
  
  const handleSavePage = async (record,index) => {
    try {
      setLoading(true);
      setLoadingText("loading");
      const dataToSend = {
          id:record.id, 
          des:record.des, 
          subject:record.subject,
          stage:record.stage,
      };
      
      const data = await requestUrl(dataToSend, "geo_save_base");
      
      if(data.status === "success"){
         
         showSnackbar('update success','success');
      }
    } 
    catch (error) {
        console.error("获取数据时出错:", error);
    }
    finally {
      setLoading(false);
    }
  }

  const handleVerify = async (record,index) => {
    if(!record.des && record.stage){
      showSnackbar(t('fill in the descriptive information and stage information to be able to audit'),'warning');
      
      return;
    }
    let isverify = 1;
    if(record.isverify ===1){
      if(record.isembeding ===1){
        showSnackbar('已经完成embedding,无法取消审核','warning');
        
        return;
      }
      isverify = 0;
    }
    else{
      isverify = 1;
    }
    try {
      setLoading(true);
      setLoadingText("loading");
      const dataToSend = {
          id:record.id,
          isverify:isverify,
      };
      
      const data = await requestUrl(dataToSend, "geo_verify");
      
      if(data.status === "success"){
        const newStoryList = [...storyList];
        newStoryList[index] = {
          ...newStoryList[index],
          isverify:isverify
        };
        setStoryList(newStoryList);
        showSnackbar('success', 'verify success');
      
      }
    } 
    catch (error) {
        console.error("获取数据时出错:", error);
    }
    finally {
      setLoading(false);
    }
  }
  const handleEmbedding = async (record,index) => {
    
    if(record.isverify === 0){
      
      showSnackbar('warning', "it't not verify!please verify");
      return;
    }
    if(record.isembeding === 1){
      
      showSnackbar('warning', '已经完成embedding,无法操作!');
      return;
    }
    try {
      setLoading(true);
      setLoadingText("loading");
      const dataToSend = {
          id:record.id,
      };
      
      const data = await requestUrl(dataToSend, "geo_embedding");
      
      if(data.status === "success"){
        const newStoryList = [...storyList];
        newStoryList[index] = {
          ...newStoryList[index],
          isembeding:1
        };
        setStoryList(newStoryList);
      }
    } 
    catch (error) {
        console.error("获取数据时出错:", error);
    }
    finally {
      setLoading(false);
    }
  }

  const handleDelete = async (record,index) => {
    try {
      setLoading(true);
      setLoadingText("loading");
      const dataToSend = {
          id:record.id,
      };
      
      const data = await requestUrl(dataToSend, "geo_delete");
      
      if(data.status === "success"){
        const newStoryList = storyList.filter((_, idx) => idx !== index);
        setStoryList(newStoryList);
        
        showSnackbar('success', 'delete success!');
      }
    } 
    catch (error) {
        console.error("获取数据时出错:", error);
    }
    finally {
      setLoading(false);
    }
  }

  function getRowStyle(record) {
    return {
      backgroundColor: record.isembeding === 1 ? 'lightblue' : 'inherit'
    };
  }

  const [page, setPage] = useState(1); // 当前页码
  const totalPages = 200; // 假设总页数为 10
  const handleInputPageChange = async (event) => {
    const newPage = Number(event.target.value);
    if (newPage >= 1 && newPage <= totalPages) {
      setPage(newPage);
      loadStoryList(1,newPage,10);
    }
  };

    return (
      <Container style={{ maxWidth:'100%', maxHeight: '100%', overflow: 'auto' }}>
      <ContainerMain>


      <ContentArea>
      <TableContainer component={Paper} style={{ maxHeight: '30vh', overflow: 'auto' }}>
      <Table stickyHeader sx={{ width: '100%' }} aria-label="simple table">
        <TableHead>
          <TableRow>
          <TableCell align="right" style={{width:'20px'}}>id</TableCell>
            <TableCell align="right">originname</TableCell>
            <TableCell align="right">dir</TableCell>
            <TableCell align="right">des</TableCell>
            <TableCell align="right">stage</TableCell>
            <TableCell align="right">subject</TableCell>
            <TableCell align="right">language</TableCell>
            <TableCell align="right">save</TableCell>
            <TableCell align="right">verify</TableCell>
            <TableCell align="right">embedding</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {storyList.map((record,index) => (
            <TableRow style={getRowStyle(record)} key={record.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
              <TableCell align="right" style={{width:'20px'}}>{record.id}</TableCell>
              <TableCell align="right">
              <a onClick={() => handleEditClick(record)} style={{cursor:'pointer',color:'blue'}}> 
                {record.originname}
              </a>
              </TableCell>
              <TableCell align="right" >
              <TextField
                    label="dir"
                    variant="outlined"
                    size="small"
                    multiline
                    rows={2}
                    fullWidth
                    sx={{ width: 250 }}
                    name="des"
                    value={record.directory1+"/" + record.directory2 || ''}
                    
                />
              </TableCell>
              <TableCell>
                <TextField
                    label="des"
                    variant="outlined"
                    size="small"
                    multiline
                    rows={2}
                    fullWidth
                    sx={{ width: 200 }}
                    name="des"
                    value={record.des || ''}
                    onChange={(e) => handleInputChange(e, index)}
                />
              </TableCell>

              <TableCell>
            <Select
                value={record.stage}
                onChange={(e) => handleStageChange(e, index)}
                input={<OutlinedInput />}
                sx={{  width: 200 }}
                inputProps={{ 'aria-label': 'Without label' }}
            >
                <MenuItem key="primary" value="primary">
                    {t('primary level')}
                </MenuItem>
                <MenuItem key="junior" value="junior">
                    {t('junior high school level')}
                </MenuItem>
                <MenuItem key="senior" value="senior">
                    {t('senior high school level')}
                </MenuItem>
            </Select>

            </TableCell>
            <TableCell>
              <Select
                value={record.subject}
                onChange={(e) => handleSubjectChange(e, index)}
                input={<OutlinedInput />}
                inputProps={{ 'aria-label': 'Without label' }}
              >
                
                <MenuItem key="mathematics" value="mathematics">
                    {t('mathematics')}
                </MenuItem>
                <MenuItem key="physics" value="physics">
                    {t('physics')}
                </MenuItem>
                <MenuItem key="chemistry" value="chemistry">
                    {t('chemistry')}
                </MenuItem>
                <MenuItem key="geography" value="geography">
                    {t('geography')}
                </MenuItem>
                <MenuItem key="biology" value="biology">
                    {t('biology')}
                </MenuItem>
              </Select>

            </TableCell>
              <TableCell align="right">{record.language}</TableCell>
              <TableCell>
                <IconButton aria-label="save" color="primary" onClick={() => handleSavePage(record,index)}>
                <SaveIcon />
              </IconButton>
            </TableCell>
            <TableCell>
              <IconButton aria-label="verify" color="primary" onClick={() => handleVerify(record, index)}>
                {record.isverify === 1 ? <CheckCircleIcon /> : <CancelIcon />}
              </IconButton>
            </TableCell>
            <TableCell>

            <IconButton aria-label="verify" color="primary" onClick={() => handleEmbedding(record,index)}
            disabled={!(record.isverify === 1 && record.des && record.isembeding === 0)}>
                {record.isembeding === 1 ? <PlayArrowIcon /> : <PlayArrowIcon />}
              </IconButton>
            </TableCell>

            <TableCell>
              <IconButton aria-label="delete" color="primary" onClick={() => handleDelete(record,index)}>
                <DeleteIcon />
              </IconButton>
            </TableCell>

            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
      </ContentArea>
      <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginBottom: 2, marginTop: '20px' }}>
      
      <Pagination
        count={totalPages}
        page={page}
        onChange={handlePageChange}
        color="primary"
      />
      <TextField
        label="Go to page"
        type="number"
        InputLabelProps={{
          shrink: true,
        }}
        variant="outlined"
        value={page}
        onChange={handleInputPageChange}
        inputProps={{ min: 1, max: totalPages }}
        margin="normal"
      />
        {/* 可以继续添加更多按钮 */}
      </Box>
    </ContainerMain>
            {/* 第三行：嵌入的iframe */}
      <Box sx={{ height: '50vh' }}>
        <iframe
          src={"geohtml/" + geoDetail.filename}
          title="Geo Detail"
          width="100%"
          height="100%"
          style={{ border: 'none' }}
        ></iframe>
      </Box>
    {loading && (
      <LoadingData text={t(loadingText)}/>
    )}
    
        </Container>
    );
};

export default GeoManage;
