
export const deviceType_m_t_d = () => {
    const isMobile = window.innerWidth <= 800;
    if(isMobile === true){
        return "m";
    }
    
    const isTablet = window.innerWidth > 800 && window.innerWidth <= 1200;
    if(isTablet === true){
        return "t";
    }
    else{
        return "d";
    }
  };



 export const processMathString = (inputString) => {

    let ops = [];
    let tempString = '';
    let inFormula = false;
  
    for (let i = 0; i < inputString.length; i++) {
      const c = inputString[i];
      if (c === '$') {
        if (inFormula) {
          ops.push({ "insert": { "formula": tempString } });
          tempString = '';
          inFormula = false;
        } else {
          if (tempString.length > 0) {
            ops.push({ "insert": tempString });
            tempString = '';
          }
          inFormula = true;
        }
      } else {
        tempString += c;
      }
    }
  
    if (tempString.length > 0) {
      ops.push({ "insert": tempString });
    }
  
    return {
      "ops": ops
    };
  }


  export const get_gradelist_by_subject = (defaultgrade,subject) => {
    
    let gradeOptions1;
    let gradeR = defaultgrade;

    if(subject === "physics"){
      gradeOptions1 = [
        { id: '8', text: '8' },
        { id: '9', text: '9' },
        { id: '10', text: '10' },
        { id: '11', text: '11' },
        { id: '12', text: '12' },
        // ... Add more options
      ];
      if(defaultgrade < 8){
        gradeR = "8";
      }
    }
    if(subject === "chemistry"){
      gradeOptions1 = [
        { id: '9', text: '9' },
        { id: '10', text: '10' },
        { id: '11', text: '11' },
        { id: '12', text: '12' },
        // ... Add more options
      ];

      if(defaultgrade < 9){
        gradeR = "9";
      }
    }
    if(subject === "geography"){
      gradeOptions1 = [
        { id: '7', text: '7' },
        { id: '8', text: '8' },
        { id: '10', text: '10' },
        { id: '11', text: '11' },
        { id: '12', text: '12' },
        // ... Add more options
      ];

      if(defaultgrade < 7){
        gradeR = "7";
      }

    }

    if(subject === "biology"){
      gradeOptions1 = [
        { id: '7', text: '7' },
        { id: '8', text: '8' },
        { id: '10', text: '10' },
        { id: '11', text: '11' },
        { id: '12', text: '12' },
        // ... Add more options
      ];
      if (defaultgrade<7){
        gradeR = "7";
      }
    }

    if(subject === "chinese" || subject === "mathematics"|| subject === "english"){
      gradeOptions1 = [
        { id: '1', text: '1' },
        { id: '2', text: '2' },
        { id: '3', text: '3' },
        { id: '4', text: '4' },
        { id: '5', text: '5' },
        { id: '6', text: '6' },
        { id: '7', text: '7' },
        { id: '8', text: '8' },
        { id: '9', text: '9' },
        { id: '10', text: '10' },
        { id: '11', text: '11' },
        { id: '12', text: '12' },
      ];
    }

    if(subject === "c++"){
      console.log("ggggg:" + subject);
      gradeOptions1 = [{ id: '1', text: '1' },];
      gradeR="1";
    }

    
    return [gradeR, gradeOptions1];
  }