import React, { useState,useEffect,useRef } from 'react';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { Container,Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Button, IconButton, Paper,Typography } from '@mui/material';
import PlayArrowIcon from '@mui/icons-material/PlayArrow'; // 声音播放图标
import { CenteredContainer ,LoadingIndicator} from '../../education/dataloading';
import { requestUrl } from '../../requestUrl';
import Select from '@mui/material/Select';
import OutlinedInput from '@mui/material/OutlinedInput';
import MenuItem from '@mui/material/MenuItem';
import { getHostName } from '../../utils/urlUtils';
import { SystemSecurityUpdate } from '@mui/icons-material';
import { useSnackbar } from '../../SnackbarProvider';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';

import PictureBook from '../../education/PictureBookDetail/PicktureBook';
import { t } from 'i18next';

const PicturebookAddEdit = ({onClose,pbid,pbRecord,pbDetails}) => {
    const [loading, setLoading] = useState(false);
    const [loadingText,setLoadingText] = useState('loading');
    const [editableRecord, setEditableRecord] = useState({});
    const [pictureBookDetail,setPictureBookDetail] =useState([]);

    const [isAddOrEdit,setIsAddOrEdit] = useState();

    const showSnackbar = useSnackbar();

    const overlayContainerStyle = {
        position: 'fixed', // 或者 'absolute'，取决于父容器
        top: 0,
        left: 0,
        width: '100vw', // 视口宽度
        height: '100vh', // 视口高度
        backgroundColor: 'rgba(0, 0, 0, 1)', // 半透明背景
        zIndex: 1000, // 确保覆盖其他元素
      };


    useEffect(() => {
        if (pbid > 0 && pbRecord) {
            // 如果是编辑模式，则使用传入的 pbRecord 来初始化状态
            setIsAddOrEdit('edit');
            setEditableRecord(pbRecord);
            setPictureBookDetail(pbDetails);
        } else {
            // 如果是新建模式，则初始化为空对象或默认值
            setIsAddOrEdit('add');
            setEditableRecord({
                id:0,
                title_local_en: '',
                status:0,
                grade:0,
                // 其他属性的默认值
            });
        }
    }, [pbid, pbRecord]);

    const handleClose = () => {
        onClose(isAddOrEdit,editableRecord);
    }


    const handleGradeChange = (event) => {
        setEditableRecord(prevRecord => ({
            ...prevRecord,
            grade: event.target.value
        }));
    };


    

    const handleUploadMusic = async (e) => {
        const file = e.target.files[0];
       
        let fp = "";
        setLoading(true);
        const token = localStorage.getItem("access_token");
        const formData = new FormData();
        formData.append("file", file);  // 'file' 是 FastAPI 端预期的字段名
        formData.append("pbid", pbid); 
        try {
            const url = getHostName() + '/picturebook_uploadbgMusic'; // 你的服务端URL
            const response = await fetch(url, {method: 'POST',
                headers: {'Authorization': `Bearer ${token}`},
                body: formData,});
    
            if (response.ok) {
                fp = response.filepath;
                //editableRecord.bg_music_path = fp;
                console.log("file upload scueess" + fp);
                // 处理成功的响应
            } else {
                console.error("file upload failure");
                // 处理错误
            }
        } catch (error) {
            console.error("file upload failre:", error);
            // 处理异常
            setLoading(false);
        }
        setLoading(false);

        setEditableRecord(prevRecord => ({
            ...prevRecord,
            bg_music_path: e.target.value
        }));

    }

    const handleFileChange = async (index,pdid, e) => {
        const file = e.target.files[0];
        const filepath = await uploadFileToServer(file,editableRecord.id,pdid,"detail");

        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                // 创建一个新的 pictureBookDetail 数组副本
                const newPictureBookDetail = [...pictureBookDetail];
                // 更新相应索引的行的 filepath
                newPictureBookDetail[index] = { ...newPictureBookDetail[index], filepath: reader.result };
                // 更新状态
                setPictureBookDetail(newPictureBookDetail);
            };

            
            editableRecord.thumbnail_path = filepath;
            reader.readAsDataURL(file);
            showSnackbar('warning', t('upload success'));
        }
    };


    const handleFileChangePBMain = async (e) => {
        const file = e.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onload = (event) => {
                // 更新 editableRecord 的 thumbnail_path
                setEditableRecord(prevState => ({
                    ...prevState,
                    thumbnail_path: event.target.result
                }));
            };

            // 调用函数上传文件到后端
             const filep = await uploadFileToServer(file,editableRecord.id,0,"main");
             


            reader.readAsDataURL(file);
        }
    };


    const uploadFileToServer = async (file,pbid,pdid,idtype) => {
        let fp = "";
        setLoading(true);
        const token = localStorage.getItem("access_token");
        const formData = new FormData();
        formData.append("file", file);  // 'file' 是 FastAPI 端预期的字段名
        formData.append("pbid", pbid); 
        formData.append("pdid", pdid); 
        formData.append("idtype", idtype); 
        try {
            const url = getHostName() + '/picturebook_uploadimage'; // 你的服务端URL
            const response = await fetch(url, {method: 'POST',
                headers: {'Authorization': `Bearer ${token}`},
                body: formData,});
    
            if (response.ok) {
                let fp = response.filepath;
                
                showSnackbar('success', t('file upload success'));
            } else {
                console.error("文件上传失败");
                // 处理错误
                showSnackbar('warning', t('file upload failure'));
                
            }
        } catch (error) {
            console.error("上传过程中出错", error);
            // 处理异常
            showSnackbar('warning', t('file upload failure'));
            setLoading(false);
        }
        setLoading(false);

        return fp;
    };


    const handleAddRow = () => {
        // 检查是否有现有行
        const lastRowIndex = pictureBookDetail.length - 1;
        const lastRow = pictureBookDetail[lastRowIndex];
        const newOrder = lastRow && lastRow.order ? lastRow.order + 1 : 1; // 如果有最后一行且有 order 值，则加 1，否则从 1 开始
    
        // 创建一个新的行对象
        const newRow = {
            id: 0,
            picturebooks_id: editableRecord.id,
            order: newOrder,
            content_en:'',
            content_local_lang:'',
            // 添加其他必要的默认值
        };
    
        // 添加新行到 pictureBookDetail
        setPictureBookDetail([...pictureBookDetail, newRow]);
    };

    

    // 处理输入字段变化的函数
    const handleInputChange = (e) => {
        setEditableRecord({
            ...editableRecord,
            [e.target.name]: e.target.value
        });
    };

    const handleTextChange = (index, field, newValue) => {
        // 创建一个新的 pictureBookDetail 数组副本
        const newPictureBookDetail = [...pictureBookDetail];
        // 更新相应索引的行的字段
        newPictureBookDetail[index] = { ...newPictureBookDetail[index], [field]: newValue };
        // 更新状态
        setPictureBookDetail(newPictureBookDetail);
    };

    
    const handleSave = async (e) => {

        let isaddEdit = "";
        if(pbid === 0){
            isaddEdit = "add";
        }
        else{
            isaddEdit = "edit";
        }
        try {
            setLoading(true);
            setLoadingText("loading");
            const dataToSend = {
                isAddEdit: isaddEdit,
                id:pbid, 
                grade:editableRecord.grade,
                title_local_lang:editableRecord.title_local_lang, 
                title_local_en:editableRecord.title_local_en,
                summarize:editableRecord.summarize,
                summarize_en:editableRecord.summarize_en, 
            };
            
            
            const data = await requestUrl(dataToSend, "add_edit_picturebook_main");
            
            if(data.status === "success"){
                if(isaddEdit === "add"){  //如果是新增，返回值为新增的dbid
                    editableRecord.id = data.pbid;
                }
            }
          } 
          catch (error) {
              console.error("获取数据时出错:", error);
          }
          finally {
            setLoading(false);
          }
    };

    const [isShowPreview,setIsShowPreview] = useState(false);
    const handlePreview = (e) => {
        setIsShowPreview(true);
    };

    //转录
    const picturebook_detail_tts = async (langtype,content) => {
        if(selectedVoiceName === ""){
           
            showSnackbar('warning', t('selecting a sound name'));
            return;
        }
        let newAudioUrl = "";
        try {
            setLoading(true);
            setLoadingText("loading");
                const dataToSend = {
                pbid:editableRecord.id,
                lang_type:langtype,
                content:content,
                voicename:selectedVoiceName,
            };
              const data = await requestUrl(dataToSend, "picturebook_main_tts");
              
              if(data.status === "success"){
                newAudioUrl = data.filepath;
                if(langtype === "local"){
                    setEditableRecord(prevRecord => ({
                        ...prevRecord,
                        summarize_mp3path: newAudioUrl
                    }));
                    showSnackbar('success', t('update success'));
                    
                   
                }
                if(langtype === "en"){
                    setEditableRecord(prevRecord => ({
                        ...prevRecord,
                        summarize_en_mp3path: newAudioUrl
                    }));
                    
                }
              }
          } catch (error) {
              console.error("get data error:", error);
          } finally {
            setLoading(false);
          }
    }
    

    const handleTranscribeClick = async (lang_type,index,id,content_en) => {
        let newAudioUrl = "";//"http://localhost:3000/picturebook/1/bg.mp3"
        if(selectedVoiceName === ""){
            
            showSnackbar('warning', t('selecting a sound name'));
            return;
        }
        try {
            setLoading(true);
            setLoadingText("loading");
                const dataToSend = {
                pbid:editableRecord.id,
                pdid:id,
                lang_type:lang_type,
                content:content_en,
                voicename:selectedVoiceName,
            };
              const data = await requestUrl(dataToSend, "picturebook_detail_tts");
              
              if(data.status === "success"){
                newAudioUrl = data.filepath;
                showSnackbar('success', t('transcribe success'));
              }
          } catch (error) {
              console.error("get data error:", error);
              
              showSnackbar('failure', t('transcribe failure'));
          } finally {
            setLoading(false);
          }

        
        // 创建一个新的 pictureBookDetail 数组副本
        const newPictureBookDetail = [...pictureBookDetail];
        // 更新相应索引的行的音频 URL
        if(lang_type === "local"){
            newPictureBookDetail[index] = { ...newPictureBookDetail[index], content_local_lang_mp3: newAudioUrl };
        }
        if(lang_type === "en"){
            newPictureBookDetail[index] = { ...newPictureBookDetail[index], content_en_mp3: newAudioUrl };
        }
        
        // 更新状态
        setPictureBookDetail(newPictureBookDetail);
        showSnackbar('success', t('transcribe finish'));
        
    };

    
    const names = ['alloy', 'echo', 'fable', 'onyx', 'nova', 'shimmer'];
    const [selectedVoiceName, setSelectedVoiceName] = useState('alloy');
    const audioRef = useRef(new Audio());

// 清理音频资源
useEffect(() => {
    return () => {
      if (audioRef.current) {
        audioRef.current.pause();
        audioRef.current.src = '';
      }
    };
  }, []);


  const playSound = (name) => {
    
    setSelectedVoiceName(name);

    let filename = "";
    if(value === "english"){
        filename = selectedVoiceName + "_en.mp3";
    }
    else{
        filename = selectedVoiceName + "_zh.mp3";
    }
    let url = "picturebook/voice/" + filename;
    
    audioRef.current.src = url;
    audioRef.current.play().catch(err => console.error('Error playing audio:', err));
  };
     
    const handleOrderChange = (index, newValue) => {
        // 创建一个新的 pictureBookDetail 数组副本
        const newPictureBookDetail = [...pictureBookDetail];
        // 更新相应索引的行的 order 字段
        newPictureBookDetail[index] = { ...newPictureBookDetail[index], order: newValue };
        // 更新状态
        setPictureBookDetail(newPictureBookDetail);
    };


    
    const handleFileTypeChange = (index, newValue) => {
        // 创建一个新的 pictureBookDetail 数组副本
        const newPictureBookDetail = [...pictureBookDetail];
        // 更新相应索引的行的 order 字段
        newPictureBookDetail[index] = { ...newPictureBookDetail[index], filetype: newValue };
        // 更新状态
        setPictureBookDetail(newPictureBookDetail);
    };


    const handleSavePage = async (row) => {
        let isaddEdit = "";
        if(row.id === 0){
            isaddEdit = "add";
        }
        else{
            isaddEdit = "edit";
        }

        let temp_pdid = row.id;

        try {
            setLoading(true);
              setLoadingText("loading");
              const dataToSend = {
                isAddEdit: isaddEdit,
                id:row.id,
                pbid:editableRecord.id,
                content_en: row.content_en,
                content_local_lang:row.content_local_lang,
                order: row.order,
                filetype:row.filetype,
              };
              const data = await requestUrl(dataToSend, "add_edit_picturebook_detail");
              
              if(data.status === "success"){
                if(isaddEdit === "add"){  //如果是新增，返回值为新增的dbid
                    temp_pdid = data.pdid;
                }
              }

              setLoading(false);
              
              showSnackbar('success', t('update success'));
          } catch (error) {
              console.error("获取数据时出错:", error);
              setLoading(false);
          } finally {
            setLoading(false);
          }




        // 查找当前行在数组中的索引
        const rowIndex = pictureBookDetail.findIndex(r => r === row);
    
        if (rowIndex !== -1) {
            // 创建一个新的 pictureBookDetail 数组副本
            const newPictureBookDetail = [...pictureBookDetail];
            // 更新当前行的 row.id
            newPictureBookDetail[rowIndex] = { ...newPictureBookDetail[rowIndex], id: temp_pdid };
            // 更新状态
            setPictureBookDetail(newPictureBookDetail);
        }
    };

    
    const [value, setValue] = React.useState('english');

    const handleChange = (event) => {
      setValue(event.target.value);
    };


    const onCloseStoryDetail = () => {
        setIsShowPreview(false);
      };


    return (
        <Container style={{ maxWidth:'100vw', maxHeight: '100vh', overflow: 'auto' }}>
            {/* 按钮功能区 */}
            <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginBottom: 2, marginTop: '20px' }}>
            <RadioGroup
                row
                aria-labelledby="demo-radio-buttons-group-label"
                name="radio-buttons-group"
                value={value}
                onChange={handleChange}
            >
        <FormControlLabel value="english" control={<Radio />} label="english" />
        <FormControlLabel value="local" control={<Radio />} label="local language" />
        
      </RadioGroup>
            {selectedVoiceName && (
                <Box display="flex" justifyContent="center">
                <Typography sx={{ color: 'white', marginRight: '30px' }} variant="h6">
                    {selectedVoiceName}
                </Typography>
                </Box>
            )}
            <Box display="flex" justifyContent="center" sx={{marginRight:'20px'}} gap={2}>
               
                {names.map(name => (
                <Button 
                    key={name} 
                    variant="contained" 
                    onClick={() => playSound(name)}
                    sx={{
                    borderRadius: '50%',
                    minWidth: '70px', // 增加按钮宽度
                    height: '70px',
                    fontSize: name.length > 4 ? '0.6rem' : '0.75rem', // 根据名字长度调整字体大小
                    textTransform: 'capitalize',
                    padding: '10px'
                    }}
                >
                    {name}
                </Button>
                ))}
            </Box>
                <Button onClick={handleSave} variant="contained" color="primary" sx={{ marginRight: '20px' }}>{t('save')}</Button>
                <Button onClick={handlePreview} variant="contained" color="secondary" sx={{ marginRight: '20px' }}>{t('previews')}</Button>
                <Button onClick={handleClose} variant="contained" color="success">{t('close')}</Button>
            </Box>
            {/* 内容区 */}
            <Box sx={{ flexGrow: 1, backgroundColor: '#f5f5f5', padding: 2 }}>
                    {/* 表格A */}
            <TableContainer component={Paper} sx={{ marginBottom: 2 }}>
                <Table aria-label="表格 A">
                    <TableHead>
                        <TableRow>
                            <TableCell>{t('field')}</TableCell>
                            <TableCell>{t('value')}</TableCell>
                            <TableCell>{t('sound playback')}</TableCell>
                            <TableCell>{t('operator')}</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {/* 表格行数据 */}
                        <TableRow>
                            <TableCell>{t('native title')}</TableCell>
                            <TableCell>
                            <TextField
                                label="Title"
                                variant="outlined"
                                size="small"
                                multiline
                                rows={2}
                                fullWidth
                                name="title_local_lang"
                                value={editableRecord.title_local_lang || ''}
                                onChange={handleInputChange}
                            />
                            </TableCell>
                            <TableCell>
                                <IconButton aria-label={t('playing')}>
                                    <PlayArrowIcon />
                                </IconButton>
                            </TableCell>
                            <TableCell>
                                <Button variant="contained">none</Button>
                            </TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell>{t('english title')}</TableCell>
                            <TableCell>
                            <TextField
                                label="Title"
                                variant="outlined"
                                size="small"
                                multiline
                                rows={2}
                                fullWidth
                                name="title_local_en"
                                value={editableRecord.title_local_en || ''}
                                onChange={handleInputChange}
                            />
                            </TableCell>
                            <TableCell>
                                <IconButton aria-label="播放">
                                    <PlayArrowIcon />
                                </IconButton>
                            </TableCell>
                            <TableCell>
                                <Button variant="contained">none</Button>
                            </TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell>{t('native summaries')}</TableCell>
                            <TableCell>
                            <TextField
                                label="Title"
                                variant="outlined"
                                size="small"
                                multiline
                                rows={2}
                                fullWidth
                                name="summarize"
                                value={editableRecord.summarize || ''}
                                onChange={handleInputChange}
                            />
                            </TableCell>
                            <TableCell>
                            <audio src={editableRecord.summarize_mp3path} controls />
                            </TableCell>
                            <TableCell>
                                <Button 
                                onClick={() => picturebook_detail_tts('local',editableRecord.summarize)}  
                                variant="contained"
                                disabled={editableRecord.id === 0}>
                                    {t('transcribe speech')}</Button>
                            </TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell>{t('english summaries')}</TableCell>
                            <TableCell>
                                <TextField
                                    label="Title"
                                    variant="outlined"
                                    size="small"
                                    multiline
                                    rows={2}
                                    fullWidth
                                    name="summarize_en"
                                    value={editableRecord.summarize_en || ''}
                                    onChange={handleInputChange}
                                />
                            </TableCell>
                            <TableCell>
                            <audio src={editableRecord.summarize_en_mp3path} controls />
                            </TableCell>
                            <TableCell>
                                <Button disabled={editableRecord.id === 0} onClick={() => picturebook_detail_tts('en',editableRecord.summarize_en)} variant="contained">转录语音</Button>
                            </TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell>{t('background music')}</TableCell>
                            <TableCell>
                               
                            </TableCell>
                            <TableCell>
                            <audio src={editableRecord.bg_music_path} controls />
                            </TableCell>
                            <TableCell>
                                <label htmlFor={`file-upload-music-${editableRecord.id}`}>
                                    <input
                                        id={`file-upload-music-${editableRecord.id}`}
                                        type="file"
                                        style={{ display: 'none' }}
                                        onChange={handleUploadMusic}
                                    />
                                    <Button
                                        variant="contained"
                                        component="span"
                                        startIcon={<CloudUploadIcon />}
                                        disabled={editableRecord.id === 0}  // 根据 row.id 的值禁用或启用按钮
                                    >
                                        {t('upload')}
                                    </Button>
                                </label>
                               
                            </TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell>{t('appropriate grade level')}</TableCell>
                            <TableCell>
                            <Select 
                                sx={{ width: '100%' }} 
                                value={editableRecord.grade || 0} // 确保 value 属性正确
                                onChange={handleGradeChange} // 添加 onChange 事件处理器
                            >
                                {[...Array(13).keys()].map(num => (
                                    <MenuItem key={num} value={num}>
                                        {num}
                                    </MenuItem>
                                ))}
                            </Select>

                            </TableCell>
                            <TableCell>
                                {t('0 Indicates no restriction on grade level')}
                                
                            </TableCell>
                            <TableCell>
                            </TableCell>
                        </TableRow>


                        <TableRow>
                            <TableCell>{t('thumbnail')}</TableCell>
                            <TableCell>
                               
                            </TableCell>
                            <TableCell>
                                <img 
                                    src={editableRecord.thumbnail_path} 
                                    
                                    style={{ width: '100px', height: '100px' }} 
                                    onClick={() => window.open(editableRecord.filepath, '_blank')}
                                />
                            </TableCell>
                            <TableCell>
                                <label htmlFor={`file-upload-${editableRecord.id}`}>
                                    <input
                                        id={`file-upload-${editableRecord.id}`}
                                        type="file"
                                        style={{ display: 'none' }}
                                        onChange={(e) => handleFileChangePBMain( e)}
                                    />
                                    <Button
                                        variant="contained"
                                        component="span"
                                        startIcon={<CloudUploadIcon />}
                                        disabled={editableRecord.id === 0}  // 根据 row.id 的值禁用或启用按钮
                                    >
                                        {t('upload')}
                                    </Button>
                                </label>
                               
                            </TableCell>
                        </TableRow>

                        {/* 更多行... */}
                    </TableBody>
                </Table>
            </TableContainer>

            {/* 表格B */}
            <TableContainer component={Paper}>
                <Table aria-label="表格 B">
                    {/* 表头 */}
        <TableHead>
            <TableRow>
                <TableCell>{t('row')}</TableCell>
                <TableCell>{t('pagination')}</TableCell>
                <TableCell>{t('native language')}</TableCell>
                <TableCell>{t('transcribe')}</TableCell>
                <TableCell>{t('sound playback')}</TableCell>
                <TableCell>{t('english')}</TableCell>
                <TableCell>{t('transcribe')}</TableCell>
                <TableCell>{t('sound playback')}</TableCell>
                <TableCell>{t('upload')}</TableCell>
                <TableCell>{t('image')}</TableCell>
                <TableCell>{t('source type')}</TableCell>
                <TableCell>{t('save')}</TableCell>
            </TableRow>
        </TableHead>
        <TableBody>
    {pictureBookDetail.map((row, index) => (
        <TableRow key={index}>
            <TableCell>{t('row')} {index + 1}</TableCell>
            <TableCell>
                <Select
                    value={row.order}
                    onChange={(e) => handleOrderChange(index, e.target.value)}
                    input={<OutlinedInput />}
                    inputProps={{ 'aria-label': 'Without label' }}
                >
                    {[...Array(30).keys()].map(num => (
                        <MenuItem key={num + 1} value={num + 1}>
                            {num + 1}
                        </MenuItem>
                    ))}
                </Select>
            </TableCell>

            <TableCell>
                <TextField
                    value={row.content_local_lang}
                    variant="outlined"
                    size="small"
                    multiline
                    rows={3}
                    fullWidth
                    onChange={(e) => handleTextChange(index, 'content_local_lang', e.target.value)}
                />
            </TableCell>
            <TableCell>
            <Button 
                    variant="contained"
                    onClick={() => handleTranscribeClick('local',index,row.id,row.content_local_lang)}
                    disabled={row.id === 0}  
                >{t('transcribe')}
                    </Button>
            </TableCell>
            <TableCell>
            <audio src={row.content_local_lang_mp3} controls />
            </TableCell>

            <TableCell>
                <TextField
                    value={row.content_en}
                    variant="outlined"
                    size="small"
                    multiline
                    rows={3}
                    fullWidth
                    onChange={(e) => handleTextChange(index, 'content_en', e.target.value)}
                />
            </TableCell>
            <TableCell>
                <Button 
                    variant="contained"
                    onClick={() => handleTranscribeClick('en',index,row.id,row.content_en)}
                    disabled={row.id === 0}  
                >
                    {t('transcribe')}
                </Button>
            </TableCell>
            <TableCell>
                <audio src={row.content_en_mp3} controls />
            </TableCell>

            <TableCell>
                <label htmlFor={`file-upload-${index}`}>
                    <input
                        id={`file-upload-${index}`}
                        type="file"
                        style={{ display: 'none' }}
                        onChange={(e) => handleFileChange(index,row.id, e)}
                    />
                    <Button
                        variant="contained"
                        component="span"
                        startIcon={<CloudUploadIcon />}
                        disabled={row.id === 0}  // 根据 row.id 的值禁用或启用按钮
                    >
                        {t('upload')}
                    </Button>
                </label>
                <p>{row.filename}</p>
            </TableCell>

            <TableCell>
                <img
                    src={row.filepath} 
                    alt={`Thumbnail for ${row.filename}`} 
                    style={{ width: '100px', height: '100px' }} 
                    onClick={() => window.open(row.filepath, '_blank')}
                />
            </TableCell>
            <TableCell>
            <Select
                value={row.filetype === 'i' ? 'image' : row.filetype === 'v' ? 'video' : ''}
                onChange={(e) => handleFileTypeChange(index, e.target.value === 'image' ? 'i' : 'v')}
                input={<OutlinedInput />}
                inputProps={{ 'aria-label': 'Without label' }}
            >
                <MenuItem key="image" value="image">
                    {t('image')}
                </MenuItem>
                <MenuItem key="video" value="video">
                    {t('video')}
                </MenuItem>
            </Select>

            </TableCell>
            <TableCell>
                <Button
                    onClick={() => handleSavePage(row)}
                    variant="contained"
                    component="span"
                >
                    {t('save')}
                </Button>
            </TableCell>
        </TableRow>
    ))}
</TableBody>

                </Table>
            </TableContainer>
            <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginTop: '20px' }}>
                <Button variant="contained" color="primary" onClick={handleAddRow}>
                    {t('add row')}
                </Button>
            </Box>
            </Box>

        {isShowPreview && (
            <div style={overlayContainerStyle}>
                <PictureBook 
                title_local={editableRecord.selectedTitle_local}
                title_en={editableRecord.selectedTitle_en}
                pages={pictureBookDetail} 
                musicurl={editableRecord.bg_music_path} 
                onClose={onCloseStoryDetail} 
                summary={editableRecord.selectedSummary} 
                summary_en={editableRecord.selectedSummaryEn}
                picturebookId={pbid}
                />
            </div>
        )}
        </Container>
    );
};

export default PicturebookAddEdit;
