import React, { createContext, useContext, useState, useCallback } from 'react';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

const SnackbarContext = createContext();

export const SnackbarProvider = ({ children }) => {
  const [snackPack, setSnackPack] = useState([]);
  const [open, setOpen] = useState(false);
  const [messageInfo, setMessageInfo] = useState(undefined);

  const addSnackbar = useCallback((message, severity) => {
    setSnackPack((prev) => [...prev, { message, key: new Date().getTime(), severity }]);
  }, []);

  React.useEffect(() => {
    if (snackPack.length && !messageInfo) {
      // 设置当前要显示的消息
      setMessageInfo({ ...snackPack[0] });
      setSnackPack((prev) => prev.slice(1));
      setOpen(true);
    } else if (snackPack.length && messageInfo && open) {
      // 关闭Snackbar以显示下一条消息
      setOpen(false);
    }
  }, [snackPack, messageInfo, open]);

  const handleClose = useCallback((event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  }, []);

  const handleExited = useCallback(() => {
    setMessageInfo(undefined);
  }, []);

  return (
    <SnackbarContext.Provider value={addSnackbar}>
      {children}
      <Snackbar
        key={messageInfo ? messageInfo.key : undefined}
        open={open}
        autoHideDuration={3000}
        onClose={handleClose}
        TransitionProps={{ onExited: handleExited }}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        style={{ marginTop: 100}} // 修改背景色为黑色
      >
        {messageInfo ? (
          <MuiAlert
            elevation={6}
            variant="filled"
            severity={messageInfo.severity}
            style={{ color: 'yellow' }} // 修改字体颜色为白色
          >
            {messageInfo.message}
          </MuiAlert>
        ) : null}
      </Snackbar>
    </SnackbarContext.Provider>
  );
};

export const useSnackbar = () => {
  const context = useContext(SnackbarContext);
  if (context === undefined) {
    throw new Error('useSnackbar must be used within a SnackbarProvider');
  }
  return context;
};
