import React, { useState, useEffect, useRef } from 'react';
import { ThemeProvider, createTheme, Grid, Box, AppBar } from '@mui/material';
import { useSelector } from 'react-redux'
// 公用组件
import Header from '../Header';
import Footer from '../Footer';
import Sidebar from './sidebar';
// 外面的壳子
const Layout = ({ children }) => {
    let s = useSelector(state => state)
    return (
        <ThemeProvider theme={createTheme()}>
            <div className='page-content' container spacing={2}>
                {/* <button
                    aria-label="Increment value"
                    onClick={() => dispatch(increment())}
                >
                    Increment
                </button> */}
                <Sidebar className='sidebar' />
                <div className='main'>
                    <div className='content'>
                        {children}
                    </div>

                </div>
                <Footer />
            </div>
        </ThemeProvider>

    )
}

export default Layout