// 侧边栏
// , useEffect, useRef
import React, { useState,useEffect } from 'react';
import { Route, useNavigate } from 'react-router-dom';
import { MenuList, MenuItem, ListItemIcon, ListItemText, SvgIcon } from '@mui/material';
import { Web, ArrowForward, Clear } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux'
import { useLocation } from 'react-router-dom';
import '../../fonts.css';
import { getColorDark,getColorLight,getTheme } from '../../ColorManager';
// 样式
const Sidebar = ({ className }) => {

    
    const routeList = useSelector(state => state.user.routeList)
    // 国际化
    const { t } = useTranslation();
    // 路由
    const navigate = useNavigate();
    // 变量
    const sidebar = React.createRef(null);
    // 是否折叠
    let [isCollapse, setIsCollapse] = useState(false);
    // 所有方法

    const [colorManager, setColorManager] = useState(getColorDark()); // 初始化为空数组

      //获取颜色设置，系统加载时设置
  useEffect(() => {

    const themeName = localStorage.getItem('theme');//此处存储的是数字1-12，而不是汉字，
    if (themeName) {
      
      if(themeName === "dark"){
        setColorManager(getColorDark());
      }

      
      if(themeName === "light"){
        setColorManager(getColorLight());
      }
    }
    else{
      let aa = getColorDark();
      setColorManager(aa);
    }

    
  }, []);



  const get_color_by_name = (name) => {

    const item = colorManager.find(item => item.container === name);
    return item.value;
  }


    const openPage = (item) => {
        navigate(item.path)
    }
    const changeCollapse = () => {
        setIsCollapse(!isCollapse)
    }
    const close = () => {
        sidebar.current.style.display = 'none'
        document.querySelector('.mask').style.display = 'none'
    }
    const location = useLocation();
    return (
        <>
            <div style={{backgroundColor:get_color_by_name('navigator-bg')}} ref={sidebar} className={className + (isCollapse && ' is-collapse' || '') + ' sidebar'}>
                <MenuList
                    id="user-menu"
                    className='menu'
                    sx={{backgroundColor:get_color_by_name('navigator-bg')}}
                >
                    {routeList.map(route => {
                        if (!route.isHide) {
                            const name = t(route.name) || route.name
                            return (
                                <MenuItem selected={location.pathname == route.path} key={route.path} onClick={() => openPage(route)}>
                                    <ListItemIcon className='icon'>
                                        <SvgIcon sx={{color:'#01b2c2'}} component={route.icon} inheritViewBox />
                                    </ListItemIcon>
                                    {!isCollapse ? <ListItemText sx={{color:'white'}}><div className='siyuanFont'>&nbsp;&nbsp;&nbsp;{name}</div></ListItemText> : ''}
                                </MenuItem>
                            )
                        }
                    })}
                </MenuList>
                {/* 工具栏 */}
                <div style={{backgroundColor:get_color_by_name('navigator-bg')}} className='tools'>
                    <div className='tool-item is-close'>
                        <SvgIcon style={{color:get_color_by_name('small-icon-color')}} component={Clear} onClick={close} />
                    </div>
                    <div className='tool-item is-collapse'>
                        <SvgIcon style={{color:get_color_by_name('small-icon-color')}} component={isCollapse ? ArrowForward : Web} onClick={changeCollapse} />
                    </div>
                </div>
            </div>

           
            <div className='mask' onClick={close}></div>

        </>
    )
}
export default Sidebar