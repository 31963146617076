import { useRef, useState, useEffect } from 'react';
import Editor from "./mquill4/Editor";
import './mquill4/index.css';

const CUSTOM_OPERATORS = [
  ["\\pm", "\\pm"],
  ["\\sqrt{x}", "\\sqrt"],
  ["\\sqrt[3]{x}", "\\sqrt[3]{}"],
  ["\\sqrt[n]{x}", "\\nthroot"],
  ["\\frac{x}{y}", "\\frac"],
  ["\\sum^{s}_{x}{d}", "\\sum"],
  ["\\prod^{s}_{x}{d}", "\\prod"],
  ["\\coprod^{s}_{x}{d}", "\\coprod"],
  ["\\int^{s}_{x}{d}", "\\int"],
  ["\\binom{n}{k}", "\\binom"]
];

function Testlatex() {
  const [operators, setOperators] = useState([]);
  const [displayHistory, setDisplayHistory] = useState(false);
  const [
    displayDeleteButtonOnHistory,
    setDisplayDeleteButtonOnHistory
  ] = useState(false);

  const toggleDisplayHistory = event => {
    setDisplayHistory(event.target.checked);
  };

  const toggleDisplayDeleteOnHistory = event => {
    setDisplayDeleteButtonOnHistory(event.target.checked);
  };

  const toggleOperators = event => {
    setOperators(event.target.checked ? CUSTOM_OPERATORS : []);
  };

  const editorRef = useRef(null);

    const [displayData, setDisplayData] = useState();

    const handleDisplay = event => {
      if (editorRef.current) {
        //const text = editorRef.current.getTextFromEditor();
        const contents = editorRef.current.getFullContents();
        setDisplayData(contents);
      }
    };

  const options = { displayHistory, operators, displayDeleteButtonOnHistory };

  const handleSet = () => {

    const initialDelta = {
      "ops": [
        { "insert": "手动阀十分asdfasdfas下" },
        { "insert": { "formula": "x^2" } },
        { "insert": " \n" }
      ]
    };

    if (editorRef.current) {
      editorRef.current.setFullContents(initialDelta);
    }


  };


  useEffect(() => {
    const initialDelta = {
      "ops": [
        { "insert": "手动阀十分下" },
        { "insert": { "formula": "x^2" } },
        { "insert": " \n" }
      ]
    };

    if (editorRef.current) {
      editorRef.current.setFullContents(initialDelta);
    }
  }, []);  // 空数组意味着这个useEffect只会在组件首次挂载时运行
  
  return (
    <main className="demo-container">
      <Editor ref={editorRef} options={options} key={JSON.stringify(options)} />



      <label>
        Use custom operator buttons:
        <input type="checkbox" className="option" onChange={toggleOperators} />
      </label>

      <label>
        Display formula history:
        <input
          type="checkbox"
          className="option"
          onChange={toggleDisplayHistory}
        />
      </label>
      <label>
        Display delete button on formula history:
        <input
          type="checkbox"
          className="option"
          onChange={toggleDisplayDeleteOnHistory}
        />
      </label>

      <button onClick={handleDisplay}>显示</button>
      <button onClick={handleSet}>显示</button>
      <span>{JSON.stringify(displayData, null, 2)}</span>
    </main>
  );
}

export default Testlatex;