import React, { useState,useEffect } from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import AnalysisIcon from '@mui/icons-material/Assessment'; // 假设这是分析题目的图标
import 'katex/dist/katex.min.css';
import katex from 'katex';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import { blue, orange } from '@mui/material/colors';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from '../../SnackbarProvider';
import { requestUrl } from '../../requestUrl';
import LoadingData from '../dataloading';
import CustomButton from '../../components/CustomButton';
import { getColorDark,getColorLight ,getTheme} from '../../ColorManager';
import { Button,Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Pagination,Modal,Checkbox ,TextField,Chip } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import ProblemDetail from './ProblemDetail';
import { Select, MenuItem, FormControl, InputLabel } from '@mui/material';
import '../ChatWindow/MessageItem.css';

// 外面的壳子
const ConfigHomework = ({row,onClose}) => {
  const showSnackbar = useSnackbar();
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [loadingText,setLoadingText] = useState('loading');
  const [userid,setUserid] = useState(0);
  const [colorManager, setColorManager] = useState(getColorDark()); // 初始化为空数组

  const [leftWidth, setLeftWidth] = useState(50);
  const [tableData, setTableData] = useState([]);
  const [selectedRowId, setSelectedRowId] = useState(null);

  const [totalPagesSession, setTotalPagesSession] = useState(1);
  const [currentPage, setCurrentPage] = useState(0);

  const [selectedRows, setSelectedRows] = useState([]);



  const get_color_by_name = (name) => {

    const item = colorManager.find(item => item.container === name);
    return item.value;
  }


  useEffect(() => {
    const fetchData = async () => {
        try {
            setIsLoading(true);
            setLoadingText("loading");
            const dataToSend = {
              page:1,
              per_page:10,
              question_title:"",
              question_code:"",
              difficulty_level:"",
            };
            const data = await requestUrl(dataToSend, "get_problem_list",t,showSnackbar);
            if(data.status === "success"){
              console.log(data.data);
              

              setTableData(data.data);
            }
            
            
          } catch (error) {
              console.error("获取数据时出错:", error);
          } finally {
            setIsLoading(false);
          }
    };

    fetchData(); 
  }, []);


  useEffect(() => {
    const fetchData = async () => {
        try {
            setIsLoading(true);
            setLoadingText("loading");
            const dataToSend = {
              id:1,
            };
            const data = await requestUrl(dataToSend, "get_grade_info_for_teacher",t,showSnackbar);
            if(data.status === "success"){
              console.log(data.data);
              

              setDataGrade(data.data);
            }
            
            
          } catch (error) {
              console.error("获取数据时出错:", error);
          } finally {
            setIsLoading(false);
          }
    };

    fetchData(); 
  }, []);


  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const year = date.getFullYear().toString().slice(-2); // 获取年份的后两位
    const month = String(date.getMonth() + 1).padStart(2, '0'); // 月份从0开始，所以要+1
    const day = String(date.getDate()).padStart(2, '0');
    const hour = String(date.getHours()).padStart(2, '0');

    return `${year}-${month}-${day}:${hour}`;
  };



  const inputStyles = {
    color:get_color_by_name('mui-textfield-text-color'),
    fontFamily: 'siyuan',
  };



  const handleClose = () => {
    console.log(JSON.stringify(selectedRows));
    onClose();
  }

  const handleSave = async () => {

    try {
        setIsLoading(true);
        setLoadingText("loading");

        const problemIds = selectedRows.map(row => row.id);
        //const problemArrayIds = [problemIds[0]]; // 如果只想要第一个 id
        
        const dataToSend = {
            gradeIds: selectedGradeIds,  // 应该是数组
            problemIds: problemIds, // 应该是数组
        };
        console.log("::::" + JSON.stringify(dataToSend));
        const data = await requestUrl(dataToSend, "add_homework",t,showSnackbar);
        if(data.status === "success"){
          console.log(data.data);
          setDataGrade(data.data);
        }
        
        
      } catch (error) {
          console.error("获取数据时出错:", error);
      } finally {
        setIsLoading(false);
      }

  }
    const handleChangePage = async (event, newPage) => {
    setCurrentPage(newPage);
    }
    

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
          const newSelectedRows = tableData.map((row) => ({
            id: row.id,
            question_code: row.question_code,
            question_title: row.question_title,
          }));
          setSelectedRows(newSelectedRows);
          return;
        }
        setSelectedRows([]);
      };
    
      const handleClick = (row) => {
        const selectedIndex = selectedRows.findIndex((selectedRow) => selectedRow.id === row.id);
        let newSelected = [];
    
        if (selectedIndex === -1) {
          newSelected = newSelected.concat(selectedRows, {
            id: row.id,
            question_code: row.question_code,
            question_title: row.question_title,
          });
        } else if (selectedIndex === 0) {
          newSelected = newSelected.concat(selectedRows.slice(1));
        } else if (selectedIndex === selectedRows.length - 1) {
          newSelected = newSelected.concat(selectedRows.slice(0, -1));
        } else if (selectedIndex > 0) {
          newSelected = newSelected.concat(
            selectedRows.slice(0, selectedIndex),
            selectedRows.slice(selectedIndex + 1)
          );
        }
    
        setSelectedRows(newSelected);
      };
    
      const isSelected = (id) => selectedRows.some((selectedRow) => selectedRow.id === id);
    



      const [dataGrade, setDataGrade] = useState([]);


      const [selectedGradeIds, setSelectedGradeIds] = useState([]);

      const handleSelectGrade = (id) => {
          const isSelected = selectedGradeIds.includes(id);
          const newSelectedGradeIds = isSelected
              ? selectedGradeIds.filter((selectedId) => selectedId !== id)
              : [...selectedGradeIds, id];
          setSelectedGradeIds(newSelectedGradeIds);
      };
  
      const isSelectedGrade = (id) => selectedGradeIds.includes(id);



    return (
      <div style={{ height: '100%', width:'100%', display: 'flex', flexDirection: 'column' ,backgroundColor:get_color_by_name('pop-window-bg'),}}>
      
        <Box sx={{ height: '100vh' }}>
        {/* 上部工具栏 */}
        <div style={{ 
            position: 'relative',
            height: '30px',
            }}>
            <div style={{ 
                position: 'absolute', 
                right: '20px',
                display: 'flex', 
                gap: '20px' 
            }}>
               <Button
                    onClick={handleSave}
                    variant="contained"
                    sx={{
                        width:'200px',
                        backgroundColor: '#1976d2', // 蓝色背景
                        color: 'white',
                        '&:hover': {
                            backgroundColor: '#1565c0', // 悬停时的颜色
                        },
                        mb: 2, // 添加下边距，使两个按钮之间有间距
                        }}
                    >
                    保存
                </Button>
                <Button
                    onClick={handleClose}
                    variant="contained"
                    sx={{
                        width:'200px',
                        backgroundColor: '#1976d2', // 蓝色背景
                        color: 'white',
                        '&:hover': {
                        backgroundColor: '#1565c0', // 悬停时的颜色
                        },
                        mb: 2, // 添加下边距，使两个按钮之间有间距
                    }}
                    >
                    关闭
                </Button>
            </div>
            </div>
          {/* 下部分 */}
          <Box sx={{ display: 'flex', height: 'calc(100% - 64px)' }}>
            {/* 左侧部分 */}
           
            <div style={{ flexBasis: '70%', backgroundColor:get_color_by_name('message-item-bg'), marginRight: '10px'}}>
                <div>
                    <TableContainer component={Paper}>
                        <Table>
                            <TableHead>
                            <TableRow sx={{ backgroundColor: get_color_by_name('pop-window-bg') }}>
                                <TableCell padding="checkbox">
                                <Checkbox
                                    color="primary"
                                    indeterminate={selectedRows.length > 0 && selectedRows.length < tableData.length}
                                    checked={tableData.length > 0 && selectedRows.length === tableData.length}
                                    onChange={handleSelectAllClick}
                                    sx={{
                                    color: 'white', // 未选中状态的颜色
                                    '&.Mui-checked': {
                                        color: 'white', // 选中状态的颜色
                                    },
                                    }}
                                />
                                </TableCell>
                                <TableCell sx={{ width: '150px', fontFamily: 'siyuan', color: get_color_by_name('headline-color') }}>{t('problem number')}</TableCell>
                                <TableCell sx={{ width: '350px', fontFamily: 'siyuan', color: get_color_by_name('headline-color') }}>{t('problem title')}</TableCell>
                                <TableCell sx={{ width: '150px', fontFamily: 'siyuan', color: get_color_by_name('headline-color') }}>{t('code language')}</TableCell>
                                <TableCell sx={{ width: '150px', fontFamily: 'siyuan', color: get_color_by_name('headline-color') }}>{t('difficulty level')}</TableCell>
                                <TableCell sx={{ width: '150px', fontFamily: 'siyuan', color: get_color_by_name('headline-color') }}>{t('difficulty t')}</TableCell>
                            </TableRow>
                            </TableHead>
                            <TableBody>
                            {tableData.map((row, index) => {
                                const isItemSelected = isSelected(row.id);
                                return (
                                <TableRow
                                    key={row.id}
                                    selected={isItemSelected}
                                    onClick={() => handleClick(row)}
                                    hover
                                    style={{
                                    cursor: 'pointer',
                                    backgroundColor: isItemSelected ? get_color_by_name('mui-button-bg') : get_color_by_name('pop-window-bg')
                                    }}
                                >
                                    <TableCell padding="checkbox">
                                    <Checkbox
                                        color="primary"
                                        checked={isItemSelected}
                                        sx={{
                                            color: 'white', // 未选中状态的颜色
                                            '&.Mui-checked': {
                                            color: 'white', // 选中状态的颜色
                                            },
                                        }}
                                    />
                                    </TableCell>
                                    <TableCell
                                    sx={{
                                        fontFamily: 'siyuan',
                                        color: get_color_by_name('mui-textfield-text-color'),
                                        textDecoration: 'underline',
                                        cursor: 'pointer'
                                    }}
                                    >
                                    {row.question_code}
                                    </TableCell>
                                    <TableCell sx={{ fontFamily: 'siyuan', color: get_color_by_name('mui-textfield-text-color') }} title={row.question_title}>
                                    {row.question_title.length > 20 ? row.question_title.substring(0, 20) + "..." : row.question_title}
                                    </TableCell>
                                    <TableCell sx={{ fontFamily: 'siyuan', color: get_color_by_name('mui-textfield-text-color') }}>{row.code_language}</TableCell>
                                    <TableCell sx={{ fontFamily: 'siyuan', color: get_color_by_name('mui-textfield-text-color') }}>{row.difficulty_level}</TableCell>
                                    <TableCell sx={{ fontFamily: 'siyuan', color: get_color_by_name('mui-textfield-text-color') }}>{row.difficulty_t}</TableCell>
                                </TableRow>
                                );
                            })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <Pagination
                        sx={{ backgroundColor:get_color_by_name('mui-textfield-bg') , borderRadius: '10px' }}
                        count={totalPagesSession}
                        page={currentPage}
                        onChange={handleChangePage}
                        color="primary"
                    />
                </div>

        </div>
        


        <div style={{ flexBasis: '30%', marginLeft:'10px', 
          backgroundColor: get_color_by_name('pop-window-bg'), padding: '1em',
          }}>
            <Box
                sx={{
                    flexBasis: '30%',
                    backgroundColor: get_color_by_name('pop-window-bg'),
                    border: `1px solid ${get_color_by_name('pop-window-border-color')}`,
                    padding: '1em',
                    display: 'flex',
                    flexWrap: 'wrap',
                    gap: '10px', // 标签之间的间距
                    height:'100%',
                    width:'100%',
                }}>


                <div style={{ display: 'flex', flexDirection: 'column'}}>
                    <div style={{ flex: 1, backgroundColor: '#f0f0f0', padding: '20px' }}>
                    <TableContainer component={Paper}>
                        <Table>
                        <TableHead>
                        <TableRow sx={{ backgroundColor: get_color_by_name('pop-window-bg') }}>
                            <TableCell sx={{ width: '150px', fontFamily: 'siyuan', color: get_color_by_name('headline-color') }}>{t('Selected Code')}</TableCell>
                            <TableCell sx={{ width: '350px', fontFamily: 'siyuan', color: get_color_by_name('headline-color') }}>{t('Selected Title')}</TableCell>
                        </TableRow>
                        </TableHead>
                        <TableBody>
                        {selectedRows.map((selectedRow) => (
                            <TableRow key={selectedRow.id} sx={{ backgroundColor: get_color_by_name('mui-button-bg') }}>
                            <TableCell sx={{ fontFamily: 'siyuan', color: get_color_by_name('mui-textfield-text-color') }}>{selectedRow.question_code}</TableCell>
                            <TableCell sx={{ fontFamily: 'siyuan', color: get_color_by_name('mui-textfield-text-color') }}>{selectedRow.question_title}</TableCell>
                            </TableRow>
                        ))}
                        </TableBody>
                        </Table>
                    </TableContainer>
                    </div>
                    <div style={{ flex: 1, backgroundColor: '#c0c0c0', padding: '20px' }}>
                    <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="grade info table">
                <TableHead>
                    <TableRow>
                        <TableCell></TableCell>
                        <TableCell>班级名称</TableCell>
                        <TableCell align="center">开课时间</TableCell>
                        <TableCell align="center">学生数量</TableCell>
                        <TableCell align="center">学生组成</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {dataGrade.map((row) => (
                        <TableRow
                            key={row.id}
                            sx={{
                                backgroundColor: isSelectedGrade(row.id) ? '#f0f0f0' : 'inherit',
                            }}
                            onClick={() => handleSelectGrade(row.id)}
                        >
                            <TableCell padding="checkbox">
                                <Checkbox
                                    checked={isSelectedGrade(row.id)}
                                    onChange={() => handleSelectGrade(row.id)}
                                    inputProps={{ 'aria-label': 'select grade' }}
                                />
                            </TableCell>
                            <TableCell component="th" scope="row">
                                {row.grade_name}
                            </TableCell>
                            <TableCell align="center">{row.begin_time}</TableCell>
                            <TableCell align="center">{row.student_count}</TableCell>
                            <TableCell align="center">{row.usernames}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
                    </div>
                </div>
                
            </Box>
            </div>
          </Box>
        </Box>


        {isLoading && (
        <LoadingData text={t(loadingText)}/>
      )}
    </div>
    )
}

export default ConfigHomework