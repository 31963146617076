import React, { useRef, useState, useEffect } from 'react';

import Cropper from 'react-cropper';
import 'cropperjs/dist/cropper.css';
import { Button,Box,Grid,Typography,Paper} from '@mui/material';
import styled from 'styled-components';
import 'katex/dist/katex.min.css';
import { InlineMath } from 'react-katex';
import { getHostName } from '../../utils/urlUtils';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from '@mui/material';

import PhotoCamera from '@mui/icons-material/PhotoCamera';
import ImageSearch from '@mui/icons-material/ImageSearch';
import Crop from '@mui/icons-material/Crop';
import Close from '@mui/icons-material/Close';
import Paste from '@mui/icons-material/ContentPaste';
import Collections from '@mui/icons-material/Collections';
import Visibility from '@mui/icons-material/Visibility';
import Tooltip from '@mui/material/Tooltip';
import LoadingData from '../dataloading';
import { useSnackbar } from '../../SnackbarProvider';
import CustomButton from '../../components/CustomButton';

import remarkMath from 'remark-math';
import rehypeKatex from 'rehype-katex';
import rehypeRaw from 'rehype-raw'; 
import ReactMarkdown from 'react-markdown';
//executeType:common,doc

function PhotoComponent({ onClose, ocrType,subject,moduleName,handleCustomRecognize}) {
    const { t } = useTranslation();
    const videoRef = useRef(null);
    const cropperRef = useRef(null);
    const [recognizedText, setRecognizedText] = useState('');
    const [capturedImage, setCapturedImage] = useState(null);
    const [isVideoVisible, setIsVideoVisible] = useState(true);
    const [videoDimensions, setVideoDimensions] = useState({ width: 0, height: 0 });
    const isMobile = useMediaQuery('(max-width:600px)');
    const isTablet = useMediaQuery('(min-width:601px) and (max-width:1024px)');
    const [loadingText,setLoadingText] = useState(false);
    const [inputImage, setInputImage] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const fileInputRef = useRef(null);  // 在函数组件的开始部分添加这一行
    const showSnackbar = useSnackbar();
    const [text, setText] = useState('');


    const [isEditorVisible, setIsEditorVisible] = useState(false);
    // 适配摄像头和截图区域的尺寸
  let adaptedStyle;
  if (isMobile) {
    adaptedStyle = { height: '50%', width: '100%' };
  } else if (isTablet) {
    adaptedStyle = { height: '70%', width: '100%' };
  } else {
    adaptedStyle = { height: '90%', width: '100%' };
  }

  const constraints1 = isMobile 
  ? { video: { facingMode: 'environment', width: { ideal: 480 }, height: { ideal: 320 } } } 
  : { video: true };

  const constraints = {
    video: { facingMode: 'environment' } // or 'user' for front camera
};


    const handleConfirm = () => {
        if(moduleName == "common"){
            onClose(text);
        }
        if(moduleName == "exampaper"){
            onClose(textJson);
        }
        if(moduleName === "doc"){
          onClose(text);
        }
        
    };


    useEffect(() => {
        const calculateDimensions = () => {
          const screenWidth = window.innerWidth;
          const screenHeight = window.innerHeight;
    
          let defaultWidth, defaultHeight;
    
          if (screenWidth < 600) {
            // 手机：宽度占据屏幕的90%，高度占据屏幕高度的70%
            defaultWidth = screenWidth * 0.9;
            defaultHeight = screenHeight * 0.7;
          } else if (screenWidth < 1024) {
            // 平板：宽度占据屏幕的80%，高度占据屏幕高度的80%
            defaultWidth = screenWidth * 0.8;
            defaultHeight = screenHeight * 0.8;
          } else {
            // 电脑：使用固定的宽度800px，高度600px
            defaultWidth = 800;
            defaultHeight = 500;
          }
    
          setVideoDimensions({ width: defaultWidth, height: defaultHeight });
        };
    
        // 在组件加载时计算默认值
        calculateDimensions();
    
        // 添加窗口大小变化的事件监听器，以便在窗口大小变化时更新默认值
        window.addEventListener('resize', calculateDimensions);
    
        // 在组件卸载时移除事件监听器
        return () => {
          window.removeEventListener('resize', calculateDimensions);
        };
      }, []); // 依赖项为空数组，表示只在组件加载和卸载时执行



    useEffect(() => {
        const startVideoStream = () => {
            if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
                const isMobile = window.innerWidth <= 800 && window.innerHeight <= 600;
                //const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
             
                navigator.mediaDevices.getUserMedia(constraints)
                    .then(function (stream) {
                        if (videoRef.current) {
                            videoRef.current.srcObject = stream;
                            if (videoRef.current.readyState > 3) {
                                videoRef.current.play().catch(err => console.log(err));
                              }
                              
                            videoRef.current.onloadedmetadata = () => {
                                setVideoDimensions({
                                    width: videoRef.current.videoWidth,
                                    height: videoRef.current.videoHeight
                                });
                            }
                        }
                    })
                    .catch(function (err) {
                        console.error("Error accessing camera: ", err);
                    });
            }
        };
        startVideoStream();
        return () => {
            if (videoRef.current && videoRef.current.srcObject) {
                videoRef.current.srcObject.getTracks().forEach(track => track.stop());
            }
        };
    }, []);


    const [textJson,setTextJson] = useState({});

    const handleRecognize = async () => {
        console.log("OCRTYPE:" + ocrType);
        console.log("subject:" + subject);
        // 获取 Cropper 实例
        const cropperInstance = cropperRef.current.cropper;  // 注意这里的改动
        if (cropperInstance) {
          const croppedCanvas = cropperInstance.getCroppedCanvas();
          if (croppedCanvas) {
            const base64Image = croppedCanvas.toDataURL();
            var send_ocrType = "";
            if(ocrType === "subject"){ //学科识别，一般都是题目
                if(subject === "mathematics"){ send_ocrType = "Math_All_page";}
                if(subject === "chemistry"){send_ocrType = "Chemistry_Diagram_OCR";}
                if(subject === "physics"){send_ocrType = "Math_All_page";}
                if(subject === "suBiologybject"){send_ocrType = "Math_All_page";}
                if(subject === "geography"){send_ocrType = "Math_All_page";}
                if(subject === "chinese"){send_ocrType = "Math_All_page";}
                if(subject === "english"){send_ocrType = "Math_All_page";}
            }
            try 
            {
              if(moduleName === "doc"){
                handleCustomRecognize(base64Image);

                return;
                
              }

                setLoadingText(t('parsing'));
                setIsLoading(true);

                const token = localStorage.getItem("access_token");
                
                let interfaceName = "";
                if(moduleName === "common"){
                    interfaceName = "getmathocr";
                }
                if(moduleName === "exampaper"){
                    interfaceName = "parse_exampaper_ocr_json";
                }

                if(moduleName === "doc"){
                  interfaceName = "getimagetext";
                }
                let url = getHostName() + '/' + interfaceName;
                const response = await fetch(url, {
                    method: "POST",
                    headers: {'Content-Type': 'application/json',
                  'Authorization': `Bearer ${token}`},
                    body:JSON.stringify({ocrType:send_ocrType, image:base64Image})
                    });
        
                if (response.ok) {
                    const data = await response.json();
                    const mathtext = data.message;
                    console.log("mathtext:::" + mathtext);
                    if(moduleName == "common"){

                    
                        setRecognizedText(mathtext)
                        let latexText = renderLatex(mathtext);
                        //let newText = text + "\n" + "------------------------------------------------" + "\n" + latexText;
                        setText(mathtext);
                    }
                    if(moduleName == "exampaper"){
                        setTextJson(mathtext);
                    }

                    if(moduleName === "doc"){

                    }
                    setIsEditorVisible(true);
                    setIsLoading(false);
                }
                else {
                    console.error("Error fetching reply:", response.statusText);
                    setIsLoading(false);
                }
            }
            catch (error) {
                console.error("There was an error fetching the reply:", error);
                setIsLoading(false);
            }
          }
        }
    };
    
    const handleCapture = () => {
        const canvas = document.createElement("canvas");
        canvas.width = videoRef.current.videoWidth;
        canvas.height = videoRef.current.videoHeight;
        canvas.getContext("2d").drawImage(videoRef.current, 0, 0);
        const imageData = canvas.toDataURL("image/png");
        setCapturedImage(imageData);
        setIsVideoVisible(false);
    };

    const handleRetake = () => {
        setIsEditorVisible(false);
        setIsVideoVisible(true);
        setCapturedImage(null);
        if (videoRef.current && videoRef.current.srcObject) {
            videoRef.current.srcObject.getTracks().forEach(track => track.stop());
        }
        // Re-initialize the video stream
        if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {

                navigator.mediaDevices.getUserMedia(constraints)
                .then(function (stream) {
                    if (videoRef.current) {
                        videoRef.current.srcObject = stream;
                        if (videoRef.current.readyState > 3) {
                            videoRef.current.play().catch(err => console.log(err));
                          }
                          
                    }
                })
                .catch(function (err) {
                    console.error("Error accessing camera: ", err);
                });
        }
    };
    
    const handleChange = (event) => {
        setText(event.target.value);
      };

    const renderLatex = (text) => {
        const regex = /\$(.*?)\$/g;
        let match;
        let lastIndex = 0;
        const elements = [];
      
        while ((match = regex.exec(text)) !== null) {
          const latex = match[1];
          const preText = text.slice(lastIndex, match.index);
          lastIndex = regex.lastIndex;
      
          elements.push(preText);
          elements.push(<InlineMath key={lastIndex}>{latex}</InlineMath>);
        }
      
        elements.push(text.slice(lastIndex));
      
        return elements;
      };
      
    const handleUploadImage = () => {
        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
    };


  
    useEffect(() => {
      // 在 capturedImage 状态更新后执行相关操作
      // 没有这里代码，点击粘贴图片2次才能在界面出现
      setIsVideoVisible(false);
    }, [capturedImage]); // 监听 capturedImage 状态的变化

    const handlePasteImage = async () => {
        try {
            setIsEditorVisible(false);
            const clipboardItems = await navigator.clipboard.read();
            const clipboardData = clipboardItems[0];
            const blob = await clipboardData.getType('image/png'); // 假设图像是png类型
            const reader = new FileReader();
    
            reader.onload = (event) => {
                const image = new Image();
                image.src = event.target.result;
    
                image.onload = () => {
                    const canvas = document.createElement("canvas");
    
                    // 计算图片的宽高比
                    const aspectRatio = image.width / image.height;
    
                    // 如果视频可见，使用视频尺寸，否则使用图片尺寸
                    let canvasWidth, canvasHeight;
                    if (isVideoVisible && videoRef.current) {
                        canvasWidth = videoRef.current.videoWidth;
                        canvasHeight = videoRef.current.videoHeight;
                    } else {
                        canvasWidth = image.width;
                        canvasHeight = image.height;
                    }
    
                    // 调整画布尺寸以保持图片比例
                    if (canvasWidth / canvasHeight > aspectRatio) {
                        // 画布过宽，调整宽度
                        canvasWidth = canvasHeight * aspectRatio;
                    } else {
                        // 画布过高，调整高度
                        canvasHeight = canvasWidth / aspectRatio;
                    }
    
                    canvas.width = canvasWidth;
                    canvas.height = canvasHeight;
                    const ctx = canvas.getContext("2d");
    
                    // 将图片居中绘制到画布
                    ctx.drawImage(image, 0, 0, canvasWidth, canvasHeight);
                    const imageData = canvas.toDataURL("image/png");
                    setCapturedImage(imageData);
                   
                    setIsVideoVisible(false);
                   
                };
            };
    
            reader.readAsDataURL(blob);
        } catch (e) {
            console.error("An error occurred while accessing clipboard: ", e);
        }
    };
    
    const handleCheckText = async () => {
        setIsEditorVisible(!isEditorVisible);

    }


    const handleExecute = async (label) => {
      if(label === "select img"){
        handleUploadImage();
      }
      if(label === "photo"){
        handleRetake();
      }
      if(label === "screenshot"){
        handleCapture();
      }
      if(label === "paste image"){
        handlePasteImage();
      }
      if(label === "OCR recognition"){
        handleRecognize();
      }
      if(label === "view transcription"){
        handleCheckText();
      }
      
    }


    const iconData = [
      { icon: <Collections />, label: 'select img',help:'selecting images from the device catalog'},
      { icon: <PhotoCamera />, label: 'photo' ,help:'taking pictures with the camera'},
      { icon: <Crop />, label: 'screenshot' ,help:'capture the image and frame it'},
      { icon: <Paste />, label: 'paste image',help:'pasting pictures from the clipboard'},
      { icon: <ImageSearch />, label: 'OCR recognition' ,help:'OCR recognize image text'},
      { icon: <Visibility />, label: 'view transcription',help:'view recognized text'}
    ];

      const [tooltip, setTooltip] = useState('');
    
      const handleTextClick = (help) => {
        console.log(help);
        setTooltip(help);
        setCountdown(5);
      };


      const containerStyle = {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        backgroundColor: 'white',
        padding: '20px'
      };
    
      const tooltipStyle = {
        position: 'fixed',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        backgroundColor: 'rgba(0, 0, 255, 0.5)',
        padding: '10px 20px',
        borderRadius: '5px',
        textAlign: 'center',
        zIndex:99999
      };
    
      const [countdown, setCountdown] = useState(5);
     
      
      useEffect(() => {
        let timer;
        if (tooltip) {
          timer = setInterval(() => {
            setCountdown((prev) => {
              if (prev === 0) {
                clearInterval(timer);
                setTooltip('');
                return 0;
              }
              return prev - 1;
            });
          }, 1000);
        }
        return () => clearInterval(timer);
      }, [tooltip]);

      const style = {
        zIndex:999999,
        position: 'fixed',  // 使用 fixed 而非 absolute 以便始终居中
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '70vw',
        height: '70vh',
        backgroundColor: 'white',  // 可以自定义背景颜色
        boxShadow: '0 2px 10px rgba(0, 0, 0, 0.3)',  // 可选的阴影效果
       
        // 添加其他必要的样式
      };

    return (
      <Paper
        elevation={3}
        style={style} sx={{ position: 'relative' }}>
      
        <div style={containerStyle}>
          <Grid container spacing={2} justifyContent="center">
            {iconData.map((item, index) => (
              <Grid item key={index}>
                <div style={{ textAlign: 'center' }}>
                <Typography
                    variant="body2"
                    style={{ color: '#163172', cursor: 'pointer' }}
                    onClick={() => handleTextClick(item.help)}
                  >
                    {t(item.label)}
                  </Typography>
                  <hr style={{ width: '50%', margin: '5px auto' }} />
                  <div style={{ paddingBottom: '5px' }}>
                    <Button
                      style={{
                        color: '#163172',
                        borderColor: 'transparent'
                      }}
                      onClick={() => handleExecute(item.label)}
                    >
                      {item.icon}
                    </Button>
                  </div>
                </div>
              </Grid>
            ))}
          </Grid>
          {tooltip && (
            <div style={tooltipStyle}>
              <Typography variant="body2">{t(tooltip)}</Typography>
              <Typography variant="body2">
                {`${countdown}s`}
              </Typography>
            </div>
          )}
        </div>

      <div>
        <div style={{backgroundColor:'white', display: 'flex', justifyContent: 'center', alignItems: 'center',border: '1px solid black',height:'550px'}}>
        {isEditorVisible ? (
          
          <div style={{height:'100%',width:'100%'}}>
          <div 
            style={{
              display: 'flex',
              flexDirection: 'column',
              backgroundColor: '#2a3d66',
              wordWrap: 'break-word',
              overflowY: 'auto',
              padding: '10px',
              height: '60%',
              maxWidth: '100%',
              border: '1px solid white',
              gap: '10px' // 设置间隔
            }} 
            className="centered-markdown"
          >
            
              <ReactMarkdown
                children={text}
                remarkPlugins={[remarkMath]}
                rehypePlugins={[rehypeRaw, rehypeKatex]} // 注意插件的顺序，rehypeRaw 先于 rehypeKatex
              />
              
            </div>
            <div style={{height:'40%'}}>
            <span>{t('edit area')}</span>
              <textarea style={{width:'100%',height:'25%'}} value={text} onChange={handleChange} />
              </div>
            </div>
            ) :
          isVideoVisible ? (
                      <video ref={videoRef} autoPlay={true}></video>
                  ) : (
                      <Cropper
                      ref={cropperRef}
                      src={capturedImage}
                      //style={{ height: videoDimensions.height, width: videoDimensions.width }}
                      style={{maxHeight:'600px',maxWidth:'1200px'}}
                      initialAspectRatio={NaN}  // Allow free crop ratio
                      autoCropArea={1}          // Initial crop area set to max
                      viewMode={1}              // Restrict the crop box to not exceed the size of the canvas
                      background={false}        // Remove the grid background
                      highlight={false}         // Remove the white highlight on the cropped box
                      dragMode="crop"           // Enable crop mode by default
                      guides={true}             // Show the dashed lines
                      movable={true}
                      resizable={true}
                      />
                  )}
        </div>
        
        

        <Box
          sx={{
            display: 'flex', // 启用 flex 布局
            flexDirection: 'row', // 子项水平排列
            alignItems: 'center', // 垂直居中对齐子项
            justifyContent:'right',
            gap: 2, // 子项之间的间隔
            mr: '0px', // 添加右边外边距30px
            marginTop:'20px',
          }}
        >
          <CustomButton
            onClick={handleConfirm}
            size="medium"
          >
            {t('close')}
          </CustomButton>

          <CustomButton
            onClick={handleConfirm}
            size="medium"
          >
            {t('confirm')}
          </CustomButton>
           
        </Box>
        

      </div>
      <input 
          type="file" 
          accept="image/*" 
          ref={fileInputRef} 
          style={{ display: 'none' }} 
          onChange={(e) => {
              const file = e.target.files[0];
              const reader = new FileReader();
              reader.onload = function(event) {
                  setInputImage(event.target.result);
                  setCapturedImage(event.target.result);
                  setIsVideoVisible(false);
              };
              reader.readAsDataURL(file);
          }} 
        />
        {isLoading && (
            <LoadingData text={t(loadingText)}/>
        )}
    </Paper>
    );
}

export default PhotoComponent;
