import React, { useState ,useRef,useEffect ,useCallback  } from 'react';


import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import styled from '@emotion/styled';

import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, IconButton ,Container} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import { useTranslation } from 'react-i18next';
import { requestUrl } from '../../requestUrl';
import LoadingData from '../../education/dataloading';
import PicturebookAddEdit from './PicturebookAddEdit';
import Pagination from '@mui/material/Pagination';
const ContainerMain = styled.div`
  display: flex;
  flex-direction: column;
  height: 90%;
  width:90%;
`;

const ButtonArea = styled.div`
  padding: 10px;
  background: #f0f0f0;
`;

const ContentArea = styled.div`
  flex-grow: 1;
  padding: 20px;
  background: #ffffff;
`;



const PicturebookManage = () => {
  const overlayContainerStyle = {
    position: 'fixed', // 或者 'absolute'，取决于父容器
    top: 0,
    left: 0,
    width: '100vw', // 视口宽度
    height: '100vh', // 视口高度
    backgroundColor: 'rgba(0, 0, 0, 1)', // 半透明背景
    zIndex: 1000, // 确保覆盖其他元素
  };
      // Inline styles
      const styles = {
        container: {
            display: 'flex',
            flexDirection: 'row',
            width: '100vw',  // 100% of viewport width
            height: '100vh', // 100% of viewport height
            overflow: 'hidden' // Prevents scrollbars if content overflows
        },
        menu: {
            width: '300px',
            height: '100%',   // Full height
            borderRight: '1px solid #ddd',
            padding: '10px',
            overflowY: 'auto' // Allows scrolling for long menus
        },
        content: {
            flex: 1,
            height: '100%',  // Full height
            padding: '10px',
            overflowY: 'auto' // Allows scrolling for long content
        }
    };
    const { t } = useTranslation();
    const [storyList,setStoryList] = useState([]);
    const [loading, setLoading] = useState(false);
    const [loadingText,setLoadingText] = useState('loading');
    const [isVisibleAddEdit,setIsVisibleAddEdit] = useState(false);

    const [pictureBookId,setPictureBookId] = useState(0);
    const [pbRecord,setPbRecord] = useState({});
    const [pictureBookDetail,setPictureBookDetail] =useState([]);


    const [pageCount, setPageCount] = useState(10); // 初始页数
    const [currentPage, setCurrentPage] = useState(1);

    const handleEditClick = async (id) => {
        
        // 你可以在这里添加更多的逻辑，比如打开一个编辑对话框
        setPictureBookId(id);
        const record = storyList.find(item => item.id === id);
        setPbRecord(record);

        try {
          setLoading(true);
          setLoadingText("loading");
          const dataToSend = {
              pbid: id,
  
          };
          const data = await requestUrl(dataToSend, "get_story_detail_by_pbid");
          
          if(data.status === "success"){
            setPictureBookDetail(data.data);
          }
      } catch (error) {
          console.error("获取数据时出错:", error);
      } finally {
        setLoading(false);
      }
        setIsVisibleAddEdit(true);
      };
    


      useEffect(() => {
        loadStoryList(0,1,20);
    }, []);  // 依赖数组


      const loadStoryList = async (grade,pagenum,pagesize) => {

        try {
          setLoading(true);
          setLoadingText("loading");
          const dataToSend = {
              grade: grade,
              page: pagenum,
              pagesize: pagesize,
          };
          const data = await requestUrl(dataToSend, "get_picturebook_list");
          
          if(data.status === "success"){
            setStoryList(data.data);
          }
        } catch (error) {
            console.error("获取数据时出错:", error);
        } finally {
          setLoading(false);
        }
      }

      const handleAddPB = () => {
        setIsVisibleAddEdit(true);
      }
      
      const handleCloseAddEdit = (isAddOrEdit,data) => {    
        setPictureBookId(0);
        setPbRecord({});
        setPictureBookDetail([]);
        setIsVisibleAddEdit(false);

        if(isAddOrEdit === "add"){
          addDataAndUpdateList(data);
        }
        if(isAddOrEdit === "edit"){
          editStoryById(data.id,data);
        }
        
      }
      const editStoryById = (id, newData) => {
        setStoryList(prevStoryList => {
          return prevStoryList.map(story => {
            // 检查当前元素的id是否与我们要编辑的id匹配
            if (story.id === id) {
              // 如果匹配，使用新数据来更新这个元素
              return { ...story, ...newData };
            } else {
              // 如果不匹配，保持原来的元素不变
              return story;
            }
          });
        });
      };
      
      

      const handleQuery = () => {
        loadStoryList(0,0,10);
      }


      const addDataAndUpdateList = (data) => {
        // 使用函数式更新来确保我们获得最新的状态
        setStoryList(prevStoryList => {
          // 创建数组的浅拷贝，以避免直接修改原始状态
          const newStoryList = [...prevStoryList];
      
          // 在数组的开始位置插入新元素
          newStoryList.unshift(data);
      
          // 如果数组长度超过1（即至少有一个元素），则移除最后一个元素
          if (newStoryList.length > 1) {
            newStoryList.pop();
          }
      
          // 返回更新后的数组作为新状态
          return newStoryList;
        });
      }

      

  const handlePublish = async (index,id,status) => {
    
    let newstatus = 0;
    if(status ===0){
      newstatus = 1;
    }

    if(status ===1){
      newstatus = 0;
    }

    const newStorylist = [...storyList];
    newStorylist[index] = { ...newStorylist[index], status: newstatus };
    setStoryList(newStorylist);

    try {
      setLoading(true);
      setLoadingText("loading");
      const dataToSend = {
          id:id, 
          status:newstatus, 
      };
      
      const data = await requestUrl(dataToSend, "picturebook_update_status");
      
      if(data.status === "success"){
         
      }
    } 
    catch (error) {
        console.error("获取数据时出错:", error);
    }
    finally {
      setLoading(false);
    }
  }


  const handlePageChange = (event, value) => {
    // 处理分页逻辑
    loadStoryList(0,value,10)
    
  };


    return (
      <Container style={{ maxWidth:'100%', maxHeight: '100%', overflow: 'auto' }}>
            <ContainerMain>

            <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginBottom: 2, marginTop: '20px' }}>
            <Button onClick={handleAddPB} variant="contained" color="primary"  sx={{ marginRight: '20px' }}>
            新增
        </Button>
        <Button onClick={handleQuery} variant="contained" color="secondary"  sx={{ marginRight: '20px' }}>
          查询
        </Button>
            </Box>

      <ContentArea>
      <TableContainer component={Paper} style={{ maxHeight: '70vh', overflow: 'auto' }}>
      <Table stickyHeader sx={{ width: '1024px' }} aria-label="simple table">
        <TableHead>
          <TableRow>
           
            <TableCell align="right">Grade</TableCell>
            <TableCell align="right">Title (Local Language)</TableCell>
            <TableCell align="right">Record Time</TableCell>
            
            <TableCell align="right">Thumbnail</TableCell>
            {/* 这里可以添加更多列标题 */}
            <TableCell align="right">Edit</TableCell>
            <TableCell align="right">public</TableCell>
            <TableCell align="right">public o</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {storyList.map((record,index) => (
            <TableRow key={record.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
              
              <TableCell align="right" sx={{ width: '10%' }}>{record.grade}</TableCell>
              <TableCell align="right"  sx={{ width: '30%' }}>{record.title_local_lang}</TableCell>
              <TableCell align="right" sx={{ width: '15%' }}>{record.recordtime}</TableCell>
              
              <TableCell align="right" sx={{ width: '20%' }}><img src={record.thumbnail_path} style={{ width: '50px', height: 'auto' }} /></TableCell>
              {/* 这里可以添加更多列数据 */}
              <TableCell align="right" sx={{ width: '10%' }}>
                <IconButton onClick={() => handleEditClick(record.id)}>
                  <EditIcon />
                </IconButton>
              </TableCell>
              <TableCell align="right" sx={{ width: '15%' }}>
                {record.status === 0 ? (
                  <div>未发布</div>
                ) : (
                    <div>已发布</div>
                )}
              </TableCell>
              <TableCell align="right" sx={{ width: '15%' }}>
                <Button 
                  onClick={() => handlePublish(index,record.id,record.status)}
                  variant="contained" 
                  color="primary" 
                  sx={{ marginRight: '20px' }}
                >
                  {record.status === 0 ? '发布' : '撤销'}
              </Button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
      </ContentArea>
      <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginBottom: 2, marginTop: '20px' }}>
      
        <Pagination 
        count={100} 
        onChange={handlePageChange} 
        style={{ marginTop: '20px' }} // 内置样式
      />
        {/* 可以继续添加更多按钮 */}
      </Box>
    </ContainerMain>

    {loading && (
      <LoadingData text={t(loadingText)}/>
    )}

    {isVisibleAddEdit && (
      <div style={overlayContainerStyle}>
      <PicturebookAddEdit onClose={handleCloseAddEdit} pbid={pictureBookId} pbRecord={pbRecord} pbDetails={pictureBookDetail}/>
      </div>
    )}
        </Container>
    );
};

export default PicturebookManage;
