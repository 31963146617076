import React, { useState, useEffect,useRef } from 'react';
import styled, { keyframes } from 'styled-components';
import {
    AppBar,
    Tabs,
    Tab,
    useMediaQuery,
    IconButton,
    Fade,
    Dialog 
} from '@mui/material';
import ArrowBackIos from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIos from '@mui/icons-material/ArrowForwardIos';
import ReactMarkdown from 'react-markdown';
import { Card, CardMedia, CardContent, Typography, Grid ,Paper,Button,List, ListItem, ListItemText } from '@mui/material';
import './default.css';
import { useTranslation } from 'react-i18next';
import { requestUrl } from '../requestUrl';
import { getDefaultContentMD } from '../getDefaultContent';

const gradientAnimation = keyframes`
    0% {
        background-position: 100% 0%;
    }
    100% {
        background-position: 0% 100%;
    }
`;

const AnimatedAppBar = styled(AppBar)`
    background: linear-gradient(90deg, #3b99ff 50%, #3b99ff 50%);
    background-size: 200% 100%;
    animation: ${gradientAnimation} 6s ease infinite;
    color: white;
    margin-top: 0;
`;

const iframeStyle = {
    width: "100%",
    height: "100%",
    border: "none",
};





const Container = styled.div`
  display: flex;
  height: 100vh; /* Full viewport height */
`;

const Menu = styled(List)`
  flex: 1;
  border-right: 1px solid #ccc;
  height: 100%;
  background-color: #444;  /* 设置菜单背景色 */
`;

const StyledListItem = styled(ListItem)`
  background-color: #333;
  color: white;
  display: flex;
  justify-content: top;
  align-items: top;
`;

const SliderContainer = styled.div`
  flex: 3;
  height: 100%;
  display: flex;
  align-items: top;
  justify-content: top;
  position: relative;
`;

const Slide = styled.div`
  opacity: ${(props) => (props.active ? '1' : '0')};
  transition: opacity 1s ease;
  position: absolute;
  display: flex;
  align-items: top;
  justify-content: top;
  width: 100%;
  height: 100%;
`;

const Image = styled.img`
  max-width: 100%;
  max-height: 90%;
  object-fit: cover;
`;





//英语部分

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const EnglishSpeakingSection = styled.section`
  background-color: #333;
  color: white;
  padding: 50px 0;
  display: flex;
  align-items: center;
  justify-content: space-around;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const Content = styled.div`
  width: 45%;
  text-align: left;
  animation: ${fadeIn} 1s ease;
  @media (max-width: 768px) {
    width: 90%;
    text-align: center;
  }
`;

const ImageContainer = styled.div`
  width: 45%;
  @media (max-width: 768px) {
    width: 100%;
  }
`;

const Title = styled.h2`
  font-size: 36px;
  text-align: center;
  margin-bottom: 30px;
  animation: ${fadeIn} 1s ease 0.5s backwards;
`;

const Subtitle = styled.p`
  font-size: 24px;
  font-weight: bold;
  text-align: center;
  margin-bottom: 20px;
  animation: ${fadeIn} 1s ease 1s backwards;
`;

const Feature = styled.div`
  font-size: 18px;
  line-height: 1.6;
  animation: ${fadeIn} 1s ease 1.5s backwards;
  &:not(:last-child) {
    margin-bottom: 15px;
  }
  span {
    font-weight: bold;
  }
`;

//数学
const laserMoveRightToLeft = keyframes`
  0% {
    left: calc(100% + 20px);
  }
  100% {
    left: -20px;
  }
`;
const LaserLineStyledRightToLeft = styled.div`
  width: 100%;
  height: 2px;

  position: relative;
  overflow: visible;
  background-color: #ffffff; // 白色
  box-shadow: 0 0 10px #ffffff, 0 0 20px #ffffff, 0 0 30px #ffffff, 0 0 40px #87CEFA, 0 0 50px #87CEFA, 0 0 60px #87CEFA, 0 0 70px #87CEFA; // 发光效果
  &:before {
    content: '';
    position: absolute;
    width: 10px; // 小球直径
    height: 10px; // 小球直径
    background: linear-gradient(to bottom, yellow, red); // 从黄色到红色的渐变
    border-radius: 50%; // 圆形
    box-shadow: 0 0 10px 5px orange; // 光晕效果
    z-index: 1;
    animation: ${laserMoveRightToLeft} 5s infinite linear;
  }
`;

const laserMove = keyframes`
  0% {
    left: -20px;
  }
  100% {
    left: calc(100% + 20px);
  }
`;

const MainContainerStyled = styled.div`
  background-color: #333;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const SectionContainerStyled = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin: 30px 0;
`;

const LaserLineStyled = styled.div`
  width: 100%;
  height: 2px;
  background-color: #ffffff; // 白色
  box-shadow: 0 0 10px #ffffff, 0 0 20px #ffffff, 0 0 30px #ffffff, 0 0 40px #87CEFA, 0 0 50px #87CEFA, 0 0 60px #87CEFA, 0 0 70px #87CEFA; // 发光效果
  position: relative;
  overflow: visible;
  box-shadow: 0 0 10px blue, 0 0 20px blue, 0 0 30px blue; // 发光效果
  &:before {
    content: '';
    position: absolute;
    width: 10px;
    height: 10px;
    background: linear-gradient(to bottom, yellow, red);
    border-radius: 50%;
    box-shadow: 0 0 10px 5px orange;
    z-index: 1;
    animation: ${laserMove} 5s infinite linear;
  }
`;

const SectionTitleStyled = styled.h2`
  font-size: 2rem;
  text-align: center;
`;

const SectionContentStyled = styled.div`
  font-size: 1rem;
  text-align: center;
  max-width: 600px;
  margin-left: 40px;
`;

const SectionImageStyled = styled.img`
  width: 100%;
  max-width: 500px;
`;


const Default = () => {
  const { t } = useTranslation();
    const [value, setValue] = useState(0);
    const [shouldAutoPlay, setShouldAutoPlay] = useState(true);

    const [defaultContent,setDefaultContent] = useState();
    const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));
 
    useEffect(() => {
        if (shouldAutoPlay) {
            const interval = setInterval(() => {
                setValue((prevValue) => (prevValue + 1) % 5);
            }, 4000);
            return () => clearInterval(interval);
        }
    }, [shouldAutoPlay]);

    const handleTabChange = (event, newValue) => {
        setValue(newValue);
    };

    const handleTabHover = (index) => {
        setValue(index);
    };

    const handlePrev = () => {
        setValue(value === 0 ? 4 : value - 1);
    };


    

    useEffect(() => {

      let lang = localStorage.getItem('language');
    
      // 如果 localStorage 中没有语言设置
      if (!lang) {
        // 获取浏览器默认语言并截取前两个字母
        lang = (navigator.language || navigator.userLanguage).substring(0, 2);
    
        // 存储到 localStorage 以便下次使用
        localStorage.setItem('language', lang);
      }
      const fetchData = async () => {      
        const dataToSend = {
          language: lang,
        };
        
        const data = await requestUrl(dataToSend, "get_default_content");
        
        setDefaultContent(data);
      };
      
      fetchData();
    }, []);
    

  
    return (
        <div style={{ width: "95%", margin: "0 auto", backgroundColor: "#f5f5f5", height: "100vh", overflow: "auto" }}
             onMouseEnter={() => setShouldAutoPlay(false)}
             onMouseLeave={() => setShouldAutoPlay(true)}
        >
         
            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", height: "calc(100vh - 64px)" }}>
                <IconButton onClick={handlePrev}>
                    <ArrowBackIos />
                </IconButton>
                <TabPanel value={value} index={0}>
                    {/**<iframe style={iframeStyle} src="jsbg/trefoil-knot-inside-torus/dist/index.html"></iframe> **/}
                    <div style={{ wordWrap: 'break-word', overflowY: 'auto', padding: '10px', maxWidth: '70vw' }} className="centered-markdown">
                  <ReactMarkdown>
                      {defaultContent}
                  </ReactMarkdown>
                  </div>
                </TabPanel>
                <TabPanel value={value} index={1}>
                    <MainContainerStyled>
                        <SectionContainerStyled>
                            <SectionImageStyled src='default/k4.png' alt='函数图像生成' />
                            <SectionContentStyled>
                            <SectionTitleStyled>精准针对性</SectionTitleStyled>
                            我们了解，数学对于大多数学生来说是一个难以攻克的科目。<br/>与传统的在线教育平台不同，我们希望你深挖每一个知识点!!!!!
                            <br/>
                            <SectionTitleStyled>独一无二的功能</SectionTitleStyled>
                            <h3>1.交互式图像生成:</h3>任意二维还是三维函数，我们都可以生成可交互的图像，帮助你更好地理解抽象概念。<br/>
                            2. 详尽解答：数学题目的解答过程分步骤进行，涵盖了涉及的所有知识点，并提供了深入的讲解和应用示例。<br/>
                            3. 丰富的教材集成：我们集成了几千个来自PHET和Geogebra的可交互教材，为你提供丰富、生动的学习体验。
                            </SectionContentStyled>
                            
                        </SectionContainerStyled>
                        <LaserLineStyled />
                        <SectionContainerStyled>
                            <SectionImageStyled src='default/k4.png' alt='函数图像生成' />
                            <SectionContentStyled>
                            <SectionTitleStyled>交叉应用</SectionTitleStyled>
                            通过具体案例，解释数学知识点在日常生活和实际应用场景中的作用。帮助学生更好地理解和记忆抽象概念，增加学习的兴趣和动机。
                            <SectionTitleStyled>我们的核心观点</SectionTitleStyled>
                            数学不仅仅是一门科学，它是一种艺术，一种语言。掌握这种语言的关键在于理解其基础元素：知识点。
                              <br/>
                            我们相信，只有深入理解了数学的每一个基础知识点，您才能在代数、几何、平面、空间、物理、化学，甚至生活中自如地应用它们。
                            </SectionContentStyled>
                        </SectionContainerStyled>
                        <LaserLineStyledRightToLeft />
                        
                        {/* ... 其他部分，从 image2.jpg 到 image6.jpg，并在它们之间也插入 <LaserLineStyled /> */}
                        
                    </MainContainerStyled>
                </TabPanel>
                <TabPanel value={value} index={2}>
                    <EnglishSpeakingSection>
                    <Content>
                            <Title>
                            全方位学科辅导 - 立体化的学科教学，解答你的所有疑惑
                            </Title>
                            <Subtitle>简介</Subtitle>
                            <Feature>
                            多维度解析：我们不只是提供标准答案，而是全面解析题目——从解题步骤到相关知识点，再到底层原理。
                            </Feature>
                            <Feature>
                            交叉学科应用：解释各学科（如数学、物理、化学等）中相同知识点的不同应用和相互联系。
                            </Feature>
                        </Content>
                        <ImageContainer>
                            {/* 这里可以添加视觉元素，比如图像或视频 */}
                            <img src="default/k4.png" alt="示例图片" style={{ width: '100%' }}/>
                        </ImageContainer>
                    </EnglishSpeakingSection>
                </TabPanel>
                <TabPanel value={value} index={3}>
                    <EnglishSpeakingSection>
                        <Content>
                            <Title>『英语口语实时对话』</Title>
                            <Subtitle>个性化设置</Subtitle>
                            <Feature><span>年级：</span>根据所在年级的内容练习口语</Feature>
                            <Feature><span>语速：</span>设置不同语速，循序渐进，每天一小步，积攒就是一大步</Feature>
                            <Feature><span>角色：</span>和不同角色对话</Feature>
                        </Content>
                        <ImageContainer>
                            {/* 这里是你的图片 */}
                            <img src="default/english.png" alt="英语口语" style={{ width: '100%' }} />
                        </ImageContainer>
                    </EnglishSpeakingSection>
                </TabPanel>
                <TabPanel value={value} index={4}>
                    <MainContainerStyled>
                            <SectionContainerStyled>
                                <SectionImageStyled src='default/k4.png' alt='函数图像生成' />
                                <SectionContentStyled>
                                <SectionTitleStyled>文档转向量库</SectionTitleStyled>
                                "上传任何格式的教育文档—无论是Markdown, PDF, 或者TXT文件—我们的先进AI算法将它们转化为向量库。这意味着您可以轻松地通过关键词或问题进行搜索，甚至生成Q&A对话。"
                                <SectionTitleStyled>音频/视频文件上传和转录</SectionTitleStyled>
                                "不仅如此，我们还支持音频和视频文件的上传。通过先进的转录技术，您的音频和视频资料将被转化为文本，并进一步生成时间轴。这样，您就可以直接点击时间轴上的点，播放相应位置的内容。"
                                </SectionContentStyled>
                                
                            </SectionContainerStyled>
                            <LaserLineStyled />
                            <SectionContainerStyled>
                                <SectionImageStyled src='default/k4.png' alt='函数图像生成' />
                                <SectionContentStyled>
                                <SectionTitleStyled>生成式对话</SectionTitleStyled>
                                "针对上传的音频和视频，我们的AI不仅能进行转录，还能根据内容进行生成式对话。这意味着，如果您有关于这些资源的任何问题，直接问AI家教，就像有个私人教师在身边一样。"
                                <SectionTitleStyled>交叉应用</SectionTitleStyled>
                                通过具体案例，解释数学知识点在日常生活和实际应用场景中的作用。帮助学生更好地理解和记忆抽象概念，增加学习的兴趣和动机。
                                </SectionContentStyled>
                            </SectionContainerStyled>
                            <LaserLineStyledRightToLeft />
                            
                            {/* ... 其他部分，从 image2.jpg 到 image6.jpg，并在它们之间也插入 <LaserLineStyled /> */}
                            
                        </MainContainerStyled>
                        
                </TabPanel>

                <TabPanel value={value} index={5}>
                    <MainContainerStyled>
                            <SectionContainerStyled>
                                <SectionImageStyled src='default/k4.png' alt='费曼学习法' />
                                <SectionContentStyled>
                                <SectionTitleStyled>打破传统辅导的界限</SectionTitleStyled>
                                在充满压力和竞争的学习环境中，传统的教育方式已不能满足现代家长和孩子的需求。我们的AI家教应用利用费曼学习法，为您的孩子提供个性化、高效的学习方案。
                                <SectionTitleStyled>. 费曼学习法：复杂知识，简单传递</SectionTitleStyled>
                                费曼学习法让学生扮演教师角色，通过解释和教授，深化自己对知识的理解。这不仅增强了理解力，还锻炼了批判性思考和解决问题的能力。
                                </SectionContentStyled>
                                
                            </SectionContainerStyled>
                            <LaserLineStyled />
                            <SectionContainerStyled>
                                <SectionImageStyled src='default/k4.png' alt='函数图像生成' />
                                <SectionContentStyled>
                                <SectionTitleStyled>交互式AI，真实模拟学习环境</SectionTitleStyled>
                                想象一下，一个全知的AI模型变成了一个好奇、渴望学习的学生。通过这种角色扮演，真正的学生能够更好地把握知识点，实现高效学习。
                                <SectionTitleStyled>成就未来的你，从现在开始</SectionTitleStyled>
                                不要等到孩子落后才开始追赶，优质的教育从现在开始。使用我们的AI家教应用，借助费曼学习法，让您的孩子走在成功的快车道上。
                                </SectionContentStyled>
                            </SectionContainerStyled>
                            <LaserLineStyledRightToLeft />
                            
                            {/* ... 其他部分，从 image2.jpg 到 image6.jpg，并在它们之间也插入 <LaserLineStyled /> */}
                            
                        </MainContainerStyled>
                        
                </TabPanel>
                <IconButton onClick={handlePrev}>
                    <ArrowForwardIos />
                </IconButton>
            </div>
        </div>
    );
};

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <Fade in={value === index} timeout={500}>
            <div
                role="tabpanel"
                hidden={value !== index}
                id={`menu-tabpanel-${index}`}
                aria-labelledby={`menu-tab-${index}`}
                {...other}
                style={{ width: "100%", height: "100%" }}
            >
                {children}
            </div>
        </Fade>
    );
}

export default Default;
