import React from 'react';
import './style.css';

const UseGrade = ({ defaultGrade, setDefaultGrade }) => {
  const gradelevels = [
    '一年级',
    '二年级',
    '三年级',
    '四年级',
    '五年级',
    '六年级',
    '初一',
    '初二',
    '初三',
    '高一',
    '高二',
    '高三',
    '大学',
    '口语',
  ];

  return (
    <div className='languages-container'>
      <label className='languages-label'>年级</label>
      <select
        className='select'
        value={defaultGrade}
        onChange={e => setDefaultGrade(e.target.value)}
      >
        <option disabled value=''>
          选择年级
        </option>
        {gradelevels.map((gradelevel, index) => (
          <option key={index} value={index + 1}>
            {gradelevel}
          </option>
        ))}
      </select>
    </div>
  );
};

export default UseGrade;
