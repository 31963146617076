import katex from "katex";
import "katex/dist/katex.css";
import ReactQuill, { Quill } from "react-quill";
import "react-quill/dist/quill.snow.css";
import "./jquery.js";
import MathQuill from 'react-mathquill';
import './mathquill4quill.css';
import mathquill4quill from "./mathquill4quill.js"
import React from "react";
import { addStyles, EditableMathField } from 'react-mathquill'
import './Editor.css';
import { withTranslation } from 'react-i18next';

import i18n from "./../i18n.js";
window.katex = katex;
addStyles()

export default class Editor extends React.Component {
  
  constructor(props) {
    super(props);
    this.reactQuill = React.createRef();
    this.handleResize = this.handleResize.bind(this);
    this.getEditorStyle = this.getEditorStyle.bind(this);
    this.state = {
      editorStyle: this.getEditorStyle()
    };
  }

  componentDidMount() {
    const enableMathQuillFormulaAuthoring = mathquill4quill({ Quill, katex });
    enableMathQuillFormulaAuthoring(
      this.reactQuill.current.editor,
      this.props.options
    );
    window.addEventListener('resize', this.handleResize);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  handleResize = () => {
    this.setState({ editorStyle: this.getEditorStyle() });
  };

  //<Editor width="500px" height="400px" />  <Editor ref={editorRef} options={options} key={JSON.stringify(options)}/>
  getEditorStyle1 = () => {
    // 如果有传入宽度和高度，就使用它们
    if (this.props.width && this.props.height) {
      return {
        width: this.props.width,
        height: this.props.height
      };
    }

    // 否则，按照现有的方式进行设置
    const width = window.innerWidth;
    const height = window.innerHeight;
    if (width <= 768) { // Mobile devices
      return {
        width: `${width * 0.95}px`,
        height: `${height * 0.95}px`
      };
    } else { // Tablets and desktops
      return {
        width: '350px',
        height: '300px'
      };
    }
  };

  getEditorStyle = () => {
    // 初始化样式对象
    let style = {
      backgroundColor: '#f3f3f3', // 背景色
      color: '#333' // 文字颜色
    };
  
    // 如果有传入宽度和高度，就使用它们
    if (this.props.width && this.props.height) {
      style.width = this.props.width;
      style.height = this.props.height;
      return style;
    }
  
    // 否则，按照现有的方式进行设置
    const width = window.innerWidth;
    const height = window.innerHeight;
    if (width <= 768) { // Mobile devices
      style.width = `${width * 0.95}px`;
      style.height = `${height * 0.95}px`;
    } else { // Tablets and desktops
      style.width = '350px';
      style.height = '300px';
    }
    return style;
  };
  


// 在 Editor 类中
getTextFromEditor = () => {
  if (this.reactQuill.current) {
    return this.reactQuill.current.getEditor().getText();
  }
  return null;
}

getFullContents = () => {
  if (this.reactQuill.current) {
    return this.reactQuill.current.getEditor().getContents();
  }
  return null;
}


// 在 Editor 类中添加这个方法
setFullContents = (delta) => {
  if (this.reactQuill.current) {
    this.reactQuill.current.getEditor().setContents(delta);
  }
}

render() {
  return (
    <div className="custom-editor">
    <ReactQuill 
      ref={this.reactQuill}
      id="editor"
      modules={{
        formula: true,
        toolbar: [["video", "bold", "italic", "underline", "formula"]]
      }}
      placeholder={i18n.t('Type text here, or click on the formula button to enter math')}
      theme="snow"
      style={this.state.editorStyle}
    />
    </div>
  );
}
}