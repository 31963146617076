import React, { useState,useRef,useEffect } from 'react';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import LoadingData from '../education/dataloading';
import { List, ListItem, TextField, Button, Container,InputAdornment,Box } from '@mui/material';

import { useTranslation } from 'react-i18next';

import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import CommonComboboxOne from '../components/CommonComboboxOne';
import ReactQuill from 'react-quill';
import './SystemHelpManage.css';
import { requestUrl } from '../requestUrl';
import ImageGallery from './ImageGallery';
import { javascript } from '@codemirror/lang-javascript';
import { useCodeMirror } from '@uiw/react-codemirror';
import { html } from '@codemirror/lang-html'; // 导入 HTML 语言支持
const SystemHelpManage = () => {

      // Inline styles
      const styles = {
        container: {
            display: 'flex',
            flexDirection: 'row',
            width: '100vw',  // 100% of viewport width
            height: '100vh', // 100% of viewport height
            overflow: 'hidden' // Prevents scrollbars if content overflows
        },
        menu: {
            width: '300px',
            height: '100%',   // Full height
            borderRight: '1px solid #ddd',
            padding: '10px',
            overflowY: 'auto' // Allows scrolling for long menus
        },
        content: {
            flex: 1,
            height: '100%',  // Full height
            padding: '10px',
            overflowY: 'auto' // Allows scrolling for long content
        }
    };
    const [isLoading, setIsLoading] = useState(false);
    const [loadingText,setLoadingText] = useState(false);
    const [open, setOpen] = useState(false);
    const [content, setContent] = useState('');

    const [selectedModule,setSelectedModule] = useState();
    const [selectedSubject,setSelectedSubject] = useState();
    const [isShowImageGallery,setIsShowImageGallery] = useState(false);
    const [showSourceEditor, setShowSourceEditor] = useState(false); // 控制显示哪个编辑器
    const { t } = useTranslation();






      const { setContainer } = useCodeMirror({
        value: content,
        extensions: [html()],
        onChange: (value, viewUpdate) => {
          setContent(value);
        },
        basicSetup: { lineWrapping: true },
        parent: {
          style: {
            height: '100%', // 确保 CodeMirror 填充整个父容器
          },
        },
      });



    const handleClick = () => {
        setOpen(!open);
    };


 // 内联样式来设置编辑器的宽高
 const editorStyle = {
    height: '70vh', // 高度设置为视口高度的90%
    width: '90%',   // 宽度设置为父容器宽度的90%
    color: 'black', // 默认文字颜色为黑色
  };

  const toolbarOptions = [
    ['bold', 'italic', 'underline', 'strike'],        // 加粗、斜体、下划线、删除线
    ['blockquote', 'code-block'],                     // 引用、代码块
  
    [{ 'header': 1 }, { 'header': 2 }],               // 标题
    [{ 'list': 'ordered'}, { 'list': 'bullet' }],     // 列表
    [{ 'script': 'sub'}, { 'script': 'super' }],      // 上标/下标
    [{ 'indent': '-1'}, { 'outdent': '+1' }],         // 缩进
    [{ 'direction': 'rtl' }],                         // 文本方向
  
    [{ 'size': ['small', false, 'large', 'huge'] }],  // 字体大小
    [{ 'header': [1, 2, 3, 4, 5, 6, false] }],        // 标题大小
  
    [{ 'color': [] }, { 'background': [] }],          // 字体颜色和背景颜色
    [{ 'font': [] }],                                 // 字体
    [{ 'align': [] }],                                // 对齐方式
  
    ['clean'],                                        // 清除格式
  
    ['link', 'image', 'video']                        // 链接、图片、视频
  ];
  
  // 设置React-Quill模块以使用自定义工具栏
  const modules = {
    toolbar: toolbarOptions,
  };

  

  useEffect(() => {
    // 创建一个style标签
    const style = document.createElement('style');
    style.type = 'text/css';
    // 使用更具体的选择器来覆盖默认样式
    style.innerHTML = `
      .ql-snow .ql-editor {
        color: black; /* 尝试更具体的选择器 */
      }
      .ql-snow .ql-editor * {
        color: inherit; /* 确保所有子元素继承颜色 */
      }
    `;
    // 将style标签添加到head中
    document.head.appendChild(style);
  }, []);

  

    const queryContent = async (module,subject) => {

        try {
            setIsLoading(true);
            setLoadingText("loading");
            const dataToSend = {
                module:module,
                subject:subject,
            };
            const data = await requestUrl(dataToSend, "query_system_help");
            
            if(data.status === "success"){
                setContent(data.content);
               // console.log(JSON.stringify(data.prompts));
            }
          } catch (error) {
              console.error("获取数据时出错:", error);
          } finally {
            setIsLoading(false);
          }
    }
    const handleSave = async() => {
        try {
            setIsLoading(true);
            setLoadingText("loading");
            const dataToSend = {
                module:selectedModule,
                subject:selectedSubject,
                content:content,
            };
            const data = await requestUrl(dataToSend, "system_help_save");
            
            if(data.status === "success"){
                setContent(data.content);
               // console.log(JSON.stringify(data.prompts));
            }
          } catch (error) {
              console.error("获取数据时出错:", error);
          } finally {
            setIsLoading(false);
          }
            
    };


    const handleSubjectClick = async (subject) => {
        setSelectedModule("mainstudy");
        setSelectedSubject(subject);
        queryContent("mainstudy",subject);
    };

    const handleModuleClick = async (item) => {
        setSelectedModule(item);
        setSelectedSubject("common");
        queryContent(item,"common");
    };

    const handleDisplayImageGallery = () => {
        setIsShowImageGallery(true);

    }
  
    const handleCloseImageGallery = () => {
        setIsShowImageGallery(false);

    }

    
    const handleChangeToHtml = () => {
        
    }
    
    return (
        <div style={styles.container}>
            
            <div style={styles.menu}>
            <List>
            <ListItemButton onClick={handleClick}>
                <ListItemText primary="学科" />
                {open ? <ExpandLess sx={{ color: 'white' }} /> : <ExpandMore sx={{ color: 'white' }} />}
            </ListItemButton>
            <Collapse in={open} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                    {['chinese', 'mathematics', 'english', 'physics', 'chemistry', 'geography', 'biology'].map(subject => (
                        <ListItemButton key={subject} sx={{ pl: 4 }} onClick={() => handleSubjectClick(subject)}>
                            <ListItemText primary={subject} />
                        </ListItemButton>
                    ))}
                </List>
            </Collapse>
            {['feman', 'parent', 'picture', 'knowledge'].map(item => (
                <ListItemButton key={item} onClick={() => handleModuleClick(item)}>
                    <ListItemText primary={item} />
                </ListItemButton>
            ))}
        </List>
            </div>
        

                <div style={{justifyContent:'flex-start',color:'black', alignItems:'flex-start' ,textAlign:'left',width:'100%',backgroundColor:'white'}}>
                <Box sx={{width:'70%', display: 'flex', justifyContent: 'flex-end', marginBottom: 2, marginTop: '20px' }}>
                    <Button onClick={handleSave} variant="contained" color="primary"  sx={{ marginRight: '20px' }}>
                    保存
                    </Button>
                    <Button variant="contained" color="secondary"  sx={{ marginRight: '20px' }}>
                    查询
                    </Button>
                    <Button onClick={handleDisplayImageGallery} variant="contained" color="secondary"  sx={{ marginRight: '20px' }}>
                    选择图片
                    </Button>
                    <Button onClick={() => setShowSourceEditor(!showSourceEditor)} variant="contained" color="secondary"  sx={{ marginRight: '20px' }}>
                    html
                    </Button>
                    </Box>
                    <Container sx={{height:'100%'}} maxWidth="md"> {/* 使用 Container 限制最大宽度 */}

                    {showSourceEditor ? (
                           <div ref={setContainer} style={{ height: '100%', width: '100%' }} />
                        ) : (
                            <ReactQuill
                            theme="snow"
                            value={content}
                            onChange={setContent}
                            style={editorStyle}
                            modules={modules}
                            />
                        )}
                    </Container>
            </div>
            
            {isLoading && (
               <LoadingData text={t(loadingText)}/>
            )}
            {isShowImageGallery && (
                
                <ImageGallery onClose={handleCloseImageGallery} folder="systemhelp" />

            )}

            
        </div>
    );
};

export default SystemHelpManage;
