// LatexTemplateModule.jsx
import React, { useState } from 'react';
import { Tabs } from 'antd';
import styled from 'styled-components';
import 'antd/dist/reset.css';
import { message ,Popover ,Input } from 'antd';
import katex from 'katex';

const { TabPane } = Tabs;

const Container = styled.div`
  width: 100%;
  height: 100%;
  background-color: #3b99ff;
  color: white !important;
`;

const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.7);
  z-index: 1000;
  color: white !important;
`;



// 定义一个样式化的关闭按钮
const CloseButton = styled.button`
  position: absolute;
  bottom: 20px;
  right: 20px;
  background-color: #f44336; /* 红色 */
  border: none;
  color: white;
  padding: 12px 24px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
  border-radius: 12px;
`;

const TemplateItem = styled.div`
  background-color: lightgray; 
  cursor: pointer; 
  margin: 5px; // Add a little margin around each item
  display: inline-block; // Make it inline
  padding: 5px; // Padding for better appearance
  color: white !important;
`;


export const LatexTemplateModule = ({ onClose }) => {

    const templateData = {
        physics: {
          方程式: [
            { name: "Newton's Second Law", latex: 'F = m \\times a' },
            { name: 'Speed', latex: 'v = \\frac{d}{t}' },
            // 添加更多物理方程式
          ],
          热力学: [
            { name: 'First Law of Thermodynamics', latex: '\\Delta U = Q - W' },
            // 添加更多热力学方程式
          ],
          电磁学: [
            { name: 'Ohm’s Law', latex: 'V = I \\times R' },
            // 添加更多电磁学方程式
          ]
        },
        chemistry: {
          方程式: [
            { name: 'Ideal Gas Law', latex: 'PV = nRT' },
            { name: 'Molarity', latex: 'M = \\frac{n}{V}' },
            // 添加更多化学方程式
          ],
          stoichiometry: [
            { name: 'Mole Ratio', latex: '\\frac{a}{b}' },
            // 添加更多化学计量方程式
          ],
          有机化学: [
            { name: 'Alkane Formula', latex: 'C_nH_{2n+2}' },
            // 添加更多有机化学方程式
          ]
        },
        math: {
            arithmetic: [
                { name: 'Addition', latex: 'a + b' },
                { name: 'Subtraction', latex: 'a - b' },
                { name: 'Multiplication', latex: 'a \\times b' },
                { name: 'Division', latex: '\\frac{a}{b}' },
                { name: 'Square Root', latex: '\\sqrt{a}' },
                { name: 'Cube Root', latex: '\\sqrt[3]{a}' },
                { name: 'Average', latex: '\\frac{a + b + c}{3}' },
                { name: 'Percentage', latex: '\\frac{a}{100}' },
                { name: 'Decimal to Fraction', latex: '\\frac{a}{10^n}' },
                { name: 'Fraction Addition', latex: '\\frac{a}{c} + \\frac{b}{d}' },
              ],
              代数: [
                { name: 'One-variable Equation', latex: 'ax + b = 0' },
                { name: 'Two-variable Equation', latex: 'ax + by = c' },
                { name: 'Quadratic Equation', latex: 'ax^2 + bx + c = 0' },
                { name: 'Factorization', latex: '(a + b)^2' },
                { name: 'Expansion', latex: '(a + b)(a - b)' },
                { name: 'Absolute Value', latex: '|a|' },
                { name: 'Simplification', latex: 'a(a + b)' },
                { name: 'Exponential', latex: 'a^b' },
                { name: 'Square', latex: 'a^2' },
                { name: 'Cube', latex: 'a^3' },
              ],
              几何: [
                { name: 'Circle Circumference', latex: '2\\pi r' },
                { name: 'Circle Area', latex: '\\pi r^2' },
                { name: 'Triangle Area', latex: '\\frac{1}{2} \\times b \\times h' },
                { name: 'Rectangle Area', latex: 'l \\times w' },
                { name: 'Square Area', latex: 'a^2' },
                { name: 'Rectangle Perimeter', latex: '2(l + w)' },
                { name: 'Square Perimeter', latex: '4a' },
                { name: 'Triangle Perimeter', latex: 'a + b + c' },
                { name: 'Pythagorean Theorem', latex: 'a^2 + b^2 = c^2' },
                { name: 'Angle Sum of Triangle', latex: 'A + B + C = 180' },
              ],
        },
      };
      
  const [activeTab, setActiveTab] = useState('1');
  const [activeKey, setActiveKey] = useState('physics');
  

  const renderTemplates = (subject, subCategory = null) => {
    let templates = templateData[subject];
  
    if (subCategory) {
      templates = templates[subCategory];
    }
  
    if (!Array.isArray(templates)) {
      return Object.keys(templates).map((category) => (
        <TabPane tab={category} key={category}>
          {renderTemplates(subject, category)}
        </TabPane>
      ));
    }
  
    const handleTemplateClick = (latex) => {
      navigator.clipboard.writeText(latex).then(() => {
        console.log("Text copied to clipboard");
        copyband(latex);
      }).catch(err => {
        console.log("Unable to copy text", err);
      });
    };
  
    return templates.map((template, index) => {
      const renderedLatex = katex.renderToString(template.latex);
      return (
        <TemplateItem 
          key={index} 
          dangerouslySetInnerHTML={{ __html: renderedLatex }} 
          onClick={() => handleTemplateClick(template.latex)}  // Added onClick event
          style={{ backgroundColor: '#3b99ff' }}  // Added light background color
        />
      );
    });
  };
  

  const renderLatexWithKatex = (latex) => {
    try {
      return katex.renderToString(latex, {
        throwOnError: false,
      });
    } catch (e) {
      console.error(e);
      return '';
    }
  };



  //复制到剪贴板
  const copyband = (latex) => {
    navigator.clipboard.writeText(latex)
      .then(() => {
        message.success('LaTeX code copied to clipboard.');  // 成功消息
      })
      .catch(err => {
        message.error('Could not copy text.');  // 错误消息
        console.log('Could not copy text: ', err);
      });
  };
  

  const handleTabChange = (key) => {
    setActiveKey(key);
  };


  return (
    <Overlay>
        <Container>
            <Tabs defaultActiveKey={activeKey} onChange={handleTabChange}>
            <TabPane tab="物理" key="physics">
            <Tabs defaultActiveKey="physics-all">
                {renderTemplates('physics')}
            </Tabs>
            </TabPane>
            <TabPane tab="化学" key="chemistry">
            <Tabs defaultActiveKey="chemistry-all">
                {renderTemplates('chemistry')}
            </Tabs>
            </TabPane>
            <TabPane tab="数学" key="math">
            <Tabs defaultActiveKey="math-all">
                {renderTemplates('math')}
            </Tabs>
            </TabPane>
        </Tabs>
            <CloseButton onClick={onClose}>Close</CloseButton>  {/* 添加的关闭按钮 */}
        </Container>
    </Overlay>
  );
};
