import React, { useState, useEffect, useRef } from 'react';
import 'katex/dist/katex.min.css';
import katex from 'katex';
import ReactMarkdown from 'react-markdown';
import styled from 'styled-components';
import { FileOutlined, FileWordOutlined, FileMarkdownOutlined, CopyOutlined, FullscreenOutlined } from '@ant-design/icons';
import { StarFilled } from '@ant-design/icons';
import { InlineMath } from 'react-katex';
import Matter from 'matter-js';
import './MessageItem.css';
import Plotly from 'plotly.js-dist';

import { useSnackbar } from '../../SnackbarProvider';
import * as THREE from 'three';
// Import IO and ChemWidget modules (and their prerequisites) only
import { Kekule } from 'kekule';
import { getHostName } from '../../utils/urlUtils';
import 'kekule/theme/default'; 
import { Avatar,Input, Button ,Card,List } from 'antd';
import { useTranslation } from 'react-i18next';
import IconButton from '@mui/material/IconButton';
import { Button as MuiButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import * as $3Dmol from '3dmol';
import { requestUrl } from '../../requestUrl';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import LoopIcon from '@mui/icons-material/Loop'; // 循环播放图标
import SettingsIcon from '@mui/icons-material/Settings';
import PauseIcon from '@mui/icons-material/Pause';
import { javascript } from '@codemirror/lang-javascript';

import GeoGebraGraph from '../GeogebraApp/GeoGebraComponent';
import { Mic, SkipPrevious, SkipNext, MusicNote, VolumeUp, Summarize,useMediaQuery} from '@mui/icons-material';
import Paper from '@mui/material/Paper';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import { Grid, Card as MuiCard, CardActionArea, CardContent, CardMedia, Box,Container} from '@mui/material';
import TextField from '@mui/material/TextField';
import DeleteIcon from '@mui/icons-material/Delete';
import AnalyticsIcon from '@mui/icons-material/Analytics';
import rehypeRaw from 'rehype-raw'; // 引入 rehype-raw 插件
import EditIcon from '@mui/icons-material/Edit'; // 引入编辑图标
import SaveIcon from '@mui/icons-material/Save'; // 引入保存图标
import ViewPrompt from './ViewPrompt';
import remarkMath from 'remark-math';
import rehypeKatex from 'rehype-katex';
import LoadingData from '../dataloading';
import { getHostNameWeb } from '../../utils/urlUtils';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

import CustomButton from '../../components/CustomButton';
import { styled as styledMui } from '@mui/material/styles';

import { useCodeMirror } from '@uiw/react-codemirror';

import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { dark } from 'react-syntax-highlighter/dist/esm/styles/prism';
import { getColorDark,getColorLight,getTheme } from '../../ColorManager';
import '../../fonts.css';

Kekule.externalResourceManager.register('three.js', THREE);

const ItemMessageContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center; /* 内容在中心对齐 */
  width: 100%; /* 确保内容宽度与父元素相同 */
  margin-top: 10px; /* 和头像时间容器之间的距离 */
`;


const ItemMessageText = styled.div`
  font-size: 16px;
  margin: 5px 0;
  color: #333;
  width:100%;
`;

const ItemMessageActions = styled.div`
  background-color: #ffffff; // 浅色背景,功能按钮
  border-radius: 5px;
  
  button {
    background: none;
    border: none;
    cursor: pointer;
    color: #007bff;
    margin-left: 5px;
    &:hover {
      text-decoration: underline;
    }
  }
`;

const ItemFullScreenMessage = styled.div`
  // 在此处添加您的全屏消息样式，例如：
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 999;
  overflow: scroll;
  &.show {
    // 在此处添加显示全屏消息时的样式
  }
`;
const ModalBackground = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; /* Ensure it's above other content */
`;

const PlotlyDiv = styled.div`
  border-radius: 10px;
  width: 100%;
  height: 100%;
  max-width: 100vw;
  max-height: 100vh;
`;
const CloseButton = styled.button`

`;


// 定义样式组件
const StyledDivPhys = styled.div`
  display: flex;
  width: ${props => props.width};
  height: ${props => props.height};
`;

const StyledDivPhyImage = styled.div`

  border-radius: 10px;
  flex-grow: 1;
  overflow: hidden;
`;

const StyledDivCode = styled.div`
  height: 500px;
  width: 120%;
  overflow: auto;
`;

const CustomAccordion = styledMui(Accordion)(({ theme }) => ({
  backgroundColor: '#112d4e',
  marginBottom: theme.spacing(1),
}));

const CustomAccordionDetails = styledMui(AccordionDetails)(({ theme }) => ({
  backgroundColor: '#2a3d66',
}));

const MessageItem = ({ message,subject,onIframeLoad ,onReceiveStringFromMessItem,useWf,getPointKnowledgeMap,fromModule,questionType }) => {

  const { t } = useTranslation();
  const [isFavorited, setIsFavorited] = useState(message.is_star || false);
  const [showModal, setShowModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false)
  const itemMessageTextRef = useRef(null);
  const [videoDimensions, setVideoDimensions] = React.useState({ width: 300, height: 0 });
  const showSnackbar = useSnackbar();
  const [isModalVisible, setModalVisibility] = useState(false);
  const threeDMolViewerRefPhysical = useRef(null);
  const threeDMolViewerRef = useRef(null);
  const [physicalJsCode,setPhysicalJsCode] = useState('');
  const [modalStyle, setModalStyle] = useState(getModalStyle);
  const closeModal = () => setModalVisibility(false);
  const closeModalPhysical = () => setIsModalVisiblePhysical(false);
  const videoRef = useRef(null);
  const [code, setCode] = useState('// 请在此处编写您的JS代码\n');
  const [isModalVisiblePhysical, setIsModalVisiblePhysical] = useState(false);
  const [dbid,setDbid] = useState(0);
  const [chemRotate,setChemRotate] = useState(true);
  const [isVisibleMathGeometry,setIsVisibleMathGeometry] = useState(false);
  const [selectedVideo, setSelectedVideo] = useState(null);
  const [pointKnowledgeArray,setPointKnowledgeArray] = useState([]);
  const [pointKnowledgeContent,setPointKnowledgeContent] = useState('');
  const[rolename,setRolename] = useState('user');
  const [showViewPrompt,setShowViewPrompt] = useState(false);
  const [loadingText,setLoadingText] = useState('loading');
  const [colorManager, setColorManager] = useState(getColorDark());
  const [parts,setParts] = useState([]);


  const { setContainer, editorView } = useCodeMirror({
    extensions: [javascript()],
    value: code,
    basicSetup: {
      lineNumbers: true,
      foldGutter: true,
      bracketMatching: true,
      closeBrackets: true,
      autocompletion: true,
      highlightActiveLineGutter: true,
      highlightSpecialChars: true,
      multipleSelections: true,
      allowMultipleSelections: true,
      indentOnInput: true,
      syntaxHighlighting: true,
      lint: true,
    },
    onChange: (value) => {
      setCode(value); // 更新code状态变量
    }
  });

  const avatarSrc = message.sender === 'S' ? 'stu.jpg' : 'zhigulogo-small.png';


  const [showVisualExecute,setShowVisualExecute] = useState(false);


const [sImage,setSimage] = useState('');

useEffect(() => {

  const themeName = localStorage.getItem('theme');//此处存储的是数字1-12，而不是汉字，
  if (themeName) {
    
    if(themeName === "dark"){
      setColorManager(getColorDark());
    }

    
    if(themeName === "light"){
      setColorManager(getColorLight());
    }
  }
  else{
    let aa = getColorDark();
    setColorManager(aa);
  }

  
}, []);


  useEffect(() => {

    if(message.sender === 'S'){
      setSimage(message.sImage);
    }
    setDbid(message.id);
    setPointKnowledgeArray(message.pointKnowledgeArray);
    setPointKnowledgeContent(message.pointKnowledgeContent);

    if(message.imageArray && message.imageArray.length>0){
      message.imageArray.forEach(imageObj => {
        // 使用正则表达式匹配图片路径
        const regex = /<img src='(.*?)'/g;
      const match = regex.exec(imageObj.image);

      // 如果找到匹配项
      if (match && match[1]) {
        // 将前缀添加到图片路径中
        const newImagePath = `${getHostNameWeb()}${match[1]}`;
        
        // 使用正则表达式替换原始图片路径的 src 属性值
        imageObj.image = imageObj.image.replace(match[1], newImagePath);
      }
      });
    }
    //if(subject === "c++"){
      const parts = parseContent(message.text);
      setParts(parts);
    //}


  }, [message]);

  const handleChemPlayOrPause = () => {
    setChemRotate(!chemRotate);
  };

  const headerStyle = {
    borderRadius: '10px',
    height: '30px',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    padding: '5px',
    backgroundColor: 'white', // 可选：确保按钮背景与模态窗口背景一致
    zIndex: 1 // 确保头部在渲染区域之上
  };

  const viewerContainerStyle = {
    borderRadius: '10px',
    flexGrow: 1,
    overflow: 'hidden' // 防止内容溢出
  };


  function getModalStyle() {
    const width = window.innerWidth;
    const height = window.innerHeight;

    if (width > 768) { // 电脑屏幕
      return {
        position: 'fixed',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        zIndex: 1000,
        display: 'flex',
        flexDirection: 'column',
        width: '800px',
        height: '600px',
        backgroundColor: 'white',
        borderRadius: '10px',
        boxShadow: '0 0 10px rgba(0, 0, 0, 0.25)'
      };
    } else if (width > 480) { // 平板
      return {
        position: 'fixed',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        zIndex: 1000,
        display: 'flex',
        flexDirection: 'column',
        height: `${height / 3}px`,
        width: `${width / 2}px`,
        backgroundColor: 'white',
        borderRadius: '10px',
        boxShadow: '0 0 10px rgba(0, 0, 0, 0.25)'
        // 其他样式保持不变
      };
    } else { // 手机
      return {
        position: 'fixed',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        zIndex: 1000,
        display: 'flex',
        flexDirection: 'column',
        height: `${height / 2}px`,
        width: `${width * 0.9}px`,
        backgroundColor: 'white',
        borderRadius: '10px',
        boxShadow: '0 0 10px rgba(0, 0, 0, 0.25)'
      };
    }
  }


  useEffect(() => {
    
    let rname = localStorage.getItem("rolename");
    setRolename(rname);

    function handleResize() {
      setModalStyle(getModalStyle());
    }

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);



  const handleLatexClick = async (event, latexContent) => {

    if(subject === "mathematics"){
      generate_math_image(event,latexContent);
    }
    if(subject === "chemistry"){
    }
  };

  const generate_chemistry_image = async (event,latexContent,formula_type) => {
    const token = localStorage.getItem("access_token");
    const clickedLatexElement = event.target;
    var divid = 'div'+latexContent;

    let isExistImg = stringArrayRef.current.includes(latexContent);
    if(isExistImg === true && formula_type === "organic"){ //已经生成图像了
      return;
    }
    stringArrayRef.current.push(latexContent);

    setIsLoading(true);
    try {
      const response = await fetch(getHostName() + '/get_chemi_cml_by_smiles_or_mathrm', {
        method: 'POST',
        headers: {'Content-Type': 'application/json',
                  'Authorization': `Bearer ${token}`},
        body: JSON.stringify({ latex_expression: latexContent, formula_type: formula_type, id: 1 }), // Include the id
      });
 
      if (!response.ok) {
        setIsLoading(false);
        throw new Error(`An error occurred: ${response.statusText}`);
        
      }

      const data = await response.json();
      
      setTimeout(() => {
        if (typeof Plotly !== 'undefined') {
          setIsLoading(false);
          createImgDivAndLoadChemistry(clickedLatexElement,latexContent,data,formula_type);
          //Plotly.newPlot('plotlyDiv', data1.data, data1.layout);
        } else {
          console.error('Plotly is not defined');
        }
      }, 4000);  // 延迟 1 秒
      //setShowModal(true);
    } catch (err) {
      console.error(err.message);
      setIsLoading(false);
    }
  }

  const createImgDivAndLoadChemistry = (targetLatexElement,latexContent,data1,formula_type) => {
        // 创建一个 img 元素
        if(formula_type === "organic")
        {
          get_chemi_image_3d(targetLatexElement,latexContent,data1);
          get_chemi_image_2d(targetLatexElement,latexContent,data1);
        }
        if(formula_type === "inorganic")
        {
          get_chemi_image_inor(targetLatexElement,latexContent,data1);
        }
      }

    const get_chemi_image_inor = async (targetLatexElement,latexContent,data1) => {
      let cif_content = data1.cif_content;
      let base_info = data1.base_info;
      setModalVisibility(true);
      setTimeout(() => {
        render3DMol(cif_content);
      },500); // 使用0毫秒的延迟，这通常足够等待下一个渲染周期
    }

    let rotationInterval;
    const viewerRef = useRef(null);
    const [isViewerReady, setIsViewerReady] = useState(false); // 新增状态

    const handleRotation = () => {
      if (chemRotate && isViewerReady && viewerRef.current) {
        rotationInterval = setInterval(() => {
          viewerRef.current.rotate(1, {x: 0, y: 1, z: 0});
          viewerRef.current.zoomTo();
          viewerRef.current.render();
        }, 100);
      } else {
        clearInterval(rotationInterval);
      }
    };
  
    useEffect(() => {
      handleRotation();
  
      // 清理函数
      return () => clearInterval(rotationInterval);
    }, [chemRotate, isViewerReady]); // 添加 isViewerReady 作为依赖

    
    const render3DMol = (cif_content) => {
      
      if (threeDMolViewerRef.current) {
        threeDMolViewerRef.current.innerHTML = t('loading 3Dmol images');
        requestAnimationFrame(() => {
          
          var config = { backgroundColor: 'white' };
          const viewer = $3Dmol.createViewer(threeDMolViewerRef.current,config);
          viewerRef.current = viewer; // 将 viewer 对象赋值给引用
          viewer.addModel(cif_content, "cif");
          // 添加多个单元格
          //viewer.addUnitCell({a: 2, b: 2, c: 2});  // 在每个方向上复制2个单元格
          viewer.addUnitCell({
            packed: true,
            a: 3, // 沿a轴复制次数
            b: 3, // 沿b轴复制次数
            c: 3  // 沿c轴复制次数
        });


          viewer.setStyle({}, {sphere: {}});

         // 定义颜色数组
          const colors = [
            'red', 'blue', 'green', 'yellow', 'purple',
            'orange', 'pink', 'brown', 'grey', 'cyan',
            'magenta', 'lime', 'maroon', 'navy', 'olive',
            'teal', 'aqua', 'coral', 'fuchsia', 'gold'
          ];
          // 获取所有原子
          var allAtoms = viewer.selectedAtoms({});
          
          // 为每个原子设置样式
          allAtoms.forEach((atom, index) => {
            const color = colors[index % colors.length]; // 循环使用颜色数组
            viewer.setStyle({ serial: atom.serial }, {
              sphere: { color: color, radius: 0.4 }
            });

            // 添加标签
            viewer.addLabel(atom.elem, { position: atom, backgroundColor: 'transparent' });
          });

          // 添加多个单元格
          viewer.addUnitCell({a: 4, b: 4, c: 4});  // 在每个方向上复制4个单元格
          setIsViewerReady(true);
          setChemRotate(true);
          
        });
      }
    };
    const get_chemi_image_2d = async (targetLatexElement,latexContent,data1) => {
      // 创建一个 div 元素作为 imgElement 的容器
     const imgContainer = document.createElement('div');
     //imgContainer.style.position = 'relative';
     imgContainer.style.borderRadius = '10px';
   
     // 创建 imgElement
     const imgElement = document.createElement('div');
   
     const divid = 'div' + latexContent;
     
     imgElement.setAttribute('id', divid);
     imgElement.style.borderRadius = '10px';
     imgElement.alt = t('function image');
     const screenWidth = window.innerWidth;
     const screenHeight = window.innerHeight;
     let widthPercentage;
     let heightPercentage;
     if (screenWidth >= 1024) {
       widthPercentage = 0.5;
       heightPercentage=0.6;
     } else if (screenWidth >= 768) {
       widthPercentage = 0.5;
       heightPercentage=0.6;
     } else {
       widthPercentage = 0.9;
       heightPercentage=0.9;
     }
     imgElement.style.width = `${screenWidth * widthPercentage}px`;
     imgElement.style.height = `${screenHeight * heightPercentage}px`;
     imgElement.style.display = 'block';
     imgElement.style.margin = '10px auto';
   
     // 创建一个最大化和一个关闭按钮
     const maximizeButton = document.createElement('button');
     maximizeButton.style.marginLeft = '10px';
   
     const maximizeImage = document.createElement('img');
     maximizeImage.src = '/source/zuidahua.png';  // 替换为你的图片URL
     maximizeImage.alt = 'Maximize';
     maximizeImage.width = 25;  // 可调整大小
     maximizeImage.height = 25;  // 可调整大小
     maximizeButton.appendChild(maximizeImage);
     // 设置按钮样式
     maximizeButton.style.marginLeft = '2px';
     maximizeButton.style.border = 'none';
     maximizeButton.style.background = 'none';
   
     const closeButton = document.createElement('button');
     closeButton.innerText = t('Close');
     closeButton.style.position = 'absolute';
     closeButton.style.top = '10px';
     closeButton.style.right = '10px';
     closeButton.style.display = 'none';  // 默认隐藏
   
     closeButton.style.color = "white";  // 设置为白色，您可以修改为您喜欢的颜色
     // 设置按钮背景颜色
     closeButton.style.backgroundColor = "#4CAF50";  // 设置为绿色，您可以修改为您喜欢的颜色
     // 设置圆角
     closeButton.style.borderRadius = "5px";  // 5px的圆角，您可以调整大小
   
     closeButton.onclick = () => {
       imgContainer.style.position = 'relative';
       imgContainer.style.width = '';
       imgContainer.style.height = '';
       imgContainer.style.backgroundColor = '';
       imgContainer.style.paddingTop = ''; // 移除 padding
   
       imgElement.style.width = `${screenWidth * widthPercentage}px`;
       imgElement.style.height = ''; // 恢复原始高度
       imgElement.style.margin = '10px auto';
       
       // 恢复 Plotly 图表的原始设置
       Plotly.relayout(divid, {
           width: screenWidth * widthPercentage,
           height: 400  // 这里你可以设置到你原来想要的高度
       });
   
       closeButton.style.display = 'none';
       closeButton.style.position = ''; // 恢复原始位置
       closeButton.style.zIndex = ''; // 恢复原始 z-index
       closeButton.style.top = '';
       closeButton.style.right = '';
       closeButton.style.height = '';
     };
   
     maximizeButton.onclick = () => {
       imgContainer.style.position = 'fixed';
       imgContainer.style.zIndex = '1000';
       imgContainer.style.top = '0';
       imgContainer.style.left = '0';
       imgContainer.style.width = '100%';
       imgContainer.style.height = 'calc(100% - 30px)'; // 留出 30px 的空间给关闭按钮
       imgContainer.style.backgroundColor = 'white';
       imgContainer.style.paddingTop = '30px'; // 添加 30px 的 padding
   
       imgElement.style.width = '100%';
       imgElement.style.height = 'calc(100% - 30px)';
       imgElement.style.margin = '0';
   
       // 最大化 Plotly 图表
       Plotly.relayout(divid, {
           width: window.innerWidth,
           height: window.innerHeight - 30  // 留出 30px 的空间给关闭按钮
       });
   
       closeButton.style.display = 'block';
       closeButton.style.position = 'fixed';
       closeButton.style.zIndex = '1001'; // 确保在 imgContainer 之上
       closeButton.style.top = '0';
       closeButton.style.right = '0';
       closeButton.style.height = '30px';
     };
   
     imgContainer.appendChild(closeButton);
     imgContainer.appendChild(imgElement);
   
     // 前面的 toggleButton 代码
     const toggleButton = document.createElement('button');
     toggleButton.innerText = "2d:" + t('Show/hide image');
     // 设置文本颜色
     toggleButton.style.color = "white";  // 设置为白色，您可以修改为您喜欢的颜色
     // 设置按钮背景颜色
     toggleButton.style.backgroundColor = "#4CAF50";  // 设置为绿色，您可以修改为您喜欢的颜色
     // 设置圆角
     toggleButton.style.borderRadius = "5px";  // 5px的圆角，您可以调整大小
     toggleButton.style.marginBottom = '5px';
     toggleButton.onclick = () => {
         if (imgContainer.style.display === 'block') {
           imgContainer.style.display = 'none';
         } else {
           imgContainer.style.display = 'block';
         }
     };
     toggleButton.style.marginBottom = '5px';
   
     //const parentRowElement = targetLatexElement.closest('.katex');
     const parentRowElement = targetLatexElement;//0220 修改
     if (parentRowElement.nextSibling) {
         parentRowElement.parentNode.insertBefore(toggleButton, parentRowElement.nextSibling);
     } else {
         parentRowElement.parentNode.appendChild(toggleButton);
     }
   
     // 插入最大化按钮
     parentRowElement.parentNode.insertBefore(maximizeButton, toggleButton.nextSibling);
   
     // 插入图像容器
     if (maximizeButton.nextSibling) {
         parentRowElement.parentNode.insertBefore(imgContainer, maximizeButton.nextSibling);
     } else {
         parentRowElement.parentNode.appendChild(imgContainer);
     }
   
       var chemViewer = new Kekule.ChemWidget.Viewer(document);
       chemViewer
       .setEnableToolbar(true)
       .setEnableDirectInteraction(true)
       .setEnableEdit(true)
       .setToolButtons([
         'loadData', 'saveData', 'molDisplayType', 'molHideHydrogens',
         'zoomIn', 'zoomOut',
         'rotateLeft', 'rotateRight', 'rotateX', 'rotateY', 'rotateZ',
         'reset', 'openEditor', 'config'
       ]);
       chemViewer.setDimension('500px', '400px');
       chemViewer.backgroundColor = '#003153';

       var myMolecule = Kekule.IO.loadFormatData(data1, 'cml');
   
       chemViewer.appendToElem(document.getElementById(divid)).setChemObj(myMolecule);
   
       // display 2D molecule in skeletal model
       chemViewer.setRenderType(Kekule.Render.RendererType.R2D);
       chemViewer.setMoleculeDisplayType(Kekule.Render.Molecule2DDisplayType.CONDENSED);//骨骼CONDENSED  SKELETAL
       chemViewer.setEnableToolbar(true);  // enable the toolbar
       //chemViewer.setEnableToolbar(false);  // disable and hide the toolbar
   
   
   
       var color2DConfigs = chemViewer.getRenderConfigs().getColorConfigs();
       color2DConfigs.setAtomColor('#A00000').setBondColor('#A0A000');  // set the default color for atoms and bonds
       color2DConfigs.setGlyphStrokeColor('#C0C0C0');   // default color of glyph stroke, e.g. the arrows
       color2DConfigs.setLabelColor('#C0C0C0');   // default color of text labels
       chemViewer.requestRepaint();    // repaint
   
   
       chemViewer.setToolbarEvokeModes([
         Kekule.Widget.EvokeMode.EVOKEE_CLICK,
         Kekule.Widget.EvokeMode.EVOKEE_MOUSE_ENTER,
         Kekule.Widget.EvokeMode.EVOKEE_TOUCH
       ]);
       chemViewer.setToolbarRevokeModes([
         Kekule.Widget.EvokeMode.EVOKEE_MOUSE_LEAVE,
         Kekule.Widget.EvokeMode.EVOKER_TIMEOUT
       ]);
     }
   

  const get_chemi_image_3d = async (targetLatexElement,latexContent,data1) => {
    // 创建一个 div 元素作为 imgElement 的容器
  const imgContainer = document.createElement('div');
  //imgContainer.style.position = 'relative';
  imgContainer.style.borderRadius = '10px';

  // 创建 imgElement
  const imgElement = document.createElement('div');

  const divid = 'div' + latexContent;
  
  imgElement.setAttribute('id', divid);


  imgElement.style.borderRadius = '10px';
  imgElement.alt = t('function image');
  const screenWidth = window.innerWidth;
  const screenHeight = window.innerHeight;
  let widthPercentage;
  let heightPercentage;
  if (screenWidth >= 1024) {
    widthPercentage = 0.5;
    heightPercentage=0.6;
  } else if (screenWidth >= 768) {
    widthPercentage = 0.5;
    heightPercentage=0.6;
  } else {
    widthPercentage = 0.9;
    heightPercentage=0.9;
  }
  imgElement.style.width = `${screenWidth * widthPercentage}px`;
  imgElement.style.height = `${screenHeight * heightPercentage}px`;
  imgElement.style.display = 'block';
  imgElement.style.margin = '10px auto';

  // 创建一个最大化和一个关闭按钮
  const maximizeButton = document.createElement('button');
  maximizeButton.style.marginLeft = '10px';

  const maximizeImage = document.createElement('img');
  maximizeImage.src = '/source/zuidahua.png';  // 替换为你的图片URL
  maximizeImage.alt = 'Maximize';
  maximizeImage.width = 25;  // 可调整大小
  maximizeImage.height = 25;  // 可调整大小
  maximizeButton.appendChild(maximizeImage);
  // 设置按钮样式
  maximizeButton.style.marginLeft = '2px';
  maximizeButton.style.border = 'none';
  maximizeButton.style.background = 'none';

  const closeButton = document.createElement('button');
  closeButton.innerText = t('Close');
  closeButton.style.position = 'absolute';
  closeButton.style.top = '10px';
  closeButton.style.right = '10px';
  closeButton.style.display = 'none';  // 默认隐藏

  closeButton.style.color = "white";  // 设置为白色，您可以修改为您喜欢的颜色
  // 设置按钮背景颜色
  closeButton.style.backgroundColor = "#4CAF50";  // 设置为绿色，您可以修改为您喜欢的颜色
  // 设置圆角
  closeButton.style.borderRadius = "5px";  // 5px的圆角，您可以调整大小

  closeButton.onclick = () => {
    imgContainer.style.position = 'relative';
    imgContainer.style.width = '';
    imgContainer.style.height = '';
    imgContainer.style.backgroundColor = '';
    imgContainer.style.paddingTop = ''; // 移除 padding

    imgElement.style.width = `${screenWidth * widthPercentage}px`;
    imgElement.style.height = ''; // 恢复原始高度
    imgElement.style.margin = '10px auto';
    
    // 恢复 Plotly 图表的原始设置
    Plotly.relayout(divid, {
        width: screenWidth * widthPercentage,
        height: 400  // 这里你可以设置到你原来想要的高度
    });

    closeButton.style.display = 'none';
    closeButton.style.position = ''; // 恢复原始位置
    closeButton.style.zIndex = ''; // 恢复原始 z-index
    closeButton.style.top = '';
    closeButton.style.right = '';
    closeButton.style.height = '';
  };

  maximizeButton.onclick = () => {
    imgContainer.style.position = 'fixed';
    imgContainer.style.zIndex = '1000';
    imgContainer.style.top = '0';
    imgContainer.style.left = '0';
    imgContainer.style.width = '100%';
    imgContainer.style.height = 'calc(100% - 30px)'; // 留出 30px 的空间给关闭按钮
    imgContainer.style.backgroundColor = 'white';
    imgContainer.style.paddingTop = '30px'; // 添加 30px 的 padding

    imgElement.style.width = '100%';
    imgElement.style.height = 'calc(100% - 30px)';
    imgElement.style.margin = '0';

    // 最大化 Plotly 图表
    Plotly.relayout(divid, {
        width: window.innerWidth,
        height: window.innerHeight - 30  // 留出 30px 的空间给关闭按钮
    });

    closeButton.style.display = 'block';
    closeButton.style.position = 'fixed';
    closeButton.style.zIndex = '1001'; // 确保在 imgContainer 之上
    closeButton.style.top = '0';
    closeButton.style.right = '0';
    closeButton.style.height = '30px';
  };

  imgContainer.appendChild(closeButton);
  imgContainer.appendChild(imgElement);

  // 前面的 toggleButton 代码
  const toggleButton = document.createElement('button');
  toggleButton.innerText = "3d:" + t('Show/hide image');
  // 设置文本颜色
  toggleButton.style.color = "white";  // 设置为白色，您可以修改为您喜欢的颜色
  // 设置按钮背景颜色
  toggleButton.style.backgroundColor = "#4CAF50";  // 设置为绿色，您可以修改为您喜欢的颜色
  // 设置圆角
  toggleButton.style.borderRadius = "5px";  // 5px的圆角，您可以调整大小
  toggleButton.style.marginBottom = '5px';
  toggleButton.onclick = () => {
      if (imgContainer.style.display === 'block') {
        imgContainer.style.display = 'none';
      } else {
        imgContainer.style.display = 'block';
      }
  };
  toggleButton.style.marginBottom = '5px';

  //  const parentRowElement = targetLatexElement.closest('.katex');  //0220 修改
  const parentRowElement = targetLatexElement;
  if (parentRowElement.nextSibling) {
      parentRowElement.parentNode.insertBefore(toggleButton, parentRowElement.nextSibling);
  } else {
      parentRowElement.parentNode.appendChild(toggleButton);
  }

  // 插入最大化按钮
  parentRowElement.parentNode.insertBefore(maximizeButton, toggleButton.nextSibling);

  // 插入图像容器
  if (maximizeButton.nextSibling) {
      parentRowElement.parentNode.insertBefore(imgContainer, maximizeButton.nextSibling);
  } else {
      parentRowElement.parentNode.appendChild(imgContainer);
  }


    var chemViewer = new Kekule.ChemWidget.Viewer(document);
    chemViewer
    .setEnableToolbar(true)
    .setEnableDirectInteraction(true)
    .setEnableEdit(true)
    .setToolButtons([
      'loadData', 'saveData', 'molDisplayType', 'molHideHydrogens',
      'zoomIn', 'zoomOut',
      'rotateLeft', 'rotateRight', 'rotateX', 'rotateY', 'rotateZ',
      'reset', 'openEditor', 'config'
    ]);

   

    chemViewer.backgroundColor = '#ffffff';
    chemViewer.setDimension('500px', '400px');
    var myMolecule = Kekule.IO.loadFormatData(data1, 'cml');

    chemViewer.appendToElem(document.getElementById(divid)).setChemObj(myMolecule);

    // display 2D molecule in skeletal model
    chemViewer.setRenderType(Kekule.Render.RendererType.R2D);
    chemViewer.setMoleculeDisplayType(Kekule.Render.Molecule2DDisplayType.CONDENSED);//CONDENSED  SKELETAL

    // display 3D molecule in space fill model
    chemViewer.setRenderType(Kekule.Render.RendererType.R3D);
    chemViewer.setMoleculeDisplayType(Kekule.Render.Molecule3DDisplayType.BALL_STICK);//NONE STICKS  SPACE_FILL BALL_STICK

    chemViewer.setEnableToolbar(true);  // enable the toolbar
    //chemViewer.setEnableToolbar(false);  // disable and hide the toolbar


    var display3DConfigs = chemViewer.getRenderConfigs().getMoleculeDisplayConfigs();
    display3DConfigs.setDrawAtomLabel = true;
    
    //display3DConfigs.setDefAtomColor('#000099').setDefBondColor('#FFFFFF');
    //display3DConfigs.setUseAtomSpecifiedColor(false);  // turn off this to take the color to effect
    //chemViewer.requestRepaint();    // repaint



    chemViewer.setToolbarEvokeModes([
      Kekule.Widget.EvokeMode.EVOKEE_CLICK,
      Kekule.Widget.EvokeMode.EVOKEE_MOUSE_ENTER,
      Kekule.Widget.EvokeMode.EVOKEE_TOUCH
    ]);
    chemViewer.setToolbarRevokeModes([
      Kekule.Widget.EvokeMode.EVOKEE_MOUSE_LEAVE,
      Kekule.Widget.EvokeMode.EVOKER_TIMEOUT
    ]);
  }
  const stringArrayRef = useRef([]);


    //是否正在生成图像，防止连续点击界面的latex
    const [isGeneratingMathImage,setIsGeneratingMathImage] = useState(false);


  //生成数学函数图像
  const generate_math_image = async (event, latexContent) => {
    
    if(isGeneratingMathImage){
      showSnackbar(t('images are being generated, please wait for completion'),'warning');
      return;
    }
    const clickedLatexElement = event.target;
    var divid = 'div'+latexContent;
    let isExistImg = stringArrayRef.current.includes(latexContent);
    if(isExistImg === true){ //已经生成图像了
      return;
    }
    stringArrayRef.current.push(latexContent);
    //const existingImg = parentElement.querySelector(`[id="${divid}"]`);
    //if (existingImg) {
      // 如果图片已经存在，直接返回，不再插入新图片

      //return;
    //}
    setIsLoading(true);

    setIsGeneratingMathImage(true);

    try {
      const token = localStorage.getItem("access_token");
      const response = await fetch(getHostName() + '/getlateximage', {
        method: 'POST',
        headers: {'Content-Type': 'application/json',
                  'Authorization': `Bearer ${token}`},
        body: JSON.stringify({ latex_expression: latexContent, id: 1 }), // Include the id
      });

      if (!response.ok) {
        setIsLoading(false);
        setIsGeneratingMathImage(false);
        throw new Error(`An error occurred: ${response.statusText}`);
        
      }
      console.log('Is Plotly defined:', typeof Plotly !== 'undefined');

      const data = await response.json();
      let data1 = JSON.parse(data);
      setTimeout(() => {
        if (typeof Plotly !== 'undefined') {
          setIsLoading(false);
          createImgDivAndLoadPlotly(clickedLatexElement,latexContent,data1);
          //Plotly.newPlot('plotlyDiv', data1.data, data1.layout);
        } else {
          console.error('Plotly is not defined');
        }
      }, 6000);  // 延迟 1 秒
      
      // Render the plot
      
      //setShowModal(true);
    } catch (err) {
      console.error(err.message);
      setIsLoading(false);
      setIsGeneratingMathImage(false);
    }

    setIsGeneratingMathImage(false);
  }
  const handleCloseModal = () => {

    setShowModal(false);
  };


const createImgDivAndLoadPlotly = (targetLatexElement, latexContent, data1) => {
  // 创建一个 div 元素作为 imgElement 的容器
  const imgContainer = document.createElement('div');
  //imgContainer.style.position = 'relative';
  imgContainer.style.borderRadius = '10px';

  // 创建 imgElement
  const imgElement = document.createElement('div');

  const divid = 'div' + latexContent;
  imgElement.setAttribute('id', divid);


  imgElement.style.borderRadius = '10px';
  imgElement.alt = t('function image');
  const screenWidth = window.innerWidth;
  const screenHeight = window.innerHeight;
  let widthPercentage;
  let heightPercentage;
  if (screenWidth >= 1024) {
    widthPercentage = 0.5;
    heightPercentage=0.6;
  } else if (screenWidth >= 768) {
    widthPercentage = 0.5;
    heightPercentage=0.6;
  } else {
    widthPercentage = 0.9;
    heightPercentage=0.9;
  }
  imgElement.style.width = `${screenWidth * widthPercentage}px`;
  imgElement.style.height = `${screenHeight * heightPercentage}px`;
  imgElement.style.display = 'block';
  imgElement.style.margin = '10px auto';

  // 创建一个最大化和一个关闭按钮
  const maximizeButton = document.createElement('button');
  maximizeButton.style.marginLeft = '10px';

  const maximizeImage = document.createElement('img');
  maximizeImage.src = '/source/zuidahua.png';  // 替换为你的图片URL
  maximizeImage.alt = 'Maximize';
  maximizeImage.width = 25;  // 可调整大小
  maximizeImage.height = 25;  // 可调整大小
  maximizeButton.appendChild(maximizeImage);
  // 设置按钮样式
  maximizeButton.style.marginLeft = '2px';
  maximizeButton.style.border = 'none';
  maximizeButton.style.background = 'none';

  const closeButton = document.createElement('button');
  closeButton.innerText = t('Close');
  closeButton.style.position = 'absolute';
  closeButton.style.top = '10px';
  closeButton.style.right = '10px';
  closeButton.style.display = 'none';  // 默认隐藏

  closeButton.style.color = "white";  // 设置为白色，您可以修改为您喜欢的颜色
  // 设置按钮背景颜色
  closeButton.style.backgroundColor = "#4CAF50";  // 设置为绿色，您可以修改为您喜欢的颜色
  // 设置圆角
  closeButton.style.borderRadius = "5px";  // 5px的圆角，您可以调整大小

  closeButton.onclick = () => {
    imgContainer.style.position = 'relative';
    imgContainer.style.width = '';
    imgContainer.style.height = '';
    imgContainer.style.backgroundColor = '';
    imgContainer.style.paddingTop = ''; // 移除 padding

    imgElement.style.width = `${screenWidth * widthPercentage}px`;
    imgElement.style.height = ''; // 恢复原始高度
    imgElement.style.margin = '10px auto';
    
    // 恢复 Plotly 图表的原始设置
    Plotly.relayout(divid, {
        width: screenWidth * widthPercentage,
        height: 400  // 这里你可以设置到你原来想要的高度
    });

    closeButton.style.display = 'none';
    closeButton.style.position = ''; // 恢复原始位置
    closeButton.style.zIndex = ''; // 恢复原始 z-index
    closeButton.style.top = '';
    closeButton.style.right = '';
    closeButton.style.height = '';
  };

  maximizeButton.onclick = () => {
    imgContainer.style.position = 'fixed';
    imgContainer.style.zIndex = '1000';
    imgContainer.style.top = '0';
    imgContainer.style.left = '0';
    imgContainer.style.width = '100%';
    imgContainer.style.height = 'calc(100% - 30px)'; // 留出 30px 的空间给关闭按钮
    imgContainer.style.backgroundColor = 'white';
    imgContainer.style.paddingTop = '30px'; // 添加 30px 的 padding

    imgElement.style.width = '100%';
    imgElement.style.height = 'calc(100% - 30px)';
    imgElement.style.margin = '0';

    // 最大化 Plotly 图表
    Plotly.relayout(divid, {
        width: window.innerWidth,
        height: window.innerHeight - 30  // 留出 30px 的空间给关闭按钮
    });

    closeButton.style.display = 'block';
    closeButton.style.position = 'fixed';
    closeButton.style.zIndex = '1001'; // 确保在 imgContainer 之上
    closeButton.style.top = '0';
    closeButton.style.right = '0';
    closeButton.style.height = '30px';
  };

  imgContainer.appendChild(closeButton);
  imgContainer.appendChild(imgElement);

  // 前面的 toggleButton 代码
  const toggleButton = document.createElement('button');
  toggleButton.innerText = t('Show/hide image');
  // 设置文本颜色
  toggleButton.style.color = "white";  // 设置为白色，您可以修改为您喜欢的颜色
  // 设置按钮背景颜色
  toggleButton.style.backgroundColor = "#4CAF50";  // 设置为绿色，您可以修改为您喜欢的颜色
  // 设置圆角
  toggleButton.style.borderRadius = "5px";  // 5px的圆角，您可以调整大小
  toggleButton.style.marginBottom = '5px';
  toggleButton.onclick = () => {
      if (imgContainer.style.display === 'block') {
        imgContainer.style.display = 'none';
      } else {
        imgContainer.style.display = 'block';
      }
  };
  toggleButton.style.marginBottom = '5px';

  const parentRowElement = targetLatexElement.closest('.katex');
  if (parentRowElement.nextSibling) {
      parentRowElement.parentNode.insertBefore(toggleButton, parentRowElement.nextSibling);
  } else {
      parentRowElement.parentNode.appendChild(toggleButton);
  }

  // 插入最大化按钮
  parentRowElement.parentNode.insertBefore(maximizeButton, toggleButton.nextSibling);

  // 插入图像容器
  if (maximizeButton.nextSibling) {
      parentRowElement.parentNode.insertBefore(imgContainer, maximizeButton.nextSibling);
  } else {
      parentRowElement.parentNode.appendChild(imgContainer);
  }

  // 使用Plotly渲染图像
  //const divid = 'div' + latexContent;
  
  //imgElement.setAttribute('id', divid);
  Plotly.newPlot(divid, data1.data, data1.layout);
};

  
  const handleChemistryClick = (event, formula_type, content) => {
    var ft = 'inorganic';//organic
    if(formula_type === "smiles"){
      ft = 'organic'
    } 
    generate_chemistry_image(event,content,ft);
  };

  const [isFullScreen, setIsFullScreen] = useState(false);

  const handlePrintClick1 = () => {
    const printWindow = window.open('', '_blank');
    printWindow.document.open();
    printWindow.document.write('<html><body>' + message.text + '</body></html>');
    printWindow.document.close();
    printWindow.print();
  };

  const handlePrintClick = () => {
    if (containerRef.current) {
      const htmlContent = containerRef.current.innerHTML; // 获取渲染后的HTML内容
      const printWindow = window.open('', '_blank');
      printWindow.document.open();
      printWindow.document.write('<html><head><title>Print</title></head><body>' + htmlContent + '</body></html>');
      printWindow.document.close();
      setTimeout(() => {
        printWindow.print(); // 延迟执行print，确保内容加载完成
      }, 500);
    }
  };

  

  const handleExportClick = (format) => {
    let content = message.text;
    if (format === 'word') {
      const blob = new Blob(['\ufeff', content], { type: 'application/msword' });
      const url = URL.createObjectURL(blob);
      downloadFile(url, 'message.doc');
    } else if (format === 'markdown') {
      const blob = new Blob([content], { type: 'text/markdown' });
      const url = URL.createObjectURL(blob);
      downloadFile(url, 'message.md');
    }
  };

 const handleFavoriteClick = async () => {
  const newIsFavorited = !isFavorited;
  setIsFavorited(newIsFavorited);


  const token = localStorage.getItem("access_token");
  const dataToSend = {
    cid:message.id,
    is_star:newIsFavorited,
  };

  const url = getHostName() + '/set_chat_record_is_star'; // 你的服务端URL
      const response = await fetch(url, {method: 'POST',
        headers: {'Content-Type': 'application/json',
                  'Authorization': `Bearer ${token}`},
        body: JSON.stringify(dataToSend),});
};


  const downloadFile = (url, filename) => {
    const link = document.createElement('a');
    link.href = url;
    link.download = filename;
    link.click();
  };

  const handleCopyClick = () => {
    navigator.clipboard.writeText(message.text).then(() => {
      showSnackbar(t('copied the formula to the clipboard'),'success');
    })
      .catch(err => {
        
        showSnackbar(t('could not copy text'),'warning');
        
      });
  };

  const handleFullScreenClick = () => {
    setIsFullScreen(true);
  };

  const handleExitFullScreenClick = () => {
    setIsFullScreen(false);
  };


  const handleAnalyseAnswer = (item,executeType) =>{
    const myString = "question:" + item.q + "__" + "answer:" + item.a;
    onReceiveStringFromMessItem(myString,executeType);
    
  }

  async function hanndleTryAgainGetAnswer(event) {
    onReceiveStringFromMessItem(message.question,"deepgen");
  }

  //将字符串的latex代码渲染
  const parseAndRenderMath = (text) => {
    const parts = text.split(/\$(.*?)\$/g);
    return parts.map((part, index) => {
      if (index % 2 === 0) {
        return part;
      } else {
        return <InlineMath key={index} math={part} />;
      }
    });
  };


  const [activeIndex, setActiveIndex] = useState(null);

  const toggleAnswerDisplay = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  React.useEffect(() => {
    const videoElement = videoRef.current;
    if (videoElement) {
        setVideoDimensions({ width: videoElement.offsetWidth, height: videoElement.offsetHeight });
    }
  }, []);

  const getResponsiveWidth = () => {
    const screenWidth = window.innerWidth;
    if (screenWidth < 768) { // 手机设备
      return '99%';
    } else if (screenWidth >= 768 && screenWidth < 992) { // 平板设备
      return '95%';
    } else { // 电脑设备
      return '80%';
    }
  };

  const [width, setWidth] = useState(getResponsiveWidth());

  useEffect(() => {
    const handleResize = () => {
      setWidth(getResponsiveWidth());
    };

    // 监听窗口大小改变事件
    window.addEventListener('resize', handleResize);

    // 组件卸载时移除监听器
    return () => window.removeEventListener('resize', handleResize);
    }, []);


    const [isAnimating, setIsAnimating] = useState(false);
    const intervalIdRef = useRef(null);


    async function hanndleGeneratePhsicsImage(event) {
      setIsLoading(true);
      try {
        let grade = localStorage.getItem("grade");
        if(grade === null){ grade = "0"; }
        const dataToSend = {
          common_chat_id:message.id,
          subject:subject,
          grade:grade,
        };
        const response = await requestUrl(dataToSend, "get_physical_js",t,showSnackbar);
        if (response) {
          let jsCode = response;
          setPhysicalJsCode(jsCode);
          setCode(jsCode);
            setIsModalVisiblePhysical(true);
            setTimeout(() => {
              const executeSimulation = new Function('Matter', 'container', jsCode);
              executeSimulation(Matter, threeDMolViewerRefPhysical.current);
            },500); 
          }
          setIsLoading(false);
      } catch (error) {
        // 处理请求错误
        setIsLoading(false);
        console.error("Error fetching data:", error);
      }
    }

    const [geoXmlCode,setGeoXmlCode] = useState();
    const [geoMathGeometry,setGeoMathGeometry] = useState('geometry');

    async function hanndleGenerateMathGeometry(event) {
      setIsLoading(true);
      try {
        const dataToSend = {
          common_chat_id:message.id,
        };
        const response = await requestUrl(dataToSend, "generate_geogebra",t,showSnackbar);
        if (response) {
          let data = response;
            let xml = data.xml;
            let appname = data.appname;

            setGeoXmlCode(xml);
            setGeoMathGeometry(appname);
            setIsVisibleMathGeometry(true);
            setIsLoading(false);
          }
      
      } catch (error) {
        // 处理请求错误
        setIsLoading(false);
        console.error("Error fetching data:", error);
      }
    }


    async function handlePlayPhysicalImage() {
      
      // 清理现有的 Matter.js 实例（如果存在）
      if (threeDMolViewerRefPhysical.current.childNodes.length > 0) {
        // 清理 DOM 元素
        threeDMolViewerRefPhysical.current.innerHTML = '';
      }

      // 创建并执行新的模拟
      const executeSimulation = new Function('Matter', 'container', code);
      executeSimulation(Matter, threeDMolViewerRefPhysical.current);
  
}


const playPhysicalImage = () => {
  // 检查threeDMolViewerRef.current是否存在
  if (!threeDMolViewerRefPhysical.current) {
    console.error('threeDMolViewerRef.current is null');
    return;
  }

  if (!isAnimating) {
    setIsAnimating(true);
    //let jsCode = physicalJsCode;
    let jsCode = code;
    // 清理现有的 Matter.js 实例（如果存在）
    if (threeDMolViewerRefPhysical.current.childNodes.length > 0) {
      // 清理 DOM 元素
      threeDMolViewerRefPhysical.current.innerHTML = '';
    }

    // 创建并执行新的模拟
    const executeSimulation = new Function('Matter', 'container', jsCode);
    executeSimulation(Matter, threeDMolViewerRefPhysical.current);

    setTimeout(() => {
      setIsAnimating(false);
    }, 3000); // 假设动画执行时间为2秒
  }
};


const handleLoopPlay = () => {
  if (intervalIdRef.current) {
    clearInterval(intervalIdRef.current);
    intervalIdRef.current = null;
  } else {
    intervalIdRef.current = setInterval(() => {
      playPhysicalImage();
    }, 2000); // 每2秒尝试播放一次
  }
};


//写在matter.js的组件，定时器
useEffect(() => {
  // 组件卸载时执行的清理逻辑
  return () => {
    if (intervalIdRef.current) {
      clearInterval(intervalIdRef.current);
    }
    // 可能还需要其他清理操作
  };
}, []);


useEffect(() => {
  if (editorView) {
    editorView.dispatch({
      effects: editorView.state.updateCode.setValue(code)
    });
  }
}, [code, editorView]);

const [showSecondDiv, setShowSecondDiv] = useState(false);
const toggleDivDisplay = () => {
  setShowSecondDiv(!showSecondDiv);
};
  
  const onCloseMathGeo = () => {
    setIsVisibleMathGeometry(false);
  }

  const [playing, setPlaying] = useState(false);
  const audioRef = useRef(null);
  const [audioSrc, setAudioSrc] = useState(message.mp3_path);
  const [isVisiblePlaying, setIsVisiblePlaying] = useState(false);
  
  const handleSubjectTTS = async () => {

    if (message.mp3_path != null && message.mp3_path != '') {
      
      setIsVisiblePlaying(true);
    }

    else{
      setIsLoading(true);
      try {
        const dataToSend = {
          id:message.id,
          answer:message.text,
          fromModule:fromModule,
        };
        let interfacename = "";

        if(subject === "familyhead"){
          interfacename = "familyhead_tts";
        }
        else{
          interfacename = "subject_tts";
        }
        
        const data = await requestUrl(dataToSend, interfacename,t,showSnackbar);
        if (data.status === "success") {

          message.mp3_path = data.filepath;
          setAudioSrc(data.filepath);
          setIsVisiblePlaying(true);
          }
          setIsLoading(false);
      } catch (error) {
        // 处理请求错误
        setIsLoading(false);
        console.error("Error fetching data:", error);
      }
    }
  }

  useEffect(() => {
    if (isVisiblePlaying && audioRef.current) {
      audioRef.current.play();
    }
  }, [isVisiblePlaying]);

  function ResponsiveIframe({ src }) {
    // 这里可以添加一些逻辑来根据屏幕大小调整iframe的尺寸
    // 例如使用CSS媒体查询或者在这里使用window.innerWidth
    const iframeStyle = {
      width: '100%', // 默认宽度为100%，可以根据需要调整
      height: '400px', // 默认高度，可以根据屏幕大小进行调整
    };
  
    return <iframe src={src} style={iframeStyle} title="Interactive Content"></iframe>;
  }

  const [showTopContainer, setShowTopContainer] = useState(false);

  const toggleTopContainer = () => {
    setShowTopContainer(!showTopContainer);
  };

  const renderTextAndMath = (text) => {
    if (text === undefined || text === null) {
      text = "";
    }
    const regex = /\$(.+?)\$/g;
    let result = '';
    let lastIndex = 0;

    text.replace(regex, (match, formula, index) => {
      // 添加普通文本
      result += text.slice(lastIndex, index);
      // 将KaTeX公式转换为HTML
      try {
        result += katex.renderToString(formula, { throwOnError: false });
      } catch (e) {
        console.error(e);
        result += match; // 如果出错，就使用原始文本
      }
      lastIndex = index + match.length;
    });

    // 确保添加最后一段普通文本
    result += text.slice(lastIndex);
    return result;
  };

  // 定义Item组件的样式
  const itemStyle = {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: '#D35400',
    padding: '8px',
    textAlign: 'center',
    color: 'rgba(0, 0, 0, 0.87)',
  };

  const handleDeletePointKnowledge = (pkitem) => {
    alert(pkitem.id);
  };

  const [pkIsEditing, setPkIsEditing] = useState(false);
  const [pkContent, setPkContent] = useState('$x^2$下面的内容是一段OCR识别$y=x^2$的学生试卷内容，公式使用了包裹，比如$y=x^2$,内有包含试卷的标题，题目，也可能包含解答过程，这些题目可能包含：选择题，填空题，解答题等，也可能包含学生在试卷中的其他记录，可以放在notebook属性内，你把这些内容正确的识别出来，试卷名称如果没有则留空，不要编造，json格式返回。这是你要参考的json代码，属性名称必须使用和我一样的:');

  const handleDoubleClick = () => {
    setPkIsEditing(true);
  };

  const handleChange = (event) => {
    setPkContent(event.target.value);
    setPointKnowledgeContent(event.target.value);
  };

  const handleBlur = () => {
    setPkIsEditing(false);
    if(pkIsEditing){
    }
  };

  const toggleEdit = () => {
    setPkIsEditing(!pkIsEditing);
  };


  const centerStyle = {
    zIndex:9999,
    display: 'flex',
    backgroundColor:'white',
    justifyContent: 'center',
    alignItems: 'center',
    height: '70vh', // 视口高度
    width: '70vw', // 视口宽度，确保宽度也是全屏的
    position: 'fixed', // 固定定位，确保不受滚动影响
    left: '50%', // 左边距设置为50%，配合transform使用，可实现居中
    top: '50%', // 顶部距离设置为50%，配合transform使用，可实现居中
    transform: 'translate(-50%, -50%)' // 平移自己的50%，确保完全居中
};

  //知识点数组编辑删除//////////////////////////


  const [visible, setVisible] = useState(0);

  const [editingIdPkArray, setEditingIdPkArray] = useState(null);
  // 新增一个状态来存储编辑中的文本
  const [editText, setEditText] = useState("");

  const toggleEditPkArray = (pkitem, event) => {
    event.stopPropagation();
    setEditingIdPkArray(editingIdPkArray === pkitem.id ? null : pkitem.id);
    // 设置当前编辑的文本为pkitem的内容
    setEditText(pkitem.content);
  };

  //保存知识点数据的某个内容
  const handleSaveEdit = (id) => {
    // 创建一个新数组作为副本，以避免直接修改状态
    const newArray = [...pointKnowledgeArray];
    // 找到需要更新的元素的索引
    const indexToUpdate = newArray.findIndex(pkitem => pkitem.id === id);
    // 检查是否找到了元素
    if(indexToUpdate !== -1) {
      // 更新元素的内容
      newArray[indexToUpdate].content = editText;
      let arrayStr = JSON.stringify(newArray);
      updatePkAarray(arrayStr,"update_pk_array_for_common_chat");//更新服务端
      // 使用更新后的数组来更新状态
      setPointKnowledgeArray(newArray);
    }
    // 退出编辑状态
    setEditingIdPkArray(null);
  };

  const updatePkAarray = async(arrayStr,interfacename) =>{
    try {
      setIsLoading(true);
      const dataToSend = {
        id:message.id,
        content:arrayStr,
      };
      
      const data = await requestUrl(dataToSend,interfacename,t,showSnackbar);
      setIsLoading(false);
    }
    catch (error) {
      setIsLoading(false);
      console.error("There was an error fetching the reply:", error);
    }
    setIsLoading(false);
    
  }
   ///////////////////////////////

  const handleDisplayViewPrompt = async() => {
    setShowViewPrompt(true);
  }

  const handleCloseViewPrompt = async() => {
    setShowViewPrompt(false);
  }
  
  const handleGetPKMap = async() => {
    const contentString = pointKnowledgeArray.map(item => item.content).join(',');
    getPointKnowledgeMap(contentString);
  }

////////////////////////

const containerRef = useRef(null);
useEffect(() => {
  const handleClickLatexInner = (event) => {
    if (subject === "mathematics" || subject === "physics") {
      // 处理数学内容点击
      const katexElement = event.target.closest('.katex');
      if (katexElement) {
        const annotationElement = katexElement.querySelector('annotation[encoding="application/x-tex"]');
        if (annotationElement) {
          const latexContent = annotationElement.textContent;
          handleLatexClick(event,latexContent);
          
        } else {
          alert("无法找到LaTeX代码。");
        }
      }
    } 
    if (subject === "chemistry" || subject === "biology") {
      // 处理化学内容点击，包括 $ 包裹的内容
      let chemtype = "common"; // 默认为 common
      const katexElement = event.target.closest('.katex');
      if (katexElement) {
        // 如果点击的是数学公式
        const annotationElement = katexElement.querySelector('annotation[encoding="application/x-tex"]');
        if (annotationElement) {
          const latexContent = annotationElement.textContent;
          handleChemistryClick(event,"common",latexContent);
        }
      } else if (event.target.closest('smiles')) {
        // 如果点击的是 smiles 标签内的内容
        chemtype = "smiles";
        const chemistryContent = event.target.textContent;
        handleChemistryClick(event,"smiles",chemistryContent);
      }
    }
  };


  const container = containerRef.current;
  const interactiveElements = container.querySelectorAll('.katex, smiles');
  interactiveElements.forEach(element => {
    element.style.cursor = 'pointer';
    element.style.color = 'yellow';

    element.addEventListener('click', handleClickLatexInner);
  });

  // 清理函数，移除事件监听器
  return () => {
    interactiveElements.forEach(element => {
      element.removeEventListener('click', handleClickLatexInner);
    });
  };
}, [subject,message]); // 添加 subject 作为 useEffect 的依赖



const [isVisiblePhet, setIsVisiblePhet] = useState(false);
const [isVisibleGeo, setIsVisibleGeo] = useState(false);
const [isVisibleVideo, setIsVisibleVideo] = useState(false);
const [isVisibleQA, setIsVisibleVideoQA] = useState(false);
  const [isMaximized, setIsMaximized] = useState(false);

  const toggleVisibility = () => {
    setIsVisiblePhet(!isVisiblePhet);
  };

  const toggleVisibilityQA = () => {
    setIsVisibleVideoQA(!isVisibleQA);
  };

  const toggleVisibilityGeo = () => {
    setIsVisibleGeo(!isVisibleGeo);
  };

  const toggleVisibilityVideo = () => {
    setIsVisibleVideo(!isVisibleVideo);
  };
  const iframeRef = useRef(null);


  const [isFullscreenIframe, setIsFullscreenIframe] = useState(false);



  const iframeStyle = isFullscreenIframe
    ? {
        position: 'fixed',
        top: 0,
        right: 0,
        width: 'calc(100% - 250px)', // 假设左侧导航栏宽度为250px
        height: '100%',
        zIndex: 9999,
      }
    : {
        width: '70%',
        height: '500px',
      };

      const [expandedSteps, setExpandedSteps] = useState([]);

      const handleAccordionChange = (index) => () => {
        const currentIndex = expandedSteps.indexOf(index);
        const newExpanded = [...expandedSteps];
        if (currentIndex === -1) {
          newExpanded.push(index);
        } else {
          newExpanded.splice(currentIndex, 1);
        }
        setExpandedSteps(newExpanded);
      };
  

      const idShowStep = () => {
        
        let ret = false;
        if(fromModule === 'subject' && ['mathematics', 'physics', 'chemistry'].includes(subject) && questionType === 2){
          
          ret = true;
        }
        if(fromModule === 'subject' && ['chinese', 'english', 'geography','biology'].includes(subject)){
          
          ret = false;
        }

        return ret;
      }
    
    const parseContent = (content) => {
      // 先尝试匹配 ```cpp，如果没有匹配到，再匹配 ```code
      let codeRegex = /```cpp([\s\S]*?)```/g;
      let match;
      const parts = [];
      let lastIndex = 0;
  
      // 尝试匹配 ```cpp
      while ((match = codeRegex.exec(content)) !== null) {
          // Push the text before the code block
          if (match.index > lastIndex) {
              parts.push({
                  type: 'text',
                  content: content.substring(lastIndex, match.index),
              });
          }
          // Push the code block
          parts.push({
              type: 'cpp-code',
              content: match[1].trim(),
          });
          lastIndex = match.index + match[0].length;
      }
  
      // 如果没有匹配到 ```cpp，再尝试匹配 ```code
      if (parts.length === 0) {
          codeRegex = /```code([\s\S]*?)```/g;
          while ((match = codeRegex.exec(content)) !== null) {
              if (match.index > lastIndex) {
                  parts.push({
                      type: 'text',
                      content: content.substring(lastIndex, match.index),
                  });
              }
              parts.push({
                  type: 'code',
                  content: match[1].trim(),
              });
              lastIndex = match.index + match[0].length;
          }
      }
  
      // 如果没有匹配到 ```cpp 或者 ```code，尝试匹配 ```c++
      if (parts.length === 0) {
          codeRegex = /```c\+\+([\s\S]*?)```/g;
          while ((match = codeRegex.exec(content)) !== null) {
              if (match.index > lastIndex) {
                  parts.push({
                      type: 'text',
                      content: content.substring(lastIndex, match.index),
                  });
              }
              parts.push({
                  type: 'code',
                  content: match[1].trim(),
              });
              lastIndex = match.index + match[0].length;
          }
      }


      if (parts.length === 0) {
        codeRegex = /```([\s\S]*?)```/g;
        while ((match = codeRegex.exec(content)) !== null) {
            if (match.index > lastIndex) {
                parts.push({
                    type: 'text',
                    content: content.substring(lastIndex, match.index),
                });
            }
            parts.push({
                type: 'code',
                content: match[1].trim(),
            });
            lastIndex = match.index + match[0].length;
        }
    }



    if (parts.length === 0) {
      
      codeRegex = /```python([\s\S]*?)```/g;
      while ((match = codeRegex.exec(content)) !== null) {
          if (match.index > lastIndex) {
              parts.push({
                  type: 'text',
                  content: content.substring(lastIndex, match.index),
              });
          }
          parts.push({
              type: 'code',
              content: match[1].trim(),
          });
          lastIndex = match.index + match[0].length;
      }
    }


    if (parts.length === 0) {
      
      codeRegex = /```javascript([\s\S]*?)```/g;
      while ((match = codeRegex.exec(content)) !== null) {
          if (match.index > lastIndex) {
              parts.push({
                  type: 'text',
                  content: content.substring(lastIndex, match.index),
              });
          }
          parts.push({
              type: 'code',
              content: match[1].trim(),
          });
          lastIndex = match.index + match[0].length;
      }
    }

  
      // Push remaining text after the last code block
      if (lastIndex < content.length) {
          parts.push({
              type: 'text',
              content: content.substring(lastIndex),
          });
      }
  
      return parts;
  };
  

      
      const encodeUrl = (code) => {
        const encodedQueryParam = encodeURIComponent(code);
        let url = "https://pythontutor.com/visualize.html#code="+ encodedQueryParam + "&cumulative=false&heapPrimitives=nevernest&mode=edit&origin=opt-frontend.js&py=cpp_g%2B%2B9.3.0&rawInputLstJSON=%5B%5D&textReferences=false";
        return url;
      }
      


      const toggleMaximize = () => {
        setIsMaximized(!isMaximized);
      };

      const handleMaximizeClick = () => {
        setIsMaximized(!isMaximized);
      };


      const handleCopyCode = (code) => {
        navigator.clipboard.writeText(code);
        showSnackbar(t('copied'),'success');
      };

      const get_color_by_name = (name) => {

        const item = colorManager.find(item => item.container === name);
        return item.value;
      }

  return (
    <div> {/* Wrap everything in a single div */}
      {isFullScreen ? (
        <ItemFullScreenMessage className={isFullScreen ? 'show' : ''} onClick={handleExitFullScreenClick}>
          <div ref={containerRef} 
          style={{backgroundColor:'#2a3d66', wordWrap: 'break-word', overflowY: 'auto', padding: '10px', maxWidth: '100%',border: '1px solid white'}} 
          className="centered-markdown">
             
              <ReactMarkdown
                children={message.text}
                remarkPlugins={[remarkMath]}
                rehypePlugins={[rehypeRaw, rehypeKatex]} // 注意插件的顺序，rehypeRaw 先于 rehypeKatex
              />
            </div>
        </ItemFullScreenMessage>
      ) : (
        
        <div style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          padding: '10px',
          backgroundColor: get_color_by_name('message-item-bg-all'),

        }} className={message.sender}>
            
          <div style={{
            display:'flex',
            justifyContent:'left',
            alignItems:'center',
            maxWidth:'1000px',
            width:'100%',
            borderRadius:'20px',
            height:'40px',
            backgroundColor:get_color_by_name('message-item-bg-s'),
          }}>
            <div style={{
              width:'30px',
              height:'30px',
              borderRadius:'50%',
              backgroundSize:'cover',
              backgroundPosition:'center',
              marginLeft:'10px',
              backgroundImage: `url(${avatarSrc})`
            }}>


            </div>
            
            <div style={{
              fontSize:'14px',
              alignContent:'center',
              color:get_color_by_name('headline-color'),
              marginLeft:'40px'}} //时间与头像之间的间距
              >
                {message.time}
                </div>
            
          </div>
          <ItemMessageContent>
            <ItemMessageText ref={itemMessageTextRef}>
            <Paper
              ref={containerRef}
              sx={{
                wordWrap: 'break-word',
                fontSize: '30px',
                overflowY: 'auto',
                padding: '10px',
                width: '100%',
                
                borderRadius: '20px',
                marginBottom: '5px',
                backgroundColor:get_color_by_name('message-item-bg')
              }}
              className="centered-markdown"
            >

            <div>
              {message.analyse && message.analyse.length > 0 && (
                message.analyse.map((step, index) => (
                  <CustomAccordion
                    key={index}
                    expanded={expandedSteps.includes(index)}
                    onChange={handleAccordionChange(index)}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon sx={{ color: 'white' }} />}
                      sx={{ backgroundColor: 'inherit' }}
                    >
                      <Typography variant="body1">
                      <div className="markdown-r">{t('step')}{index + 1}：{step.stepname}</div>
                        </Typography>
                      <Typography variant="body1">{step.summarize}</Typography>
                    </AccordionSummary>
                    <CustomAccordionDetails>
                      <Typography variant="body1">
                      <div className="markdown-r">
                        <ReactMarkdown
                          children={step.stepcontent} 
                          remarkPlugins={[remarkMath]}
                          rehypePlugins={[rehypeRaw, rehypeKatex]}
                        />
                        </div>
                      </Typography>
                    </CustomAccordionDetails>
                  </CustomAccordion>
                ))
              )}
            </div>
            {message.sender === 'R' && idShowStep() === true &&(//r的回复，并且来自subject功能，显示完整结果
            <CustomAccordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon sx={{ color: 'white' }} />}
                sx={{ backgroundColor: 'inherit' }}
              >
                <Typography variant="body1"><div className="markdown-s">{t('full resolution')}</div></Typography>
              </AccordionSummary>
              <CustomAccordionDetails>
                <Typography variant="body1">
                <div className="markdown-r">
                  <ReactMarkdown
                    children={message.text}
                    remarkPlugins={[remarkMath]}
                    rehypePlugins={[rehypeRaw, rehypeKatex]}
                  />
                  </div>
                </Typography>
              </CustomAccordionDetails>
            </CustomAccordion>
            )}
            {
                message.sender === 'R' && idShowStep() === false && (
                  fromModule === 'science' ? (
                    // 如果subject为c++, 显示特定逻辑
                    <div>
                      {parts.map((part, index) => (
                        part.type === 'text' ? (
                          <div className="markdown-r">
                          <ReactMarkdown
                            key={`text-${index}`}
                            children={part.content}
                            remarkPlugins={[remarkMath]}
                            rehypePlugins={[rehypeRaw, rehypeKatex]}
                          />
                          </div>
                        ) : (
                          <div key={`code-${index}`} style={{ position: 'relative' }}>
                            <CustomButton
                              style={{ position: 'absolute', right: '10px', top: '10px' }}
                              onClick={() => handleCopyCode(part.content)}
                            >
                              {t('copy code')}
                            </CustomButton>
                            <SyntaxHighlighter language="cpp" style={dark}>
                            {part.content}
                          </SyntaxHighlighter>
                            
                          <div>
                          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '10px',marginBottom:'10px' }}>
                              <CustomButton onClick={() => setShowVisualExecute(!showVisualExecute)}>
                                {t('visual execute')}
                              </CustomButton>
                              {showVisualExecute && (
                                <CustomButton onClick={handleMaximizeClick}>
                                {isMaximized ? t('restore') : t('maximize')}
                              </CustomButton>
                                  )}
                                  
                              </div>
                            </div>

                            {showVisualExecute && (
                              <div style={isMaximized ? {
                                  position: 'fixed',
                                  top: 0,
                                  left: 0,
                                  width: '100%',
                                  height: '100%',
                                  zIndex: 1000,
                                  backgroundColor: 'white'
                                } : {
                                  height: '500px',
                                  width: '100%'
                                }}
                              >
                                <iframe
                                  style={{ width: '100%', height: '100%', border: 'none' }}
                                  src={encodeUrl(part.content)}
                                ></iframe>
                                {isMaximized && (
                                  <CustomButton onClick={handleMaximizeClick} style={{ position: 'absolute', top: 10, right: 10 }}>
                                    {t('restore')}
                                  </CustomButton>
                                )}
                              </div>
                            )}
                              </div>
                          </div>
                        )
                      ))}
                    </div>
                  ) : (
                    // 如果subject不是c++, 使用原有的渲染方式    
                    <div className="markdown-r">
                    <ReactMarkdown
                      children={message.text}
                      remarkPlugins={[remarkMath]}
                      rehypePlugins={[rehypeRaw, rehypeKatex]}
                    />
                    </div>
                  )
                )
              }

            {message.sender === 'R' && fromModule !== 'subject' && fromModule !== 'c++' && fromModule !== 'science' ** (//r的回复，并且来自subject功能，显示完整结果
                <div className="markdown-r">
                  <ReactMarkdown
                    children={message.text}
                    remarkPlugins={[remarkMath]}
                    rehypePlugins={[rehypeRaw, rehypeKatex]}
                  />
                </div>
            )}
          
          {message.sender === 'S' && ( /** S端发送消息显示 */
            <>
            <div>
              {sImage ? (
                <img src={sImage} alt="Displayed" />
              ) : (
               <></>
              )}
            </div>
            <div className="markdown-s">
              
            <ReactMarkdown
              children={message.text}
              remarkPlugins={[remarkMath]}
              rehypePlugins={[rehypeRaw, rehypeKatex]}
             
            />
          </div>
            </>
            )}
            </Paper>




            {message.sender === 'R' && (
              <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 2 }}>
              {
                message.imageArray && message.imageArray.length > 0 && (
                
                  message.imageArray.map((item, index) => (
                    <Card key={index} sx={{ maxWidth: 345, margin: 'auto' }}>
                      <CardContent>
                        <Typography gutterBottom variant="h5" component="div">
                          {item.description}
                        </Typography>
                      </CardContent>
                      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                        <CardMedia
                          component="img"
                          image={item.image.match(/src='([^']+)'/)[1]} // Extract the src from the img_tag string
                          alt="image"
                          sx={{ width: 'auto', maxHeight: 300, margin: 'auto' }} // Adjust these values as needed
                        />
                      </Box>
                    </Card>
                  ))
                )
              }

            
            </Box>
            )}

            {message.sender === 'R' && subject === 'physics' && (
              <MuiButton variant="contained" color="primary" onClick={hanndleGeneratePhsicsImage}>try it! generate animation</MuiButton>
            )}
            {/* 
            {message.sender === 'R' && subject === 'mathematics' && (
              <MuiButton variant="contained" color="primary" onClick={hanndleGenerateMathGeometry}>try it! generate image</MuiButton>
            )}
            */}
            {message.sender === 'R' && useWf === 0 && ['mathematics', 'physics', 'chemistry'].includes(subject) && (
            
              <div style={{display:'flex', justifyContent:'center', flexDirection: 'column', alignItems: 'center',marginBottom:'10px'}}>
              <MuiButton variant="contained" color="primary" onClick={hanndleTryAgainGetAnswer}>
                {t("not satisfied with the answer? strengthened answer?")}
              </MuiButton>
              </div>
            )}

            {message.sender === 'R' && useWf === 0 && ['mathematics', 'physics', 'chemistry'].includes(subject) && (

            <Box sx={{width: '100%', display: 'flex', flexDirection: 'row',justifyContent:'center',gap:'10px', alignItems: 'center',marginBottom:'10px'}}>
              {message.qARecord && message.qARecord.length > 0 && (
                <Button
                  startIcon={isVisibleQA ? <VisibilityOffIcon /> : <VisibilityIcon />}
                  onClick={toggleVisibilityQA}
                  variant="contained"
                  color="primary"
                  style={{ marginBottom: '1rem' }}
                >
                  {isVisibleQA ? 'Hide QA' : 'Show QA'}
                </Button>
              )}

              {message.sender === 'R' && message.phetRecord && message.phetRecord.length > 0 && (
                <CustomButton
                  startIcon={isVisiblePhet ? <VisibilityOffIcon /> : <VisibilityIcon />}
                  onClick={toggleVisibility}
                >
                  {isVisiblePhet ? t('hide phet courseware') : t('show phet courseware')}
                </CustomButton>
              )}

              {/* {message.geoRecord && message.geoRecord!=undefined && (*/}
                {message.sender === 'R' && message.geoRecord && message.geoRecord.length > 0 && (
                <CustomButton
                    startIcon={isVisibleGeo ? <VisibilityOffIcon /> : <VisibilityIcon />}
                    onClick={toggleVisibilityGeo}
                  >
                    {isVisibleGeo ? t('hide geo courseware') : t('show geo courseware')}
                  </CustomButton>
                )}

                {message.sender === 'R' && message.videoRecord && message.videoRecord.length > 0 && (
                  <CustomButton
                  startIcon={isVisibleVideo ? <VisibilityOffIcon /> : <VisibilityIcon />}
                  onClick={toggleVisibilityVideo}
                  > 
                    {isVisibleVideo ? t('hide video courseware') : 'show video courseware'}
                  </CustomButton>
                )}
              </Box>
            )}

              {/* qa*/}
            {message.sender === 'R' && useWf === 0 && ['mathematics', 'physics', 'chemistry'].includes(subject) && (
              <div style={{display:'flex', justifyContent:'center', flexDirection: 'column', alignItems: 'center'}}>
             
              {isVisibleQA && (
                <Box sx={{color:'yellow', width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                  {
                    message.qARecord && message.qARecord.length > 0 && (
                      message.qARecord.map((item, index) => (
                        <div key={index} style={{ marginBottom: '10px', textAlign: 'center' }}>
                          <div
                            onClick={() => toggleAnswerDisplay(index)}
                            style={{ fontSize: '14px' }}
                          >
                            {t('question')}{index + 1}:&nbsp;&nbsp;&nbsp;{parseAndRenderMath(item.q)}&nbsp;&nbsp;（{t('click to show answers')}）
                          </div>
                          <div
                            style={{
                              display: activeIndex === index ? 'block' : 'none',
                              color: 'white',
                              cursor: 'default'
                            }}
                          >
                            <div className="markdown-s">
                              <ReactMarkdown
                                children={item.a}
                                remarkPlugins={[remarkMath]}
                                rehypePlugins={[rehypeRaw, rehypeKatex]}
                              />
                          </div>

                            
                            <button
                              style={{
                                color: '#3b99ff',
                                backgroundColor: 'white',
                                borderRadius: '10px',
                                fontSize: '14px',
                                cursor: 'help',
                                marginTop: '10px' // 保持按钮在内容下方
                              }}
                              onClick={() => handleAnalyseAnswer(item,"deep")}
                            >
                              {t('Continue with in-depth questions')}
                            </button>
                          </div>
                        </div>
                      ))
                    )
                  }
                </Box>
              )}
            </div>
            )}

            {/*phet */}
            {message.sender === 'R' && useWf === 0 && ['mathematics', 'physics', 'chemistry'].includes(subject) && (
              
              <div style={{display:'flex', justifyContent:'center', flexDirection: 'column', alignItems: 'center'}}>
              
              {isVisiblePhet && (
                <Box sx={{width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                  {
                    message.phetRecord && message.phetRecord.length > 0 && (
                      message.phetRecord.map((item) => (
                        // 使用Box组件为Accordion添加外边距
                        <Box key={item.id} sx={{ marginBottom: '10px', width: '60%' }}>
                          <Accordion>
                          <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1a-content"
                          id={`panel1a-header-${item.id}`}
                          sx={{ color:get_color_by_name('mui-button-text-color'), backgroundColor:get_color_by_name('mui-button-bg'), alignItems: 'center', display: 'flex', justifyContent: 'space-between' }} // 使用Flex布局确保内容水平对齐
                        >
                            {/* 左侧内容 */}
                            <Typography sx={{ flex: 1 }}>{item.topics}</Typography>

                            {/* 右侧内容，和箭头水平对齐 */}
                            {/* 添加你希望展示的内容 */}
                            <Typography sx={{ color: get_color_by_name('mui-button-text-color'), flex: 1, textAlign: 'right' }}>
                              {/* 可以放置你想要的任何内容，这里以额外信息为例 */}
                              {item.topics}
                            </Typography>
                          </AccordionSummary>
                                <AccordionDetails style={{ flexDirection: 'column' }}>
                                  <Typography style={{ color: 'black' }} paragraph>{item.learning_objectives.split(",").join(", ")}</Typography>
                                  
                                  {/* 确保您的 ResponsiveIframe 支持 ref */}
                                  <iframe ref={iframeRef} src={`${getHostNameWeb()}/document/phet/${item.pname}/${item.html_filename}`} style={iframeStyle} />
                            
                                </AccordionDetails>
                              </Accordion>
                            </Box>
                        ))
                    )
                  }
                  </Box>
              )}
            </div>
            )}

            {message.sender === 'R' && useWf === 0 && ['mathematics', 'physics', 'chemistry'].includes(subject) && (
                 <div style={{display:'flex', justifyContent:'center', flexDirection: 'column', alignItems: 'center'}}>
                
                {isVisibleGeo && (
                  <Box sx={{width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                    {
                      message.geoRecord && message.geoRecord.length > 0 && (
                        message.geoRecord.map((item, index) => (
                        // 使用Box组件为Accordion添加外边距
                        <Box key={item.id} sx={{ marginBottom: '10px', width: '60%' }}>
                          <Accordion>
                          <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls={`panel${index}a-content`}
                          id={`panel${index}a-header`}
                          sx={{color:get_color_by_name('mui-button-text-color'), backgroundColor:get_color_by_name('mui-button-bg'), alignItems: 'center', display: 'flex', justifyContent: 'space-between' }} // 使用Flex布局确保内容水平对齐
                        >
                            {/* 左侧内容 */}
                            <Typography sx={{ flex: 1 }}>{item.topics}</Typography>
              
                            {/* 右侧内容，和箭头水平对齐 */}
                            {/* 添加你希望展示的内容 */}
                            <Typography sx={{ color:get_color_by_name('mui-button-text-color'), flex: 1, textAlign: 'right' }}>
                              {/* 可以放置你想要的任何内容，这里以额外信息为例 */}
                              {index + 1}
                            </Typography>
                          </AccordionSummary>
                                <AccordionDetails style={{ flexDirection: 'column' }}>
                                
                                  
                                  {/* 确保您的 ResponsiveIframe 支持 ref */}
                                  <iframe
                                    style={{
                                      width: '100%',
                                      height: '500px', // 可以根据需要调整高度
                                      scrollbarWidth: 'thin',
                                      scrollbarColor: '#888 #f1f1f1'
                                    }}
                                    src={`${getHostNameWeb()}/document/geohtml/${item.gname}`}
                                    title={`Content ${index + 1}`}
                                  />
                            
                                </AccordionDetails>
                              </Accordion>
                            </Box>
                        ))
                    )}

                  </Box>
                )}
              </div>
            )}

            {message.sender === 'R' && useWf === 0 && ['mathematics', 'physics', 'chemistry'].includes(subject) && (
             <div style={{display:'flex', justifyContent:'center', flexDirection: 'column', alignItems: 'center'}}>
              
              {isVisibleVideo && (
                <Box sx={{width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                <Grid container spacing={0}>
                  {
                    message.videoRecord && message.videoRecord.length > 0 && (
                      message.videoRecord.map((item, index) => (
                        <Grid item xs={12} sm={6} lg={4} key={index} sx={{ display: 'flex', justifyContent: 'center', p: 0.5 }}>
                          {/* 为每个Grid项手动设置padding以实现大约10px的间距 */}
                          <MuiCard elevation={4} sx={{ width: '100%', maxWidth: 345, mb: 2, mx: 'auto' }}>
                            <CardActionArea onClick={() => setSelectedVideo(item.filename)}>
                              <CardContent sx={{ p: 2 }}>
                                <Typography gutterBottom variant="subtitle1" component="div" sx={{ textAlign: 'center', fontSize: '1rem' }}>
                                  {item.pcontent}
                                </Typography>
                              </CardContent>
                            </CardActionArea>
                          </MuiCard>
                        </Grid>
                      ))
                    )
                  }
                </Grid>
                {selectedVideo && (
                  <Box my={2}>
                    <Typography variant="h6" gutterBottom>
                      {t('play video')}
                    </Typography>
                    <CardMedia
                      component="video"
                      controls
                      sx={{
                        maxHeight: { xs: '250px', sm: '350px', lg: '450px' },
                        width: '100%',
                        objectFit: 'contain'
                      }}
                      src={`${getHostNameWeb()}document/video/${selectedVideo}`}
                      title="Selected Video"
                    />
                  </Box>
                )}
              </Box>
              )}
            </div>
              
            )}

            
            {/** 0220新增开始 */}
            {message.sender === 'R' && ['mathematics', 'physics', 'chemistry'].includes(subject) && (
              <Grid item xs={visible === 2 ? 6 : 12}>
                {
                  message.sender === 'R' && 
                  ['mathematics', 'physics', 'chemistry', 'english', 'chinese', 'biology', 'geography'].includes(subject) &&  pointKnowledgeArray &&
                  pointKnowledgeArray !== undefined && pointKnowledgeArray.length > 0 && (
                    <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center' }}>
                      <IconButton aria-label="analysis" sx={{ backgroundColor: 'white', color: 'orange' }} onClick={toggleTopContainer}>
                        <AnalyticsIcon />
                      </IconButton>
                    </div>
                  )
                }


              <Box sx={{width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                {showTopContainer && (
                    <Container maxWidth="sm">
                    <Box sx={{ borderRadius:'10px',bgcolor: '#cfe8fc', p: 2, display: 'flex', flexDirection: 'column', gap: 2 }}>
                      {/* 上部分的数组分组显示 */}
                      <Box sx={{ bgcolor: 'white', p: 2 }}>

                        
                      <Grid container spacing={2}>
                        {
                        pointKnowledgeArray !== undefined && pointKnowledgeArray.length > 0 && (
                          pointKnowledgeArray.map((pkitem) => (
                            <Grid item xs={pkitem.xs} key={pkitem.id} onClick={(event) => toggleEditPkArray(pkitem, event)}>
                              <div style={itemStyle}>
                                {editingIdPkArray === pkitem.id ? (
                                  // 编辑状态：显示输入框，允许用户修改文本
                                  <div id={`edit-${pkitem.id}`}>
                                    <TextField
                                    sx={{
                                      backgroundColor: 'white',
                                      // Targeting the input element for styling
                                      '& .MuiInputBase-input': {
                                        color: 'black', // Change text color
                                      },
                                    }}
                                    multiline
                                    maxRows={4}
                                    onClick={(e) => e.stopPropagation()} 
                                    className="editable-input" 
                                    style={{color:'black'}} 
                                    type="text" 
                                    value={editText} 
                                    onChange={(e) => setEditText(e.target.value)} 
                                    onBlur={() => handleSaveEdit(pkitem.id)} />
                                    
                                  
                                  </div>
                                ) : (
                                  // 非编辑状态：显示文本
                                  <span dangerouslySetInnerHTML={{ __html: renderTextAndMath(pkitem.content) }} />
                                )}
                                <IconButton onClick={(event) => handleDeletePointKnowledge(pkitem, event)} aria-label="delete">
                                  <DeleteIcon sx={{ color: 'white' }} />
                                </IconButton>
                                {editingIdPkArray === pkitem.id && (
                                  // 编辑状态时显示保存图标
                                  <IconButton onClick={() => handleSaveEdit(pkitem.id)} aria-label="save">
                                    <SaveIcon sx={{ color: 'white' }} />
                                  </IconButton>
                                )}
                              </div>
                            </Grid>
                          ))
                        )
                      }
                      </Grid>
                      
                      </Box>
    
                      {/* 中间的总结文字内容 */}
                      <Box sx={{ bgcolor: '#7D26CD', p: 2, flex: 1 }} onDoubleClick={handleDoubleClick}>
                        {!pkIsEditing ? (
                          <Typography sx={{ mb: 1.5, color: 'white' }} dangerouslySetInnerHTML={{ __html: renderTextAndMath(pointKnowledgeContent) }}>
                            {/* 内容通过 dangerouslySetInnerHTML 插入 */}
                            
                          </Typography>
                        ) : (
                          <TextField
                          sx={{backgroundColor:'white',color:'black'}}
                            fullWidth
                            multiline
                            variant="outlined"
                            value={pointKnowledgeContent}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            autoFocus
                          />
                        )}
                      </Box>
                      {/* 下容器 */}
                      <Box sx={{ bgcolor: 'primary.main', p: 2, display: 'flex', justifyContent: 'center' }}>
    
                      {!pkIsEditing ? (
                        <IconButton sx={{ backgroundColor:'white', color: 'orange' }} onClick={toggleEdit}>
                          <EditIcon /> {/* 非编辑状态显示编辑图标按钮 */}
                        </IconButton>
                      ) : (
                        <IconButton sx={{backgroundColor:'white', color: 'orange' }} onClick={toggleEdit}>
                          <SaveIcon /> {/* 编辑状态显示保存图标按钮 */}
                        </IconButton>
                      )}
                      </Box>
                    </Box>
                  </Container>
                  
                )}
              </Box>

           </Grid>
            )}
            {/** 0220新增开始 */}

            </ItemMessageText>
            {message.sender === 'R' && (
              <ItemMessageActions>
                <Button icon={<FileOutlined />} className="print-button" onClick={handlePrintClick} />
                <Button icon={<FileWordOutlined />} className="export-button" onClick={() => handleExportClick('word')} />
                <Button icon={<FileMarkdownOutlined />} className="export-button" onClick={() => handleExportClick('markdown')} />
                <Button icon={<CopyOutlined />} className="copy-button" onClick={handleCopyClick} />
                <Button icon={<FullscreenOutlined />} className="full-screen-button" onClick={handleFullScreenClick} />
                <Button
                  icon={<StarFilled style={{ color: isFavorited ? 'gold' : 'black' }} />}
                  className="favorite-button"
                  onClick={handleFavoriteClick}
                />
                <Button onClick={handleSubjectTTS}>
                {playing ? <VolumeUp /> : <VolumeUp />}
              </Button>
              {isVisiblePlaying && (
                <div>
                  <audio controls ref={audioRef} src={audioSrc} preload="metadata" />
                </div>
              )}
              
              </ItemMessageActions>
           )} 

            
          {rolename === "admin" && message.sender === "R" && (
           <Button
           variant='contained'
           onClick={handleDisplayViewPrompt}
           fullWidth
           size='large'
           sx={{
             textTransform: 'none',
           }}
         >
           {t('visible to administrators')}:{t('request history')}
       </Button>
        )}
          </ItemMessageContent>

        </div>
      )}
      {isLoading && (
        <LoadingData text={t(loadingText)}/>
      )}
      {/* Modal code - outside of the main conditional rendering */}
      {showModal && (
        <ModalBackground>
          <CloseButton  onClick={handleCloseModal}>关闭</CloseButton >
          <PlotlyDiv id="plotlyDiv">
            {/* Image rendering logic, you can call a function to render the LaTeX expression */}
          </PlotlyDiv>
        </ModalBackground>
      )}

      {isModalVisible && (
        <div style={modalStyle}>
          <div style={headerStyle}>
          
          <IconButton onClick={handleChemPlayOrPause}>
          {chemRotate ? <PauseIcon /> : <LoopIcon />}
          </IconButton>
          <IconButton onClick={closeModal}>
            <CloseIcon />
          </IconButton>
          </div>
            <div ref={threeDMolViewerRef} style={viewerContainerStyle}></div>
        </div>
      )}
      {isModalVisiblePhysical && (
        <div style={modalStyle}>
          <div style={headerStyle}>
            
            <IconButton onClick={handlePlayPhysicalImage}>
            <PlayArrowIcon />
            </IconButton>
            <LoopIcon onClick={handleLoopPlay} style={{ cursor: 'pointer' }} />
            <IconButton aria-label="settings" onClick={toggleDivDisplay}>
              <SettingsIcon />
          </IconButton>
          <IconButton onClick={closeModalPhysical}>
              <CloseIcon />
            </IconButton>
          </div>
          <StyledDivPhys>
            <StyledDivPhyImage ref={threeDMolViewerRefPhysical} />
            {showSecondDiv && <StyledDivCode ref={setContainer} />}
          </StyledDivPhys>
        </div>
      )}
      {isVisibleMathGeometry && (
        <div style={modalStyle}>
          <GeoGebraGraph appName={geoMathGeometry} language='zh' xmlCode={geoXmlCode} dbid={dbid} onCloseMathGeo={onCloseMathGeo} />
        </div> 
      )}

      {showViewPrompt && (
        <div style={centerStyle}>
          <ViewPrompt chatId={dbid} viewPromptType="common" onClose={handleCloseViewPrompt}/>
        </div> 
      )}
    </div>
  );
}

export default MessageItem;