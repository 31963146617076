import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import MessageItem from './MessageItem'; // 确保路径正确

// 修改MessageListContainer以接受一个height prop
const MessageListContainer = styled.div`
  flex: 1;
  overflow-y: auto;
  height: ${props => props.height}px; // 使用props动态设置高度
  background-color: black; // 浅色背景
  border-radius: 10px;
`;

const MessageList = ({ messages, handleItemClick, subject, onReceiveStringFromMessItem, useWf, getPointKnowledgeMap, fromModule,questionType }) => {
  const containerRef = useRef(null);
  const [containerHeight, setContainerHeight] = useState(window.innerHeight - 230); // 初始高度计算

  useEffect(() => {
    const handleResize = () => {
      setContainerHeight(window.innerHeight - 230); // 更新高度
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize); // 清理监听器
  }, []);

  useEffect(() => {
    const container = containerRef.current;
    if (container) {
      container.scrollTop = container.scrollHeight;
    }
  }, [messages]);

  const handleIframeLoad = () => {
    const container = containerRef.current;
    if (container) {
      container.scrollTop = container.scrollHeight;
    }
  };

  return (
    <MessageListContainer ref={containerRef} height={containerHeight}>
      {messages.map((message, index) => (
        <MessageItem
          key={index}
          message={message}
          onClick={() => handleItemClick(message)}
          subject={subject}
          onIframeLoad={handleIframeLoad}
          onReceiveStringFromMessItem={onReceiveStringFromMessItem}
          useWf={useWf}
          getPointKnowledgeMap={getPointKnowledgeMap}
          fromModule={fromModule}
          questionType={questionType}
        />
      ))}
    </MessageListContainer>
  );
};

export default MessageList;
