import React, { useState, useEffect, useRef } from 'react';
import ChatWindow from '../ChatWindow/ChatWindow';
import LatexModule from '../Subject/LatexModule';
import { useSnackbar } from '../../SnackbarProvider';
import CommonCombobox from '../../components/CommonCombobox';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton,Grid } from '@mui/material';
import QuizIcon from '@mui/icons-material/Quiz';
import styled from 'styled-components';
import './MainStudyFeynman.css';
import ReactMarkdown from 'react-markdown';
import AudioRecorder from '../AudioRecorder/AudioRecorder';
import TextField from '@mui/material/TextField';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Pagination,Modal } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { deviceType_m_t_d } from '../../utils/commonUtils';
import '../Subject/MainStudy.css';
import { v4 as uuidv4 } from 'uuid';
import DescriptionIcon from '@mui/icons-material/Description';
import ClearIcon from '@mui/icons-material/Clear';
import SystemHelp from '../SystemHelp/SystemHelp';
import DeleteIcon from '@mui/icons-material/Delete';
import { requestUrl } from '../../requestUrl';
import { get_gradelist_by_subject } from '../../utils/commonUtils';
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import Draggable from 'react-draggable';
import { Refresh } from '@mui/icons-material';
import SettingsIcon from '@mui/icons-material/Settings';
import LoadingData from '../dataloading';
import AddCardIcon from '@mui/icons-material/AddCard';
import WebAssetIcon from '@mui/icons-material/WebAsset';
import { Typography, Accordion, AccordionSummary, AccordionDetails,Box } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CustomButton from '../../components/CustomButton';
import { getColorDark,getColorLight } from '../../ColorManager';
export const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 8888;
`;

export const ModalContainer = styled.div`
  zIndex:9999;
  background-color: white;
  padding: 20px;
  width: 100%;
  max-width: 800px;
  height: 100%;
  max-height: 600px;
  overflow-y: auto;
  border-radius: 8px;
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.1);
`;

const Container = styled.div`
  width: 100%;
  height: 85vh;
  @media (min-width: 768px) {  /* 针对平板和电脑屏幕 */
    width: 98%;
  }
`

const Middle = styled.div`
  height: calc(90vh -40px);  /* 计算剩余高度 */
  background-color: #eee;
  
`;

const MainStudyFeynman = () => {
  const { t } = useTranslation();
  const [selectedGradeOption, setSelectedGradeOption] = useState('7');
  const [selectedSubjectOptionText, setSelectedSubjectOptionText] = useState(null);
  const [isLatexModalVisible, setLatexModalVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [messages, setMessages] = useState([]);
  const [inputText, setInputText] = useState(''); // 文本框的值
  const [uploadedImage, setUploadedImage] = useState(null); //从inputarea中传过来的上传图片
  const [cuspredefinedContent, setCuspredefinedContent] = useState([]);
  const [selectedTypeId, setSelectedTypeId] = useState('1');
  // 列表项的点击事件
  const [pageList, setPageList] = useState([1]);
  const [isShowAudioRecorder, setIsShowAudioRecorder] = useState(false);
  const [pointTopicList, setPointTopicList] = useState({});
  const [isOpenPointTopicHistory, setIsOpenPointTopicHistory] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [selectedRowId, setSelectedRowId] = useState(0);
  const [selectedRowTopicText, setSelectedRowTopicText] = useState('');

  const [selectedRowData, setSelectedRowData] = useState(null);
  const [pointTopicText, setPointTopicText] = useState('');
  const [currentPointTopicDbid, setCurrentPointTopicDbid] = useState(0);//后台返回的主题ID
  const [anchorEl, setAnchorEl] = useState(null);
  const [translateText, setTranslateText] = useState('');
  const [markdownText, setMarkdownText] = useState('# Hello Markdown');
  const [showA, setShowA] = useState(false);
  const [blankPageContentElement, setBlankPageContentElement] = useState();//填充刚加载页面的空白element
  const [isDisplayBlankContent, setIsDisplayBlankContent] = useState(true);
  const [sessionChatId, setSessionChatId] = useState('');
  const [isNeedCreateSeesionChat, setIsNeedCreateSeesionChat] = useState(true);
  const [loadingText,setLoadingText] = useState('loading');
  const [isShowSystemHelp, setIsShowSystemHelp] = useState(false);
  const [conversationCount, setConversationCount] = useState(0);
  const [maxConversationCount, setMaxConversationCount] = useState(20);
  const [totalPagesSession, setTotalPagesSession] = useState(1);//0220

  const [colorManager, setColorManager] = useState(getColorDark()); // 初始化为空数组

  const showSnackbar = useSnackbar();

  const [gradeOptions, setGradeOptions] = useState([
    { id: '1', text: '1' },
    { id: '2', text: '2' },
    { id: '3', text: '3' },
    { id: '4', text: '4' },
    { id: '5', text: '5' },
    { id: '6', text: '6' },
    { id: '7', text: '7' },
    { id: '8', text: '8' },
    { id: '9', text: '9' },
    { id: '10', text: '10' },
    { id: '11', text: '11' },
    { id: '12', text: '12' },
    // ... Add more options
  ]);

  const subjectOptions = [
    { id: 'mathematics', text: t('mathematics') },
    { id: 'physics', text: t('physics') },
    { id: 'chemistry', text: t('chemistry') },
    { id: 'geography', text: t('geography') },
    { id: 'biology', text: t('biology') },
    // ... Add more options
  ];


  //获取颜色设置，系统加载时设置
  useEffect(() => {

    const themeName = localStorage.getItem('theme');//此处存储的是数字1-12，而不是汉字，
    if (themeName) {
      
      if(themeName === "dark"){
        setColorManager(getColorDark());
      }

      
      if(themeName === "light"){
        setColorManager(getColorLight());
      }
    }
    else{
      let aa = getColorDark();
      setColorManager(aa);
    }

    
  }, []);

  const get_color_by_name = (name) => {

    const item = colorManager.find(item => item.container === name);
    return item.value;
  }


  const onCloseHelp = () => {

    setIsShowSystemHelp(false);
  }


  const handleSystemHelp = () => {
    setIsSettingButtonOpen(false);
    setIsShowSystemHelp(true);
  };

  const loadSubjectData = async (page) => {
    if (!selectedSubjectOptionText || !selectedGradeOption) {
      
      showSnackbar(t('please select the subject and grade first'),'warning');
      return;
    }

    setIsDisplayBlankContent(false);

    const token = localStorage.getItem("access_token");
    setAnchorEl(null);
    try {
      setIsLoading(true);
      const dataToSend = {
        ptid: selectedRowId,
        page_size: 50,
        page_number: page,
      }
      const data = await requestUrl(dataToSend, "get_feynman_chat_by_ptid");
      setIsDisplayBlankContent(false);
      setMessages(data.data);
      if (page === 1) {
        setPageList(data.total_pages);
      }

      setPointTopicText(selectedRowTopicText);
      setCurrentPointTopicDbid(selectedRowId);
      setIsNeedCreateSeesionChat(false);//无需创建sessionchat表数据
      setConversationCount(data.total_records);

      setIsLoading(false);
    }
    catch (error) {
      setIsLoading(false);
      console.error("There was an error fetching the reply:", error);
      setIsNeedCreateSeesionChat(false);//无需创建sessionchat表数据
      
    }
  }


  const load_point_topic_list = async (subject, grade) => {
    setIsDisplayBlankContent(true);
    try {
      setIsLoading(true);
      const dataToSend = {
        subject: subject,
        grade_value: grade,
      };
      const data = await requestUrl(dataToSend, "get_point_topic_feynman");
      setPointTopicList(data.point_topic);
      setMaxConversationCount(data.feynmanmax);
      setIsLoading(false);
    }
    catch (error) {
      setIsLoading(false);
      console.error("There was an error fetching the reply:", error);
    }
  };


  const onListItemClick = async (selectedSubject) => {
 
    setMessages([]);
    setSelectedSubjectOptionText(selectedSubject);
    localStorage.setItem('feynmansubject',selectedSubject);

    let gradeR, gradeOptions1;

    [gradeR, gradeOptions1] = get_gradelist_by_subject(selectedGradeOption, selectedSubject);

    setGradeOptions(gradeOptions1);
    setSelectedGradeOption(gradeR);

    load_point_topic_list(selectedSubject, gradeR);
  };

  const [aiQuestion, setAiQuestion] = useState('none');

  const handleSendMessage = async (message) => {
    if (message === "") {
      
      showSnackbar(t('please input content'),'warning');

      return;
    }
    console.log(selectedSubjectOptionText + "###" + selectedGradeOption);
    if (selectedSubjectOptionText === null || selectedGradeOption === null) {
      
      showSnackbar(t('you must select subject and grade'),'warning');
      setTimeout(() => {
        inputAreaRef.current.setInputValue(message);
      }, 1000);

      return;
    }

    if (conversationCount >= maxConversationCount) {      
      showSnackbar(t('maximum number of conversations reached'),'warning');
      return;
    }

    //如果没有指定主题，需要提醒指定主题
    if (pointTopicText === "") {
      
      showSnackbar(t('you must choose a topic'),'warning');
      setTimeout(() => {
        inputAreaRef.current.setInputValue(message);
      }, 1000);
      setShowPointTopicDetailDiv(true);
      return;
    }

    let dddd;
    if (uploadedImage) {
      dddd = "<img src={uploadedImage} />";
      // 有图片
    } else {
      // 没有图片
    }
    setIsDisplayBlankContent(false);
    // 处理发送消息逻辑
    if (message != "begin") {
      setMessages(prevMessages => [...prevMessages, { sender: 'S', text: message, time: new Date().toLocaleTimeString() }]);
    }

    try {
      // 合成客户端的JSON数据

      const token = localStorage.getItem("access_token");
      setIsLoading(true);

      let dataimage = null;
      if (uploadedImage === null) {
        dataimage = 'none';
      }
      else {
        dataimage = uploadedImage;
      }
      //qtype
      const dataToSend = {
        subjectValue: selectedSubjectOptionText,
        gradeValue: selectedGradeOption,
        inputValue: message,
        image: dataimage,
        pointTopicId: currentPointTopicDbid,
        pointTopicText: pointTopicText,
        isNeedCreateSeesionChat: isNeedCreateSeesionChat,
        aiquestion: aiQuestion,
      };

      setUploadedImage(null);
      const dat_server = await requestUrl(dataToSend, "question_feynman");
      const { dbid, dbptid, response_text } = dat_server;
      setCurrentPointTopicDbid(dbptid);
      setSelectedRowId(dbptid);
      setAiQuestion(response_text);
      setMessages(prevMessages => [...prevMessages, { id: dbid, sender: 'R', text: response_text, time: new Date().toLocaleTimeString() }]);
      setIsLoading(false);
      setConversationCount(conversationCount + 1);

    }
    catch (error) {
      setIsLoading(false);
      console.error("There was an error fetching the reply:", error);
    }
  };


  //选择年级事件
  const handleMenuItemGradeClick = async (selectedGrade) => {
    let grade = selectedGrade.toString();

    console.log("song");
    setSelectedGradeOption(grade);
    localStorage.setItem('grade', grade);

    setMessages([]);
    load_point_topic_list(selectedSubjectOptionText,grade);
  };

  //选择主题事件
  const handleMenuItemSversionClick = async (e) => {

    //setPointTopicText(selectedComboxPointTopic);
    setPointTopicText(e.target.value);
    

  };

  const handleDocumentUpdate = (updatedContent) => {
    setLatexModalVisible(false);
    setInputText(updatedContent); // 设置 inputValue
    setSelectedTypeId('1');
  };

  useEffect(() => {
    const defaultGrade = localStorage.getItem('grade');//此处存储的是数字1-12，而不是汉字，
    if (defaultGrade) {
      setSelectedGradeOption(defaultGrade);
      const sid = uuidv4().replace(/-/g, '');
      setSessionChatId(sid);

      setSelectedRowId(0);
      setCurrentPointTopicDbid(0);

    }

    const defaultSubject = localStorage.getItem('feynmansubject');
    if (defaultSubject) {
      setSelectedSubjectOptionText(defaultSubject);

    }
    else{
      setSelectedSubjectOptionText('mathematics');
    }
    if (defaultGrade && defaultSubject) {

      load_point_topic_list(defaultSubject, defaultGrade);
    }
  }, []);

  const handleClearRecord = () => {
    setIsDisplayBlankContent(true);
    setMessages([]);
    setAnchorEl(null);
    setIsNeedCreateSeesionChat(true);
    setConversationCount(0);
    setPointTopicText('');
    setSelectedRowId(0);
    setCurrentPointTopicDbid(0);
  }

  const onReceiveStringFromMessItem = (str) => {
    // 处理发送消息逻辑
    const mess = str + "<questionflag>chatinner</questionflag>";
    handleSendMessage(mess);
  };

  const handleImageUpload = (imageData) => {

    setUploadedImage(imageData);
  };

  const handleAddPT = async () => {
    console.log("sss");
    setAnchorEl(null);
    setMessages([]);
    //setOpen(false);
    setIsNeedCreateSeesionChat(true);//

    setIsPlaying(true);

    
    setIsSettingButtonOpen(false);
    setShowPointTopicDetailDiv(false);
  };


  const handleAudioRecord = () => {
    setIsShowAudioRecorder(true);
  }

  const onCloseOnly = () => {
    setIsShowAudioRecorder(false);
  }

  const onCloseAudioRecord = () => {
    setIsShowAudioRecorder(false);
    //setIsetInputValueEnable(true);
    let textOld = inputAreaRef.current.getInputValue();

    inputAreaRef.current.setInputValue(textOld + translateText);
    //setIsetInputValueEnable(false);
  }


  const toggleA = async () => {

    if (selectedRowId === null) {
      alert("没有主题");
      return;
    }
    if (messages.length === 0) {
      alert("还没有记录");
      return;
    }
    setShowA(!showA);

    if (showA === true) {
      return;
    }
    try {
      setIsLoading(true);
      const dataToSend = {
        ptid: currentPointTopicDbid,
      };
      const data = await requestUrl(dataToSend, "get_point_topic_analyse_list");
      setMarkdownText(data.data[0].content);
      setIsLoading(false);

    }
    catch (error) {
      setIsLoading(false);
      console.error("There was an error fetching the reply:", error);
    }
  };

  const handleChangePointTopicTextBox = (event) => {
    setPointTopicText(event.target.value);  // 更新状态
  };

  const handleGetPointTopicHistory = async () => {
    setIsSettingButtonOpen(false);
    setIsOpenPointTopicHistory(true);
  };

  //关闭主题历史组件
  const handleClosePTHistory = () => {
    setIsOpenPointTopicHistory(false);
  };

    //获取主题历史记录按钮
    const handleGetPTHistory = async () => {//0220
      loadDataCommonChatSessionList(1);
  };
  const loadDataCommonChatSessionList = async (page) => {
    try {
      const token = localStorage.getItem("access_token");
      setIsLoading(true)
      let dataimage = null;
      if(uploadedImage === null)
      {
        dataimage = 'none';}
      else
      {
        dataimage = uploadedImage;}
        //qtype
      const dataToSend = {
        pagesize:15,
        pagenum:page,
      };

      const data = await requestUrl(dataToSend,"get_feynman_point_topic_history_list");
      setUploadedImage(null);
      setTableData(data.data);
      setTotalPagesSession(data.total_pages);
      //setSelectedPTHistoryData(dat_server);
      setIsLoading(false);
    }
    catch (error) {
      setIsLoading(false);
      console.error("There was an error fetching the reply:", error);
    }
  }

  //加载指定主题的对话记录
  const handleLoadChatPT = async () => {
    if (!selectedRowId) {
      showSnackbar(t('Load Record, select a record to load'),'warning');
      return;
    }
    setIsPlaying(false);
    loadSubjectData(1);
    setIsOpenPointTopicHistory(false);
    setSelectedGradeOption(selectedRowData.grade);
    setSelectedSubjectOptionText(selectedRowData.subject);
    
  }

  const handleChangePage = async (event, newPage) => {//0220
    setCurrentPage(newPage);
    loadDataCommonChatSessionList(newPage);
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const year = date.getFullYear().toString().slice(-2); // 获取年份的后两位
    const month = String(date.getMonth() + 1).padStart(2, '0'); // 月份从0开始，所以要+1
    const day = String(date.getDate()).padStart(2, '0');
    const hour = String(date.getHours()).padStart(2, '0');

    return `${year}-${month}-${day}:${hour}`;
  };

  //分析当前
  const handleAnalysePT = async () => {
    const dataToSend = {
      subject: selectedSubjectOptionText,
      grade: selectedGradeOption,
      ptid: selectedRowId,
      pttext: pointTopicText,
    };

    try {
      setIsLoading(true);
      let data = await requestUrl(dataToSend, "analyse_pt_by_id");
      setMarkdownText(data.content);
    }
    catch (error) {
      console.error("There was an error fetching the reply:", error);
      setIsLoading(false);
    }
    setIsLoading(false);
  }

  const inputAreaRef = useRef(null);

  const isMobile = window.innerWidth <= 768;

  const removeItem = (idToRemove) => {
    // 使用filter方法过滤掉id等于1的元素
    const filteredItems = tableData.filter(item => item.id !== idToRemove);

    // 更新状态以反映删除后的数组
    setTableData(filteredItems);
  };

  // 调用函数来删除id为1的元素

  //删除chatsession
  const handleDeleteChatSession = async (sessionid) => {
    try {
      setIsLoading(true);
      const dataToSend = {
        sessionid: sessionid,
      };
      const data1 = await requestUrl(dataToSend, "delete_feynman_chat_session");

      if (data1.status === "success") {
        removeItem(sessionid);

      }
    }
    catch (error) {
      console.error("获取数据时出错:", error);
    }
    finally {
      setIsLoading(false);
    }
  };


  const [isPlaying, setIsPlaying] = useState(false);


  const handlePlayClick = async () => {

    if(pointTopicText.length === 0){
      showSnackbar(t('you must setting point topic'),'warning');
      return;
    }
    setIsPlaying(false);

    await handleSendMessage("begin");
  };


  const centerStyle = {
    position: 'absolute', // 使用绝对定位
    top: '50%', // 垂直居中
    left: '50%', // 水平居中
    transform: 'translate(-50%, -50%)', // 确保准确居中
    zIndex: 9999 // 高z-index以确保在其他内容之上
  };
    const centerStylePlaying = {
      position: 'absolute', // 使用绝对定位
      borderRadius:'10px',
      top: '50%', // 垂直居中
      border: '2px solid yellow', // 添加黄色边框
      left: '50%', // 水平居中
      transform: 'translate(-50%, -50%)', // 确保准确居中
      zIndex: 99999 // 高z-index以确保在其他内容之上
    };


  ///////////////////////////////
  const [isSettingButtonOpen, setIsSettingButtonOpen] = useState(false);
  const [settingDragging, setSettingDragging] = useState(false);

  const handleDrag = (e, data) => {
    if (data.deltaX !== 0 || data.deltaY !== 0) {
      setSettingDragging(true);
    }
  };

  const handleStop = () => {
    setTimeout(() => {
      setSettingDragging(false);
    }, 100);
  };

  const handleSettingClick = (e) => {
   e.stopPropagation(); // 阻止点击事件冒泡到document
   // 如果不是拖动行为，则切换弹出状态
   if (!settingDragging) {
     setIsSettingButtonOpen(!isSettingButtonOpen);
   }
 };

// 添加全局点击事件监听器以关闭容器
useEffect(() => {
 const handleClickOutside = (event) => {
   // 通过ref检查点击是否在容器或按钮外
   if (isSettingButtonOpen && !document.querySelector('[aria-label="settings"]').contains(event.target)) {
     setIsSettingButtonOpen(false);
   }
 };

 // 只有当容器打开时才监听点击事件
 if (isSettingButtonOpen) {
   document.addEventListener('click', handleClickOutside);
 }
 return () => {
   document.removeEventListener('click', handleClickOutside);
 };
}, [isSettingButtonOpen]); // 依赖于isSettingButtonOpen

// 动画和相关样式定义
const blinkAnimation = `
@keyframes blink-animation {
  0% { opacity: 1; }
  50% { opacity: 0; }
  100% { opacity: 1; }
}
`;


const blinkAnimation11111 = `
@keyframes blink-animation {
  0% { box-shadow: 0 0 10px rgba(255, 165, 0, 0.5); }
  50% { box-shadow: 0 0 20px rgba(255, 165, 0, 0.9); }
  100% { box-shadow: 0 0 10px rgba(255, 165, 0, 0.5); }
}
`;



 // 容器A样式
 const containerAStyle = {
  backgroudColor:'#1565c0 !important',
  display: 'flex',
  height: '30px',
  alignItems: 'center',
  justifyContent: isMobile ? 'flex-start' : 'center',
  position: 'relative',
  border: '1px solid #ccc', // 辅助可视化
  padding: isMobile ? '0 10px' : '0', // 为手机视图添加左右内边距
  
};

// 容器B样式，当非移动设备时，无需特殊定位
const containerBStyle = {};

// Right容器样式
const containerRightStyle = {
  position: 'absolute',
  right: '10px',
  display: 'flex',
  gap: '10px', // 设置子控件之间的水平间隔为10px
  justifyContent: 'center', // 让子控件水平居中对齐
  alignItems: 'center', // 使子控件在容器内垂直居中
  
};

// 以下是组件样式
const paperStyle = {
  padding: '20px',
  margin: '10px',
  backgroundColor: '#f5f5f5', // 浅乳白色背景
};

const buttonGridStyle = {
  marginBottom: '20px',
};


const [showPointTopicDetailDiv,setShowPointTopicDetailDiv] = useState(false);
const handleClickPointTopicAddOne = (ptText) => {
  setPointTopicText(ptText);
  
}


const textStyle = {
  fontSize: '24px', // 设置大一些的文字大小
  fontWeight: 'bold', // 加粗
  color: 'yellow', // 黄色文字
  textAlign: 'center', // 文字居中
  marginTop: '16px' // 文字和图标按钮之间的间距
};

const blinkAnimation11 = `@keyframes blink {
  0% { opacity: 1; }
  50% { opacity: 0; }
  100% { opacity: 1; }
}`;




  return (
    <div style={{ display: 'flex', flexDirection: 'column', width: '100%', marginLeft: '10px' }}>

      {/* Main Container background: 'lightblue',*/}
      <div style={{ flex: 1, position: 'relative' }}>
        <Container>
          <div style={containerAStyle}>
            <div style={containerRightStyle}>
                {t('analog point of knowledge')}:{t(pointTopicText)}
              <div style={{ color: 'white'}}>
                {t(selectedSubjectOptionText)}:{selectedGradeOption}{t('grade')}
              </div>
              <DescriptionIcon sx={{ color: 'white', cursor: 'pointer' }} onClick={toggleA} />
              <WebAssetIcon onClick={handleClearRecord} sx={{ color: 'white', cursor: 'pointer' }} /> 
            </div>
          </div>

          <Middle>
            {isDisplayBlankContent && blankPageContentElement}
            <ChatWindow
              messages={messages}
              onSendMessage={handleSendMessage}
              predefinedContent={cuspredefinedContent}
              inputText={inputText}
              setInputText={setInputText}
              subject={selectedSubjectOptionText}
              isLoading={isLoading}
              onReceiveStringFromMessItem={onReceiveStringFromMessItem}
              onImageUpload={handleImageUpload}
              showAudio={true}
              handleAudioRecord={handleAudioRecord}
              ref={inputAreaRef}
              fromModule='feynman'
              isShowBackgroundHtml={isDisplayBlankContent}
            />
          </Middle>

          {isLatexModalVisible &&
            <Overlay>
              <ModalContainer>
                <LatexModule
                  onClose={() => setLatexModalVisible(false)}
                  onConfirm={handleDocumentUpdate}
                  subject={selectedSubjectOptionText}
                  ocrType='subject'
                />
              </ModalContainer>
            </Overlay>
          }
          {isLoading && (
            <LoadingData text={t(loadingText)}/>
          )}

          {isShowAudioRecorder && (
            <Modal
            open={isShowAudioRecorder}
            onClose={onCloseAudioRecord}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            sx={{zIndex:5555}}
          >
              <AudioRecorder onCloseAudioRecord={onCloseAudioRecord} translateText={translateText} setTranslateText={setTranslateText} onCloseOnly={onCloseOnly} />
            </Modal>
          )}

         
          <Dialog open={isOpenPointTopicHistory} onClose={handleClosePTHistory}
            sx={{
              
              '& .MuiPaper-root': {
                width: {
                  xs: '98%', // 手机屏幕上宽度设置为 90%
                  sm: '800px', // 平板和电脑屏幕上最大宽度为 500px
                },
                maxWidth: '100%', // 确保不会超出屏幕
              },
            }}>
              <div style={{border: `1px solid ${get_color_by_name('pop-window-border-color')}`,}}>
              <DialogTitle sx={{ textAlign: 'center', fontSize: '24px',fontFamily:'siyuan',backgroundColor:get_color_by_name('mui-button-bg') }}
                style={{color:get_color_by_name('headline-color')}}
              >
              {t('topics history records')}
              <Button variant="text"  sx={{color:get_color_by_name('small-icon-color')}} onClick={handleGetPTHistory}>
                <Refresh></Refresh>
                {/* {t('load records')} */}
              </Button>
            </DialogTitle>
            <DialogContent style={{backgroundColor:get_color_by_name('mui-button-bg')}}>
              <div>
                {/* <Button variant="contained" color="primary" sx={{ width: '100%', marginBottom: '10px' }} onClick={handleGetPTHistory}>
                  {t('load records')}
                </Button> */}
                <div >
                  <TableContainer component={Paper} >
                    <Table>
                      <TableHead>
                        <TableRow sx={{backgroundColor:get_color_by_name('pop-window-bg')}}>
                          <TableCell sx={{fontFamily:'siyuan',color:get_color_by_name('headline-color')}}>{t('topics')}</TableCell>
                          <TableCell sx={{fontFamily:'siyuan',color:get_color_by_name('headline-color')}}>{t('time')}</TableCell>
                          <TableCell sx={{fontFamily:'siyuan',color:get_color_by_name('headline-color')}}>{t('delete')}</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                      {tableData.map((row, index) => (
                          <TableRow key={row.id} selected={selectedRowId === row.id}
                            onClick={() => {
                              setSelectedRowId(row.id);
                              setSelectedRowTopicText(row.point_topic);
                              setSelectedRowData(row);
                            }}
                            hover
                            style={{ cursor: 'pointer', backgroundColor: selectedRowId === row.id ? get_color_by_name('mui-button-bg') : get_color_by_name('pop-window-bg') }}
                          >
                            <TableCell title={row.point_topic} sx={{fontFamily:'siyuan',color:get_color_by_name('headline-color')}}>
                              {row.point_topic.length > 20 ? row.point_topic.substring(0, 20) + "..." : row.point_topic}
                              </TableCell>
                            <TableCell sx={{fontFamily:'siyuan',color:get_color_by_name('headline-color')}}>{formatDate(row.recordtime)}</TableCell>
                            <TableCell sx={{fontFamily:'siyuan',color:get_color_by_name('headline-color')}}>
                              <IconButton aria-label="delete" sx={{color:get_color_by_name('small-icon-color')}} onClick={() => handleDeleteChatSession(row.id)}>
                                <DeleteIcon />
                              </IconButton>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                    
                  </TableContainer>
                  <Pagination
                    sx={{ backgroundColor:get_color_by_name('mui-textfield-bg'), borderRadius: '10px' }}
                    count={totalPagesSession}
                    page={currentPage}
                    onChange={handleChangePage}
                    color="primary"
                  />
                </div>
              </div>

            </DialogContent>
            <DialogActions sx={{backgroundColor:get_color_by_name('pop-window-bg')}}>
              <CustomButton onClick={handleLoadChatPT} fullWidth sx={{ textTransform: 'none' }}>{t('confirm')}</CustomButton>
              <CustomButton onClick={handleClosePTHistory} fullWidth sx={{ textTransform: 'none', }}>{t('close')}</CustomButton>
            </DialogActions>
            </div>
          </Dialog>
        </Container>
        {/* A Container */}
        {isShowSystemHelp && (
          <SystemHelp modulename="feman" subject="common" onClose={onCloseHelp} />
        )}



        <Modal
          open={showA}
          onClose={toggleA}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          sx={{zIndex:5555}}
        >
        <Paper 
          style={{
            zIndex: 55545,
            display: 'flex',
            flexDirection: 'column', // 由于我们希望内容垂直堆叠，需要设置flex方向
            backgroundColor: get_color_by_name('navigator-bg'),
            position: 'fixed',
            left: '50%',
            top: '50%',
            height:'80%',
            width:'80%',
            transform: 'translate(-50%, -50%)',
            margin: '20px',
            padding: '20px',
            border: `1px solid ${get_color_by_name('pop-window-border-color')}`,
            textAlign:'center',
          }} 
          elevation={3}
      >
          <Typography variant="h2" style={{ color:get_color_by_name('headline-color'), textAlign: 'left', alignItems:'center',textAlign:'center' }}>
            {t('feynman analysis report')} {/* 这里添加了一个标题 */}
          </Typography>
          <br/>
          <ReactMarkdown>{markdownText}</ReactMarkdown>

          <div style={{ 
            display: 'flex', 
            flexDirection: 'column', 
            gap: '20px',
            alignItems: 'center', // 使按钮在水平方向居中
            marginTop: 'auto',    // 将按钮容器推到底部
            marginBottom: '20px'  // 距离底部20px
          }}>
            <CustomButton onClick={handleAnalysePT} width='100%' >
              {t('get report')}
            </CustomButton>
            <CustomButton onClick={toggleA} width='100%' >
              {t('close')}
            </CustomButton>
          </div>
        </Paper>
      </Modal>



        

        <div>
          {isPlaying && (
           <div style={centerStylePlaying}>
           <style>{blinkAnimation11}</style>
           <Grid container direction="column" alignItems="center" justifyContent="center">
             <IconButton onClick={handlePlayClick} sx={{ fontSize: 100, animation: 'blink 1s infinite' }}>
               <PlayCircleIcon sx={{ fontSize: 'inherit' }} />
             </IconButton>
             <Typography variant="body1" style={textStyle} sx={{margin:'10px'}}>
                {t('click begin!')}
             </Typography>
           </Grid>
         </div>
          )}
        </div>
      </div>

      {/**悬浮按钮代码----开始 */}
      <Draggable
          onDrag={handleDrag}
          onStop={handleStop}
        >
      <div style={{
        position: 'absolute',
        zIndex: 99999,
        right: '100px', // 距离屏幕右侧100px
        top: '30%', // 父容器的顶部距离视口顶部的距离为视口高度的一半
        transform: 'translateY(-50%)' // 向上移动元素高度的一半，实现垂直居中
      }}>        
        <style>
          {blinkAnimation}
        </style>
        <Box
          sx={{
            display: 'flex', // 启用 flex 布局
            flexDirection: 'column', // 子项水平排列
            alignItems: 'center', // 垂直居中对齐子项
            gap: 2, // 子项之间的间隔
          }}
        >
        <IconButton
          aria-label="settings"
          onClick={handleSettingClick}
          onTouchEnd={handleSettingClick} // 添加触摸事件处理
          sx={{
            '& .MuiSvgIcon-root': {
              color: get_color_by_name('small-icon-color'),
              animation: 'blink-animation 1s infinite'
            }
          }}
        >
          <SettingsIcon fontSize="large" />
        </IconButton>

        <IconButton
          aria-label="camera"
          onClick={() => setShowPointTopicDetailDiv(true)}
          onTouchEnd={() => setShowPointTopicDetailDiv(true)} // 添加触摸事件处理
          sx={{
            '& .MuiSvgIcon-root': {
              color: get_color_by_name('small-icon-color'),
              animation: 'blink-animation 1s infinite'
            }
          }}
        >
          <AddCardIcon fontSize="large" />
        </IconButton>

        </Box>

        {/* 条件渲染自定义的容器或组件 */}
        {isSettingButtonOpen && (
          <div
            style={{
              position: 'absolute',
              top: '50px', // 根据需要调整
              right: '0',
              zIndex: 100000, // 确保容器位于按钮之上
              width:'400px',
              // 添加其他所需的样式
            }}
            onClick={(e) => e.stopPropagation()} // 防止容器内的点击事件冒泡
          >
        
            <Paper style={{
              padding: '20px',
              margin: '10px',
              backgroundColor:get_color_by_name('pop-window-bg'),
              border: `1px solid ${get_color_by_name('pop-window-border-color')}`,
            }} elevation={3}>
              <Grid container spacing={3}>

                <Grid item xs={6} style={buttonGridStyle}>
                <CustomButton
                  onClick={handleGetPointTopicHistory}
                  onTouchEnd={(e) => {
                    e.preventDefault(); // 阻止默认行为和冒泡，根据需要调整
                    handleGetPointTopicHistory();
                  }}
                  size="medium"
                  startIcon={<AddCardIcon />}
                  sx={{width:'170px'}}
                >
                  {t('history')}
                </CustomButton>

                
                </Grid>

                <Grid item xs={6} style={buttonGridStyle}>
                <CustomButton
                  onClick={handleSystemHelp}
                  onTouchEnd={(e) => {
                    e.preventDefault(); // 阻止默认行为和冒泡，根据需要调整
                    handleSystemHelp();
                  }}
                  size="medium"
                  startIcon={<QuizIcon />}
                  sx={{width:'170px'}}
                >
                  {t('functionality help')}
                </CustomButton>

                

                </Grid>


                <Grid container spacing={1} direction="column" alignItems="center" sx={{marginTop:'0px'}}>
                  <Grid item>
                    <h2><span style={{color:get_color_by_name('headline-color')}}>{t('grade')}</span></h2>
                  </Grid>
                  <Grid item container justifyContent="center" spacing={1}>
                  {gradeOptions.map((option) => (
                      <Grid item key={option.id}>
                        <Button
                          sx={{
                            fontFamily:'siyuan',
                            border: `1px solid ${get_color_by_name('pop-window-border-color')}`,
                            backgroundColor: selectedGradeOption === option.id ? get_color_by_name('mul-button-bg-selected') : get_color_by_name('mul-button-bg'),
                            color: selectedGradeOption === option.id ? get_color_by_name('mul-button-color-selected') : get_color_by_name('mul-button-color'),
                            '&:hover': {
                              backgroundColor: selectedGradeOption === option.id ? 'darkgreen' : 'lightgrey',
                            },
                          }}
                          onClick={() => handleMenuItemGradeClick(option.id)}
                          onTouchEnd={(e) => {
                            e.preventDefault(); // 阻止默认行为和冒泡，根据需要调整
                            handleMenuItemGradeClick(option.id);
                          }}
                        >
                          {option.text}
                        </Button>
                      </Grid>
                    ))}
                  </Grid>
                  <Grid item>
                    <h2><span style={{color:get_color_by_name('headline-color')}}>{t('subject')}</span></h2>
                  </Grid>
                  <Grid item container justifyContent="center" spacing={1}>
                  {subjectOptions.map((option) => (
                      <Grid item key={option.id}>
                        <Button
                          sx={{
                            fontFamily:'siyuan',
                            border: `1px solid ${get_color_by_name('pop-window-border-color')}`,
                            backgroundColor: selectedSubjectOptionText === option.id ? get_color_by_name('mul-button-bg-selected') : get_color_by_name('mul-button-bg'),
                            color: selectedSubjectOptionText === option.id ? get_color_by_name('mul-button-color-selected') : get_color_by_name('mul-button-color'),
                            '&:hover': {
                              backgroundColor: selectedSubjectOptionText === option.id ? 'darkgreen' : 'lightgrey',
                            },
                          }}
                          onClick={() => onListItemClick(option.id)}
                          onTouchEnd={(e) => {
                            e.preventDefault(); // 阻止默认行为和冒泡，根据需要调整
                            onListItemClick(option.id);
                          }}
                        >
                          {option.text}
                        </Button>
                      </Grid>
                    ))}
                  </Grid>
                </Grid>


              
                  
                <Grid item xs={6} style={buttonGridStyle}>

                <CustomButton
                  onClick={() => setShowPointTopicDetailDiv(true)}
                  onTouchEnd={(e) => {
                    e.preventDefault(); // 阻止默认行为和冒泡，根据需要调整
                    setShowPointTopicDetailDiv(true);
                  }}
                  size="medium"
                  startIcon={<QuizIcon />}
                  sx={{width:'170px'}}
                >
                   {t('add point topic')}
                </CustomButton>
                  </Grid>
       
                  <Grid item xs={6} style={buttonGridStyle}>

                    <CustomButton
                      onClick={handleAddPT}
                      onTouchEnd={(e) => {
                        e.preventDefault(); // 阻止默认行为和冒泡，根据需要调整
                        handleAddPT();
                      }}
                        size="medium"
                        startIcon={<QuizIcon />}
                        sx={{width:'170px'}}
                      >
                        {t('confirm')}
                    </CustomButton>
                  </Grid>
                
              </Grid>
            </Paper>

            {/* 移除了关闭按钮，因为点击图标按钮已经能够实现关闭功能 */}
          </div>
        )}
      </div>
    </Draggable>
      {/**悬浮按钮代码----结束 */}
      {showPointTopicDetailDiv && (
        <Paper elevation={3} style={{ ...centerStyle, padding: '16px' ,backgroundColor:get_color_by_name('navigator-bg')}}>
        <div style={{ width: '100%', marginTop: '16px'}}>
          {Object.keys(pointTopicList).map((category, index) => (
            <Accordion key={index} style={{ marginBottom: '16px' }}>
              <AccordionSummary style={{ backgroundColor:get_color_by_name('mui-button-bg'), color: get_color_by_name('headline-color') }} expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                <Typography style={{ backgroundColor:get_color_by_name('mui-button-bg'), color: get_color_by_name('headline-color') }}>{category}</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <div>
                  {pointTopicList[category].map((topic, idx) => (
                    <CustomButton
                      key={idx}
                      
                      onClick={() => handleClickPointTopicAddOne(topic)}
                    >
                      {topic}
                    </CustomButton>
                  ))}
                </div>
              </AccordionDetails>
            </Accordion>
          ))}
        </div>
          <TextField
            label="Multiline Textbox"
            multiline
            rows={2}
            variant="outlined"
            value={pointTopicText}
            onChange={handleChangePointTopicTextBox}
            sx={{
              width:'100%',
              backgroundColor: get_color_by_name('mui-textfield-bg'),
              // Targeting the input element for styling
              '& .MuiInputBase-input': {
                color: get_color_by_name('headline-color'), // Change text color
              },
            }}
          />
       <div style={{ marginTop: '16px', display: 'flex', justifyContent: 'flex-end', paddingRight: '20px' }}>
          <CustomButton  onClick={() => setShowPointTopicDetailDiv(false)}>
            {t('close')}
          </CustomButton>
          <CustomButton
            onClick={handleAddPT}
            size='medium'
          >
            {t('confirm')}
          </CustomButton>
        </div>
      </Paper>
      )}



<Box sx={{ position: 'fixed', bottom: 0, right: 0, zIndex: 8888 }}>

        <Button
          onClick={handleClearRecord}
            onTouchEnd={(e) => {
              e.preventDefault(); // 阻止默认行为和冒泡，根据需要调整
              handleClearRecord();
            }}
            sx={{
              position: 'absolute', 
              bottom: '33vh', 
              right: 60,
              fontFamily:'siyuan',
              border: `1px solid ${get_color_by_name('pop-window-border-color')}`,
              backgroundColor: get_color_by_name('mul-button-bg'),
              color: get_color_by_name('mul-button-color'),
              
            }}
          >
            开始新对话
          </Button>
      </Box>

    </div>
  );
};

export default MainStudyFeynman;
