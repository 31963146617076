import React, { useState ,useEffect} from 'react';
import { Stepper, Step, StepLabel, Button, Typography, RadioGroup, FormControlLabel, Radio ,Paper} from '@mui/material';
import './StudentPollComponent.css';
import { useTranslation } from 'react-i18next';
import { requestUrl } from '../../requestUrl';
import CustomButton from '../../components/CustomButton';
import ReactMarkdown from 'react-markdown';
import LoadingData from '../dataloading';



const StudentPollComponent = () => {
    const { t } = useTranslation();

  const [activeStep, setActiveStep] = useState(0);

  const [isLoading, setIsLoading] = useState(false);
  const [loadingText,setLoadingText] = useState('loading');

  const [pollResult, setPollResult] = useState('# Here is a markdown heading\n\nAnd some more text\n\n## songlixun'); // 用于存储后端返回的结果
  const [showResult, setShowResult] = useState(false); // 控制是否显示结果和按钮
  const [showStepper, setShowStepper] = useState(true);
  


  const [questions,setQuestions] = useState([]);
  const [answers, setAnswers] = useState(Array(questions.length).fill(''));
  const [teacherStyle,setTeacherStyle] = useState({});

  useEffect(() => {
    
    const fetchData = async () => {
        try {
            setIsLoading(true);
            setLoadingText("loading");
            const dataToSend = {
                id:0,
            };
            const data = await requestUrl(dataToSend, "get_student_poll_QA",t);
            if(data.status === "success"){
              console.log(data.data);
              setQuestions(data.data);
              setTeacherStyle(data.teacherStyle);
            }
            
            
          } catch (error) {
              console.error("获取数据时出错:", error);
          } finally {
            setIsLoading(false);
          }
    };

    fetchData();
  }, []);



  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };


  const handleChange = (event) => {
    const newAnswers = [...answers];
    newAnswers[activeStep] = event.target.value;
    setAnswers(newAnswers);
  
    // Delay the next question by 1 second to allow users to see their selected option
    if (activeStep < questions.length - 1) {
      setTimeout(() => {
        handleNext();
      }, 1000); // 1000 milliseconds = 1 second
    }
  };
  

  const handleStep = (step) => () => {
    setActiveStep(step);
  };
  


  const requestStudentPoll = async (questionAnswers) => {

    try {
        setIsLoading(true);
        
        const dataToSend = {
            pollResponses: questionAnswers
          };
        setIsLoading(true);

        const data = await requestUrl(dataToSend,"set_sutdent_poll",t);
        setPollResult(data.data);
        //setSelectedPTHistoryData(dat_server);
        setIsLoading(false);
        setShowResult(true); // 显示结果和按钮
        setShowStepper(false);
      }
      catch (error) {
        setIsLoading(false);
        console.error("There was an error fetching the reply:", error);
      }
      setIsLoading(false);
  }

  const handleFinish = async () => {
    console.log("song");
    // 创建一个新的数组来存储问题和对应的答案，分数，以及描述
    const questionAnswers = questions.map((question, index) => ({
      question: question.question,
      answer: answers[index],
      score: question.options.find(option => option.option === answers[index]).score,
      description: question.description, // 新增了 description 字段
      potentiallyScore:question.potentiallyScore,
      strongScore:question.strongScore
    }));
    
    console.log('Question and Answers:', questionAnswers);
  
    // 你可以在这里执行其他操作，例如发送这个数据到服务器
  
    // 如果你想检查是否有未回答的问题，可以继续使用你之前的逻辑
    const emptyStringIndices = answers.map((item, index) => item === '' ? index : null).filter(index => index !== null);
    console.log('Indices of Unanswered Questions:', emptyStringIndices);
    if (emptyStringIndices.length > 0) {
      // 处理未回答的问题
      console.log('Please answer all questions.');
    } else {
      // 所有问题都已回答
      console.log('All questions answered.');
      console.log(JSON.stringify(questionAnswers));
      requestStudentPoll(questionAnswers);
      // 这里你可以将 questionAnswers 发送到后端
      // 例如，使用 fetch 或者其他适合的方法
      // await fetch('/api/submit', {
      //   method: 'POST',
      //   headers: {
      //     'Content-Type': 'application/json',
      //   },
      //   body: JSON.stringify(questionAnswers),
      // });
    }
  };
  

  const handleRestartPoll = async () => {
    setActiveStep(0);
    setShowResult(false);
    setShowStepper(true);

  }
  
  return (
    <Paper style={{
      zIndex: 99999,
      borderRadius: '10px',
      width: '600px',
      height: 'auto', // 根据内容自动调整高度
      backgroundColor: '#f9f9f9', // 使用更柔和的白色系背景
      boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', // 添加轻微的阴影
      padding: '20px',
      position: 'fixed',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
    }}>
      {
        showStepper && (
          <>
            <Stepper activeStep={activeStep} alternativeLabel style={{ marginBottom: '20px' }}>
              {questions.map((step, index) => (
                <Step key={index} onClick={() => handleStep(index)} style={{ cursor: 'pointer' }}>
                  <StepLabel>{`${index + 1}`}</StepLabel>
                </Step>
              ))}
            </Stepper>
  
            {activeStep === questions.length ? (
              <div style={{ textAlign: 'center', marginTop: '20px' }}>
                <Typography>All steps completed - you&apos;re finished</Typography>
                <CustomButton onClick={() => setActiveStep(0)} style={{ marginTop: '10px' }}>Reset</CustomButton>
              </div>
            ) : (
              <div>
                <Typography color="textPrimary" style={{ marginBottom: '20px' }}>{questions[activeStep].question}</Typography>
                <RadioGroup className="radioGroup" value={answers[activeStep]} onChange={(event) => handleChange(event, activeStep)}>
                  {questions[activeStep].options.map((option, index) => (
                    <FormControlLabel
                      key={index}
                      value={option.option}
                      control={<Radio />}
                      label={option.option}
                      className="radioLabel"
                    />
                  ))}
                </RadioGroup>
                <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '20px' }}>
                  <CustomButton disabled={activeStep === 0} onClick={handleBack}>Back</CustomButton>
                  <CustomButton onClick={activeStep === questions.length - 1 ? handleFinish : handleNext}>
                    {activeStep === questions.length - 1 ? 'Finish' : 'Next'}
                  </CustomButton>
                </div>
              </div>
            )}
          </>
        )
      }
  
      {isLoading && (
        <LoadingData text={t(loadingText)} />
      )}
  
      {showResult && (
        <div style={{ borderRadius: '10px', marginTop: '20px', backgroundColor: '#e8eaf6', padding: '10px' }}>
          <Typography variant="body1" style={{ color: '#333' }}>
            <ReactMarkdown>
              {pollResult}
            </ReactMarkdown>
          </Typography>
          <CustomButton onClick={handleRestartPoll} style={{ marginTop: '10px' }}>Restart Poll</CustomButton>
        </div>
      )}
    </Paper>
  );
};

export default StudentPollComponent;
