import React, { useState ,useEffect} from 'react';
import { Table, TableBody, TableCell, TableContainer, TableRow, Paper, Button, TextField, FormControl, InputLabel, Select, MenuItem,Box } from '@mui/material';
import { Grid, Typography } from '@mui/material';

const UserAddEdit = ({isAddEdit, initialUserData, onSave, onClose }) => {
  //const [userData, setUserData] = useState(initialUserData);
 
  const [isAddOrEdit,setIsAddOrEdit] = useState();
  const defaultUserData = {
    id: 0,
    username: '',
    phone_number: '',
    expirate_time: '',
    rolename: 'user',
    billid:1,
    // ... 其他属性的默认值 ...
  };
  const [userData, setUserData] = useState(isAddEdit === 'edit' && initialUserData ? initialUserData : defaultUserData);

  useEffect(() => {
    console.log("nulllll");
    if (isAddEdit === "edit") {
        // 如果是编辑模式，则使用传入的 pbRecord 来初始化状态
        setIsAddOrEdit('edit');
        setUserData(initialUserData);
        
    } else {
        // 如果是新建模式，则初始化为空对象或默认值
        setIsAddOrEdit('add');
        setUserData({
            id:0,
            username: '',
            phone_number:'',
            expirate_time:'',
            rolename:'user',
            billid:1,
            // 其他属性的默认值
        });
    }
}, [initialUserData]);



  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setUserData({ ...userData, [name]: value });
  };

  const handleSubmit = () => {
    //onSave(userData);
  };

  return (
    <div style={{width:'90%'}}>
      <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginBottom: 2, marginTop: '20px',backgroundColor:'white' }}>
        <Button variant="outlined" color="secondary" onClick={onClose}>
          关闭当前页面
        </Button>
        <Button variant="contained" color="primary" onClick={handleSubmit}>
          保存
        </Button>
        
      </Box>

      
      <Box sx={{ flexGrow: 1, padding: 2 ,backgroundColor:'white',color:'black'}}>
      <Typography variant="h5" gutterBottom>
        用户详细信息
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            label="用户名"
            name="username"
            value={userData.username}
            onChange={handleInputChange}
            sx={{ input: { color: 'black' } }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            label="手机号"
            name="phone_number"
            value={userData.phone_number}
            onChange={handleInputChange}
            sx={{ input: { color: 'black' } }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            label="密码"
            name="password"
            type="password"
            value={userData.password}
            onChange={handleInputChange}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            label="创建时间"
            name="created_at"
            value={userData.created_at}
            InputProps={{
              readOnly: true,
            }}
            sx={{ input: { color: 'black' } }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            label="更新时间"
            name="updated_at"
            value={userData.updated_at}
            InputProps={{
              readOnly: true,
            }}
            sx={{ input: { color: 'black' } }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            label="等级"
            name="grade"
            type="number"
            value={userData.grade}
            onChange={handleInputChange}
            sx={{ input: { color: 'black' } }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            label="软件版本"
            name="sversion"
            value={userData.sversion}
            onChange={handleInputChange}
            sx={{ input: { color: 'black' } }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            label="最后学科"
            name="last_subject"
            value={userData.last_subject}
            onChange={handleInputChange}
            sx={{ input: { color: 'black' } }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            label="语言"
            name="language"
            value={userData.language}
            onChange={handleInputChange}
            sx={{ input: { color: 'black' } }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            label="状态"
            name="status"
            type="number"
            value={userData.status}
            onChange={handleInputChange}
            sx={{ input: { color: 'black' } }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            label="到期时间"
            name="expirate_time"
            type="datetime-local"
            value={userData.expirate_time}
            onChange={handleInputChange}
            InputLabelProps={{ shrink: true }}
            sx={{ input: { color: 'black' } }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormControl fullWidth>
            <InputLabel>角色名称</InputLabel>
            <Select
              name="rolename"
              value={userData.rolename}
              onChange={handleInputChange}
            >
              <MenuItem value="user">用户</MenuItem>
              <MenuItem value="admin">管理员</MenuItem>
              <MenuItem value="editor">编辑</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        {/* 这里补充其他字段 */}
        {/* 根据需要添加更多字段 */}
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            label="家庭负责人密码"
            name="familyhead_password"
            type="password"
            value={userData.familyhead_password}
            onChange={handleInputChange}
            sx={{ input: { color: 'black' } }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            label="学生密码"
            name="student_password"
            type="password"
            value={userData.student_password}
            onChange={handleInputChange}
            sx={{ input: { color: 'black' } }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            label="计费规则ID"
            name="billing_rule_id"
            type="number"
            value={userData.billing_rule_id}
            onChange={handleInputChange}
            sx={{ input: { color: 'black' } }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            label="声音名称"
            name="voice_name"
            value={userData.voice_name}
            onChange={handleInputChange}
            sx={{ input: { color: 'black' } }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            label="教学策略"
            name="teaching_strategy"
            value={userData.teaching_strategy}
            onChange={handleInputChange}
            sx={{ input: { color: 'black' } }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            label="教师风格"
            name="teacher_style"
            value={userData.teacher_style}
            onChange={handleInputChange}
            sx={{ input: { color: 'black' } }}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            label="个人兴趣"
            name="personal_interest"
            value={userData.personal_interest}
            onChange={handleInputChange}
            sx={{ input: { color: 'black' } }}
          />
        </Grid>
      </Grid>
    </Box>
    </div>
  );
};

export default UserAddEdit;
