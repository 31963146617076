import React, { useState,useEffect,useMemo,useRef } from 'react';

import { requestUrl,requestUrlWithOutParameter } from '../requestUrl';
import { List, ListItem,InputAdornment } from '@mui/material';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import LoadingData from '../education/dataloading';
import { useSnackbar } from '../SnackbarProvider';
import { Container,Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Button, IconButton, Paper,Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import Select from '@mui/material/Select';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import OutlinedInput from '@mui/material/OutlinedInput';
import MenuItem from '@mui/material/MenuItem';
import CustomButton from '../components/CustomButton';

const BgHtmlManage = () => {

      // Inline styles
      const styles = {
        listContainer: {
            maxHeight: 'calc(100vh - 100px)', // Adjust the 100px based on your header/footer size
            overflowY: 'auto' // Enable scrolling
        },
        container: {
            display: 'flex',
            flexDirection: 'row',
            width: '100vw',  // 100% of viewport width
            height: '100vh', // 100% of viewport height
            overflow: 'hidden' // Prevents scrollbars if content overflows
        },
        menu: {
            width: '300px',
            height: '100%',   // Full height
            borderRight: '1px solid #ddd',
            padding: '10px',
            overflowY: 'auto' // Allows scrolling for long menus
        },
        content: {
            flex: 1,
            height: '100%',  // Full height
            padding: '10px',
            overflowY: 'auto' 
        }
    };

    const showSnackbar = useSnackbar();
    const { t } = useTranslation();
    const [bgHtmlList, setBgHtmlList] = useState([]);
    const [bgHtmlLangList,setBgHtmlLangList] = useState([]);

    const [selectedSubject, setSelectedSubject] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [loadingText,setLoadingText] = useState(false);


    const names = ['alloy', 'echo', 'fable', 'onyx', 'nova', 'shimmer'];
    const [selectedVoiceName, setSelectedVoiceName] = useState('alloy');
    const audioRef = useRef(new Audio());

    useEffect(() => {
        const fetchData = async () => {
            try {
                setIsLoading(true);
                setLoadingText("loading");
                const dataToSend = {
                    id:1,
                };
                const data = await requestUrl(dataToSend, "get_bg_html_list_a");
                
                if(data.status === "success"){
                    setBgHtmlList(data.data);
                }
              } 
              catch (error) {
                  console.error("获取数据时出错:", error);
              }
              finally {
                setIsLoading(false);
              }
        };

        fetchData();
    }, []);

    const [selectedBgHtmlMain,setSelectedBgHtmlMain] = useState({});

    const handleMenuClick = async (bgHtml) => {
        setSelectedBgHtmlMain(bgHtml);
        console.log(JSON.stringify(bgHtml));
        try {
            setIsLoading(true);
            setLoadingText("loading");
            let bname = "";
            const parts = bgHtml.bg_src.split('/');
            bname = parts[1];
            console.log("bname:" + bname);
            const dataToSend = {
                bgHtmlId:bgHtml.id,
                bgHtmlName:bname,
              };
            const data = await requestUrl(dataToSend,"get_bg_html_all_lang");
            setBgHtmlLangList(data.data);
            
            
          } catch (error) {
            console.error("获取数据时出错:", error);
            setIsLoading(false);
          }
          setIsLoading(false);

    };

    const handlePromptChange = (id, field, value) => {
        setBgHtmlList(bgHtmlList.map(
            prompt => prompt.id === id ? { ...prompt, [field]: value } : prompt
        ));
    };
 

    const allSubjects = ['mathematics','chinese','english','physics','chemistry','biology','geography'];

    const handleLnagCodeChange = (index, newValue) => {
        // 创建一个新的 pictureBookDetail 数组副本
        const tempList = [...bgHtmlLangList];
        // 更新相应索引的行的 order 字段
        tempList[index] = { ...tempList[index], lang_code: newValue };
        // 更新状态
        setBgHtmlLangList(tempList);
    };

    const handleTextChange = (index, field, newValue) => {
        // 创建一个新的 pictureBookDetail 数组副本
        const tempList = [...bgHtmlLangList];
        // 更新相应索引的行的字段
        tempList[index] = { ...tempList[index], [field]: newValue };
        // 更新状态
        setBgHtmlLangList(tempList);
    };


    const handleSavePage = async (row) => {
        let isaddEdit = "";
        if(row.id === 0){
            isaddEdit = "add";
        }
        else{
            isaddEdit = "edit";
        }

        let temp_pdid = row.id;

        try {
            setIsLoading(true);
              setLoadingText("loading");
              const dataToSend = {
                isAddEdit:isaddEdit ,
                id:row.id,
                bg_html_id:selectedBgHtmlMain.id,
                lang_code:row.lang_code,
                title:row.title,
                content:row.content,
              };

              console.log("data:" + JSON.stringify(dataToSend));
              const data = await requestUrl(dataToSend, "update_bg_html_lang");
              
              if(data.status === "success"){
                if(isaddEdit === "add"){  //如果是新增，返回值为新增的dbid
                    temp_pdid = data.data;
                }
              }

              setIsLoading(false);
              showSnackbar('update success','success');
          } catch (error) {
              console.error("获取数据时出错:", error);
              setIsLoading(false);
          } finally {
            setIsLoading(false);
          }




        // 查找当前行在数组中的索引
        const rowIndex = bgHtmlLangList.findIndex(r => r === row);
    
        if (rowIndex !== -1) {
            // 创建一个新的 pictureBookDetail 数组副本
            const tempList = [...bgHtmlLangList];
            // 更新当前行的 row.id
            tempList[rowIndex] = { ...tempList[rowIndex], id: temp_pdid };
            // 更新状态
            setBgHtmlLangList(tempList);
        }
    };

    const handleTranscribeClick = async (index,row) => {
        let newAudioUrl = "";//"http://localhost:3000/picturebook/1/bg.mp3"
        if(selectedVoiceName === ""){
            
            showSnackbar('warning', t('selecting a sound name'));
            return;
        }
        try {
            let bname = "";
            const parts = selectedBgHtmlMain.bg_src.split('/');
            bname = parts[1];


            setIsLoading(true);
            setLoadingText("loading");
                const dataToSend = {
                id:row.id,
                content:row.content,
                voiceName:"zh-CN-XiaoyiNeural",
                bg_html_name:bname,
                lang_code:row.lang_code,

            };
              const data = await requestUrl(dataToSend, "update_bg_html_lang_audio");
              
              if(data.status === "success"){
                newAudioUrl = data.data;
                handleTextChange(index,"mp3_path",newAudioUrl);
                showSnackbar('transcribe success.','success');
                
              }
          } catch (error) {
              console.error("get data error:", error);
              showSnackbar('transcribe failure:' + error,'error');
              
          } finally {
            setIsLoading(false);
          }

        
          {/* 
        // 创建一个新的 pictureBookDetail 数组副本
        const tempList = [...pictureBookDetail];
        // 更新相应索引的行的音频 URL
        if(lang_type === "local"){
            tempList[index] = { ...tempList[index], content_local_lang_mp3: newAudioUrl };
        }
        if(lang_type === "en"){
            tempList[index] = { ...tempList[index], content_en_mp3: newAudioUrl };
        }
        
        // 更新状态
        setPictureBookDetail(tempList);

        */}
    };


    const handleAddRow = () => {
        // 检查是否有现有行
        const lastRowIndex = bgHtmlLangList.length - 1;
        const lastRow = bgHtmlLangList[lastRowIndex];
       
        // 创建一个新的行对象
        const newRow = {
            id: 0,
            bg_html_id: 1232,
            lang_code:'zh',
            title:'',
            content:'',
            // 添加其他必要的默认值
        };
    
        // 添加新行到 pictureBookDetail
        setBgHtmlLangList([...bgHtmlLangList, newRow]);
    };



    return (
        <div style={styles.container}>
            
            <div style={styles.menu}>
                <List>
                    {bgHtmlList.map(bgHtml => (
                        <React.Fragment>
                        <ListItemButton onClick={() => handleMenuClick(bgHtml)} sx={{ color: 'white' }}>
                            <ListItemText primary={<span style={{ color: 'white' }}>{bgHtml.bg_title}</span>} />
                        </ListItemButton>
                        </React.Fragment>
                    ))}
                </List>
           
            </div>
            
            <div style={{justifyContent:'flex-start',color:'black', alignItems:'flex-start' ,textAlign:'left',width:'100%',backgroundColor:'white'}}>
                    
                <Container> {/* 使用 Container 限制最大宽度 */}
                <List style={styles.listContainer}>
                
                    
                   <TableContainer component={Paper}>
                   <Table aria-label="表格 B">
                       {/* 表头 */}
                        <TableHead>
                            <TableRow>
                                <TableCell>{t('id')}</TableCell>
                                <TableCell>{t('language code')}</TableCell>
                                <TableCell>{t('title')}</TableCell>
                                <TableCell>{t('content')}</TableCell>
                                <TableCell>{t('zhuanlu')}</TableCell>
                                <TableCell>{t('test voice')}</TableCell>
                                <TableCell>{t('save')}</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                        {bgHtmlLangList.map((row, index) => (
                                <TableRow key={index}>
                                    <TableCell>{t('row')} {index + 1}</TableCell>
                                    <TableCell>
                                        <Select
                                            value={row.lang_code}
                                            onChange={(e) => handleLnagCodeChange(index, e.target.value)}
                                            input={<OutlinedInput />}
                                            inputProps={{ 'aria-label': 'Without label' }}
                                        >
                                            {["zh", "en"].map((langCode) => (
                                                <MenuItem key={langCode} value={langCode}>
                                                {langCode.toUpperCase()}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </TableCell>
                        
                                    <TableCell>
                                        <TextField
                                            style={{ width: '200px' }} // 设置宽度为200px
                                            value={row.title}
                                            variant="outlined"
                                            size="small"
                                            multiline
                                            rows={3}
                                            fullWidth
                                            onChange={(e) => handleTextChange(index, 'title', e.target.value)}
                                        />
                                    </TableCell>

                                    <TableCell>
                                        <TextField
                                            style={{ width: '200px' }} // 设置宽度为200px
                                            value={row.content}
                                            variant="outlined"
                                            size="small"
                                            multiline
                                            rows={3}
                                            fullWidth
                                            onChange={(e) => handleTextChange(index, 'content', e.target.value)}
                                        />
                                    </TableCell>


                                    <TableCell>
                                    <CustomButton 
                                            onClick={() => handleTranscribeClick(index,row)}
                                            disabled={row.id === 0}  
                                        >{t('transcribe')}
                                            </CustomButton>
                                    </TableCell>
                                    <TableCell>
                                        <audio src={row.mp3_path} controls />
                                    </TableCell>
                                   
                                    <TableCell>
                                        <Button
                                            onClick={() => handleSavePage(row)}
                                            variant="contained"
                                            component="span"
                                        >
                                            {t('save')}
                                        </Button>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
   
                   </Table>
               </TableContainer>
               <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginTop: '20px' }}>
                   <CustomButton onClick={handleAddRow}>
                       {t('add row')}
                   </CustomButton>
               </Box>
             
                </List>
                </Container>
            </div>

            {isLoading && (
                <LoadingData text={t(loadingText)}/>
            )}
        </div>
    );
};

export default BgHtmlManage;
