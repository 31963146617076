import React, { useState,useRef,useEffect,forwardRef ,useImperativeHandle} from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import MicIcon from '@mui/icons-material/Mic';
import ArticleIcon from '@mui/icons-material/Article';
import {Modal,Paper ,Box} from '@mui/material';
import ZoomOutMapIcon from '@mui/icons-material/ZoomOutMap';
import SendIcon from '@mui/icons-material/Send';
import Button from '@mui/material/Button';
import CustomButton from '../../components/CustomButton';
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { Popover } from '@mui/material';
import '../../fonts.css';
import { getColorDark,getColorLight,getTheme } from '../../ColorManager';

const InputWrapper = styled.div`
  flex-grow: 1;
  position: relative;
`;

const MenuItem = styled.div`
  cursor: pointer;
  padding: 5px;

  &:hover {
    background-color: #f0f0f0;
  }
`;

const ImageContainer = styled.div`
  position: absolute; // 使用绝对定位
  left: 10px;  // 与InputWrapper的左边界保持一定的间距
  top: 50%;  
  transform: translateY(-50%);  // 垂直居中
  z-index: 5; // 使其处于其他元素之上
`;

const Thumbnail = styled.img`
  position: absolute;
  left: 10px;  // 与InputWrapper的左边界保持一定的间距
  top: 50%;
  width: 40px;
  height: 40px;
  transform: translateY(-50%);  // 垂直居中
`;

const DeleteIcon = styled(HighlightOffIcon)`
  position: absolute;
  top: 0;
  left: 35px; // 与Thumbnail的宽度近似，以使其位于右上角
  cursor: pointer;
  background-color: white;
  border-radius: 50%;
  z-index: 10;
`;

const ContentStyle = styled.div`
  max-height: 400px;  // 设置最大高度
  overflow-y: auto;  // 添加垂直滚动条
`;

const InputArea = forwardRef(({
  onSendMessage, 
  predefinedContent, 
  inputText, 
  setInputText,
  isLoading,
  onImageUpload,
  showAudio,
  handleAudioRecord,
  showAddImage,
  subject,
  questionTypeId,
  showWritingConfig,
  isShowWritingConfigWindow
}, ref) => {

  const [showContentSelector, setShowContentSelector] = useState(false);
  const fileInputRef = useRef(null);
  const [selectedImage, setSelectedImage] = useState(null);
  const [inputValue, setInputValue] = useState('');
  const { t } = useTranslation();
  const inputRef = useRef(null);  // 创建 ref 对象

  const [colorManager, setColorManager] = useState(getColorDark());


    //获取颜色设置，系统加载时设置
    useEffect(() => {

      const themeName = localStorage.getItem('theme');//此处存储的是数字1-12，而不是汉字，
      if (themeName) {
        
        if(themeName === "dark"){
          setColorManager(getColorDark());
        }
  
        
        if(themeName === "light"){
          setColorManager(getColorLight());
        }
      }
      else{
        let aa = getColorDark();
        setColorManager(aa);
      }
  
      
    }, []);

    const get_color_by_name = (name) => {

      const item = colorManager.find(item => item.container === name);
      return item.value;
    }

  useImperativeHandle(ref, () => ({
    setInputValue: (value) => {
     
      setInputValue(value);
    },
    getInputValue: () => {
      return inputValue;
    }
  }));


  const handleInputChange = (e) => {
    
    if(subject === "chinese" || subject === "english"){
      if(questionTypeId === 6 && isShowWritingConfigWindow === true){
        showWritingConfig();
        return;
      }
    }
    setInputValue(e.target.value);
    //setInputText(e.target.value);
  };

  const onSetInputValue = (message) => {
    setInputValue(message);
  };

  const handleSendClick = () => {
    
    if(inputValue === null){ //界面没有任何输入
      
    }
    else {
      onSendMessage(inputValue);
      setInputValue('');
      setInputText('');
    }
  };

  const handlePredefinedContentClick = (content) => { 
    setInputText(content);
    setInputValue(content);
    setShowContentSelector(false);

    // 设置光标位置,需要稍微延迟设置光标的代码，让 React 先更新 DOM

    setTimeout(() => {
      if (inputRef.current) {
        inputRef.current.focus();
        inputRef.current.setSelectionRange(content.length, content.length);  // 设置光标位置
      }
    }, 0);
  };


  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file && (file.type === 'image/png' || file.type === 'image/jpeg')) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setSelectedImage(reader.result);
        onImageUpload(reader.result); // 传递图片数据给MainStudy组件
      }
      reader.readAsDataURL(file);
    } else {
      console.log('请选择一个有效的图片文件(png或jpg)。');
    }
    e.target.value = null;  // 重置文件输入的值
  };
  
  const handleDeleteImage = () => {
    setSelectedImage(null);
    fileInputRef.current.value = null;  // 重置文件输入的值
    onImageUpload(null); // 通知MainStudy组件图片已被删除
  };
  

  const handleKeyDown  = (e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      console.log('Send message:');
      handleSendClick();
    }
    
  };
  
  const [showZoomIn,setShowZoomIn] = useState(false);

  const contentSelector11 = (
    <ContentStyle>
      <ul>
        {predefinedContent.map((content, index) => (
          <MenuItem key={index} onClick={() => handlePredefinedContentClick(content)}>
            {content}
          </MenuItem>
        ))}
      </ul>
    </ContentStyle>
  );


  // 修改后的内容选择器
const contentSelector1 = (
  <ContentStyle onClick={() => setShowContentSelector(false)}>
    <ul>
      {predefinedContent.map((content, index) => (
        <MenuItem key={index} onClick={() => handlePredefinedContentClick(content)}>
          {content}
        </MenuItem>
      ))}
    </ul>
  </ContentStyle>
);

const [anchorEl, setAnchorEl] = useState(null);
const handleClick = (event) => {
  setAnchorEl(event.currentTarget);
  setShowContentSelector((prev) => !prev);
};

const handleClose = () => {
  setShowContentSelector(false);
};
//const open = Boolean(anchorEl);
const open = Boolean(anchorEl) && showContentSelector;
const id = open ? 'simple-popover' : undefined;



const inputStyles = {
  paddingRight: '40px', // 为发送按钮留出空间
  paddingLeft: selectedImage ? '60px' : '10px',
  color:get_color_by_name('mui-textfield-text-color'),
  fontFamily: 'siyuan',
};


const inputStylesZoomMax = {
  color:get_color_by_name('mui-textfield-text-color'),
  fontFamily: 'siyuan',
};



  return (
    <div style={{
      display:'flex',
      alignItems:'center',
      padding:'10px',
      borderTop:'1px solid #ccc',
      backgroundColor:get_color_by_name('message-item-bg-all'),
    }}>

    <div>
      <IconButton
        size="medium"
        onClick={handleClick}  // 点击时切换显示状态
      >
        <ArticleIcon sx={{color:get_color_by_name('small-icon-color')}} />
      </IconButton>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}  // 点击外部时能够关闭Popover
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}

        sx={{
          '& .MuiPaper-root': {
            backgroundColor: get_color_by_name('pop-window-bg'), // 更改背景色
            color: get_color_by_name('pop-window-text-color'), // 更改文字颜色
            padding: 2, // 添加内边距以使内容不贴边
            border: `1px solid ${get_color_by_name('pop-window-border-color')}`,
          },
        }}
      >
        {contentSelector1}
      </Popover>
    </div>


      {showAddImage && (
      <div>
        <IconButton sx={{color:get_color_by_name('small-icon-color')}} aria-label="record" component="span" onClick={() => fileInputRef.current.click()}>
          <AddPhotoAlternateIcon />
            <input 
              ref={fileInputRef} 
              type="file" 
              style={{ display: 'none' }} 
              onChange={handleFileChange}
            />
          </IconButton>
      </div>
      )}
      {showAudio && (
          <div className="App">
          <IconButton sx={{color:get_color_by_name('small-icon-color')}} aria-label="record" component="span" onClick={handleAudioRecord}>
            <MicIcon />
          </IconButton>
        </div>
        )}
      <IconButton sx={{color:get_color_by_name('small-icon-color')}} aria-label="record" component="span" onClick={() => {setShowZoomIn(true)}}>
        <ZoomOutMapIcon />
      </IconButton>
      <InputWrapper>
        {selectedImage && (
          <ImageContainer>
            <Thumbnail src={selectedImage} />
            <DeleteIcon onClick={handleDeleteImage} />
          </ImageContainer>
        )}
        <TextField
          inputRef={inputRef}
          onKeyDown={handleKeyDown} // 添加这个属性来监听按键事件
          value={inputValue}
          onChange={handleInputChange}
          placeholder={t('enter your question,you can also record audio by clicking on the microphone button')}
          multiline
         
          fullWidth
          InputProps={{
            sx: inputStyles,
          }}
          
          sx={{
            '& .MuiInputBase-root': {
              backgroundColor: get_color_by_name('mui-textfield-bg'), // 自定义背景色
            },
            '& .MuiOutlinedInput-root': {
              '&:hover fieldset': {
                borderColor: get_color_by_name('mui-textfield-bg'),
              },
              '&.Mui-focused fieldset': {
                borderColor: 'primary.main',
              },
              '& fieldset': {
                borderColor: get_color_by_name('mui-textfield-bg'),
              },
            },
          }}
    />
        
        <Button
          style={{
            position: 'absolute',
            right: '10px',
            top: '50%',
            transform: 'translateY(-50%)',
            backgroundColor: 'transparent',
            border: 'none',
            color: get_color_by_name('small-icon-color'),
          }}
          startIcon={<SendIcon />}
          onClick={handleSendClick}
          disabled={isLoading}
        />
      </InputWrapper>

      <Modal
          open={showZoomIn}
          onClose={() => {setShowZoomIn(false)}}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          sx={{zIndex:5555}}
        >
          <Paper
            elevation={3}
            sx={{
              zIndex: 9999,
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              display: 'flex',
              flexDirection: 'column',
              gap: '20px',
              alignItems: 'center',
              padding: '20px',
              width: '70vw',
              backgroundColor:get_color_by_name('navigator-bg'),
              border: `1px solid ${get_color_by_name('pop-window-border-color')}`, // 使用函数返回值设置边框颜色
            }}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                flex: 1, // 让这个容器充满除按钮外的所有空间
                width: '100%', // 确保宽度是100%
                
              }}
            >
              <TextField
                label={t('question')}
                sx={{
                  backgroundColor: get_color_by_name('mui-textfield-bg'), 
                  color: get_color_by_name('mui-textfield-text-color'),
                  width: '100%', // 宽度设置为100%
                  height: '100%', // 高度设置为100%
                }}

                InputProps={{
                  sx: inputStylesZoomMax,
                }}
                fullWidth
                multiline
                rows={25}
                variant="outlined"
                value={inputValue}
                onChange={handleInputChange}
                autoFocus
              />
            </Box>
            <Box
              sx={{
                alignSelf: 'flex-end',
                width: '100%',
                display: 'flex',
                justifyContent: 'flex-end',
              }}
            >
              <CustomButton
                onClick={() => {setShowZoomIn(false)}}
              >
                {t('close')}
              </CustomButton>
            </Box>
          </Paper>


      </Modal>
    </div>
  );
});

export default InputArea;

