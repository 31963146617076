import React, { useState,useEffect,useRef } from 'react';
import { Box, Paper, Stack,Modal } from '@mui/material';
import { requestUrl } from '../../requestUrl';
import {  Typography, Grid,Button,Chip } from '@mui/material';
import LoadingData from '../dataloading';
import { useTranslation } from 'react-i18next';
import 'katex/dist/katex.min.css';
import katex from 'katex';
import './pkmap.css';
import { useNavigate } from 'react-router-dom';
import remarkMath from 'remark-math';
import rehypeKatex from 'rehype-katex';
import MessageList from '../ChatWindow/MessageList';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw'; // 引入 rehype-raw 插件
import MessageItem from '../ChatWindow/MessageItem';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';

import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import { styled } from '@mui/material/styles';

import CardHeader from '@mui/material/CardHeader';
import Collapse from '@mui/material/Collapse';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import { red } from '@mui/material/colors';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Masonry } from '@mui/lab';
import { useSnackbar } from '../../SnackbarProvider';

import CustomButton from '../../components/CustomButton';

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));



const PointKnowledgeMapComponent = ({pkContent,onClose,subject}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [loadingText,setLoadingText] = useState(false);
  const [showChatDetail,setShowChatDetail] = useState(false);
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [results,setResults] = useState([]);
  const showSnackbar = useSnackbar();
  const results1 = [
    {
        "common_chat": {
            "id": 1,
            "question": "What is the capital of France $x^2$?",
            "answer": "The capital of France is Paris.",
            "recordtime": "2024-02-25 10:00:00",
            "pointKnowledgeArray": [{"id":1,"xs":8,"content":"二次函数的求解"},{"id":2,"xs":4,"content":"求根公式"},{"id":3,"xs":4,"content":"虚数概念"}],
            "pointKnowledgeContent": "Paris $x^2$is the capital and most populous city of France.",
            "common_chat_session_id": 101
        },
        "common_chat_session": {
            "session_name": "Geography Session",
            "recordtime": "2024-02-25 09:50:00",
            "qtype": 1,
            "qtype_name": "Multiple Choice",
            "pointKnowledgeArray": [{"id":1,"xs":8,"content":"二次函数的求解"},{"id":2,"xs":4,"content":"求根公式$x^2$"},{"id":3,"xs":4,"content":"虚数概念"}],
            "pointKnowledgeContent": "This session covers countries and their capitals."
        }
    },
    {
        "common_chat": {
            "id": 2,
            "question": "What is the formula for water?",
            "answer": "The formula for water is H2O.",
            "recordtime": "2024-02-24 14:30:00",
            "pointKnowledgeArray": [{"id":1,"xs":8,"content":"二次函数的求解"},{"id":2,"xs":4,"content":"求根公式"},{"id":3,"xs":4,"content":"虚数概念"}],
            "pointKnowledgeContent": "Water is a transparent, tasteless, odorless, and nearly colorless chemical substance.",
            "common_chat_session_id": 102
        },
        "common_chat_session": {
            "session_name": "Chemistry Basics",
            "recordtime": "2024-02-24 14:00:00",
            "qtype": 2,
            "qtype_name": "Short Answer",
            "pointKnowledgeArray": [{"id":1,"xs":8,"content":"二次函数的求解"},{"id":2,"xs":4,"content":"求根公式"},{"id":3,"xs":4,"content":"虚数概念"}],
            "pointKnowledgeContent": "This session includes basic chemistry questions."
        }
    },
    {
        "common_chat": {
            "id": 3,
            "question": "Who wrote Macbeth?",
            "answer": "Macbeth was written by William Shakespeare.",
            "recordtime": "2024-02-23 16:45:00",
            "pointKnowledgeArray": [{"id":1,"xs":8,"content":"二次函数的求解"},{"id":2,"xs":4,"content":"求根公式"},{"id":3,"xs":4,"content":"虚数概念"}],
            "pointKnowledgeContent": "Macbeth is a tragedy by William Shakespeare; it is thought to have been first performed in 1606.",
            "common_chat_session_id": 103
        },
        "common_chat_session": {
            "session_name": "English Literature",
            "recordtime": "2024-02-23 16:30:00",
            "qtype": 3,
            "qtype_name": "Essay",
            "pointKnowledgeArray": [{"id":1,"xs":8,"content":"二次函数的求解"},{"id":2,"xs":4,"content":"求根公式"},{"id":3,"xs":4,"content":"虚数概念"}],
            "pointKnowledgeContent": "This session focuses on important works and authors in English literature."
        }
    },
    {
        "common_chat": {
            "id": 4,
            "question": "How does photosynthesis work?",
            "answer": "Photosynthesis is the process by which green plants and some other organisms use sunlight to synthesize foods from carbon dioxide and water.",
            "recordtime": "2024-02-22 11:20:00",
            "pointKnowledgeArray": [{"id":1,"xs":8,"content":"二次函数的求解"},{"id":2,"xs":4,"content":"求根公式"},{"id":3,"xs":4,"content":"虚数概念"}],
            "pointKnowledgeContent": "Photosynthesis in plants generally involves the green pigment chlorophyll and generates oxygen as a byproduct.",
            "common_chat_session_id": 104
        },
        "common_chat_session": {
            "session_name": "Biology 101",
            "recordtime": "2024-02-22 11:00:00",
            "qtype": 4,
            "qtype_name": "Lab",
            "pointKnowledgeArray": [{"id":1,"xs":8,"content":"二次函数的求解"},{"id":2,"xs":4,"content":"求根公式"},{"id":3,"xs":4,"content":"虚数概念"}],
            "pointKnowledgeContent": "This session explores the basics of plant biology, including photosynthesis."
        }
    }
  ];

  const [expanded, setExpanded] = React.useState(null); // 步骤1：修改初始状态

  const handleExpandClick = (id) => { // 步骤2：修改函数
    setExpanded(expanded === id ? null : id);
  };


    
  const handleCloseSessionChat = () => setShowChatWindow(false);
  const [messages, setMessages] = useState([]);

  // 定义Item组件的样式
  const itemStyle = {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: '#393e46',
    padding: '8px',
    textAlign: 'center',
    color: 'rgba(0, 0, 0, 0.87)',
    borderRadius:'10px',
    
  };
      
  useEffect(() => {
    const fetchImages = async () => {
      try 
      {
        setIsLoading(true);
        setLoadingText("loading");
        const dataToSend = {
            pkContent:pkContent,
            queryType:"single",
            subject:subject,
            grade:1,

        };

        const data = await requestUrl(dataToSend, "query_pk_related_common_chat",t,showSnackbar);

        if(data.status === "success"){
            console.log(JSON.stringify(data.data));
            setResults(data.data);
        }
      } 
      catch (error)
      {
        console.error("获取数据时出错:", error);
      } 
      finally 
      {
        setIsLoading(false);
      }
    };

    fetchImages();
  }, [pkContent]);



  const renderTextAndMath = (text) => {
    if (text === null || text === "") return text;
      if (text === undefined) {
        text = "";
      }
      const regex = /\$(.+?)\$/g;
      let result = '';
      let lastIndex = 0;
  
      text.replace(regex, (match, formula, index) => {
        // 添加普通文本
        result += text.slice(lastIndex, index);
        // 将KaTeX公式转换为HTML
        try {
          result += katex.renderToString(formula, { throwOnError: false });
        } catch (e) {
          console.error(e);
          result += match; // 如果出错，就使用原始文本
        }
        lastIndex = index + match.length;
      });
  
      // 确保添加最后一段普通文本
      result += text.slice(lastIndex);
      return result;
    };


    const truncateText = (text, maxLength) => {
      // 检查text是否为null或空字符串
      if (text === null || text === "") return text;

      // 如果text的长度小于或等于maxLength，直接返回text
      if (text.length <= maxLength) return text;

      // 否则，截断text并添加省略号
      return text.substring(0, maxLength) + '...';
    };






  const [showChatWindow,setShowChatWindow] = useState(false);

  const handleClickChatSessionDetail = (commonChat_session_detail) => {

    console.log(commonChat_session_detail.id);
    
    setShowChatWindow(true);
    loadSubjectData(1,subject,0,commonChat_session_detail.id);
    
  };



  const [commonChatDetail,setCommonChatDetail] = useState({});



  const loadSubjectData = async (page,selectedSubject,grade,ccsid) => {
    console.log("song");
    try {
      setIsLoading(true);
      const dataToSend = {
        subject:selectedSubject,
        grade_value:'0',
        common_chat_session_id:ccsid,
        page_size:10,
        page_number:page,
      };

      console.log(dataToSend);
      
      const data = await requestUrl(dataToSend,"get_subject_history",t,showSnackbar);
      console.log(data);
      setMessages(data.data);
    
      if(page === 1){
        
      }
      setIsLoading(false);    
      
      
    }
    catch (error) {
      setIsLoading(false);
      console.error("There was an error fetching the reply:", error);
    }
  }

  const [oneMessage,setOneMessage] = useState(null);


  const handleClickChatDetail = async (commonChat_detail) => {
    setCommonChatDetail(commonChat_detail);

    try {
      setIsLoading(true);
      const dataToSend = {
        id:commonChat_detail.id,
      };

      console.log(dataToSend);
      
      const data = await requestUrl(dataToSend,"get_commonchat_one",t,showSnackbar);
      console.log(data);
      setOneMessage(data.data);
      setIsLoading(false);    
      
      
    }
    catch (error) {
      setIsLoading(false);
      console.error("There was an error fetching the reply:", error);
    }


    setShowChatDetail(true);
    setOpen(true);
  };


    return (
      <Paper 
        style={{
          zIndex: 5555,
          display: 'flex',
          flexDirection: 'column', // 由于我们希望内容垂直堆叠，需要设置flex方向
          position: 'fixed',
          left: '50%',
          top: '50%',
          height:'80%',
          maxWidth:'800px',
          width:'80%',
          transform: 'translate(-50%, -50%)',
          margin: '20px',
          padding: '20px',
          backgroundColor: 'white'
        }} 
      elevation={3}
    >
        <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%',alignItems: 'center', justifyContent: 'center' }}>
          <Box sx={{ height: '40px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              <span style={{color:'#FF9900'}}>{t('found')}{results.length}{t('relevant records related to the knowledge point')}</span>
          </Box>
           
          <Box sx={{ height: '100%', overflow: 'auto', display: 'flex', flexDirection: 'column', gap: 2, padding: '20px' }}>
          <Masonry columns={{ xs: 1, sm: 2 }} spacing={2}>
            {results.map((result, index) => (
              <div key={index} style={{ marginBottom: '16px' }}> {/* 每个Grid项在小屏幕上占满宽度，在中等以上屏幕上占一半宽度 */}
                {/* Common Chat Data */}
                <Card sx={{ maxWidth: 345,color:'white', backgroundColor:'#003366' }} key={result.common_chat.id} >
                  <CardHeader
                    avatar={
                      <Avatar sx={{ bgcolor: red[500] }} aria-label="recipe">
                        R
                      </Avatar>
                    }
                    action={
                      <IconButton aria-label="settings">
                        <MoreVertIcon />
                      </IconButton>
                    }
                    title={
                      <ReactMarkdown
                        children={result.common_chat.question}
                        remarkPlugins={[remarkMath]}
                        rehypePlugins={[rehypeRaw, rehypeKatex]}
                      />
                    }
                    subheader={result.common_chat.recordtime}
                    titleTypographyProps={{ component: 'div' }} // 允许 title 接受 React 组件
                    subheaderTypographyProps={{ style: { color: 'white' } }} // 设置 subheader 字体颜色为白色
                  />

                  {result.common_chat.bg_img && (
                  <CardMedia
                    sx={{padding:'10px',borderRadius:'10px'}}
                    component="img"
                    height="194"
                    image={result.common_chat.bg_img} // 使用bg_img的值作为image属性
                    alt="Common Chat Background"
                  />
                )}
                
                  <CardContent>
                    <Typography variant="body2" color="text.secondary">
                    <ReactMarkdown
                            children={truncateText(result.common_chat.answer,100)}
                            remarkPlugins={[remarkMath]}
                            rehypePlugins={[rehypeRaw, rehypeKatex]} // 注意插件的顺序，rehypeRaw 先于 rehypeKatex
                          />
                     
                    </Typography>
                  </CardContent>

                  <CardContent>
                    <Typography variant="body2" color="text.secondary">
                    <ReactMarkdown
                            children={result.common_chat.pointKnowledgeContent}
                            remarkPlugins={[remarkMath]}
                            rehypePlugins={[rehypeRaw, rehypeKatex]} // 注意插件的顺序，rehypeRaw 先于 rehypeKatex
                          />
                    </Typography>
                  </CardContent>

                  <CardContent>
                  <Typography variant="body2" color="text.secondary">
                      <span style={{color:'white'}}>{t('session name')}</span>
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                    <ReactMarkdown
                            children={truncateText(result.common_chat_session.session_name, 100)}
                            remarkPlugins={[remarkMath]}
                            rehypePlugins={[rehypeRaw, rehypeKatex]} // 注意插件的顺序，rehypeRaw 先于 rehypeKatex
                          />
                      
                    </Typography>
                    <IconButton aria-label="share" onClick={() => handleClickChatSessionDetail(result.common_chat_session)}>
                      <MenuBookIcon  sx={{color:'white'}}/>
                    </IconButton>
                  </CardContent>

                  <CardActions disableSpacing>
                    <IconButton aria-label="add to favorites" onClick={() => handleClickChatDetail(result.common_chat)}>
                      <LibraryBooksIcon sx={{color:'white'}} />
                    </IconButton>
                   
                    <ExpandMore
                      expand={expanded === result.common_chat.id} // 判断expanded状态是否等于当前Card的id
                      onClick={() => handleExpandClick(result.common_chat.id)} // 传入当前Card的id
                      aria-expanded={expanded === result.common_chat.id}
                      aria-label="show more"
                    >
                      <ExpandMoreIcon  sx={{color:'white'}}/>
                    </ExpandMore>

                  </CardActions>

                  <Collapse in={expanded === result.common_chat.id} timeout="auto" unmountOnExit>
                    <CardContent sx={{background:'#325b84'}}>
                    <ReactMarkdown
                            children={result.common_chat.answer}
                            remarkPlugins={[remarkMath]}
                            rehypePlugins={[rehypeRaw, rehypeKatex]} // 注意插件的顺序，rehypeRaw 先于 rehypeKatex
                          />
                    </CardContent>
                  </Collapse>
                </Card>               
              </div>
            ))}
            </Masonry>
          </Box>

          <Box sx={{ height: '40px', display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '20px' }}>
              
              <CustomButton onClick={onClose}>{t('close')}</CustomButton>
          </Box>

          {isLoading && (
              <LoadingData text={t(loadingText)}/>
          )}


        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          sx={{zIndex:9999}}
        >
          <Paper
            style={{
              zIndex: 9999,
              display: 'flex',
              flexDirection: 'column', // 由于我们希望内容垂直堆叠，需要设置flex方向
              backgroundColor: 'white',
              position: 'fixed',
              left: '50%',
              top: '50%',
              height: '80%',
              maxWidth: '800px',
              width: '80%',
              transform: 'translate(-50%, -50%)',
              margin: '20px',
              padding: '20px',
            }}
            elevation={3}
          >

            <Typography variant="subtitle1" style={{ color: 'white', backgroundColor: '#537780', borderRadius: '5px', height: '60px', marginBottom: '20px' }}>
             
            <MessageList messages={oneMessage} subject={subject} useWf={0}/>
            </Typography>
            <Box sx={{ display: 'flex', justifyContent: 'space-around', marginTop: 'auto' }}>
              <CustomButton onClick={handleClose}>{t('cancel')}</CustomButton>
              <CustomButton onClick={handleClose}>{t('confirm')}</CustomButton>
            </Box>
          </Paper>

        </Modal>

        <Modal
          open={showChatWindow}
          onClose={handleCloseSessionChat}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          sx={{zIndex:6666}}
        >
          <Paper
            style={{
              zIndex: 6666,
              display: 'flex',
              flexDirection: 'column', // 由于我们希望内容垂直堆叠，需要设置flex方向
              backgroundColor: 'white',
              position: 'fixed',
              left: '50%',
              top: '50%',
              height: '80%',
              maxWidth: '800px',
              width: '80%',
              transform: 'translate(-50%, -50%)',
              margin: '20px',
              padding: '20px',
            }}
            elevation={3}
          >

            <MessageList messages={messages} subject={subject} useWf={0}
              />

            <Box sx={{ display: 'flex', justifyContent: 'space-around', marginTop: 'auto' }}>
              <CustomButton onClick={handleCloseSessionChat}>{t('cancel')}</CustomButton>
              <CustomButton onClick={handleCloseSessionChat}>{t('confirm')}</CustomButton>
            </Box>
          </Paper>

        </Modal>
      </Box>
      </Paper>
    );
};

export default PointKnowledgeMapComponent;
