import React, { useState, useEffect, useRef } from 'react';
import './style.css';

import { useNavigate } from 'react-router-dom';
import { getHostName } from '../../utils/urlUtils';

import styled, { keyframes } from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Select } from '@mui/material';
import i18n from '../../i18n';

import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import SettingsIcon from '@mui/icons-material/Settings';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import { DensityMedium } from '@mui/icons-material';
import {Grid, Breadcrumbs, Typography, SvgIcon } from '@mui/material';
import LoadingData from '../../education/dataloading';
import { useVisitorData } from '@fingerprintjs/fingerprintjs-pro-react';
import { useSnackbar } from '../../SnackbarProvider';
import { getColorDark,getColorLight } from '../../ColorManager';

const useCurrentRouteName = () => {
  const location = useLocation();
  const routeList = useSelector((state) => state.user.routeList);

  
  // 直接使用 location.pathname 进行比较，不移除前导斜线
  const currentRoute = routeList.find(route => route.path === location.pathname);
  return currentRoute ? currentRoute.name : 'home';
};

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;


const Header = ({ isLoggedInS, setIsLoggedInS, user, isLoggedIn, setToken, handleDisconnect, setActiveView }) => {
  const {
    isLoading,
    error,
    getData
  } = useVisitorData({ tag: "subscription-form" }, { immediate: false });
  const showSnackbar = useSnackbar();
  const { t } = useTranslation();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isRegister, setIsRegister] = useState(false);
  const [username, setUsername] = useState(null); // 这里定义 user 和 setUser

  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const modalRef = useRef();
  const [selectedLanguage, setSelectedLanguage] = useState('en');

  const [rolename, setRolename] = useState('user');

  const [password, setPassword] = useState('');

  const [loadingText, setLoadingText] = useState('login...');

  const [showRegistration, setShowRegistration] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState("");
  const [invitationCode, setInvitationCode] = useState("");
  const [passwordMismatch, setPasswordMismatch] = useState(false);

  const [isComposing, setIsComposing] = useState(false);

  const [fdata, setFdata] = useState(null);


  const [colorManager, setColorManager] = useState(getColorDark()); // 初始化为空数组

  //获取颜色设置，系统加载时设置
useEffect(() => {

const themeName = localStorage.getItem('theme');//此处存储的是数字1-12，而不是汉字，
if (themeName) {
  
  if(themeName === "dark"){
    setColorManager(getColorDark());
  }

  
  if(themeName === "light"){
    setColorManager(getColorLight());
  }
}
else{
  let aa = getColorDark();
  setColorManager(aa);
}


}, []);



const get_color_by_name = (name) => {

const item = colorManager.find(item => item.container === name);
return item.value;
}


  const handleKeyDown = (e) => {
    if (isComposing) return;  // 如果正在输入中文，则直接返回

    if (e.key === 'Enter') {
      // 处理回车键逻辑
      handleLogin();
    }
  };
  const handleComposition = (e) => {
    if (e.type === 'compositionstart') {
      setIsComposing(true);
    } else if (e.type === 'compositionend') {
      setIsComposing(false);
    }
  };


  // 处理外部点击
  const handleClickOutside = event => {
    if (modalRef.current && !modalRef.current.contains(event.target)) {
      setIsModalVisible(false);
    }
  };


  // 添加和清除事件监听器
  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleConfirmPasswordChange = (e) => {
    const newConfirmPassword = e.target.value;
    setConfirmPassword(newConfirmPassword);

    if (newConfirmPassword !== password) {
      setPasswordMismatch(true);
    } else {
      setPasswordMismatch(false);
    }
  };

  const languageOptions = {
    'en': 'english',
    'zh': '简体中文',
    'in': 'indonesian',
    'ko': 'korean',
    'ja': 'japanese',
    'ru': 'russian',
    'fr': 'french',
    'th': 'thai',
    'ma': 'malay',
    'de': 'german',
    'vi': 'vietnamese',
    'uk': 'ukrainian'
  };



  const getIpAddress = async () => {
    try {
      const response = await fetch('http://ip-api.com/json/');
      const data = await response.json();
      return data.query;  // 'query' 字段包含了IP地址
    } catch (error) {
      console.error('Error fetching IP address:', error);
      return null;  // 在错误发生时返回null或适当的错误信息
    }
  };

  const handleLogin = async (e) => {
    //e.preventDefault();
    // 登录逻辑
    if (username === '') {
      alert("用户名不能为空");
      return;
    }
    if (password === '') {
      alert("密码不能为空");
      return;
    }
    setLoading(true); // 开始加载
    const ip = "none";//await getIpAddress(); 

    console.log("ip地址:" + ip);
    let datas;
    try {
      datas = {};//await getData();

      setFdata(datas); // 假设您有一个状态变量来存储这些数据
      // 在这里，您可以继续执行依赖于数据的其他操作
    } catch (error) {
      // 处理错误
      console.error('Error fetching data:', error);
    }

    let url = getHostName() + '/login';

    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          username: username,
          password: password,
          fingerdata: datas,
          ip: ip,
        }),
      });

      const data = await response.json();


      if (data.success) {

        // 将username、uid和access_token保存在浏览器本地
        user = data.user;

        localStorage.setItem('username', user.username);
        localStorage.setItem('uid', user.id);
        localStorage.setItem('grade', user.grade);
        localStorage.setItem('subject', user.last_subject);
        localStorage.setItem('sversion', user.sversion);
        localStorage.setItem('language', user.language);
        localStorage.setItem('userlanguage', user.language);
        localStorage.setItem('rolename', user.rolename);
        localStorage.setItem('access_token', data.access_token);
        setRolename(user.rolename);
        // 可以在此处执行其他操作，例如关闭模态框或跳转到其他页面
        setIsModalVisible(false);
        setUsername(data.username); // 更新用户名
        /////////////////////////////////////setIsLoggedIn(true);
        setIsLoggedInS(true);
        isLoggedIn.current = true;
        setLoading(false); // 结束加载
        navigate('/Default');
      } else {
        // 处理注册失败的情况
        console.log('登录失败:', data.message);
        setLoading(false); // 结束加载
      }
    } catch (error) {
      // 处理请求过程中的错误
      console.error('登录过程中发生错误:', error);
      setLoading(false); // 结束加载
    }


    setLoading(false); // 结束加载
    setIsModalVisible(false);
  };

  const handleRegister = async (values) => {
    if (username === '') {
      showSnackbar(t('user name cannot be empty'),'warning');
      return;
    }
    if (password === '') {
      showSnackbar(t('password cannot be empty','warning'));
      return;
    }
    if (invitationCode === '') {
      showSnackbar(t('invite code cannot be empty','warning'));
    }

    setLoading(true); // 开始加载

    // 获取完整的语言设置字符串，如 "en-US"
    const fullLang = navigator.language || navigator.userLanguage;

    // 通过'-'分割字符串，取第一个部分作为简写
    const shortLang = fullLang.split('-')[0];

    try {
      const response = await fetch(getHostName() + '/register', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          username: username,
          phone_number: "135",
          password: password,
          invite_code: invitationCode,
          lang: shortLang,
        }),
      });


      if(response.status === 501){
        showSnackbar(t('no invitation code found','warning'));
        setLoading(false); // 结束加载
        return;
      }

      if(response.status === 502){
        showSnackbar(t('server error','warning'));
        setLoading(false); // 结束加载
        return;
      }

      if(response.status === 503){
        showSnackbar(t('invalid invitation code','warning'));
        setLoading(false); // 结束加载
        return;
      }

      if(response.status === 400){
        showSnackbar(t('your username is already taken, please register under another name','error'));
        setLoading(false); // 结束加载
        return;
      }

      const data = await response.json();


      if (data.success) {

        // 将username、uid和access_token保存在浏览器本地
        localStorage.setItem('username', data.username);
        localStorage.setItem('uid', data.uid);
        localStorage.setItem('access_token', data.access_token);
        localStorage.setItem('grade', 5);
        localStorage.setItem('subject', "mathematics");
        localStorage.setItem('sversion', "人教版");
        localStorage.setItem('language', shortLang);
        localStorage.setItem('userlanguage', shortLang);


        // 可以在此处执行其他操作，例如关闭模态框或跳转到其他页面
        setIsRegister(false);
        setIsModalVisible(false);
        setUsername(data.username); // 更新用户名
        setIsLoggedInS(true);
        isLoggedIn.current = true;
        setLoading(false); // 结束加载
        navigate('/Default');
      } else {
        // 处理注册失败的情况
        console.log('register failure:', data.message);
        setLoading(false); // 结束加载
      }
    } catch (error) {
      // 处理请求过程中的错误
      console.error('register error:', error);
      setLoading(false); // 结束加载
    }
  };


  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    if (isLoggedIn.current === true) {
      
    }
    else {
      setIsModalVisible(true);
    }
  };


  const logout = () => {
    console.log("退出");
    // 清除本地存储的用户信息
    localStorage.removeItem('username');
    localStorage.removeItem('uid');
    localStorage.removeItem('access_token');
    isLoggedIn.current = false;
    // 如果你使用了某种状态管理（如 Redux 或 Context API），你可能还需要更新状态
    // 例如：dispatch(logoutAction());

    // 重定向到登录页面或首页
    navigate('/Default');
  };

  const getBrowserLanguage = () => {
    const browserLang = navigator.language || navigator.userLanguage;
    let langToSet = browserLang.split('-')[0];
    console.log("语言:" + browserLang + "," + langToSet);
    i18n.changeLanguage(langToSet); // 设置 i18n 的语言
    console.log(`Language set to: ${langToSet}`);
    setSelectedLanguage(langToSet);
  }

  // 当组件挂载时，检查localStorage中的令牌
  useEffect(() => {
    let lang = localStorage.getItem('language');//先从本地检测，

    if (lang) {

      setSelectedLanguage(lang);
      i18n.changeLanguage(lang); // 设置 i18n 的语言
    }
    else { //本地没有检测浏览器
      getBrowserLanguage();
    }
  }, [selectedLanguage]); // 空依赖数组确保只在组件挂载时运行


  // 当组件挂载时，检查localStorage中的令牌
  useEffect(() => {
    const token = localStorage.getItem('access_token');
    if (token) {
      // 如果令牌存在，设置为已登录状态
      setUsername(localStorage.getItem('username'));
      ///////////////////////////////////(true);
      isLoggedIn.current = true;
    }
  }, []); // 空依赖数组确保只在组件挂载时运行


  useEffect(() => {
    const token = localStorage.getItem('access_token');
    if (token) {
      // 如果令牌存在，设置为已登录状态
      setUsername(localStorage.getItem('username'));
      ///////////////////////////////////(true);
      isLoggedIn.current = true;
    }
  }, [isLoggedInS]);



  const handleChange = async (event) => {
    console.log(`点击了选项: ${event.target.value}`);
    setSelectedLanguage(event.target.value); // 2. 设置选中的选项
    localStorage.setItem('language', event.target.value);
    i18n.changeLanguage(event.target.value);
  };


  const centerStyle = {
    position: 'fixed', // 使用 fixed 代替 absolute
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    background: 'rgba(0, 0, 0, 1)',
    boxSizing: 'border-box',
    boxShadow: '0 15px 25px rgba(0, 0, 0, 0.6)',
    borderRadius: '10px',
    opacity: '0.9',
    zIndex: 9999,
    // 其他样式，例如 width、padding 等
  };


  const [selectedItem, setSelectedItem] = useState('home');


  const changeSideBar = () => {
    document.querySelector('.sidebar').style.display = 'block'
    document.querySelector('.mask').style.display = 'block'
  };



  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);


  const currentRouteName = useCurrentRouteName();
  
  const handleClose = (event) => {
    event.stopPropagation();
    setAnchorEl(null);
  };



  return (
    <>

      <Grid container className='header' style={{backgroundColor:get_color_by_name('div-bg'),borderRadius:'5px'}} >
        <Grid className='header-left' item xs={7}>

          <DensityMedium class="icon" onClick={changeSideBar}></DensityMedium>
          <span class="tips">{t('location')}:</span>

          <Breadcrumbs aria-label="breadcrumb">
            <Typography color="text.primary">{t(currentRouteName)}</Typography>
          </Breadcrumbs>
        </Grid>
        

       


        <Grid className='header-right' item xs={5}>
          <div className='tools'>
            <div className='tool-item'>
              {/* <SvgIcon component={GTranslate} onClick={changeLanguage} /> */}
              <Select
                className='select'
                value={selectedLanguage}
                onChange={handleChange}
              >
                {
                  Object.keys(languageOptions).map(key => (
                   
                    <MenuItem
                    className='select-item' key={key} value={key}
                      sx={{
                        color: get_color_by_name('headline-color'),
                        fontSize: '14px',
                        backgroundColor:get_color_by_name('pop-window-bg'),
                      }}
                      >
                        {languageOptions[key]}
                      </MenuItem>
                  ))
                }
              </Select>

            </div>
            <div className='tool-item' style={{display:'flex',alignItems:'center'}}>
              {/* 登录状态 */}
              {!isLoggedIn.current ? <Button aria-controls="user-menu"
                aria-haspopup="true"
                onClick={() => setIsModalVisible(true)} variant="outlined">{t('Login')}</Button> : <div className='avatar' onClick={handleClick}>
                  <span style={{fontSize:'12px',color:'white'}}>{username}</span>

                  <Menu
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'right',
                    }}
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'left',
                    }}
                    PaperProps={{
                      style: {
                        backgroundColor: 'white',
                        color: 'black',
                      },
                    }}
                  >
                    <MenuItem 
                    onClick={(event) => {
                      navigate('/myorder');
                      event.stopPropagation();
                      setAnchorEl(null);
                    }} 
                    sx={{fontSize:'12px'}}>
                    <ShoppingCartIcon style={{ marginRight: '10px' }} />
                    {t('my order')}
                  </MenuItem>
                  <MenuItem onClick={logout} sx={{fontSize:'12px'}}>
                    <SettingsIcon style={{ marginRight: '10px' }} />
                    {t('log out')}
                  </MenuItem>
                  </Menu>


                  </div>}
            </div>
          </div>
        </Grid>
      </Grid>

      {/* <div style={{ color: 'white' }} className="logo-container">
        <HeroSection style={{ cursor: 'pointer' }} >
          <FormControl sx={{
            height: '30px',
            minHeight: '30px',
            marginLeft: '20px',
            '& .MuiInputBase-root': {
              height: '30px',
              minHeight: '30px',
              fontSize: '16px'
            }
          }}>
            <Select sx={{
              color: 'white',
              padding: '2px 0', // 尝试减小这个值
              lineHeight: 'normal', // 或者尝试设置这个值
              '& > div': { // 直接针对内部的div设置样式
                height: '30px',
                width: '60px'
              }
            }}
              value={selectedLanguage}
              onChange={handleChange}
            >
              {Object.keys(languageOptions).map((key) => (
                <MenuItem key={key} value={key} style={{ color: 'white', backgroundColor: '#4a90e2' }}>
                  {languageOptions[key]}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <div style={headerTitle}>
            <Button sx={{ color: 'white', width: '100%' }} onClick={toggleDrawer(true)}>{t(selectedItem)}</Button>
            <Drawer
              anchor="top"
              open={isOpen}
              onClose={toggleDrawer(false)}
              sx={{
                '& .MuiDrawer-paper': {
                  backgroundColor: '#f4f4f4',
                  transition: 'all 0.5s ease',
                }
              }}
            >
              <List
                sx={{ width: '100%' }}
                role="presentation"
              >
                {systemMenus.map((text, index) => (
                  <>
                    <ListItem
                      button
                      key={text}
                      sx={{
                        justifyContent: 'center',
                        '& .MuiListItemText-primary': {
                          color: '#333', // 改为深色字体
                          textAlign: 'center',
                          fontSize: '18px', // 加大字体大小
                          fontWeight: 'bold', // 加粗字体
                        },
                        '&:hover': {
                          backgroundColor: '#ddd', // 深一点的悬停背景色
                        }
                      }}
                      selected={selectedItem === text}
                      onClick={() => handleMenuItemClick(text)}
                    >
                      <ListItemIcon>{icons[index]}</ListItemIcon>
                      <ListItemText primary={t(text) + t(systemMenusContent[index])} />
                    </ListItem>
                    <Divider /> 
                  </>
                ))}
              </List>
            </Drawer>
          </div>
          <div></div>
        </HeroSection>

      </div> */}


      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        {/* <NavBar1 rolename={rolename} style={{ width: '100px', height: '30px' }} /> */}
        <div className="auth-container" >
          {/* <div>
            {isLoggedIn.current ? (
              <>
                <Button
                  aria-controls="user-menu"
                  aria-haspopup="true"
                  onClick={handleClick}
                >
                  <span style={{ fontSize: '12px', color: 'white' }}>{username}</span>
                </Button>
                {userMenu}
              </>
            ) : (
              <Button variant="contained" color="primary" onClick={() => setIsModalVisible(true)}>
                Login
              </Button>
            )}
          </div> */}

          {isModalVisible && (

            <div className="login-box" style={centerStyle} ref={modalRef}>
              <h2>{showRegistration ? t('Register') : t('Login')}</h2>
              <form className='login'>
                <div className="user-box">
                  <input
                    type="text"
                    name="username"
                    required
                    value={username}
                    onChange={e => setUsername(e.target.value)}
                  />
                  <label>{t('Username')}</label>
                </div>
                <div className="user-box">
                  <input
                    type="password"
                    name="password"
                    required
                    value={password}
                    onChange={e => setPassword(e.target.value)}
                    onKeyDown={handleKeyDown}
                    onCompositionStart={handleComposition}
                    onCompositionEnd={handleComposition}
                  />
                  <label>{t('Password')}</label>
                </div>

                {showRegistration && (
                  <>
                    <div className="user-box">
                      <input
                        type="password"
                        name="confirmPassword"
                        required
                        value={confirmPassword}
                        onChange={handleConfirmPasswordChange}
                      />
                      <label>{t('Confirm Password')}</label>
                    </div>
                    {passwordMismatch && <div style={{ color: '#03e9f4' }}>{t('Passwords do not match')}</div>}
                    <div className="user-box">
                      <input
                        type="text"
                        name="invitationCode"
                        required
                        value={invitationCode}
                        onChange={e => setInvitationCode(e.target.value)}
                      />
                      <label>{t('Invitation Code')}</label>
                    </div>
                  </>
                )}

                <div className='btn'>
                  <a href="#" onClick={showRegistration ? handleRegister : handleLogin}>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                    {showRegistration ? t('Register') : t('Login')}
                  </a>

                  <a href="#" onClick={() => setShowRegistration(!showRegistration)}>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                    {showRegistration ? t('Go to Login') : t('Register')}
                  </a>
                </div>
              </form>

              {loading && (
                <LoadingData text={t(loadingText)}/>
              )}
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default Header;
