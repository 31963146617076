import React, { useState,useEffect } from 'react';
import { Box, Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Paper } from '@mui/material';

import { requestUrl } from '../requestUrl';
import LoadingData from '../education/dataloading';
import { useTranslation } from 'react-i18next';

const PriceRuleManage = () => {
    const [billingRules, setBillingRules] = useState([]);
    const [loading, setLoading] = useState(false);
    const [loadingText,setLoadingText] = useState('loading');
    const { t } = useTranslation();

    useEffect(() => {
        // 假设 fetchData 是一个获取数据的异步函数
        const fetchData = async () => {
            try {
                setLoading(true);
                setLoadingText("loading");
                const dataToSend = {
                    id:1,
                };
                const data = await requestUrl(dataToSend, "get_billing_rules");
                console.log(JSON.stringify(data));
                if(data.status === "success"){
                    setBillingRules(data.data);
                   
                }
              } 
              catch (error) {
                  console.error("获取数据时出错:", error);
              }
              finally {
                setLoading(false);
              }
        };

        fetchData();
    }, []);

    // 更新特定规则的特定字段
    const handleEdit = (id, field, value) => {
        setBillingRules(rules =>
            rules.map(rule => 
                rule.id === id ? { ...rule, [field]: value } : rule
            )
        );
    };
    const handleSave = async (rule) => {
        console.log(JSON.stringify(rule));
        try {
            setLoading(true);
            setLoadingText("loading");
            const dataToSend = {
                id:rule.id,
                cchat_count:rule.cchat_count,
                cchat_feynman_count:rule.cchat_feynman_count,
                ask_doc_count:rule.ask_doc_count,
                doc_summarize_count:rule.doc_summarize_count,
                use_wolframalpha_count:rule.use_wolframalpha_count,
            };
            const data = await requestUrl(dataToSend, "update_billing_rule_by_id");
            
            if(data.status === "success"){
               
               
            }
          } 
          catch (error) {
              console.error("获取数据时出错:", error);
          }
          finally {
            setLoading(false);
          }
    }


    return (
        <Box sx={{ width: '100%' }}>
            <Box sx={{ display: 'flex', justifyContent: 'flex-end', p: 2 }}>
                {/* 在这里添加更多的按钮 */}
                <Button variant="contained" color="primary">添加新规则</Button>
            </Box>
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell>ID</TableCell>
                            <TableCell align="right">等级名称</TableCell>
                            <TableCell align="right">价格</TableCell>
                            <TableCell align="right">语言</TableCell>
                            <TableCell align="right">科目请求</TableCell>
                            <TableCell align="right">feynman请求</TableCell>
                            <TableCell align="right">文档数量</TableCell>
                            <TableCell align="right">文档总结</TableCell>
                            <TableCell align="right">wolfram请求限制</TableCell>
                            <TableCell align="right">订阅天数</TableCell>
                            <TableCell align="right">保存</TableCell>
                            
                            {/* ...其他表头 */}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                    {billingRules.map((rule) => (
                            <TableRow key={rule.id}>
                                <TableCell component="th" scope="row">
                                    {rule.id}
                                </TableCell>
                                <TableCell align="right">{rule.level_name}</TableCell>
                                <TableCell align="right">{rule.price}</TableCell>
                                <TableCell align="right">{rule.language}</TableCell>
                                <TableCell align="right">
                                    <TextField  sx={{ input: { color: 'black' } }}
                                        value={rule.cchat_count}
                                        onChange={(e) => handleEdit(rule.id, 'cchat_count', e.target.value)}
                                    />
                                </TableCell>
                                <TableCell align="right">
                                    <TextField sx={{ input: { color: 'black' } }}
                                        value={rule.cchat_feynman_count}
                                        onChange={(e) => handleEdit(rule.id, 'cchat_feynman_count', e.target.value)}
                                    />
                                </TableCell>
                                <TableCell align="right">
                                    <TextField sx={{ input: { color: 'black' } }}
                                        value={rule.ask_doc_count}
                                        onChange={(e) => handleEdit(rule.id, 'ask_doc_count', e.target.value)}
                                    />
                                </TableCell>
                                <TableCell align="right">
                                    <TextField sx={{ input: { color: 'black' } }}
                                        value={rule.doc_summarize_count}
                                        onChange={(e) => handleEdit(rule.id, 'doc_summarize_count', e.target.value)}
                                    />
                                </TableCell>
                                <TableCell align="right">
                                    <TextField sx={{ input: { color: 'black' } }}
                                        value={rule.use_wolframalpha_count}
                                        onChange={(e) => handleEdit(rule.id, 'use_wolframalpha_count', e.target.value)}
                                    />
                                </TableCell>
                                <TableCell align="right">
                                    <TextField sx={{ input: { color: 'black' } }}
                                        value={rule.subscription_day}
                                        onChange={(e) => handleEdit(rule.id, 'subscription_day', e.target.value)}
                                    />
                                </TableCell>
                                <TableCell align="right">
                                    <Button variant="contained" color="primary" onClick={() => handleSave(rule)}>
                                        保存
                                    </Button>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            {loading && (
      <LoadingData text={t(loadingText)}/>
    )}
        </Box>
    );
};

export default PriceRuleManage;
