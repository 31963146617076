import React, { useState,useEffect } from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import AnalysisIcon from '@mui/icons-material/Assessment'; // 假设这是分析题目的图标
import 'katex/dist/katex.min.css';
import katex from 'katex';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import { blue, orange } from '@mui/material/colors';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from '../../SnackbarProvider';
import { requestUrl } from '../../requestUrl';
import LoadingData from '../dataloading';
import CustomButton from '../../components/CustomButton';
import { getColorDark,getColorLight ,getTheme} from '../../ColorManager';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Pagination,Modal,Checkbox ,TextField,Chip } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import ProblemDetail from './ProblemDetail';
import { Select, MenuItem, FormControl, InputLabel } from '@mui/material';
import CommonComboboxOne from '../../components/CommonComboboxOne.jsx';
import CommonCombobox from '../../components/CommonCombobox.jsx';
import AddEditProblem from './AddEditProblem.jsx';

import ConfigHomework from './ConfigHomework.jsx';

// 随机生成颜色的函数
const getRandomColor = () => {
  const letters = '0123456789ABCDEF';
  let color = '#';
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
};



const ProblemTeacher = ({}) => {
  const showSnackbar = useSnackbar();
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [loadingText,setLoadingText] = useState('loading');
  const [userid,setUserid] = useState(0);
  const [colorManager, setColorManager] = useState(getColorDark()); // 初始化为空数组
  const [tableData, setTableData] = useState([]);
  const [tableHomeWorkData,setTableHomeWorkData] = useState([]);//保存指定老师的家庭作业列表
  const [tableGradeHomeworkData,setTableGradeHomeworkData] = useState([]);//保存某一个家庭作业的详细信息，学生，完成情况，界面右侧列表
  const [totalPagesSession, setTotalPagesSession] = useState(1);
  const [currentPage, setCurrentPage] = useState(0);


  const [selectedRowId, setSelectedRowId] = useState(null);
  const [selectedRowIdHomework, setSelectedRowIdHomework] = useState(null);
  const [selectedRowIdGradeHW, setSelectedRowIdGradeHW] = useState(null);

  const [selectedRowData, setSelectedRowData] = useState(null);
  const [showProblemAddEdit,setShowProblemAddEdit] = useState(false);
  const [tagColors, setTagColors] = useState({});

  const [showConfigHomework,setShowConfigHomework] = useState(false);

  const [addEditProblem,setAddEditProblem] = useState('Add');

 // 状态：选择的值
 const [selectedValue, setSelectedValue] = useState('CSP-J');


 const [showProblemDetail,setShowProblemDetail] = useState(false);

 const [selectedRowMyHomeworkId, setSelectedRowMyHomeworkId] = useState(null);//problem_code_submit的id
 
  const tags = [
    'Array',
    'Binary Tree',
    'Segment Tree',
    'Bubble Sort',
    'Linked List',
    'Queue',
    'Stack',
    'Heap',
    'Graph',
    'HashMap',
    'DFS',
    'BFS',
    'Dynamic Programming',
    'Binary Search',
    'Quick Sort',
    'Merge Sort',
  ];

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const year = date.getFullYear().toString().slice(-2); // 获取年份的后两位
    const month = String(date.getMonth() + 1).padStart(2, '0'); // 月份从0开始，所以要+1
    const day = String(date.getDate()).padStart(2, '0');
    const hour = String(date.getHours()).padStart(2, '0');

    return `${year}-${month}-${day}:${hour}`;
  };

  const get_color_by_name = (name) => {

    const item = colorManager.find(item => item.container === name);
    return item.value;
  };


  


  useEffect(() => {
    console.log("song");
    const fetchData = async () => {
        try {
            setIsLoading(true);
            setLoadingText("loading");
            const dataToSend = {
              page:1,
              per_page:10,
              question_title:"",
              question_code:"",
              difficulty_level:"",
            };
            const data = await requestUrl(dataToSend, "get_problem_list",t,showSnackbar);
            if(data.status === "success"){
              console.log(data.data);
              

              setTableData(data.data);
            }
            
            
          } catch (error) {
              console.error("获取数据时出错:", error);
          } finally {
            setIsLoading(false);
          }
    };

    fetchData(); 
  }, []);


  useEffect(() => {
    const fetchData = async () => {
        try {
            setIsLoading(true);
            setLoadingText("loading");
            const dataToSend = {
              id:0,
            };
            const data = await requestUrl(dataToSend, "get_homeworks_for_teacher",t,showSnackbar);
            if(data.status === "success"){
              console.log(data.data);
              setTableHomeWorkData(data.data);
            }
            
            
          } catch (error) {
              console.error("获取数据时出错:", error);
          } finally {
            setIsLoading(false);
          }
    };

    fetchData(); 
  }, []);


  const handleChangePage = async (event, newPage) => {
    setCurrentPage(newPage);
    //loadDataCommonChatSessionList(newPage);
  };
  
  //点击某个学生的作业情况
  const handleClickRowGradeHW = async (row) => {
    setShowProblemDetail(true);
  }


  //点击家庭作业的数据行
  const handleClickRow = async (row) => {
    try{
      setIsLoading(true);
      setLoadingText("loading");
      const dataToSend = {
        id:row.id,
      };
      const data = await requestUrl(dataToSend, "get_students_homework_detail_by_hid",t,showSnackbar);
      if(data.status === "success"){
        console.log(data.data);
        setTableGradeHomeworkData(data.data);
        setIsLoading(false);
      }
    } 
    catch (error) {
        console.error("获取数据时出错:", error);
        setIsLoading(false);
    } 
    finally {
      setIsLoading(false);
    }
  }




  //点击题库列表
  const handleClickRowProblemList = async (row) => {
  
    setShowProblemAddEdit(true);

  }


  const handleClosePAddEdit = async() =>{
    setShowProblemAddEdit(false);
  }
  const handleCloseConfigHomework = async() =>{
    setShowConfigHomework(false);
  }


  const handleAddConfigHomework = async() =>{
    setShowConfigHomework(true);
  }

  const handleCloseHomework = async() =>{
    setShowConfigHomework(false);
  }
  


 // 处理选择变化
 const handleChange = (event) => {
   setSelectedValue(event.target.value);
   console.log('Selected value:', event.target.value);
 };


const mockData = [
  { id: 1, label: '水题' },
  { id: 2, label: '入门' },
  { id: 3, label: 'CSP-J' },
  { id: 4, label: 'CSP-S' },
  { id: 5, label: 'NOIP' },
  { id: 6, label: 'NOI' },
];

const [searchText,setSearchText] = useState('');

const handleChangeSearchText = (event) => {
  setSearchText(event.target.value);
  
}
const handleAddProblem = (event) => {
  setAddEditProblem("Add");
  setTimeout(() => {
    setShowProblemAddEdit(true);
  }, 0);
}
const handleDisplayProblemList = () => {

  setShowHomeWork(false);
  setShowProblemList(true);
}


const handdleDdisplayHomeWorkModule = () => {

  setShowHomeWork(true);
  setShowProblemList(false);
}

const handleCloseDetail = async() =>{
  setShowProblemDetail(false);
}



const [showProblemList,setShowProblemList] = useState(false);
const [showHomeWork,setShowHomeWork] = useState(true);



    return (
      <div 
        style={{ 
          backgroundColor:get_color_by_name('pop-window-bg'), 
          height: '100%', display: 'flex', 
          flexDirection: 'column',
          border: `1px solid ${get_color_by_name('pop-window-border-color')}`, }}>
      {/* Top toolbar */}
      <div style={{ 
       
          height: '3em', 
          backgroundColor:get_color_by_name('message-item-bg'), 
          padding: '0.5em', 
          boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)', 
          display: 'flex', // 使子元素水平排列
          alignItems: 'center', // 垂直方向对齐
          gap: '20px' // 控件之间的间隔为 20px
          
        }}>
          <CustomButton sx={{ marginRight: '10px', height: '100%' }} onClick={handleDisplayProblemList}>
        {t('题库列表')}
      </CustomButton>
      <CustomButton sx={{ marginRight: '10px', height: '100%' }} onClick={handleAddProblem}>
        {t('添加题目')}
      </CustomButton>
      <CustomButton sx={{ marginRight: '10px', height: '100%' }} onClick={handleAddConfigHomework}>
        {t('留作业')}
      </CustomButton>
      <CustomButton sx={{ marginRight: '10px', height: '100%' }} onClick={handdleDdisplayHomeWorkModule}>
        {t('作业列表')}
      </CustomButton>
      <FormControl sx={{ minWidth: 120, height: '100%' }}>
        
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={selectedValue}
          onChange={handleChange}
          sx={{ height: '32px' }} // 保证 Select 高度与父容器一致
        >
          {mockData.map((item) => (
            <MenuItem key={item.id} value={item.label}>
              {item.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <TextField
            variant="outlined"
            value={searchText}
            onChange={handleChangeSearchText}
            sx={{
              width:'200px',
              backgroundColor: get_color_by_name('mui-textfield-bg'),
              // Targeting the input element for styling
              '& .MuiInputBase-input': {
                color: get_color_by_name('headline-color'), // Change text color
              },
            }}
          />
      <CustomButton sx={{ marginRight: '10px', height: '100%' }}>
        {t('跳转')}
      </CustomButton>
      <CustomButton sx={{ marginRight: '10px', height: '100%' }}>
        {t('搜索')}
      </CustomButton>
      <CustomButton sx={{ marginRight: '10px', height: '100%' }} onClick={handleAddProblem}>
        {t('添加题目')}
      </CustomButton>
    </div>
      
      {/* Bottom content area */}
      <div style={{ flexGrow: 1,backgroundColor:get_color_by_name('pop-window-bg'), display: 'flex' }}>
        {/* Left section */}
        <div style={{ flexBasis: '40%', backgroundColor:get_color_by_name('message-item-bg'), marginRight: '10px'}}>
        <div>
          {showProblemList && (
            <>
                <TableContainer component={Paper}>
                  <Table>
                    <TableHead>
                      <TableRow sx={{backgroundColor:get_color_by_name('pop-window-bg')}}>
                        <TableCell sx={{width:'150px', fontFamily:'siyuan',color:get_color_by_name('headline-color')}}>{t('problem number')}</TableCell>
                        <TableCell sx={{width:'350px', fontFamily:'siyuan',color:get_color_by_name('headline-color')}}>{t('problem title')}</TableCell>
                        <TableCell sx={{width:'150px', fontFamily:'siyuan',color:get_color_by_name('headline-color')}}>{t('code language')}</TableCell>
                        <TableCell sx={{width:'150px', fontFamily:'siyuan',color:get_color_by_name('headline-color')}}>{t('difficulty level')}</TableCell>
                        <TableCell sx={{width:'150px', fontFamily:'siyuan',color:get_color_by_name('headline-color')}}>{t('difficulty t')}</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                    {tableData.map((row, index) => (
                        <TableRow key={row.id} selected={selectedRowId === row.id}
                          onClick={() => {
                            setSelectedRowId(row.id);
                            setSelectedRowData(row);
                            setAddEditProblem('Edit');
                          }}
                          hover
                          style={{ cursor: 'pointer', backgroundColor: selectedRowId === row.id ? get_color_by_name('mui-button-bg') : get_color_by_name('pop-window-bg') }}
                        >
                          <TableCell
                            sx={{
                              fontFamily: 'siyuan',
                              color: get_color_by_name('mui-textfield-text-color'),
                              textDecoration: 'underline', // 添加下划线
                              cursor: 'pointer' // 鼠标悬停时显示为可点击
                            }}
                            onClick={() => handleClickRowProblemList(row)} // 传递row参数
                            
                          >
                            {row.question_code}
                          </TableCell>
                          <TableCell sx={{fontFamily:'siyuan',color:get_color_by_name('mui-textfield-text-color')}} title={row.question_title} >{row.question_title.length > 20 ? row.question_title.substring(0, 20) + "..." : row.question_title}</TableCell>
                          <TableCell sx={{fontFamily:'siyuan',color:get_color_by_name('mui-textfield-text-color')}}>{row.code_language}</TableCell>
                          
                          <TableCell sx={{fontFamily:'siyuan',color:get_color_by_name('mui-textfield-text-color')}}>{row.difficulty_level}</TableCell>
                          <TableCell sx={{fontFamily:'siyuan',color:get_color_by_name('mui-textfield-text-color')}}>{row.difficulty_t}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                  
                </TableContainer>
                <Pagination
                  sx={{ backgroundColor:get_color_by_name('mui-textfield-bg') , borderRadius: '10px' }}
                  count={totalPagesSession}
                  page={currentPage}
                  onChange={handleChangePage}
                  color="primary"
                />
                </>
          )}

          {showHomeWork && (
            <>
            <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow sx={{backgroundColor:get_color_by_name('pop-window-bg')}}>
                  <TableCell sx={{width:'150px', fontFamily:'siyuan',color:get_color_by_name('headline-color')}}>{t('homework num')}</TableCell>
                  <TableCell sx={{width:'350px', fontFamily:'siyuan',color:get_color_by_name('headline-color')}}>{t('homework name')}</TableCell>
                  <TableCell sx={{width:'150px', fontFamily:'siyuan',color:get_color_by_name('headline-color')}}>{t('create time')}</TableCell>
                  <TableCell sx={{width:'150px', fontFamily:'siyuan',color:get_color_by_name('headline-color')}}>{t('grade name')}</TableCell>
                  
                </TableRow>
              </TableHead>
              <TableBody>
              {tableHomeWorkData.map((row, index) => (
                  <TableRow key={row.id} selected={selectedRowIdHomework === row.id}
                    onClick={() => {
                      setSelectedRowIdHomework(row.id);
                      
                    }}
                    hover
                    style={{ cursor: 'pointer', backgroundColor: selectedRowIdHomework === row.id ? get_color_by_name('mui-button-bg') : get_color_by_name('pop-window-bg') }}
                  >
                    <TableCell
                      sx={{
                        fontFamily: 'siyuan',
                        color: get_color_by_name('mui-textfield-text-color'),
                        textDecoration: 'underline', // 添加下划线
                        cursor: 'pointer' // 鼠标悬停时显示为可点击
                      }}
                      onClick={() => handleClickRow(row)} // 传递row参数
                      
                    >
                      {row.id}
                    </TableCell>
                    <TableCell sx={{fontFamily:'siyuan',color:get_color_by_name('mui-textfield-text-color')}} >{row.homework_name}</TableCell>
                    <TableCell sx={{fontFamily:'siyuan',color:get_color_by_name('mui-textfield-text-color')}}>{row.createtime}</TableCell>
                    <TableCell sx={{fontFamily:'siyuan',color:get_color_by_name('mui-textfield-text-color')}}>{row.grade_name}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            
          </TableContainer>
          
            </>
          )}
        </div>

        </div>
        {/* Right section */}
        <div style={{ flexBasis: '60%', marginLeft:'10px', 
          backgroundColor: get_color_by_name('pop-window-bg'),
          }}>
         <Box
          sx={{
            flexBasis: '60%',
            backgroundColor: get_color_by_name('pop-window-bg'),
            display: 'flex',
            flexWrap: 'wrap',
            height:'100%',
            width:'100%',
          }}
        >
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow sx={{backgroundColor:get_color_by_name('pop-window-bg')}}>
                  <TableCell sx={{width:'150px', fontFamily:'siyuan',color:get_color_by_name('headline-color')}}>{t('student')}</TableCell>
                  <TableCell sx={{width:'350px', fontFamily:'siyuan',color:get_color_by_name('headline-color')}}>{t('code language')}</TableCell>
                  <TableCell sx={{width:'150px', fontFamily:'siyuan',color:get_color_by_name('headline-color')}}>{t('run time')}</TableCell>
                  <TableCell sx={{width:'150px', fontFamily:'siyuan',color:get_color_by_name('headline-color')}}>{t('run memory')}</TableCell>
                  <TableCell sx={{width:'150px', fontFamily:'siyuan',color:get_color_by_name('headline-color')}}>{t('isBuildSuccess')}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
              {tableGradeHomeworkData.map((row, index) => (
                  <TableRow key={row.id} selected={selectedRowMyHomeworkId === row.id}
                    onClick={() => {
                      setSelectedRowMyHomeworkId(row.id);
                    }}
                    hover
                    style={{ cursor: 'pointer', backgroundColor: selectedRowMyHomeworkId === row.id ? get_color_by_name('mui-button-bg') : get_color_by_name('pop-window-bg') }}
                  >
                    <TableCell
                      sx={{
                        fontFamily: 'siyuan',
                        color: get_color_by_name('mui-textfield-text-color'),
                        textDecoration: 'underline', // 添加下划线
                        cursor: 'pointer' // 鼠标悬停时显示为可点击
                      }}
                      onClick={() => handleClickRowGradeHW(row)} // 传递row参数
                      
                    >
                      {row.username}
                    </TableCell>
                    <TableCell sx={{fontFamily:'siyuan',color:get_color_by_name('mui-textfield-text-color')}} >{row.code_language}</TableCell>
                    <TableCell sx={{fontFamily:'siyuan',color:get_color_by_name('mui-textfield-text-color')}}>{row.run_time}</TableCell>
                    <TableCell sx={{fontFamily:'siyuan',color:get_color_by_name('mui-textfield-text-color')}}>{row.run_memory}</TableCell>
                    <TableCell sx={{ fontFamily: 'siyuan', color: 'inherit' }}>
                      <Box
                        sx={{
                          display: 'inline-block',
                          padding: '2px 8px',  // 内边距调整矩形的大小
                          borderRadius: '12px',  // 圆角设置
                          backgroundColor: 
                            row.isBuildSuccess === 0 ? 'green'   // 编译成功
                            : row.isBuildSuccess === 1 ? 'red'    // 编译失败
                            : row.isBuildSuccess === 2 ? 'blue'   // 已提交
                            : 'gray',                     // 未提交
                          color: 'white',  // 文字颜色
                        }}
                      >
                        {row.isBuildSuccess === 0 ? '编译成功'
                        : row.isBuildSuccess === 1 ? '编译失败'
                        : row.isBuildSuccess === 2 ? '已提交'
                        : '未提交'}
                      </Box>
                    </TableCell>


                  </TableRow>
                ))}
              </TableBody>
            </Table>
            
          </TableContainer>
        </Box>
        </div>
      </div>



      <Modal
          open={showProblemAddEdit}
          onClose={handleClosePAddEdit}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          
        >
        <div style={{
          height: '100vh',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}>
          <Paper
            style={{
             
              border: `1px solid ${get_color_by_name('pop-window-border-color')}`,
              backgroundColor:get_color_by_name('pop-window-bg'),
              padding: '1em', // Optional: add some padding to the Paper
              width: '95%',  // Optional: adjust the width as needed
              height:'850px'
            }}
          >
            {/* Your content goes here */}
            <AddEditProblem onClose={handleClosePAddEdit} addEdit={addEditProblem} pid={selectedRowId}/>
          </Paper>
        </div>
      </Modal>




      <Modal
          open={showConfigHomework}
          onClose={handleCloseConfigHomework}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          
        >
        <div style={{
          height: '100vh',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}>
          <Paper
            style={{
             
              border: `1px solid ${get_color_by_name('pop-window-border-color')}`,
              backgroundColor:get_color_by_name('pop-window-bg'),
              padding: '1em', // Optional: add some padding to the Paper
              width: '95%',  // Optional: adjust the width as needed
              height:'850px'
            }}
          >
            {/* Your content goes here */}
            <ConfigHomework onClose={handleCloseHomework}/>
          </Paper>
        </div>
      </Modal>



      <Modal
          open={showProblemDetail}
          onClose={handleCloseDetail}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          sx={{zIndex:5555}}
        >
        <div style={{
          height: '100vh',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}>
          <Paper
            style={{
              zIndex: 55545,
              border: `1px solid ${get_color_by_name('pop-window-border-color')}`,
              backgroundColor:get_color_by_name('pop-window-bg'),
              padding: '1em', // Optional: add some padding to the Paper
              width: '95%',  // Optional: adjust the width as needed
              height:'850px'
            }}
          >
            {/* Your content goes here */}
            <ProblemDetail onClose={handleCloseDetail} spid={selectedRowMyHomeworkId} pid={selectedRowId} detailtype="s"/>
          </Paper>
        </div>
      </Modal>
    </div>
    )
};

export default ProblemTeacher;