import React, { useState, useEffect } from 'react';

import { Box, Button, Container, Table, TableBody, TableContainer, TableHead, TableRow, TableCell, Paper, IconButton, Pagination,TextField } from '@mui/material';
import { styled } from '@mui/material/styles';
import LoadingData from '../education/dataloading';
import EditIcon from '@mui/icons-material/Edit';
import { requestUrl } from '../requestUrl';
import ProxyUserM from './ProxyUserM';
import CancelIcon from '@mui/icons-material/Cancel'; // “停用”的图标
import CheckCircleIcon from '@mui/icons-material/CheckCircle'; // “启用”的图标
import { useTranslation } from 'react-i18next';


const StyledTableCell = styled(TableCell)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.common.white,
  fontSize: 16,
}));

const ProxyManage = () => {
    const overlayContainerStyle = {
        position: 'fixed', // 或者 'absolute'，取决于父容器
        top: 0,
        left: 0,
        width: '100vw', // 视口宽度
        height: '100vh', // 视口高度
        backgroundColor: 'rgba(0, 0, 0, 1)', // 半透明背景
        zIndex: 1000, // 确保覆盖其他元素
      };
      const { t } = useTranslation();
  const [users, setUsers] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [userCount,setUserCount] = useState();
  const pageSize = 10; // 每页显示的用户数

  const [loading, setLoading] = useState(false);
  const [loadingText,setLoadingText] = useState('loading');

  const [isShowUserAddEdit,setIsShowUserAddEdit] = useState(false);
  const [selectedUser,setSelectedUser] = useState(null);
  const [isAddEdit,setIsAddEdit] = useState();
  useEffect(() => {
    fetchUsers(1,20);
  }, []);


  // 模拟从服务器获取用户数据
  const fetchUsers = async (pagenum,pagesize) => {
    try {
        setLoading(true);
        setLoadingText("loading");
        const dataToSend = {
            username:'',
        };
        const data = await requestUrl(dataToSend, "get_all_users_proxy");
        
        if(data.status === "success"){
            setUsers(data.data);

            setUserCount(data.usercount);

        }
      } 
      catch (error) {
          console.error("获取数据时出错:", error);
      }
      finally {
        setLoading(false);
      }
    
  };

  useEffect(() => {
    fetchUsers(currentPage,20);
  }, [currentPage]);

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };


  const handleEditClick = async (user) => {
    setSelectedUser(user);
    setIsAddEdit("edit");
    setIsShowUserAddEdit(true);
  }

  const handleCloseAddEdit = async () => {
    setIsShowUserAddEdit(false);
  }
  const handleAddUser = async () => {
    setIsAddEdit("add");
    setIsShowUserAddEdit(true);
  }





  const updateUserFieldById = (id, key, newValue) => {
    // 使用map方法遍历users数组，对每个用户进行处理
    setUsers(users.map(user => {
      // 当找到匹配的用户ID时
      if (user.id === id) {
        // 使用展开运算符复制用户对象，然后更新指定的字段
        return { ...user, [key]: newValue };
      }
      // 对于不需要更新的用户，直接返回原始对象
      return user;
    }));
  };



  const toggleUserStatus = async(userid,status) => {
    
    
    try {
      setLoading(true);
      setLoadingText("loading");
      const dataToSend = {
          status:status,
      };
      const data = await requestUrl(dataToSend, "set_user_status");
      
      if(data.status === "success"){
          
          updateUserFieldById(userid,"status",status);
      }
    } 
    catch (error) {
        console.error("获取数据时出错:", error);
    }
    finally {
      setLoading(false);
    }

  }

  const [searchUserName,setSearchUserName] = useState('');
  const [proxyCode,setProxyCode] = useState('slx111');


  const handleQuery = async () => {
    fetchUsers(1,20);
  }


  const handleInputChange = (e) => {
    setSearchUserName(e.target.value);
  }

  return (
    <div>
    <Container maxWidth="100%" sx={{ mt: 4, mb: 4 }}>
      <Box sx={{ display: 'flex', justifyContent: 'flex-end', mb: 2,backgroundColor:'white',gap:'20px',height:'60px' }}>
        {t('my proxy code:')}
      <TextField
            fullWidth
            label="proxy code"
            name="proxycode"
            value={proxyCode}
            onChange={handleInputChange}
            sx={{ width:'250px',height:'45px',margin:'10px', input: { color: 'black' } }}
          />
      <TextField
            fullWidth
            
            label="username"
            name="username"
            value={searchUserName}
            onChange={handleInputChange}
            sx={{ width:'250px',height:'45px',margin:'10px',input: { color: 'black' } }}
          />
        <Button onClick={handleQuery} variant="contained" color="secondary" sx={{height:'35px',margin:'10px'}}>
          查询
        </Button>
        <Button onClick={handleAddUser} variant="contained" color="primary"  sx={{height:'35px',margin:'10px'}}>
          新增
        </Button>
      </Box>

      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="customized table">
          <TableHead>
            <TableRow>
              {/* Define widths here */}
              <StyledTableCell width="2%">ID</StyledTableCell>
              <StyledTableCell width="7%">用户名</StyledTableCell>
              <StyledTableCell width="5%">billid</StyledTableCell>
              <StyledTableCell width="7%">手机号</StyledTableCell>
              <StyledTableCell width="10%">创建时间</StyledTableCell>
              <StyledTableCell width="5%">使用语言</StyledTableCell>
              <StyledTableCell width="7%">过期时间</StyledTableCell>
              <StyledTableCell width="5%">角色</StyledTableCell>
              <StyledTableCell width="5%">创建人</StyledTableCell>
              <StyledTableCell width="5%">编辑</StyledTableCell>
              <StyledTableCell width="5%">状态</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {users.map((user) => (
              <TableRow key={user.id}>
                {/* Cell contents */}
                <TableCell>{user.id}</TableCell>
                <TableCell>{user.username}</TableCell>
                <TableCell>{user.billing_rule_id}</TableCell>
                <TableCell>{user.phone_number}</TableCell>
                <TableCell>{user.created_at}</TableCell>
                <TableCell>{user.language}</TableCell>
                <TableCell>{user.expirate_time}</TableCell>
                <TableCell>{user.rolename}</TableCell>
                <TableCell>{user.create_user_id}</TableCell>
                <TableCell>
                    <IconButton onClick={() => handleEditClick(user)}>
                  <EditIcon />
                </IconButton></TableCell>
                <TableCell>
              {user.status === 1 ? (
                <Button
                  variant="contained"
                  startIcon={<CancelIcon />}
                  onClick={() => toggleUserStatus(user.id, 0)}
                  style={{ backgroundColor: 'red', color: 'white' }}
                >
                  点击停用
                </Button>
              ) : (
                <Button
                  variant="contained"
                  startIcon={<CheckCircleIcon />}
                  onClick={() => toggleUserStatus(user.id, 1)}
                  style={{ backgroundColor: 'green', color: 'white' }}
                >
                  点击启用
                </Button>
              )}
            </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Pagination
        count={totalPages}
        page={currentPage}
        onChange={handlePageChange}
        sx={{ mt: 2, display: 'flex', justifyContent: 'center' }}
      />
    </Container>

    {isShowUserAddEdit && (
      <div style={overlayContainerStyle}>
        <ProxyUserM isAddEdit={isAddEdit} initialUserData={selectedUser} onClose={handleCloseAddEdit} />
      </div>
    )}

  {loading && (
      <LoadingData text={t(loadingText)}/>
  )}
  </div>
  );
};

export default ProxyManage;
