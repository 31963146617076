

import React, { useState ,useRef,useEffect ,useCallback  } from 'react';
import styled from 'styled-components';

import { useSpring, animated } from '@react-spring/web';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import { Select, MenuItem, FormControl, InputLabel } from '@mui/material';
import 'swiper/css/effect-coverflow';
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper/modules';
import Box from '@mui/material/Box';
import { Swiper, SwiperSlide } from 'swiper/react';
import LoadingData from '../dataloading';
import { useTranslation } from 'react-i18next';
import PictureBook from '../PictureBookDetail/PicktureBook';
import { requestUrl } from '../../requestUrl';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import { EffectCoverflow } from 'swiper/modules';
import './PbMain.css';
import { getHostNameWeb } from '../../utils/urlUtils';
import Stack from '@mui/material/Stack';
import { Pagination as MuiPagination } from '@mui/material';


  const BookContainer = styled.div`
  @media (min-width: 1024px) {
    height: 80%;
  }
  @media (max-width: 1023px) and (min-width: 768px) {
    height: 85%;
  }
  @media (max-width: 767px) {
    height: 80%;
  }
  width: auto;
  position: relative;
`;

const StyledButton = styled(Button)`
  margin: 0 5px;
`;


const Page = styled.div`
  width: 1024px;
  max-width: 100%;
  height: 60vh;
  max-height: 80vh;
  object-fit: contain;
  margin: auto;
  display: block;
  backface-visibility: hidden;
  transform-origin: bottom right;

  position: relative;
`;

const PbMain = () => {

  {/* 
  const [grade, setGrade] = useState(() => {
    const savedGrade = localStorage.getItem('grade');
    return savedGrade ? savedGrade : '';
  });
*/}
const { t } = useTranslation();
  const [grade, setGrade] = useState(0);
  const [loading, setLoading] = useState(false);
  const [loadingText,setLoadingText] = useState('loading');

  const [showOverlay, setShowOverlay] = useState(false);

  const [pictureBookDetail,setPictureBookDetail] =useState([]);
  const [selectedPB_music_url,setSelectedPB_music_url] = useState();
  const [selectedSummary,setSelectedSummary] = useState();
  const [selectedSummaryEn,setSelectedSummaryEn] = useState();
  const [selectedPicturebookId,setSelectedPicturebookId] = useState(0);
  const [selectedTitle_local,setSelectedTitle_local] = useState('');
  const [selectedTitle_en,setSelectedTitle_en] = useState('');
  const [storyList, setStoryList] = useState([]);
  const [activeIndex, setActiveIndex] = useState(0);
  
  const [totalPages,setTotalPages] = useState(0);
  const [totalRecords,setTotalRecords] = useState(0);

  const handleOverlayToggle = async (id) => {
    try {
        setLoading(true);
        setSelectedPicturebookId(id);
        setLoadingText("loading");
        const dataToSend = {
            pbid: id,

        };
        const data = await requestUrl(dataToSend, "get_story_detail_by_pbid");
        
        if(data.status === "success"){
          setPictureBookDetail(data.data);
          
          const record = storyList.find(item => item.id === id);
          setSelectedTitle_local(record.title_local_lang);
          setSelectedTitle_en(record.title_local_en);
          setSelectedSummary(record.summarize);
          setSelectedPB_music_url(record.bg_music_path);
          setSelectedSummaryEn(record.summarize_en);
         
        }
    } catch (error) {
        console.error("获取数据时出错:", error);
    } finally {
      setLoading(false);
    }

    setShowOverlay(!showOverlay);
  };
  
  useEffect(() => {
    // 当grade状态更新时，保存到localStorage
    localStorage.setItem('pbgrade', grade);
  }, [grade]);


  useEffect(() => {
    // 当grade状态更新时，保存到localStorage
    let ggg = localStorage.getItem('pbgrade', grade);
    if (!ggg) {
      setGrade(ggg);
    }

  }, []);


  const [pagenum,setPagenum] = useState(1);

  //下拉
  const handleChange = async (event) => {
    setGrade(event.target.value);
    let gra = event.target.value;
    loadStoryList(gra,1,10);
  };


  const loadStoryList = async (grade,pagenum,pagesize) => {
    try {
      setLoading(true);
      setLoadingText("loading");
      const dataToSend = {
          grade: grade,
          page: pagenum,
          pagesize: pagesize,
      };
      const data = await requestUrl(dataToSend, "get_picturebook_list");
      
      if(data.status === "success"){
        setStoryList(data.data);
        if(pagenum === 1){
          setTotalPages(data.total_pages);
          setTotalRecords(data.total_count);
        }
      }
    } catch (error) {
        console.error("获取数据时出错:", error);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    const fetchData = async () => {
        try {
          setLoading(true);
            setLoadingText("loading");
            const dataToSend = {
                grade: 0,
                page: 1,
                pagesize: 10,
            };
            const data = await requestUrl(dataToSend, "get_picturebook_list");
            if(data.status === "success"){
              console.log(JSON.stringify(data.data));
              setStoryList(data.data);
              setTotalPages(data.total_pages);
            setTotalRecords(data.total_count);
            }
        } catch (error) {
            console.error("获取数据时出错:", error);
        } finally {
          setLoading(false);
        }
    };
    fetchData();
  }, []);



  const handleSlideChange = useCallback((swiper) => {
    // 设置当前活动幻灯片的索引
    setActiveIndex(swiper.activeIndex);
  }, []);

  const handleClick = useCallback((id) => {
    // 查找当前活动幻灯片的id
    const activeSlideId = storyList[activeIndex].id;
  
    if (id === activeSlideId) {
      // 当前幻灯片是正中间的幻灯片时触发的事件
      handleOverlayToggle(id);
      console.log(`Clicked slide with id ${id}`);
    }
  }, [activeIndex, storyList]);

  
  const onCloseStoryDetail = () => {
    setShowOverlay(false);
  };
  const overlayContainerStyle = {
    position: 'fixed', // 或者 'absolute'，取决于父容器
    top: 0,
    left: 0,
    width: '100vw', // 视口宽度
    height: '100vh', // 视口高度
    backgroundColor: 'rgba(0, 0, 0, 1)', // 半透明背景
    zIndex: 1000, // 确保覆盖其他元素
  };
  
  const handlePageChange = (event, value) => {
    // 处理分页逻辑
    loadStoryList(0,value,10)
    console.log(`当前页面: ${value}`);
  };


  //<PictureBook title={'小马过河的故事'} pages={images} musicurl={musicbgurl} />
  return (
    <>
    <BookContainer>
      <Box sx={{
        display: 'flex',
        justifyContent: 'center', // 这一行使内容水平居中
        marginBottom: 2,
        marginTop: '20px'
      }}>
        
        <Select
          labelId="grade-selector-label"
          id="grade-selector"
          value={grade}
          label="Grade"
          onChange={handleChange}
          // 添加自定义样式以使其更加卡通化和鲜艳
          sx={{ 
            fontSize:'14px',
            backgroundColor: '#da6709', // 示例背景色
            color: 'white', // 示例文字色
            height: '30px', // 设置高度为40px
            // 在此添加更多样式
          }}
        >
        {Array.from({ length: 13 }, (_, i) => (
          <MenuItem value={i} key={i}>
            {i === 0 ? '不限制' : `${i} Grade`}
          </MenuItem>
        ))}
        </Select>
      
        

        {/* 更多按钮 */}
      </Box>

      <Box sx={{
        display: 'flex',
        justifyContent: 'center', // 这一行使内容水平居中
        marginBottom: 2,
        marginTop: '20px'
      }}>
      <Page>
        <Swiper
          onSlideChange={handleSlideChange}
          effect={'coverflow'}
          grabCursor={true}
          centeredSlides={true}
          slidesPerView={'auto'}
          coverflowEffect={{
            rotate: 50,
            stretch: 0,
            depth: 100,
            modifier: 1,
            slideShadows: true,
          }}
          pagination={true}
          modules={[EffectCoverflow, Pagination]}
          className="mySwiper"
        >
        {storyList.map((image, index) => (
          <SwiperSlide key={index} style={{ position: 'relative', textAlign: 'center' }} onClick={() => handleClick(image.id)}>
            <img src={getHostNameWeb() + "/" + image.thumbnail_path} alt={image.title_local_lang} style={{ width: '100%' }} />
            <div style={{
              position: 'absolute',
              bottom: 0,
              left: 0,
              width: '100%',
              backgroundColor: 'rgba(0, 0, 0, 0.5)', // 半透明背景
              color: 'white', // 文字颜色
              padding: '10px' // 内边距
            }}>
              {image.title_local_lang} - {image.title_local_en}
            </div>
          </SwiperSlide>
      
        ))}
      </Swiper>
      </Page>

      </Box>
    
      <Box sx={{
        display: 'flex',
        flexDirection: 'column', // 设置布局方向为垂直
        alignItems: 'center', // 水平居中子元素
        marginBottom: 2,
        marginTop: '20px',
        color:'white'
      }}>
        

        <span style={{ marginBottom: '30px' }}>绘本数量:{totalRecords}</span> {/* 文本下方添加间隔 */}
      <Stack spacing={2}>
          <MuiPagination 
          onChange={handlePageChange}
            count={totalPages} // 总页数
            size="large" // 大小为 large
            siblingCount={1} // 当前页码两侧显示的页码数
            boundaryCount={1} // 开始和结束处显示的页码数
            showFirstButton // 显示第一页按钮
            showLastButton // 显示最后一页按钮
          />
        </Stack>
      </Box>
    </BookContainer>
    {loading && (
      <LoadingData text={t(loadingText)}/>
    )}

    {showOverlay && (
      <div style={overlayContainerStyle}>
        <PictureBook 
        title_local={selectedTitle_local}
        title_en={selectedTitle_en}
        pages={pictureBookDetail} 
        musicurl={selectedPB_music_url} 
        onClose={onCloseStoryDetail} 
        summary={selectedSummary} 
        summary_en={selectedSummaryEn}
        picturebookId={selectedPicturebookId}
        />
      </div>
    )}
    </>
  );
};

export default PbMain;