import React, { useRef, useState, useEffect } from 'react';
import { IconButton, Dialog, DialogTitle, DialogContent, Slider, Box } from '@mui/material';
import TuneIcon from '@mui/icons-material/Tune'; // 设置图标
import DeleteIcon from '@mui/icons-material/Delete'; // 清除图标
import UndoIcon from '@mui/icons-material/Undo'; // 撤销图标
import RedoIcon from '@mui/icons-material/Redo'; // 重做图标
import EraserIcon from '@mui/icons-material/DeleteSweep'; // 橡皮擦图标
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { InlineMath, BlockMath } from 'react-katex';
import 'katex/dist/katex.min.css';
import SvgIcon from '@mui/material/SvgIcon';
import { styled } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
// 假设你有一个图标来用在IconButton中，例如用Material Icons的Icon
import ContentPasteIcon from '@mui/icons-material/ContentPaste';
import ReactMarkdown from 'react-markdown';
import remarkMath from 'remark-math';
import rehypeKatex from 'rehype-katex';
import 'katex/dist/katex.min.css';
import rehypeRaw from 'rehype-raw';
import { CrisisAlert } from '@mui/icons-material';
import LoadingData from '../dataloading';
import { getHostName } from '../../utils/urlUtils';
import CustomButton from '../../components/CustomButton';

const HandOcr = ({onClose}) => {
  const { t } = useTranslation();
  const canvasRef = useRef(null);
  const [isDrawing, setIsDrawing] = useState(false);
  const [lineWidth, setLineWidth] = useState(5); // 默认线条粗细
  const [open, setOpen] = useState(false); // 控制弹出框开关状态
  const [history, setHistory] = useState([]); // 绘制历史记录
  const [redoHistory, setRedoHistory] = useState([]); // 撤销的历史记录


  const [loadingText,setLoadingText] = useState(false);
  const [isLoading, setIsLoading] = useState(false);


  useEffect(() => {
      saveCanvasState(); // 初始时保存画布状态
  }, []);



  const startDrawing = (e) => {
    const { offsetX, offsetY } = getCoordinates(e);
    const ctx = canvasRef.current.getContext('2d');
    ctx.lineWidth = lineWidth;
    ctx.beginPath();
    ctx.moveTo(offsetX, offsetY);
    setIsDrawing(true);
  };
  
  const draw = (e) => {
    if (!isDrawing) {
      return;
    }
    const { offsetX, offsetY } = getCoordinates(e);
    const ctx = canvasRef.current.getContext('2d');
    
    ctx.globalCompositeOperation = isErasing ? 'destination-out' : 'source-over';
    ctx.lineWidth = isErasing ? eraserWidth : lineWidth;
  
    ctx.lineTo(offsetX, offsetY);
    ctx.stroke();
  };
  
  const endDrawing = () => {
    const ctx = canvasRef.current.getContext('2d');
    ctx.closePath();
    setIsDrawing(false);
    saveCanvasState();
    ctx.globalCompositeOperation = 'source-over';
  };
  
  // 新增：获取触摸或鼠标事件的坐标
  const getCoordinates = (event) => {
    if (event.touches && event.touches.length > 0) {
      const rect = canvasRef.current.getBoundingClientRect();
      return {
        offsetX: event.touches[0].clientX - rect.left,
        offsetY: event.touches[0].clientY - rect.top
      };
    } else {
      return {
        offsetX: event.nativeEvent.offsetX,
        offsetY: event.nativeEvent.offsetY
      };
    }
  };



  const handleClickOpen = () => {
      setOpen(true);
  };

  const handleClose = () => {
      setOpen(false);
  };

  const handleSliderChange = (event, newValue) => {
      setLineWidth(newValue);
  };

  const clearCanvas = () => {
      const ctx = canvasRef.current.getContext('2d');
      ctx.clearRect(0, 0, canvasRef.current.width, canvasRef.current.height);
  };

  const saveCanvasState = () => {
    const canvas = canvasRef.current;
    const imageData = canvas.toDataURL();
    // 检查新的状态是否与历史记录中最后一个状态不同
    if (history.length === 0 || history[history.length - 1] !== imageData) {
        setHistory(prev => [...prev, imageData]);
        setRedoHistory([]); // 每次保存时清空重做历史
    }
  };
  

  const undo = () => {
      if (history.length > 1) { // 保留初始状态，避免全部删除
          const newHistory = history.slice(0, history.length - 1);
          const lastState = history[history.length - 2];
          setRedoHistory(prev => [...prev, history[history.length - 1]]);
          setHistory(newHistory);
          restoreCanvasState(lastState);
      }
  };

  const redo = () => {
      if (redoHistory.length > 0) {
          const lastState = redoHistory.pop();
          setHistory(prev => [...prev, lastState]);
          restoreCanvasState(lastState);
      }
  };

  const restoreCanvasState = (state) => {
      const canvas = canvasRef.current;
      const ctx = canvas.getContext('2d');
      const image = new Image();
      image.onload = () => {
          clearCanvas();
          ctx.drawImage(image, 0, 0, canvas.width, canvas.height);
      };
      image.src = state;
  };






  const [isErasing, setIsErasing] = useState(false); // 橡皮擦模式状态
  const toggleEraser = () => {
    setIsErasing(!isErasing);
  };

  const [eraserWidth, setEraserWidth] = useState(5); // 橡皮擦默认粗细
  const [openEraserDialog, setOpenEraserDialog] = useState(false); // 控制橡皮擦粗细调整对话框的开关
  
  const handleEraserWidthChange = (event, newValue) => {
      setEraserWidth(newValue);
  };
  
  const toggleEraserDialog = () => {
      setOpenEraserDialog(!openEraserDialog);
  };








  const [text, setText] = useState('一元二次函数$y=ax^2$');
  const handleChange = (event) => {
    setText(event.target.value);
  };

  const pasteFromClipboard = async () => {
    const text = await navigator.clipboard.readText();
    setText(currentText => currentText + text);
  };

  const renderContent = () => {
    const parts = text.split(/(\$\$?[^$]+\$\$?)/).filter(Boolean);
    return parts.map((part, index) => {
      if (part.startsWith('$') && part.endsWith('$')) {
        const cleanPart = part.replace(/^\$+|\$+$/g, '');
        return part.startsWith('$$')
          ? <BlockMath key={index}>{cleanPart}</BlockMath>
          : <InlineMath key={index}>{cleanPart}</InlineMath>;
      }
      return <span key={index}>{part}</span>;
    });
  };




// 自定义按钮样式
const CustomIconButton = styled(IconButton)({
  backgroundColor: '#f5f5f5', // 乳白色背景色
  '&:hover': {
    backgroundColor: '#eee', // 鼠标悬停时的背景色
  },
  boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2)', // 3D效果
  color: 'blue', // 图标颜色
});

// 自定义图标
const CustomIcon = (props) => (
  <SvgIcon {...props}>
    {/* 这里替换成你自己的SVG路径 */}
    <path d="M10 20v-6h4v6h5v-8h3L12 3 2 12h3v8z" />
  </SvgIcon>
);


const handleCloseThis = () => {
  onClose(text);
  
}

const handleBeginOcr = async () => {

  // 检查绘画次数是否不低于五次
  if (history.length < 6) { // 因为初始状态也被保存了，所以应该是小于6
    alert("绘画次数必须不低于五次才能进行OCR识别。");
    return; // 提前退出函数
  }



  if (canvasRef.current) {
    const canvas = canvasRef.current;
    const base64Image = canvas.toDataURL('image/png'); // 获取图片的DataURL
    console.log(base64Image); // 可以打印看看或者发送到后端

    try 
      {
        setLoadingText(t('parsing'));
        setIsLoading(true);
        const token = localStorage.getItem("access_token");
        let url = getHostName() + '/getmathocr';
        const response = await fetch(url, {
            method: "POST",
            headers: {'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`},
            body:JSON.stringify({ocrType:"Math_All_page", image:base64Image})
            });

        if (response.ok) {
            const data = await response.json();
            const mathtext = data.message;
            let newtext = text + "\n----------" + mathtext;
            setText(newtext);
            setIsLoading(false);
        }
        else {
            console.error("Error fetching reply:", response.statusText);
            setIsLoading(false);
        }
      }
    catch (error) {
      console.error("There was an error fetching the reply:", error);
      setIsLoading(false);
    }
  }
  
}
// 动画和相关样式定义
const blinkAnimation = `
@keyframes blink-animation {
  0% { opacity: 1; }
  50% { opacity: 0; }
  100% { opacity: 1; }
}
`;

  return (
    <Box style={{ display: 'flex', flexDirection: 'column',  backgroundColor: 'white' }}>
      <Box style={{ display: 'flex', justifyContent: 'center', marginBottom: '10px' }}>
          <IconButton color="primary" onClick={handleClickOpen}><TuneIcon /></IconButton>
          <IconButton color="secondary" onClick={() => { clearCanvas(); saveCanvasState(); }}><DeleteIcon /></IconButton>
          <IconButton color="default" onClick={undo}><UndoIcon /></IconButton>
          <IconButton color="default" onClick={redo}><RedoIcon /></IconButton>
          <IconButton color={isErasing ? "secondary" : "default"} onClick={toggleEraser}><EraserIcon /></IconButton> {/* 橡皮擦按钮 */}
          <style>
            {blinkAnimation}
          </style>
          <IconButton color="primary" onClick={toggleEraserDialog}><TuneIcon /></IconButton>
          <IconButton 
            color="primary" 
            onClick={handleBeginOcr} 
            sx={{
              '& .MuiSvgIcon-root': {
                color: 'skublue', // 设置图标颜色为红色
                animation: 'blink-animation 1s infinite' // 应用闪烁动画
              }
            }}
          >
        <CrisisAlert />
      </IconButton>
          
          
      </Box>
     <canvas
        ref={canvasRef}
        width={800}
        height={500}
        onMouseDown={startDrawing}
        onMouseMove={draw}
        onMouseUp={endDrawing}
        onMouseOut={endDrawing}
        onTouchStart={startDrawing}
        onTouchMove={draw}
        onTouchEnd={endDrawing}
        style={{ border: '1px solid black' }}
      />

<Box
  sx={{
    display: 'flex',
    flexDirection: 'column',
    gap: '5px',
    alignItems: 'center',
    boxSizing: 'border-box', // 使height包括padding和border
  }}
>
  <TextField
    multiline
    rows={1}
    variant="outlined"
    value={text}
    onChange={handleChange}
    sx={{
      width: '100%',
     
    }}
  />
  <div style={{width:'100%',height:'35px', backgroundColor:'#2a3d66',borderRadius:'5px'}}>
    <ReactMarkdown
      children={text}
      remarkPlugins={[remarkMath]}
      rehypePlugins={[rehypeRaw, rehypeKatex]}
    />
 </div>
</Box>

      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          gap: '10px', // 在按钮之间创建20px的间隙
          marginTop:'10px',
        }}
      >
       
        <CustomButton onClick={handleCloseThis}>
          {t('ok')}
        </CustomButton>
      </Box>
  


      <Dialog open={open} onClose={handleClose} style={{ zIndex: 99999 }}>
          <DialogTitle>Choose Line Width</DialogTitle>
          <DialogContent>
              <Slider
                  aria-label="Line Width"
                  value={lineWidth}
                  onChange={handleSliderChange}
                  step={1}
                  marks
                  min={1}
                  max={20}
                  valueLabelDisplay="auto"
              />
          </DialogContent>
      </Dialog>

      <Dialog open={openEraserDialog} onClose={toggleEraserDialog} style={{ zIndex: 99999 }}>
        <DialogTitle>Adjust Eraser Width</DialogTitle>
        <DialogContent>
            <Slider
                aria-label="Eraser Width"
                value={eraserWidth}
                onChange={handleEraserWidthChange}
                step={1}
                marks
                min={1}
                max={50} // 根据需要调整最大值
                valueLabelDisplay="auto"
            />
        </DialogContent>
    </Dialog>

    {isLoading && (
        <LoadingData text={t(loadingText)}/>
      )}
    </Box>
  );
};

export default HandOcr;
