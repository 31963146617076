
import { ManageAccountsSharp, TravelExplore,  Home, CollectionsBookmark, QuestionAnswerOutlined, PsychologyAltRounded, PushPinRounded, FamilyRestroomRounded, FaceRounded } from '@mui/icons-material';
import AutoStoriesIcon from '@mui/icons-material/AutoStories';
import { createSlice } from '@reduxjs/toolkit'
import ConnectedTvIcon from '@mui/icons-material/ConnectedTv';
import ImportantDevicesIcon from '@mui/icons-material/ImportantDevices';
export const user = createSlice({
  name: 'user',
  initialState: {
    userInfo: "",
    lang:'en',
    languageOpt: {
      'en': 'english',
      'zh': '简体中文',
      'in': 'indonesian',
      'ko': 'korean',
      'ja': 'japanese',
      'ru': 'russian',
      'fr': 'french',
      'th': 'thai',
      'ma': 'malay',
      'de': 'german',
      'vi': 'vietnamese',
      'uk': 'ukrainian'
    },
    routeList: [
      // 出现在菜单中
      { path: '/default', icon: Home, name: 'home' },
      { path: '/mainstudy', icon: CollectionsBookmark, name: 'subject' },
      { path: '/askanything', icon: QuestionAnswerOutlined, name: 'Knowledge Base' },
      { path: '/mainstudyfeynman', icon: PsychologyAltRounded, name: 'Feynman Learning' },
      { path: '/science', icon: ConnectedTvIcon, name: 'science' },
      { path: '/myoi', icon: ConnectedTvIcon, name: 'myoi' },
      { path: '/publicdoc', icon: ConnectedTvIcon, name: 'public doc' },
      { path: '/algorithmbook', icon: ImportantDevicesIcon, name: 'algorithm book' },
      { path: '/algorithm', icon: ImportantDevicesIcon, name: 'algorithm visualizer' },
      { path: '/pbmain', icon: AutoStoriesIcon, name: 'picture book' },
      { path: '/familyhead', icon: FamilyRestroomRounded, name: 'family head' },
      { path: '/usersetting', icon: ManageAccountsSharp, name: 'user setting' },
      { path: '/privacy', icon: '', name: 'Privacy' },
      { path: '/support', icon: '', name: 'Support' },
      
     

      // 不出现在菜单isHide 控制\
     
      { path: '/interactivecourseware', icon: AutoStoriesIcon, name: 'interactive courseware',isHide: true },
      { path: '/spoken', icon: TravelExplore, name: 'Spoken English',isHide: true },
      { path: '/settings', icon: '', name: '', isHide: true },
      { path: '/conversation', icon: '', name: '', isHide: true },
      { path: '/shared', icon: '', name: '', isHide: true },
      { path: '/create', icon: '', name: '', isHide: true }, 
      { path: '/delete', icon: '', name: '', isHide: true },
      { path: '/uploadfile', icon: '', name: '', isHide: true },
      { path: '/login', icon: '', name: '', isHide: true },
      { path: '/myorder', icon: '', name: '', isHide: true },
      { path: '/testlatex', icon: '', name: '', isHide: true },
      { path: '/test', icon: '', name: 'test', isHide: true },
      { path: '/testgeo', icon: '', name: 'testgeo', isHide: true },
      { path: '/admin', icon: '', name: 'admin' , isHide: true},
      { path: '/student', icon: FaceRounded, name: 'growing pains', isHide: true },
      { path: '/teacher', icon: ConnectedTvIcon, name: 'teacher',isHide: true  },
      { path: '/bigcleverness', icon: '', name: 'big cleverness' ,isHide: true},
    ]
  },
  reducers: {
    getUserInfo: state => {},
    setUserInfo: state => {
      localStorage.setItem('user', state)
    },
    changeLanguage: (state,actions) => {
      state.lang = actions.payload
    },
  }
})

export const { getUserInfo, setUserInfo,changeLanguage } = user.actions

export default user.reducer