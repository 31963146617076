/**
 * src/pages/Conversation.jsx
 *
 * created by Lynchee on 7/28/23
 */

import React, { useEffect, useState } from 'react';
import CallView from '../components/CallView';
import TextView from '../components/TextView';
import { useNavigate, useLocation } from 'react-router-dom';
import queryString from 'query-string';
import Avatar from '@mui/material/Avatar';
import useAvatarView from '../components/AvatarView';
import { extractEmotionFromPrompt } from '@avatechai/avatars';
import lz from 'lz-string';
import { Button, Grow } from '@mui/material';
import MessageListEng from '../education/ChatWindowEng/MessageListEng';
import styled from 'styled-components';

// TODO: user can access this page only if isConnected.current
const StyledButtonSwitch = styled(Button)`
  width: 100%;
  background-color: #35394A;
  border-color: #1B2134;
  color: white;
  text-transform: none;
  &:hover {
    background-color: #35394A;
    border-color: #617CC2;
  }
`;

const Conversation = ({
  isConnecting,
  isConnected,
  isRecording,
  isPlaying,
  isThinking,
  isResponding,
  audioPlayer,
  handleStopCall,
  handleContinueCall,
  audioQueue,
  audioContextRef,
  audioSourceNodeRef,
  setIsPlaying,
  handleDisconnect,
  isCallView,
  setIsCallView,
  send,
  stopAudioPlayback,
  textAreaValue,
  setTextAreaValue,
  messageInput,
  setMessageInput,
  setUseSearch,
  setUseEchoCancellation,
  callActive,
  startRecording,
  stopRecording,
  setPreferredLanguage,
  selectedCharacter,
  messageId,
  token,
  isTextStreaming,
  sessionId,
  setSelectedCharacter,
  setSelectedModel,
  setSelectedDevice,
  setUseMultiOn,
  connect,
  oneMessageAll,//完整的一条ai回复消息
  oneMessageAllFromUserAudioToText,// 从后端传回来的用户语音消息转录为文本的字符串，app.jsx传入
  chatId,
}) => {
  const navigate = useNavigate();
  const { search } = useLocation();
  const {
    character = '',
    selectedModel = '',
    selectedDevice = '',
    isCallViewParam = '',
    preferredLanguage = '',
    useSearchParam = '',
    useEchoCancellationParam = '',
    useMultiOnParam = '',
  } = queryString.parse(search);
  const isCallViewUrl = isCallViewParam === 'true';
  const useSearch = useSearchParam === 'true';
  const useEchoCancellation = useEchoCancellationParam === 'true';
  const useMultiOn = useMultiOnParam === 'true';
  const message = isTextStreaming ? '' : textAreaValue;
  const [emotion, setEmotion] = useState('');

  const { avatarDisplay, handleFirstInteractionAudio } = useAvatarView(
    selectedCharacter?.avatar_id,
    emotion
  );

  const [showFirstDiv, setShowFirstDiv] = useState(true);
  const [messages, setMessages] = useState([]);
  const [messageInputMLE, setMessageInputMLE] = useState('');
  const [commMethod, setCommMethod] = useState('实时');



  useEffect(() => {
    const emotion = extractEmotionFromPrompt(message);
    if (emotion && emotion.length > 0) setEmotion(emotion);
  }, [message]);


  useEffect(() => {

    const newMessage = { chatid:chatId, messageType: 'S', englishText:oneMessageAllFromUserAudioToText, audioUrl: '', chineseText: '', time: '10:00 AM', avatarUrl: 'stu.jpg' };
    setMessages([...messages, newMessage]);
  }, [oneMessageAllFromUserAudioToText]);

  

  useEffect(() => {
    const newMessage = { chatid:chatId, messageType: 'R', englishText:oneMessageAll, audioUrl: '', chineseText: '', time: '10:00 AM', avatarUrl: 'stu.jpg' };
    setMessages([...messages, newMessage]);
  }, [oneMessageAll]);


  useEffect(() => {
    if (
      character === '' ||
      selectedModel === '' ||
      selectedDevice === '' ||
      isCallViewUrl === '' ||
      preferredLanguage === '' ||
      useSearch === '' ||
      useEchoCancellation === ''
    ) {
      navigate('/');
    }
    const paramSelectedCharacter = JSON.parse(
      lz.decompressFromEncodedURIComponent(character)
    );
    setSelectedCharacter(paramSelectedCharacter);

    setSelectedModel(selectedModel);

    setSelectedDevice(selectedDevice);

    setIsCallView(isCallViewUrl);

    setPreferredLanguage(preferredLanguage);

    setUseSearch(useSearch);

    setUseEchoCancellation(useEchoCancellation);

    setUseMultiOn(useMultiOn);
  }, []);

  useEffect(() => {
    if (!isConnecting.current) {
      const tryConnect = async () => {
        try {
          // requires login if user wants to use gpt4 or claude.
          connect();
        } catch (error) {
          console.error(error);
        }
      };
      tryConnect();
    }

    const handleUnload = event => {
      event.preventDefault();
      navigate('/');
    };
    window.addEventListener('beforeunload', handleUnload);

    // Clean up event listener on component unmount
    return () => window.removeEventListener('beforeunload', handleUnload);
  }, [connect]);

  if (!isConnected.current) {
    return null;
  }



  
  const handledisplayMessList = () => {
    
    setShowFirstDiv(!showFirstDiv);
    
  };


  const onHandleSendMessage = () => {  //textview传递出来的消息发送按钮事件，用于更新聊天列表
    
    const newMessage = { chatid:'1', messageType: 'S', englishText:messageInput, audioUrl: '', chineseText: '你好', time: '10:00 AM', avatarUrl: 'stu.jpg' };
    setMessages([...messages, newMessage]);
  };

  
  return (
    <div className='conversation-page'>
      <div style={{ display: showFirstDiv ? 'block' : 'none' }}>

        {/* we render both views but only display one. */}
        <p className='alert text-white'>
          {isConnected.current && isThinking && isCallView ? (
            <span>{selectedCharacter.name} is thinking...</span>
          ) : isConnected.current && isRecording ? (
            <span className='recording'>Recording</span>
          ) : null}
        </p>

        <div className={`avatar-wrapper ${isPlaying ? 'pulsating-avatar' : ''}`}>
          {selectedCharacter?.avatar_id ? (
            <>{avatarDisplay}</>
          ) : (
            <Avatar
              alt={selectedCharacter.name}
              src={selectedCharacter.image_url}
              sx={{ width: 76, height: 76 }}
            />
          )}
        </div>

        <div
          className='main-screen'
          style={{ display: isCallView ? 'flex' : 'none' }}
        >
          <CallView
            isRecording={isRecording}
            isPlaying={isPlaying}
            isResponding={isResponding}
            audioPlayer={audioPlayer}
            handleStopCall={handleStopCall}
            handleContinueCall={handleContinueCall}
            audioQueue={audioQueue}
            audioContextRef={audioContextRef}
            audioSourceNodeRef={audioSourceNodeRef}
            setIsPlaying={setIsPlaying}
            handleDisconnect={handleDisconnect}
            setIsCallView={setIsCallView}
            sessionId={sessionId}
            handleFirstInteractionAudio={handleFirstInteractionAudio}
          />
        </div>

        <div
          className='main-screen'
          style={{ display: isCallView ? 'none' : 'flex' }}
        >
          <TextView
            selectedCharacter={selectedCharacter}
            send={send}
            isPlaying={isPlaying}
            isThinking={isThinking}
            isResponding={isResponding}
            stopAudioPlayback={stopAudioPlayback}
            textAreaValue={textAreaValue}
            setTextAreaValue={setTextAreaValue}
            messageInput={messageInput}
            setMessageInput={setMessageInput}
            handleDisconnect={handleDisconnect}
            setIsCallView={setIsCallView}
            useSearch={useSearch}
            setUseSearch={setUseSearch}
            callActive={callActive}
            startRecording={startRecording}
            stopRecording={stopRecording}
            preferredLanguage={preferredLanguage}
            setPreferredLanguage={setPreferredLanguage}
            messageId={messageId}
            token={token}
            sessionId={sessionId}
          />
        </div>
      </div>

      <div style={{ display: showFirstDiv ? 'none' : 'block',width:'400px' }}>
        <div style={{ width:'100%',backgroundColor:'#35394A'}}>
          <MessageListEng messages={messages} messageInput={messageInputMLE}/>
        </div>
      </div>

      <StyledButtonSwitch variant="outlined" onClick={handledisplayMessList}>
      列表切换
    </StyledButtonSwitch>


    </div>
  );
};

export default Conversation;
