import React from 'react';

const AlgorithmBook = () => {
  return (
    <div style={{ width: '100%', height: '90vh' }}> {/* 使用100vw和100vh来使组件的尺寸最大 */}
      <iframe title='book' src="https://chentu.online/site" style={{ width: '100%', height: '100%', border: 'none' }}></iframe>
    </div>
  );
};

export default AlgorithmBook;
 