import React, { useState } from 'react';
import { Box, Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, TextField } from '@mui/material';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import {requestUrl} from '../../requestUrl';
import LoadingData from '../../education/dataloading';
// 定义样式组件
const StyledTableContainer = styled(TableContainer)`
  margin: 20px;
  max-width: 800px;
`;

const StyledTableCell = styled(TableCell)`
  font-weight: bold;
`;

const UserConsumeManage = () => {
  const { t } = useTranslation();
  // 使用useState设置startDate的初始值为当前日期
  


  const getCurrentDate = () => {

    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, '0');
    const day = String(today.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  };

  const [dataList,setDataList] = useState([]);

  const [startDate, setStartDate] = useState(getCurrentDate());
  const [endDate, setEndDate] = useState(getCurrentDate());

  const [isLoading, setIsLoading] = useState(false);
  const [loadingText,setLoadingText] = useState(false);

  // 示例数据，替换为实际从后端获取的数据
  const data = [
    { userid: 1, token_waste_total_count: 10, token_waste_total_token: 100, wolframalpha_request_total_count: 5 },
    { userid: 2, token_waste_total_count: 8, token_waste_total_token: 80, wolframalpha_request_total_count: 4 },
    // 更多数据...
  ];

  const handleQuery = async () => {
  
    try {
      setIsLoading(true);
      setLoadingText("loading");
      const dataToSend = {
        startDate:startDate,
        endDate:endDate,
      };
      const data = await requestUrl(dataToSend, "get_user_token_waste");
      
      if(data.status === "success"){
        console.log(JSON.stringify(data.data));
          setDataList(data.data);
         // console.log(JSON.stringify(data.prompts));
      }
    } catch (error) {
        console.error("获取数据时出错:", error);
    } finally {
      setIsLoading(false);
    }

  }



  return (
    <Box sx={{backgroundColor:'white',width:'90%',height:'80%'}}>
      <Box display="flex" justifyContent="flex-start" alignItems="center" m={1}>
       <TextField
          type="date"
          value={startDate}
          onChange={(e) => setStartDate(e.target.value)}
          InputLabelProps={{
            shrink: true
          }}
          
          style={{ marginRight: '20px' }}
        />

      <TextField
        type="date"
        value={endDate}
        onChange={(e) => setEndDate(e.target.value)}
        InputLabelProps={{
          shrink: true
        }}
        
        style={{ marginRight: '20px' }}
      />
        <Button onClick={handleQuery} variant="contained" color="primary" style={{ marginRight: '20px' }}>查询</Button>
      </Box>
      <StyledTableContainer component={Paper}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <StyledTableCell>用户ID</StyledTableCell>
              <StyledTableCell align="right">请求总次数</StyledTableCell>
              <StyledTableCell align="right">消耗总token</StyledTableCell>
              <StyledTableCell align="right">Wolfram请求总次数</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {dataList.map((row) => (
              <TableRow key={row.userid}>
                <TableCell component="th" scope="row">
                  {row.userid}
                </TableCell>
                <TableCell align="right">{row.token_waste_total_count}</TableCell>
                <TableCell align="right">{row.token_waste_total_token}</TableCell>
                <TableCell align="right">{row.wolframalpha_request_total_count}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </StyledTableContainer>

      
      {isLoading && (
        <LoadingData text={t(loadingText)}/>
      )}


    </Box>
  );
};

export default UserConsumeManage;
