import ReactMarkdown from 'react-markdown';
import remarkMath from 'remark-math';
import rehypeKatex from 'rehype-katex';
import 'katex/dist/katex.min.css';
import React, {useEffect, Component ,useRef,useState} from 'react';
import * as $3Dmol from '3dmol';
import * as THREE from 'three';
// Import IO and ChemWidget modules (and their prerequisites) only
import { Kekule } from 'kekule';
import 'kekule/theme/default';
import Matter from 'matter-js';
import { requestUrl } from './requestUrl';
import IconButton from '@mui/material/IconButton';
import { Button as MuiButton } from '@mui/material';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import CloseIcon from '@mui/icons-material/Close';
import FullscreenExitIcon from '@mui/icons-material/FullscreenExit';
import LoopIcon from '@mui/icons-material/Loop'; // 循环播放图标
import StarButton from './components/StarButton/StarButton';
import ImageOCR from './education/ImageOCR/ImageOCR';
import GeoGebraGraph from './education/GeogebraApp/GeoGebraComponent';

Kekule.externalResourceManager.register('three.js', THREE);




function getModalStyle() {
  const width = window.innerWidth;
  const height = window.innerHeight;

  if (width > 768) { // 电脑屏幕
    return {
      position: 'fixed',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      zIndex: 1000,
      display: 'flex',
      flexDirection: 'column',
      width: '800px',
      height: '600px',
      backgroundColor: 'white',
      borderRadius: '10px',
      boxShadow: '0 0 10px rgba(0, 0, 0, 0.25)'
    };
  } else if (width > 480) { // 平板
    return {
      position: 'fixed',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      zIndex: 1000,
      display: 'flex',
      flexDirection: 'column',
      height: `${height / 3}px`,
      width: `${width / 2}px`,
      backgroundColor: 'white',
      borderRadius: '10px',
      boxShadow: '0 0 10px rgba(0, 0, 0, 0.25)'
      // 其他样式保持不变
    };
  } else { // 手机
    return {
      position: 'fixed',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      zIndex: 1000,
      display: 'flex',
      flexDirection: 'column',
      height: `${height / 2}px`,
      width: `${width * 0.9}px`,
      backgroundColor: 'white',
      borderRadius: '10px',
      boxShadow: '0 0 10px rgba(0, 0, 0, 0.25)'
    };
  }
}

const headerStyle = {
  borderRadius: '10px',
  height: '30px',
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'center',
  padding: '5px',
  backgroundColor: 'white', // 可选：确保按钮背景与模态窗口背景一致
  zIndex: 1 // 确保头部在渲染区域之上
};


const iframeStyle = {
  position: 'fixed',  // 使用 fixed 使 iframe 覆盖全屏
  top: 0,            // 从顶部开始
  left: 0,           // 从左侧开始
  zIndex: -1,        // 确保它在其他内容的后面
  width: '100%',     // 宽度为100%
  height: '100%',    // 高度为100%
  border: 'none',    // 无边框
};

const MyComponent = () => {
   const markdownText = '# Your Markdown here\n## Another Heading';
   const physicsContainer = useRef(null);
   const threeDMolViewerRef = useRef(null);

   const [isModalVisible, setModalVisibility] = useState(false);
   const [modalStyle, setModalStyle] = useState(getModalStyle);

   const closeModal = () => setModalVisibility(false);
   const viewerContainerStyle = {
    borderRadius: '10px',
    flexGrow: 1,
    overflow: 'hidden' // 防止内容溢出
  };
  const maximizeModal = () => {
    // ...最大化窗口的逻辑...
  };

  const handleRestore = () => {
    // 处理恢复动作
  };
   



  
  //获取元素周期表
  function widgetzhouqi(){
    var widget = new Kekule.ChemWidget.PeriodicTable(document);
    widget.setEnableSelect(true)
    .setDisplayedComponents(['symbol', 'name', 'atomicNumber'])
    .appendToElem(document.getElementById('div1'));  // append to HTML element
  }

  function getchemi(){

    // set new object in viewer



    var chemViewer = new Kekule.ChemWidget.Viewer(document);
    chemViewer.setDimension('500px', '400px');
    var cmlData = '<cml xmlns="http://www.xml-cml.org/schema"><molecule id="m1"><atomArray><atom id="a2" elementType="C" x2="7.493264658965051" y2="35.58088907877604"/><atom id="a3" elementType="O" x2="8.186084981992602" y2="35.18088907877604"/><atom id="a1" elementType="C" x2="6.800444335937501" y2="35.18088907877604"/></atomArray><bondArray><bond id="b2" order="S" atomRefs2="a2 a3"/><bond id="b1" order="S" atomRefs2="a2 a1"/></bondArray></molecule></cml>';
    var cmldata1 = `<?xml version="1.0" encoding="utf-8"?>
    <cml xmlns="http://www.xml-cml.org/schema" xmlns:convention="http://www.xml-cml.org/convention/" convention="convention:molecular">
      <molecule id="m-1" formalCharge="0" spinMultiplicity="1">
        <atomArray>
          <atom id="a0" elementType="C" formalCharge="0" hydrogenCount="3" x3="3.549285" y3="1.020068" z3="-0.138812"/>
          <atom id="a1" elementType="C" formalCharge="0" hydrogenCount="0" x3="2.190240" y3="0.363086" z3="-0.214068"/>
          <atom id="a2" elementType="C" formalCharge="0" hydrogenCount="1" x3="2.062992" y3="-0.928226" z3="-0.498842"/>
          <atom id="a3" elementType="C" formalCharge="0" hydrogenCount="2" x3="0.750248" y3="-1.635773" z3="-0.587991"/>
          <atom id="a4" elementType="C" formalCharge="0" hydrogenCount="1" x3="-0.175571" y3="-0.853427" z3="0.270314"/>
          <atom id="a5" elementType="C" formalCharge="0" hydrogenCount="0" x3="-1.612442" y3="-1.200358" z3="0.126515"/>
          <atom id="a6" elementType="O" formalCharge="0" hydrogenCount="0" x3="-2.196670" y3="-2.218603" z3="-0.259979"/>
          <atom id="a7" elementType="C" formalCharge="0" hydrogenCount="1" x3="-2.256238" y3="0.052758" z3="0.588824"/>
          <atom id="a8" elementType="C" formalCharge="0" hydrogenCount="0" x3="-1.443710" y3="1.068178" z3="0.336085"/>
          <atom id="a9" elementType="C" formalCharge="0" hydrogenCount="3" x3="-1.696977" y3="2.488621" z3="0.636521"/>
          <atom id="a10" elementType="C" formalCharge="0" hydrogenCount="1" x3="-0.210174" y3="0.566735" z3="-0.312798"/>
          <atom id="a11" elementType="C" formalCharge="0" hydrogenCount="2" x3="1.039017" y3="1.276942" z3="0.054231"/>
        </atomArray>
        <bondArray>
          <bond atomRefs2="a0 a1" id="b0" order="S"/>
          <bond atomRefs2="a1 a2" id="b1" order="D"/>
          <bond atomRefs2="a1 a11" id="b2" order="S"/>
          <bond atomRefs2="a2 a3" id="b3" order="S"/>
          <bond atomRefs2="a3 a4" id="b4" order="S"/>
          <bond atomRefs2="a4 a5" id="b5" order="S"/>
          <bond atomRefs2="a4 a10" id="b6" order="S"/>
          <bond atomRefs2="a5 a6" id="b7" order="D"/>
          <bond atomRefs2="a5 a7" id="b8" order="S"/>
          <bond atomRefs2="a7 a8" id="b9" order="D"/>
          <bond atomRefs2="a8 a9" id="b10" order="S"/>
          <bond atomRefs2="a8 a10" id="b11" order="S"/>
          <bond atomRefs2="a10 a11" id="b12" order="S"/>
        </bondArray>
      </molecule>
    </cml>`;
    var myMolecule = Kekule.IO.loadFormatData(cmldata1, 'cml');

    chemViewer.appendToElem(document.getElementById('div1')).setChemObj(myMolecule);

    // display 2D molecule in skeletal model
    chemViewer.setRenderType(Kekule.Render.RendererType.R2D);
    chemViewer.setMoleculeDisplayType(Kekule.Render.Molecule2DDisplayType.SKELETAL);

    // display 3D molecule in space fill model
    chemViewer.setRenderType(Kekule.Render.RendererType.R3D);
    chemViewer.setMoleculeDisplayType(Kekule.Render.Molecule3DDisplayType.SPACE_FILL);

    chemViewer.setEnableToolbar(true);  // enable the toolbar
    //chemViewer.setEnableToolbar(false);  // disable and hide the toolbar

    chemViewer.setToolbarEvokeModes([
      Kekule.Widget.EvokeMode.EVOKEE_CLICK,
      Kekule.Widget.EvokeMode.EVOKEE_MOUSE_ENTER,
      Kekule.Widget.EvokeMode.EVOKEE_TOUCH
    ]);
    chemViewer.setToolbarRevokeModes([
      Kekule.Widget.EvokeMode.EVOKEE_MOUSE_LEAVE,
      Kekule.Widget.EvokeMode.EVOKER_TIMEOUT
    ]);


  }

  function getchemi2D(){

    // set new object in viewer



    var chemViewer = new Kekule.ChemWidget.Viewer(document);
    chemViewer.setDimension('500px', '400px');
    
    var cmldata1 = `<?xml version="1.0" encoding="utf-8"?>
    <cml xmlns="http://www.xml-cml.org/schema" xmlns:convention="http://www.xml-cml.org/convention/" convention="convention:molecular">
      <molecule id="m-1" formalCharge="0" spinMultiplicity="1">
        <atomArray>
          <atom id="a0" elementType="C" formalCharge="0" hydrogenCount="3" x3="3.549285" y3="1.020068" z3="-0.138812"/>
          <atom id="a1" elementType="C" formalCharge="0" hydrogenCount="0" x3="2.190240" y3="0.363086" z3="-0.214068"/>
          <atom id="a2" elementType="C" formalCharge="0" hydrogenCount="1" x3="2.062992" y3="-0.928226" z3="-0.498842"/>
          <atom id="a3" elementType="C" formalCharge="0" hydrogenCount="2" x3="0.750248" y3="-1.635773" z3="-0.587991"/>
          <atom id="a4" elementType="C" formalCharge="0" hydrogenCount="1" x3="-0.175571" y3="-0.853427" z3="0.270314"/>
          <atom id="a5" elementType="C" formalCharge="0" hydrogenCount="0" x3="-1.612442" y3="-1.200358" z3="0.126515"/>
          <atom id="a6" elementType="O" formalCharge="0" hydrogenCount="0" x3="-2.196670" y3="-2.218603" z3="-0.259979"/>
          <atom id="a7" elementType="C" formalCharge="0" hydrogenCount="1" x3="-2.256238" y3="0.052758" z3="0.588824"/>
          <atom id="a8" elementType="C" formalCharge="0" hydrogenCount="0" x3="-1.443710" y3="1.068178" z3="0.336085"/>
          <atom id="a9" elementType="C" formalCharge="0" hydrogenCount="3" x3="-1.696977" y3="2.488621" z3="0.636521"/>
          <atom id="a10" elementType="C" formalCharge="0" hydrogenCount="1" x3="-0.210174" y3="0.566735" z3="-0.312798"/>
          <atom id="a11" elementType="C" formalCharge="0" hydrogenCount="2" x3="1.039017" y3="1.276942" z3="0.054231"/>
        </atomArray>
        <bondArray>
          <bond atomRefs2="a0 a1" id="b0" order="S"/>
          <bond atomRefs2="a1 a2" id="b1" order="D"/>
          <bond atomRefs2="a1 a11" id="b2" order="S"/>
          <bond atomRefs2="a2 a3" id="b3" order="S"/>
          <bond atomRefs2="a3 a4" id="b4" order="S"/>
          <bond atomRefs2="a4 a5" id="b5" order="S"/>
          <bond atomRefs2="a4 a10" id="b6" order="S"/>
          <bond atomRefs2="a5 a6" id="b7" order="D"/>
          <bond atomRefs2="a5 a7" id="b8" order="S"/>
          <bond atomRefs2="a7 a8" id="b9" order="D"/>
          <bond atomRefs2="a8 a9" id="b10" order="S"/>
          <bond atomRefs2="a8 a10" id="b11" order="S"/>
          <bond atomRefs2="a10 a11" id="b12" order="S"/>
        </bondArray>
      </molecule>
    </cml>`;
    var myMolecule = Kekule.IO.loadFormatData(cmldata1, 'cml');

    chemViewer.appendToElem(document.getElementById('div2d')).setChemObj(myMolecule);

    // display 2D molecule in skeletal model
    chemViewer.setRenderType(Kekule.Render.RendererType.R2D);
    chemViewer.setMoleculeDisplayType(Kekule.Render.Molecule2DDisplayType.SKELETAL);


    chemViewer.setEnableToolbar(false);  // enable the toolbar
    //chemViewer.setEnableToolbar(false);  // disable and hide the toolbar

    chemViewer.setToolbarEvokeModes([
      Kekule.Widget.EvokeMode.EVOKEE_CLICK,
      Kekule.Widget.EvokeMode.EVOKEE_MOUSE_ENTER,
      Kekule.Widget.EvokeMode.EVOKEE_TOUCH
    ]);
    chemViewer.setToolbarRevokeModes([
      Kekule.Widget.EvokeMode.EVOKEE_MOUSE_LEAVE,
      Kekule.Widget.EvokeMode.EVOKER_TIMEOUT
    ]);


  }

  function componentDidMount() {
    const element = document.getElementById('molecule-viewer');
    const viewer = $3Dmol.createViewer(element);
    
    $3Dmol.download("pdb:1MO8",viewer,{multimodel:true, frames:true},function(){
      viewer.setStyle({}, {cartoon:{color:"spectrum"}});
      viewer.render();
    });
  }


  
  function generate3Dmol(){
    const element = document.getElementById('molecule-viewer');
    var config = { backgroundColor: 'green' };
    const viewer = $3Dmol.createViewer(element,config);
    
   

  const h2oXYZ = `
  data_1546383
  loop_
  _publ_author_name
  'El Mendili, Yassine'
  'Bardeau, Jean-Fran\,cois'
  'Randrianantoandro, Nirina'
  'Grasset, Fabien'
  'Greneche, Jean-Marc'
  _publ_section_title
  ;
   Insights into the Mechanism Related to the Phase Transition from
   \a-Fe2O3 to \g-Fe2O3 Nanoparticles Induced by Thermal Treatment and
   Laser Irradiation
  ;
  _journal_issue                   44
  _journal_name_full               'The Journal of Physical Chemistry C'
  _journal_page_first              23785
  _journal_page_last               23792
  _journal_paper_doi               10.1021/jp308418x
  _journal_volume                  116
  _journal_year                    2012
  _chemical_formula_sum            'Fe2 O3'
  _space_group_crystal_system      trigonal
  _space_group_IT_number           167
  _space_group_name_Hall           '-R 3 2"c'
  _space_group_name_H-M_alt        'R -3 c :H'
  _cell_angle_alpha                90
  _cell_angle_beta                 90
  _cell_angle_gamma                120
  _cell_formula_units_Z            6
  _cell_length_a                   5.03
  _cell_length_b                   5.03
  _cell_length_c                   13.75
  _cell_volume                     301.279
  _cod_data_source_file            0000001.cif
  _cod_data_source_block           0000001
  _cod_original_cell_volume        260.6214
  _cod_database_code               1546383
  loop_
  _space_group_symop_id
  _space_group_symop_operation_xyz
  1 x,y,z
  2 -y,x-y,z
  3 -x+y,-x,z
  4 y,x,-z+1/2
  5 -x,-x+y,-z+1/2
  6 x-y,-y,-z+1/2
  7 -x,-y,-z
  8 y,-x+y,-z
  9 x-y,x,-z
  10 -y,-x,z-1/2
  11 x,x-y,z-1/2
  12 -x+y,y,z-1/2
  13 x+2/3,y+1/3,z+1/3
  14 -y+2/3,x-y+1/3,z+1/3
  15 -x+y+2/3,-x+1/3,z+1/3
  16 y+2/3,x+1/3,-z+5/6
  17 -x+2/3,-x+y+1/3,-z+5/6
  18 x-y+2/3,-y+1/3,-z+5/6
  19 -x+2/3,-y+1/3,-z+1/3
  20 y+2/3,-x+y+1/3,-z+1/3
  21 x-y+2/3,x+1/3,-z+1/3
  22 -y+2/3,-x+1/3,z-1/6
  23 x+2/3,x-y+1/3,z-1/6
  24 -x+y+2/3,y+1/3,z-1/6
  25 x+1/3,y+2/3,z+2/3
  26 -y+1/3,x-y+2/3,z+2/3
  27 -x+y+1/3,-x+2/3,z+2/3
  28 y+1/3,x+2/3,-z+7/6
  29 -x+1/3,-x+y+2/3,-z+7/6
  30 x-y+1/3,-y+2/3,-z+7/6
  31 -x+1/3,-y+2/3,-z+2/3
  32 y+1/3,-x+y+2/3,-z+2/3
  33 x-y+1/3,x+2/3,-z+2/3
  34 -y+1/3,-x+2/3,z+1/6
  35 x+1/3,x-y+2/3,z+1/6
  36 -x+y+1/3,y+2/3,z+1/6
  loop_
  _atom_site_label
  _atom_site_type_symbol
  _atom_site_fract_x
  _atom_site_fract_y
  _atom_site_fract_z
  _atom_site_occupancy
  _atom_site_U_iso_or_equiv
  O1 O-2 0 0.356 0.25 1 0.0
  Fe1 Fe+3 0 0 0.3527 1 0.0
  
  `;

  const fe2o3 = `####################################################################### 
  # 
  # This file contains crystal structure data downloaded from the 
  # Cambridge Structural Database (CSD) hosted by the Cambridge 
  # Crystallographic Data Centre (CCDC) in cooperation with FIZ Karlsruhe.
  # 
  # Please note that these data are only for research purposes or private use.
  # For detailed information please see under Terms & Conditions.
  # Full information about CCDC and FIZ Karlsruhe data access policies and 
  # citation guidelines are available at http://www.ccdc.cam.ac.uk/access/V1
  # 
  # Audit and citation data items may have been added by FIZ Karlsruhe. 
  # Please retain this information to preserve the provenance of 
  # this file and to allow appropriate attribution of the data. 
  # 
  #######################################################################
  
  data_eta-Fe2O3_63_9GPa
  _audit_block_doi                 10.25505/fiz.icsd.cc1y2h7r
  _database_code_depnum_ccdc_archive 'CCDC 1789854'
  loop_
  _citation_id
  _citation_doi
  _citation_year
  1 10.1038/ncomms10661 2016
  _audit_update_record             
  ;
  2018-02-23 deposited with the CCDC.	2023-11-16 downloaded from the CCDC.
  ;
  
  
  _audit_creation_method           SHELXL-2014/7
  _shelx_SHELXL_version_number     2014/7
  _chemical_name_systematic        'iron(III) oxide'
  _chemical_name_common            eta-Fe2O3
  _chemical_formula_moiety         'Fe2 O3'
  _chemical_formula_sum            'Fe2 O3'
  _chemical_compound_source        'high-pressure high-temperature synthesis'
  _chemical_formula_weight         159.70
  
  _diffrn_ambient_temperature      296(2)
  _diffrn_ambient_pressure         63900000
  
  loop_
  _atom_type_symbol
  _atom_type_description
  _atom_type_scat_dispersion_real
  _atom_type_scat_dispersion_imag
  _atom_type_scat_source
  O O -0.0001 0.0011 'International Tables Vol C Tables 4.2.6.8 and 6.1.1.4'
  Fe Fe 0.1387 0.2029 'International Tables Vol C Tables 4.2.6.8 and 6.1.1.4'
  
  _space_group_crystal_system      orthorhombic
  _space_group_IT_number           63
  _space_group_name_H-M_alt        'C m c m'
  _space_group_name_Hall           '-C 2c 2'
  
  _shelx_space_group_comment       
  ;
  The symmetry employed for this shelxl refinement is uniquely defined
  by the following loop, which should always be used as a source of
  symmetry information in preference to the above space-group names.
  They are only intended as comments.
  ;
  
  loop_
  _space_group_symop_operation_xyz
  'x, y, z'
  '-x, -y, z+1/2'
  'x, -y, -z'
  '-x, y, -z+1/2'
  'x+1/2, y+1/2, z'
  '-x+1/2, -y+1/2, z+1/2'
  'x+1/2, -y+1/2, -z'
  '-x+1/2, y+1/2, -z+1/2'
  '-x, -y, -z'
  'x, y, -z-1/2'
  '-x, y, z'
  'x, -y, z-1/2'
  '-x+1/2, -y+1/2, -z'
  'x+1/2, y+1/2, -z-1/2'
  '-x+1/2, y+1/2, z'
  'x+1/2, -y+1/2, z-1/2'
  
  _cell_length_a                   2.640(6)
  _cell_length_b                   8.639(9)
  _cell_length_c                   6.414(14)
  _cell_angle_alpha                90
  _cell_angle_beta                 90
  _cell_angle_gamma                90
  _cell_volume                     146.3(5)
  _cell_formula_units_Z            4
  _cell_measurement_temperature    296(2)
  _cell_measurement_reflns_used    42
  _cell_measurement_theta_min      4.0650
  _cell_measurement_theta_max      11.0860
  
  _exptl_crystal_description       prism
  _exptl_crystal_colour            'dark red'
  _exptl_crystal_density_meas      ?
  _exptl_crystal_density_method    ?
  _exptl_crystal_density_diffrn    7.250
  _exptl_crystal_F_000             304
  _exptl_transmission_factor_min   ?
  _exptl_transmission_factor_max   ?
  _exptl_crystal_size_max          0.03
  _exptl_crystal_size_mid          0.02
  _exptl_crystal_size_min          0.005
  _exptl_absorpt_coefficient_mu    2.323
  _shelx_estimated_absorpt_T_min   ?
  _shelx_estimated_absorpt_T_max   ?
  _exptl_absorpt_correction_type   none
  _exptl_absorpt_correction_T_min  ?
  _exptl_absorpt_correction_T_max  ?
  _exptl_absorpt_process_details   ?
  
  _diffrn_radiation_probe          x-ray
  _diffrn_radiation_type           synchrotron
  _diffrn_radiation_wavelength     0.33440
  _diffrn_source                   synchrotron
  _diffrn_source_type              'APS, GSECARS, Beamline 13 IDD'
  _diffrn_radiation_monochromator  graphite
  _diffrn_measurement_device_type  
  ;
  MAR-CrysAlisPro-abstract goniometer: imported MAR images
  ;
  _diffrn_measurement_method       '\w scans'
  _diffrn_detector_area_resol_mean ?
  _diffrn_reflns_number            93
  _diffrn_reflns_av_unetI/netI     0.0494
  _diffrn_reflns_av_R_equivalents  0.0637
  _diffrn_reflns_limit_h_min       -2
  _diffrn_reflns_limit_h_max       2
  _diffrn_reflns_limit_k_min       -9
  _diffrn_reflns_limit_k_max       9
  _diffrn_reflns_limit_l_min       -6
  _diffrn_reflns_limit_l_max       5
  _diffrn_reflns_theta_min         2.675
  _diffrn_reflns_theta_max         11.101
  _diffrn_reflns_theta_full        11.101
  _diffrn_measured_fraction_theta_max 0.697
  _diffrn_measured_fraction_theta_full 0.693
  _diffrn_reflns_Laue_measured_fraction_max 0.697
  _diffrn_reflns_Laue_measured_fraction_full 0.693
  _diffrn_reflns_point_group_measured_fraction_max 0.697
  _diffrn_reflns_point_group_measured_fraction_full 0.693
  _reflns_number_total             53
  _reflns_number_gt                37
  _reflns_threshold_expression     'I > 2\s(I)'
  _reflns_Friedel_coverage         0.000
  _reflns_Friedel_fraction_max     .
  _reflns_Friedel_fraction_full    .
  
  _reflns_special_details          
  ;
   Reflections were merged by SHELXL according to the crystal
   class for the calculation of statistics and refinement.
   
   _reflns_Friedel_fraction is defined as the number of unique
   Friedel pairs measured divided by the number that would be
   possible theoretically, ignoring centric projections and
   systematic absences.
  ;
  
  _computing_data_collection       
  ;
  CrysAlisPro, Agilent Technologies,
  Version 1.171.37.31 (release 14-01-2014 CrysAlis171 .NET)
  (compiled Jan 14 2014,18:38:05)
  ;
  _computing_cell_refinement       
  ;
  CrysAlisPro, Agilent Technologies,
  Version 1.171.37.31 (release 14-01-2014 CrysAlis171 .NET)
  (compiled Jan 14 2014,18:38:05)
  ;
  _computing_data_reduction        
  ;
  CrysAlisPro, Agilent Technologies,
  Version 1.171.37.31 (release 14-01-2014 CrysAlis171 .NET)
  (compiled Jan 14 2014,18:38:05)
  ;
  
  _computing_structure_solution    'SHELXS-97 (Sheldrick, 1990)'
  _computing_structure_refinement  'SHELXL-2014/7 (Sheldrick, 2014)'
  _computing_molecular_graphics    ?
  _computing_publication_material  ?
  _refine_special_details          ?
  _refine_ls_structure_factor_coef Fsqd
  _refine_ls_matrix_type           full
  _refine_ls_weighting_scheme      calc
  _refine_ls_weighting_details     
  'w=1/[\s^2^(Fo^2^)+(0.1162P)^2^+0.6678P] where P=(Fo^2^+2Fc^2^)/3'
  _atom_sites_solution_primary     direct
  _atom_sites_solution_secondary   difmap
  
  _refine_ls_extinction_method     none
  _refine_ls_extinction_coef       .
  _refine_ls_number_reflns         53
  _refine_ls_number_parameters     9
  _refine_ls_number_restraints     0
  _refine_ls_R_factor_all          0.0910
  _refine_ls_R_factor_gt           0.0654
  _refine_ls_wR_factor_ref         0.1806
  _refine_ls_wR_factor_gt          0.1635
  _refine_ls_goodness_of_fit_ref   1.212
  _refine_ls_restrained_S_all      1.212
  _refine_ls_shift/su_max          0.000
  _refine_ls_shift/su_mean         0.000
  
  loop_
  _atom_site_label
  _atom_site_type_symbol
  _atom_site_fract_x
  _atom_site_fract_y
  _atom_site_fract_z
  _atom_site_U_iso_or_equiv
  _atom_site_adp_type
  _atom_site_occupancy
  _atom_site_site_symmetry_order
  _atom_site_calc_flag
  _atom_site_refinement_flags_posn
  _atom_site_refinement_flags_adp
  _atom_site_refinement_flags_occupancy
  _atom_site_disorder_assembly
  _atom_site_disorder_group
  Fe1 Fe 0.0000 0.0000 0.0000 0.019(2) Uiso 1 4 d S . P . .
  O1 O 0.5000 0.4090(18) 0.2500 0.009(5) Uiso 1 4 d S . P . .
  Fe2 Fe 0.0000 0.2453(4) 0.2500 0.016(2) Uiso 1 4 d S . P . .
  O2 O 0.5000 0.1436(16) 0.055(3) 0.017(3) Uiso 1 2 d S . P . .
  
  _geom_special_details            
  ;
   All esds (except the esd in the dihedral angle between two l.s. planes)
   are estimated using the full covariance matrix.  The cell esds are taken
   into account individually in the estimation of esds in distances, angles
   and torsion angles; correlations between esds in cell parameters are only
   used when they are defined by crystal symmetry.  An approximate (isotropic)
   treatment of cell esds is used for estimating esds involving l.s. planes.
  ;
  
  loop_
  _geom_bond_atom_site_label_1
  _geom_bond_atom_site_label_2
  _geom_bond_distance
  _geom_bond_site_symmetry_2
  _geom_bond_publ_flag
  Fe1 O1 1.786(7) 13 ?
  Fe1 O1 1.786(7) 5_445 ?
  Fe1 O2 1.846(10) 9 ?
  Fe1 O2 1.846(10) 9_655 ?
  Fe1 O2 1.846(10) 1_455 ?
  Fe1 O2 1.846(10) . ?
  Fe1 Fe1 2.640(6) 1_655 ?
  Fe1 Fe1 2.640(6) 1_455 ?
  Fe1 Fe2 2.658(4) . ?
  Fe1 Fe2 2.658(4) 9 ?
  O1 Fe1 1.786(7) 6 ?
  O1 Fe1 1.786(7) 5 ?
  O1 Fe2 1.935(12) 1_655 ?
  O1 Fe2 1.935(12) . ?
  Fe2 O1 1.935(12) 1_455 ?
  Fe2 O2 2.019(12) 1_455 ?
  Fe2 O2 2.019(12) 10_556 ?
  Fe2 O2 2.019(12) 10_456 ?
  Fe2 O2 2.019(12) . ?
  Fe2 O2 2.180(17) 6 ?
  Fe2 O2 2.180(17) 13 ?
  Fe2 Fe2 2.640(6) 1_655 ?
  Fe2 Fe2 2.640(6) 1_455 ?
  Fe2 Fe1 2.658(4) 2 ?
  O2 Fe1 1.846(10) 1_655 ?
  O2 Fe2 2.019(12) 1_655 ?
  O2 Fe2 2.180(17) 13 ?
  
  loop_
  _geom_angle_atom_site_label_1
  _geom_angle_atom_site_label_2
  _geom_angle_atom_site_label_3
  _geom_angle
  _geom_angle_site_symmetry_1
  _geom_angle_site_symmetry_3
  _geom_angle_publ_flag
  O1 Fe1 O1 180.0(9) 13 5_445 ?
  O1 Fe1 O2 97.1(6) 13 9 ?
  O1 Fe1 O2 82.9(6) 5_445 9 ?
  O1 Fe1 O2 97.1(6) 13 9_655 ?
  O1 Fe1 O2 82.9(6) 5_445 9_655 ?
  O2 Fe1 O2 91.3(6) 9 9_655 ?
  O1 Fe1 O2 82.9(6) 13 1_455 ?
  O1 Fe1 O2 97.1(6) 5_445 1_455 ?
  O2 Fe1 O2 88.7(6) 9 1_455 ?
  O2 Fe1 O2 180.0(7) 9_655 1_455 ?
  O1 Fe1 O2 82.9(6) 13 . ?
  O1 Fe1 O2 97.1(6) 5_445 . ?
  O2 Fe1 O2 180.0 9 . ?
  O2 Fe1 O2 88.7(6) 9_655 . ?
  O2 Fe1 O2 91.3(6) 1_455 . ?
  O1 Fe1 Fe1 90.0 13 1_655 ?
  O1 Fe1 Fe1 90.0 5_445 1_655 ?
  O2 Fe1 Fe1 135.7(3) 9 1_655 ?
  O2 Fe1 Fe1 44.3(3) 9_655 1_655 ?
  O2 Fe1 Fe1 135.7(3) 1_455 1_655 ?
  O2 Fe1 Fe1 44.3(3) . 1_655 ?
  O1 Fe1 Fe1 90.0 13 1_455 ?
  O1 Fe1 Fe1 90.0 5_445 1_455 ?
  O2 Fe1 Fe1 44.3(3) 9 1_455 ?
  O2 Fe1 Fe1 135.7(3) 9_655 1_455 ?
  O2 Fe1 Fe1 44.3(3) 1_455 1_455 ?
  O2 Fe1 Fe1 135.7(3) . 1_455 ?
  Fe1 Fe1 Fe1 180.0 1_655 1_455 ?
  O1 Fe1 Fe2 101.0(5) 13 . ?
  O1 Fe1 Fe2 79.0(5) 5_445 . ?
  O2 Fe1 Fe2 130.7(4) 9 . ?
  O2 Fe1 Fe2 130.7(4) 9_655 . ?
  O2 Fe1 Fe2 49.3(4) 1_455 . ?
  O2 Fe1 Fe2 49.3(4) . . ?
  Fe1 Fe1 Fe2 90.0 1_655 . ?
  Fe1 Fe1 Fe2 90.0 1_455 . ?
  O1 Fe1 Fe2 79.0(5) 13 9 ?
  O1 Fe1 Fe2 101.0(5) 5_445 9 ?
  O2 Fe1 Fe2 49.3(4) 9 9 ?
  O2 Fe1 Fe2 49.3(4) 9_655 9 ?
  O2 Fe1 Fe2 130.7(4) 1_455 9 ?
  O2 Fe1 Fe2 130.7(4) . 9 ?
  Fe1 Fe1 Fe2 90.0 1_655 9 ?
  Fe1 Fe1 Fe2 90.0 1_455 9 ?
  Fe2 Fe1 Fe2 180.0 . 9 ?
  Fe1 O1 Fe1 127.8(9) 6 5 ?
  Fe1 O1 Fe2 108.8(2) 6 1_655 ?
  Fe1 O1 Fe2 108.8(2) 5 1_655 ?
  Fe1 O1 Fe2 108.8(2) 6 . ?
  Fe1 O1 Fe2 108.8(2) 5 . ?
  Fe2 O1 Fe2 86.1(7) 1_655 . ?
  O1 Fe2 O1 86.1(7) 1_455 . ?
  O1 Fe2 O2 82.6(4) 1_455 1_455 ?
  O1 Fe2 O2 139.8(5) . 1_455 ?
  O1 Fe2 O2 139.8(5) 1_455 10_556 ?
  O1 Fe2 O2 82.6(4) . 10_556 ?
  O2 Fe2 O2 128.4(8) 1_455 10_556 ?
  O1 Fe2 O2 82.6(4) 1_455 10_456 ?
  O1 Fe2 O2 139.8(5) . 10_456 ?
  O2 Fe2 O2 76.5(8) 1_455 10_456 ?
  O2 Fe2 O2 81.7(6) 10_556 10_456 ?
  O1 Fe2 O2 139.8(5) 1_455 . ?
  O1 Fe2 O2 82.6(4) . . ?
  O2 Fe2 O2 81.7(6) 1_455 . ?
  O2 Fe2 O2 76.5(8) 10_556 . ?
  O2 Fe2 O2 128.4(8) 10_456 . ?
  O1 Fe2 O2 71.2(3) 1_455 6 ?
  O1 Fe2 O2 71.2(3) . 6 ?
  O2 Fe2 O2 138.4(4) 1_455 6 ?
  O2 Fe2 O2 68.6(6) 10_556 6 ?
  O2 Fe2 O2 68.6(6) 10_456 6 ?
  O2 Fe2 O2 138.4(4) . 6 ?
  O1 Fe2 O2 71.2(3) 1_455 13 ?
  O1 Fe2 O2 71.2(3) . 13 ?
  O2 Fe2 O2 68.6(6) 1_455 13 ?
  O2 Fe2 O2 138.4(4) 10_556 13 ?
  O2 Fe2 O2 138.4(4) 10_456 13 ?
  O2 Fe2 O2 68.6(6) . 13 ?
  O2 Fe2 O2 127.8(7) 6 13 ?
  O1 Fe2 Fe2 133.0(3) 1_455 1_655 ?
  O1 Fe2 Fe2 47.0(3) . 1_655 ?
  O2 Fe2 Fe2 130.8(3) 1_455 1_655 ?
  O2 Fe2 Fe2 49.2(3) 10_556 1_655 ?
  O2 Fe2 Fe2 130.8(3) 10_456 1_655 ?
  O2 Fe2 Fe2 49.2(3) . 1_655 ?
  O2 Fe2 Fe2 90.000(1) 6 1_655 ?
  O2 Fe2 Fe2 90.000(1) 13 1_655 ?
  O1 Fe2 Fe2 47.0(3) 1_455 1_455 ?
  O1 Fe2 Fe2 133.0(3) . 1_455 ?
  O2 Fe2 Fe2 49.2(3) 1_455 1_455 ?
  O2 Fe2 Fe2 130.8(3) 10_556 1_455 ?
  O2 Fe2 Fe2 49.2(3) 10_456 1_455 ?
  O2 Fe2 Fe2 130.8(3) . 1_455 ?
  O2 Fe2 Fe2 90.000(1) 6 1_455 ?
  O2 Fe2 Fe2 90.000(1) 13 1_455 ?
  Fe2 Fe2 Fe2 180.0 1_655 1_455 ?
  O1 Fe2 Fe1 125.7(2) 1_455 . ?
  O1 Fe2 Fe1 125.7(2) . . ?
  O2 Fe2 Fe1 43.9(3) 1_455 . ?
  O2 Fe2 Fe1 91.5(5) 10_556 . ?
  O2 Fe2 Fe1 91.5(5) 10_456 . ?
  O2 Fe2 Fe1 43.9(3) . . ?
  O2 Fe2 Fe1 153.2(4) 6 . ?
  O2 Fe2 Fe1 79.0(4) 13 . ?
  Fe2 Fe2 Fe1 90.000(1) 1_655 . ?
  Fe2 Fe2 Fe1 90.0 1_455 . ?
  O1 Fe2 Fe1 125.7(2) 1_455 2 ?
  O1 Fe2 Fe1 125.7(2) . 2 ?
  O2 Fe2 Fe1 91.5(5) 1_455 2 ?
  O2 Fe2 Fe1 43.9(3) 10_556 2 ?
  O2 Fe2 Fe1 43.9(3) 10_456 2 ?
  O2 Fe2 Fe1 91.5(5) . 2 ?
  O2 Fe2 Fe1 79.0(4) 6 2 ?
  O2 Fe2 Fe1 153.2(4) 13 2 ?
  Fe2 Fe2 Fe1 90.0 1_655 2 ?
  Fe2 Fe2 Fe1 90.0 1_455 2 ?
  Fe1 Fe2 Fe1 74.22(16) . 2 ?
  Fe1 O2 Fe1 91.3(6) . 1_655 ?
  Fe1 O2 Fe2 86.8(3) . . ?
  Fe1 O2 Fe2 151.5(9) 1_655 . ?
  Fe1 O2 Fe2 151.5(9) . 1_655 ?
  Fe1 O2 Fe2 86.8(3) 1_655 1_655 ?
  Fe2 O2 Fe2 81.7(6) . 1_655 ?
  Fe1 O2 Fe2 97.1(6) . 13 ?
  Fe1 O2 Fe2 97.1(6) 1_655 13 ?
  Fe2 O2 Fe2 111.4(6) . 13 ?
  Fe2 O2 Fe2 111.4(6) 1_655 13 ?
  
  _refine_diff_density_max         1.851
  _refine_diff_density_min         -1.416
  _refine_diff_density_rms         0.467
  
  _shelx_res_file                  
  ;
  
      test.res created by SHELXL-2014/7
  
  TITL Fe2O3_250um_p2 in Cmcm
  CELL 0.33440 2.6402 8.6392 6.4143 90.000 90.000 90.000
  ZERR 4.0000 0.0063 0.0092 0.0139 0.000 0.000 0.000
  LATT 7
  SYMM -X, -Y, 0.5+Z
  SYMM X, -Y, -Z
  SYMM -X, Y, 0.5-Z
  SFAC O FE
  DISP       Fe    0.13872    0.20289       413.35
  DISP       O    -0.00011    0.00113         7.70
  UNIT 12 8
  MERG 2
  FMAP 2
  GRID
  PLAN 20
  TEMP 23
  L.S. 10
  ACTA
  WGHT    0.116200    0.667800
  FVAR       3.98859
  FE1   2    0.000000    0.000000    0.000000    10.25000    0.01921
  O1    1    0.500000    0.409029    0.250000    10.25000    0.00921
  FE2   2    0.000000    0.245327    0.250000    10.25000    0.01620
  O2    1    0.500000    0.143639    0.055153    10.50000    0.01742
  HKLF 4
  
  REM  Fe2O3_250um_p2 in Cmcm
  REM R1 =  0.0654 for      37 Fo > 4sig(Fo)  and  0.0910 for all      53 data
  REM      9 parameters refined using      0 restraints
  
  END
  
  WGHT      0.1162      0.6678
  
  REM Highest difference peak  1.851,  deepest hole -1.416,  1-sigma level  0.467
  Q1    1   0.0000  0.4971  0.2500  10.25000  0.05    1.85
  Q2    1   0.5000  0.2132  0.0058  10.50000  0.05    1.57
  Q3    1   0.0000  0.4298  0.3272  10.50000  0.05    1.56
  Q4    1   0.0000  0.0797  0.2500  10.25000  0.05    1.46
  Q5    1   0.5000  0.4929  0.2500  10.25000  0.05    1.08
  Q6    1   0.2345  0.0868  0.0524  11.00000  0.05    0.69
  Q7    1   0.2859  0.3468  0.2500  10.50000  0.05    0.65
  Q8    1   0.1657  0.1845  0.3491  11.00000  0.05    0.65
  Q9    1   0.0000  0.1794  0.3763  10.50000  0.05    0.62
  Q10   1  -0.5000  0.2517  0.2500  10.25000  0.05    0.58
  Q11   1   0.5000  0.0000  0.0000  10.25000  0.05    0.42
  Q12   1   0.2775  0.3014  0.3682  11.00000  0.05    0.27
  ;
  _shelx_res_checksum              14907
  
  _shelx_hkl_file                  
  ;
     0  -2  -1 45.1145 67.5853   1
     0   2   1 2.84906 84.3218   1
     0  -2  -2 280699. 2843.13   1
     0   4   1 13608.0 303.010   1
     0  -4  -1 12126.0 509.795   1
     0   4   2 3888.15 431.746   1
     0  -4  -2 2896.73 192.406   1
     0  -4  -3 1163.38 217.387   1
     0  -4  -4 127926. 1750.11   1
     0  -4  -5 754.661 2323.56   1
     0   6   1 468.652 247.395   1
     0  -6  -2 208127. 1908.46   1
     0   6   2 213025. 1206.63   1
     0   6   3 9219.71 1199.40   1
     0  -6  -3 9832.69 369.956   1
     0  -6  -4 559.986 298.600   1
     0  -6  -5-207.848 242.882   1
     0   8   2 290.783 315.743   1
     0   8   3 2803.15 861.640   1
     0   8   4 38492.2 3502.68   1
     0  -8  -4 59238.7 857.299   1
     1  -1   1 45790.6 1697.77   1
    -1   1  -2 16609.8 868.825   1
     1  -1   2 17617.7 303.959   1
     1  -1   3 84824.4 658.132   1
     1   1   3 83220.5 491.376   1
    -1  -1  -4 48569.2 881.542   1
    -1   1  -4 56844.0 640.889   1
    -1  -1  -5 6784.34 194.247   1
    -1  -1  -6 12876.3 291.409   1
    -1   3   0 104710. 3721.49   1
     1  -3   0 122822. 1235.90   1
    -1   3  -1 98780.6 1141.17   1
     1  -3   1 97449.7 825.837   1
     1  -3   2 130921. 1010.11   1
    -1   3  -2 130189. 1105.66   1
    -1   3  -3 54626.7 756.505   1
    -1   5   0 37451.5 946.750   1
     1  -5   0 41718.9 682.448   1
     1  -5   1 54754.8 807.269   1
    -1   5  -1 55694.9 905.908   1
     1  -5  -1 55278.5 893.904   1
    -1   5  -2 42135.7 714.258   1
     1  -5   2 42141.0 772.935   1
     1  -5  -3 11302.3 2660.44   1
     1  -5  -4 10657.8 3751.66   1
     1   5   4 12281.4 237.898   1
    -1   7   0 1343.42 705.277   1
     1  -7   0 1296.58 456.274   1
    -1   7   1 11088.6 849.068   1
    -1   7  -1 14494.4 572.136   1
     1  -7  -2 20477.2 607.784   1
    -1   7  -2 21817.8 680.742   1
    -1   7   3 9778.13 4342.07   1
     1  -7  -3 15923.0 705.289   1
     1  -7  -4 11031.9 1162.86   1
     1   7   4 3211.80 272.619   1
     1  -9   0 28162.8 909.513   1
    -1   9   0 26619.7 990.251   1
    -1   9   1 13714.0 1028.17   1
    -1   9  -1 7537.16 568.491   1
    -1   9   2 26298.1 2673.77   1
     1  -9  -2 4617.71 470.115   1
     2   0   3 512.374 1328.88   1
     2   0   4 110068. 1037.08   1
     2   0   5-117.763 327.164   1
     2  -2   1 3367.46 3401.88   1
     2  -2   2 63798.1 1235.00   1
    -2   2  -3 4679.16 3598.30   1
     2  -2   3 5538.74 482.541   1
     2   2   4-231.093 1851.84   1
     2  -2   4 370.523 390.668   1
     2  -2   5 683.396 372.538   1
     2   2   5 225.086 218.871   1
    -2   2  -5-88.5426 2995.74   1
     2  -4   0 58546.3 2787.15   1
     2  -4   1 2424.00 657.023   1
     2  -4  -1 9948.48 4251.20   1
    -2   4  -1 5246.38 4119.31   1
    -2   4  -2 3572.84 4219.75   1
     2  -4   2 173.789 447.026   1
     2  -4  -2 5975.94 4386.52   1
     2  -4   3 381.301 400.462   1
    -2   4  -3-1709.86 3158.44   1
    -2   4  -4 60050.9 2615.05   1
     2  -4   4 48687.6 987.124   1
     2  -6   0 526.067 713.805   1
    -2   6   0 163.459 4894.34   1
     2  -6   1 70.4086 412.311   1
    -2   6  -1 1265.06 4918.60   1
     2  -6  -1-687.403 741.750   1
    -2   6  -2 88266.6 3716.41   1
     2  -6   2 93931.5 1434.32   1
    -2   6  -3 2493.72 919.010   1
     2  -6   3 1929.01 566.855   1
     0   0   0    0.00    0.00   0
  TITL Fe2O3_250um_p2 in Cmcm
  REM Cmcm (#63 in standard setting)
  CELL 0.33440   2.640249   8.639191   6.414252  90.0000  90.0000  90.0000
  ZERR    2.00   0.006326   0.009223   0.013926   0.0000   0.0000   0.0000
  LATT  7
  SYMM -x,-y, z+1/2
  SYMM -x, y,-z+1/2
  SYMM  x,-y,-z
  SFAC O Fe
  UNIT 6.00 4.00
  TREF
  HKLF 4
  END
  ;
  _shelx_hkl_checksum              23702
  
  `;

  viewer.addModel(fe2o3, "cif");

// 添加单元格
//viewer.addUnitCell();
// 添加多个单元格
viewer.addUnitCell({a: 2, b: 2, c: 2});  // 在每个方向上复制2个单元格
viewer.setStyle({}, {sphere: {}});
viewer.setStyle({elem: 'Fe'}, {sphere: {color: 'red', radius: 0.6}});
viewer.setStyle({elem: 'O'}, {sphere: {color: 'blue', radius: 0.4}});
// 获取所有原子
var allAtoms = viewer.selectedAtoms({});

// 自动为所有原子添加标签
for (var i = 0; i < allAtoms.length; i++) {
  var atom = allAtoms[i];
  viewer.addLabel(atom.elem, {position: atom, backgroundColor: 'transparent'});
}
// 添加多个单元格
viewer.addUnitCell({a: 4, b: 4, c: 4});  // 在每个方向上复制4个单元格

// 最后，别忘了渲染视图
viewer.zoomTo();
viewer.render();

  }
  function handledisplaypage(){

  }

  
  const [physicalJsCode,setPhysicalJsCode] = useState('');

  async function handleWuli() {

    const Engine = Matter.Engine;
    const Render = Matter.Render;

    // 创建一个引擎
    const engine = Engine.create();

    // 创建一个渲染器
    const render = Render.create({
        element: threeDMolViewerRef.current,
        engine: engine,
        options: {
            width: 800,
            height: 600,
            wireframes: false
        }
    });

     // 创建斜面和物体
     let ground = Matter.Bodies.rectangle(400, 550, 800, 20, { 
          isStatic: true, 
          angle: Math.PI * 0.05,
          render: { fillStyle: '#060a19' }
      });

      // 计算物体应该放置的位置
      let boxX = 200; // 可根据需要调整X坐标
      let boxY = ground.position.y - (ground.bounds.max.y - ground.bounds.min.y) / 2 - 0; // 斜面上方20像素
      let box = Matter.Bodies.rectangle(boxX, boxY, 40, 40, {
          render: { fillStyle: '#C44D58' }
      });


    // 将物体和斜面添加到世界
    Matter.World.add(engine.world, [box, ground]);

    


// 计算力和绘制箭头的函数
function drawForces(body) {
  const ctx = render.context;
  const position = body.position;
  const scale =50; // 调整比例因子以更好地显示力
  const angle = ground.angle;
  // 计算重力（垂直向下）
  const gravity = { x: 0, y: engine.world.gravity.y * body.mass * scale };
  // 绘制重力
  drawArrow(ctx, position, { x: position.x + gravity.x, y: position.y + gravity.y }, '#00a2ff');

// 计算并绘制沿斜面方向的分力（平行分力）
const parallelForce = {
  x: gravity.y * Math.sin(angle) * Math.cos(angle),
  y: gravity.y * Math.sin(angle) * Math.sin(angle)
};
drawDashedLine(ctx, position, { x: position.x + parallelForce.x, y: position.y + parallelForce.y }, '#00a2ff');

// 计算并绘制垂直于斜面的分力（法向分力）
const perpendicularForce = {
  x: -gravity.y * Math.cos(angle) * Math.sin(angle),
  y: gravity.y * Math.cos(angle) * Math.cos(angle)
};
drawDashedLine(ctx, position, { x: position.x + perpendicularForce.x, y: position.y + perpendicularForce.y }, '#00a2ff');




// 计算摩擦力（沿斜面）
const frictionMagnitude = gravity.y * Math.cos(ground.angle); // 摩擦力大小
const frictionDirection = body.velocity.x >= 0 ? -1 : 1; // 根据物体运动方向调整摩擦力方向

// 摩擦力方向应与斜面平行
const friction = {
    x: frictionDirection * frictionMagnitude * Math.cos(ground.angle),
    y: frictionDirection * frictionMagnitude * Math.sin(ground.angle)
};

// 绘制摩擦力
drawArrow(ctx, position, { x: position.x + friction.x, y: position.y + friction.y }, '#ff0000');


  // 计算法向力（斜面垂直方向）
  const normal = {
    x: gravity.y * Math.sin(ground.angle),
    y: -gravity.y * Math.cos(ground.angle)
  };

// 绘制法向力
drawArrow(ctx, position, { x: position.x + normal.x, y: position.y + normal.y }, '#00ff00');

}

function drawArrow(ctx, start, end, color) {
  // 绘制线条
  ctx.beginPath();
  ctx.moveTo(start.x, start.y);
  ctx.lineTo(end.x, end.y);
  ctx.strokeStyle = color;
  ctx.lineWidth = 2;
  ctx.stroke();

  // 计算箭头的方向
  const angle = Math.atan2(end.y - start.y, end.x - start.x);
  const headLength = 10; // 箭头头部的长度

  // 绘制箭头的头部
  ctx.beginPath();
  ctx.moveTo(end.x, end.y);
  ctx.lineTo(end.x - headLength * Math.cos(angle - Math.PI / 6), end.y - headLength * Math.sin(angle - Math.PI / 6));
  ctx.lineTo(end.x - headLength * Math.cos(angle + Math.PI / 6), end.y - headLength * Math.sin(angle + Math.PI / 6));
  ctx.lineTo(end.x, end.y);
  ctx.lineTo(end.x - headLength * Math.cos(angle - Math.PI / 6), end.y - headLength * Math.sin(angle - Math.PI / 6));
  ctx.fillStyle = color;
  ctx.fill();
}
  
function drawDashedLine(ctx, start, end, color, dashLength = 5) {
  ctx.beginPath();
  ctx.setLineDash([dashLength, dashLength]);
  ctx.moveTo(start.x, start.y);
  ctx.lineTo(end.x, end.y);
  ctx.strokeStyle = color;
  ctx.lineWidth = 2;
  ctx.stroke();
  ctx.setLineDash([]); // 重置为实线
}

  // Add this to the Matter.Render.create options
  render.options.showAngleIndicator = true;
  
  // Render forces after each update
  Matter.Events.on(render, 'afterRender', () => {
      drawForces(box);
  });

    // 在每个步长更新受力情况
  Matter.Events.on(engine, 'afterUpdate', () => {
    // 施加额外的下滑力
    const additionalForce = {
        x: Math.sin(ground.angle) * 0.005, // 适当调整这个值以改变施加的力的大小
        y: Math.cos(ground.angle) * 0.005
    };
    Matter.Body.applyForce(box, box.position, additionalForce);

    drawForces(box);
  });



  // 运行引擎和渲染器
  Matter.Runner.run(engine);
  Matter.Render.run(render);

  
}

  async function 月球地球轨道旋转() {

    var engine = Matter.Engine.create();

        // 创建渲染器
        var render = Matter.Render.create({
            element: threeDMolViewerRef.current,
            engine: engine,
            options: {
                wireframes: false
            }
        });

        // 创建地球和月球
        var earth = Matter.Bodies.circle(400, 200, 50, { isStatic: true, render: { fillStyle: 'blue' } });
        var moon = Matter.Bodies.circle(700, 200, 10, { render: { fillStyle: 'gray' } });

        // 添加到世界
        Matter.World.add(engine.world, [earth, moon]);

        // 运行引擎和渲染器
        Matter.Engine.run(engine);
        Matter.Render.run(render);

        // 更新月球位置
        let angle = 0;
        const updateMoonPosition = () => {
            angle += 0.02; // 速度，可以调整
            const distance = 300; // 距离，可以调整
            Matter.Body.setPosition(moon, {
                x: earth.position.x + distance * Math.cos(angle),
                y: earth.position.y + distance * Math.sin(angle)
            });
            requestAnimationFrame(updateMoonPosition);
        };
        updateMoonPosition();
}


  async function 抛物() {


            // 基本的 Matter.js 模块别名
    const Engine = Matter.Engine;
    const Render = Matter.Render;
    const World = Matter.World;
    const Bodies = Matter.Bodies;

    // 创建一个引擎
    const engine = Engine.create();

    // 创建一个渲染器
    const render = Render.create({
        element: threeDMolViewerRef.current,
        engine: engine,
        options: {
            width: 800,
            height: 600,
            wireframes: false
        }
    });

    // 创建月球表面的地面
    const ground = Bodies.rectangle(400, 580, 810, 60, { isStatic: true });

    // 定义小球的初始位置和速度
    const h = 100; // 初始高度
    const v0 = 15; // 初始水平速度

    // 创建一个小球，模拟宇航员抛出的球
    const ball = Bodies.circle(50, 500 - h, 20, {
        restitution: 0.6 // 设置弹性
    });

    // 设置小球的水平速度
    Matter.Body.setVelocity(ball, { x: v0, y: 0 });

    // 将地面和球添加到世界中
    World.add(engine.world, [ground, ball]);

    // 设置月球重力加速度
    engine.world.gravity.y = 1.6; // 月球表面的重力加速度约为 1.6 m/s^2

    // 运行引擎和渲染器
    //Engine.run(engine);
    Matter.Runner.run(engine);
    Render.run(render);
  
  }


  async function handleWuli111() {


        let jsCode =`
        var engine = Matter.Engine.create();

        // 创建渲染器
        var render = Matter.Render.create({
            element: container,
            engine: engine,
            options: {
                wireframes: false
            }
        });

        // 创建地球和月球
        var earth = Matter.Bodies.circle(400, 200, 50, { isStatic: true, render: { fillStyle: 'blue' } });
        var moon = Matter.Bodies.circle(700, 200, 10, { render: { fillStyle: 'gray' } });

        // 添加到世界
        Matter.World.add(engine.world, [earth, moon]);

        // 运行引擎和渲染器
        Matter.Engine.run(engine);
        Matter.Render.run(render);

        // 更新月球位置
        let angle = 0;
        const updateMoonPosition = () => {
            angle += 0.02; // 速度，可以调整
            const distance = 300; // 距离，可以调整
            Matter.Body.setPosition(moon, {
                x: earth.position.x + distance * Math.cos(angle),
                y: earth.position.y + distance * Math.sin(angle)
            });
            requestAnimationFrame(updateMoonPosition);
        };
        updateMoonPosition();
        `;
        setPhysicalJsCode(jsCode);
        if (threeDMolViewerRef.current) {
          const executeSimulation = new Function('Matter', 'container', jsCode);
          executeSimulation(Matter, threeDMolViewerRef.current);

        }


  }

  async function handlePlayPhysical() {
    playPhysicalImage();
    
  }

  const [isAnimating, setIsAnimating] = useState(false);
  const intervalIdRef = useRef(null);

  const playPhysicalImage1 = () => {
    if (!isAnimating) {
      setIsAnimating(true);
      console.log('播放动画'); // 在这里实现播放逻辑
      let jsCode = physicalJsCode;
  
        // 清理现有的 Matter.js 实例（如果存在）
        if (threeDMolViewerRef.current.childNodes.length > 0) {
          // 清理 DOM 元素
          threeDMolViewerRef.current.innerHTML = '';
        }
  
        // 创建并执行新的模拟
        const executeSimulation = new Function('Matter', 'container', jsCode);
        executeSimulation(Matter, threeDMolViewerRef.current);


      setTimeout(() => {
        setIsAnimating(false);
      }, 3000); // 假设动画执行时间为2秒
    }
  };

  const playPhysicalImage = () => {
    // 检查threeDMolViewerRef.current是否存在
    if (!threeDMolViewerRef.current) {
      console.error('threeDMolViewerRef.current is null');
      return;
    }
  
    if (!isAnimating) {
      setIsAnimating(true);
      console.log('播放动画'); // 在这里实现播放逻辑
      let jsCode = physicalJsCode;
  
      // 清理现有的 Matter.js 实例（如果存在）
      if (threeDMolViewerRef.current.childNodes.length > 0) {
        // 清理 DOM 元素
        threeDMolViewerRef.current.innerHTML = '';
      }
  
      // 创建并执行新的模拟
      const executeSimulation = new Function('Matter', 'container', jsCode);
      executeSimulation(Matter, threeDMolViewerRef.current);
  
      setTimeout(() => {
        setIsAnimating(false);
      }, 3000); // 假设动画执行时间为2秒
    }
  };

  useEffect(() => {
    // 组件卸载时执行的清理逻辑
    return () => {
      if (intervalIdRef.current) {
        clearInterval(intervalIdRef.current);
      }
      // 可能还需要其他清理操作
    };
  }, []);

  const handleLoopPlay = () => {
    if (intervalIdRef.current) {
      clearInterval(intervalIdRef.current);
      intervalIdRef.current = null;
    } else {
      intervalIdRef.current = setInterval(() => {
        playPhysicalImage();
      }, 2000); // 每2秒尝试播放一次
    }
  };



  const drawRectangle = (app) => {
    app.evalCommand("A = (1, 1)");
    app.evalCommand("B = (1, 3)");
    app.evalCommand("C = (4, 3)");
    app.evalCommand("D = (4, 1)");
    app.evalCommand("rect = Polygon[A, B, C, D]");
  };

  const drawCommands = [
    "A = (1, 1)",
    "B = (1, 3)",
    "C = (4, 3)",
    "D = (4, 1)",
    "rect = Polygon[A, B, C, D]"
  ];

  const drawCommandString = "A = (1, 1); B = (1, 3); C = (4, 3); D = (4, 1); rect = Polygon[A, B, C, D]";

  return (
    <div style={{backgroundColor:'green',color:'black'}}>
      <div style={{ width: '100%', position: 'relative' }}>
      <iframe style={iframeStyle} src="jsbg/trefoil-knot-inside-torus/dist/index.html"></iframe>
      {/* 其他内容 */}
    </div>
      <button onClick={generate3Dmol}>3dmol生成</button>
      <button onClick={componentDidMount}>3dmol按钮</button>
      <button onClick={widgetzhouqi}>获取元素周期表</button>
      <button onClick={getchemi}>chmi</button>
      <button onClick={getchemi2D}>chmi2D</button>
      <button onClick={handledisplaypage}>显示网页</button>
      <button onClick={handleWuli}>物理</button>
      <StarButton></StarButton>
      <button >chemdoodle</button>
      <div ref={threeDMolViewerRef} style={viewerContainerStyle}></div>
    <div id='molecule-viewer'></div>
    <GeoGebraGraph drawCommandString={drawCommandString} appName={"geometry"} language='zh' />
    </div>
    
   
  );
};

export default MyComponent;
