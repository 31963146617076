/**
 * src/pages/Privacy.jsx
 *
 * created by pycui on 8/18/23
 */

import React from 'react';

const Privacy = () => {
  return (
    <div className='card' style={{ display: "flex", 'justify-content': "center"}}>

      <div className='home'>
        <h1>隐私声明</h1>
        <p>
          <strong>生效日期:</strong> 08/18/2023
        </p>

        <h2>简介</h2>
        <p>
          感谢您使用chentu ai家教网站。我们尊重您的隐私，并希望保护您的个人数据。无论您从何处访问我们的网站，本隐私声明都将告知您我们如何处理您的个人数据，以及您的隐私权和法律对您的保护。
        </p>

        <h2>我们收集的数据</h2>
        <p>
          我们可能会收集、使用、存储和传输有关您的不同类型的个人数据，具体如下：
        </p>
        <ul>
          <li>
            <strong>联系数据:</strong> 包括电子邮件地址和唯一用户ID。
          </li>
          <li>
            <strong>使用数据：</strong> 有关您如何使用我们的网站、产品和服务的信息。
          </li>
        </ul>

        <h2>我们如何使用您的数据</h2>
        <p>
          我们只会出于收集它的目的来使用您的个人数据，包括以下几点：
        </p>
        <ul>
          <li>注册您为新客户。</li>
          <li>处理和满足您的请求。</li>
          <li>管理与您的关系。</li>
          <li>
            向您提供有关您可能感兴趣的商品或服务的建议和推荐。
          </li>
        </ul>

        <h2>数据安全</h2>
        <p>
          我们已经采取了适当的安全措施，以防止您的个人数据被意外丢失、使用或未经授权的访问。此外，我们限制了对您个人数据访问的员工、代理人、承包商和其他第三方，他们需要了解这些信息才能进行业务。
        </p>

        <h2>数据保留</h2>
        <p>
          我们只会在完成收集数据的目的，包括满足任何法律、会计或报告要求的目的后，才保留您的个人数据。
        </p>

        <h2>您的法律权利</h2>
        <p>
          在某些情况下，您有权根据数据保护法律要求访问、更正、删除、限制、转移或反对处理您的个人数据。
        </p>

        <h2>联系我们</h2>
        <p>
          如您对本隐私声明或我们的隐私做法有任何问题，请通过service@chentu.co与我们联系。
        </p>
      </div>
    </div>
  );
};

export default Privacy;
