import React, { useState, useRef,useEffect } from 'react';
import IconButton from '@mui/material/IconButton';
import StopIcon from '@mui/icons-material/Stop';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import SendIcon from '@mui/icons-material/Send';
import LoadingData from '../dataloading';
import { getHostName } from '../../utils/urlUtils';
import { useTranslation } from 'react-i18next';
import TextField from '@mui/material/TextField';
import { Paper } from '@mui/material';
import Stack from '@mui/material/Stack';
import { Mic} from '@mui/icons-material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import CustomButton from '../../components/CustomButton';
import { getColorDark,getColorLight } from '../../ColorManager';
import '../../fonts.css';

const AudioRecorder = ({ onCloseAudioRecord, translateText, setTranslateText,onCloseOnly }) => {
  const { t } = useTranslation();
  const [recording, setRecording] = useState(false);
  const [audioBlob, setAudioBlob] = useState(null);
  const audioRef = useRef(null);
  const mediaRecorderRef = useRef(null); // 新增这一行
  const [isLoading, setIsLoading] = useState(false);
  const [loadingText,setLoadingText] = useState(false);

  const [colorManager, setColorManager] = useState(getColorDark()); // 初始化为空数组

    //获取颜色设置，系统加载时设置
  useEffect(() => {

  const themeName = localStorage.getItem('theme');//此处存储的是数字1-12，而不是汉字，
  if (themeName) {
    
    if(themeName === "dark"){
      setColorManager(getColorDark());
    }

    
    if(themeName === "light"){
      setColorManager(getColorLight());
    }
  }
  else{
    let aa = getColorDark();
    setColorManager(aa);
  }


  }, []);



  const get_color_by_name = (name) => {

  const item = colorManager.find(item => item.container === name);
  return item.value;
  }

  const startRecording = () => {
    navigator.mediaDevices.getUserMedia({ audio: true })
      .then((stream) => {
        const mediaRecorder = new MediaRecorder(stream);
        mediaRecorderRef.current = mediaRecorder; // 新增这一行
        mediaRecorder.onstart = () => setRecording(true);
        mediaRecorder.ondataavailable = (event) => {
          setAudioBlob(event.data);
        };
        mediaRecorder.onstop = () => setRecording(false);
        mediaRecorder.start();
      })
      .catch((error) => {
        // 处理异常，例如提示用户没有麦克风
        if (error.name === "NotAllowedError" || error.name === "NotFoundError") {
          alert(t("No microphone detected, make sure your device is connected to a microphone and the web page has permission to use it"));              
        } else {
          // 处理其他类型的错误
          console.error("获取麦克风时发生错误：", error);
        }
      });
  };


  const stopRecording = () => mediaRecorderRef.current && mediaRecorderRef.current.stop(); // 修改这一行
  const playRecording = () => audioRef.current && audioRef.current.play();

  const sendRecording = async () => {
    if (audioBlob) {
      const formData = new FormData();
      formData.append('audio', audioBlob, 'audio.wav');
      const token = localStorage.getItem("access_token");
      try {
        setIsLoading(true);
        const url = getHostName() + '/translate_audio'; // 你的服务端URL
        const response = await fetch(url, {method: 'POST',
          headers: {'Authorization': `Bearer ${token}`},
          body: formData,});
        if (response.ok) {
            const data = await response.json();
            console.log(JSON.stringify(data.data));
            setTranslateText(data.data);
           
            setIsLoading(false);



            navigator.clipboard.writeText(data.data)
                .then(() => {
                    alert('已经复制到剪贴板');
                })
                .catch(err => {
                    alert('Unable to copy text to clipboard');
                });



        }
        else {
            console.error("Error fetching reply:", response.statusText);
            setIsLoading(false);
        }
      }
      catch (error) {
        setIsLoading(false);
        console.error("There was an error fetching the reply:", error);
      }

    }
  };

  const handleTextChange = (event) => {
    setTranslateText(event.target.value);
  };


  const inputStyles = {
   
    color:get_color_by_name('mui-textfield-text-color'),
    fontFamily: 'siyuan',
  };
  

  return (
    <Paper
      elevation={3}
      sx={{
        zIndex:9999,
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        display: 'flex',
        flexDirection: 'column',
        gap: '20px',
        alignItems: 'center',
        padding: '20px',
        width:'500px',
        borderRadius: '20px',
        backgroundColor:get_color_by_name('pop-window-bg'),
        border: `1px solid ${get_color_by_name('pop-window-border-color')}`,
      }}
    >
      {/* 第一行：四个按钮 */}
      <Table>
      <TableBody>
        {/* Row for Icons */}
        <TableRow>
          <TableCell align="center">
            <IconButton 
              onClick={startRecording} disabled={recording}
              color="warning" 
              style={{
                backgroundColor: '#b9b9b9',
                border: '1px solid #ccc',
                boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.5)',
                borderRadius: '50%',
              }}
            >
              <Mic fontSize="medium"/>
            </IconButton>
          </TableCell>
          <TableCell align="center">
            <IconButton 
              onClick={stopRecording} disabled={!recording}
              color="warning" 
              style={{
                backgroundColor: '#b9b9b9',
                border: '1px solid #ccc',
                boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.5)',
                borderRadius: '50%',
              }}
            >
              <StopIcon fontSize="medium" />
            </IconButton>
          </TableCell>
          <TableCell align="center">
            <IconButton 
              onClick={playRecording} disabled={!audioBlob}
              color="warning" 
              style={{
                backgroundColor: '#b9b9b9',
                border: '1px solid #ccc',
                boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.5)',
                borderRadius: '50%',
              }}
            >
              <PlayArrowIcon fontSize="medium" />
            </IconButton>
          </TableCell>
          <TableCell align="center">
            <IconButton 
              onClick={sendRecording} disabled={!audioBlob}
              color="warning" 
              style={{
                backgroundColor: '#b9b9b9',
                border: '1px solid #ccc',
                boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.5)',
                borderRadius: '50%',
              }}
            >
              <SendIcon fontSize='medium' />
            </IconButton>
          </TableCell>
        </TableRow>

        {/* Row for Texts */}
        <TableRow>
          <TableCell sx={{fontFamily:'siyuan',color:get_color_by_name('headline-color')}} align="center">{t('start recording')}</TableCell>
          <TableCell sx={{fontFamily:'siyuan',color:get_color_by_name('headline-color')}} align="center">{t('stop recording')}</TableCell>
          <TableCell sx={{fontFamily:'siyuan',color:get_color_by_name('headline-color')}} align="center">{t('play recorded sound')}</TableCell>
          <TableCell sx={{fontFamily:'siyuan',color:get_color_by_name('headline-color')}} align="center">{t('transcription to text')}</TableCell>
        </TableRow>
      </TableBody>
    </Table>
      {/* 第二行：一个文本框 */}
  
      <TextField
        multiline
        rows={4}
        
        variant="outlined"
        value={translateText}
        onChange={handleTextChange}
        placeholder={t('here is what the audio transcription looks like')}
        InputProps={{
          sx: inputStyles,
        }}
        
        sx={{
          width:'100%',
          '& .MuiInputBase-root': {
            backgroundColor: get_color_by_name('mui-textfield-bg'), // 自定义背景色
          },
          '& .MuiOutlinedInput-root': {
            '&:hover fieldset': {
              borderColor: get_color_by_name('mui-textfield-bg'),
            },
            '&.Mui-focused fieldset': {
              borderColor: 'primary.main',
            },
            '& fieldset': {
              borderColor: get_color_by_name('mui-textfield-bg'),
            },
          },
        }}
    />



      {/* 第三行：两个按钮 */}
      <Stack direction="row" spacing={2}>
      <CustomButton onClick={onCloseAudioRecord}>
        {t('confirm')}
        </CustomButton>
        <CustomButton onClick={onCloseAudioRecord}>
        {t('cancel')}
        </CustomButton>
      </Stack>

      <audio ref={audioRef} src={audioBlob ? URL.createObjectURL(audioBlob) : ''} />
      {isLoading && (
        <LoadingData text={t(loadingText)}/>
      )}
    </Paper>
  );
};

export default AudioRecorder;
