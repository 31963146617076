import React, { useState,useEffect} from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button } from '@mui/material';
import { getHostName } from '../../utils/urlUtils';
import { useSnackbar } from '../../SnackbarProvider';
import LoadingData from '../dataloading';
import { useTranslation } from 'react-i18next';
import './BigCleverness.css';

const BigCleverness = () => {

  const showSnackbar = useSnackbar();//这个是界面顶部弹出提示信息

  const { t } = useTranslation();//这是i18n全球化，支持多语言的，界面
  const [isLoading, setIsLoading] = useState(false);
  const [loadingText,setLoadingText] = useState('大聪明');
    // 模拟数据
    const [data, setData] = useState([
        { "id": 1, "name": "songlixun", "age": 11 },
        { "id": 2, "name": "hemin", "age": 12 },
        { "id": 3, "name": "liudehua", "age": 13 },
    ]);

    const test = async () => {
      try 
      {
        //这行代码固定，是提交到服务端的token信息，做验证用
        const token = localStorage.getItem("access_token");

        //dataToSend是提交到服务器的json参数，基本智谷的所有接口基本都是json格式
        //cid和is_star是参数名称，和后端必须名字完全一致，message.id和newIsFavorited是参数的值，值的类型也要匹配，str，int，bool

        setIsLoading(true);//界面显示加载旋转的那个动画
        const dataToSend = {
          cid:1,
          is_star:true,
          name:'songlixun',
        };
      
        //这是请求地址url，后面是接口名称set_chat_record_is_star
        const url = getHostName() + '/set_chat_record_is_star'; // 你的服务端URL

        //这是具体的请求信息，包括token和参数datatosend
            const response = await fetch(url, {method: 'POST',
              headers: {'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`},
              body: JSON.stringify(dataToSend),});

        if (response.ok) {
          showSnackbar(t('copied the formula to the clipboard'),'success');//后面的字段有success,failure,warning,成功，失败，警告，前面的字段是在界面显示的详细信息
            const data = await response.json();//访问成功后，从后端取到了数据，如果后端返回给前端的事json格式，那么这里用转成json,
            const mathtext = data.message;
            //这个返回的数据结构比如是下面这个样子
            //  {"status":"success","student":"{{"id":1,"name":"song"},{"id":1,"name":"song"}}"}   也可以是上面的data数据结构，
            //通过data.student就获取到了，赋值
            //调用定义变量的setData方法赋值
            //setData(data.student);
            //这样return中的代码，使用data数据就可以得到了

            setIsLoading(false);
        }
        else {
            console.error("Error fetching reply:", response.statusText);
            setIsLoading(false);
        }
      }
    catch (error) {
      console.error("There was an error fetching the reply:", error);
      setIsLoading(false);
    }
    }

    // 编辑事件
    const handleEdit = (rowData) => {
        // 在这里处理编辑逻辑，可以传递rowData中的参数到编辑组件
        console.log("Editing:", rowData);
        
        setIsLoading(true);
        setTimeout(() => {
          // 在这里写你想要延迟执行的代码
          console.log("Delayed action after 3 seconds");
      }, 3000);
      showSnackbar(rowData.name,'success');
      setIsLoading(false);

    };

//中括号如果留空，代表组件第一次加载的时候，执行副作用的代码
    useEffect(() => {


    }, []);


    //如果中括号里面有变量，那么，只要这个变量的值发生改变，就会执行里面的代码
    useEffect(() => {
      

    }, [data]);




    return (
        <TableContainer component={Paper}>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>ID</TableCell>
                        <TableCell>Name</TableCell>
                        <TableCell>Age</TableCell>
                        <TableCell>Action</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {data.map((row) => (
                        <TableRow key={row.id}>
                            <TableCell>{row.id}</TableCell>
                            <TableCell>{row.name}</TableCell>
                            <TableCell>{row.age}</TableCell>
                            <TableCell>
                                <Button onClick={() => handleEdit(row)} variant="outlined" size="small">Edit</Button>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>

            {isLoading && (
              <LoadingData text={t(loadingText)}/>
            )}


        </TableContainer>

        
    );
};

export default BigCleverness;
