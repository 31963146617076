import React, { useState, useEffect, useRef } from 'react';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import CommonCombobox from '../components/CommonCombobox';
import { IconButton, unstable_ClassNameGenerator, Grid,Modal } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { requestUrl, requestUrlWithOutParameter } from '../requestUrl';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import StudentPollComponent from './StudentPoll/StudentPollComponent';
import LoadingData from './dataloading';
import TeacherStyle from './TeacherStyle/TeacherStyle';
import { useSnackbar } from '../SnackbarProvider';
import CustomButton from '../components/CustomButton';
import { getAppVersion } from '../utils/appversion';

const UserSetting = () => {
  const showSnackbar = useSnackbar();
  const { t } = useTranslation();
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [isSamePassword, setIsSamePassword] = useState(false);

  const [selectedLanguage, setSelectedLanguage] = useState();//设置音视频转语言
  const [selectedVoiceName, setSelectedVoiceName] = useState('alloy');

  const [musicurl, setMusicurl] = useState('');

  const [openTeacherStyle,setOpenTeacherStyle] = useState(false);
  const handleCloseTeacherStyle = () => setOpenTeacherStyle(false);
  const [showStudentPoll,setShowStudentPoll] = useState(false);

  const onCloseTeacherStyle = () => setOpenTeacherStyle(false);
  const [isLoading, setIsLoading] = useState(false);
  const [loadingText,setLoadingText] = useState('loading');

  const dropdownLanguages = [
    { id: 'en', text: 'english' },
    { id: 'zh', text: '简体中文' },
    { id: "zh-TW", text: "中國台灣" },
    { id: "zh-HK", text: "中国香港" },
    { id: 'ru', text: 'russian' },
    { id: 'fr', text: 'french' },
    { id: 'de', text: 'german' },
    { id: 'jp', text: 'japanese' },
    { id: 'ko', text: 'korean' },
    { id: "in", text: "Indonesian" },
    { id: "ms", text: "Malay" },
    { id: "ar", text: "Arabic" },
    { id: "vi", text: "Vietnamese" },
    { id: "th", text: "Thai" },
    { id: "fil", text: "Filipino" },
  ];

  const [dropdownLanguages1, setDropdownLanguages1] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
        try {
            setIsLoading(true);
            setLoadingText("loading");
            const dataToSend = {
                id:1,
            };
            const data = await requestUrl(dataToSend, "get_user_language_list");
            
            if(data.status === "success"){
              console.log("list:" + JSON.stringify(data.list));
              
              setDropdownLanguages1(data.list);
              setTimeout(() => {
                setSelectedLanguage(data.userlanguage);
                setSelectedVoiceName(data.uservoicename);
              }, 500);
            }
          } 
          catch (error) {
              console.error("获取数据时出错:", error);
          }
          finally {
            setIsLoading(false);
          }
    };

    fetchData();
}, []);


  useEffect(() => {
    let vnameurl = "default/voice/" + selectedVoiceName + ".mp3";
    //let vnameurl = "default/voice/1.mp3";
    setMusicurl(vnameurl);
  }, [selectedVoiceName]);



  useEffect(() => {
    if (password && confirmPassword) {
      setIsSamePassword(password === confirmPassword);
    }
  }, [password, confirmPassword]);


  const handleSave = async () => {
    if (isSamePassword) {
      const dataToSend = {
        password: password,
      };
      localStorage.setItem('language', selectedLanguage);
      const data = await requestUrl(dataToSend, "setpassword");

      localStorage.setItem("userlanguage", selectedLanguage);
      if (data.status === "success") {
        alert("update password success");
        showSnackbar('success', t('update success'));
      }

    } else {
      console.log('The two passwords do not match.');
    }
  };

  const handleSettingLanguage = async () => {
    const dataToSend = {
      voicename: selectedVoiceName,
      language: selectedLanguage,
    };

    setIsLoading(true);
    
    const data = await requestUrl(dataToSend, "set_user_default_language");
    setIsLoading(false);
    
    if (data.status === "success") {
      showSnackbar(t('update success'),'success');
      localStorage.setItem('language', selectedLanguage);
      localStorage.setItem("userlanguage", selectedLanguage);
      localStorage.setItem("voicename", selectedVoiceName);
    }
  };

  const get_user_language = async () => {

    localStorage.setItem('language', selectedLanguage);
    const data = await requestUrlWithOutParameter("get_user_system_language");
    console.log("data111：" + JSON.stringify(data));
    if (data.status === "success") {
      showSnackbar('success', t('update success'));

    }
  };
  const [isMusicPlaying, setIsMusicPlaying] = useState(false); // 
  const musicRef = useRef(new Audio({ musicurl })); // 


  const toggleMusic = () => {
    console.log(musicurl);
    if (isMusicPlaying) {
      musicRef.current.pause();
    } else {
      if (musicRef.current) {
        musicRef.current.src = musicurl;
        musicRef.current.play().catch(e => console.error("播放失败:", e));
      }
    }
    setIsMusicPlaying(!isMusicPlaying);
  };
  // backgroundColor: 'darkblue'
  // style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', color: 'white', padding: '20px' }}


  const handleStudentPoll = () => {
    setShowStudentPoll(true);
  }

  const handleClickTeacherStyle = () => {
    //showSnackbar('操作成功！', 'success');
    setOpenTeacherStyle(true);
  }
  

  
  const handleCheckUpdate = async () => {
    let v = getAppVersion();

    const dataToSend = {
      version:v,
    };

    setIsLoading(true);
    
    const data = await requestUrl(dataToSend, "check_version");
    setIsLoading(false);
    
    if (data.status === "success") {
      
      if(v == data.appversion){
        showSnackbar(t('the current version is the latest version'),'success');
      }
      else{
        let path = data.path;
      // 下载新的安装包并安装
      try {
        window.location.href = path; // 触发下载
        // 监听下载完成事件
        window.addEventListener('focus', async () => {
          // 这里可以检查是否下载完成，例如检查某个标志文件是否存在
          // 当下载完成后，可以执行安装操作，例如使用第三方库进行安装
          // 注意：直接在浏览器中下载的 APK 文件无法自动触发安装，用户需要手动进行安装操作
        });
      } catch (error) {
        console.error('Error downloading APK:', error);
        showSnackbar('Error downloading APK', 'error');
      }
    }
  }
}

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={6}>
        <div className='card' style={{height:'100%'}}>
          {/* Password Settings */}
          <div className='form-item'>
            <div className='label'>新密码</div>
            <TextField
              className='input'
              type="password"
              label={t('new password')}
              variant="filled"
              InputLabelProps={{ style: { color: 'white' } }}
              InputProps={{ style: { color: 'white' } }}
              style={{ marginBottom: '20px', backgroundColor: 'rgba(255, 255, 255, 0.1)' }}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>
          <div className='form-item'>
            <div className='label'>确认密码</div>
            <TextField
              className='input'
              type="password"
              label={t('Confirm Password')}
              variant="filled"
              InputLabelProps={{ style: { color: 'white' } }}
              InputProps={{ style: { color: 'white' } }}
              style={{ marginBottom: '20px', backgroundColor: 'rgba(255, 255, 255, 0.1)' }}
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
            />
          </div>
          <CustomButton
          width="100%"
            disabled={!isSamePassword}
            onClick={handleSave}
          >
            {t('save')}
          </CustomButton>
          <br/>
          <br/>
          <CustomButton
            width="100%"
           
            onClick={() => {
              window.open("https://zhiguai.net", '_blank');
            }}
          >
            {t('access zhigu offical website')}
          </CustomButton>
        </div>
      </Grid>
      <Grid item xs={12} md={6}>
        {/* New Container */}
        <div className='card' style={{height:'100%'}}>
          <div style={{ marginTop: '40px', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <p>{t('The language settings here will take effect in the following functions')}</p>
            <p style={{ fontSize: "14px" }}>{t('Audio and video transcription language')}</p>
            <p style={{ fontSize: "14px" }}>{t('Text summary language')}</p>
            <p style={{ fontSize: "14px" }}>{t('Language for all question and answer responses')}</p>
            <div style={{ marginBottom: '20px', width: "100%" }}>  {/* Add this line */}
              <CommonCombobox
                preferredValue={selectedLanguage}
                setPreferredValue={setSelectedLanguage}
                options={dropdownLanguages}
                placeholder={t('select language')}
                customWidth="100%"
                labelText={t('select language')}
                className="form-item white-text"
              />
              <CommonCombobox
                preferredValue={selectedVoiceName}
                setPreferredValue={setSelectedVoiceName}
                options={dropdownLanguages1}
                placeholder={t('Transcription voice sound selection')}
                customWidth="100%"
                labelText={t('Choose your favorite sound')}
                className="form-item white-text"
              />
            </div>  {/* Add this line */}
            <IconButton
              onClick={toggleMusic}
              color="warning"
              style={{

                backgroundColor: '#b9b9b9', // 背景颜色
                border: '1px solid #ccc', // 边框
                boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.5)', // 阴影效果
                borderRadius: '50%', // 确保圆形
              }}
            >
              <PlayArrowIcon />
            </IconButton>
            <br />
            <CustomButton
              onClick={handleSettingLanguage}
            >
              {t('confirm')}
            </CustomButton>
            {/* 
            <br />
            <CustomButton 
            onClick={handleStudentPoll}
            >
                {t('qa')}
            </CustomButton>
              <br />
            
            <CustomButton
            onClick={handleClickTeacherStyle}>
                {t('teacher style')}
            </CustomButton>

*/}
            <br />
            <CustomButton
            onClick={handleCheckUpdate}>
                {t('check update')}
            </CustomButton>
            <div style={{ marginBottom: '20px', width: "100%" }}>  {/* Add this line */}

            </div>  {/* Add this line */}

          </div>
        </div>
      </Grid>


      {showStudentPoll && (
        
        <StudentPollComponent/>
     
      )}

      <Modal
        open={openTeacherStyle}
        onClose={handleCloseTeacherStyle}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        BackdropProps={{ onClick: handleCloseTeacherStyle }}
        sx={{ zIndex: 9999 }}
      >
        <TeacherStyle onClose={onCloseTeacherStyle}/>
      </Modal>

      {isLoading && (
        <LoadingData text={t(loadingText)}/>
      )}
    </Grid>
  );
};

export default UserSetting;
