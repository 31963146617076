import React, { useEffect, useRef } from 'react';
import MessageEng from './MessageEng';

import styled from 'styled-components';

const ScrollableDiv = styled.div`
  height: 70vh;
  overflow-y: scroll;

  &::-webkit-scrollbar {
    width: 12px;
  }

  &::-webkit-scrollbar-track {
    background-color: #F0F0F0;  /* 轨道的背景颜色 */
    border-radius: 10px;  /* 轨道的圆角 */
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: #555;
    transition: background-color 0.3s ease;
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: #333;
  }
`;


const MessageListEng = ({ messages }) => {
  // Mock data for demonstration
  
  
  // Use mock data if messages prop is not provided

  const messageListRef = useRef(null);


  useEffect(() => {
    if (messageListRef.current) {
      messageListRef.current.scrollTop = messageListRef.current.scrollHeight;
    }
  }, [messages]);



  return (
    <ScrollableDiv ref={messageListRef}>
      {messages.map((message, index) => (
        <MessageEng
          key={index}
          chatid={message.chatid}
          messageType={message.messageType}
          englishText={message.englishText}
          audioUrl={message.audioUrl}
          chineseText={message.chineseText}
          time={message.time}
          avatarUrl={message.avatarUrl}
        />
      ))}
    </ScrollableDiv>
  );
};

export default MessageListEng;
