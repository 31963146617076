import React, { useEffect, useState } from 'react';
import { Button, Container, Grid, Paper, Typography, Table, TableBody, TableCell, TableHead, TableRow,TableContainer } from '@mui/material';
import { useTranslation } from 'react-i18next';
import {requestUrl, requestUrlWithOutParameter} from './requestUrl'
import IconButton from '@mui/material/IconButton';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';


const styles = {
  container: {
    padding: '20px',
  },
  paper: {
    padding: '20px',
    marginBottom: '20px'
  },
  table: {
    minWidth: '300px',
  }
};

const MyOrder = () => {
  const [orders, setOrders] = useState([]);
  const { t } = useTranslation();

  const [rows, setRows] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await requestUrlWithOutParameter("get_rulebilling");
        setRows(data.rulebilling);

        setOrders(data.userorder);
      } catch (error) {
        console.error("获取数据时出错:", error);
      }
    };

    fetchData();
  }, []);


  const handleOrderClick = (id) => {
    // Handle the order logic here
    alert("Ordering for ID:", id);
    // You might want to call an API or update some state here
};

  return (
    <Container style={styles.container}>

      <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                      <TableRow>
                         
                          <TableCell align="right">Level Name</TableCell>
                          <TableCell align="right">Price</TableCell>
                          <TableCell align="right">生效天数</TableCell>
                          <TableCell align="right">Order</TableCell>
                          {/* ... 其他列 */}
                      </TableRow>
                  </TableHead>
                  <TableBody>
                      {rows.map((row) => (
                          <TableRow key={row.id}>
                             
                              <TableCell align="right">{row.level_name}</TableCell>
                              <TableCell align="right">{row.price}{row.currency_symbol}</TableCell>
                              <TableCell align="right">{row.subscription_day}</TableCell>
                              <TableCell align="right">
                                <IconButton onClick={() => handleOrderClick(row.id)}>
                                    <ShoppingCartIcon />
                                </IconButton>
                            </TableCell>
                          </TableRow>
                      ))}
                  </TableBody>
              </Table>
          </TableContainer>



      <Grid item xs={12}>
          <Paper style={styles.paper}>
            <Typography variant="h6" style={{ color: 'black' }}>Package Comparison</Typography>
            <Typography variant="body2" style={{ color: 'black' }}>1 Month Service: Includes basic support, 50GB storage</Typography>
            <Typography variant="body2" style={{ color: 'black' }}>3 Months Service: Includes priority support, 100GB storage</Typography>
            <Typography variant="body2" style={{ color: 'black' }}>1 Year Service: Includes premium support, unlimited storage</Typography>
          </Paper>
        </Grid>
        {/* Purchase Software Service */}

       
        
            <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table" style={styles.table}>
              <TableHead>
                <TableRow>
                  <TableCell>{t('Cost')}</TableCell>
                  <TableCell>{t('支付方式')}</TableCell>
                  <TableCell>{t('购买周期')}</TableCell>
                  <TableCell>{t('Expiry Date')}</TableCell>
                  <TableCell>{t('Service Type')}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {orders.map((order, index) => (
                  <TableRow key={index}>
                    <TableCell>{order.pay_fee}.{order.currency_symbol}</TableCell>
                    <TableCell>{order.pay_type}</TableCell>
                    <TableCell>{order.subscribe_day}</TableCell>
                    <TableCell>{order.expire_time}</TableCell>
                    <TableCell>{order.level_name}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            </TableContainer>
          
    </Container>
  );
};

export default MyOrder;
