import React, { useState, useEffect } from 'react';

import Button from '@mui/material/Button';
import { requestUrl } from '../requestUrl';
import LoadingData from '../education/dataloading';
import Modal from '@mui/material/Modal';
import CustomButton from '../components/CustomButton';
const ImageGallery = ({ folder,onClose }) => {
    const [images, setImages] = useState([]);
    const [selectedImage, setSelectedImage] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [loadingText,setLoadingText] = useState(false);
    // 当组件挂载或文件夹名称更改时，从后端加载图片
    useEffect(() => {
        const fetchImages = async () => {
            try {
                setIsLoading(true);
                setLoadingText("loading");
                const dataToSend = {
                    foldername:folder,
                };
                const data = await requestUrl(dataToSend, "get_images_from_folder");
                console.log(JSON.stringify(data));
                
                setImages(data);
                
              } catch (error) {
                  console.error("获取数据时出错:", error);
              } finally {
                setIsLoading(false);
              }
        };

        fetchImages();
    }, [folder]);

    const handleImageClick = (image) => {
        setSelectedImage(image);
    };

    const handleCloseViewer = () => {
        setSelectedImage(null);
    };

    const handleDeleteImage = (id) => {
        setImages(images.filter(image => image.id !== id));
        handleCloseViewer();
    };

    const handleCopyUrl = (url) => {
        navigator.clipboard.writeText(url);
        alert('URL copied to clipboard');
    };
    const galleryStyle = {
        position: 'fixed', // 使用固定定位
        top: '50%', // 垂直居中
        left: '50%', // 水平居中
        transform: 'translate(-50%, -50%)', // 确保准确居中
        backgroundColor: 'white',
        padding: '20px',
        border: '1px solid #ccc',
        borderRadius: '10px',
        zIndex: 1000 // 确保在其他元素之上
    };
    return (
        <div style={galleryStyle}>
            {images.map(image => (
                <img 
                    key={image.id} 
                    src={image.url} 
                    alt={image.title} 
                    style={{ width: 100, height: 100, margin: 10 }} 
                    onClick={() => handleImageClick(image)} 
                />
            ))}
            {selectedImage && (
                <Modal open={Boolean(selectedImage)} onClose={handleCloseViewer}>
                    <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', backgroundColor: 'white', padding: 20 }}>
                        <img src={selectedImage.url} alt={selectedImage.title} style={{ maxWidth: '100%', maxHeight: '80vh' }} />
                        <CustomButton onClick={() => handleCopyUrl(selectedImage.url)}>Copy URL</CustomButton>
                        <CustomButton onClick={() => handleDeleteImage(selectedImage.id)}>Delete</CustomButton>
                    </div>
                </Modal>
            )}
            <CustomButton onClick={onClose} style={{ marginTop: '20px' }}>Close Gallery</CustomButton>
        </div>
    );
};

export default ImageGallery;
