import React, { useState ,useEffect} from 'react';
import { useTranslation } from 'react-i18next';
import { Stepper, Step, StepLabel, Typography, Box,Button } from '@mui/material';
import { requestUrl } from '../../requestUrl';
import LoadingData from '../dataloading';
import { useSnackbar } from '../../SnackbarProvider';
// 模拟数据
const mockData = [
  {"time":"2024-02-21 11:12:34","request_type":"gpt","model":"gpt35","request_name":"openai","request_result":"结果圣诞节法律上快递费","request_list":[{"role": "user", "content":"songlixun"},{"role": "assistant", "content": "slsss"}]},
  {"time":"2024-02-21 11:12:34","request_type":"gpt","model":"gpt40","request_name":"openai","request_result":"结果圣诞节法律上快递费","request_list":[{"role": "user", "content":"songlixun"},{"role": "assistant", "content": "slsss"}]},
  {"time":"2024-02-21 11:12:34","request_type":"wolfram","model":"gpt40","request_name":"openai","request_result":"结果圣诞节法律上快递费","request_list":"这是wolfram的结果"}
];

const ViewPrompt = ({ chatId, viewPromptType,onClose }) => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false)
  const [loadingText,setLoadingText] = useState('loading');
  const showSnackbar = useSnackbar();
  const [requestHistory,setRequestHistory] = useState([]);
  // 根据commonChatId和viewPromptType过滤数据，这里仅展示如何使用mockData
  const steps = requestHistory.map(item => `${item.request_name} - ${item.time}`);




  useEffect(() => {
    const fetchData = async () => {
        try {
            setIsLoading(true);
            setLoadingText("loading");
            const dataToSend = {
                chatId:chatId,
                viewPromptType:viewPromptType,
            };
            const data = await requestUrl(dataToSend, "get_request_history",t,showSnackbar);
            
            if(data.status === "success"){
              setRequestHistory(data.data);
                
            }
          } 
          catch (error) {
              console.error("获取数据时出错:", error);
          }
          finally {
            setIsLoading(false);
          }
    };

    fetchData();
}, []);


  return (
    <Box sx={{ width: '80%', maxHeight: '400px', overflow: 'auto' }}>
      <Stepper orientation="vertical">
        {requestHistory.map((data, index) => (
         
          <Step key={index} active={true}>
            <Typography variant="body1" fontWeight="bold" fontSize="1.2rem" color='black'>
              第{index+1}次请求:<br />
              {`Time: ${data.time}, Model: ${data.model}, Request Name: ${data.request_name}`}
            </Typography>
            
            
            {data.request_type === 'gpt' || data.request_type === 'azure' ? (
              <Box sx={{ ml: 4 }}>
                {data.request_list.map((item, itemIndex) => (
                  <>
                   <Typography variant="body1" fontWeight="bold" fontSize="1.2rem" color='black'>
                   {`Role: ${item.role},Content:`}
                 </Typography>
                  <Typography sx={{color:'black'}} key={itemIndex} variant="body2">{` ${item.content}`}</Typography>
                  </>
                ))}
              </Box>
            )  : (
              <Typography variant="body2" sx={{ ml: 4 ,color:'black'}}>
                {data.request_list}
              </Typography>
            )}
            <Typography variant="body1" fontWeight="bold" fontSize="1.2rem" color='black'>
              Result:
            </Typography>

            <Typography variant="body1" sx={{ mt: 2, mb: 1 ,color:'black'}}>
              {`${data.request_result}`}
            </Typography>
          </Step>
        ))}
      </Stepper>

      <Button onClick={onClose}>close</Button>
      {isLoading && (
          <LoadingData text={t(loadingText)}/>
      )}
    </Box>
  );
};

export default ViewPrompt;
