export const getColorDark = () => {
    let colorManagerDark = [
        { container: "div-bg", color: '#031019' },
        { container: "paper-bg", value: '#f5f5f5' },
        { container: "message-item-bg", value: '#152531' },//单条聊天消息的消息背景色，messageItem
        { container: "message-item-bg-s", value: '#152531' },//单条聊天消息的消息背景色,s端用户端，messageItem
        { container: "message-item-bg-all", value: '#031019' },//单条聊天消息的主背景色，messageItem
        { container: "small-icon-color", value: '#42BFD0' },
        
        { container: "mui-textfield-bg", value: '#152531' },//输入框背景色
        { container: "mui-textfield-text-color", value: 'white' },//输入框文字颜色

        { container: "navigator-bg", value: '#031320' },//侧边栏导航背景色

        { container: "mui-button-bg", value: '#06374C' },//按钮背景色
        { container: "mui-button-text-color", value: 'white' },//按钮文字颜色
        { container: "headline-color", value: '#42BFD0' },//标题文字颜色

        { container: "pop-window-bg", value: '#031320' },//弹窗背景色
        { container: "pop-window-border-color", value: '#42BFD0' },//弹窗边框线条颜色

        { container: "pop-window-text-color", value: '#42BFD0' },//弹窗文字颜色
        { container: "word-button", value: '#ce5964' },
        { container: "word-text", value: '#f5f5f5' },

        { container: "mul-button-bg-selected", value: 'white' },//多按钮：被选中背景色
        { container: "mul-button-bg", value: '#152531' },//多按钮：被选中文字颜色
        { container: "mul-button-color-selected", value: '#42BFD0' },//多按钮：未被选中背景色
        { container: "mul-button-color", value: '#42BFD0' },//多按钮：未被选中文字颜色

        { container: "paragraphs-line-color", value: 'yellow' },
      ];
    
    
      return colorManagerDark;
};

export const getColorLight = () => {
    let colorManagerLight = [
        { container: "div", color: '#f5f5f5' },
        { container: "paper", value: '#f5f5f5' },
        { container: "message-item-bg", value: '#164E5C' },

        { container: "word-button", value: '#f5f5f5' },
        { container: "word-text", value: '#f5f5f5' },
      ];
    
    return colorManagerLight;
    
};

export const getTheme = () => {
    const themeName = localStorage.getItem('theme');//此处存储的是数字1-12，而不是汉字，
    if (themeName) {
      
      if(themeName === "dark"){
        return getColorDark();
      }

      
      if(themeName === "light"){
        return getColorLight();
      }
    }
    else{
      return getColorDark();
    }
    
};


 
