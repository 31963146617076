import katex from "katex";
import "katex/dist/katex.css";
import ReactQuill, { Quill } from "react-quill";
import "react-quill/dist/quill.snow.css";
import "./jquery.js";
import MathQuill from 'react-mathquill';
import './mathquill4quill.css';
import mathquill4quill from "./mathquill4quill.js"
import React from "react";
import { addStyles, EditableMathField } from 'react-mathquill'
import './Editor.css';
import { withTranslation } from 'react-i18next';

import i18n from "./../i18n.js";

import { getColorDark,getColorLight } from "../ColorManager.js";


window.katex = katex;
addStyles()

export default class EditorNoClassName extends React.Component {
  
  constructor(props) {
    super(props);
    this.reactQuill = React.createRef();
    this.handleResize = this.handleResize.bind(this);
    this.getEditorStyle = this.getEditorStyle.bind(this);
    this.state = {
      editorStyle: this.getEditorStyle(),
      lastCursorPosition: null, // 初始化光标位置
    };
  }
  

  componentDidMount() {
    const enableMathQuillFormulaAuthoring = mathquill4quill({ Quill, katex });
    enableMathQuillFormulaAuthoring(
      this.reactQuill.current.editor,
      this.props.options
    );
    window.addEventListener('resize', this.handleResize);
  
    // 添加 selection-change 事件监听器
    this.reactQuill.current.getEditor().on('selection-change', this.handleSelectionChange);
  }
  

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  
    // 移除 selection-change 事件监听器
    this.reactQuill.current.getEditor().off('selection-change', this.handleSelectionChange);
  }
  

  handleResize = () => {
    this.setState({ editorStyle: this.getEditorStyle() });
  };

  //<Editor width="500px" height="400px" />  <Editor ref={editorRef} options={options} key={JSON.stringify(options)}/>
  getEditorStyle1 = () => {
    // 如果有传入宽度和高度，就使用它们
    if (this.props.width && this.props.height) {
      return {
        width: this.props.width,
        height: this.props.height
      };
    }

    // 否则，按照现有的方式进行设置
    const width = window.innerWidth;
    const height = window.innerHeight;
    if (width <= 768) { // Mobile devices
      return {
        width: `${width * 0.95}px`,
        height: `${height * 0.95}px`
      };
    } else { // Tablets and desktops
      return {
        width: '350px',
        height: '300px'
      };
    }
  };

  getEditorStyle = () => {
    // 初始化样式对象
    let style = {
      backgroundColor: '#152531', // 背景色
      color: 'white',// 文字颜色
    };
  
    // 如果有传入宽度和高度，就使用它们
    if (this.props.width && this.props.height) {
      style.width = this.props.width;
      style.height = this.props.height;
      return style;
    }
  
    // 否则，按照现有的方式进行设置
    const width = window.innerWidth;
    const height = window.innerHeight;
    if (width <= 768) { // Mobile devices
      style.width = `${width * 0.95}px`;
      style.height = `${height * 0.95}px`;
    } else { // Tablets and desktops
      style.width = '350px';
      style.height = '300px';
    }
    return style;
  };
  


// 在 Editor 类中
getTextFromEditor = () => {
  if (this.reactQuill.current) {
    return this.reactQuill.current.getEditor().getText();
  }
  return null;
}

getFullContents = () => {
  if (this.reactQuill.current) {
    return this.reactQuill.current.getEditor().getContents();
  }
  return null;
}


// 在 Editor 类中添加这个方法
setFullContents = (delta) => {
  if (this.reactQuill.current) {
    this.reactQuill.current.getEditor().setContents(delta);
  }
}

getCursorLocation = () => {
  if (this.reactQuill.current) {
    // 获取 Quill 编辑器实例
    const editor = this.reactQuill.current.getEditor();
    // 获取当前的选择范围
    const range = editor.getSelection();
    if (range) {
      return range.index;
    }
  }
  return null; // 如果没有获取到光标位置，则返回 null
}


insertFormulaAtCursor1 = (formula) => {
  let position = this.getCursorLocation();
  
  if (position === null) {
    // 如果当前没有获取到光标位置，使用上次记录的光标位置
    position = this.state.lastCursorPosition;
  }

  if (position !== null) {
    const editor = this.reactQuill.current.getEditor();
    editor.insertEmbed(position, 'formula', formula);
    editor.setSelection(position + 1);
    // 更新 lastCursorPosition 以反映新的光标位置
    this.setState({ lastCursorPosition: position + 1 });
  }
}

insertFormulaAtCursor = (formula,showSnackbar) => {
  let position = this.getCursorLocation();

  if (position === null) {
    // 如果当前没有获取到光标位置，使用上次记录的光标位置
    position = this.state.lastCursorPosition;
    if(position === null){
      showSnackbar(i18n.t('点击编辑区，设置光标位置'),'warning');
    }
  }

  if (position !== null) {
    const editor = this.reactQuill.current.getEditor();

    // 使用正则表达式匹配被$包裹的公式
    const regex = /(\$.*?\$)/g;
    let match;
    let lastIndex = 0;

    // 遍历所有匹配项并插入到编辑器中
    while ((match = regex.exec(formula)) !== null) {
      // 插入公式前的普通文本
      const textBeforeFormula = formula.substring(lastIndex, match.index);
      if (textBeforeFormula) {
        editor.insertText(position, textBeforeFormula);
        position += textBeforeFormula.length;
      }

      // 插入公式，移除公式两侧的$
      const mathFormula = match[0].slice(1, -1);
      editor.insertEmbed(position, 'formula', mathFormula);
      position += 1; // 插入公式后更新位置

      lastIndex = regex.lastIndex;
    }

    // 插入最后一个公式后的普通文本
    if (lastIndex < formula.length) {
      const textAfterLastFormula = formula.substring(lastIndex);
      editor.insertText(position, textAfterLastFormula);
      position += textAfterLastFormula.length;
    }

    editor.setSelection(position); // 移动光标到插入内容之后
    // 更新 lastCursorPosition 以反映新的光标位置
    this.setState({ lastCursorPosition: position });
  }
}




handleSelectionChange = (range, oldRange, source) => {
  if (range) {
    this.setState({ lastCursorPosition: range.index });
  }
};




render() {
  return (
    <ReactQuill 
      ref={this.reactQuill}
      id="editor"
      modules={{
        formula: true,
        toolbar: [["video", "bold", "italic", "underline", "formula"]]
      }}
      placeholder={i18n.t('Type text here, or click on the formula button to enter math')}
      theme="snow"
      style={this.state.editorStyle}
    />
  );
}
}