import React, { useState, useEffect, useRef } from 'react';
import ChatWindow from '../ChatWindow/ChatWindow';
import { useSnackbar } from '../../SnackbarProvider';
import { getHostName } from '../../utils/urlUtils';
import CommonCombobox from '../../components/CommonCombobox';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, MenuItem, IconButton,Grid,Modal ,Box} from '@mui/material';
import { Menu as MuiMenu } from '@mui/material';
import styled from 'styled-components';
import './FamilyHead.css';
import AudioRecorder from '../AudioRecorder/AudioRecorder';
import { useTranslation } from 'react-i18next';
import ReactMarkdown from 'react-markdown';
import { deviceType_m_t_d } from '../../utils/commonUtils';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, TablePagination } from '@mui/material';
import SystemHelp from '../SystemHelp/SystemHelp';
import TextField from '@mui/material/TextField';
import { useNavigate } from 'react-router-dom';
import { requestUrl } from '../../requestUrl';
import DeleteIcon from '@mui/icons-material/Delete';
import { Refresh } from '@mui/icons-material';
import LoadingData from '../dataloading';
import Draggable from 'react-draggable';
import SettingsIcon from '@mui/icons-material/Settings';
import CategoryIcon from '@mui/icons-material/Category';
import AddCardIcon from '@mui/icons-material/AddCard';
import QuizIcon from '@mui/icons-material/Quiz';
import WebAssetIcon from '@mui/icons-material/WebAsset';
import CustomButton from '../../components/CustomButton';
import { getColorDark,getColorLight } from '../../ColorManager';

export const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 6666;
`;

export const ModalContainer = styled.div`
  background-color: white;
  padding: 10px;
  width: 100%;
  max-width: 800px;
  height: 100%;
  max-height: 600px;
  overflow-y: auto;
  border-radius: 8px;
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.1);
`;

const StyledButton = styled(Button)`
  margin-top: 200px; // 用于演示向上弹出

`;

const FamilyHead = () => {
  const { t } = useTranslation();
  const [selectedGradeOption, setSelectedGradeOption] = useState('7');
  const [selectedSubjectOptionText, setSelectedSubjectOptionText] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [messages, setMessages] = useState([]);
  const [inputText, setInputText] = useState(''); // 文本框的值
  const [isKeepHistory, setIsKeepHistory] = useState(false);
  const [uploadedImage, setUploadedImage] = useState(null); //从inputarea中传过来的上传图片
  const [cuspredefinedContent, setCuspredefinedContent] = useState([]);
  const [open, setOpen] = useState(false);
  const [menuAnchorElement, setMenuAnchorElement] = useState(null);
  const [selectedType, setSelectedType] = useState(t('Common Q'));
  const [selectedTypeId, setSelectedTypeId] = useState('1');
  const [menuAnchorElementPage, setMenuAnchorElementPage] = useState(null);
  const [selectedPageNumber, setSelectedPageNumber] = useState(1);
  const [pageList, setPageList] = useState([1]);
  const [isShowAudioRecorder, setIsShowAudioRecorder] = useState(false);
  const [translateText, setTranslateText] = useState('');
  const [anchorEl, setAnchorEl] = useState(null)
  const blankImageList = useRef([]);
  const blankMarkdownText = useRef(null);
  const [isOpenHistory, setIsOpenHistory] = useState(false);
  const [blankPageContentElement, setBlankPageContentElement] = useState();//填充刚加载页面的空白element
  const [isDisplayBlankContent, setIsDisplayBlankContent] = useState(true);
  const [sessionChatId, setSessionChatId] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [numberOfRowsPerPage, setNumberOfRowsPerPage] = useState(10);
  const [selectedRowId, setSelectedRowId] = useState(null);
  const [tableData, setTableData] = useState([]);
  const [selectedRowData, setSelectedRowData] = useState(null);
  const [isNeedCreateSeesionChat, setIsNeedCreateSeesionChat] = useState(true);
  const showSnackbar = useSnackbar();
  const inputAreaRef = useRef(null);

  const [dialogOpen, setDialogOpen] = useState(true);
  const [password, setPassword] = useState('');

  const navigate = useNavigate();
  const [loadingText,setLoadingText] = useState('loading');
  const [questionTypeList, setQuestionTypeList] = useState([
    { id: 1, value: '通用问题' },
    { id: 2, value: '古诗文' }
  ]);


  const flagMessFromItem = useRef(false);
  const [gradeOptions, setGradeOptions] = useState([
    { id: '1', text: '1' },
    { id: '2', text: '2' },
    { id: '3', text: '3' },
    { id: '4', text: '4' },
    { id: '5', text: '5' },
    { id: '6', text: '6' },
    { id: '7', text: '7' },
    { id: '8', text: '8' },
    { id: '9', text: '9' },
    { id: '10', text: '10' },
    { id: '11', text: '11' },
    { id: '12', text: '12' },
    // ... Add more options
  ]);

  const [colorManager, setColorManager] = useState(getColorDark());


  useEffect(() => {

    const themeName = localStorage.getItem('theme');//此处存储的是数字1-12，而不是汉字，
    if (themeName) {
      
      if(themeName === "dark"){
        setColorManager(getColorDark());
      }

      
      if(themeName === "light"){
        setColorManager(getColorLight());
      }
    }
    else{
      let aa = getColorDark();
      setColorManager(aa);
    }

    
  }, []);

  const get_color_by_name = (name) => {

    const item = colorManager.find(item => item.container === name);
    return item.value;
  }


  /////////////////////////悬浮按钮////////////////////////////////

  const [isButtonOpen, setIsButtonOpen] = useState(false);
  const [buttonPosition, setButtonPosition] = useState({ top: 100, right: 30 });
  const [isDragging, setIsDragging] = useState(false);
  const [dragOffset, setDragOffset] = useState({ x: 0, y: 0 }); // 保留偏差状态

  const togglePanel = () => {
    if (!isDragging) {
      setIsButtonOpen(!isButtonOpen);
    }
  };

  const startDrag = (e) => {
    e.preventDefault();
    setIsDragging(false);

    // 根据是触摸还是鼠标事件，计算初始偏差
    const clientX = e.type === 'touchstart' ? e.touches[0].clientX : e.clientX;
    const clientY = e.type === 'touchstart' ? e.touches[0].clientY : e.clientY;
    const rect = e.target.getBoundingClientRect();
    const offsetX = clientX - rect.left;
    const offsetY = clientY - rect.top;
    setDragOffset({ x: offsetX, y: offsetY }); // 更新偏差

    const onMouseMove = (moveEvent) => {
      setIsDragging(true);
      const moveX = moveEvent.type === 'touchmove' ? moveEvent.touches[0].clientX : moveEvent.clientX;
      const moveY = moveEvent.type === 'touchmove' ? moveEvent.touches[0].clientY : moveEvent.clientY;

      // 应用偏差来计算新位置
      const newTop = moveY - dragOffset.y;
      const newRight = window.innerWidth - moveX - (50 - dragOffset.x); // 假设按钮宽度为50px，根据需要调整

      setButtonPosition({
        top: Math.max(0, Math.min(window.innerHeight - 50, newTop)),
        right: Math.max(0, Math.min(window.innerWidth - 50, newRight)),
      });
    };

    const onMouseUp = () => {
      document.removeEventListener('mousemove', onMouseMove);
      document.removeEventListener('mouseup', onMouseUp);
      document.removeEventListener('touchmove', onMouseMove);
      document.removeEventListener('touchend', onMouseUp);
      // 延迟设置isDragging为false
      setTimeout(() => {
        if (isDragging) {
          setIsDragging(false);
        }
      }, 10); // 10毫秒延迟，可根据需要调整
    };

    document.addEventListener('mousemove', onMouseMove);
    document.addEventListener('mouseup', onMouseUp);
    document.addEventListener('touchmove', onMouseMove, { passive: false });
    document.addEventListener('touchend', onMouseUp);
  };

  //这个函数的作用，没有这个函数导致点击面板内容会隐藏面板，
  //当点击面板内容时，事件冒泡导致触发了悬浮按钮的 togglePanel 方法，这会改变面板的可见状态。
  //您需要在面板内容的点击事件中调用 event.stopPropagation() 方法，这将阻止事件继续冒泡到父元素。
  const preventHidePanel = (e) => {
    e.stopPropagation(); // 阻止事件冒泡
  };


  /**要实现点击面板以外的区域以隐藏面板的功能，您可以监听整个文档的点击事件，并判断点击是否发生在面板或悬浮按钮上。如果不是，则隐藏面板。这涉及到几个关键步骤：
  监听文档的点击事件：在组件挂载（useEffect 中 componentDidMount）时添加全局点击事件监听，并在组件卸载时移除监听（componentWillUnmount）。
  判断点击区域：当全局点击事件触发时，检查点击事件的目标元素（event.target），并判断它是否属于悬浮按钮或面板的一部分。
  条件性隐藏面板：如果点击发生在悬浮按钮或面板之外，就隐藏面板。
  总结：点击面板以外区域，隐藏面板
  **/
  useEffect(() => {
    const handleOutsideClick = (event) => {
      // 检查点击是否发生在悬浮按钮或面板内
      const isButtonClick = document.getElementById("floating-button")?.contains(event.target);
      const isPanelClick = document.getElementById("panel-content")?.contains(event.target);
      if (!isButtonClick && !isPanelClick && isButtonOpen) {
        setIsButtonOpen(false); // 如果点击在这些元素之外，隐藏面板
      }
    };

    // 绑定点击事件
    document.addEventListener('click', handleOutsideClick);

    // 组件卸载时移除事件监听
    return () => {
      document.removeEventListener('click', handleOutsideClick);
    };
  }, [isButtonOpen]); // 依赖项数组中包含isButtonOpen，以便在其变化时更新


  /////////////////////////悬浮按钮代码结束////////////////////////////////






  // 如果需要在组件加载时自动弹出对话框，可以使用useEffect
  useEffect(() => {
    setDialogOpen(true);
  }, []);

  const [isShowSystemHelp, setIsShowSystemHelp] = useState(false);
  const onCloseHelp = () => {

    setIsShowSystemHelp(false);
  }


  const handleSystemHelp = () => {
    setIsShowSystemHelp(true);
  };


  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const year = date.getFullYear().toString().slice(-2); // 获取年份的后两位
    const month = String(date.getMonth() + 1).padStart(2, '0'); // 月份从0开始，所以要+1
    const day = String(date.getDate()).padStart(2, '0');
    const hour = String(date.getHours()).padStart(2, '0');

    return `${year}-${month}-${day}:${hour}`;
  };

  const loadSubjectData = async (page) => {
    if (!selectedGradeOption) {
      
      showSnackbar('warning', t('please select your grade level first'));
      return;
    }

    const token = localStorage.getItem("access_token");
    setAnchorEl(null);
    try {
      setIsLoading(true);
      const dataToSend = {
        subject: '',
        grade_value: selectedGradeOption,
        common_chat_session_id: sessionChatId,
        page_size: 10,
        page_number: page,
      };

      const url = getHostName() + '/get_familyhead_history';
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify(dataToSend),
      });
      if (response.ok) {
        setIsDisplayBlankContent(false);//默认空白页的图片不显示
        const data = await response.json();

        setMessages(data.data);
        //setCuspredefinedContent(data.prompts);
        //setQuestionTypeList(data.qtypes);

        setIsKeepHistory(true);
        if (page === 1) {
          setPageList(data.total_page);
        }
        setIsNeedCreateSeesionChat(false);//无需创建sessionchat表数据
        setIsLoading(false);
      }
      else {
        console.error("Error fetching reply:", response.statusText);
      }
    }
    catch (error) {
      setIsLoading(false);
      console.error("There was an error fetching the reply:", error);
    }
  }
  const [detailPrompts, setDetailPrompts] = useState([]);
  //加载推荐问题列表和问题类型列表
  const load_prompts_questionTypes = async (subject, grade) => {
    const token = localStorage.getItem("access_token");
    try {
      setIsLoading(true);
      const dataToSend = {
        grade_value: grade,
      };

      const url = getHostName() + '/get_familyhead_prompts'; // 你的服务端URL
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify(dataToSend),
      });
      if (response.ok) {

        const data = await response.json();
        setCuspredefinedContent(data.prompts);
        setQuestionTypeList(data.qtypes);
        let detailPrompts = data.question_types_details_prompts;
        setDetailPrompts(detailPrompts);

        blankImageList.current = data.images;
        blankMarkdownText.current = data.mdtext;

        let element = renderOverlay(data.images, data.mdtext);
        setBlankPageContentElement(element);
        setIsLoading(false);
        setTableData([]);

        setSessionChatId(0);
        setSelectedRowId(0);
      }
      else {
        console.error("Error fetching reply:", response.statusText);
      }
    }
    catch (error) {
      setIsLoading(false);
      console.error("There was an error fetching the reply:", error);
    }
  };

  const set_problem_type = async (problemTypeId) => {
    setSelectedTypeId(problemTypeId); //问题类型设置为默认
    const item = questionTypeList.find(item => item.id === problemTypeId);
    setSelectedType(item.value);//修改界面
  }


  const handleSendMessage = async (message) => {
    console.log("send");
    if (message === "") {
      console.log("sss");
      showSnackbar('warning', t('please input content'));
      

      return;
    }

    if (selectedGradeOption === null) {
      showSnackbar('warning', t('you must select subject and grade'));
      setTimeout(() => {
        inputAreaRef.current.setInputValue(message);
      }, 1000);

      return;
    }

    setIsDisplayBlankContent(false);
    let dddd;
    if (uploadedImage) {
      const dddd = `<img src=${uploadedImage} style="height: 50vh; max-width: 100vw;" alt="upload Image"/>`;
      message = message + dddd;
      // 有图片
    } else {
      // 没有图片
    }

    // 处理发送消息逻辑
    setMessages(prevMessages => [...prevMessages, { sender: 'S', text: message, time: new Date().toLocaleTimeString() }]);
    try {

      const token = localStorage.getItem("access_token");
      setIsLoading(true);

      let dataimage = null;
      if (uploadedImage === null) {
        dataimage = 'none';
      }
      else {
        dataimage = uploadedImage;
      }
      //qtype
      let messsend = message;

      if (flagMessFromItem.current == true) {
        messsend = messsend + "<questionflag>chatinner</questionflag>";
        flagMessFromItem.current = false;

      }
      const dataToSend = {
        gradeValue: selectedGradeOption,
        inputValue: message,
        qtype: selectedTypeId,
        isKeepHistory: isKeepHistory,
        image: dataimage,
        common_chat_session_id: sessionChatId,
        isNeedCreateSeesionChat: isNeedCreateSeesionChat,
      };
      console.log(JSON.stringify(dataToSend));
      const url = getHostName() + '/question_familyhead'; // 你的服务端URL
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify(dataToSend),
      });

      if (response.ok) {
        setUploadedImage(null);
        setIsKeepHistory(true);//保留记忆
        set_problem_type(1);
        const dat_server = await response.json();
        const { dbid, dbsessionid, response_text } = dat_server;
        if (sessionChatId === '0') { //刚加载为0 ，新建为0，对话后，这个返回值肯定大于0，只要开始对话，这个值就会大于0，因为数据库新增了记录或者从客户端直接把大于0 的值带了过去，然后同样回传
          setSessionChatId(dbsessionid);
        }
        setMessages(prevMessages => [...prevMessages, { id: dbid, sender: 'R', text: response_text, time: new Date().toLocaleTimeString() }]);
        //uploadedImage = null;
        setUploadedImage(null);
        setIsLoading(false);

        setIsNeedCreateSeesionChat(false);
      }
      else {
        console.error("Error fetching reply:", response.statusText);
        set_problem_type(1);
      }
    }
    catch (error) {
      setIsLoading(false);
      set_problem_type(1);
      console.error("There was an error fetching the reply:", error);
    }
  };

  //选择年级事件
  const handleMenuItemGradeClick = async (e) => {
    setSelectedGradeOption(e.text); // 2. 设置选中的选项

    localStorage.setItem('familyheagrade', e.text);
    load_prompts_questionTypes('', e.text);
  };

  useEffect(() => {

    const defaultGrade = localStorage.getItem('familyheagrade');//此处存储的是数字1-12，而不是汉字，
    if (defaultGrade) {
      setSelectedGradeOption(defaultGrade);
      load_prompts_questionTypes('', defaultGrade);
    }
    else {
      load_prompts_questionTypes('', "1");
    }


  }, []);

  const handleClearRecord = () => {
    setMessages([]);
    setIsKeepHistory(false);//不保留记忆，已经清除记录
    setAnchorEl(null);
    setIsDisplayBlankContent(true);//显示空白页的默认图
    setIsNeedCreateSeesionChat(true);
    setSelectedRowId(0);
    setSessionChatId(0);
  }


  const onReceiveStringFromMessItem = (str) => {
    // 处理发送消息逻辑
    flagMessFromItem.current = true;

    handleSendMessage(str);
  };

  const handleImageUpload = (imageData) => {
    console.log("input图片:" + imageData);
    setUploadedImage(imageData);
  }

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleClick11 = (event) => {
    setMenuAnchorElement(event.currentTarget);
  };

  //选择问题类型事件
  const handleClose11 = (id, type) => {
    console.log(id);
    setMenuAnchorElement(null);
    if (type !== null && id !== null) {
      setSelectedType(type);
      setSelectedTypeId(id);

      setCuspredefinedContent(detailPrompts[id]);
    }
  };


  const handleClickPageNumber = (event) => {
    setMenuAnchorElementPage(event.currentTarget);
  };

  const handleClosePage = (pagenumber) => {
    setMenuAnchorElementPage(null);
    if (pagenumber !== null) {
      setSelectedPageNumber(pagenumber);
    }
  };

  const handlePageChange = (pagenumber) => {
    setMenuAnchorElementPage(null);
    if (pagenumber !== null) {
      setSelectedPageNumber(pagenumber);

      loadSubjectData(pagenumber);
    }
  };

  const handleAudioRecord = () => {
    setIsShowAudioRecorder(true);
  }

  const onCloseOnly = () => {
    setIsShowAudioRecorder(false);
  }

  const onCloseAudioRecord = () => {
    setIsShowAudioRecorder(false);
    //setIsetInputValueEnable(true);
    let textOld = inputAreaRef.current.getInputValue();

    inputAreaRef.current.setInputValue(textOld + translateText);
    //setIsetInputValueEnable(false);
  }

  const handleImageClick = (subject, imgName) => {
    const filenameWithExtension = imgName.split('/').pop(); // 提取最后一个路径组件，即 "math2.png"
    const filename = filenameWithExtension.split('.').slice(0, -1).join('.'); // 移除扩展名

    if (selectedSubjectOptionText === 'mathematics') {
      if (filename === 'math1') {
        alert('You clicked Algebra');
      }
      if (filename === 'math2') {
        alert('You clicked Geometry');
      }
      if (filename === 'math3') {
        alert('You clicked Geometry');
      }
      if (filename === 'math4') {
        alert('You clicked Geometry');
      }
      // ... other math images
    }
    if (selectedSubjectOptionText === 'physical') {
      if (filename === 'phys1') {
        alert('You clicked Physics');
      }
      if (filename === 'phys2') {
        alert('You clicked Chemistry');
      }
      if (filename === 'phys3') {
        alert('You clicked Chemistry');
      }
      if (filename === 'phys4') {
        alert('You clicked Chemistry');
      }
      // ... other physical images
    }
  };


  const isMobile = window.innerWidth <= 768;
  const renderOverlay = (imageList, mktext) => {
    const device = deviceType_m_t_d();
    const containerStyles = {

      display: 'flex',
      flexDirection: isMobile ? 'column' : 'row',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      zIndex: 1000,
      overflow: 'hidden',
      width: isMobile ? '60%' : '70vw',
      height: isMobile ? '60%' : '500px'
    };

    // Initialize image size and layout direction based on the device type
    let imageSize = {};
    let imageContainerFlexDirection = 'column'; // Default to vertical alignment
    switch (device) {
      case 'm':
        imageSize = { width: '100px', height: '100px' };
        imageContainerFlexDirection = 'row'; // Horizontal alignment for mobile
        break;
      case 't':
        imageSize = { width: '200px', height: '200px' };
        break;
      case 'd':
        imageSize = { width: '400px', height: '400px' };
        break;
      default:
        imageSize = { width: 'auto', height: 'auto' };
        break;
    }

    if (isDisplayBlankContent) {
      return (
        <div style={containerStyles}>
          <div style={{
            marginBottom: '20px',
            marginRight: '20px',
            flexDirection: imageContainerFlexDirection,
            display: 'flex'
          }}>
            {imageList.map((imgSrc, index) => (
              <div key={index} style={{ marginBottom: imageContainerFlexDirection === 'column' ? '20px' : '0', marginRight: imageContainerFlexDirection === 'row' ? '20px' : '0' }}>
                <img
                  src={imgSrc}
                  alt={`image-${index}`}
                  style={{
                    ...imageSize,
                    maxHeight: '80%',
                    borderRadius: '20px',
                    cursor: 'pointer'
                  }}
                  onClick={() => handleImageClick(selectedSubjectOptionText, imgSrc)}
                />
              </div>
            ))}
          </div>
          <div style={{ wordWrap: 'break-word', overflowY: 'auto', padding: '10px', maxWidth: '70vw' }} className="centered-markdown">
            <ReactMarkdown>
              {mktext}
            </ReactMarkdown>
          </div>
        </div>
      );
    }
    return null;
  };

  //关闭主题历史组件
  const handleClosePTHistory = () => {
    setIsOpenHistory(false);

  };

  const handleGetPTHistory = async () => {
    try {
      const token = localStorage.getItem("access_token");
      const uids = localStorage.getItem("uid");
      const uid = parseInt(uids, 10);

      setIsLoading(true);
      let dataimage = null;
      if (uploadedImage === null) {
        dataimage = 'none';
      }
      else {
        dataimage = uploadedImage;
      }
      //qtype
      const dataToSend = {
        subject: '',
        grade: selectedGradeOption,
        userid: uid,
        page_size: 10,
        page_number: 1,
      };
      setIsLoading(true);
      const url = getHostName() + '/get_familyhead_chat_session_list'; // 你的服务端URL
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify(dataToSend),
      });

      if (response.ok) {
        setUploadedImage(null);
        const data = await response.json();
        
        setTableData(data.data);
        //setSelectedPTHistoryData(dat_server);
        setIsLoading(false);
      }
      else {
        console.error("Error fetching reply:", response.statusText);
        setIsLoading(false);
      }
    }
    catch (error) {
      setIsLoading(false);
      console.error("There was an error fetching the reply:", error);
    }
  };

  const handleChangePage = (event, newPage) => {
    setCurrentPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setNumberOfRowsPerPage(parseInt(event.target.value, 10));
    setCurrentPage(0);
  };

  //加载指定主题的对话记录
  const handleLoadChatPT = async () => {
    if (!selectedRowId) {
      
      showSnackbar('warning', t('Load Record, select a record to load'));
      return;
    }
    loadSubjectData(1);
    setIsOpenHistory(false);
  }

  const handleShowCommonChatList = () => {
    setIsOpenHistory(true);
  }

  const [passwordServer, setPasswordServer] = useState('');

  const handleDialogClose = () => {
    navigate('/Default');
  };



  const handleDialogPasswordOk = async () => {
    // 这里可以添加密码验证逻辑
    if (password === "") {
      
      showSnackbar('warning', t('please input your password'));
      return;
    }
    try {

      setIsLoading(true);

      const dataToSend = {
        password: password,
      };
      const data = await requestUrl(dataToSend, "validate_familyhead_password");

      if (data.status === "success") {
        setDialogOpen(false);
      }
      else {
        
        showSnackbar('success', t('password wrong'));
      }
    }
    catch (error) {
      console.error("获取数据时出错:", error);
    }
    finally {
      setIsLoading(false);
    }

  };


  const handleSettingPasswordServer = async () => {
    if (passwordServer === "") {
      
      showSnackbar('warning', t('please input your password'));
      return;
    }
    try {

      setIsLoading(true);

      const dataToSend = {
        password: passwordServer,
      };
      const data = await requestUrl(dataToSend, "set_familyhead_password");

      if (data.status === "success") {
        
        showSnackbar('success', t('update success'));
        setDialogOpen(false);
      }
    }
    catch (error) {
      console.error("获取数据时出错:", error);
    }
    finally {
      setIsLoading(false);
    }
  }


  const removeItem = (idToRemove) => {
    // 使用filter方法过滤掉id等于1的元素
    const filteredItems = tableData.filter(item => item.id !== idToRemove);

    // 更新状态以反映删除后的数组
    setTableData(filteredItems);
  };

  // 调用函数来删除id为1的元素

  //删除chatsession
  const handleDeleteChatSession = async (sessionid) => {
    try {
      setIsLoading(true);
      const dataToSend = {
        sessionid: sessionid,
      };
      const data = await requestUrl(dataToSend, "delete_familyhead_chat_session");

      if (data.status === "success") {
        removeItem(sessionid);

      }
    }
    catch (error) {
      console.error("获取数据时出错:", error);
    }
    finally {
      setIsLoading(false);
    }
  };








 // 容器A样式
 const containerAStyle = {
  backgroudColor:'#1565c0 !important',
  display: 'flex',
  height: '30px',
  alignItems: 'center',
  justifyContent: isMobile ? 'flex-start' : 'center',
  position: 'relative',
  border: '1px solid #ccc', // 辅助可视化
  padding: isMobile ? '0 10px' : '0', // 为手机视图添加左右内边距
  
};

  ///////////////////////////////

  const blinkAnimation = `
@keyframes blink-animation {
  0% { opacity: 1; }
  50% { opacity: 0; }
  100% { opacity: 1; }
}
`;

  const [isSettingButtonOpen, setIsSettingButtonOpen] = useState(false);
  const [settingDragging, setSettingDragging] = useState(false);

  const handleDrag = (e, data) => {
    if (data.deltaX !== 0 || data.deltaY !== 0) {
      setSettingDragging(true);
    }
  };

  const handleStop = () => {
    setTimeout(() => {
      setSettingDragging(false);
    }, 100);
  };

  const handleSettingClick = (e) => {
   e.stopPropagation(); // 阻止点击事件冒泡到document
   // 如果不是拖动行为，则切换弹出状态
   if (!settingDragging) {
     setIsSettingButtonOpen(!isSettingButtonOpen);
   }
 };

// 添加全局点击事件监听器以关闭容器
useEffect(() => {
 const handleClickOutside = (event) => {
   // 通过ref检查点击是否在容器或按钮外
   if (isSettingButtonOpen && !document.querySelector('[aria-label="settings"]').contains(event.target)) {
     setIsSettingButtonOpen(false);
   }
 };

 // 只有当容器打开时才监听点击事件
 if (isSettingButtonOpen) {
   document.addEventListener('click', handleClickOutside);
 }
 return () => {
   document.removeEventListener('click', handleClickOutside);
 };
}, [isSettingButtonOpen]); // 依赖于isSettingButtonOpen


const paperStyle = {
  padding: '20px',
  margin: '10px',
  backgroundColor: '#f5f5f5', // 浅乳白色背景
};

const buttonGridStyle = {
  marginBottom: '20px',
};


  return (
    <div>

      <div style={containerAStyle}>
      <div style={{ color: get_color_by_name('headline-color'), fontSize: '14px' }}>
         {selectedGradeOption}{t('grade')}
      </div>
        
        
      <span style={{ fontSize: '14px' }}>{t('Q type')}:</span>
      <div>
          <StyledButton onClick={handleClick11} sx={{ color: get_color_by_name('headline-color') }}>
            {selectedType}
          </StyledButton>
          <MuiMenu sx={{ color: get_color_by_name('headline-color') }}
            anchorEl={menuAnchorElement}
            open={Boolean(menuAnchorElement)}
            onClose={() => handleClose11(null)}
            anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
            transformOrigin={{ vertical: 'bottom', horizontal: 'left' }}
            getContentAnchorEl={null}
          >
            {questionTypeList.map(({ id, value }) => (
              <MenuItem
                sx={{ fontSize: '14px' }}
                key={id}
                selected={id === selectedTypeId} // 确保选中状态基于 selectedTypeId
                onClick={() => handleClose11(id, value)}
              >
                {value}
              </MenuItem>
            ))}


          </MuiMenu>
        </div>
        </div>
      
      <div>
        <Grid container spacing={0} >
        <Grid item xs={true === 2 ? 6 : 12}>
          <ChatWindow
            messages={messages}
            onSendMessage={handleSendMessage}
            predefinedContent={cuspredefinedContent}
            inputText={inputText}
            setInputText={setInputText}
            subject='familyhead'
            isLoading={isLoading}
            onReceiveStringFromMessItem={onReceiveStringFromMessItem}
            onImageUpload={handleImageUpload}
            showAudio={true}
            handleAudioRecord={handleAudioRecord}
            ref={inputAreaRef}
            isShowBackgroundHtml={isDisplayBlankContent}
            grade={selectedGradeOption}
            fromModule='familyhead'
          />
          </Grid>
        </Grid>
      </div>

      {isLoading && (
        <LoadingData text={t(loadingText)}/>
      )}
      <Dialog
        open={open}
        onClose={handleClose}
        sx={{
          zIndex: 6666, // 设置一个高的 z-index 值
          '& .MuiPaper-root': {
            width: {
              xs: '90%',
              sm: '500px',
            },
            maxWidth: '100%',
            backgroundColor:get_color_by_name('pop-window-bg'),
            border: `1px solid ${get_color_by_name('pop-window-border-color')}`,
          },
        }}>
        <DialogTitle style={{color:get_color_by_name('pop-window-text-color')}} sx={{ textAlign: 'center',fontSize: '24px' }}>
          {t('settings')}
        </DialogTitle>
        <DialogContent>
          {/* 
          <CommonCombobox
            preferredValue={selectedVersion}
            setPreferredValue={setSelectedVersion}
            options={dropdownMenuSVersion}
            onValueSelected={handleMenuItemSversionClick}
            placeholder="选择教材"
            labelText="教材"
          />
          */}
          <div className='form-item'>
            <div className='label'>密码</div>
            <TextField
              autoFocus
              margin="dense"
              id="password"
              label="密码"
              type="password"
              fullWidth
              sx={{backgroundColor:'white', color:'black'}}
              value={passwordServer}
              onChange={(e) => setPasswordServer(e.target.value)}
            />
          </div>
          <CommonCombobox
            preferredValue={selectedGradeOption}
            setPreferredValue={setSelectedGradeOption}
            options={gradeOptions}
            onValueSelected={handleMenuItemGradeClick}
            placeholder={t('select grade')}
            labelText={t('grade')}
            classNames='form-item white-bg'
          />
          {/* 
          <CommonCombobox
            preferredValue={selectedSemester}
            setPreferredValue={setSelectedSemester}
            options={dropdownMenuSemester}
            onValueSelected={handleMenuItemSemesterClick}
            placeholder="选择"
            labelText="学期"
          />*/}

        </DialogContent>
        <DialogActions>
          <Button
            variant='contained'
            onClick={handleSettingPasswordServer}
            fullWidth
            size='large'
            sx={{
              textTransform: 'none',
            }}
          >
            {t('重置密码')}
          </Button>
          <Button
            variant='contained'
            onClick={handleClose}
            fullWidth
            size='large'
            sx={{
              textTransform: 'none',
            }}
          >
            {t('close')}
          </Button>

        </DialogActions>
      </Dialog>

      {isShowSystemHelp && (
        <SystemHelp modulename="parent" subject="common" onClose={onCloseHelp} />
      )}
      {isShowAudioRecorder && (
        <Modal
        open={isShowAudioRecorder}
        onClose={onCloseAudioRecord}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{zIndex:5555}}
      >
          <AudioRecorder onCloseAudioRecord={onCloseAudioRecord} translateText={translateText} setTranslateText={setTranslateText} onCloseOnly={onCloseOnly} />
        </Modal>
      )}

      <Dialog open={isOpenHistory} onClose={handleClosePTHistory}
        sx={{

          '& .MuiPaper-root': {
            width: {
              xs: '98%', // 手机屏幕上宽度设置为 90%
              sm: '800px', // 平板和电脑屏幕上最大宽度为 500px
            },
            maxWidth: '100%', // 确保不会超出屏幕
          },
        }}>
          <div style={{border: `1px solid ${get_color_by_name('pop-window-border-color')}`,}}>
          <DialogTitle sx={{ textAlign: 'center', fontSize: '24px',fontFamily:'siyuan',backgroundColor:get_color_by_name('mui-button-bg') }}
          style={{color:get_color_by_name('headline-color')}}
        >
          {t('topics history records')}
          <Button variant="text" sx={{color:get_color_by_name('small-icon-color')}} onClick={handleGetPTHistory}>
            <Refresh></Refresh>
            {/* {t('load records')} */}
          </Button>
        </DialogTitle>
        <DialogContent sx={{backgroundColor:get_color_by_name('pop-window-bg')}}>
          <div>

            <div style={{backgroundColor:get_color_by_name('pop-window-bg')}}>
              <TableContainer component={Paper}>
                <Table>
                  <TableHead>
                    <TableRow sx={{backgroundColor:get_color_by_name('pop-window-bg')}}>
                      <TableCell sx={{fontFamily:'siyuan',color:get_color_by_name('headline-color')}}>{t('topics')}</TableCell>

                      <TableCell sx={{fontFamily:'siyuan',color:get_color_by_name('headline-color')}}>{t('time')}</TableCell>
                      <TableCell sx={{fontFamily:'siyuan',color:get_color_by_name('headline-color')}}>{t('delete')}</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {tableData.slice(currentPage * numberOfRowsPerPage, currentPage * numberOfRowsPerPage + numberOfRowsPerPage).map((row, index) => (
                      <TableRow key={row.id} selected={selectedRowId === row.id}
                        onClick={() => {
                          setSelectedRowId(row.id);
                          setSessionChatId(row.id);
                          setSelectedRowData(row);
                        }}
                        hover
                        style={{ cursor: 'pointer', backgroundColor: selectedRowId === row.id ? get_color_by_name('mui-button-bg') : get_color_by_name('pop-window-bg') }}
                      >
                        <TableCell sx={{fontFamily:'siyuan',color:get_color_by_name('headline-color')}}>{row.session_name}</TableCell>

                        <TableCell sx={{fontFamily:'siyuan',color:get_color_by_name('headline-color')}}>{formatDate(row.recordtime)}</TableCell>
                        <TableCell>
                          <IconButton aria-label="delete"  sx={{color:get_color_by_name('small-icon-color')}}   onClick={() => handleDeleteChatSession(row.id)}>
                            <DeleteIcon />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
                <TablePagination
                  sx={{ position: "relative", zIndex: 10000,backgroundColor:get_color_by_name('mui-textfield-bg') , borderRadius: '10px' }}
                  rowsPerPageOptions={[5, 10, 25]}
                  component="div"
                  count={tableData.length}
                  rowsPerPage={numberOfRowsPerPage}
                  page={currentPage}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </TableContainer>
            </div>
          </div>

        </DialogContent>
        <DialogActions sx={{backgroundColor:get_color_by_name('pop-window-bg')}}>
          <CustomButton onClick={handleLoadChatPT} fullWidth sx={{ textTransform: 'none' }}>{t('confirm')}</CustomButton>
          <CustomButton onClick={handleClosePTHistory} fullWidth sx={{ textTransform: 'none', }}>{t('close')}</CustomButton>
        </DialogActions>
        </div>
      </Dialog>

     


   {/**悬浮按钮代码----开始 */}
   <Draggable
          onDrag={handleDrag}
          onStop={handleStop}
        >
      <div style={{
        position: 'absolute',
        zIndex: 99999,
        right: '100px', // 距离屏幕右侧100px
        top: '30%', // 父容器的顶部距离视口顶部的距离为视口高度的一半
        transform: 'translateY(-50%)' // 向上移动元素高度的一半，实现垂直居中
      }}>        
        <style>
          {blinkAnimation}
        </style>
      


        <IconButton
          aria-label="settings"
          onClick={handleSettingClick}
          onTouchEnd={handleSettingClick} // 添加触摸事件处理
          sx={{
            '& .MuiSvgIcon-root': {
              color: get_color_by_name('small-icon-color'),
              animation: 'blink-animation 1s infinite'
            }
          }}
        >
          <SettingsIcon fontSize="large" />
        </IconButton>



        {/* 条件渲染自定义的容器或组件 */}
        {isSettingButtonOpen && (
          <div
            style={{
             
              position: 'absolute',
              top: '50px', // 根据需要调整
              right: '0',
              zIndex: 100000, // 确保容器位于按钮之上
              width:'400px',
              // 添加其他所需的样式
            }}
            onClick={(e) => e.stopPropagation()} // 防止容器内的点击事件冒泡
          >

    <Paper style={{
          padding: '20px',
          margin: '10px',
          backgroundColor:get_color_by_name('pop-window-bg'),
          border: `1px solid ${get_color_by_name('pop-window-border-color')}`,
        }} elevation={3}>
      <Grid container spacing={2}>
        <Grid item xs={6} style={buttonGridStyle}>
          <CustomButton
            onClick={handleClickOpen}
            onTouchEnd={(e) => {
              e.preventDefault(); // 阻止默认行为和冒泡，根据需要调整
              handleClickOpen(e);
            }}
            size="medium"
            variant="contained"
            startIcon={<CategoryIcon />}
            
            sx={{width:'170px'}}
          >
            {t('settings')}
          </CustomButton>


              
        </Grid>
        <Grid item xs={6} style={buttonGridStyle}>
          <CustomButton
            onClick={handleShowCommonChatList}
            onTouchEnd={(e) => {
              e.preventDefault(); // 阻止默认行为和冒泡，根据需要调整
              handleShowCommonChatList();
            }}
            size="medium"
            
            startIcon={<AddCardIcon />}
           
            sx={{width:'170px'}}
          >
            {t('add to category')}
          </CustomButton>
        </Grid>
        <Grid item xs={6}>
          <CustomButton
            onClick={handleShowCommonChatList}
            onTouchEnd={(e) => {
              e.preventDefault(); // 阻止默认行为和冒泡，根据需要调整
              handleShowCommonChatList();
            }}
            size="medium"
           
            startIcon={<AddCardIcon />}
            sx={{width:'170px'}}
          >
            {t('history')}
          </CustomButton>

          


        </Grid>
        <Grid item xs={6}>
          <CustomButton
            onClick={handleSystemHelp}
            onTouchEnd={(e) => {
              e.preventDefault(); // 阻止默认行为和冒泡，根据需要调整
              handleSystemHelp();
            }}
            size="medium"
            startIcon={<QuizIcon />}
            sx={{width:'170px'}}
          >
            {t('help')}
          </CustomButton>
        </Grid>

        <Grid container spacing={2} direction="column" alignItems="center" sx={{marginTop:'10px'}}>
      <Grid item>
      <h2><span style={{color:'black'}}>{t('grade')}</span></h2>
      </Grid>
      <Grid item container justifyContent="center" spacing={1}>
      {gradeOptions.map((option) => (
          <Grid item key={option.id}>
            <Button
              onClick={() => handleMenuItemGradeClick(option.id)}
              onTouchEnd={(e) => {
                e.preventDefault(); // 阻止默认行为和冒泡，根据需要调整
                handleMenuItemGradeClick(option.id);
              }}

              sx={{
                fontFamily:'siyuan',
                border: `1px solid ${get_color_by_name('pop-window-border-color')}`,
                backgroundColor: selectedGradeOption === option.id ? get_color_by_name('mul-button-bg-selected') : get_color_by_name('mul-button-bg'),
                color: selectedGradeOption === option.id ? get_color_by_name('mul-button-color-selected') : get_color_by_name('mul-button-color'),
                '&:hover': {
                  backgroundColor: selectedGradeOption === option.id ? 'darkgreen' : 'lightgrey',
                },
              }}
            >
              {option.text}
            </Button>
          </Grid>
        ))}
      </Grid>
      
    </Grid>
      </Grid>
    </Paper>
      </div>
    )}
      </div>
      </Draggable>
      {/**悬浮按钮代码----结束 */}



      <Box sx={{ position: 'fixed', bottom: 0, right: 0, zIndex: 8888 }}>

<Button
  onClick={handleClearRecord}
    onTouchEnd={(e) => {
      e.preventDefault(); // 阻止默认行为和冒泡，根据需要调整
      handleClearRecord();
    }}
    sx={{
      position: 'absolute', 
      bottom: '33vh', 
      right: 60,
      fontFamily:'siyuan',
      border: `1px solid ${get_color_by_name('pop-window-border-color')}`,
      backgroundColor: get_color_by_name('mul-button-bg'),
      color: get_color_by_name('mul-button-color'),
      
    }}
  >
    开始新对话
  </Button>
</Box>

    </div>
  );
};

export default FamilyHead;
