//properties for mapping
/* partial charges for proteins */
export const partialCharges = {
"ALA:N": -0.15,
"ALA:CA": 0.10,
"ALA:CB": 0.00,
"ALA:C": 0.60,
"ALA:O": -0.55,
"ARG:N": -0.15,
"ARG:CA": 0.10,
"ARG:CB": 0.00,
"ARG:CG": 0.00,
"ARG:CD": 0.10,
"ARG:NE": -0.10,
"ARG:CZ": 0.50,
"ARG:NH1": 0.25,
"ARG:NH2": 0.25,
"ARG:C": 0.60,
"ARG:O": -0.55,
"ASN:N": -0.15,
"ASN:CA": 0.10,
"ASN:CB": 0.00,
"ASN:CG": 0.55,
"ASN:OD1": -0.55,
"ASN:ND2": 0.00,
"ASN:C": 0.60,
"ASN:O": -0.55,
"ASP:N": -0.15,
"ASP:CA": 0.10,
"ASP:CB": 0.00,
"ASP:CG": 0.14,
"ASP:OD1": -0.57,
"ASP:OD2": -0.57,
"ASP:C": 0.60,
"ASP:O": -0.55,
"CYS:N": -0.15,
"CYS:CA": 0.10,
"CYS:CB": 0.19,
"CYS:SG": -0.19,
"CYS:C": 0.60,
"CYS:O": -0.55,
"GLN:N": -0.15,
"GLN:CA": 0.10,
"GLN:CB": 0.00,
"GLN:CG": 0.00,
"GLN:CD": 0.55,
"GLN:OE1": -0.55,
"GLN:NE2": 0.00,
"GLN:C": 0.60,
"GLN:O": -0.55,
"GLU:N": -0.15,
"GLU:CA": 0.10,
"GLU:CB": 0.00,
"GLU:CG": 0.00,
"GLU:CD": 0.14,
"GLU:OE1": -0.57,
"GLU:OE2": -0.57,
"GLU:C": 0.60,
"GLU:O": -0.55,
"GLY:N": -0.15,
"GLY:CA": 0.10,
"GLY:C": 0.60,
"GLY:O": -0.55,
"HIS:N": -0.15,
"HIS:CA": 0.10,
"HIS:CB": 0.00,
"HIS:CG": 0.10,
"HIS:ND1": -0.10,
"HIS:CD2": 0.10,
"HIS:NE2": -0.40,
"HIS:CE1": 0.30,
"HIS:C": 0.60,
"HIS:O": -0.55,
"ILE:N": -0.15,
"ILE:CA": 0.10,
"ILE:CB": 0.00,
"ILE:CG2": 0.00,
"ILE:CG1": 0.00,
"ILE:CD": 0.00,
"ILE:C": 0.60,
"ILE:O": -0.55,
"LEU:N": -0.15,
"LEU:CA": 0.10,
"LEU:CB": 0.00,
"LEU:CG": 0.00,
"LEU:CD1": 0.00,
"LEU:CD2": 0.00,
"LEU:C": 0.60,
"LEU:O": -0.55,
"LYS:N": -0.15,
"LYS:CA": 0.10,
"LYS:CB": 0.00,
"LYS:CG": 0.00,
"LYS:CD": 0.00,
"LYS:CE": 0.25,
"LYS:NZ": 0.75,
"LYS:C": 0.60,
"LYS:O": -0.55,
"MET:N": -0.15,
"MET:CA": 0.10,
"MET:CB": 0.00,
"MET:CG": 0.06,
"MET:SD": -0.12,
"MET:CE": 0.06,
"MET:C": 0.60,
"MET:O": -0.55,
"PHE:N": -0.15,
"PHE:CA": 0.10,
"PHE:CB": 0.00,
"PHE:CG": 0.00,
"PHE:CD1": 0.00,
"PHE:CD2": 0.00,
"PHE:CE1": 0.00,
"PHE:CE2": 0.00,
"PHE:CZ": 0.00,
"PHE:C": 0.60,
"PHE:O": -0.55,
"PRO:N": -0.25,
"PRO:CD": 0.10,
"PRO:CA": 0.10,
"PRO:CB": 0.00,
"PRO:CG": 0.00,
"PRO:C": 0.60,
"PRO:O": -0.55,
"SER:N": -0.15,
"SER:CA": 0.10,
"SER:CB": 0.25,
"SER:OG": -0.25,
"SER:C": 0.60,
"SER:O": -0.55,
"THR:N": -0.15,
"THR:CA": 0.10,
"THR:CB": 0.25,
"THR:OG1": -0.25,
"THR:CG2": 0.00,
"THR:C": 0.60,
"THR:O": -0.55,
"TRP:N": -0.15,
"TRP:CA": 0.10,
"TRP:CB": 0.00,
"TRP:CG": -0.03,
"TRP:CD2": 0.10,
"TRP:CE2": -0.04,
"TRP:CE3": -0.03,
"TRP:CD1": 0.06,
"TRP:NE1": -0.06,
"TRP:CZ2": 0.00,
"TRP:CZ3": 0.00,
"TRP:CH2": 0.00,
"TRP:C": 0.60,
"TRP:O": -0.55,
"TYR:N": -0.15,
"TYR:CA": 0.10,
"TYR:CB": 0.00,
"TYR:CG": 0.00,
"TYR:CD1": 0.00,
"TYR:CE1": 0.00,
"TYR:CD2": 0.00,
"TYR:CE2": 0.00,
"TYR:CZ": 0.25,
"TYR:OH": -0.25,
"TYR:C": 0.60,
"TYR:O": -0.55,
"VAL:N": -0.15,
"VAL:CA": 0.10,
"VAL:CB": 0.00,
"VAL:CG1": 0.00,
"VAL:CG2": 0.00,
"VAL:C": 0.60,
"VAL:O": -0.55
};
    
//this can be supplied to mapAtomProperties
export function applyPartialCharges(atom, keepexisting) {
    if(!keepexisting || typeof(atom.partialCharge) === "undefined") {
        if(atom.resn && atom.atom) {
            var key = atom.resn+":"+atom.atom;
            atom.properties.partialCharge = partialCharges[key];
        }
    }
};
