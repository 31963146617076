/**
 * src/hooks/langaugeCode.js
 * Common utils for language i18n.
 *
 * created by pycui on 7/28/23
 */

// A mapping from preferredLanguage to language code.
export const languageCode = {
  English: 'en-US',
  Chinese: 'zh-CN',
  French: 'fr-FR',
  German: 'de-DE',
  Italian: 'it-IT',
  Japanese: 'ja-JP',
  Korean: 'ko-KR',
  Portuguese: 'pt-PT',
  Russian: 'ru-RU',
  Spanish: 'es-ES',
  Hindi: 'hi-IN',
  Polish: 'pl-PL',
};
