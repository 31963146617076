import React, { useState,useEffect } from 'react';
import { Box } from '@mui/material';
import { requestUrl } from '../../requestUrl';

import LoadingData from '../dataloading';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { useTranslation } from 'react-i18next';

import { useSnackbar } from '../../SnackbarProvider';
const SystemHelp = ({modulename,subject,onClose}) => {
  const { t } = useTranslation();
  const showSnackbar = useSnackbar();
    const style = {
        zIndex:9999,
        position: 'fixed',  // 使用 fixed 而非 absolute 以便始终居中
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '70vw',
        height: '70vh',
        backgroundColor: 'white',  // 可以自定义背景颜色
        boxShadow: '0 2px 10px rgba(0, 0, 0, 0.3)',  // 可选的阴影效果
       
        // 添加其他必要的样式
      };

      const [isLoading, setIsLoading] = useState(false);
      const [loadingText,setLoadingText] = useState(false);
      const [content,setContent] = useState('');

      useEffect(() => {
        const fetchImages = async () => {
            try {
                setIsLoading(true);
                setLoadingText("loading");
                const dataToSend = {
                    module:modulename,
                    subject:subject,
                };
                const data = await requestUrl(dataToSend, "query_system_help",t,showSnackbar);
                if(data.status === "success"){
                    
                    setContent(data.content);
                }
                
                
              } catch (error) {
                  console.error("获取数据时出错:", error);
              } finally {
                setIsLoading(false);
              }
        };

        fetchImages();
    }, [modulename]);
    const [isVisible, setIsVisible] = useState(true);
    const handleClose = () => setIsVisible(false);

    if (!isVisible) return null;

    return (
        <Box style={style} sx={{ position: 'relative' }}>
            <IconButton 
        sx={{ position: 'absolute', top: 0, right: 0 }} 
        onClick={onClose}
      >
        <CloseIcon />
      </IconButton>
      <Box 
        sx={{ 
            '& *': { color: 'black' }, // 这会尝试将所有子元素的颜色设置为黑色
        }} 
        dangerouslySetInnerHTML={{ __html: content }} 
        />
      {isLoading && (
        <LoadingData text={t(loadingText)}/>
        )}
    </Box>
    );
};

export default SystemHelp;
