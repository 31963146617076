
import { requestUrl } from '../requestUrl';

import LoadingData from '../education/dataloading';
import { useTranslation } from 'react-i18next';
import React, { useState, useEffect } from 'react';
import { TextField, Button, Box,InputAdornment } from '@mui/material';
import TextareaAutosize from '@mui/material/TextareaAutosize';
const SystemConfig = () => {
    const [configs, setConfigs] = useState([]);
    const [loading, setLoading] = useState(false);
    const [loadingText,setLoadingText] = useState('loading');
    const { t } = useTranslation();



    const textFieldStyles = {
        input: { 
            color: 'black', // 设置输入文本的颜色
            overflowY: 'auto' // 自动滚动条
        },
        label: { color: 'black' }
    };

    
    useEffect(() => {
        // 假设 fetchData 是一个获取数据的异步函数
        const fetchData = async () => {
            try {
                setLoading(true);
                setLoadingText("loading");
                const dataToSend = {
                    id:1,
                };
                const data = await requestUrl(dataToSend, "get_system_config");
                console.log(JSON.stringify(data));
                if(data.status === "success"){
                    setConfigs(data.data);
                   console.log(JSON.stringify(data.data));
                }
              } 
              catch (error) {
                  console.error("获取数据时出错:", error);
              }
              finally {
                setLoading(false);
              }
        };

        fetchData();
    }, []);

    const handleInputChange = async (index, event) => {

        const newConfigs = [...configs];
        newConfigs[index][event.target.name] = event.target.value;
        setConfigs(newConfigs);
    };

    const handleUpdate = async (index,sckey,scvalue) => {
        // 发送更新请求到后端

        try {
            setLoading(true);
            setLoadingText("loading");
            const dataToSend = {
                sckey:sckey,
                scvalue:scvalue,
            };
            const data = await requestUrl(dataToSend, "update_system_config_i");
            console.log(JSON.stringify(data));
            if(data.status === "success"){
                const newConfigs = [...configs];
                newConfigs[index][sckey] = scvalue;
                setConfigs(newConfigs);
            }
          } 
          catch (error) {
              console.error("获取数据时出错:", error);
          }
          finally {
            setLoading(false);
          }
    };

    return (
        <Box sx={{ margin: '20px',backgroundColor:'white' }}>
            {configs.map((config, index) => (
                <Box key={config.id} sx={{ marginBottom: '20px' }} gap={10}>
                    <span style={{color:'black'}}>{config.sctitle}::{config.scdes}</span>
                    <br/>
                    <TextField sx={{width:'80%'}}
    name="scvalue"
    value={config.scvalue}
    onChange={(e) => handleInputChange(index, e)}
    multiline
    InputProps={{
        inputComponent: TextareaAutosize,
        inputProps: {
            style: { height: '30px', overflowY: 'auto', color: 'black' },
            minRows: 2,
        },
        // 在这里添加 InputAdornment
        endAdornment: (
            <InputAdornment position="end">
                <Button
                    variant="contained"
                    color="primary"
                    onClick={() => handleUpdate(index, config.sckey, config.scvalue)}
                >
                    {t('update')}
                </Button>
            </InputAdornment>
        ),
    }}
    InputLabelProps={{ style: textFieldStyles.label }}
/>
<br></br>
                </Box>
            ))}

        {loading && (
      <LoadingData text={t(loadingText)}/>
    )}
        </Box>
    );
};

export default SystemConfig;
