import React, { useState,useEffect } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, TextField,Button } from '@mui/material';
import { requestUrl } from '../requestUrl';
import LoadingData from '../education/dataloading';
import { useSnackbar } from '../SnackbarProvider';
import { useTranslation } from 'react-i18next';


const WenXinYYSettingComponent = () => {
    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [loadingText,setLoadingText] = useState(false);
    const { t } = useTranslation();

    useEffect(() => {
        const fetchData = async () => {
            try {
                setIsLoading(true);
                setLoadingText("loading");
                const dataToSend = {
                    id:1,
                };
                const data = await requestUrl(dataToSend, "get_wwyy_model_data");
                
                if(data.status === "success"){
                    setData(data.data);
                    console.log(data.data);
                }
              } 
              catch (error) {
                  console.error("获取数据时出错:", error);
              }
              finally {
                setIsLoading(false);
              }
        };

        fetchData();
    }, []);
  
    const handleClientIDChange = (index, event) => {
      const newData = [...data];
      newData[index].client_id = event.target.value;
      setData(newData);
    };
  
    const handleSecretKeyChange = (index, event) => {
      const newData = [...data];
      newData[index].secret_key = event.target.value;
      setData(newData);
    };

    const handleUpdateKey = async (index,row)  => {
        const dataToSend = {
            modelname:row.modelname,
            clientid:row.client_id,
            secretkey:row.secret_key,
        };

        try {
            setIsLoading(true);
            setLoadingText("loading");
            
            const data = await requestUrl(dataToSend, "update_wwyy_key");
            
            if(data.status === "success"){
                const new_token = data.data;
                console.log("newtoken:" + new_token);
                const newData = [...data];
                newData[index].token = new_token;
                newData[index].token_time = data.tokentime;
                setData(newData);
                
            }
          } 
          catch (error) {
              console.error("获取数据时出错:", error);
          }
          finally {
            setIsLoading(false);
          }

        console.log(dataToSend);
        
    }
  
    return (
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Modelname</TableCell>
              <TableCell>Token Time</TableCell>
              <TableCell>Token</TableCell>
              <TableCell>Client ID</TableCell>
              <TableCell>Secret Key</TableCell>
              <TableCell>update</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((row, index) => (
              <TableRow key={index}>
                <TableCell>{row.modelname}</TableCell>
                <TableCell>{row.token_time}</TableCell>
                <TableCell>{row.token}</TableCell>
                <TableCell>
                  <TextField
                    value={row.client_id}
                    sx={{ input: { color: 'black' } }}
                    onChange={(event) => handleClientIDChange(index, event)}
                  />
                </TableCell>
                <TableCell>
                  <TextField
                    value={row.secret_key}
                    sx={{ input: { color: 'black' } }}
                    onChange={(event) => handleSecretKeyChange(index, event)}
                  />
                </TableCell>

                <TableCell align="right" sx={{ width: '15%' }}>
                <Button 
                  onClick={() => handleUpdateKey(index,row)}
                  variant="contained" 
                  color="primary" 
                  sx={{ marginRight: '20px' }}
                >
                  update
              </Button>
              </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>

        {isLoading && (
                <LoadingData text={t(loadingText)}/>
            )}

      </TableContainer>
    );
  };
  
  export default WenXinYYSettingComponent;