import React, { forwardRef,useState,useEffect,useRef} from 'react';
import MessageList from './MessageList';
import InputArea from './InputArea';
import { Card } from 'antd';
import { Button,IconButton,Paper } from '@mui/material';
import { useTranslation } from 'react-i18next';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import { t } from 'i18next';
import { requestUrl } from '../../requestUrl';
import LoadingData from '../dataloading';
import { Modal, List, ListItem, ListItemText,Box } from '@mui/material';
import ChatBubbleOutlineOutlinedIcon from '@mui/icons-material/ChatBubbleOutlineOutlined';
import Draggable from 'react-draggable';
import remarkMath from 'remark-math';
import rehypeKatex from 'rehype-katex';
import rehypeRaw from 'rehype-raw'; // 引入 rehype-raw 插件
import ReactMarkdown from 'react-markdown'; 
import StopIcon from '@mui/icons-material/Stop';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import Slider from '@mui/material/Slider';
import { useSnackbar } from '../../SnackbarProvider';
import { getHostNameWeb } from '../../utils/urlUtils';
import CustomButton from '../../components/CustomButton';
import { getColorDark,getColorLight } from '../../ColorManager';

const ChatWindow = forwardRef(({
  messages, 
  onSendMessage, 
  predefinedContent = [], 
  inputText, 
  setInputText,
  subject,
  isLoading,
  onReceiveStringFromMessItem,
  onImageUpload,
  showAudio,
  handleAudioRecord,
  useWf,
  getPointKnowledgeMap,
  isShowBackgroundHtml,
  grade,
  fromModule,
  questionType,
  showAddImage,
  showWritingConfig,
  isShowWritingConfigWindow
}, ref) => {

  const { t } = useTranslation();
  const [isLoading1, setIsLoading1] = useState(false);
  const [loadingText,setLoadingText] = useState('loading');
  const [showBgHtmlList,setShowBgHtmlList] = useState(false);
  const [selectedSrc,setSelectedSrc] = useState('');
  const [settingDragging, setSettingDragging] = useState(false);
  const [selectedAudioSrc,setSelectedAudioSrc] = useState('');
  const [selectedAudioSrcBgm,setSelectedAudioSrcBgm] = useState('');

  const [showContent,setShowContent] = useState(false);
  const [currentItemData,setCurrentItemData] = useState({});

  const [isPlaying, setIsPlaying] = useState(false);
  const [isPlayingBgm, setIsPlayingBgm] = useState(false);
  const audioRef = React.useRef(new Audio());
  const audioRefBgm = useRef(new Audio());
  const showSnackbar = useSnackbar();
  const [bgHtmlListData, setBgHtmlListData] = useState([]);


  const [colorManager, setColorManager] = useState(getColorDark()); // 初始化为空数组

  //获取颜色设置，系统加载时设置
useEffect(() => {

const themeName = localStorage.getItem('theme');//此处存储的是数字1-12，而不是汉字，
if (themeName) {
  
  if(themeName === "dark"){
    setColorManager(getColorDark());
  }

  
  if(themeName === "light"){
    setColorManager(getColorLight());
  }
}
else{
  let aa = getColorDark();
  setColorManager(aa);
}


}, []);



const get_color_by_name = (name) => {

const item = colorManager.find(item => item.container === name);
return item.value;
}



  const LoadBgHtmlList = async () => {
    try {
      setIsLoading1(true);
      setLoadingText("loading");
      const dataToSend = {
          subject:subject,
          grade:grade,
          fromModule:fromModule,
      };
      
      const data = await requestUrl(dataToSend, "get_bg_html",t,showSnackbar);
      if(data.status === "success"){
        setBgHtmlListData(data.data);
        if(data.data && data.data.length > 0) {
          //const firstItem = data.data[0];
          //const firstItem = data.data.filter(item => item.is_first === 1);
          const firstItem = data.data.find(item => item.is_first === 1);
          setCurrentItemData(firstItem);
          // 假设 firstItem 中包含 audioSrc 属性
          const bgsrc = getHostNameWeb() + "/";
          
          setSelectedSrc(bgsrc+firstItem.bg_src);
          setSelectedAudioSrc(bgsrc + firstItem.mp3_path);
          setSelectedAudioSrcBgm(bgsrc + firstItem.bg_music_name);
        }
      }
      if(data.status === "failure"){
        showSnackbar("there is nothing data.","warning");
      }
      
      
    } catch (error) {
      console.log("获取数据时出错:", "error");
    } finally {
      setIsLoading1(false);
    }
  }




  useEffect(() => {
    const fetchData = async () => {
      LoadBgHtmlList();
    };

    if(isShowBackgroundHtml === true){
      fetchData();
    }
    else{
      setShowContent(false);
    }
  }, [isShowBackgroundHtml,subject]);



  

  useEffect(() => {
    const bgsrc = getHostNameWeb() + "/";
    if(fromModule === "feynman"){
      setSelectedSrc(bgsrc + 'jsbg/pen-export-EydXrZ/dist/index.html');
      setSelectedAudioSrc(bgsrc + 'jsbg/bgm/xjcy.mp3');
    }
    if(fromModule === "familyhead"){
      setSelectedSrc(bgsrc + 'jsbg/just-an-svg-animated-turbulence-filter/dist/index.html');
      setSelectedAudioSrc(bgsrc + 'jsbg/bgm/xjcy.mp3');
    }
    if(fromModule === "subject"){
      setSelectedSrc(bgsrc + 'jsbg/css-3d-solar-system/dist/index.html');
      setSelectedAudioSrc(bgsrc + 'jsbg/bgm/xjcy.mp3');
    }

    if(fromModule === "science"){
      setSelectedSrc(bgsrc + 'jsbg/fake-hacking-program/dist/index.html');
      setSelectedAudioSrc(bgsrc + 'jsbg/bgm/xjcy.mp3');
      
    }
  }, [fromModule]);

  const handleItemClick = (item) => {
    setShowBgHtmlList(false);
    setSelectedSrc(item.bg_src);
    setSelectedAudioSrcBgm(item.bg_music_name);
    setSelectedAudioSrc(item.mp3_path);
    setCurrentItemData(item);
  };


  const handleDrag = (e, data) => {
    if (data.deltaX !== 0 || data.deltaY !== 0) {
      setSettingDragging(true);
    }
  };

  const handleStop = () => {
    setTimeout(() => {
      setSettingDragging(false);
    }, 100);
  };

  const divRef = useRef(null);
  const toggleFullScreen = () => {
    if (!document.fullscreenElement &&    // 标准方法
        !document.mozFullScreenElement && !document.webkitFullscreenElement && !document.msFullscreenElement ) { // 兼容不同浏览器
        // 进入全屏
        const elem = divRef.current;
        if (elem.requestFullscreen) {
            elem.requestFullscreen();
        } else if (elem.mozRequestFullScreen) { /* Firefox */
            elem.mozRequestFullScreen();
        } else if (elem.webkitRequestFullscreen) { /* Chrome, Safari and Opera */
            elem.webkitRequestFullscreen();
        } else if (elem.msRequestFullscreen) { /* IE/Edge */
            elem.msRequestFullscreen();
        }
    } else {
        // 退出全屏
        if (document.exitFullscreen) {
            document.exitFullscreen();
        } else if (document.mozCancelFullScreen) { /* Firefox */
            document.mozCancelFullScreen();
        } else if (document.webkitExitFullscreen) { /* Chrome, Safari and Opera */
            document.webkitExitFullscreen();
        } else if (document.msExitFullscreen) { /* IE/Edge */
            document.msExitFullscreen();
        }
    }
};


// 监听selectedAudioSrc变化，以及处理播放和停止逻辑
useEffect(() => {
  const playAudio = async (audioElement) => {
    try {
      await audioElement.play();
      console.log("播放成功");
    } catch (error) {
      console.error("播放失败", error);
    }
  };

  if (selectedAudioSrc) {
    audioRef.current.src = selectedAudioSrc;
    audioRef.current.load(); // 确保重新加载audio元素
    //playAudio(audioRef.current);
    //setIsPlaying(true);
    //audioRef.current.onended = () => setIsPlaying(false);
  }
  
  if (selectedAudioSrcBgm) {
    audioRefBgm.current.src = selectedAudioSrcBgm;
    audioRefBgm.current.load(); // 确保重新加载audio元素
    playAudio(audioRefBgm.current);
    setIsPlayingBgm(true);
    audioRefBgm.current.onended = () => setIsPlayingBgm(false);
  }

  return () => {
    audioRef.current.pause();
    audioRef.current.currentTime = 0;

    audioRefBgm.current.pause();
    audioRefBgm.current.currentTime = 0;
  };
}, [selectedAudioSrc, selectedAudioSrcBgm]);


// 控制播放和暂停
const togglePlay = () => {
  if (isPlaying) {
    audioRef.current.pause();
  } else {
    if (selectedAudioSrc) {
      audioRef.current.play().catch(error => console.error("播放失败", error));
    }
  }
  setIsPlaying(!isPlaying);
};

const togglePlayBgm = () => {
  if (isPlayingBgm) {
    audioRefBgm.current.pause();
  } else {
    if (selectedAudioSrcBgm) {
      audioRefBgm.current.play().catch(error => console.error("播放失败", error));
    }
  }
  setIsPlayingBgm(!isPlayingBgm);
};

const [peopleVolume, setpeopleVolume] = useState(50); // 默认音量为50%
const [peopleVolumeBgm, setpeopleVolumeBgm] = useState(20); // 默认音量为50%


useEffect(() => {
  audioRef.current.volume = peopleVolume / 100;
}, [peopleVolume]);

useEffect(() => {
  audioRefBgm.current.volume = peopleVolumeBgm / 100;
}, [peopleVolumeBgm]);


const handleVolumeChangePeople = (event, newValue) => {
  setpeopleVolume(newValue);
};

const handleVolumeChangePeopleBgm = (event, newValue) => {
  setpeopleVolumeBgm(newValue);
};


useEffect(() => {
  // 确保audioRef和audioRefBgm引用了有效的音频元素
  if (audioRef.current && audioRefBgm.current) {
    // 如果已经加载音频，则停止播放并重置播放位置
    audioRef.current.pause();
    audioRef.current.currentTime = 0; // 重置音频到开始位置，如果需要的话
    audioRefBgm.current.pause();
    audioRefBgm.current.currentTime = 0; // 重置背景音乐到开始位置，如果需要的话
    
    // 更新播放状态为false
    setIsPlaying(false);
    setIsPlayingBgm(false);
  }
}, [messages]);




const [containerHeight, setContainerHeight] = useState(window.innerHeight - 260); // 初始高度计算

useEffect(() => {
  const handleResize = () => {
    setContainerHeight(window.innerHeight - 260); // 更新高度
  };

  window.addEventListener('resize', handleResize);
  return () => window.removeEventListener('resize', handleResize); // 清理监听器
}, []);


  return (
    <div style={{backgroundColor:get_color_by_name('message-item-bg-all')}}>
      <div bodyStyle={{ padding: 0 }} bordered={false}>
        {isShowBackgroundHtml &&
        <div ref={divRef} style={{ display: 'flex', flexDirection: 'column' }}>
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center',
          border: `1px solid ${get_color_by_name('pop-window-border-color')}`,
          backgroundColor:get_color_by_name('mui-textfield-bg')}}>
            {/* 为了避免最后一个按钮有右边距，可以单独为最后一个按钮不设置marginRight，或者通过添加特定的处理逻辑来实现 */}
            <Slider sx={{width:'100px',color:'white'}}
              value={peopleVolumeBgm}
              size='small'
              onChange={handleVolumeChangePeopleBgm}
              aria-labelledby="continuous-slider"
            />

            <IconButton onClick={togglePlayBgm}>
              {isPlayingBgm ? <StopIcon sx={{color:'white'}} /> : <PlayArrowIcon sx={{color:'white'}}/>}
            </IconButton>

            <IconButton onClick={toggleFullScreen}>
              <FullscreenIcon sx={{ color: 'white' }} />
            </IconButton>
            <Button style={{color:'white'}} onClick={() => {setShowBgHtmlList(true)}}>{t('other animations')}</Button>

            <IconButton onClick={() => setShowContent(!showContent)}>
              <ChatBubbleOutlineOutlinedIcon sx={{ color: 'white' }} />
            </IconButton>

            <IconButton onClick={togglePlay}>
              {isPlaying ? <StopIcon sx={{color:'white'}} /> : <PlayArrowIcon sx={{color:'white'}}/>}
            </IconButton>
            {/* 假设这是最后一个按钮，我们不给它设置marginRight */}
            
           
            <Slider sx={{width:'100px',color:'white'}}
            value={peopleVolume}
            size='small'
            onChange={handleVolumeChangePeople}
            aria-labelledby="continuous-slider"
          />

          </div>
          <div style={{ flexGrow: 1,position:'relative'}}>
            {/* 内容区占据剩余空间 */}
            <iframe 
              src={selectedSrc}
              style={{
                position: 'absolute',
                
                width: '100%',
                height: `${containerHeight}px`,
                border: 'none',
                zIndex: 1000,
              }}>
                
              </iframe>
          </div>
          

          
          
          {showContent && (
        <Draggable
          onDrag={handleDrag}
          onStop={handleStop}
        >
          <div
            style={{
              width:'300px',
              position: 'absolute',
              right: '100px', // 距离右侧边缘100px
              top: '50px', // 根据需要调整顶部位置
              background: 'rgba(255, 255, 255, 0.3)', // 半透明背景
              border: '1px solid white', // 白色边框
              padding: '10px', // 内边距
              zIndex: 2000, // 确保文本框在iframe之上
              color:'white',
            }}>
              <div>
                <h1 style={{ margin: '0', padding: '0', fontSize: '16px', fontWeight: 'bold' }}>{currentItemData.bg_title}</h1>
                
                <ReactMarkdown
                children={currentItemData.bg_content}
                remarkPlugins={[remarkMath]}
                rehypePlugins={[rehypeRaw, rehypeKatex]} // 注意插件的顺序，rehypeRaw 先于 rehypeKatex
              />
              </div>
          </div>
      </Draggable>
          )}
          
      </div>
          
          }
        <MessageList  
          messages={messages} 
          subject={subject} 
          onReceiveStringFromMessItem={onReceiveStringFromMessItem} 
          useWf={useWf} 
          fromModule={fromModule}
          questionType={questionType}/>
        <InputArea 
          onSendMessage={onSendMessage} 
          predefinedContent={predefinedContent} 
          inputText={inputText} 
          setInputText={setInputText} 
          isLoading={isLoading} 
          onImageUpload={onImageUpload}
          showAudio={showAudio}
          handleAudioRecord={handleAudioRecord}
          showAddImage={showAddImage}
          ref={ref}
          subject={subject}
          questionTypeId={questionType}
          showWritingConfig={showWritingConfig}
          isShowWritingConfigWindow={isShowWritingConfigWindow}
          />
      </div>

      {isLoading1 && (
       <LoadingData text={t(loadingText)}/>
      )}

      <Modal
          open={showBgHtmlList}
          onClose={() => {setShowBgHtmlList(false)}}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          sx={{zIndex:9999}}
        >
          <Paper
            elevation={3}
            sx={{
              zIndex:9999,
              backgroundColor:'#2a3d66',
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              display: 'flex',
              flexDirection: 'column',
              gap: '20px',
              alignItems: 'center',
              padding: '20px',
              width:'500px',
            }}
          >
            <Box
        sx={{
          width: '100%', // 使Box充满Paper的宽度
          maxHeight: '60vh', // 设置最大高度为视口的60%
          overflow: 'auto', // 超出部分显示滚动条
        }}
      >
            <List>
              {bgHtmlListData.map((item) => (
                <ListItem key={item.id} button onClick={() => handleItemClick(item)}>
                  <ListItemText sx={{color:'white'}} primary={item.bg_title} /> 
                </ListItem>
              ))}
            </List>
            </Box>
      </Paper>
      </Modal>


    </div>
  );
});

export default ChatWindow;
