import React, { useState ,useRef,useEffect } from 'react';
import styled from 'styled-components';
import { Mic, SkipPrevious, SkipNext, MusicNote, VolumeUp, Summarize} from '@mui/icons-material';
import { Typography } from '@mui/material';
import ChildCareIcon from '@mui/icons-material/ChildCare'; // 儿童图标
import SchoolIcon from '@mui/icons-material/School'; // 老师图标
import Slider from '@mui/material/Slider';
import { Swiper, SwiperSlide } from 'swiper/react';
import Box from '@mui/material/Box';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/effect-cards';
import MenuItem from '@mui/material/MenuItem';
import './PicktureBook.css';
// import required modules
import { EffectCards } from 'swiper/modules';
import LanguageIcon from '@mui/icons-material/Language';
import MicIcon from '@mui/icons-material/Mic';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import SendIcon from '@mui/icons-material/Send';
import StopIcon from '@mui/icons-material/Stop';
import { keyframes } from 'styled-components';
import { IconButton, unstable_ClassNameGenerator } from '@mui/material';
import {requestUrl} from '../../requestUrl';
import LoadingData from '../dataloading';
import { getHostName } from '../../utils/urlUtils';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import CancelIcon from '@mui/icons-material/Cancel';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import PaletteIcon from '@mui/icons-material/Palette';
import Popover from '@mui/material/Popover';
import HistoryIcon from '@mui/icons-material/History';
import pinyin from "pinyin";
import { useTranslation } from 'react-i18next';
import { getHostNameWeb } from '../../utils/urlUtils';
const Page1 = styled.div`
  width: 1024px;
  max-width: 100%;
  height: 85vh;
  max-height: 80vh;
  object-fit: contain;
  margin: auto;
  display: block;
  backface-visibility: hidden;
  transform-origin: bottom right;
  position: relative;
`;
const Page = styled.div`
  width: 1024px;
  max-width: 100%;
  height: 100vh; // 使用100视口高度，或者根据需要调整
  max-height: 85vh;
  margin: auto;
  display: block;
  position: relative;
`;

const ButtonContainer = styled.div`
  text-align: center;
  margin-top: 5px;
`;


const ChatContainer = styled(Box)`
  height: 100%;
  width: 100%;
  overflow-y: auto;
  border: 1px solid transparent;  // 设置边框颜色为透明
  padding: 10px;
  background-color: transparent; // 设置背景颜色为透明
  border-radius: 10px;
`;

const Message = styled(Box)`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
`;

const IconContainer = styled(Box)`
  display: flex;
  align-items: center;
  margin-right: 10px;
`;



const PictureBook = ({ title_local,title_en,pages,musicurl,onClose,summary,summary_en,picturebookId}) => {
  const [currentPage, setCurrentPage] = useState(0);
  const [textPosition, setTextPosition] = useState({ x: 0, y: 0 });
  const [showEnglish, setShowEnglish] = useState(false);
  const { t } = useTranslation();
  const pageRef = useRef(null);
  const textRef = useRef(null);
  const [isLoaded, setIsLoaded] = useState(false); // 新增状态，跟踪加载状态
  const [isMusicPlaying, setIsMusicPlaying] = useState(false); // 初始状态为播放音乐
  const musicRef = useRef(new Audio({musicurl})); // 替换为您的音乐文件路径
  const [isMusicLoading, setIsMusicLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingText,setLoadingText] = useState('loading');

  const [audioPeopleUrl,setAudioPeopleUrl] = useState(); 
  const audioPeopleRef = useRef(new Audio());

  const [playbackRate, setPlaybackRate] = useState(1);

  const [pinyinText, setPinyinText] = useState([]);

  const [fontSize, setFontSize] = useState(20); // 初始字体大小
  const [sliderOpen, setSliderOpen] = useState(false); // 控制滑块显示的状态

  // 处理滑块变化
  const handleSliderChangeFontSize = (event, newValue) => {
    setFontSize(newValue);
  };

  // 切换滑块显示
  const toggleSlider = () => {
    setSliderOpen(!sliderOpen);
  };


  const playAudio = (audioPath) => {
    const audio = new Audio(audioPath);
    audio.play();
  };


  const [color, setColor] = useState('black');
  const colors = ['red', 'blue', 'green', 'yellow', 'purple', 'pink', 'orange', 'brown', 'grey', 'black', 'violet', 'indigo', 'lime', 'olive', 'teal'];


  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };


  const handleCloseColor = () => {
    setAnchorEl(null);
  };

  const handleChangeColor1 = (color) => {
    setColor(color);
    handleCloseColor();
  };


  const open = Boolean(anchorEl);
  const id = open ? 'color-picker-popover' : undefined;





  const ColoredText1 = styled.span`
  color: ${props => props.color};
  font-weight: bold;  // 设置文本为粗体
  font-size: 20px; 
`;

const ColoredText = styled.span`
  color: ${props => props.color};
  font-weight: bold;
  font-size: ${props => props.fontSize}px; // 使用fontSize属性控制字体大小
`;

// 定义颜色块的样式
const ColorBox = styled.div`
  width: 20px;
  height: 20px;
  background-color: ${props => props.color};
`;
const handleChangeColor = (event) => {
    setColor(event.target.value);
  };

const ContainerAudio = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 50%;
  width: 200px;
  height: 200px;
  display: ${props => props.isVisible ? 'flex' : 'none'};
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: rgba(173, 216, 230, 0.5); // 半透明背景
`;

const ContainerHistory = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 10px;
  width: 400px;
  height: 400px;
  display: ${props => props.isVisible ? 'flex' : 'none'};
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: rgba(173, 216, 230, 0.5); // 半透明背景
`;

  const scaleUp = keyframes`
  from {
    transform: scale(1);
  }
  to {
    transform: scale(1.2);
  }
  `;

  const StyledIconButton = styled(IconButton)`
  &.MuiIconButton-root {
    animation: ${scaleUp} 0.3s ease;
  }
  `;



  
  useEffect(() => {
    musicRef.current = new Audio(musicurl);
    // 注意：我们不在这里自动播放音乐
    return () => {
      if (musicRef.current) {
        musicRef.current.pause();
      }
    };
  }, [musicurl]);

  const speechRef = useRef();

  // 设置音量状态
  const [volume, setVolume] = useState(20); // 默认音量为50%
  const [peopleVolume, setpeopleVolume] = useState(100); // 默认音量为50%
   // 当组件挂载时，设置默认音量
   useEffect(() => {
    musicRef.current.volume = volume / 100;
  }, [volume]);

  useEffect(() => {
    audioPeopleRef.current.volume = peopleVolume / 100;
  }, [peopleVolume]);

  // 处理音量变化
  const handleVolumeChange = (event, newValue) => {
    setVolume(newValue);
  };

  const handleVolumeChangePeople = (event, newValue) => {
    setpeopleVolume(newValue);
  };


  const toggleMusic = () => {
    if (isMusicPlaying) {
      musicRef.current.pause();
    } else {
      if (musicRef.current) {
        musicRef.current.play().catch(e => console.error("播放失败:", e));
      }
    }
    setIsMusicPlaying(!isMusicPlaying);
  };


  const handleLoad = () => {
    if (pageRef.current && textRef.current) {
      const pageRect = pageRef.current.getBoundingClientRect();
      const textRect = textRef.current.getBoundingClientRect();

      const centerX = (pageRect.width - textRect.width) / 2;
      // 将文本容器的初始y坐标设置为页面高度的90%
      const bottomY = pageRect.height * 0.9 - textRect.height;

      setTextPosition({
        x: centerX > 0 ? centerX : 10, // 确保x坐标不为负
        y: bottomY > 0 ? bottomY : 10, // 确保y坐标不为负
      });
      setIsLoaded(true); // 设置为已加载
    }
  };

  useEffect(() => {
    if (isLoaded) {
      // 当页面和文本容器都加载后再调整位置
      handleLoad();
    }
  }, [isLoaded]); // 依赖于加载状态

 const nextPage = () => {
  if (selectedTextLocal) {
    const words = pinyin(selectedTextLocal, {
      style: pinyin.STYLE_NORMAL, // 根据需要选择拼音风格
    });
    const combinedText = selectedTextLocal.split('').map((char, index) => ({
      char,
      pinyin: words[index] ? words[index][0] : ''
    }));
    setPinyinText(combinedText);
  }
};

const prevPage = () => {

};
const handleClose = () => {
  onClose();
};


const BoxRef = useRef(null);

const [isDragging, setIsDragging] = useState(false);
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const [relPosition, setRelPosition] = useState(null);

  
  useEffect(() => {
    if (BoxRef.current) {
      const { offsetHeight } = document.documentElement;
      const swiperHeight = offsetHeight * 0.8; // 假设 Swiper 占据了 80% 的视口高度
      const boxHeight = BoxRef.current.offsetHeight;
      const y = swiperHeight - boxHeight - 20; // Swiper 底部以上 20px
      const x = offsetHeight / 2 - BoxRef.current.offsetWidth / 2;
      setPosition({ x, y });
    }
  }, []);



  const onMouseDown = (e) => {
    if (e.button !== 0) return; // 只响应左键点击
    setRelPosition({
      x: e.pageX - position.x,
      y: e.pageY - position.y
    });
    setIsDragging(true);
  };

  const onMouseUp = () => {
    setIsDragging(false);
  };

  const onMouseMove = (e) => {
    if (!isDragging) return;
    setPosition({
      x: e.pageX - relPosition.x,
      y: e.pageY - relPosition.y
    });
  };

  useEffect(() => {
    if (pages && pages.length > 0) {
      updateTextContent(0);
    }
  }, [pages]);


  const updateTextContent = (index) => {
    const image = pages[index];
    setSelectedTextEn(image.content_en);
    setSelectedTextLocal(image.content_local_lang);
    setCurrentPage(index);
  };

  

  const [selectedTextEn, setSelectedTextEn] = useState('');
  const [selectedTextLocal, setSelectedTextLocal] = useState('');

  const [audioPeopleUrlEnglish,setAudioPeopleUrlEnglish] = useState();
  const [audioPeopleUrlLocal,setAudioPeopleUrlLocal] = useState();

  const handleSlideChange = (swiper) => {
    const index = swiper.activeIndex;
    const image = pages[index];
    
    setSelectedTextEn(image.content_en);
    setSelectedTextLocal(image.content_local_lang);
    setAudioPeopleUrlEnglish(getHostNameWeb() + "/" + image.content_en_mp3);
    setAudioPeopleUrlLocal(getHostNameWeb() + "/" + image.content_local_lang_mp3);
    setCurrentPage(index);


    // 根据showEnglish的值选择音频URL
    const audioUrl = showEnglish ? getHostNameWeb() + "/" + image.content_en_mp3 : getHostNameWeb() + "/" + image.content_local_lang_mp3;
    setAudioPeopleUrl(audioUrl);
    

    const currentIndex = swiper.activeIndex;
    const previousIndex = swiper.previousIndex;
    const currentImage = pages[currentIndex];
    const previousImage = pages[previousIndex];

     // 检查当前页面是否为视频并播放
     if (currentImage.filetype === 'v') {
      const currentVideoElement = document.getElementById(`video_${currentIndex}`);
      if (currentVideoElement) {
          currentVideoElement.play();
      }
    }

  // 如果上一个页面是视频，停止播放
  if (previousImage && previousImage.filetype === 'v') {
      const previousVideoElement = document.getElementById(`video_${previousIndex}`);
      if (previousVideoElement) {
          previousVideoElement.pause();
          previousVideoElement.currentTime = 0; // 可以选择重置视频到开始
      }
  }




    // 处理视频自动播放
    if (image.filetype === 'v') {
      const videoElement = document.getElementById(`video_${index}`);
      if (videoElement) {
          videoElement.play();
      }
      } 
    else {
          // 处理音频播放
          audioPeopleRef.current.src = audioUrl;
          audioPeopleRef.current.play();
    }
  };

  useEffect(() => {
    return () => {
      // 暂停并重置音频
      audioPeopleRef.current.pause();
      audioPeopleRef.current.src = '';
    };
  }, []);


// 当showEnglish变化时，切换音频
useEffect(() => {
  if (pages.length > 0) {
    const audioUrl = showEnglish ? getHostNameWeb() + "/" + pages[0].content_en_mp3 : getHostNameWeb() + "/" + pages[0].content_local_lang_mp3;
    audioRef.current.src = audioUrl;
  }
}, [showEnglish, pages]);


  const toggleLanguage = () => {
    let se = !showEnglish
    setShowEnglish(se);
    if(se === true){
      setAudioPeopleUrl(audioPeopleUrlEnglish);
    }
    else
    {
      setAudioPeopleUrl(audioPeopleUrlLocal);
    }


  }
  //const toggleLanguage = () => setShowEnglish(!showEnglish); // 切换语言的函数

  const audioRef = useRef(null);
  const mediaRecorderRef = useRef(null);
  const [audioChunks, setAudioChunks] = useState([]);
  const [isVisible, setIsVisible] = useState(false);

  const handlePlayback = () => {
    if (audioChunks.length) {
      const audioBlob = new Blob(audioChunks, { type: 'audio/wav' });
      const audioUrl = URL.createObjectURL(audioBlob);
      audioRef.current.src = audioUrl;
      audioRef.current.play();
    }
  };

    const [audioRemote, setAudioRemote] = useState(null);


  const handleSendAudio = async () => {
    const audioBlob = new Blob(audioChunks, { type: 'audio/wav' });
    const formData = new FormData();
    formData.append("id",1);
    formData.append("grade",1);
    formData.append("pagenum",currentPage);
    formData.append("pagecontent",selectedTextLocal);
    formData.append("summarize",summary);
    formData.append("audio", audioBlob);
    console.log(formData);
    try {
      setLoading(true);
      setLoadingText("loading");
     
      //const response = await requestUrlFormData(formData, "picturebook_question");
      
      const token = localStorage.getItem("access_token");

      const url = getHostName() + '/picturebook_question'; // 你的服务端URL
      const response = await fetch(url, {method: 'POST',
                headers: {'Authorization': `Bearer ${token}`},
                body: formData,});
      if (response.ok) {

        if (response.ok) {
          const data = await response.blob();
          const audioUrl = URL.createObjectURL(data);
          const audio = new Audio(audioUrl);
      
          setAudioRemote(audio); // 只设置一次Audio对象
          audio.play();
      }



      }
      else {
          console.error("Error fetching reply:", response.statusText);
      }

  } catch (error) {
      console.error("获取数据时出错:", error);
  } finally {
      setLoading(false);
      setIsVisible(false);
  }
  };


  const handlePlayRemoteAudio = () => {
    if (audioPeopleRef.current) {
      audioPeopleRef.current.src = audioPeopleUrl;
      audioPeopleRef.current.play();
    }
};
  
  const handleStartRecording = () => {
      setAudioChunks([]);  // 清空之前的录音数据
      navigator.mediaDevices.getUserMedia({ audio: true }).then(stream => {
        mediaRecorderRef.current = new MediaRecorder(stream);
        mediaRecorderRef.current.ondataavailable = (event) => {
          setAudioChunks((prev) => [...prev, event.data]);
        };
        mediaRecorderRef.current.start();
      });
  };

  const handleStopRecording = () => {
    mediaRecorderRef.current.stop();
  };


  const [isVisiblePlay, setIsVisiblePlay] = useState(true); // 初始状态为可见


  const handleClickPlay = () => {

    setIsVisiblePlay(false);
    toggleMusic();//播放背景音乐

    const audioUrl = showEnglish ? getHostNameWeb() + "/" + pages[0].content_en_mp3 : getHostNameWeb() + "/" + pages[0].content_local_lang_mp3;
    setAudioPeopleUrl(audioUrl);
    
    if (audioPeopleRef.current) {
      audioPeopleRef.current.src = audioUrl;
      audioPeopleRef.current.play();
    }

  };


  const containerStylePlay = {
    position: 'fixed', // 或 'absolute'
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    display: isVisiblePlay ? 'block' : 'none',
    zIndex: 1000
  };

  const [isShowPBChatHistory,setIsShowPBChatHistory] = useState(false);
  const [messages,setMessages] = useState([]);
  
  const handlePicturebookChatHistory = async () => {
    
    if(isShowPBChatHistory === true){
      setIsShowPBChatHistory(false);
      return;
    }
    try {
      setLoading(true);
      
      setLoadingText("loading");
      const dataToSend = {
          pbid: picturebookId,
      };
      const data = await requestUrl(dataToSend, "get_picturebook_history");
      
      if(data.status === "success"){
          setMessages(data.data);
          setLoading(false);
      }
  } catch (error) {
      console.error("获取数据时出错:", error);
  } finally {
    setLoading(false);
  }


    setIsShowPBChatHistory(true);


  }

  useEffect(() => {
    if (selectedTextLocal) {
      const words = pinyin(selectedTextLocal, {
        style: pinyin.STYLE_NORMAL, // 根据需要选择拼音风格
      });
      const combinedText = selectedTextLocal.split('').map((char, index) => ({
        char,
        pinyin: words[index] ? words[index][0] : ''
      }));
      setPinyinText(combinedText);
    }
  }, [selectedTextLocal]); // 依赖数组中包含selectedTextLocal，以便在其变化时触发

  

  return (
    <div style={{backgroundColor:'#c9c9c9'}}>
          <div style={{
            fontSize:'26px',
            fontFamily:'Verdana',
            color: 'black',
            position: 'absolute',  // 使用绝对定位
            top: '20px',          // 距离顶部20px
            left: '50%',          // 左边距离屏幕宽度的一半
            transform: 'translateX(-50%)' // 向左偏移自身宽度的一半，实现水平居中
          }}>
            <span style={{color:'black',fontFamily:'Pacifico'}}>{showEnglish ? title_en : title_local}</span>
          </div>
        <Page>
        <Swiper
        style={{ height: '85vh' }}
        effect={'cards'}
        grabCursor={true}
        modules={[EffectCards]}
        className="mySwiper"
        onSlideChange={handleSlideChange}
        speed={1000}
        onSwiper={(swiper) => swiper.update()} // 在 Swiper 实例化后调用 update 方法
    >
        {pages.map((image, index) => (
            <SwiperSlide key={image.id} style={{ height: '100%' }}> {/* 使用唯一 key 值 */}
                {image.filetype === 'i' && (
                    <img style={{ height: '100%', width: '100%', objectFit: 'contain' }} src={getHostNameWeb() + "/" +image.filepath} alt={image.content_local_lang} />
                )}
                {image.filetype === 'v' && (
                    <video id={`video_${index}`} style={{ pointerEvents: 'none', height: '100%', width: '100%', objectFit: 'contain' }}>
                        <source src={getHostNameWeb() + "/" + image.filepath} type="video/mp4" />
                        Your browser does not support the video tag.
                    </video>
                )}
            </SwiperSlide>
        ))}
    </Swiper>
    
        <Box
        ref={BoxRef}
          sx={{
            zIndex:999999,
            width: 600,
            height: 200,
            position: 'absolute',
            bottom: '-170px', // 距离图片底部20px
            left: '50%', // 左侧偏移量为父元素宽度的一半
            transform: 'translateX(-5%)', // 横向偏移自身宽度的一半，使之居中
            backgroundColor: isDragging ? 'rgba(255, 255, 255, 0.5)' : 'transparent',
            color: 'black',
            padding: '10px',
            
            top: position.y,
            left: position.x,
            cursor: 'move'
          }}
          onMouseDown={onMouseDown}
          onMouseUp={onMouseUp}
          onMouseMove={onMouseMove}
        >
          {showEnglish ? (
    <ColoredText color={color}>{selectedTextEn}</ColoredText>
  ) : (
    <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
      {pinyinText.map((item, index) => (
        <div key={index} style={{ margin: '5px', textAlign: 'center' }}>
          <ColoredText color={color} fontSize={fontSize}><div>{item.pinyin}</div></ColoredText>
          <ColoredText color={color} fontSize={fontSize}>{item.char}</ColoredText>
        </div>
      ))}
    </div>
  )}
        </Box>
      </Page>
      
      <ButtonContainer>
      <IconButton 
          onClick={() => setIsVisible(!isVisible)}
          color="warning" 
          style={{
            
            backgroundColor: '#b9b9b9', // 背景颜色
            border: '1px solid #ccc', // 边框
            boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.5)', // 阴影效果
            borderRadius: '50%', // 确保圆形
          }}
        >
      <Mic />
    </IconButton>


      {/* 上一页按钮 */}
      <IconButton color="warning" sx={{ margin: 1, boxShadow: 2 }}
          style={{
            backgroundColor: '#b9b9b9', // 背景颜色
            border: '1px solid #ccc', // 边框
            boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.5)', // 阴影效果
            borderRadius: '50%', // 确保圆形
          }} onClick={toggleSlider} aria-label="previous page">
        <ChevronLeftIcon />
      </IconButton>
      
      {/* 下一页按钮 */}


     
      <IconButton 
        color="warning" 
        sx={{ margin: 1, boxShadow: 2 }}
        style={{
          backgroundColor: '#b9b9b9', // 背景颜色
          border: '1px solid #ccc', // 边框
          boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.5)', // 阴影效果
          borderRadius: '50%', // 确保圆形
        }}
        onClick={nextPage} aria-label="add pinyin">
        <ChevronRightIcon />
      </IconButton>
      {/* 背景音乐播放按钮 */}

      <IconButton 
          onClick={toggleMusic} 
          color="warning" 
          style={{
            
            backgroundColor: '#b9b9b9', // 背景颜色
            border: '1px solid #ccc', // 边框
            boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.5)', // 阴影效果
            borderRadius: '50%', // 确保圆形
          }}
        >
      {isMusicPlaying ? <StopIcon /> : <PlayArrowIcon />}
    </IconButton>
          {/* MUI音量控制器 */}

      <IconButton 
        onClick={handlePlayRemoteAudio} 
        color="warning" 
        sx={{ margin: 1, boxShadow: 2 }}
      >
        <VolumeUp />
      </IconButton>
      

      <IconButton 
      color="warning" 
      style={{
        backgroundColor: '#b9b9b9', // 背景颜色
        border: '1px solid #ccc', // 边框
        
      }}
      onClick={toggleLanguage} aria-label="toggle language">
      <LanguageIcon />
      {/* 可以在这里添加文字或旗帜图标来表示当前语言 */}
    </IconButton>

    <IconButton color="warning" onClick={handleClick} style={{ backgroundColor: color, borderRadius: '50%' }} sx={{ margin: 1, boxShadow: 2 }}>
        <PaletteIcon />
      </IconButton>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleCloseColor}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        {colors.map((color, index) => (
          <MenuItem key={index} onClick={() => handleChangeColor1(color)}>
            <ColorBox color={color} />
          </MenuItem>
        ))}
      </Popover>

      <IconButton
          onClick={handleClose}
          color="warning" 
          style={{
            backgroundColor: '#b9b9b9', // 背景颜色
            border: '1px solid #ccc', // 边框
            boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.5)', // 阴影效果
            borderRadius: '50%', // 确保圆形
          }}
          aria-label="close">
        <CancelIcon />
      </IconButton>
      
      </ButtonContainer>
      
      <Box sx={{zIndex:99999}} display="flex" justifyContent="center" alignItems="center" gap={2}>
      <Slider 
        sx={{
          width: '100px',
          '& .MuiSlider-thumb': {
            color: '#ffb74d', // 使用橘黄色作为滑块颜色
          },
          '& .MuiSlider-track': {
            color: '#b87c0d', // 使用淡蓝色作为轨道颜色
          },
          '& .MuiSlider-rail': {
            color: '#535458', // 使用灰色作为未填充轨道的颜色
          },
        }}
        value={volume}
        onChange={handleVolumeChange}
        aria-labelledby="continuous-slider"
      />
      <IconButton
          onClick={handlePicturebookChatHistory}
          color="warning" 
          style={{
            backgroundColor: '#b9b9b9', // 背景颜色
            border: '1px solid #ccc', // 边框
            boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.5)', // 阴影效果
            borderRadius: '50%', // 确保圆形
          }}
          aria-label="history">
            <HistoryIcon />
          </IconButton>

      <Slider sx={{width:'100px'}}
        value={peopleVolume}
        onChange={handleVolumeChangePeople}
        aria-labelledby="continuous-slider"
      />
      
        <Slider sx={{width:'100px'}}
          value={fontSize}
          onChange={handleSliderChangeFontSize}
     
          min={10}
          max={30}
        />
      
        </Box>
       

      <ContainerAudio style={{zIndex:999}} isVisible={isVisible}>
      <StyledIconButton 
          onMouseDown={handleStartRecording}
          onMouseUp={handleStopRecording}>
          <MicIcon />
        </StyledIconButton>
        <StyledIconButton onClick={handlePlayback}>
          <PlayArrowIcon />
        </StyledIconButton>
        <StyledIconButton onClick={handleSendAudio}>
          <SendIcon />
        </StyledIconButton>
      <audio ref={audioRef} hidden></audio>
      </ContainerAudio>
      
      {loading && (
      <LoadingData text={t(loadingText)}/>
    )}

      {isShowPBChatHistory && (
        <ContainerHistory style={{zIndex:9999}} isVisible={isShowPBChatHistory}>
         <ChatContainer>
      {messages.map((message, index) => (
        <Message key={index}>
          <IconContainer>
            {message.sender === 'S' ? (
              <ChildCareIcon color="primary" />
            ) : (
              <SchoolIcon color="secondary" />
            )}
            <IconButton onClick={() => playAudio(message.mp3_path)}>
              <PlayCircleOutlineIcon />
            </IconButton>
          </IconContainer>
          <Typography variant="body1" sx={{color:'blue'}}>{message.text}</Typography>
        </Message>
      ))}
    </ChatContainer>
        </ContainerHistory>
      )}



    <div style={containerStylePlay}>
    <IconButton 
  onClick={handleClickPlay} 
  style={{ 
    fontSize: '100px', 
    color: 'rgba(255, 255, 255, 0.8)', // 提高透明度
    backgroundColor: 'transparent',
  }}
>
  <PlayCircleOutlineIcon style={{ 
    fontSize: 'inherit',
    animation: 'glow 1.5s ease-in-out infinite', // 添加动画
    textShadow: '0 0 20px rgba(255, 255, 255, 0.8), 0 0 40px rgba(255, 255, 255, 0.8)' // 更明显的发光效果
  }} />
</IconButton>



        </div>
    </div>
  );
};

export default PictureBook;
