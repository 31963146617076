import React from 'react';
import styled from 'styled-components';

const ComboboxContainer1 = styled.div`
  margin-top: 10px;
  margin-bottom: 10px;
  width: 275px; /* Set width to 275px */
  display: flex;
  height: 70px; /* Set height to 50px */
`;

const ComboboxContainer = styled.div`
  margin-top: 10px;
  margin-bottom: 10px;
  width: ${props => props.customWidth ? props.customWidth : '275px'}; /* 使用模板字符串动态设置宽度 */
  display: flex;
  height: 70px; /* 设置默认高度为70px，你也可以动态设置 */
`;


const ComboboxLabel = styled.label`
  color: black;
`;

const ComboboxSelect = styled.select`
  background-color: #1b2134;
  color: #ffffff;
  border-color: #1b2134;
  padding: 10px;
  border-radius: 5px;
  margin-top: 10px;
  margin-bottom: 10px;
`;

const CommonComboboxOne = ({ preferredValue, setPreferredValue, options, onValueSelected, placeholder, labelText, customWidth }) => {
  const handleChange = (e) => {
    const selectedValue = e.target.value;
    setPreferredValue(selectedValue);

    if (onValueSelected) {
      onValueSelected(selectedValue);
    }
  };

  return (
    <ComboboxContainer customWidth={customWidth}>
      <ComboboxLabel>{labelText}</ComboboxLabel>
      <ComboboxSelect
        value={preferredValue}
        onChange={handleChange}
      >
        <option disabled value="">
          {placeholder || '请选择'}
        </option>
        {options.map((text, index) => (
          <option key={index} value={text}>
            {text}
          </option>
        ))}
      </ComboboxSelect>
    </ComboboxContainer>
  );
};

export default CommonComboboxOne;
