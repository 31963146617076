import React, { useState, useRef } from "react";

import { getHostName } from "./utils/urlUtils";

const Testaliyun = () => {
  const fileInputRef = useRef(null);
  const [uploadProgress, setUploadProgress] = useState(0);

  const handleUpload = async () => {
    let song= "";
    const file = fileInputRef.current.files[0];
    if (!file) return;

    const token = localStorage.getItem("access_token");
    const dataToSend = {
      filename: file.name
    };

    const url = getHostName() + '/generate_upload_params'; // 你的服务端URL

    const response = await fetch(url, {
      method: 'POST', 
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
      body: JSON.stringify(dataToSend)
    });

    if (response.ok) {
      const data = await response.json();

      const formData = new FormData();
      formData.append("key", data.key);
      formData.append("OSSAccessKeyId", data.OSSAccessKeyId);
      formData.append("policy", data.policy);
      formData.append("Signature", data.Signature);
      formData.append("file", file);

      const xhr = new XMLHttpRequest();
      xhr.open("POST", `https://${data.bucket}.${data.endpoint}`);
      xhr.upload.onprogress = (e) => {
        if (e.lengthComputable) {
          const percentage = (e.loaded / e.total) * 100;
          setUploadProgress(percentage);
        }
      };

      xhr.onload = () => {
        if (xhr.status >= 200 && xhr.status < 300) {
            console.log("Upload successful!");
            setUploadProgress(0);  // Reset progress after upload
        } else {
            console.error("OSS Error Response:", xhr.status, xhr.statusText, xhr.responseText);
            throw new Error("Error uploading file to OSS");
        }
     };
     
    

      xhr.onerror = () => {
        throw new Error("Error uploading file to OSS");
      };

      xhr.send(formData);

    } else {
      const errorData = await response.text();
      console.error("Error fetching upload params:", errorData);
    }
  };

  return (
    <div>
      <input type="file" ref={fileInputRef} />
      <button onClick={handleUpload}>Upload to OSS</button>
      {uploadProgress > 0 && (
        <div>
          <div>Upload Progress:</div>
          <progress value={uploadProgress} max="100"></progress>
          {uploadProgress.toFixed(2)}%
        </div>
      )}
    </div>
  );
};

export default Testaliyun;
